import PaymentMethod from 'payment/shared/constants/PaymentMethod';
import BookingStatus from 'postbooking/shared/constants/BookingStatus';
import { JavaCurrencyValue } from 'shared/utils/currency';

import AccomodationType from '../constants/AccomodationType';
import SortType from '../constants/SortType';
import {
  AutocompleteItem,
  Badge,
  Facility,
  GeoLocation,
  HotelNonEmployeeDetail,
  HotelNonEmployeeTraveler,
  Image,
  NonEmployeeTypeSimplified,
  Property,
  Room,
} from './types';

// ===== Auto Complete
export const AUTOCOMPLETE_API = '/api/v1/search/hotel/autocomplete';
export type AutocompleteRequest = {
  query: string;
};
export type AutocompleteResponse = {
  results: AutocompleteItem[];
};

// ===== Search Properties
export const SEARCH_PROPERTY_API = '/api/v1/search/hotel/list';
export type SearchPropertyRequest = {
  checkInDate: string;
  checkOutDate: string;
  employeeIds: string[];
  nonEmployeeTravelers: HotelNonEmployeeTraveler;
  numOfRooms: number;
  sort: SortType;
  id: string;
  type: string;
  geoLocation?: GeoLocation;
  stars: number[];
  minPrice: number | null;
  maxPrice: number | null;
  accommodationTypes: AccomodationType[];
  limit?: number;
  offset?: number;
  locale: string;
  currencyCode: string;
  // Tracking
  tripRequestId?: string;
  searchTerm?: string;
  trackingSpec: {
    pageName: string;
    pageCategory: string;
    data: {
      visitId: string;
      funnelSource: string;
    };
  };
};
export type SearchPropertyResponse = {
  properties: Property[];
  numOfHotels: string;
  maximalOffset: string;
};

// ===== Property Detail
// Hotel Detail Decom project -> Combining the API with the Search Room API
export type PropertyDetailResponse = {
  name: string;
  address: string;
  starRating: string;
  reviewScore: Nullable<string>;
  accommodationType: AccomodationType;
  geoLocation: GeoLocation;
  images: Image[];
  facilities: Facility[];
  checkInTime: Nullable<string>;
  checkOutTime: Nullable<string>;
  importantNotice: Nullable<string>;
  checkInInstruction: Nullable<string>;
  policy: Nullable<string>;
  badges: Badge[];
};

// ===== Search Rooms
export const SEARCH_ROOM_API = '/api/v1/search/hotel/rooms';
export type SearchRoomRequest = {
  checkInDate: string;
  checkOutDate: string;
  employeeIds: string[];
  nonEmployeeTravelers: HotelNonEmployeeTraveler;
  numOfRooms: number;
  propertyId: string;
  locale: string;
  currencyCode: string;
  tripRequestId?: string;
  trackingSpec: {
    pageName: string;
    pageCategory: string;
    data: {
      visitId: string;
      searchId?: string;
      funnelSource: string;
    };
  };
};
export type SearchRoomResponse = PropertyDetailResponse & {
  rooms: Room[];
};

// ===== Room Availability
export const ROOM_AVAILABILITY_API = '/api/v1/search/hotel/room/availability';
export type RoomAvailabilityRequest = {
  checkInDate: string;
  checkOutDate: string;
  employeeIds: string[];
  nonEmployeeTravelers: HotelNonEmployeeTraveler;
  numOfRooms: number;
  propertyId: string;
  roomId: string;
  rateKey: string;
  roomOccupancy: number;
  locale: string;
  currencyCode: string;
  tripRequestId?: string;
  trackingSpec: {
    pageName: string;
    pageCategory: string;
    data: {
      visitId: string;
      searchId?: string;
      detailId?: string;
    };
  };
  totalFareFromClient: JavaCurrencyValue;
};
export type RoomAvailabilityResponse = {
  availability: 'AVAILABLE' | 'NOT_AVAILABLE';
  propertyName: string;
  propertyStarRating: string;
  propertyImage: Nullable<string>;
  checkInTime: Nullable<string>;
  checkOutTime: Nullable<string>;
  roomId: string;
  rateKey: string;
  roomName: string;
  roomTypeName: Nullable<string>;
  bedType: string;
  roomOccupancy: string;
  totalFare: JavaCurrencyValue;
  isFreeCancelation: boolean;
  isRefundable: boolean;
  refundPolicy: string;
  isBreakfastIncluded: Nullable<boolean>;
  isWifiIncluded: Nullable<boolean>;
  isComplying: boolean;
  totalFareFromClient: JavaCurrencyValue;
  serviceFee: Nullable<JavaCurrencyValue>;
  vatFee: Nullable<JavaCurrencyValue>;
  grandTotal: Nullable<JavaCurrencyValue>;
};

// ===== Submit Booking
export const SUBMIT_BOOKING_API = '/api/v1/booking/submit';
export type SubmitBookingRequest = {
  type: 'HOTEL';
  confirmSubmit: boolean;
  locale: string;
  policyBypassRemarks?: string;
  productRequestId?: string;
  notes?: string;
  attachmentUrl?: string;
  tripRequestId?: string;
  contact: {
    phoneNumber: string;
    phoneNumberPrefix: string;
  };
  totalFareFromClient: JavaCurrencyValue;
  hotel: {
    checkInDate: string;
    checkOutDate: string;
    propertyId: string;
    roomId: string;
    numOfRooms: number;
    roomOccupancy: number;
    rateKey: string;
    specialRequest: string;
    employeeIds: string[];
    nonEmployeeTravelers: HotelNonEmployeeDetail;
  };
  trackingSpec: {
    pageName: string;
    pageCategory: string;
    data: {
      visitId: string;
      searchId?: string;
      detailId?: string;
      preBookingId?: string;
    };
  };
  serviceFee?: Nullable<JavaCurrencyValue>;
  vatFee?: Nullable<JavaCurrencyValue>;
  grandTotal?: Nullable<JavaCurrencyValue>;
};

// ===== Booking Detail
export const BOOKING_DETAIL_API = '/api/v1/booking/hotel/detail';
export type BookingDetailRequest = {
  bookingId: string;
};
export type BookingDetailResponse = {
  paymentMethod: PaymentMethod;
  bookingSubmissionStatus: string;
  bookingFailureReason: Nullable<string>;
  bookingId: string;
  tripId: string;
  corporateId: string;
  paymentExpirationTimeInSecond: string;
  status: BookingStatus;
  bookingTime: string;
  policyBypassRemarks: string;
  providerId: string;
  providerBookingId: string;
  loggedInUser: string;
  contactDetail: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    phoneNumberPrefix: string;
  };
  hotelBookingDetail: {
    propertyInfo: {
      id: string;
      name: string;
      image: string;
      lines: string;
      localLines: string;
      city: string;
      country: string;
      lat: string;
      lon: string;
      checkInTime: Nullable<string>;
      checkOutTime: Nullable<string>;
    };
    roomsInfo: Array<{
      id: string;
      name: string;
      numOfAdults: string;
      bedType: string;
      breakfastIncluded: boolean;
      wifiIncluded: boolean;
    }>;
    checkInDate: string;
    checkOutDate: string;
    specialRequest: Nullable<string>;
  };
  guests: Array<{
    isEmployee: boolean;
    type: NonEmployeeTypeSimplified;
    firstName: string;
    lastName: string;
    title: Nullable<string>;
    email?: string;
    division: Nullable<string>;
    age?: string;
  }>;
  priceChanged: boolean;
  detailPrice: Array<{
    price: JavaCurrencyValue;
    description: string;
  }>;
  totalPrice: JavaCurrencyValue;
  totalPriceCharged: JavaCurrencyValue;
  locale: string;
  serviceFee: Nullable<JavaCurrencyValue>;
  vatFee: Nullable<JavaCurrencyValue>;
  grandTotal: Nullable<JavaCurrencyValue>;
};

// ===== Resend Voucher
export const RESEND_VOUCHER_API = '/api/v1/booking/hotel/resend-eticket';
export type ResendVoucherRequest = {
  bookingId: string;
};

// Prebooking Attachment Upload
export const PREBOOKING_ATTACHMENT_UPLOAD = '/api/v1/booking/upload';
export type PrebookingAttachmentUploadRequest = {
  type: 'BOOKING';
};
export type PrebookingAttachmentUploadResponse = {
  url: string;
  isSuccess: boolean;
};

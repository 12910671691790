import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Popup, Text, Token, Button } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

type Props = {
  isVisible: boolean;
  onClose(): void;
};

export default function RequiredDocumentModal(props: Props) {
  const { isVisible, onClose } = props;

  const cr = useContentResource().CorporateRegistration;

  return (
    <Modal isVisible={isVisible}>
      <Popup showCloseButton={false} onCloseButtonPress={onClose} width={600}>
        <View style={styles.container}>
          <Text style={styles.title} variant="headline">
            {cr.requiredDocumentTitle}
          </Text>
          <Text style={styles.bold} ink="secondary">
            {cr.requiredDocumentCc}
          </Text>
          <Text style={styles.content} ink="secondary">
            {cr.requiredDocumentCcContent}
          </Text>
          <Text style={styles.bold} ink="secondary">
            {cr.requiredDocumentInvoice}
          </Text>
          <Text style={styles.content} ink="secondary">
            {cr.requiredDocumentInvoiceContent}
          </Text>
          <Button
            style={styles.button}
            text={cr.requiredDocumentOkButton}
            onPress={onClose}
          />
        </View>
      </Popup>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: Token.spacing.m,
    marginTop: -Token.spacing.xxl,
  },
  title: {
    marginBottom: Token.spacing.m,
  },
  content: {
    marginBottom: Token.spacing.m,
    paddingLeft: Token.spacing.s,
  },
  bold: Token.typography.weightMedium,
  button: {
    width: 285,
    marginHorizontal: 'auto',
  },
});

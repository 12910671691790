import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import WarningIcon from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_system_status_warning-fill_24px.svg';
import { useTheme, Icon, Text, Token } from '@traveloka/web-components';
import { appendTestId } from '../../utils/TestUtil';

type Props = {
  testID?: string;
  text: string;
  style?: StyleProp<ViewStyle>;
  variant?: 'rounded' | 'circle';
};

export default function ComplianceLabel(props: Props) {
  const { variant, testID, text, style } = props;
  const { color } = useTheme();

  const complianceStyle = { backgroundColor: color.lightStain };

  return (
    <View
      testID={appendTestId(testID, 'compliance')}
      style={[
        Style.compliance,
        variant && Style[variant],
        complianceStyle,
        style,
      ]}
    >
      <Icon src={WarningIcon} width={16} height={16} />
      <Text variant="ui-tiny" ink="secondary" style={Style.complianceLabel}>
        {text}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  compliance: {
    flexDirection: 'row',
    paddingHorizontal: Token.spacing.xs,
    paddingVertical: Token.spacing.xxs,
    alignSelf: 'flex-start',
  },
  complianceLabel: {
    marginLeft: Token.spacing.xs,
    flex: 1,
  },
  rounded: {
    borderRadius: Token.border.radius.normal,
  },
  circle: {
    borderRadius: Token.border.radius.rounded,
  },
});

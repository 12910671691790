import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';
import {
  createElement,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewProps,
} from 'react-native';

import Broken from '@traveloka/icon-kit-web/svg/dark/ic_system_img_placeholder_broken_24px.svg';
import ChevronLeft from '@traveloka/icon-kit-web/svg/light/ic_system_chevron_left_16px.svg';
import ChevronRight from '@traveloka/icon-kit-web/svg/light/ic_system_chevron_right_16px.svg';
import Close from '@traveloka/icon-kit-web/svg/light/ic_system_cross_close_24px.svg';
import {
  useTheme,
  Button,
  Card,
  Icon,
  Image,
  Text,
  Token,
} from '@traveloka/web-components';
import { useHoverable, Modal } from '@traveloka/web-components/future';

import Divider from '../../shared/components/Divider/Divider';
import IconLabel from '../IconLabel/IconLabel';

type Props = {
  bed: string;
  bathroomAmenities: string[];
  bathroomAmenitiesLabel: string;
  guest: string;
  images: string[];
  isVisible: boolean;
  noPhotoText: string;
  onClosePress(): void;
  onSeeOptionsPress(): void;
  roomFacilities: string[];
  roomFacilitiesLabel: string;
  roomInfoLabel: string;
  roomName: string;
  roomPrice: string;
  roomPriceLabel: string;
  seeOptionsLabel: string;
  size: string;
  startingFromLabel: string;
};

const PropsContext = createContext<Props>(undefined!);
function useProps() {
  return useContext(PropsContext);
}

const IMAGES_PER_ROW = 4;

export default function RoomDetailModal(props: Props) {
  const { isVisible } = props;

  return (
    <PropsContext.Provider value={props}>
      <Modal isVisible={isVisible}>
        <Card style={Style.card}>
          <RoomImage />
          <RoomInfo />
        </Card>
      </Modal>
    </PropsContext.Provider>
  );
}

function RoomImage() {
  const { noPhotoText, roomName, images, isVisible, onClosePress } = useProps();

  const scrollHeightRef = useRef(0);
  const measureLayout = useCallback((e: LayoutChangeEvent) => {
    scrollHeightRef.current = e.nativeEvent.layout.height;
  }, []);
  const scrollRef = useRef<ScrollView>(null!);
  const thumbnailRefs = useRef<Array<TouchableOpacity | null>>([]);

  const yOffsetRef = useRef(0);
  const handleScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      yOffsetRef.current = e.nativeEvent.contentOffset.y;
    },
    []
  );

  const [currentIndex, setIndex] = useState(0);
  const handleLeft = useCallback(() => {
    setIndex(index => {
      const nextIndex = index - 1;

      if (nextIndex < 0) {
        return images.length - 1;
      }

      return nextIndex;
    });
  }, [images.length]);
  const handleRight = useCallback(() => {
    setIndex(index => {
      const nextIndex = index + 1;

      if (nextIndex > images.length - 1) {
        return 0;
      }

      return nextIndex;
    });
  }, [images.length]);
  const handleUp = useCallback(() => {
    setIndex(index => {
      const nextIndex = index - IMAGES_PER_ROW;

      if (nextIndex >= 0) {
        return nextIndex;
      }

      const numOfRows = Math.ceil(images.length / IMAGES_PER_ROW);
      const fullRows = numOfRows * IMAGES_PER_ROW;
      const tempIndex = fullRows + nextIndex;

      if (tempIndex > images.length - 1) {
        return tempIndex - IMAGES_PER_ROW;
      }

      return tempIndex;
    });
  }, [images.length]);
  const handleDown = useCallback(() => {
    setIndex(index => {
      const nextIndex = index + IMAGES_PER_ROW;

      if (nextIndex > images.length - 1) {
        return nextIndex % IMAGES_PER_ROW;
      }

      return nextIndex;
    });
  }, [images.length]);

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();

      switch (e.key) {
        case 'ArrowLeft':
          handleLeft();
          break;
        case 'ArrowRight':
          handleRight();
          break;
        case 'ArrowUp':
          handleUp();
          break;
        case 'ArrowDown':
          handleDown();
          break;
      }
    },
    [handleLeft, handleRight, handleUp, handleDown]
  );

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('keydown', handleKeydown);

      return () => document.removeEventListener('keydown', handleKeydown);
    }

    return;
  }, [isVisible]);

  useEffect(() => {
    const view = thumbnailRefs.current[currentIndex];

    if (scrollRef.current && view) {
      view.measure((_x, y, _w, h) => {
        if (
          y < yOffsetRef.current ||
          y + h > yOffsetRef.current + scrollHeightRef.current
        ) {
          scrollRef.current.scrollTo({ y });
        }
      });
    }
  }, [currentIndex]);
  const mainImage = images[currentIndex];

  return (
    <View style={Style.image}>
      <View style={Style.header}>
        <Text ink="white">{roomName}</Text>
        <TouchableIcon src={Close} size={24} onPress={onClosePress} />
      </View>
      <View style={Style.main}>
        <TouchableIcon src={ChevronLeft} size={16} onPress={handleLeft} />
        {typeof mainImage === 'undefined' && (
          <View style={Style.noImage}>
            <Icon src={Broken} width={24} height={24} />
            <Text variant="ui-small" ink="secondary" style={Style.imageText}>
              {noPhotoText}
            </Text>
          </View>
        )}
        {typeof mainImage !== 'undefined' && (
          <Image
            variant="rounded"
            objectFit="cover"
            src={mainImage}
            width={565}
            height={325}
          />
        )}
        <TouchableIcon src={ChevronRight} size={16} onPress={handleRight} />
      </View>
      <ScrollView
        onScroll={handleScroll}
        ref={scrollRef}
        style={Style.thumbnailsScroll}
        contentContainerStyle={Style.thumbnails}
        scrollEventThrottle={500}
        onLayout={measureLayout}
      >
        {images.map((image, index) => (
          <Thumbnail
            ref={el => (thumbnailRefs.current[index] = el)}
            key={index}
            src={image}
            isActive={currentIndex === index}
            onPress={() => setIndex(index)}
          />
        ))}
      </ScrollView>
    </View>
  );
}

type TouchableIconProps = {
  onPress(): void;
  size: number;
  src: string;
};

function TouchableIcon(props: TouchableIconProps) {
  const { onPress, size, src } = props;
  const [isHovered, hoverEvent] = useHoverable();

  return (
    <TouchableOpacity activeOpacity={0.5} onPress={onPress}>
      <View
        {...hoverEvent}
        style={[Style.chevron, isHovered && Style.chevronFocused]}
      >
        <Icon src={src} width={size} height={size} />
      </View>
    </TouchableOpacity>
  );
}

type ThumbnailProps = {
  isActive: boolean;
  src: string;
  onPress(): void;
};

const Thumbnail = forwardRef<TouchableOpacity, ThumbnailProps>((props, ref) => {
  const [isHovered, hoverEvent] = useHoverable();
  const { isActive, onPress, src } = props;

  return (
    <TouchableOpacity
      ref={ref}
      activeOpacity={0.5}
      onPress={onPress}
      style={Style.thumbnailTouchable}
      accessible={false}
    >
      <Image
        {...hoverEvent}
        variant="rounded"
        objectFit="cover"
        src={src}
        height={90}
        style={[
          Style.thumbnail,
          isActive && Style.thumbnailActive,
          isHovered && Style.thumbnailFocused,
        ]}
      />
    </TouchableOpacity>
  );
});

function RoomInfo() {
  const {
    bathroomAmenities,
    bathroomAmenitiesLabel,
    bed,
    guest,
    onSeeOptionsPress,
    roomFacilities,
    roomFacilitiesLabel,
    roomInfoLabel,
    roomPrice,
    roomPriceLabel,
    seeOptionsLabel,
    size,
    startingFromLabel,
  } = useProps();

  const { color } = useTheme();
  const infoStyle = {
    backgroundColor: color.lightPrimary,
  };

  return (
    <View style={[Style.info, infoStyle]}>
      <View style={Style.scroll}>
        <Text variant="ui-small" style={Style.sectionTitle}>
          {roomInfoLabel}
        </Text>
        <IconLabel type="bed" text={bed} style={Style.icon} />
        <IconLabel type="size" text={size} style={Style.icon} />
        <IconLabel type="guest" text={guest} />

        {roomFacilities.length > 0 && (
          <>
            <Divider subtle />
            <Text variant="ui-small" style={Style.sectionTitle}>
              {roomFacilitiesLabel}
            </Text>
            <FacilityList facilities={roomFacilities} />
          </>
        )}

        {bathroomAmenities.length > 0 && (
          <>
            <Divider subtle />
            <Text variant="ui-small" style={Style.sectionTitle}>
              {bathroomAmenitiesLabel}
            </Text>
            <FacilityList facilities={bathroomAmenities} />
          </>
        )}
      </View>
      <View style={Style.action}>
        <Text variant="ui-small">{startingFromLabel}</Text>
        <Text variant="ui-large" ink="price" style={Style.price}>
          {roomPrice}
          <Text variant="ui-tiny" ink="secondary">
            {roomPriceLabel}
          </Text>
        </Text>
        <Button
          size="small"
          onPress={onSeeOptionsPress}
          text={seeOptionsLabel}
        />
      </View>
    </View>
  );
}

type FacilityListProps = {
  facilities: string[];
};

function FacilityList(props: FacilityListProps) {
  return (
    <Ul style={Style.facilities}>
      {props.facilities.map((text, index) => (
        <Text
          key={index}
          // @ts-ignore
          accessibilityRole="listitem"
          variant="ui-small"
          style={Style.facility}
        >
          {text}
        </Text>
      ))}
    </Ul>
  );
}

function Ul(props: PropsWithChildren<ViewProps>) {
  return createElement('ul', props);
}

const Style = StyleSheet.create({
  card: {
    width: 945,
    flexDirection: 'row',
    backgroundColor: Token.color.uiDarkPrimary,
    maxHeight: 640,
  },
  image: {
    flex: 1,
    padding: Token.spacing.xs,
  },
  info: {
    width: 300,
  },
  scroll: {
    flex: 1,
    padding: Token.spacing.m,
    overflowY: 'auto' as 'scroll',
  },
  action: {
    ...Token.elevation.hover,
    padding: Token.spacing.m,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: Token.spacing.xl,
  },
  main: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Token.spacing.m,
  },
  thumbnailsScroll: {
    marginHorizontal: Token.spacing.xl,
    flex: 1,
  },
  thumbnails: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  chevron: {
    transitionProperty: 'border-color, opacity',
    transitionDuration: `${Token.animation.timing.instant}ms`,
    opacity: 0.5,
    padding: Token.spacing.xs,
  },
  chevronFocused: {
    opacity: 1,
  },
  thumbnailTouchable: {
    flexBasis: `${100 / IMAGES_PER_ROW}%`,
  },
  thumbnail: {
    padding: Token.border.width.bold,
    transitionProperty: 'opacity, background-color',
    transitionDuration: `${Token.animation.timing.instant}ms`,
    borderRadius: Token.border.radius.normal,
    opacity: 0.5,
    overflow: 'hidden',
  },
  thumbnailActive: {
    backgroundColor: Token.color.uiLightPrimary,
    opacity: 1,
  },
  thumbnailFocused: {
    opacity: 1,
  },
  price: {
    fontWeight: Token.typography.weightMedium.fontWeight,
    marginBottom: Token.spacing.xs,
  },
  facilities: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Token.spacing.m,
    marginRight: -Token.spacing.m,
  },
  facility: {
    width: '50%',
    paddingRight: Token.spacing.m,
    marginBottom: Token.spacing.xs,
    listStyle: 'disc',
    display: 'list-item' as 'flex',
  },
  icon: {
    marginBottom: Token.spacing.xs,
  },
  sectionTitle: {
    marginBottom: Token.spacing.xs,
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  noImage: {
    width: 565,
    height: 325,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Token.color.uiDarkStain,
    borderRadius: Token.border.radius.normal,
  },
  imageText: {
    marginTop: Token.spacing.xs,
  },
});

import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Text, Token } from '@traveloka/web-components';

type Props = {
  children?: ReactNode;
  step: number;
  style: StyleProp<ViewStyle>;
  title: string;
  disabled?: boolean;
};

export default function RegistrationStep(props: Props) {
  const { children, step, style, title, disabled } = props;

  return (
    <View style={[styles.root, style]}>
      <View style={[styles.step, disabled && styles.stepDisabled]}>
        <Text variant="ui-small" ink="white">
          {step}
        </Text>
      </View>
      <View style={styles.info}>
        <Text
          variant="title-1"
          style={styles.title}
          ink={disabled ? 'secondary' : 'primary'}
        >
          {title}
        </Text>
        <View>{children}</View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  step: {
    alignItems: 'center',
    backgroundColor: Token.color.uiBluePrimary,
    borderRadius: Token.border.radius.rounded,
    height: 24,
    justifyContent: 'center',
    width: 24,
  },
  stepDisabled: {
    backgroundColor: Token.color.uiLightSecondary,
  },
  info: {
    flex: 1,
    marginStart: Token.spacing.m,
  },
  title: {
    marginBottom: Token.spacing.m,
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import {
  FlightSearchEmpty,
  FlightSearchResultItem,
  FlightSearchReturnSmartComboHandler,
} from '@traveloka/ctvweb-ui/flight';
import { Token } from '@traveloka/web-components';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import { useProductRequest } from 'approval-system/shared/contexts/ProductRequestContext';
import { useFlightSearchResultContext } from 'flight/search/contexts/FlightSortFilterContext';
import { FlightSearchData } from 'flight/search/utils/flight-search-view-util';

import FlightSearchToolbar from '../FlightSearchToolbar/FlightSearchToolbarContainer';

type Props = {
  scState?: {
    departIsScAndHasNonSc: boolean;
    toSmartComboPage: boolean;
    toNonSmartComboPage: boolean;
    setState: (value: boolean) => void;
    selectedDepart: FlightSearchData;
  };
  isReturnFlight: boolean;
  onSelect: (e: FlightSearchData) => void;
};

function FlightSearchResultList(props: Props) {
  const {
    CorporateFlightSearch,
    CorporateFlightSearchSmartCombo: contentSC,
  } = useContentResource();
  const productRequest = useProductRequest();

  const { scState, isReturnFlight, onSelect } = props;
  const content = {
    priceSuffix: `/${CorporateFlightSearch.pax}`,
    select: CorporateFlightSearch.choose,
    flightDetail: CorporateFlightSearch.flightDetail,
    fareDetail: CorporateFlightSearch.fareDetail,
    noFlights: CorporateFlightSearch.noFlights,
    pleaseModify: CorporateFlightSearch.pleaseModify,
    smartComboTag: contentSC.tag,
    smartComboTooltipTitle: contentSC.tooltipTitle,
    smartComboTooltipDescription: contentSC.tooltipDescription,
  };

  const results = useFlightSearchResultContext();
  const {
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();

  return (
    <>
      <FlightSearchToolbar style={Style.toolbar} />

      {scState?.toSmartComboPage && (
        <FlightSearchReturnSmartComboHandler
          switcherLabel={{
            title: contentSC.toSmartComboTitle,
            description: contentSC.toSmartComboDescription,
            changedPrice: scState.selectedDepart.summary.mainPriceStr,
            continueButton: contentSC.continueButton,
          }}
          onClickHandler={() => scState.setState(true)}
        />
      )}

      {!results.length && (
        <FlightSearchEmpty
          mainText={content.noFlights}
          subText={content.pleaseModify}
        />
      )}

      {results.map((result, index) => (
        <View key={result.flightId} style={Style.searchRow}>
          <FlightSearchResultItem
            testID={`flight.search.item.${index}`}
            flight={result}
            compactLayout={isReturnFlight}
            label={content}
            onSelectResultClick={() =>
              fetchAccountStatus(() => onSelect(result))
            }
            loading={isAccountStatusFetching}
            showButton={!productRequest.enabled || !!productRequest.data}
          />
        </View>
      ))}

      {scState?.departIsScAndHasNonSc && scState?.toNonSmartComboPage && (
        <FlightSearchReturnSmartComboHandler
          switcherLabel={{
            title: contentSC.toNormalFlightTitle,
            description: contentSC.toNormalFlightDescription,
            changedPrice: scState.selectedDepart.isSmartCombo
              ? scState.selectedDepart.nonSmartCombo!.summary.mainPriceStr
              : '-',
            continueButton: contentSC.continueButton,
          }}
          onClickHandler={() => scState.setState(false)}
        />
      )}
    </>
  );
}

const Style = StyleSheet.create({
  toolbar: {
    zIndex: 1,
    marginBottom: Token.spacing.m,
    //@ts-ignore
    position: 'sticky',
    top: 59,
  },
  searchRow: {
    marginBottom: Token.spacing.m,
  },
});

export default FlightSearchResultList;

import { v4 as uuid } from 'uuid';

import SessionStorage from '../utils/SessionStorage';

const VISIT_ID_SESSION_STORAGE_KEY = '$trck$';

export function getVisitId(): string {
  const visitId = SessionStorage.get(VISIT_ID_SESSION_STORAGE_KEY);

  if (visitId) {
    return visitId;
  }

  const newVisitId = uuid();
  SessionStorage.set(VISIT_ID_SESSION_STORAGE_KEY, newVisitId);

  return newVisitId;
}

export function removeVisitId() {
  const visitId = SessionStorage.get(VISIT_ID_SESSION_STORAGE_KEY);

  if (visitId) {
    SessionStorage.remove(VISIT_ID_SESSION_STORAGE_KEY);
  }
}

import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useApi } from '@traveloka/ctv-core';

import {
  RefundEligibilityRequest,
  HotelRefundEligibilityResponse,
  REFUND_ELIGIBILITY_API,
} from 'refund/shared/api';
import {
  HotelMainRefundReasonWithAdditionalInfo,
  HotelReasonsWithAdditionalInfo,
  HotelReasonsWithNoAdditionalInfo,
} from 'refund/shared/api/types';
import ProductType from 'postbooking/shared/constants/ProductType';

type Props = {
  bookingId: string;
};

type State = {
  isFetching: boolean;
  isSubmitting: boolean;
  data: Omit<
    HotelRefundEligibilityResponse['hotelRefundEligibility'],
    'reasons'
  > & {
    reasons: Array<
      HotelReasonsWithAdditionalInfo | HotelReasonsWithNoAdditionalInfo
    >;
  };
};

type Action = {
  setIsSubmitting(isSubmitting: boolean): void;
};

const initialState: State = {
  isFetching: false,
  isSubmitting: false,
  data: undefined!,
};

const HotelRequestRefundContext = createContext<[State, Action]>(undefined!);

export function HotelRequestRefundProvider(props: PropsWithChildren<Props>) {
  const { bookingId, children } = props;
  const [state, setState] = useState<State>({
    ...initialState,
    isFetching: true,
  });

  const refundEligibility = useApi<
    HotelRefundEligibilityResponse,
    RefundEligibilityRequest
  >({
    domain: 'refund',
    method: 'post',
    path: REFUND_ELIGIBILITY_API,
  });

  useEffect(() => {
    setState(prevValue => ({ ...prevValue, isFetching: true }));
    refundEligibility({ bookingId, productType: ProductType.HOTEL })
      .then(res => {
        if (res.success) {
          return {
            isFetching: false,
            isSubmitting: false,
            data: {
              ...res.data.hotelRefundEligibility,
              reasons: res.data.hotelRefundEligibility.reasons.map(reason => {
                if (
                  reason.mainReason ===
                    HotelMainRefundReasonWithAdditionalInfo.DOUBLE_BOOKING ||
                  reason.mainReason ===
                    HotelMainRefundReasonWithAdditionalInfo.OTHERS
                ) {
                  return {
                    mainReason: reason.mainReason,
                    additionalInformation: '',
                  };
                }
                return {
                  mainReason: reason.mainReason,
                  additionalInformation: undefined,
                };
              }),
            },
          };
        }

        return initialState;
      })
      .catch(() => initialState)
      .then(state => setState(state));
  }, []);

  const setIsSubmitting = useCallback((isSubmitting: boolean) => {
    setState(prevValue => ({ ...prevValue, isSubmitting }));
  }, []);

  const providerValue: [State, Action] = useMemo(
    () => [state, { setIsSubmitting }],
    [state, setIsSubmitting]
  );

  return (
    <HotelRequestRefundContext.Provider value={providerValue}>
      {children}
    </HotelRequestRefundContext.Provider>
  );
}

export function useHotelRequestRefund() {
  return useContext(HotelRequestRefundContext);
}

import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const BookingList: PrivatePageComponent = function() {
  return null;
};

BookingList.routeProtection = function() {
  return { path: '/booking' };
};

export default BookingList;

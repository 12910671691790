import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import PlusIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_button_add_24px.svg';
import { useTheme, Icon, Text, Token } from '@traveloka/web-components';

type Props = {
  text: string;
  onPress?(): void;
};

export default function AddCardButton(props: Props) {
  const { text, onPress } = props;

  const { color } = useTheme();
  const rootStyle = {
    borderColor: color.borderDivide,
  };

  return (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={onPress}
      style={[Style.root, rootStyle]}
    >
      <Icon src={PlusIcon} width={24} height={24} />
      <Text ink="primary-interactive" style={Style.text}>
        {text}
      </Text>
    </TouchableOpacity>
  );
}

const Style = StyleSheet.create({
  root: {
    alignItems: 'center',
    borderRadius: Token.border.radius.normal,
    borderWidth: Token.border.width.thick,
    justifyContent: 'center',
    minHeight: 120,
    width: 248,
  },
  text: {
    marginTop: Token.spacing.m,
  },
});

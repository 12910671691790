import { datadogRum } from '@datadog/browser-rum';
import { differenceInCalendarDays, parse } from 'date-fns';
import { uniqBy } from 'lodash';

import { useLocalizedDateFormat } from '@traveloka/ctv-core';
import { AdditionalTransitInfo } from '@traveloka/ctvweb-ui/src/flight/types';

import {
  getAirportName,
  getCityWithAirportCode,
} from 'flight/search/utils/flight-static-data-util';
import {
  FareInfo as APIFareInfo,
  Compliance,
  FlightSearchResult,
  Journey,
  PaxFareDetail,
  Segment,
  SmartComboSearchResult,
} from 'flight/shared/api/types';
import RefundableStatus from 'flight/shared/constants/refundable-status';
import SeatClass from 'flight/shared/constants/seat-class';
import { CurrencyValue, convert } from 'shared/utils/currency';
import { JAVA_DATE, TIME } from 'shared/utils/date';
import { formatCurrency, formatMessage } from 'shared/utils/intl';

import { ComputedSearchSpec } from '../contexts/SearchSpecContext';
import { Airline, Airport, StaticData } from '../types';

export type FlightSearchData = NormalFlightSearchData | SmartComboSearchData;

export type NormalFlightSearchData = {
  flightId: string;
  compliance: Nullable<ComplianceInfo>;
  summary: SummaryData;
  segments: SegmentData[];
  journeys: JourneyData[];
  isSmartCombo: false;
};

export type SmartComboSearchData = {
  flightId: string;
  compliance: Nullable<ComplianceInfo>;
  summary: SummaryData;
  segments: SegmentData[];
  journeys: JourneyData[];
  nonSmartCombo: Nullable<NormalFlightSearchData>;
  isSmartCombo: true;
};

type FlightSearchCr = {
  CorporateFlightGeneral: {
    baggage: string;
    baggagePiece: string;
    direct: string;
    extraDay: string;
    inFlightMeal: string;
    multiAirline: string;
    operatedBy: string;
    stopOverInfo: string;
    transitDifferentAirport: string;
    transitMayNeedReCheckInInfo: string;
    transitStopInfo: string;
    transitMayNeedReCheckIn1: string;
    transitMayNeedReCheckIn2: string;
    transitVisaRequiredInfo: string;
    transitVisaRequired1: string;
    transitInfo: string;
    transitPlural: string;
    adultFare: string;
    childFare: string;
    infantFare: string;
    vat: string;
    psc: string;
    fuelSurcharge: string;
    adminFee: string;
    additionalFee: string;
    save: string;
    regularTotalPrice: string;
    totalPrice: string;
  } & DurationCr;
  CorporateFlightSearch: {
    baggagePartial: string;
    baggagePaidPartial: string;
    baggagePaid: string;
    inFlightMealPartial: string;
    nonCompliant: string;
  };
  CorporateFlightSeatClass: {
    [key in SeatClass]: string;
  };
  CorporateFlightRefundableStatus: {
    [key in RefundableStatus]: string;
  };
};

export function useCreateFlights() {
  const createSummary = useCreateSummary();
  const createSegments = useCreateSegments();

  return (
    searchSpec: ComputedSearchSpec,
    searchResults: FlightSearchResult[],
    staticData: StaticData,
    cr: FlightSearchCr
  ): NormalFlightSearchData[] => {
    return searchResults
      .map<NormalFlightSearchData>(result => ({
        flightId: result.flightId,
        compliance: createCompliance(result.complying, cr),
        summary: createSummary(searchSpec, result.journeys, staticData, cr),
        segments: createSegments(
          flattenSegments(result.journeys),
          staticData,
          cr
        ),
        journeys: createJourneys(
          searchSpec,
          result.journeys,
          staticData,
          cr,
          createSegments
        ),
        nonSmartCombo: null,
        isSmartCombo: false,
      }))
      .filter(flight => {
        if (!flight.summary.airlineCode) {
          // #REF-1
          // Static data might not be completed and searchResult might have asked the data
          console.error(
            'useCreateFlights - Invalid airline mapping',
            flight.summary.airlineBrand
          );
          datadogRum.addError({
            error: new Error(
              'useCreateFlights - Invalid airline mapping: ' +
                flight.summary.airlineBrand
            ),
          });
        }
        return flight.summary.airlineCode;
      });
  };
}

export function useCreateSmartComboFlights() {
  const createSummary = useCreateSummary();
  const createFlights = useCreateFlights();
  const createSegments = useCreateSegments();

  return (
    searchSpec: ComputedSearchSpec,
    searchResults: SmartComboSearchResult[],
    intersectDepartureResults: Nullable<FlightSearchResult[]>,
    staticData: StaticData,
    cr: FlightSearchCr
  ): SmartComboSearchData[] => {
    return searchResults.map((result, index) => ({
      flightId: index.toString(),
      compliance: createCompliance(result.complying, cr),
      summary: createSummary(searchSpec, result.journeys, staticData, cr),
      segments: createSegments(
        flattenSegments(result.journeys),
        staticData,
        cr
      ),
      journeys: createJourneys(
        searchSpec,
        result.journeys,
        staticData,
        cr,
        createSegments
      ),
      nonSmartCombo: intersectDepartureResults
        ? createFlights(
            searchSpec,
            createIntersectNonSC(result.journeys, intersectDepartureResults),
            staticData,
            cr
          )[0]
        : null,
      isSmartCombo: true,
    }));
  };
}

type ComplianceInfo = {
  reason: string;
  label: string;
};

function createCompliance(
  complying: Nullable<Compliance>,
  cr: FlightSearchCr
): Nullable<ComplianceInfo> {
  if (!complying || complying.isComplying) {
    return null;
  }

  const { CorporateFlightSearch } = cr;

  return {
    reason: complying.errorMessage,
    label: CorporateFlightSearch.nonCompliant,
  };
}

export type SummaryData = {
  airlineLogos: AirlineLogo[];
  airlineName: string;
  airlineCode: string;
  airlineBrand: string;
  isMultiClass: boolean;
  seatClass: string;
  departureDateTime: Date;
  departureDate: string;
  departureTime: string;
  departureAirport: Airport;
  departureLocation: string;
  arrivalDateTime: Date;
  arrivalDate: string;
  arrivalTime: string;
  arrivalAirport: Airport;
  arrivalLocation: string;
  offset: number;
  offsetStr: Nullable<string>;
  duration: number;
  durationStr: string;
  transit: number;
  transitStr: string;
  mainPrice: CurrencyValue;
  mainPriceStr: string;
  originPrice: Nullable<CurrencyValue>;
  originPriceStr: Nullable<string>;
  totalPriceLabel: string;
  totalPrice: CurrencyValue;
  totalPriceStr: string;
  facilities: Facility[];
};

function useCreateSummary() {
  const { format } = useLocalizedDateFormat();
  return (
    searchSpec: ComputedSearchSpec,
    journeys: Journey[],
    staticData: StaticData,
    cr: FlightSearchCr
  ): SummaryData => {
    const segments = flattenSegments(journeys);
    const { CorporateFlightGeneral } = cr;
    const {
      0: departureSegment,
      [segments.length - 1]: arrivalSegment,
    } = segments;
    const {
      airportCode: departureAirportCode,
      departureDate: departureDateJava,
      departureTime,
    } = departureSegment.departureDetail;
    const {
      airportCode: arrivalAirportCode,
      arrivalDate: arrivalDateJava,
      arrivalTime,
    } = arrivalSegment.arrivalDetail;

    // Journey Summary
    const multiplier = {
      adults:
        searchSpec.passengers.length + searchSpec.nonEmployeeTravelers.adults,
      children: searchSpec.nonEmployeeTravelers.children,
      infants: searchSpec.nonEmployeeTravelers.infants,
    };
    const {
      duration,
      currency,
      decimalPoints,
      ...journeySummary
    } = createJourneySummary(journeys, cr);
    const totalPrice = {
      currency,
      amount: 0,
      decimalPoints,
    };
    const totalMainPrice = {
      currency,
      amount: journeySummary.adults.mainPrice,
      decimalPoints,
    };
    const totalOriginPrice = {
      currency,
      amount: journeySummary.adults.originPrice ?? 0,
      decimalPoints,
    };
    (Object.keys(journeySummary) as Array<keyof typeof journeySummary>).forEach(
      key => {
        const { mainPrice } = journeySummary[key];

        totalPrice.amount += mainPrice * multiplier[key];
      }
    );

    const totalPriceLabel = cr.CorporateFlightGeneral.totalPrice;
    const totalPriceStr = formatCurrency(totalPrice);
    const durationStr = printDuration(duration, cr.CorporateFlightGeneral);
    const mainPriceStr = formatCurrency(totalMainPrice);
    const originPriceStr = totalOriginPrice.amount
      ? formatCurrency(totalOriginPrice)
      : null;

    // Segment Summary
    const {
      airlineLogos,
      airlineName,
      airlineCode,
      airlineBrand,
      isMultiClass,
      seatClass,
    } = createSegmentSummary(segments, staticData, cr);

    // Airport
    const departureAirport = staticData.airportMap[departureAirportCode]!;
    const arrivalAirport = staticData.airportMap[arrivalAirportCode]!;
    const departureLocation = getCityWithAirportCode(
      departureAirportCode,
      staticData
    );
    const arrivalLocation = getCityWithAirportCode(
      arrivalAirportCode,
      staticData
    );

    // DateTime
    const departureDateTime = makeDateTime(departureDateJava, departureTime);
    const departureDate = format(departureDateTime, 'FULL_WEEKDAY_MONTH');
    const arrivalDateTime = makeDateTime(arrivalDateJava, arrivalTime);
    const arrivalDate = format(arrivalDateTime, 'FULL_WEEKDAY_MONTH');
    const offset = differenceInCalendarDays(arrivalDateTime, departureDateTime);
    const offsetStr =
      offset > 0
        ? formatMessage(CorporateFlightGeneral.extraDay, { d: offset })
        : null;

    // Transit
    const transit =
      segments.length + segments.filter(segment => segment.stopInfo).length - 1;
    const transitStr = transit
      ? formatMessage(CorporateFlightGeneral.transitPlural, { num: transit })
      : CorporateFlightGeneral.direct;

    // Others
    const facilities = createFacilitiesSummary(segments, cr);

    return {
      airlineLogos,
      airlineName,
      airlineCode,
      airlineBrand,
      isMultiClass,
      seatClass,
      departureDateTime,
      departureDate,
      departureTime,
      departureAirport,
      departureLocation,
      arrivalDateTime,
      arrivalDate,
      arrivalTime,
      arrivalAirport,
      arrivalLocation,
      offset,
      offsetStr,
      duration,
      durationStr,
      transit,
      transitStr,
      mainPrice: totalMainPrice,
      mainPriceStr,
      originPrice: totalOriginPrice.amount ? totalOriginPrice : null,
      originPriceStr,
      totalPriceLabel,
      totalPrice,
      totalPriceStr,
      facilities,
    };
  };
}

type SegmentData = {
  airlineCode: string;
  marketingAirlineCode: string;
  airlineLogo: AirlineLogo;
  flightName: string;
  operatedBy: Nullable<string>;
  seatClass: string;
  departureAirport: Airport;
  departureDateTime: Date;
  departureDate: string;
  departureTime: string;
  departureLocation: string;
  departureAirportName: string;
  arrivalAirport: Airport;
  arrivalDateTime: Date;
  arrivalDate: string;
  arrivalTime: string;
  arrivalLocation: string;
  arrivalAirportName: string;
  duration: number;
  durationStr: string;
  offset: number;
  facilities: Facility[];
  stopOver: Nullable<StopOverInfo>;
  transit: Nullable<TransitInfo>;
  changeAirportCode: Nullable<string>;
  additionalTransitInfo: AdditionalTransitInfo[];
};

function useCreateSegments() {
  const { format } = useLocalizedDateFormat();
  return (
    segments: Segment[],
    staticData: StaticData,
    cr: FlightSearchCr,
    segmentFromPrevJourney?: Segment
  ): SegmentData[] => {
    return segments
      .map((segment, index, segments): SegmentData | false => {
        try {
          const { CorporateFlightGeneral, CorporateFlightSeatClass } = cr;

          const {
            arrivalDetail,
            marketingAirline: marketingAirlineCode,
            brandAirline: brandAirlineCode,
            departureDetail,
            flightCode,
            flightDurationInMinutes,
            operatingAirline: operatingAirlineCode,
            seatClass: seatClassEnum,
            stopInfo,
            addOns,
            mayNeedReCheckIn,
            visaRequired,
          } = segment;

          const {
            airportCode: departureAirportCode,
            departureDate: departureDateJava,
            departureTime,
          } = departureDetail;
          const {
            airportCode: arrivalAirportCode,
            arrivalDate: arrivalDateJava,
            arrivalTime,
          } = arrivalDetail;

          // Airline
          // #REF-1 - Adding try catch for this line
          const { airlineCode, airlineName, logoUrl } = staticData.airlineMap[
            brandAirlineCode
          ]!;
          const airlineLogo = {
            src: logoUrl,
            alt: airlineName,
          };
          const flightName = `${airlineName} ${flightCode}`;
          const operatingAirline =
            operatingAirlineCode !== brandAirlineCode
              ? staticData.airlineMap[operatingAirlineCode]!
              : null;
          const operatedBy =
            operatingAirline &&
            formatMessage(CorporateFlightGeneral.operatedBy, {
              by: operatingAirline.airlineName,
            });

          // Seat Class
          const seatClass = CorporateFlightSeatClass[seatClassEnum];

          // Date + Time
          const departureDateTime = makeDateTime(
            departureDateJava,
            departureTime
          );
          const arrivalDateTime = makeDateTime(arrivalDateJava, arrivalTime);
          const departureDate = format(departureDateTime, 'SHORT_MONTH');
          const arrivalDate = format(arrivalDateTime, 'SHORT_MONTH');

          // Airport
          const departureAirport = staticData.airportMap[departureAirportCode]!;
          const arrivalAirport = staticData.airportMap[arrivalAirportCode]!;
          const departureLocation = getCityWithAirportCode(
            departureAirportCode,
            staticData
          );
          const departureAirportName = getAirportName(
            departureAirportCode,
            staticData
          );
          const arrivalLocation = getCityWithAirportCode(
            arrivalAirportCode,
            staticData
          );
          const arrivalAirportName = getAirportName(
            arrivalAirportCode,
            staticData
          );

          // Duration
          const duration = Number(flightDurationInMinutes);
          const durationStr = printDuration(duration, CorporateFlightGeneral);
          const offset = differenceInCalendarDays(
            arrivalDateTime,
            departureDateTime
          );

          // Others
          const prevSegment = (segments[index - 1] ||
            segmentFromPrevJourney) as Segment | undefined;
          const stopOver = createStopOverInfo(stopInfo, staticData, cr);
          const transit = createTransitInfo(prevSegment, segment);
          const facilities = createFacilities(addOns, cr);
          const changeAirportCode =
            prevSegment &&
            prevSegment.arrivalDetail.airportCode !== departureAirportCode
              ? prevSegment.arrivalDetail.airportCode
              : null;

          // Additional Transit Info
          const additionalTransitInfo: AdditionalTransitInfo[] = [];

          if (transit) {
            if (changeAirportCode) {
              additionalTransitInfo.push({
                type: 'text',
                content: formatMessage(CorporateFlightGeneral.transitInfo, {
                  duration: printDuration(
                    transit.duration,
                    CorporateFlightGeneral
                  ),
                  location: getCityWithAirportCode(
                    transit.airportCode,
                    staticData
                  ),
                }),
              });

              additionalTransitInfo.push({
                type: 'divide',
                content: CorporateFlightGeneral.transitStopInfo,
              });

              additionalTransitInfo.push({
                type: 'list',
                content: CorporateFlightGeneral.transitDifferentAirport,
              });
            }

            if (mayNeedReCheckIn) {
              if (additionalTransitInfo.length === 0) {
                additionalTransitInfo.push({
                  type: 'text',
                  content: formatMessage(
                    CorporateFlightGeneral.transitMayNeedReCheckInInfo,
                    {
                      duration: printDuration(
                        transit.duration,
                        CorporateFlightGeneral
                      ),
                      location: getCityWithAirportCode(
                        transit.airportCode,
                        staticData
                      ),
                    }
                  ),
                });
              }

              if (additionalTransitInfo.length === 1) {
                additionalTransitInfo.push({
                  type: 'divide',
                  content: CorporateFlightGeneral.transitStopInfo,
                });
              }

              additionalTransitInfo.push({
                type: 'list',
                content: CorporateFlightGeneral.transitMayNeedReCheckIn1,
              });
              additionalTransitInfo.push({
                type: 'list',
                content: CorporateFlightGeneral.transitMayNeedReCheckIn2,
              });
            }

            if (visaRequired) {
              if (additionalTransitInfo.length === 0) {
                additionalTransitInfo.push({
                  type: 'text',
                  content: formatMessage(
                    CorporateFlightGeneral.transitVisaRequiredInfo,
                    {
                      duration: printDuration(
                        transit.duration,
                        CorporateFlightGeneral
                      ),
                      location: getCityWithAirportCode(
                        transit.airportCode,
                        staticData
                      ),
                    }
                  ),
                });
              }
              if (additionalTransitInfo.length === 1) {
                additionalTransitInfo.push({
                  type: 'divide',
                  content: CorporateFlightGeneral.transitStopInfo,
                });
              }

              additionalTransitInfo.push({
                type: 'list',
                content: CorporateFlightGeneral.transitVisaRequired1,
              });
            }

            if (additionalTransitInfo.length === 0) {
              additionalTransitInfo.push({
                type: 'text',
                content: formatMessage(CorporateFlightGeneral.transitInfo, {
                  duration: printDuration(
                    transit.duration,
                    CorporateFlightGeneral
                  ),
                  location: getCityWithAirportCode(
                    transit.airportCode,
                    staticData
                  ),
                }),
              });
            }
          }

          return {
            airlineCode,
            marketingAirlineCode,
            airlineLogo,
            flightName,
            operatedBy,
            seatClass,
            departureAirport,
            departureDateTime,
            departureDate,
            departureTime,
            departureLocation,
            departureAirportName,
            arrivalAirport,
            arrivalDateTime,
            arrivalDate,
            arrivalTime,
            arrivalLocation,
            arrivalAirportName,
            duration,
            durationStr,
            offset,
            facilities,
            stopOver,
            transit,
            changeAirportCode,
            additionalTransitInfo,
          };
        } catch (e) {
          // #REF-1
          const { brandAirline } = segments[0];
          console.error(
            'useCreateSegments - Invalid airline mapping',
            brandAirline
          );
          datadogRum.addError({
            error: new Error(
              'useCreateSegments - Invalid airline mapping: ' + brandAirline
            ),
          });
          return false;
        }
      })
      .filter(flight => flight) as SegmentData[];
  };
}

type JourneyData = {
  airlineLogos: AirlineLogo[];
  airlineName: string;
  route: string;
  isMultiClass: boolean;
  seatClass: string;
  refund: Nullable<RefundableInfo>;
  fares: FareInfo[];
  fareInfo: APIFareInfo;
  segments: SegmentData[];
};

function createJourneys(
  searchSpec: ComputedSearchSpec,
  journeys: Journey[],
  staticData: StaticData,
  cr: FlightSearchCr,
  createSegments: (
    segments: Segment[],
    staticData: StaticData,
    cr: FlightSearchCr,
    segmentFromPrevJourney?: Segment
  ) => SegmentData[]
): JourneyData[] {
  return journeys.map((journey, journeyIndex) => {
    const {
      segments,
      departureDetail,
      arrivalDetail,
      refundableStatus,
      fareInfo,
    } = journey;
    const { airportCode: departureAirportCode } = departureDetail;
    const { airportCode: arrivalAirportCode } = arrivalDetail;

    // Segment Summary
    const {
      airlineLogos,
      airlineName,
      isMultiClass,
      seatClass,
    } = createSegmentSummary(segments, staticData, cr);

    // Others
    const route = [
      getCityWithAirportCode(departureAirportCode, staticData),
      getCityWithAirportCode(arrivalAirportCode, staticData),
    ].join(' - ');
    const refund = createRefundableInfo(refundableStatus, cr);
    const fares = createFares(searchSpec, journey, cr);

    const segmentsFromPrevJourney =
      journeyIndex > 0 && journeys[journeyIndex - 1].segments;
    let segmentFromPrevJourney = undefined;
    if (segmentsFromPrevJourney) {
      segmentFromPrevJourney =
        segmentsFromPrevJourney[segmentsFromPrevJourney.length - 1];
    }

    return {
      airlineLogos,
      airlineName,
      route,
      isMultiClass,
      seatClass,
      refund,
      fares,
      fareInfo,
      segments: createSegments(
        segments,
        staticData,
        cr,
        segmentFromPrevJourney
      ),
    };
  });
}

function createIntersectNonSC(
  journeys: Journey[],
  intersectDepartureResults: FlightSearchResult[]
) {
  const scKeySegments = getTripIdentifier(journeys);
  return intersectDepartureResults.filter(
    result => scKeySegments === getTripIdentifier(result.journeys)
  );
}

export function getTripIdentifier(journeys: Journey[]) {
  let key = '';
  journeys.forEach(journey =>
    journey.segments.forEach(segment => (key += concatSegmentDetail(segment)))
  );
  return key;
}

export function concatSegmentDetail(segment: Segment) {
  return (
    segment.flightCode +
    segment.departureDetail.departureDate +
    segment.departureDetail.departureTime +
    segment.arrivalDetail.arrivalDate +
    segment.arrivalDetail.arrivalTime
  );
}

type FareInfo = {
  color: 'positive' | 'primary' | 'secondary';
  label: string;
  value: string;
};

// Component
function createFares(
  searchSpec: ComputedSearchSpec,
  journey: Journey,
  cr: FlightSearchCr
): FareInfo[] {
  const { airlineFare, partnerFare } = journey.fareInfo;
  const { CorporateFlightGeneral } = cr;

  const multiplier = {
    adults:
      searchSpec.passengers.length + searchSpec.nonEmployeeTravelers.adults,
    children: searchSpec.nonEmployeeTravelers.children,
    infants: searchSpec.nonEmployeeTravelers.infants,
  };
  const airlineTotalFare = {
    adults: convert(airlineFare.adultFare.totalFareWithCurrency),
    children: airlineFare.childFare
      ? convert(airlineFare.childFare.totalFareWithCurrency)
      : { amount: 0 },
    infants: airlineFare.infantFare
      ? convert(airlineFare.infantFare.totalFareWithCurrency)
      : { amount: 0 },
  };
  const partnerTotalFare = {
    adults: convert(partnerFare.adultFare.totalFareWithCurrency),
    children: partnerFare.childFare
      ? convert(partnerFare.childFare.totalFareWithCurrency)
      : { amount: 0 },
    infants: partnerFare.infantFare
      ? convert(partnerFare.infantFare.totalFareWithCurrency)
      : { amount: 0 },
  };
  const saving = {
    adults: airlineTotalFare.adults.amount - partnerTotalFare.adults.amount,
    children:
      airlineTotalFare.children.amount - partnerTotalFare.children.amount,
    infants: airlineTotalFare.infants.amount - partnerTotalFare.infants.amount,
  };
  const fareInfo = {
    adults: saving.adults > 0 ? partnerFare.adultFare : airlineFare.adultFare,
    children:
      saving.children > 0 ? partnerFare.childFare : airlineFare.childFare,
    infants:
      saving.infants > 0 ? partnerFare.infantFare : airlineFare.infantFare,
  };

  const fareInfos = Object.keys(fareInfo.adults) as Array<
    keyof typeof fareInfo.adults
  >;
  const fareKey = Object.keys(fareInfo) as Array<keyof typeof fareInfo>;

  const totalAmount: Record<
    keyof Omit<PaxFareDetail, 'baseFareWithCurrency'>,
    number
  > = {
    additionalFeeWithCurrency: 0,
    adminFeeWithCurrency: 0,
    fuelSurchargeWithCurrency: 0,
    pscWithCurrency: 0,
    totalFareWithCurrency: 0,
    vatWithCurrency: 0,
  };
  const results: FareInfo[] = [];
  fareKey.forEach(key => {
    fareInfos
      .filter(type => {
        const fare = fareInfo[key]?.[type];

        return fare && Number(fare.displayAmount) > 0;
      })
      .forEach(type => {
        const rawFare = fareInfo[key]?.[type]!;
        const fare = convert(rawFare);

        if (type === 'baseFareWithCurrency') {
          let content = CorporateFlightGeneral.adultFare;
          if (key === 'children') {
            content = CorporateFlightGeneral.childFare;
          } else if (key === 'infants') {
            content = CorporateFlightGeneral.infantFare;
          }
          results.push({
            color: 'primary',
            label: `${content} (x${multiplier[key]})`,
            value: formatCurrency({
              ...fare,
              amount: fare.amount * multiplier[key],
            }),
          });
        } else {
          totalAmount[type] += fare.amount * multiplier[key];
        }
      });
  });

  fareInfos.forEach(type => {
    if (
      type !== 'baseFareWithCurrency' &&
      type !== 'totalFareWithCurrency' &&
      totalAmount[type] > 0
    ) {
      const rawFare = fareInfo.adults[type]!;
      const fare = convert(rawFare);

      results.push({
        color: 'secondary',
        label: fareSwitcher(type, cr),
        value: formatCurrency({
          ...fare,
          amount: totalAmount[type],
        }),
      });
    }
  });

  const totalSaving =
    saving.adults * multiplier.adults +
    saving.children * multiplier.children +
    saving.infants * multiplier.infants;
  if (totalSaving > 0) {
    // append regular Total Fare and Save Fare
    results.push(
      {
        color: 'secondary',
        label: CorporateFlightGeneral.regularTotalPrice,
        value: formatCurrency({
          ...airlineTotalFare.adults,
          amount:
            airlineTotalFare.adults.amount * multiplier.adults +
            airlineTotalFare.children.amount * multiplier.children +
            airlineTotalFare.infants.amount * multiplier.infants,
        }),
      },
      {
        color: 'positive',
        label: CorporateFlightGeneral.save,
        value: formatCurrency({
          ...partnerTotalFare.adults,
          amount: -totalSaving,
        }),
      }
    );
  }

  return results;
}

function fareSwitcher(
  type: keyof Omit<
    PaxFareDetail,
    'baseFareWithCurrency' | 'totalFareWithCurrency'
  >,
  cr: FlightSearchCr
) {
  const { CorporateFlightGeneral } = cr;

  switch (type) {
    case 'vatWithCurrency':
      return CorporateFlightGeneral.vat;
    case 'pscWithCurrency':
      return CorporateFlightGeneral.psc;
    case 'fuelSurchargeWithCurrency':
      return CorporateFlightGeneral.fuelSurcharge;
    case 'adminFeeWithCurrency':
      return CorporateFlightGeneral.adminFee;
    case 'additionalFeeWithCurrency':
      return CorporateFlightGeneral.additionalFee;
  }
}

type StopOverInfo = {
  airportCode: string;
  airportName: string;
  duration: number;
  description: string;
};

function createStopOverInfo(
  stopInfo: Segment['stopInfo'],
  staticData: StaticData,
  cr: FlightSearchCr
): Nullable<StopOverInfo> {
  if (!stopInfo) {
    return null;
  }

  return {
    airportCode: stopInfo.airportCode,
    airportName: getAirportName(stopInfo.airportCode, staticData),
    duration: Number(stopInfo.durationMinutes),
    description: formatMessage(cr.CorporateFlightGeneral.stopOverInfo, {
      location: getCityWithAirportCode(stopInfo.airportCode, staticData),
      duration: printDuration(
        Number(stopInfo.durationMinutes),
        cr.CorporateFlightGeneral
      ),
    }),
  };
}

type TransitInfo = {
  airportCode: string;
  duration: number;
};

function createTransitInfo(
  prevSegment: Segment | undefined,
  segment: Segment
): Nullable<TransitInfo> {
  if (!prevSegment) {
    return null;
  }

  return {
    airportCode: prevSegment.arrivalDetail.airportCode,
    duration: Number(segment.transitDurationInMinutes),
  };
}

type GeneralFacility = {
  type: 'baggage_paid' | 'meal';
  description: string;
};

type BaggageFacility = {
  type: 'baggage';
  description: string;
  weight: number;
  quantity: number;
};

type Facility = GeneralFacility | BaggageFacility;

function createFacilities(
  addOns: Segment['addOns'],
  cr: FlightSearchCr
): Facility[] {
  const { CorporateFlightGeneral, CorporateFlightSearch } = cr;
  const facilities: Facility[] = [];

  // Both addOns is probably an empty array, so index 0 will be undefined
  const [baggageOption] = addOns.baggageOptions;
  const [mealOption] = addOns.mealOptions;

  if (baggageOption) {
    if (Number(baggageOption.baggageWeight) === 0) {
      facilities.push({
        type: 'baggage_paid',
        description: CorporateFlightSearch.baggagePaid,
      });
    } else {
      const { baggageWeight, baggageQuantity, baggageType } = baggageOption;

      const weight = Number(baggageWeight);
      const quantity = Number(baggageQuantity);

      facilities.push({
        type: 'baggage',
        description:
          baggageType === 'PIECE'
            ? formatMessage(CorporateFlightGeneral.baggagePiece, {
                weight,
                quantity,
              })
            : formatMessage(CorporateFlightGeneral.baggage, {
                num: weight,
              }),
        weight,
        quantity,
      });
    }
  }

  if (mealOption && Number(mealOption.quantity) > 0) {
    facilities.push({
      type: 'meal',
      description: CorporateFlightGeneral.inFlightMeal,
    });
  }

  return facilities;
}

function createFacilitiesSummary(
  segments: Segment[],
  cr: FlightSearchCr
): Facility[] {
  const { CorporateFlightSearch } = cr;
  const facilities = segments.reduce((prev, segment) => {
    return prev.concat(createFacilities(segment.addOns, cr));
  }, [] as Facility[]);

  const results: Facility[] = [];

  const freeBaggages = facilities.filter(
    facility => facility.type === 'baggage'
  ) as BaggageFacility[];

  if (freeBaggages.length > 0) {
    const minimum = freeBaggages.reduce((prev, current) => {
      const { quantity, weight } = current;

      // Total baggage weight is calculated by multiplying quantity and weight
      const totalWeight = quantity * weight;
      const prevTotalWeight = prev.quantity * prev.weight;

      // If current total weight is less than previous total weight, display current baggage
      if (totalWeight < prevTotalWeight) {
        return current;
      }

      // If current total weight is the same as previous total weight,
      // Display current baggage if quantity is less than previous baggage quantity
      else if (totalWeight === prevTotalWeight && quantity < prev.quantity) {
        return current;
      }

      return prev;
    }, freeBaggages[0]);

    results.push({
      ...minimum,
      description:
        segments.length > 1
          ? formatMessage(CorporateFlightSearch.baggagePartial, {
              num: freeBaggages.length,
              of: segments.length,
            })
          : minimum.description,
    });
  } else {
    const paidBaggages = facilities.filter(
      facility => facility.type === 'baggage_paid'
    );

    if (paidBaggages.length > 0) {
      const [paidBaggage] = paidBaggages;
      results.push({
        ...paidBaggage,
        description:
          segments.length > 1
            ? formatMessage(CorporateFlightSearch.baggagePaidPartial, {
                num: paidBaggages.length,
                of: segments.length,
              })
            : paidBaggage.description,
      });
    }
  }

  const freeMeals = facilities.filter(facility => facility.type === 'meal');
  if (freeMeals.length > 0) {
    const [freeMeal] = freeMeals;

    results.push({
      ...freeMeal,
      description:
        segments.length > 1
          ? formatMessage(CorporateFlightSearch.inFlightMealPartial, {
              num: freeMeals.length,
              of: segments.length,
            })
          : freeMeal.description,
    });
  }

  return results;
}

type RefundableInfo = {
  isAvailable: boolean;
  decription: string;
};

function createRefundableInfo(
  refundableStatus: RefundableStatus,
  cr: FlightSearchCr
): Nullable<RefundableInfo> {
  if (refundableStatus === RefundableStatus.UNKNOWN) {
    return null;
  }

  return {
    isAvailable: refundableStatus === RefundableStatus.REFUNDABLE,
    decription: cr.CorporateFlightRefundableStatus[refundableStatus],
  };
}

// Summary
type AirlineLogo = {
  src: string;
  alt: string;
};

function createJourneySummary(journeys: Journey[], cr: FlightSearchCr) {
  const {
    duration,
    currency,
    decimalPoints,
    adults,
    children,
    infants,
  } = journeys.reduce(
    (obj, journey) => {
      obj.duration += Number(journey.journeyDuration);
      obj.currency =
        journey.fareInfo.partnerFare.adultFare.totalFareWithCurrency.currency;
      obj.decimalPoints = Number(
        journey.fareInfo.airlineFare.adultFare.totalFareWithCurrency
          .decimalPoints
      );

      obj.adults.partnerFareAmount += Number(
        journey.fareInfo.partnerFare.adultFare.totalFareWithCurrency
          .displayAmount
      );
      obj.adults.airlineFareAmount += Number(
        journey.fareInfo.airlineFare.adultFare.totalFareWithCurrency
          .displayAmount
      );

      if (
        journey.fareInfo.partnerFare.childFare &&
        journey.fareInfo.airlineFare.childFare
      ) {
        obj.children.partnerFareAmount += Number(
          journey.fareInfo.partnerFare.childFare.totalFareWithCurrency
            .displayAmount
        );
        obj.children.airlineFareAmount += Number(
          journey.fareInfo.airlineFare.childFare.totalFareWithCurrency
            .displayAmount
        );
      }

      if (
        journey.fareInfo.partnerFare.infantFare &&
        journey.fareInfo.airlineFare.infantFare
      ) {
        obj.infants.partnerFareAmount += Number(
          journey.fareInfo.partnerFare.infantFare.totalFareWithCurrency
            .displayAmount
        );
        obj.infants.airlineFareAmount += Number(
          journey.fareInfo.airlineFare.infantFare.totalFareWithCurrency
            .displayAmount
        );
      }
      return obj;
    },
    {
      duration: 0,
      currency: '',
      decimalPoints: 0,
      adults: {
        partnerFareAmount: 0,
        airlineFareAmount: 0,
      },
      children: {
        partnerFareAmount: 0,
        airlineFareAmount: 0,
      },
      infants: {
        partnerFareAmount: 0,
        airlineFareAmount: 0,
      },
    }
  );

  const adultPrice = extractPrice(adults, cr);
  const childPrice = extractPrice(children, cr);
  const infantPrice = extractPrice(infants, cr);

  return {
    duration,
    currency,
    decimalPoints,
    adults: adultPrice,
    children: childPrice,
    infants: infantPrice,
  };
}

function extractPrice(
  value: {
    partnerFareAmount: number;
    airlineFareAmount: number;
  },
  cr: FlightSearchCr
) {
  const { partnerFareAmount, airlineFareAmount } = value;

  const isPartnerCheaper = partnerFareAmount < airlineFareAmount;
  const mainPrice = isPartnerCheaper ? partnerFareAmount : airlineFareAmount;

  const originPrice = isPartnerCheaper ? airlineFareAmount : null;

  return {
    mainPrice,
    originPrice,
  };
}

function createSegmentSummary(
  segments: Segment[],
  staticData: StaticData,
  cr: FlightSearchCr
) {
  const airlines = uniqBy(segments, 'brandAirline').reduce((obj, segment) => {
    const airline = staticData.airlineMap[segment.brandAirline];

    if (airline) {
      obj.push(airline);
    }

    return obj;
  }, [] as Airline[]);
  const airlineLogos = airlines.map<AirlineLogo>(airline => ({
    src: airline.logoUrl,
    alt: airline.airlineName,
  }));

  const seatClasses = uniqBy(segments, 'seatClass').map(
    segment => segment.seatClass
  );
  const isMultiClass = seatClasses.length > 1;

  return {
    airlineLogos,
    airlineName:
      airlineLogos.length > 1
        ? cr.CorporateFlightGeneral.multiAirline
        : airlineLogos[0]?.alt,
    // #REF-1
    airlineCode: airlines[0]?.airlineCode,
    airlineBrand: segments[0].brandAirline,
    isMultiClass,
    seatClass: isMultiClass
      ? cr.CorporateFlightSeatClass.MIXED
      : cr.CorporateFlightSeatClass[seatClasses[0]],
  };
}

// Utils
type DurationCr = {
  durationD: string;
  durationH: string;
  durationM: string;
};

export function printDuration(duration: number, cr: DurationCr): string {
  const rawDay = duration / 60 / 24;
  const rawHour = (duration / 60) % 24;
  const rawMinute = duration % 60;

  let d = 0;
  let h = 0;
  let m = 0;
  if (duration < 0) {
    d = Math.ceil(rawDay);
    h = (d !== 0 ? -1 : 1) * Math.ceil(rawHour);
    m = (d !== 0 || h !== 0 ? -1 : 1) * Math.ceil(rawMinute);
  } else {
    d = Math.floor(rawDay);
    h = Math.floor(rawHour);
    m = Math.floor(rawMinute);
  }

  const dStr = formatMessage(cr.durationD, { d });
  const hStr = formatMessage(cr.durationH, { h });
  const mStr = formatMessage(cr.durationM, { m });

  if (d !== 0) {
    return `${dStr} ${hStr} ${mStr}`;
  }

  if (h !== 0) {
    return `${hStr} ${mStr}`;
  }

  return `${mStr}`;
}

function flattenSegments(journeys: Journey[]) {
  interface FlattenedSegment extends Segment {
    journeyIndex: number;
    segmentIndex: number;
  }
  const flattenedSegments: FlattenedSegment[] = [];

  journeys.forEach((journey, journeyIndex) => {
    journey.segments.forEach((segment, segmentIndex) => {
      flattenedSegments.push({ journeyIndex, segmentIndex, ...segment });
    });
  });

  return flattenedSegments;
}

function makeDateTime(date: string, time: string) {
  return parse(`${date} ${time}`, `${JAVA_DATE} ${TIME}`, 0);
}

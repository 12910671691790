import React from 'react';

import { useContentResource } from '@traveloka/ctv-core/resource';

import { useFlightRequestRefund } from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';
import { useFlightRequestRefundFormAction } from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import InfoBox from 'refund/shared/components/InfoBox';

import FlightPassengerSegment from './FlightPassengerSegment';

export default function FlightPassengers() {
  const [{ data }] = useFlightRequestRefund();
  const { flightRefundData } = data;
  const { checkShouldRefundAllPax } = useFlightRequestRefundFormAction();

  const shouldRefundAllPax = checkShouldRefundAllPax();

  const content = useContentResource()
    .CorporateFlightRequestRefundSelectDetails;

  return (
    <>
      {shouldRefundAllPax && (
        <InfoBox
          text={content.shouldRefundAllPaxInfoText}
          verticalAlign="top"
          noBorderRadius
        />
      )}
      {flightRefundData.travelers.map((traveler, travelerIndex) => {
        return (
          <FlightPassengerSegment
            key={travelerIndex}
            traveler={traveler}
            travelerIndex={travelerIndex}
          />
        );
      })}
    </>
  );
}

import React from 'react';
import { Animated, StyleSheet, View } from 'react-native';

import MultiClassIcon from '@traveloka/icon-kit-web/svg/darkBlue/ic_transport_seat_class_multiple-fill_16px.svg';
import SmartComboIcon from '@traveloka/icon-kit-web/svg/blue/ic_marketing_smart_combo_24px.svg';
import {
  useTheme,
  Button,
  Icon,
  Image,
  Text,
  Token,
  Tooltip,
} from '@traveloka/web-components';
import { useLayout } from '@traveloka/web-components/future';

import RouteIcon from '../../../../svg/16/ic_flight_route_16px.svg';
import FlightCompliance from '../../FlightCompliance/FlightCompliance';
import { FlightFacilityIcon } from '../../FlightFacility/FlightFacility';
import {
  useActiveDetail,
  useDispatch,
  ActiveDetail,
} from '../contexts/DetailContext';
import { useProps } from '../contexts/PropsContext';
import useAccentDefault from '../hooks/use-accent-default';
import useControlledTransition from '../hooks/use-controlled-transition';
import { appendTestId } from '../../../shared/utils/TestUtil';

type ToggleBehaviorMap = Record<ActiveDetail, number>;

type Props = {
  testID?: string;
};

export default function FlightSummary(props: Props) {
  return (
    <>
      <View style={Style.container}>
        <AirlineInfo {...props} />
        <JourneyInfo {...props} />
        <PriceAction {...props} />
      </View>
      <DetailToggler {...props} />
      <ComplianceInfo {...props} />
    </>
  );
}

function AirlineInfo(props: Props) {
  const { testID, compactLayout, flight } = useProps();
  const { summary } = flight;
  const isMultiAirline = summary.airlineLogos.length > 1;

  return (
    <View
      style={[
        !compactLayout ? Style.airlineInfoNormal : Style.airlineInfoCompact,
        !isMultiAirline && Style.singleAirlineInfo,
      ]}
    >
      <View
        style={[
          Style.airlineInfoLogos,
          isMultiAirline && Style.multiAirlineInfoLogos,
        ]}
      >
        {summary.airlineLogos.map((logo, index) => (
          <Image
            key={index}
            src={logo.src}
            alt={logo.alt}
            width={Token.spacing.l}
            height={Token.spacing.l}
            objectFit="contain"
            style={{ marginRight: Token.spacing.xs }}
          />
        ))}
      </View>
      <View style={Style.airlineName}>
        <Text>{summary.airlineName}</Text>
        <View
          testID={appendTestId(testID, 'seat-class')}
          style={Style.seatClass}
        >
          {summary.isMultiClass && (
            <Icon
              src={MultiClassIcon}
              width={Token.spacing.s}
              height={Token.spacing.s}
              style={{ marginRight: Token.spacing.xxs }}
            />
          )}
          <Text variant="ui-tiny" ink="secondary">
            {summary.seatClass}
          </Text>
        </View>
      </View>
    </View>
  );
}

function JourneyInfo(props: Props) {
  const { compactLayout, flight } = useProps();
  const { summary } = flight;
  const { testID } = props;

  return (
    <View style={Style.journeyInfo}>
      <View
        style={[
          !compactLayout
            ? Style.journeySegmentNormal
            : Style.journeySegmentCompact,
        ]}
      >
        <Text>{summary.departureTime}</Text>
        <Text variant="ui-small" ink="secondary">
          {summary.departureLocation}
        </Text>
      </View>
      <Image
        src={RouteIcon}
        width={Token.spacing.xl}
        height={Token.spacing.l}
        objectFit="contain"
        style={{ marginRight: Token.spacing.m }}
      />
      <View
        style={[
          !compactLayout
            ? Style.journeySegmentNormal
            : Style.journeySegmentCompact,
        ]}
      >
        <Text>
          {summary.arrivalTime}
          {summary.offsetStr && (
            <Text variant="ui-tiny" style={Style.journeyOffset}>
              {summary.offsetStr}
            </Text>
          )}
        </Text>
        <Text variant="ui-small" ink="secondary">
          {summary.arrivalLocation}
        </Text>
      </View>
      <View
        style={[
          !compactLayout
            ? Style.journeyDurationNormal
            : Style.journeyDurationCompact,
        ]}
      >
        <Text>{summary.durationStr}</Text>
        <View style={Style.journeyTransit}>
          {summary.transit > 0 && <View style={Style.journeyTransitDot} />}
          <Text
            testID={appendTestId(testID, 'transit')}
            variant="ui-small"
            ink="secondary"
          >
            {summary.transitStr}
          </Text>
        </View>
      </View>
      <View style={Style.facilities}>
        {summary.facilities.map((facility, index) => (
          <View key={index} style={Style.facility}>
            <FlightFacilityIcon facility={facility} />
          </View>
        ))}
      </View>
    </View>
  );
}

function PriceAction(props: Props) {
  const {
    label,
    onSelectResultClick,
    flight,
    compactLayout,
    showButton,
    loading,
  } = useProps();
  const { compliance, summary, isSmartCombo } = flight;
  const { testID } = props;

  return (
    <View style={compliance && !compactLayout && Style.spacing}>
      {isSmartCombo && (
        <View style={Style.smartCombo}>
          <Tooltip
            content={
              <Text
                testID={appendTestId(testID, 'sc-tooltip')}
                variant="ui-tiny"
                ink="muted"
              >
                <Text style={Style.smartComboTag} variant="ui-tiny" ink="muted">
                  {label.smartComboTooltipTitle}
                </Text>
                {label.smartComboTooltipDescription}
              </Text>
            }
            width={220}
          >
            <Icon src={SmartComboIcon} />
          </Tooltip>
          <Text
            testID={appendTestId(testID, 'sc-tag')}
            variant="ui-small"
            ink="primary-interactive"
          >
            {label.smartComboTag}
          </Text>
        </View>
      )}
      {summary.originPriceStr && (
        <Text
          variant="ui-small"
          ink="muted"
          style={[Style.originPrice, Style.bold]}
        >
          {summary.originPriceStr}
          {label.priceSuffix}
        </Text>
      )}
      <Text
        testID={appendTestId(testID, 'main-price')}
        variant="ui-small"
        ink="secondary"
        style={[Style.mainPrice, Style.bold]}
      >
        <Text variant="ui-large" ink="price" style={Style.bold}>
          {summary.mainPriceStr}
        </Text>
        {label.priceSuffix}
      </Text>
      {showButton && (
        <Button
          testID={appendTestId(testID, 'choose-button')}
          text={label.select}
          onPress={onSelectResultClick}
          variant="main-cta"
          size="small"
          loading={loading}
        />
      )}
    </View>
  );
}

function DetailToggler(props: Props) {
  const { label } = useProps();
  const dispatch = useDispatch();
  const activeDetail = useActiveDetail();
  const { color } = useTheme();
  const { testID } = props;

  const { interpolate } = useControlledTransition<
    ActiveDetail,
    ToggleBehaviorMap
  >(activeDetail, Token.animation.timing.instant);
  const [flightRect, flightBindings] = useLayout();
  const [fareRect, fareBindings] = useLayout();

  const accentHeight = interpolate({
    none: 0,
    flight: Token.border.width.bold,
    fare: Token.border.width.bold,
  });

  const accentOffsetMap = useAccentDefault({
    flight: 0,
    fare: flightRect.width + Token.spacing.xxl,
  });
  const accentOffset = interpolate(accentOffsetMap);

  const accentWidthMap = useAccentDefault({
    flight: flightRect.width,
    fare: fareRect.width,
  });
  const accentWidth = interpolate(accentWidthMap);

  return (
    <>
      <View style={Style.detailToggler}>
        <Button
          {...flightBindings}
          testID={appendTestId(testID, 'flight-detail')}
          text={label.flightDetail}
          onPress={() => dispatch('flight')}
          variant="text"
          size="small"
        />
        <Button
          {...fareBindings}
          testID={appendTestId(testID, 'fare-info')}
          text={label.fareDetail}
          onPress={() => dispatch('fare')}
          variant="text"
          size="small"
          style={Style.detailTogglerOffset}
        />
      </View>
      <View style={Style.accentContainer}>
        <Animated.View
          style={{
            backgroundColor: color.tintPrimary,
            height: 1,
            width: 1,
            transform: [{ scaleX: accentWidth }, { scaleY: accentHeight }],
            transformOrigin: 'bottom left',
            marginLeft: accentOffset,
          }}
        />
      </View>
    </>
  );
}

function ComplianceInfo(props: Props) {
  const { flight } = useProps();
  const { compliance } = flight;
  const { testID } = props;

  return (
    compliance && (
      <FlightCompliance
        testID={appendTestId(testID, 'compliant')}
        label={compliance.label}
        style={Style.compliance}
      />
    )
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },

  // Airline info
  airlineName: {
    flex: 1,
  },
  airlineInfoNormal: {
    width: 160,
    marginRight: Token.spacing.m,
  },
  airlineInfoCompact: {
    flexBasis: '100%',
    marginBottom: Token.spacing.m,
  },
  singleAirlineInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  airlineInfoLogos: {
    flexDirection: 'row',
  },
  multiAirlineInfoLogos: {
    marginBottom: Token.spacing.xs,
  },
  seatClass: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  // Journey Info
  journeyInfo: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  journeySegmentNormal: {
    width: 96,
  },
  journeySegmentCompact: {
    width: 80,
  },
  journeyOffset: {
    marginLeft: Token.spacing.xxs,
  },
  journeyDurationNormal: {
    width: 96,
    marginHorizontal: Token.spacing.l,
  },
  journeyDurationCompact: {
    width: 72,
    marginHorizontal: Token.spacing.ml,
  },
  journeyTransit: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  journeyTransitDot: {
    width: Token.spacing.s,
    height: Token.spacing.s,
    marginRight: Token.spacing.xxs,
    borderRadius: Token.border.radius.rounded,
    backgroundColor: Token.color.uiYellowPrimary,
  },
  facilities: {
    flexDirection: 'row',
  },
  facility: {
    marginRight: Token.spacing.xxs,
  },

  // Price Action
  spacing: {
    paddingTop: Token.spacing.m,
  },
  bold: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  originPrice: {
    textDecorationLine: 'line-through',
    marginBottom: Token.spacing.xxs,
  },
  mainPrice: {
    marginBottom: Token.spacing.xs,
  },

  // Detail Toggler
  detailToggler: {
    marginTop: Token.spacing.m,
    flexDirection: 'row',
  },
  detailTogglerActive: {
    marginTop: Token.spacing.xxxl,
  },
  detailTogglerOffset: {
    marginLeft: Token.spacing.xxl,
  },
  accentContainer: {
    height: Token.border.width.bold,
    justifyContent: 'flex-end',
  },

  // Compliance
  compliance: {
    position: 'absolute',
    right: 0,
    top: 0,
    borderTopRightRadius: Token.border.radius.normal - 1,
    borderBottomLeftRadius: Token.border.radius.normal,
  },

  smartCombo: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Token.spacing.xxs,
  },
  smartComboTag: {
    fontWeight: 'bold',
  },
});

import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { FormProvider, useController, useForm } from 'react-hook-form';

import { useApi, useContentResource, useTracker } from '@traveloka/ctv-core';
import {
  Button,
  Card,
  ErrorMessage,
  Icon,
  Input,
  Text,
  Token,
} from '@traveloka/web-components';

import EmailIcon from '@traveloka/icon-kit-web/svg/blue/ic_contact_mail_24px.svg';
import IcSystemArrowRight from '@traveloka/icon-kit-web/svg/blue/ic_system_arrow_right_12px.svg';

import {
  PreregistrationRequest,
  PreregistrationResponse,
  PREREGISTATION_API,
} from 'registration/api';
import { RegistrationStatus } from 'registration/api/types';
import { usePreRegisterConfirm } from 'registration/contexts/PreRegisterConfirmContext';
import { Content, StepHeader } from 'shared/components/Layout';
import { useDispatchSnackbar } from 'shared/contexts/Snackbar/SnackbarContext';
import Snackbar from 'shared/contexts/Snackbar/Snackbar';
import { isEmail, maxLength, required } from 'shared/utils/validator';

import RegistrationStep from './RegistrationStep';
import RegistrationSubmitted from './AfterSubmission/RegistrationSubmitted';
import VerificationSteps from './AfterSubmission/VerificationSteps';
import ActivateAccount from './AfterSubmission/ActivateAccount';
import RequiredDocumentModal from './RequiredDocumentModal';

export type ProcessStep =
  | 'VERIFICATION_PROCESS'
  | 'COMPLETED'
  | 'REJECTED'
  | 'INITIAL_PROCESS';

export default function RegistrationProcess() {
  const [isRequiredDocumentVisible, setIsRequiredDocumentVisible] = useState(
    false
  );

  const data = usePreRegisterConfirm();

  const { status } = data ?? {};

  const cr = useContentResource().CorporateRegistration;
  const track = useTracker('ors-page');

  const currentStep = getCurrentStep(status);

  return (
    <>
      <StepHeader current={-1} steps={[]} />
      <Snackbar />
      <Content style={styles.content}>
        <Text variant="headline" style={styles.headline}>
          {cr.processOverviewV2}
        </Text>
        <Text ink="secondary" style={styles.subHeadline}>
          {cr.processOverviewHelpV3}
        </Text>
        <RegistrationStep
          step={1}
          style={styles.step}
          title={cr.registrationPhaseV2}
        >
          {currentStep === 'INITIAL_PROCESS' ? (
            <Card style={styles.cardContent}>
              <Text variant="ui-small">{cr.registrationPhaseHelpV3}</Text>
              <Button
                style={styles.seeRequiredDocumentButton}
                iconEnd={() => (
                  <Icon src={IcSystemArrowRight} width={12} height={12} />
                )}
                text={cr.seeRequiredDocumentsButton}
                variant="text"
                size="small"
                onPress={() => {
                  setIsRequiredDocumentVisible(true);
                  track('corpB2b.onlineRegistration.webEvent', {
                    eventName: 'CORP_B2B.ONLINE_REGISTRATION.WEB_EVENT',
                    eventVersion: '1.0.0',
                    pageName: 'ORS_PAGE',
                    pageCategory: 'ORS_PAGE',
                    eventValue: 'CLICK_REQUIRED_DOCUMENT',
                  }).send();
                }}
              />
              <SendEmailForm />
            </Card>
          ) : (
            <RegistrationSubmitted />
          )}
        </RegistrationStep>
        <RegistrationStep
          step={2}
          style={styles.step}
          title={cr.verificationPhaseV2}
        >
          {currentStep === 'INITIAL_PROCESS' ? (
            <Card style={styles.cardContent}>
              <Text variant="ui-small">{cr.verificationPhaseHelpV3}</Text>
            </Card>
          ) : (
            <VerificationSteps status={currentStep} />
          )}
        </RegistrationStep>
        <RegistrationStep
          step={3}
          style={styles.step}
          title={cr.accountCreatedPhaseV2}
          disabled={currentStep === 'REJECTED'}
        >
          {currentStep === 'COMPLETED' ? (
            <ActivateAccount />
          ) : (
            <Card style={styles.cardContent}>
              <Text
                variant="ui-small"
                ink={currentStep === 'REJECTED' ? 'secondary' : 'primary'}
              >
                {cr.accountCreatedPhaseHelpV3}
              </Text>
            </Card>
          )}
        </RegistrationStep>
      </Content>
      <RequiredDocumentModal
        isVisible={isRequiredDocumentVisible}
        onClose={() => setIsRequiredDocumentVisible(false)}
      />
    </>
  );
}

type EmailFormValue = { email: string };

function SendEmailForm() {
  const cr = useContentResource().CorporateRegistration;

  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const openSnackbar = useDispatchSnackbar();
  const track = useTracker('ors-page');
  const methods = useForm<EmailFormValue>();
  const { control, handleSubmit: formSubmit, setError } = methods;
  const {
    field: { onChange, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name: 'email',
    rules: {
      validate(v) {
        if (required(v) === false) {
          return cr.picEmailRequired;
        } else if (maxLength(v, 256) === false) {
          return cr.picEmailMaxLength;
        } else if (isEmail(v) === false) {
          return cr.picEmailFormat;
        }

        return;
      },
    },
  });

  const sendEmail = useApi<PreregistrationResponse, PreregistrationRequest>({
    domain: 'management',
    method: 'post',
    path: PREREGISTATION_API,
  });

  async function handleSubmit(values: EmailFormValue) {
    setLoading(true);

    const res = await sendEmail({
      picEmail: values.email,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    if (res.success) {
      if (res.data.status === 'OK') {
        openSnackbar({ message: cr.emailSent, variant: 'normal' });
        track('corpB2b.onlineRegistration.webEvent', {
          eventName: 'CORP_B2B.ONLINE_REGISTRATION.WEB_EVENT',
          eventVersion: '1.0.0',
          pageName: 'ORS_PAGE',
          pageCategory: 'ORS_PAGE',
          eventValue: 'SUBMIT_EMAIL',
          emailAddress: values.email,
        }).send();
      } else if (res.data.status === 'EMAIL_HAS_REGISTERED') {
        setError('email', { message: cr.emailHasRegistered });
      }
      setSent(true);
    }

    setLoading(false);
  }

  return (
    <FormProvider {...methods}>
      <View style={styles.sendEmail}>
        <View style={styles.emailInput}>
          <Input
            error={Boolean(error?.message)}
            disabled={loading || sent}
            iconLeft={<Icon src={EmailIcon} />}
            placeholder={cr.sendEmailPlaceholderV2}
            onChangeText={onChange}
            onBlur={onBlur}
          />
        </View>
        <Button
          disabled={sent}
          loading={loading}
          onPress={formSubmit(handleSubmit)}
          text={cr.sendEmailV2}
          variant="main-cta"
        />
      </View>
      {error?.message && <ErrorMessage text={error.message} />}
    </FormProvider>
  );
}

const styles = StyleSheet.create({
  headline: {
    marginBottom: Token.spacing.m,
  },
  subHeadline: {
    marginBottom: Token.spacing.xxl,
  },
  content: {
    width: 640,
  },
  cardContent: {
    padding: Token.spacing.l,
  },
  registrationCompleteContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Token.spacing.l,
  },
  registrationComplete: {
    marginLeft: Token.spacing.m,
  },
  registrationCompleteInfo: {
    marginTop: Token.spacing.l,
  },
  linkText: {
    textDecorationLine: 'underline',
    color: Token.color.uiBluePrimary,
  },
  seeRequiredDocumentButton: {
    width: 'fit-content',
    marginTop: Token.spacing.m,
    padding: 0,
  },
  sendEmail: {
    marginTop: Token.spacing.l,
    flexDirection: 'row',
  },
  emailLabel: {
    marginTop: Token.spacing.l,
  },
  emailInput: {
    marginEnd: Token.spacing.m,
    flex: 1,
  },
  step: {
    marginBottom: Token.spacing.xxl,
  },
  verificationPhaseInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Token.spacing.m,
  },
  verificationPhaseInfoLabel: {
    marginLeft: Token.spacing.m,
  },
  verificationWaitLongerInfo: {
    marginTop: Token.spacing.m,
  },
  info: {
    marginBottom: Token.spacing.l,
  },
  signInButton: {
    marginLeft: 'auto',
  },
});

function getCurrentStep(
  status: Nullable<RegistrationStatus> | undefined
): ProcessStep {
  switch (status) {
    case RegistrationStatus.NEED_VERIFICATION:
    case RegistrationStatus.ONGOING_CREDIT_ASSESSMENT:
    case RegistrationStatus.ONGOING_LEGAL_REVISION:
    case RegistrationStatus.AGREEMENT_SENT_TO_CLIENT:
    case RegistrationStatus.REQUEST_FOR_ACTIVATION:
      return 'VERIFICATION_PROCESS';
    case RegistrationStatus.ACTIVE:
    case RegistrationStatus.REGISTERED:
      return 'COMPLETED';
    case RegistrationStatus.REJECTED:
    case RegistrationStatus.REJECTED_BY_CORPORATE:
    case RegistrationStatus.REJECTED_BY_INTERNAL:
      return 'REJECTED';
    default:
      return 'INITIAL_PROCESS';
  }
}

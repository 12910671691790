import 'unfetch/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';

import './index.css';

import { Amplify } from 'aws-amplify';
import config from '@traveloka/ctv-core/config';
import { AppRegistry } from 'react-native';
import App from './App';

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });

Amplify.configure(config.amplify);

import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Token, Text } from '@traveloka/web-components';

import ContactUsContent from './components/ContactUsContent/ContactUsContent';
import { Content, Footer, Header, Page } from 'shared/components/Layout';
import { useFeatureControl } from '@traveloka/ctv-core/feature-control';
import { Redirect } from 'react-router-dom';
import { useContentResource } from '@traveloka/ctv-core';

export default function ContactUs() {
  const contactUsFC = useFeatureControl('b2b-contact-us');

  if (!contactUsFC.enabled) {
    return <Redirect to="/" />;
  }

  return (
    <Page>
      <Header />
      <HeaderBar />
      <Content>
        <ContactUsContent />
      </Content>
      <Footer />
    </Page>
  );
}

function HeaderBar() {
  const content = useContentResource().CorporateContactUsHeader;

  return (
    <View style={Style.wrapper}>
      <Text variant="display" ink="white">
        {content.title}
      </Text>
      <Text style={Style.bold} ink="white">
        {content.subtitle}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: Token.color.uiBluePrimary,
    paddingTop: Token.spacing.xxl,
    minHeight: 180,
    alignItems: 'center',
  },
  bold: Token.typography.weightMedium,
});

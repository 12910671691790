import React, { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import Girl from '@traveloka/ctvweb-ui/img/popup-girl.png';
import { Image, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

type Props = {
  isVisible: boolean;
  title: string;
  onClosePress?(): void;
};

export default function PopupGirl(props: PropsWithChildren<Props>) {
  const { isVisible, title, children, onClosePress } = props;

  return (
    <Modal isVisible={isVisible}>
      <Popup
        showCloseButton={typeof onClosePress === 'function'}
        onCloseButtonPress={onClosePress}
        width={600}
        maxWidth={600}
      >
        <View style={Style.content}>
          <Image src={Girl} width={221} height={256} style={Style.image} />
          <Text variant="ui-large" style={Style.title}>
            {title}
          </Text>
          {children}
        </View>
      </Popup>
    </Modal>
  );
}

const Style = StyleSheet.create({
  image: {
    marginBottom: Token.spacing.xxxl,
  },
  title: {
    marginBottom: Token.spacing.m,
    fontWeight: Token.typography.weightMedium.fontWeight,
    textAlign: 'center',
  },
  content: {
    alignItems: 'center',
  },
});

import { useCallback, useMemo, useState } from 'react';

import {
  addDays,
  isWithinInterval,
  setHours,
  setMinutes,
  differenceInCalendarDays,
} from 'date-fns';
import { intersection, union, uniq, uniqBy, xor } from 'lodash';

import { CurrencyValue } from 'shared/utils/currency';
import storage from 'shared/utils/storage';

import { Airline, Airport, StaticData } from '../types';
import { FlightSearchData } from '../utils/flight-search-view-util';

type Time = 'midnight' | 'morning' | 'afternoon' | 'evening';
type Facility = 'baggage' | 'meal';
type TransitCount = 'direct' | 'one' | 'moreThanOne';
type Compliance = 'yes' | 'no';

type Preference = 'excludeOvernightTransit' | 'excludeLateNightFlights';

type FilterLookup = {
  transitCount: Record<TransitCount, string[]>;
  transitPoint: Dictionary<string[]>;
  departureTime: Record<Time, string[]>;
  arrivalTime: Record<Time, string[]>;
  airline: Dictionary<string[]>;
  compliance: Record<Compliance, string[]>;
  facility: Record<Facility, string[]>;
  preference: Record<Preference, string[]>;
};

function createFilterLookup(): FilterLookup {
  return {
    airline: {},
    arrivalTime: {
      midnight: [],
      morning: [],
      afternoon: [],
      evening: [],
    },
    departureTime: {
      midnight: [],
      morning: [],
      afternoon: [],
      evening: [],
    },
    compliance: {
      yes: [],
      no: [],
    },
    facility: {
      baggage: [],
      meal: [],
    },
    preference: {
      excludeOvernightTransit: [],
      excludeLateNightFlights: [],
    },
    transitCount: {
      direct: [],
      one: [],
      moreThanOne: [],
    },
    transitPoint: {},
  };
}

type FilterOptionValues<T> = Array<{
  disabled: boolean;
  value: T;
}>;

type FilterOptionRange<T> = {
  min: T;
  max: T;
};

type FilterOption = {
  transitCount: FilterOptionValues<TransitCount>;
  transitDuration: FilterOptionRange<number>;
  transitPoint: Airport[];
  departureTime: FilterOptionValues<Time>;
  arrivalTime: FilterOptionValues<Time>;
  airline: Airline[];
  compliance: FilterOptionValues<Compliance>;
  price: FilterOptionRange<CurrencyValue>;
  facility: FilterOptionValues<Facility>;
  preference: FilterOptionValues<Preference>;
};

function createFilterOption(): FilterOption {
  return {
    transitCount: [
      { disabled: false, value: 'direct' },
      { disabled: false, value: 'one' },
      { disabled: false, value: 'moreThanOne' },
    ],
    transitDuration: {
      min: Number.MAX_SAFE_INTEGER,
      max: 0,
    },
    transitPoint: [],
    departureTime: [
      { disabled: false, value: 'midnight' },
      { disabled: false, value: 'morning' },
      { disabled: false, value: 'afternoon' },
      { disabled: false, value: 'evening' },
    ],
    arrivalTime: [
      { disabled: false, value: 'midnight' },
      { disabled: false, value: 'morning' },
      { disabled: false, value: 'afternoon' },
      { disabled: false, value: 'evening' },
    ],
    airline: [],
    compliance: [
      { disabled: false, value: 'yes' },
      { disabled: false, value: 'no' },
    ],
    price: {
      min: {
        amount: Number.MAX_SAFE_INTEGER,
        currency: '',
        decimalPoints: 0,
      },
      max: {
        amount: 0,
        currency: '',
        decimalPoints: 0,
      },
    },
    facility: [
      { disabled: false, value: 'baggage' },
      { disabled: false, value: 'meal' },
    ],
    preference: [
      { disabled: false, value: 'excludeOvernightTransit' },
      { disabled: false, value: 'excludeLateNightFlights' },
    ],
  };
}

type FilterValue = {
  transitCount: TransitCount[];
  transitDuration: FilterOptionRange<number>;
  transitPoint: string[];
  departureTime: Time[];
  arrivalTime: Time[];
  airline: string[];
  compliance: Compliance[];
  price: FilterOptionRange<number>;
  facility: Facility[];
  preference: Preference[];
};

const initialFilterValue: FilterValue = {
  transitCount: [],
  transitDuration: {
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
  },
  transitPoint: [],
  departureTime: [],
  arrivalTime: [],
  airline: [],
  compliance: [],
  price: {
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
  },
  facility: [],
  preference: [],
};

type SortValue =
  | 'default'
  | 'price'
  | 'earliestDeparture'
  | 'latestDeparture'
  | 'earliestArrival'
  | 'latestArrival'
  | 'duration';

const sortOptions: SortValue[] = [
  'price',
  'earliestDeparture',
  'latestDeparture',
  'earliestArrival',
  'latestArrival',
  'duration',
];

if (storage.get('flight-default-sort')) {
  sortOptions.unshift('default');
}

type SortFnMap = Record<
  SortValue,
  Array<(x: FlightSearchData['summary']) => number>
>;

const sortFnMap: SortFnMap = {
  default: [],
  price: [x => x.mainPrice.amount, x => x.departureDateTime.valueOf()],
  earliestDeparture: [
    x => x.departureDateTime.valueOf(),
    x => x.mainPrice.amount,
  ],
  latestDeparture: [
    x => -x.departureDateTime.valueOf(),
    x => x.mainPrice.amount,
  ],
  earliestArrival: [x => x.arrivalDateTime.valueOf(), x => x.mainPrice.amount],
  latestArrival: [x => -x.arrivalDateTime.valueOf(), x => x.mainPrice.amount],
  duration: [x => x.duration, x => x.mainPrice.amount],
};

export default function useFlightSortFilter(
  rawResults: FlightSearchData[],
  staticData: StaticData
) {
  const results = uniqBy(rawResults, result => result.flightId);
  const [filterLookup, filterOption] = useMemo(
    () => createFilterData(results, staticData),
    [results, staticData]
  );

  const [sortValue, setSort] = useState<SortValue>('price');
  const [filterValue, setFilterValue] = useState<FilterValue>({
    ...initialFilterValue,
    transitDuration: {
      min: filterOption.transitDuration.min,
      max: filterOption.transitDuration.max,
    },
    price: {
      min: filterOption.price.min.amount,
      max: filterOption.price.max.amount,
    },
  });

  const newResults = useMemo(() => {
    return sortAndFilter(filterLookup, results, filterValue, sortValue);
  }, [filterLookup, results, filterValue, sortValue]);

  const setFilter = useCallback(
    (
      filter: keyof FilterValue,
      value: FilterValue[keyof FilterValue] | string
    ) => {
      // Select all or range
      if (Array.isArray(value) || typeof value === 'object') {
        setFilterValue(prevState => ({
          ...prevState,
          [filter]: value,
        }));

        return;
      }

      if (filter !== 'price' && filter !== 'transitDuration') {
        setFilterValue(prevState => ({
          ...prevState,
          [filter]: xor(prevState[filter], [value]),
        }));
      }
    },
    []
  );

  return {
    results: newResults,
    sortValue,
    setSort,
    filterValue,
    setFilter,
    sortOptions,
    filterOption,
  };
}

function sortAndFilter(
  filterLookup: FilterLookup,
  results: FlightSearchData[],
  filterValue: FilterValue,
  sortValue: SortValue
) {
  const filterValueKeys = Object.keys(filterValue) as Array<keyof FilterValue>;

  const filtered = filterValueKeys.reduce((filteredFlights, filterName) => {
    switch (filterName) {
      case 'transitCount':
      case 'transitPoint':
      case 'departureTime':
      case 'arrivalTime':
      case 'compliance':
      case 'airline': {
        const values = filterValue[filterName] as string[];
        const filterLookupValues = filterLookup[filterName] as Record<
          any,
          string[]
        >;

        if (values.length > 0) {
          const flightIds = union(...values.map(v => filterLookupValues[v]));

          return filteredFlights.filter(flight =>
            flightIds.includes(flight.flightId)
          );
        }

        return filteredFlights;
      }
      case 'facility':
      case 'preference': {
        const values = filterValue[filterName] as string[];
        const filterLookupValues = filterLookup[filterName] as Record<
          any,
          string[]
        >;

        if (values.length > 0) {
          const flightIds = intersection(
            ...values.map(v => filterLookupValues[v])
          );

          return filteredFlights.filter(flight =>
            flightIds.includes(flight.flightId)
          );
        }

        return filteredFlights;
      }
      case 'transitDuration': {
        const { min, max } = filterValue.transitDuration;

        return filteredFlights.filter(({ segments }) => {
          if (min === 0 && segments.every(segment => !segment.transit)) {
            return true;
          }

          return segments.some(segment => {
            if (!segment.transit) {
              return false;
            }

            const duration = segment.transit.duration;

            return duration >= min && duration <= max;
          });
        });
      }
      case 'price': {
        const { min, max } = filterValue.price;

        return filteredFlights.filter(({ summary }) => {
          return (
            summary.mainPrice.amount >= min && summary.mainPrice.amount <= max
          );
        });
      }
    }

    return filteredFlights;
  }, results);

  return filtered.sort((a, b) => {
    const sortFns = sortFnMap[sortValue];

    for (const sortFn of sortFns) {
      const diff = sortFn(a.summary) - sortFn(b.summary);

      if (diff !== 0) {
        return diff;
      }
    }

    return 0;
  });
}

// Filter Option Utils
function createFilterData(results: FlightSearchData[], staticData: StaticData) {
  const filterLookup = createFilterLookup();
  const filterOption = createFilterOption();

  for (const flight of results) {
    const { flightId, compliance, summary, segments } = flight;

    // Transit Count
    if (summary.transit === 0) {
      filterLookup.transitCount.direct.push(flightId);
    } else if (summary.transit === 1) {
      filterLookup.transitCount.one.push(flightId);
    } else {
      filterLookup.transitCount.moreThanOne.push(flightId);
    }

    // Transit Point
    const airportCodes: string[] = [];
    segments.forEach(segment => {
      if (segment.stopOver) {
        airportCodes.push(segment.stopOver.airportCode);
      }

      if (segment.transit) {
        airportCodes.push(segment.transit.airportCode);
      }

      if (segment.changeAirportCode) {
        airportCodes.push(segment.changeAirportCode);
      }
    });
    const uniqAirportCodes = uniq(airportCodes);
    for (const airportCode of uniqAirportCodes) {
      if (filterLookup.transitPoint[airportCode]) {
        filterLookup.transitPoint[airportCode].push(flightId);
      } else {
        filterLookup.transitPoint[airportCode] = [flightId];
      }
    }

    // Departure Time
    for (const timeGroup of getTimeGroup(summary.departureDateTime)) {
      filterLookup.departureTime[timeGroup].push(flightId);
    }

    // Arrival Time
    for (const timeGroup of getTimeGroup(summary.arrivalDateTime)) {
      filterLookup.arrivalTime[timeGroup].push(flightId);
    }

    // Airline
    const uniqAirlineCodes = uniq(segments.map(segment => segment.airlineCode));
    for (const airlineCode of uniqAirlineCodes) {
      if (filterLookup.airline[airlineCode]) {
        filterLookup.airline[airlineCode].push(flightId);
      } else {
        filterLookup.airline[airlineCode] = [flightId];
      }
    }

    // Compliance
    if (!compliance) {
      filterLookup.compliance.yes.push(flightId);
    } else {
      filterLookup.compliance.no.push(flightId);
    }

    // Facility
    for (const facility of summary.facilities) {
      if (facility.type !== 'baggage_paid') {
        filterLookup.facility[facility.type].push(flightId);
      }
    }
    // Preference
    if (!hasOvernightTransit(segments)) {
      filterLookup.preference.excludeOvernightTransit.push(flightId);
    }

    if (!hasLateNightFlight(segments)) {
      filterLookup.preference.excludeLateNightFlights.push(flightId);
    }

    // Update filter option min-max value
    segments.reduce((filter, segment) => {
      const duration = segment.transit ? segment.transit.duration : 0;

      if (duration > filter.max) {
        filter.max = duration;
      }

      if (duration < filter.min) {
        filter.min = duration;
      }

      return filter;
    }, filterOption.transitDuration);

    if (summary.mainPrice.amount > filterOption.price.max.amount) {
      filterOption.price.max = summary.mainPrice;
    }

    if (summary.mainPrice.amount < filterOption.price.min.amount) {
      filterOption.price.min = summary.mainPrice;
    }
  }

  // Build filter options
  filterOption.airline = Object.keys(filterLookup.airline)
    .map(airlineCode => staticData.airlineMap[airlineCode])
    .filter<Airline>((airline): airline is Airline => !!airline);
  filterOption.transitPoint = Object.keys(filterLookup.transitPoint)
    .map(airportCode => staticData.airportMap[airportCode])
    .filter<Airport>((airport): airport is Airport => !!airport);

  // update disabled value for every options
  // except airlines and transit points which are dynamic
  const filterLookupKeys = Object.keys(filterLookup) as Array<
    keyof FilterLookup
  >;

  filterLookupKeys.forEach(filterName => {
    if (filterName !== 'airline' && filterName !== 'transitPoint') {
      const filterOptionValues = filterOption[filterName] as FilterOptionValues<
        any
      >;

      filterOptionValues.forEach(option => {
        const filterLookupValues = filterLookup[filterName] as Record<
          any,
          string[]
        >;

        option.disabled = filterLookupValues[option.value].length === 0;
      });
    }
  });

  return [filterLookup, filterOption] as const;
}

function hasOvernightTransit(segments: FlightSearchData['segments']): boolean {
  if (segments.length < 2) {
    return false;
  }

  const [first, next] = segments;

  // This is safe, only the first segment will probably have no transit
  const transitDuration = next.transit!.duration;
  const isMoreThan5Hours = transitDuration >= 300;
  const departNextDay = differenceInCalendarDays(
    first.arrivalDateTime,
    next.departureDateTime
  );

  const date = setMinutes(first.arrivalDateTime, 0);
  const isArrivalInBetween = isWithinInterval(first.arrivalDateTime, {
    start: setHours(date, 0),
    end: setHours(date, 2),
  });

  // Determine if transit is more than 5 hours pass next day or arrive between 00:00 - 02:00
  if (isMoreThan5Hours && (departNextDay || isArrivalInBetween)) {
    return true;
  }

  return hasOvernightTransit(segments.slice(1));
}

function hasLateNightFlight(segments: FlightSearchData['segments']): boolean {
  return segments.some(segment => {
    const { departureDateTime } = segment;
    const year = departureDateTime.getFullYear();
    const month = departureDateTime.getMonth();
    const date = departureDateTime.getDate();
    const isDepartBeforeMidnight = isWithinInterval(departureDateTime, {
      start: new Date(year, month, date, 21, 0, 0, 0),
      end: new Date(year, month, date, 23, 59, 0, 0),
    });
    const isDepartAfterMidnight = isWithinInterval(departureDateTime, {
      start: new Date(year, month, date, 0, 0, 0, 0),
      end: new Date(year, month, date, 3, 0, 0, 0),
    });
    const isOvernight = segment.offset > 0;

    return (isOvernight && isDepartBeforeMidnight) || isDepartAfterMidnight;
  });
}

function getTimeGroup(dateTime: Date) {
  const result: Time[] = [];

  const date = setMinutes(dateTime, 0);
  const twelveAM = setHours(date, 0);
  const sixAM = setHours(date, 6);
  const twelvePM = setHours(date, 12);
  const sixPM = setHours(date, 18);
  const twelveAMNextDay = addDays(twelveAM, 1);

  if (isWithinInterval(dateTime, { start: twelveAM, end: sixAM })) {
    result.push('midnight');
  }
  if (isWithinInterval(dateTime, { start: sixAM, end: twelvePM })) {
    result.push('morning');
  }
  if (isWithinInterval(dateTime, { start: twelvePM, end: sixPM })) {
    result.push('afternoon');
  }
  if (isWithinInterval(dateTime, { start: sixPM, end: twelveAMNextDay })) {
    result.push('evening');
  }

  return result;
}

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import InfoIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_status_info-fill_16px.svg';
import {
  Card,
  Icon,
  LoadingIndicator,
  Text,
  Token,
  Tooltip,
} from '@traveloka/web-components';

import { useManageCardState } from '../context/CardListContext';
import AddCardWithModal from './AddCardWithModal';
import CardWithModal from './CardWithModal';

export default function ManageCreditCardContent() {
  const state = useManageCardState();

  const cr = useContentResource().CorporateManageCard;

  if (state.isLoading || !state.data) {
    return (
      <View style={Style.loadingContainer}>
        <LoadingIndicator />
      </View>
    );
  }

  const [mainCard, backupCard] = state.data;

  return (
    <>
      <Card style={Style.root}>
        <View style={Style.header}>
          <Text variant="ui-large" ink="white">
            {cr.pageTitle}
          </Text>
        </View>

        {!mainCard && (
          <View style={[Style.content, Style.start]}>
            <Text style={Style.text}>{cr.noCards}</Text>
            <AddCardWithModal />
          </View>
        )}
        {mainCard && (
          <View style={Style.content}>
            <View>
              <Text style={Style.mainCardText}>{cr.mainCard}</Text>
              <CardWithModal
                {...mainCard}
                backupCardNumber={backupCard?.cardDetail.cardNumber}
              />
            </View>

            <View style={Style.backupCard}>
              <View style={Style.backupCardTitle}>
                <Text style={Style.backupCardText}>{cr.backupCard}</Text>
                <Tooltip width={600} content={cr.noBackupCard}>
                  <Icon src={InfoIcon} width={16} height={16} />
                </Tooltip>
              </View>

              {backupCard && <CardWithModal {...backupCard} />}
              {!backupCard && <AddCardWithModal />}
            </View>
          </View>
        )}
      </Card>
    </>
  );
}

const Style = StyleSheet.create({
  root: {
    minHeight: 340,
  },
  loadingContainer: {
    alignItems: 'center',
  },
  header: {
    padding: Token.spacing.l,
    backgroundColor: Token.color.brandBusinessSuit,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  start: {
    alignItems: 'center',
    flex: 1,
  },
  text: {
    flex: 1,
    marginRight: Token.spacing.xxl,
  },
  content: {
    padding: Token.spacing.l,
    flexDirection: 'row',
  },
  backupCard: {
    marginStart: Token.spacing.l,
  },
  mainCardText: {
    marginBottom: Token.spacing.l,
  },
  backupCardTitle: {
    marginBottom: Token.spacing.l,
    flexDirection: 'row',
    alignItems: 'center',
  },
  backupCardText: {
    marginEnd: Token.spacing.xs,
  },
});

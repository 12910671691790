import { Config } from './types';

let config: Config;
if (process.env.REACT_APP_CONFIG_ENV === 'production') {
  config = require('./production').default;
} else if (process.env.REACT_APP_CONFIG_ENV === 'staging') {
  config = require('./staging').default;
} else if (process.env.REACT_APP_CONFIG_ENV === 'demo') {
  config = require('./demo').default;
} else if (process.env.NODE_ENV === 'development') {
  config = require('./development').default;
} else {
  config = require('./default').default;
}

export default config;

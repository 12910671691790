import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';

import { FEATURE_CONTROL_API } from './api';
import { FeatureControl, Feature } from './types';

import useApi from '../api/useApi';
import { isOffline } from '..';

type Props = {
  featureControl?: FeatureControl;
  setFeatureControl: Dispatch<SetStateAction<FeatureControl | undefined>>;
};

const FeatureControlContext = createContext<FeatureControl>({});

export function FeatureControlProvider(props: PropsWithChildren<Props>) {
  const { children, featureControl, setFeatureControl } = props;

  const callFc = useApi<{ features: FeatureControl }>({
    domain: 'content',
    method: 'post',
    path: FEATURE_CONTROL_API,
    withAuth: false,
  });

  useEffect(() => {
    if (isOffline === true) {
      setFeatureControl(createProxy({}));
      return;
    }

    callFc({}).then(res => {
      const fc: FeatureControl = {};

      if (res && res.success) {
        Object.keys(res.data.features).forEach(key => {
          const { enabled, properties } = res.data.features[key];

          fc[key] = {
            enabled: String(enabled) === 'true',
            properties,
          };
        });

        Array.from(
          { length: window.localStorage.length },
          (_, index) => window.localStorage.key(index) as string
        ).forEach(key => {
          if (key.startsWith('fc-')) {
            const enabled = window.localStorage[key] === 'true';
            const fcKey = key.replace(/^fc-/, '');

            fc[fcKey] = fc[fcKey] ?? {};
            fc[fcKey].enabled = enabled;
          }
        });
      }

      setFeatureControl(createProxy(fc));
    });
  }, []);

  if (!featureControl) {
    return null;
  }

  return (
    <FeatureControlContext.Provider value={featureControl}>
      {children}
    </FeatureControlContext.Provider>
  );
}

export function useGlobalFeatureControl() {
  const value = useContext(FeatureControlContext);

  if (value === undefined) {
    throw new Error('`FeatureControlContext` could not find any provider');
  }

  return value;
}

export function useFeatureControl<T = unknown>(featureKey: string) {
  return useGlobalFeatureControl()[featureKey] as Feature<T>;
}

// Feature Control
const defaultFc = { enabled: false, properties: {} };
function createProxy(value: FeatureControl) {
  return new Proxy(value, {
    get(obj, key: string) {
      return obj[key] ?? defaultFc;
    },
  });
}

import React, { MouseEvent, ReactNode } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import HeaderCreditLimit from 'credit-limit/components/HeaderCreditLimit/HeaderCreditLimit';

import {
  LocalizedLink,
  PaymentMethod,
  useAuth,
  useContentResource,
  useLocale,
  useTracker,
} from '@traveloka/ctv-core';
import { Dropdown, MenuItem } from '@traveloka/ctvweb-ui';
import Briefcase from '@traveloka/icon-kit-web/svg/blue/ic_link_to_careers_fill_16px.svg';
import Policy from '@traveloka/icon-kit-web/svg/blue/ic_policy_check_16px.svg';
import UserAccount from '@traveloka/icon-kit-web/svg/blue/ic_user_account-fill_24px.svg';
import SignOut from '@traveloka/icon-kit-web/svg/blue/ic_user_sign_out_log_out_24px.svg';
import Chevron from '@traveloka/icon-kit-web/svg/dark/ic_system_chevron_down_16px.svg';
import { Button, Icon, Token, useTheme } from '@traveloka/web-components';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import CompanyMenu from 'company/shared/component/CompanyMenu/CompanyMenu';
import ProfileMenu from 'profile/shared/components/ProfileMenu/ProfileMenu';
import ApprovalMenu from 'shared/components/ApprovalMenu/ApprovalMenu';
import LanguageMenu from 'shared/components/LanguageMenu/LanguageMenu';

type Props = {
  additionalInfoHeader?: ReactNode;
};

export default function Header(props: Props) {
  const { additionalInfoHeader } = props;
  const auth = useAuth();
  const { color } = useTheme();
  const { isSuspended } = useAccountStatus();
  const { locale } = useLocale();
  const wrapperStyle = {
    backgroundColor: color.lightPrimary,
  };
  const corporateHeaderStyle = {
    borderTopColor: color.uiBluePrimary,
  };

  const track = useTracker('landing-page');
  const content = useContentResource().CorporateHeader;

  function sendTracking(buttonName: string) {
    return track('corpB2b.landingPage.beforeLogin', {
      eventName: 'corpB2b.LandingPage',
      eventVersion: '1.0.0',
      pageName: 'HOME_PAGE',
      pageCategory: 'LANDING_PAGE',
      pageReferrer: 'HOME_PAGE',
      buttonName: buttonName.toUpperCase(),
      pageTitle: 'Landing Page',
    });
  }

  async function handleLoginPress() {
    await sendTracking('LOGIN').send();

    auth.login();
  }

  async function handleRegisterPress(e: MouseEvent<HTMLAnchorElement>) {
    const isPrevented = e.defaultPrevented;
    const href = e.currentTarget.href;

    await sendTracking('REGISTER').send();

    if (isPrevented) {
      window.location.href = href;
    }
  }

  const useInvoice = auth.user?.paymentMethod === PaymentMethod.INVOICE;

  return (
    <View nativeID="ctv-header" style={[Style.wrapper, wrapperStyle]}>
      {!isSuspended && additionalInfoHeader}
      <View style={[Style.corporateHeader, corporateHeaderStyle]}>
        <View style={Style.container}>
          <TouchableOpacity
            onPress={() =>
              (window.location.href = auth.isAuthenticated
                ? `/${locale}/home`
                : `/${locale}/`)
            }
          >
            <View testID="header.home-icon">
              <Icon
                src={'/img/traveloka-for-corporates.png'}
                width={279}
                height={28}
              />
            </View>
          </TouchableOpacity>
          <View style={Style.rightMenu}>
            {auth.isAuthenticated && (
              <Button
                testID="header.my-booking"
                style={Style.button}
                variant="text-black"
                text={content.myBookingButtonText}
                onPress={() => (window.location.href = `/${locale}/booking`)}
              />
            )}
            {<LanguageMenu testID="header" />}

            {!auth.isAuthenticated && (
              <>
                <Button
                  testID="pre-login.header.login"
                  variant="secondary"
                  text={content.loginButtonText}
                  style={Style.button}
                  onPress={handleLoginPress}
                />
                <LocalizedLink
                  to="/register"
                  style={Style.button}
                  onClick={handleRegisterPress}
                >
                  <Button
                    testID="pre-login.header.register"
                    text={content.registerButtonText}
                  />
                </LocalizedLink>
              </>
            )}
            {auth.isAuthenticated && (
              <>
                {auth.user.globalTripRequestApproval && (
                  <Dropdown
                    alignment="left"
                    trigger={
                      <Button
                        testID="header.menu.approval.button"
                        variant="secondary"
                        text={content.approvalButtonText}
                        iconStart={() => <Icon src={Policy} />}
                        iconEnd={() => <Icon src={Chevron} />}
                        style={Style.button}
                      />
                    }
                  >
                    <ApprovalMenu testID="header" />
                  </Dropdown>
                )}
                <Dropdown
                  alignment="right"
                  trigger={
                    <Button
                      testID="header.menu.company.button"
                      variant="secondary"
                      text={auth.user.corporateAlias}
                      iconStart={() => <Icon src={Briefcase} />}
                      iconEnd={useInvoice ? HeaderCreditLimit : undefined}
                      style={Style.button}
                    />
                  }
                >
                  <CompanyMenu
                    testID="header"
                    headerStyle={Style.companyMenuHeader}
                  />
                </Dropdown>
                <Dropdown
                  alignment="right"
                  trigger={
                    <Button
                      testID="header.menu.profile.button"
                      variant="secondary"
                      text={auth.user.name}
                      iconStart={() => (
                        <Icon src={UserAccount} width={16} height={16} />
                      )}
                      iconEnd={() => <Icon src={Chevron} />}
                      style={Style.button}
                    />
                  }
                >
                  <ProfileMenu
                    testID="header"
                    headerStyle={Style.companyMenuHeader}
                  >
                    <MenuItem
                      testID="header.menu.profile.logout"
                      leftIcon={SignOut}
                      text={content.logoutButtonText}
                      onPress={auth.logout}
                    />
                  </ProfileMenu>
                </Dropdown>
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    ...Token.elevation.float,
    top: 0,
    zIndex: 3,
    // @ts-ignore
    position: 'sticky',
  },
  corporateHeader: {
    borderTopWidth: 4,
    borderStyle: 'solid',
  },
  container: {
    width: '100%',
    maxWidth: 1240,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.l,
    marginHorizontal: 'auto',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightMenu: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginLeft: Token.spacing.s,
  },
  companyMenuHeader: {
    backgroundColor: Token.color.uiLightStain,
  },
  languageButton: {
    marginLeft: Token.spacing.s,
    minWidth: 55,
  },
  innerLanguageButton: {
    justifyContent: 'flex-start',
  },
});

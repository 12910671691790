import React, { useMemo } from 'react';

import { useContentResource } from '@traveloka/ctv-core/resource';

import { usePaymentStateFlight } from 'payment/select/contexts/PaymentContext';
import { convert, CurrencyValue } from 'shared/utils/currency';
import { formatCurrency, formatMessage } from 'shared/utils/intl';

import FlightDetailCard from '../DetailCard/FlightDetailCard';
import ErrorBoundary from './ErrorBoundary';
import PaymentSelectContent from './PaymentSelectContent';

type Breakdown = {
  testID?: string;
  label: string;
  value: string;
  isFree?: boolean;
};

const emptyFare: CurrencyValue = {
  amount: 0,
  currency: '',
  decimalPoints: 0,
};

export default function FlightPaymentSelectContent() {
  const { booking } = usePaymentStateFlight();
  const {
    paymentExpirationTimeInSecond,
    flightBookingDetail,
    grandTotal,
    totalPriceCharged,
    serviceFee,
    vatFee,
  } = booking;

  const content = useContentResource().CorporateFlightPayment;

  const [totalPrice, priceBreakdown] = useMemo(() => {
    const { journeys, passengers, fareDetail } = flightBookingDetail;

    const fare = {
      adults: convert(fareDetail.adultFare.totalFareWithCurrency),
      children: fareDetail.childFare
        ? convert(fareDetail.childFare.totalFareWithCurrency)
        : emptyFare,
      infants: fareDetail.infantFare
        ? convert(fareDetail.infantFare.totalFareWithCurrency)
        : emptyFare,
    };
    fare.adults.amount = fare.adults.amount * passengers.adults.length;
    fare.children.amount = fare.children.amount * passengers.children.length;
    fare.infants.amount = fare.infants.amount * passengers.infants.length;

    const breakdown: Breakdown[] = [
      {
        testID: 'confirmation-payment.content.adult-price.value',
        label: formatMessage(content.breakdownAdultFareLabel, {
          length: passengers.adults.length,
        }),
        value: formatCurrency(fare.adults),
      },
    ];

    if (passengers.children.length) {
      breakdown.push({
        testID: 'confirmation-payment.content.child-price.value',
        label: formatMessage(content.breakdownChildFareLabel, {
          length: passengers.children.length,
        }),
        value: formatCurrency(fare.children),
      });
    }

    if (passengers.infants.length) {
      breakdown.push({
        testID: 'confirmation-payment.content.child-price.value',
        label: formatMessage(content.breakdownInfantFareLabel, {
          length: passengers.infants.length,
        }),
        value: formatCurrency(fare.infants),
      });
    }

    journeys.forEach((journey, index) => {
      const { departureDetail, arrivalDetail } = journey;

      let value: CurrencyValue = {
        amount: 0,
        currency: '',
        decimalPoints: 0,
      };

      [...passengers.adults, ...passengers.children].forEach(adult => {
        const journeyAddon = adult.addOns[index];

        const routeBaggageAddon =
          journeyAddon.availableAddOnsOptions?.baggageOptions?.[0];

        const segmentAddons = journeyAddon.segmentsWithAvailableAddOns;

        if (routeBaggageAddon) {
          const val = convert(routeBaggageAddon.priceWithCurrency);

          value = {
            ...val,
            amount: value.amount + val.amount,
          };
        } else if (segmentAddons) {
          segmentAddons.forEach(segmentAddOn => {
            const segmentBaggageAddon =
              segmentAddOn.availableAddOnsOptions?.baggageOptions?.[0];

            if (segmentBaggageAddon) {
              const val = convert(segmentBaggageAddon.priceWithCurrency);

              value = {
                ...val,
                amount: value.amount + val.amount,
              };
            }
          });
        }
      });

      const isFree = value.amount === 0;

      breakdown.push({
        label: formatMessage(content.breakdownBaggageLabel, {
          departureAirportCode: departureDetail.airportCode,
          arrivalAirportCode: arrivalDetail.airportCode,
        }),
        isFree,
        value: isFree ? content.breakdownValueFree : formatCurrency(value),
      });
    });

    if (serviceFee) {
      breakdown.push({
        label: content.breakdownServiceFeeLabel,
        value: formatCurrency(convert(serviceFee)),
      });
    }

    if (vatFee) {
      breakdown.push({
        label: content.breakdownVatFeeLabel,
        value: formatCurrency(convert(vatFee)),
      });
    }

    const totalPrice = formatCurrency(convert(grandTotal || totalPriceCharged));
    return [totalPrice, breakdown];
  }, [flightBookingDetail, grandTotal, totalPriceCharged]);

  return (
    <PaymentSelectContent
      status={flightBookingDetail.status}
      expireOn={Number(paymentExpirationTimeInSecond)}
      priceBreakdown={priceBreakdown}
      totalPrice={totalPrice}
    >
      <ErrorBoundary
        componentName={'FlightDetailCard'}
        additionalAttributes={booking}
      >
        <FlightDetailCard />
      </ErrorBoundary>
    </PaymentSelectContent>
  );
}

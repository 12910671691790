import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Text, Token } from '@traveloka/web-components';

import { Traveler } from './types';

type Props = {
  children?: ReactNode;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  traveler: Traveler;
};

export default function TravelerPickerEntry(props: Props) {
  const { children, disabled, testID, traveler, style } = props;
  const subtitle = [traveler.division, traveler.email]
    .filter<string>((value): value is string => !!value)
    .join(' • ');

  const maybeMutedInk = disabled && 'muted';
  return (
    <View testID={testID} style={[Style.summary, style]}>
      {children && (
        <View style={Style.summaryWithIcon}>
          <Text
            style={[Style.textOverflow, Style.summaryText]}
            ink={maybeMutedInk || 'primary'}
          >
            {traveler.fullname}
          </Text>
          {children}
        </View>
      )}
      {!children && (
        <Text ink={maybeMutedInk || 'primary'}>{traveler.fullname}</Text>
      )}
      <Text
        variant="ui-small"
        ink={maybeMutedInk || 'secondary'}
        style={Style.textOverflow}
      >
        {subtitle}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  summary: {
    flex: 1,
  },
  summaryWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  summaryText: {
    marginRight: Token.spacing.xs,
    flex: 1,
  },
});

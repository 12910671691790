import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Badge, Icon, Text, Token } from '@traveloka/web-components';

import { VerificationStatus } from 'registration/api/types';

type Props = {
  status: VerificationStatus;
  label: string;
  desc: string;
  icon: string;
};

export default function RenderVerificationStep(props: Props) {
  const { icon, desc, label, status } = props;

  return (
    <View style={styles.container}>
      <Icon src={icon} width={40} height={40} />
      <View style={styles.textContainer}>
        <Text
          style={styles.label}
          variant="title-3"
          ink={status === 'NOT_YET' ? 'secondary' : 'primary'}
        >
          {label}
        </Text>
        <Text variant="ui-small" ink="secondary">
          {desc}
        </Text>
      </View>
      <RenderStatus status={status} />
    </View>
  );
}

type RenderStatusProps = {
  status: VerificationStatus;
};
function RenderStatus(props: RenderStatusProps) {
  const { status } = props;

  const cr = useContentResource().CorporateRegistration;

  switch (status) {
    case 'COMPLETED':
      return (
        <View style={styles.status}>
          <Badge variant="positive" text={cr.completedLabel} />
        </View>
      );

    case 'ON_GOING':
      return (
        <View style={styles.status}>
          <Badge variant="info" text={cr.onGoingLabel} />
        </View>
      );

    case 'NOT_YET':
    default:
      return null;
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContainer: {
    marginLeft: Token.spacing.ml,
    flex: 1,
  },
  label: {
    marginBottom: Token.spacing.s,
  },
  status: {
    marginLeft: 'auto',
    paddingLeft: Token.spacing.m,
  },
});

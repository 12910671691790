import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useApi, useContentResource } from '@traveloka/ctv-core';
import { ApiResult } from '@traveloka/ctv-core/api/types';

import AccountSuspendedPopupContainer from 'account-status/components/AccountSuspendedPopup/AccountSuspendedPopup';
import { useProductRequest } from 'approval-system/shared/contexts/ProductRequestContext';
import { FlightPrebookStore } from 'flight/prebook/utils/store';
import { useSearchSpec } from 'flight/search/contexts/SearchSpecContext';
import { useStaticData } from 'flight/search/contexts/StaticDataContext';
import useFlightSearchReducer from 'flight/search/reducers/flight-search-reducer';
import {
  getBasicSearchSpec,
  getSmartComboSearchSpec,
} from 'flight/search/utils/flight-search-spec-util';
import {
  FlightSearchData,
  NormalFlightSearchData,
  getTripIdentifier,
  useCreateFlights,
  useCreateSmartComboFlights,
} from 'flight/search/utils/flight-search-view-util';
import { flightPollingMechanism } from 'flight/search/utils/flightPollingMechanism';
import {
  FlightSearchRequest,
  FlightSearchResponse,
  ONE_WAY_API,
  ROUND_TRIP_API,
  SMART_COMBO_API,
  SmartComboSearchRequest,
  SmartComboSearchResponse,
} from 'flight/shared/api';
import {
  FlightSearchResult,
  Journey,
  SmartComboSearchResult,
} from 'flight/shared/api/types';
import JourneyType from 'flight/shared/constants/journey-type';

import FlightCheckBookingModal from '../FlightCheckBookingModal/FlightCheckBookingModal';
import FlightSearchHeader from '../FlightSearchHeader/FlightSearchHeader';
import FlightSearchResultBlock from '../FlightSearchResultBlock/FlightSearchResultBlock';
import FlightSearchResultBlockReturn from '../FlightSearchResultBlock/FlightSearchResultBlockReturn';

export type CheckBookingData = {
  isVisible: boolean;
} & Omit<FlightPrebookStore, 'preBookingStatus' | 'approverReason'>;

const initCheckBookingData: CheckBookingData = {
  isVisible: false,
  flights: undefined!,
  journeyType: undefined!,
  passengers: undefined!,
  nonEmployeeTravelers: undefined!,
  searchId: undefined!,
  searchSpec: undefined!,
};

export default function FlightSearchContent() {
  const [checkBookingData, setCheckBookingData] = useState<CheckBookingData>(
    initCheckBookingData
  );
  const memoFlightSearch = useRef<FlightSearchResponse>();
  const memoFlightScSearch = useRef<SmartComboSearchResponse>();

  const contentResource = useContentResource();

  const createFlights = useCreateFlights();
  const createSmartComboFlights = useCreateSmartComboFlights();
  const searchSpec = useSearchSpec();
  const productRequest = useProductRequest();
  const [departState, departAction] = useFlightSearchReducer();
  const staticData = useStaticData();
  const showDepart = !searchSpec.isRoundTrip || !departState.selected;

  const searchOneWay = useApi<FlightSearchResponse, FlightSearchRequest>({
    domain: 'search',
    method: 'post',
    path: ONE_WAY_API,
  });

  const searchRoundTrip = useApi<FlightSearchResponse, FlightSearchRequest>({
    domain: 'search',
    method: 'post',
    path: ROUND_TRIP_API,
  });

  const searchSmartCombo = useApi<
    SmartComboSearchResponse,
    SmartComboSearchRequest
  >({
    domain: 'search',
    method: 'post',
    path: SMART_COMBO_API,
  });

  // Depart
  const handleSelectDepart = useCallback(
    (flight: FlightSearchData) => {
      if (searchSpec.isRoundTrip) {
        departAction.select(flight);

        return;
      }

      let tripRequestId: string | undefined;
      let productRequestId: string | undefined;
      let tripName: string | undefined;
      let approverName: string | undefined;

      if (productRequest.enabled) {
        tripRequestId = productRequest.data?.tripRequestId;
        productRequestId = productRequest.data?.productRequestId;
        tripName = productRequest.data?.tripName;
        approverName = productRequest.data?.approverName;
      }

      setCheckBookingData({
        isVisible: true,
        searchId: departState.searchId,
        journeyType: JourneyType.ONE_WAY,
        passengers: searchSpec.passengers,
        nonEmployeeTravelers: searchSpec.nonEmployeeTravelers,
        flights: [(flight as unknown) as NormalFlightSearchData],
        productRequestId,
        tripName,
        approverName,
        tripRequestId,
        searchSpec: window.location.search,
      });
    },
    [departState.searchId, departAction, searchSpec, productRequest]
  );

  async function fetchSearchResult() {
    const {
      passengers,
      nonEmployeeTravelers,
      isInternational,
      isRoundTrip,
    } = searchSpec;

    if (passengers.length === 0 && nonEmployeeTravelers.adults === 0) {
      return true;
    }

    const search = isRoundTrip ? searchRoundTrip : searchOneWay;

    const hasSmartCombo =
      isRoundTrip && isInternational && !memoFlightScSearch.current;

    return await Promise.all([
      !memoFlightSearch.current
        ? search(getBasicSearchSpec(searchSpec, true))
        : null,
      hasSmartCombo
        ? searchSmartCombo(getSmartComboSearchSpec(searchSpec))
        : undefined,
    ])
      .then(values => {
        const [flightSearch, smartComboSearch] = values;
        const usedFlightSearch: ApiResult<FlightSearchResponse> = flightSearch || {
          data: memoFlightSearch.current!,
          success: true,
          trackingSpec: null,
        };
        const usedSmartComboSearch: ApiResult<SmartComboSearchResponse> = smartComboSearch || {
          data: memoFlightScSearch.current ?? {
            completed: true,
            departureFlightDetail: [],
            flightTable: [],
            returnFlightDetail: [],
          },
          success: true,
          trackingSpec: null,
        };

        if (flightSearch?.success && flightSearch.data.completed) {
          memoFlightSearch.current = flightSearch.data;
        }

        if (smartComboSearch?.success && smartComboSearch.data.completed) {
          memoFlightScSearch.current = smartComboSearch.data;
        }

        if (usedFlightSearch.success) {
          const searchId = usedFlightSearch.trackingSpec
            ? usedFlightSearch.trackingSpec.id
            : null;

          if (!isRoundTrip) {
            const flightResults = createFlights(
              searchSpec,
              usedFlightSearch.data.searchResults,
              staticData,
              contentResource
            );
            departAction.result(flightResults, searchId);
            return usedFlightSearch.data.completed;
          }

          let isCompleted = usedFlightSearch.data.completed;
          if (usedSmartComboSearch.success) {
            isCompleted =
              usedFlightSearch.data.completed &&
              usedSmartComboSearch.data.completed;

            const {
              departureFlightDetail: smartComboDeparture,
            } = usedSmartComboSearch.data;
            const flattenSmartComboDeparture = flattenSegments(
              smartComboDeparture
            );

            const flightNotInSCResult: FlightSearchResult[] = [];
            const flightInSCResult: FlightSearchResult[] = [];

            usedFlightSearch.data.searchResults.forEach(flightResult => {
              const [flightKeySegments, isNotInSmartCombo] = getSegmentResult(
                flightResult,
                flattenSmartComboDeparture
              );
              if (isNotInSmartCombo) {
                flightNotInSCResult.push(flightResult);
              } else {
                const flightIsCheaper = isCheaperFlight(
                  flightResult,
                  flattenSmartComboDeparture[flightKeySegments]
                );

                if (flightIsCheaper) {
                  const scDepartureIndex = getSCDepartureIndex(
                    smartComboDeparture,
                    flightKeySegments
                  );

                  smartComboDeparture[
                    scDepartureIndex
                  ] = replaceSCPriceWithCheaperPrice(
                    smartComboDeparture[scDepartureIndex],
                    flightResult.journeys
                  );
                }

                flightInSCResult.push(flightResult);
              }
            });

            const flightData = createFlights(
              searchSpec,
              flightNotInSCResult,
              staticData,
              contentResource
            );

            const smartComboData = createSmartComboFlights(
              searchSpec,
              smartComboDeparture,
              flightInSCResult,
              staticData,
              contentResource
            );

            const combinedData = ([] as FlightSearchData[]).concat(
              flightData,
              smartComboData
            );

            departAction.result(combinedData, searchId);
            departAction.smartCombo([], usedSmartComboSearch.data);
          } else if (!usedSmartComboSearch.success) {
            if (usedSmartComboSearch) {
              console.error(usedSmartComboSearch.error.message);
            }
            departAction.result(
              createFlights(
                searchSpec,
                usedFlightSearch.data.searchResults,
                staticData,
                contentResource
              ),
              searchId
            );
            departAction.smartCombo([], null);
          }

          return isCompleted;
        } else {
          console.error(usedFlightSearch.error.message);
        }
        return false;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  }

  useEffect(() => {
    const timeoutId = flightPollingMechanism(fetchSearchResult);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <FlightSearchHeader
        onSearch={departAction.reset}
        isLoading={departState.isLoading}
      />

      {showDepart && (
        <FlightSearchResultBlock
          isLoading={departState.isLoading}
          results={departState.results}
          onSelect={handleSelectDepart}
        />
      )}
      {!showDepart && (
        <FlightSearchResultBlockReturn
          departFlight={departState.selected!}
          onChangeFlightPress={departAction.cancel}
          smartComboResponse={departState.smartComboResponse}
          setCheckBookingData={setCheckBookingData}
        />
      )}

      <FlightCheckBookingModal
        {...checkBookingData}
        onClose={() =>
          setCheckBookingData(prevValue => ({ ...prevValue, isVisible: false }))
        }
      />
      <AccountSuspendedPopupContainer backToHome />
    </>
  );
}

// Functions
function flattenSegments(flightDetail: SmartComboSearchResult[]) {
  const flattenSmartComboSearch: Record<string, SmartComboSearchResult> = {};
  flightDetail.forEach(departureFlight => {
    const keySegments = getTripIdentifier(departureFlight.journeys);
    flattenSmartComboSearch[keySegments] = departureFlight;
  });
  return flattenSmartComboSearch;
}

function getSegmentResult(
  result: FlightSearchResult,
  flattenSmartComboSearch: any
): [string, boolean] {
  const keySegments = getTripIdentifier(result.journeys);
  const isNotInSmartCombo = !(keySegments in flattenSmartComboSearch);

  return [keySegments, isNotInSmartCombo];
}

function getTotalPrice(result: FlightSearchResult | SmartComboSearchResult) {
  let amount = 0;
  result.journeys.forEach(
    journey =>
      (amount += Number(
        journey.fareInfo.partnerFare.adultFare.totalFareWithCurrency
          .displayAmount
      ))
  );
  return amount;
}

function isCheaperFlight(
  flightResult: FlightSearchResult,
  smartComboResult: SmartComboSearchResult
) {
  const flightPrice = getTotalPrice(flightResult);
  const smartComboPrice = getTotalPrice(smartComboResult);

  return flightPrice < smartComboPrice;
}

function getSCDepartureIndex(
  smartComboSearchResults: SmartComboSearchResult[],
  keySegment: string
) {
  return smartComboSearchResults.findIndex(
    result => getTripIdentifier(result.journeys) === keySegment
  );
}

function replaceSCPriceWithCheaperPrice(
  smartComboSearchResult: SmartComboSearchResult,
  flightSearchJourneys: Journey[]
) {
  return {
    ...smartComboSearchResult,
    journeys: smartComboSearchResult.journeys.map((journey, index) => {
      const { adultFare, childFare, infantFare } = flightSearchJourneys[
        index
      ].fareInfo.partnerFare;

      const result: Journey = {
        ...journey,
        fareInfo: {
          partnerFare: {
            ...journey.fareInfo.partnerFare,
            adultFare: {
              ...journey.fareInfo.partnerFare.adultFare,
              baseFareWithCurrency: adultFare.totalFareWithCurrency,
              totalFareWithCurrency: adultFare.totalFareWithCurrency,
            },
          },
          airlineFare: {
            ...journey.fareInfo.partnerFare,
            adultFare: {
              ...journey.fareInfo.partnerFare.adultFare,
              baseFareWithCurrency: adultFare.totalFareWithCurrency,
              totalFareWithCurrency: adultFare.totalFareWithCurrency,
            },
          },
        },
      };

      if (journey.fareInfo.partnerFare.childFare && childFare) {
        result.fareInfo.partnerFare = {
          ...result.fareInfo.partnerFare,
          childFare: {
            ...journey.fareInfo.partnerFare.childFare,
            baseFareWithCurrency: childFare.totalFareWithCurrency,
            totalFareWithCurrency: childFare.totalFareWithCurrency,
          },
        };
        result.fareInfo.airlineFare = {
          ...result.fareInfo.airlineFare,
          childFare: {
            ...journey.fareInfo.partnerFare.childFare,
            baseFareWithCurrency: childFare.totalFareWithCurrency,
            totalFareWithCurrency: childFare.totalFareWithCurrency,
          },
        };
      }

      if (journey.fareInfo.partnerFare.infantFare && infantFare) {
        result.fareInfo.partnerFare = {
          ...result.fareInfo.partnerFare,
          infantFare: {
            ...journey.fareInfo.partnerFare.infantFare,
            baseFareWithCurrency: infantFare.totalFareWithCurrency,
            totalFareWithCurrency: infantFare.totalFareWithCurrency,
          },
        };
        result.fareInfo.airlineFare = {
          ...result.fareInfo.airlineFare,
          infantFare: {
            ...journey.fareInfo.partnerFare.infantFare,
            baseFareWithCurrency: infantFare.totalFareWithCurrency,
            totalFareWithCurrency: infantFare.totalFareWithCurrency,
          },
        };
      }

      return result;
    }),
  };
}

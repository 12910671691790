import React, { Fragment } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useLocale } from '@traveloka/ctv-core';
import {
  Badge,
  Icon,
  Image,
  Text,
  Token,
  useTheme,
} from '@traveloka/web-components';

import IcSystemCheckmark from '@traveloka/icon-kit-web/svg/light/ic_system_checkmark_12px.svg';

type Props = {
  current: number;
  steps: string[];
};

export default function BookingHeader(props: Props) {
  const { current, steps } = props;

  const { locale } = useLocale();
  const { color } = useTheme();
  const wrapperStyle = {
    backgroundColor: color.lightPrimary,
    borderTopColor: color.uiBluePrimary,
  };
  const separatorStyle = { backgroundColor: color.lightSecondary };

  return (
    <View style={[Style.wrapper, wrapperStyle]}>
      <View style={Style.container}>
        <TouchableOpacity
          onPress={() => (window.location.href = `/${locale}/`)}
        >
          <Image
            src="/img/traveloka-for-corporates.png"
            width={279}
            height={28}
          />
        </TouchableOpacity>
        <View style={Style.steps}>
          {steps.map((step, index) => (
            <Fragment key={index}>
              {index > 0 && <View style={[Style.separator, separatorStyle]} />}
              <View style={Style.number}>
                {index < current ? (
                  <View style={Style.checkmark}>
                    <Icon src={IcSystemCheckmark} width={16} />
                  </View>
                ) : (
                  <Badge
                    variant={index === current ? 'info' : 'neutral-subtle'}
                    text={String(index + 1)}
                  />
                )}
              </View>
              <Text variant="ui-small" ink={'secondary'} style={Style.text}>
                {step}
              </Text>
            </Fragment>
          ))}
        </View>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    ...Token.elevation.float,
    top: 0,
    zIndex: 3,
    // @ts-ignore
    position: 'sticky',
    borderTopWidth: 4,
    borderStyle: 'solid',
  },
  container: {
    width: '100%',
    maxWidth: 1640,
    paddingVertical: Token.spacing.m,
    paddingHorizontal: Token.spacing.l,
    marginHorizontal: 'auto',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  steps: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    marginHorizontal: Token.spacing.s,
    height: Token.border.width.bold,
    width: Token.spacing.xxl,
  },
  number: {
    width: 24,
    height: 24,
    // @ts-ignore
    userSelect: 'none',
  },
  text: {
    marginLeft: Token.spacing.xs,
  },
  checkmark: {
    backgroundColor: Token.color.uiGreenPrimary,
    borderRadius: Token.border.radius.rounded,
    padding: Token.spacing.xxs,
  },
});

import React from 'react';
import { StyleSheet } from 'react-native';
import { parse } from 'qs';

import { useApi, useContentResource } from '@traveloka/ctv-core';
import ChevronLeft from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_left_16px.svg';
import { Button, Card, Icon, Text, Token } from '@traveloka/web-components';

import { useHotelRequestRefundNavigator } from 'refund/request-refund/contexts/hotel/HotelRequestRefundNavigatorContext';
import InfoBox from 'refund/shared/components/InfoBox';

import { RefundRequestQs } from 'postbooking/booking-detail/components/ProductRefundDetail/ProductRefundDetail';
import ProductType from 'postbooking/shared/constants/ProductType';
import {
  HotelSubmitRefundRequest,
  SubmitRefundHotelResponse,
  SUBMIT_REQUEST_REFUND_API,
} from 'refund/shared/api';
import { HotelSubmitRefundError } from 'refund/shared/api/types';
import { useHotelRequestRefundForm } from 'refund/request-refund/contexts/hotel/HotelRequestRefundFormContext';
import {
  RequestRefundStep,
  useProductRequestRefundPage,
} from 'refund/request-refund/contexts/ProductRequestRefundPageContext';
import { useHotelRequestRefund } from 'refund/request-refund/contexts/hotel/HotelRequestRefundContext';

import HotelGuests from '../select-details/HotelGuests/HotelGuests';
import RefundHotelDetail from '../select-details/RefundHotelDetail/RefundHotelDetail';
import RefundReason from './RefundReason/RefundReason';
import HotelErrorModal from './HotelErrorModal/HotelErrorModal';

export default function HotelRequestRefundReview() {
  const qs: RefundRequestQs = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const [
    _,
    { changeCurrentStep, goToBookingDetail, setError },
  ] = useProductRequestRefundPage();
  const { goToPreviousStep } = useHotelRequestRefundNavigator();
  const { mainRefundReason } = useHotelRequestRefundForm();
  const [{ isSubmitting }, { setIsSubmitting }] = useHotelRequestRefund();

  const content = useContentResource().CorporateHotelRequestRefundReview;

  const submitRefund = useApi<
    SubmitRefundHotelResponse,
    HotelSubmitRefundRequest
  >({
    domain: 'refund',
    method: 'post',
    path: SUBMIT_REQUEST_REFUND_API,
  });

  function handleSubmitRefund() {
    setIsSubmitting(true);

    const payload: HotelSubmitRefundRequest = {
      bookingId: qs.bookingId,
      productType: ProductType.HOTEL,
      hotelRefundSubmission: {
        mainReason: mainRefundReason!.mainReason,
        additionalInformation: mainRefundReason!.additionalInformation,
      },
    };

    submitRefund(payload)
      .then(res => {
        if (res.success) {
          setError(res.data.hotelRefundSubmission.errorMessage);

          if (res.data.hotelRefundSubmission.status === 'OK') {
            goToBookingDetail();
          } else if (
            res.data.hotelRefundSubmission.errorMessage ===
            HotelSubmitRefundError.INVALID_SPEC
          ) {
            changeCurrentStep(RequestRefundStep.SELECT_DETAILS);
            window.scrollTo(0, 0);
          }
        }
      })
      .finally(() => setIsSubmitting(false));
  }

  return (
    <>
      <Button
        style={Style.buttonBack}
        size="small"
        variant="text"
        text={content.backButtonText}
        iconStart={() => <Icon src={ChevronLeft} />}
        onPress={goToPreviousStep}
      />
      <Text style={Style.headline} variant="headline">
        {content.reviewHeadline}
      </Text>
      <Text style={Style.title} variant="title-1">
        {content.refundItemTitle}
      </Text>
      <Card style={Style.hotelDetailCard}>
        <RefundHotelDetail />
      </Card>
      <Text style={Style.title} variant="title-1">
        {content.refundReason}
      </Text>
      <Card style={Style.refundReasonContainer}>
        <RefundReason />
      </Card>
      <Text style={Style.title} variant="title-1">
        {content.passengerTitle}
      </Text>
      <Card style={Style.guestContainer}>
        <HotelGuests />
      </Card>
      <Text style={Style.title} variant="title-1">
        {content.refundableAmountTitle}
      </Text>
      <Card style={Style.refundableAmountContainer}>
        <InfoBox
          style={Style.refundableAmountContent}
          text={content.refundableAmountContent}
          verticalAlign="top"
        />
      </Card>
      <Button
        style={Style.buttonContinue}
        variant="main-cta"
        text={content.submitButtonText}
        onPress={handleSubmitRefund}
        loading={isSubmitting}
      />
      <HotelErrorModal />
    </>
  );
}

const Style = StyleSheet.create({
  buttonBack: {
    marginBottom: Token.spacing.m,
    width: 80,
  },
  headline: {
    marginBottom: Token.spacing.xl,
  },
  title: {
    marginBottom: Token.spacing.m,
  },
  hotelDetailCard: {
    marginBottom: Token.spacing.xxl,
  },
  refundReasonContainer: {
    padding: Token.spacing.l,
    marginBottom: Token.spacing.xxl,
  },
  guestContainer: {
    paddingHorizontal: Token.spacing.l,
    paddingTop: Token.spacing.m,
    paddingBottom: Token.spacing.l,
    marginBottom: Token.spacing.xxl,
  },
  refundableAmountContainer: {
    padding: Token.spacing.m,
    marginBottom: Token.spacing.l,
  },
  refundableAmountContent: {
    borderColor: Token.color.uiBluePrimary,
    borderWidth: Token.border.width.thick,
  },
  buttonContinue: {
    marginLeft: 'auto',
    width: 304,
  },
});

import { createContext, useContext } from 'react';

import { ValidConfirmation } from 'registration/api/types';

const PreRegisterConfirmContext = createContext<Nullable<ValidConfirmation>>(
  null
);

export const PreRegisterConfirmProvider = PreRegisterConfirmContext.Provider;

export function usePreRegisterConfirm() {
  return useContext(PreRegisterConfirmContext);
}

import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const PurchaseDetail: PrivatePageComponent = function() {
  return null;
};

PurchaseDetail.routeProtection = function({ params }) {
  if (params.tripId !== undefined) {
    return { path: '/booking/purchase/' + params.tripId };
  }

  return { path: '/booking' };
};

export default PurchaseDetail;

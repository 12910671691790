const ALLOWED_SOURCES = [
  'SEARCH_FORM',
  'SEARCH_RESULT',
  'SEARCH_RESULT_SF',
  'HOTEL_DETAIL_SF',
  'OTHERS',
];

export function validateSource(source: string = '') {
  return ALLOWED_SOURCES.includes(source) ? source : 'OTHERS';
}

import React, { useState, useMemo, useRef } from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Easing,
  Animated,
} from 'react-native';

import { Token, Icon } from '@traveloka/web-components';

import IcSystemChevronLeft from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_left_24px.svg';
import IcSystemChevronRight from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_right_24px.svg';

type ImageCarouselProps<T> = {
  lists: T[];
  column: number;
  containerHeight: number;
  renderItem: (renderItemProps: { item: T; index: number }) => void;
};

function ImageCarousel<T>(props: ImageCarouselProps<T>) {
  const { lists, column, containerHeight, renderItem } = props;

  const [firstShownImageIndex, setFirstShownImageIndex] = useState(0);

  const columns = Math.floor(column);
  const widthPercentage = 100 / columns;

  const showLeftBtn = firstShownImageIndex > 0;
  const showRightBtn = firstShownImageIndex < lists.length - columns;

  const interpolationRange = useMemo(() => {
    if (lists.length <= columns) {
      return {
        inputRange: [0, 0],
        outputRange: [0, 0],
      };
    }

    // Input range length determines how many times we can slide the carousel to the left (by pressing next button)
    // Output range determines how far (in percentage) we should slide the carousel for each step
    return {
      inputRange: Array(lists.length - columns + 1)
        .fill(0)
        .map((_, i) => i),
      outputRange: Array(lists.length - columns + 1)
        .fill(0)
        .map((_, i) => `${-1 * (Math.floor(i * widthPercentage * 10) / 10)}%`), // round to 1 decimal place
    };
  }, [lists.length, columns]);

  const offset = useRef(new Animated.Value(0));

  function handleButtonPress(nextOffset: -1 | 1) {
    return () => {
      if (
        (nextOffset === 1 && firstShownImageIndex < lists.length - columns) ||
        (nextOffset === -1 && firstShownImageIndex > 0)
      ) {
        Animated.timing(offset.current, {
          easing: Easing.out(Easing.ease),
          toValue: firstShownImageIndex + nextOffset,
          duration: Token.animation.timing.normal,
        }).start();

        setFirstShownImageIndex(firstShownImageIndex + nextOffset);
      }
    };
  }

  return (
    <View style={Style.wrapper}>
      {showLeftBtn && (
        <TouchableOpacity
          style={[Style.button, Style.leftButton]}
          onPress={handleButtonPress(-1)}
        >
          <Icon src={IcSystemChevronLeft} width={16} />
        </TouchableOpacity>
      )}
      <View
        style={[
          Style.rowContainer,
          showLeftBtn
            ? Style.contentLeftActionSpacing
            : Style.contentLeftSpacing,
          showRightBtn
            ? Style.contentRightActionSpacing
            : Style.contentRightSpacing,
          { height: containerHeight },
        ]}
      >
        <Animated.View
          style={[
            Style.carousel,
            {
              transform: [
                {
                  translateX: offset.current.interpolate({
                    inputRange: interpolationRange.inputRange,
                    outputRange: interpolationRange.outputRange,
                  }),
                },
              ],
            },
          ]}
        >
          {lists.map((item, itemIdx) => (
            <View
              style={[
                Style.item,
                {
                  width: `${widthPercentage}%`,
                  paddingLeft: itemIdx > 0 ? Token.spacing.xs : 0,
                },
              ]}
            >
              {renderItem({ item, index: itemIdx })}
            </View>
          ))}
        </Animated.View>
      </View>
      {showRightBtn && (
        <TouchableOpacity
          style={[Style.button, Style.rightButton]}
          onPress={handleButtonPress(1)}
        >
          <Icon src={IcSystemChevronRight} width={16} />
        </TouchableOpacity>
      )}
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  carousel: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  item: {
    paddingVertical: Token.spacing.s,
  },
  contentLeftSpacing: {
    paddingLeft: Token.spacing.xs,
  },
  contentRightSpacing: {
    paddingRight: Token.spacing.xs,
  },
  contentLeftActionSpacing: {
    paddingLeft: Token.spacing.m,
  },
  contentRightActionSpacing: {
    paddingRight: Token.spacing.m,
  },
  rowContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  button: {
    ...Token.elevation.float,
    position: 'absolute',
    backgroundColor: Token.color.uiLightPrimary,
    borderRadius: 25,
    padding: Token.spacing.xs,
    zIndex: 2,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  leftButton: {
    left: 0,
  },
  rightButton: {
    right: 0,
  },
});

export default ImageCarousel;

import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { useTheme, Text, Token } from '@traveloka/web-components';
import { useHoverable } from '@traveloka/web-components/future';

type DropdownItemProps = {
  testID?: string;
  label: string;
  subLabel?: string;
  isActive: boolean;
  onPress: () => void;
};

export default function InputDropdownItem(props: DropdownItemProps) {
  const { testID, label, subLabel, isActive, onPress } = props;

  const { color } = useTheme();
  const [isHovered, event] = useHoverable();
  const hoverStyle = {
    backgroundColor: color.lightStain,
  };

  return (
    <TouchableOpacity
      {...event}
      testID={testID}
      onPress={onPress}
      style={[ItemStyle.container, (isHovered || isActive) && hoverStyle]}
    >
      <Text>{label}</Text>
      {typeof subLabel === 'string' && (
        <Text variant="ui-tiny" ink="secondary">
          {subLabel}
        </Text>
      )}
    </TouchableOpacity>
  );
}

const ItemStyle = StyleSheet.create({
  container: {
    cursor: 'pointer',
    padding: Token.spacing.xs,
  },
});

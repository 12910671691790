// TODO: Make this generic later, and move it to a more suitable directory.

import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { View } from 'react-native';

import { Text } from '@traveloka/web-components';
import config from '@traveloka/ctv-core/config';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  componentName: string;
  additionalAttributes?: Record<string, any>;
  renderError?: () => JSX.Element;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: Readonly<ErrorBoundaryProps>) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: React.ErrorInfo) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (config.datadog.env === 'prod') {
      try {
        const renderingError = new Error(error.message);
        renderingError.name = `ReactRenderingError`;
        renderingError.stack = errorInfo.componentStack;
        // @ts-ignore following Datadog browser error collection guideline
        renderingError.cause = error;

        const additionalAttributes: Record<string, any> = JSON.parse(
          JSON.stringify(this.props.additionalAttributes || {})
        );

        // Delete unnecessary fields (Flight).
        delete additionalAttributes.pnrCode;

        // Delete unnecessary fields (Hotel).
        delete additionalAttributes.contactDetail;
        delete additionalAttributes.guests;

        datadogRum.addError(error, {
          additionalAttributes,
          errorInfo,
          componentName: this.props.componentName,
        });
      } catch (error) {
        // No op.
      }
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.props.renderError) {
        return this.props.renderError();
      }
      return (
        <View>
          <Text>Unexpected component error happened.</Text>
        </View>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React, { FC } from 'react';
import { FlatList } from 'react-native';

import { Card } from '@traveloka/web-components';

import { appendTestId } from '../../../utils/TestUtil';
import InputDropdownItem from './InputDropdownItem';
import { Item } from './types';

export type ItemComponentType = {
  testID?: string;
  isActive: boolean;
  onPress(): void;
} & Item;

export type Props<T> = {
  testID?: string;
  items: T[];
  ItemComponent?: FC<ItemComponentType>;
  onPressItem: (item: T) => void;
  activeIndex: number;
  dropdownHeight?: number;
};

export default function InputDropdownList<T extends Item>(props: Props<T>) {
  const {
    testID,
    items,
    onPressItem,
    activeIndex,
    dropdownHeight = 350,
    ItemComponent,
  } = props;

  return (
    <Card elevation="float">
      <FlatList
        style={{ maxHeight: dropdownHeight }}
        initialNumToRender={10}
        maxToRenderPerBatch={10}
        data={items}
        extraData={activeIndex}
        windowSize={3}
        keyExtractor={(item, index) => `${index}-${item.value}`}
        renderItem={({ item, index }) =>
          ItemComponent ? (
            <ItemComponent
              {...item}
              testID={appendTestId(testID, `list.${index}`)}
              isActive={activeIndex === index}
              onPress={() => onPressItem(item)}
            />
          ) : (
            <InputDropdownItem
              testID={appendTestId(testID, `list.${index}`)}
              label={item.label}
              subLabel={item.subLabel}
              isActive={activeIndex === index}
              onPress={() => onPressItem(item)}
            />
          )
        }
      />
    </Card>
  );
}

import React from 'react';

import {
  useProductRequestRefundPage,
  RequestRefundStep,
} from 'refund/request-refund/contexts/ProductRequestRefundPageContext';

import FlightRequestRefundDocument from '../FlightRequestRefundSteps/document/FlightRequestRefundDocument';
import FlightRequestRefundPolicy from '../FlightRequestRefundSteps/policy/FlightRequestRefundPolicy';
import FlightRequestRefundReview from '../FlightRequestRefundSteps/review/FlightRequestRefundReview';
import FlightRequestRefundSelectDetails from '../FlightRequestRefundSteps/select-details/FlightRequestRefundSelectDetails';

export default function FlightRequestRefund() {
  const [{ currentStep }] = useProductRequestRefundPage();

  switch (currentStep) {
    case RequestRefundStep.REFUND_POLICY:
      return <FlightRequestRefundPolicy />;
    case RequestRefundStep.SELECT_DETAILS:
      return <FlightRequestRefundSelectDetails />;
    case RequestRefundStep.REFUND_DOCUMENT:
      return <FlightRequestRefundDocument />;
    case RequestRefundStep.REVIEW_REQUEST:
      return <FlightRequestRefundReview />;
  }
}

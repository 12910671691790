import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { useTheme, Text, Token } from '@traveloka/web-components';

type Props = {
  isReturnFlight: boolean;
  title: string;
  date: string;
  route: string;
  style?: ViewProps['style'];
};

export default function FlightSearchResultHeader(props: Props) {
  const { isReturnFlight, title, date, route, style } = props;
  const { color } = useTheme();

  return (
    <View
      style={[
        Style.container,
        {
          backgroundColor: color.tintPrimary,
        },
        style,
      ]}
    >
      <Text
        variant="hero"
        style={{
          color: color.lightPrimary,
        }}
      >
        {Number(isReturnFlight) + 1}
      </Text>
      <View
        style={[
          Style.line,
          {
            backgroundColor: color.lightPrimary,
          },
        ]}
      />
      <View style={Style.right}>
        <Text
          variant="ui-small"
          style={[
            {
              color: color.lightPrimary,
            },
            Style.title,
          ]}
        >
          {title}
        </Text>
        <Text
          variant="ui-tiny"
          style={{
            color: color.lightPrimary,
          }}
        >
          {date}
        </Text>
        <Text
          variant="ui-tiny"
          style={{
            color: color.lightPrimary,
          }}
        >
          {route}
        </Text>
      </View>
    </View>
  );
}

FlightSearchResultHeader.defaultProps = {
  isReturnFlight: false,
};

const Style = StyleSheet.create({
  container: {
    padding: Token.spacing.ml,
    flexDirection: 'row',
    alignItems: 'center',
  },
  line: {
    alignSelf: 'stretch',
    width: Token.border.width.bold,
    marginHorizontal: Token.spacing.m,
  },
  right: {
    flex: 1,
  },
  title: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
});

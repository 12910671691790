import React from 'react';
import { TouchableOpacity } from 'react-native';

import { useLocalizedDateFormat } from '@traveloka/ctv-core';
import { Divider } from '@traveloka/ctvweb-ui';
import { FlightSegment } from '@traveloka/ctvweb-ui/refund';

import { ONE_WAY_SEPARATOR } from 'flight/search/constants/flight-search-constant';
import { useFlightRequestRefund } from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';
import {
  useFlightRequestRefundForm,
  useFlightRequestRefundFormAction,
} from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';

const DOT = '•';

export default function RefundFlightJourneys() {
  const { flightCheckboxes } = useFlightRequestRefundForm();
  const { updateFlightCheckboxes } = useFlightRequestRefundFormAction();
  const [{ data }] = useFlightRequestRefund();
  const { flightRefundData, isPartialJourney } = data;

  const { format } = useLocalizedDateFormat();

  return (
    <>
      {flightRefundData.journeys.map((journey, journeyIndex) => {
        function changeCheckbox() {
          updateFlightCheckboxes(journeyIndex);
        }

        const disabledCheckbox = journey.isJourneyRefunded || !isPartialJourney;

        return (
          <TouchableOpacity
            key={journeyIndex}
            onPress={changeCheckbox}
            disabled={disabledCheckbox}
          >
            {journey.segments.map((segment, segmentIndex) => {
              const isFirstRow = journeyIndex + segmentIndex === 0;
              const isFirstSegment = segmentIndex === 0;

              const key = `${journeyIndex}-${segmentIndex}`;

              const flightSegmentProps = {
                airlineInfo: `${segment.airline.airlineName} ${DOT} ${segment.flightCode}`,
                checked: flightCheckboxes[journeyIndex].checkboxValue,
                flightDatetime: `${format(
                  new Date(segment.departureDetail.departureDate),
                  'FULL_WEEKDAY'
                )} ${DOT} ${segment.departureDetail.departureTime}`,
                flightRoute: `${segment.departureDetail.city}(${segment.departureDetail.airportCode}) ${ONE_WAY_SEPARATOR} ${segment.arrivalDetail.city}(${segment.arrivalDetail.airportCode})`,
                disabledCheckbox,
                isFirstSegment,
                changeCheckbox,
              };

              return (
                <React.Fragment key={key}>
                  {!isFirstRow && (
                    <Divider
                      margin={!isFirstSegment ? 'left' : 'none'}
                      spacing="xxxxl"
                    />
                  )}
                  <FlightSegment {...flightSegmentProps} />
                </React.Fragment>
              );
            })}
          </TouchableOpacity>
        );
      })}
    </>
  );
}

import React from 'react';
import { StyleSheet } from 'react-native';

import { useAuth, useContentResource, useTracker } from '@traveloka/ctv-core';
import { Button, Card, Text, Token } from '@traveloka/web-components';

export default function ActivateAccount() {
  const cr = useContentResource().CorporateRegistration;

  const track = useTracker('landing-page');
  const auth = useAuth();

  function sendTracking() {
    return track('corpB2b.landingPage.beforeLogin', {
      eventName: 'corpB2b.LandingPage',
      eventVersion: '1.0.0',
      pageName: 'HOME_PAGE',
      pageCategory: 'REGISTRATION_PAGE',
      pageReferrer: 'HOME_PAGE',
      buttonName: 'LOGIN',
      pageTitle: 'Register',
    });
  }
  async function handleOnPressLogin() {
    sendTracking().send();

    auth.login('/');
  }

  return (
    <Card style={styles.card}>
      <Text style={styles.info} variant="ui-small">
        {cr.activateAccountInfo}
      </Text>
      <Button
        variant="main-cta"
        text={cr.activateAccountButton}
        onPress={handleOnPressLogin}
      />
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    padding: Token.spacing.l,
  },
  info: {
    marginBottom: Token.spacing.m,
  },
});

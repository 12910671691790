import React from 'react';

import { Footer, Page } from 'shared/components/Layout';

import RegistrationContent from './components/RegistrationContent';

export default function Registration() {
  return (
    <Page>
      <RegistrationContent />
      <Footer />
    </Page>
  );
}

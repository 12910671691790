import React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Divider } from '@traveloka/ctvweb-ui';
import { Card, Text, Token } from '@traveloka/web-components';

import { usePrebook } from 'hotel/prebook/contexts/PrebookProvider';
import SharedStyle from 'hotel/prebook/hotelPrebook.style';
import { formatMessage } from 'shared/utils/intl';

export default function TravelerForm() {
  const { travelers } = usePrebook();

  const content = useContentResource().CorporateHotelPrebookTravelerForm;

  if (!travelers.length) {
    return null;
  }

  return (
    <>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {content.title}
      </Text>
      <Card style={SharedStyle.section}>
        <FlatList
          data={travelers}
          scrollEnabled={false}
          keyExtractor={item => item.email}
          ItemSeparatorComponent={() => <Divider margin="none" subtle />}
          renderItem={({ item, index }) => (
            <View style={Style.content}>
              <Text style={Style.name}>
                {formatMessage(content.travelerFullNameText, {
                  index: index + 1,
                  name: item.fullname,
                })}
              </Text>
              {!!item.division && (
                <Text variant="ui-small" ink="secondary">
                  {item.division}
                </Text>
              )}
            </View>
          )}
        />
      </Card>
    </>
  );
}

const Style = StyleSheet.create({
  content: {
    padding: Token.spacing.m,
  },
  name: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
});

import React, { useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { AddCardButton } from '@traveloka/ctvweb-ui/company';
import { Button, Card, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import CreditCardForm, { Handler } from './CreditCardForm';
import { useContentResource } from '@traveloka/ctv-core';

export default function AddCardModal() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const cr = useContentResource().CorporateManageCard;

  const formRef = useRef<Handler>(null!);

  return (
    <>
      <AddCardButton text={cr.addOneCard} onPress={() => setVisible(true)} />
      <Modal isVisible={visible} onModalHide={formRef.current?.clear}>
        <Card style={Style.content}>
          <CreditCardForm
            ref={formRef}
            onAuthorizeStart={() => setLoading(true)}
            onAuthorizeFailed={() => setLoading(false)}
          />
          <View style={Style.control}>
            <Button
              variant="secondary"
              text={cr.cancel}
              loading={loading}
              onPress={() => setVisible(false)}
            />
            <Button
              variant="main-cta"
              text={cr.addCard}
              style={Style.add}
              loading={loading}
              onPress={formRef.current?.validate}
            />
          </View>
        </Card>
      </Modal>
    </>
  );
}

const Style = StyleSheet.create({
  content: {
    padding: Token.spacing.l,
    width: 444,
  },
  control: {
    marginTop: Token.spacing.l,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  add: {
    marginStart: Token.spacing.m,
  },
});

import { useEffect } from 'react';

import { getQueryString, useApi } from '@traveloka/ctv-core';

import {
  RetrieveAttachmentCsvRequest,
  RetrieveAttachmentCsvResponse,
  RetrieveAttachmentEmailRequest,
  RetrieveAttachmentEmailResponse,
  RETRIEVE_ATTACHMENT_CSV,
  RETRIEVE_ATTACHMENT_EMAIL,
} from 'redirect/shared/api';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

export default function CompanyRedirectAttachment() {
  const qs = getQueryString();

  const history = useLocalizedHistory();
  const retrieveAttachmentFromCsv = useApi<
    RetrieveAttachmentCsvResponse,
    RetrieveAttachmentCsvRequest
  >({
    domain: 'management',
    method: 'post',
    path: RETRIEVE_ATTACHMENT_CSV,
  });
  const retrieveAttachmentFromEmail = useApi<
    RetrieveAttachmentEmailResponse,
    RetrieveAttachmentEmailRequest
  >({
    domain: 'management',
    method: 'post',
    path: RETRIEVE_ATTACHMENT_EMAIL,
  });

  useEffect(() => {
    if (qs.tripId) {
      retrieveAttachmentFromCsv({
        tripId: qs.tripId,
      })
        .then(res => {
          if (res.success) {
            window.open(res.data.url, '_blank');
            history.replace('/company/expense-report');
            return;
          }

          history.replace('/company/expense-report?err=true');
        })
        .catch(() => {
          history.replace('/company/expense-report?err=true');
        });
    } else if (qs.fileName) {
      retrieveAttachmentFromEmail({
        fileName: qs.fileName,
      })
        .then(res => {
          if (res.success) {
            window.open(res.data.url, '_blank');
            history.replace('/company/expense-report');
            return;
          }

          history.replace('/company/expense-report?err=true');
        })
        .catch(() => {
          history.replace('/company/expense-report?err=true');
        });
    }
  }, []);

  return null;
}

import { Permission } from '@traveloka/ctv-core';

import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const CompanyAccessRole: PrivatePageComponent = function() {
  return null;
};

CompanyAccessRole.routeProtection = function({ user, params }) {
  const hasPermission = user.has(
    Permission.USER_ROLE_CREATE,
    Permission.USER_ROLE_UPDATE
  );

  if (!hasPermission) {
    return { path: '/' };
  }

  if (params.roleId !== undefined && Permission.USER_ROLE_UPDATE) {
    return { path: '/company/permissions/role/' + params.roleId };
  }

  return { path: '/company/permissions/role' };
};

export default CompanyAccessRole;

import React, { ComponentProps, MouseEvent } from 'react';
import { StyleSheet } from 'react-native';

import { Anchor } from '@traveloka/web-components/future';

type AnchorProps = ComponentProps<typeof Anchor>;

type LinkAnchorProps = AnchorProps & {
  navigate(): void;
};

export default function LinkAnchor(props: LinkAnchorProps) {
  const { navigate, onClick, style, ...rest } = props;

  function handleOnClick(e: MouseEvent<HTMLAnchorElement>) {
    const { target } = e.currentTarget;

    const shouldPrevent =
      e.button === 0 && // ignore everything but left clicks
      (!target || target === '_self') && // let browser handle "target=_blank" etc.
      !isModifiedEvent(e); // ignore clicks with modifier keys

    if (shouldPrevent) {
      e.preventDefault();
    }

    try {
      onClick && onClick(e);
    } catch (ex) {
      e.preventDefault();
      throw ex;
    }

    if (e.defaultPrevented && !onClick) {
      navigate();
    }
  }

  return (
    <Anchor {...rest} style={[Style.anchor, style]} onClick={handleOnClick} />
  );
}

const Style = StyleSheet.create({
  anchor: {
    alignItems: 'stretch',
    flexBasis: 'auto',
    flexDirection: 'column',
  },
});

// =====
function isModifiedEvent(e: MouseEvent) {
  return !!(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);
}

import React, {
  createContext,
  Dispatch,
  useContext,
  PropsWithChildren,
  useReducer,
  useRef,
  MutableRefObject,
} from 'react';

import {
  FlightReasonsWithNoSecondary,
  FlightReasonsWithSecondary,
  FlightMainRefundReason,
  FlightRefundPersonalReason,
  FlightMainRefundReasonWithNoSecondary,
  RefundDocument,
  FlightTraveler,
  FlightMainRefundReasonWithNoSecondaryButHasDocument,
} from 'refund/shared/api/types';

import { useFlightRequestRefund } from './FlightRequestRefundContext';

export type RefundDocumentType = {
  file?: File;
  valueToSubmit?: string; //This can be public_url or double booking input
};

export type TravelerForm = {
  checkboxValue: boolean;
  traveler: FlightTraveler;
  secondaryReason: Nullable<FlightRefundPersonalReason>;
  requiredDocument: Partial<Record<RefundDocument, RefundDocumentType>>;
};

type FlightCheckbox = {
  checkboxValue: boolean;
  journeyId: string;
};

type FormValue = {
  flightCheckboxes: FlightCheckbox[];
  mainRefundReason: Nullable<
    FlightReasonsWithNoSecondary | FlightReasonsWithSecondary
  >;
  travelerForms: TravelerForm[];
  tncCheckbox: boolean;
};

type Action =
  | {
      type: 'updateFlightCheckboxes';
      flightCheckboxes: FlightCheckbox[];
    }
  | {
      type: 'updateTravelerForms';
      travelerForms: TravelerForm[];
    }
  | {
      type: 'updateSelectedMainReason';
      selectedMainReason:
        | FlightReasonsWithNoSecondary
        | FlightReasonsWithSecondary;
    }
  | {
      type: 'updateTncCheckbox';
      tncCheckbox: boolean;
    };

type ActionDispatcher = {
  addRequiredDocument(
    travelerId: string,
    document: RefundDocument,
    file: File,
    publicUrl?: string
  ): void;
  addIdenticalTrip(
    travelerId: string,
    document: RefundDocument.DOUBLE_BOOKING_PNR,
    value: string
  ): void;
  checkShouldRefundAllPax(): boolean;
  deleteRequiredDocument(travelerId: string, document: RefundDocument): void;
  updateFlightCheckboxes(journeyIndex: number): void;
  updatePassengerCheckboxes(updatedIndex: number): void;
  updateSecondaryReason(
    updatedIndex: number,
    reason: FlightRefundPersonalReason
  ): void;
  updateSelectedMainReason(
    selectedMainReason: Nullable<FlightMainRefundReason>
  ): void;
  updateTncCheckbox(value: boolean): void;
};

const FlightRequestRefundFormContext = createContext<
  // The 3rd index is for the reference of an updated traveler form, used for the simultaneous updated document.
  // Reference: https://stackoverflow.com/a/55478816/7552329
  [FormValue, Dispatch<Action>, MutableRefObject<TravelerForm[]>]
>(undefined!);

function useInitialForm(): FormValue {
  const [{ data }] = useFlightRequestRefund();
  const { isPartialJourney, flightRefundData } = data;

  return {
    flightCheckboxes: flightRefundData.journeys.map(journey => ({
      journeyId: journey.journeyId,
      checkboxValue: !isPartialJourney,
    })),
    mainRefundReason: null,
    travelerForms: flightRefundData.travelers.map<TravelerForm>(traveler => ({
      checkboxValue: false,
      traveler: traveler,
      secondaryReason: null,
      requiredDocument: {},
    })),
    tncCheckbox: false,
  };
}

export function FlightRequestRefundFormProvider({
  children,
}: PropsWithChildren<{}>) {
  const initialValue = useInitialForm();
  const updatedTravelerForm = useRef<TravelerForm[]>(
    initialValue.travelerForms
  );
  function reducer(state: FormValue, action: Action): FormValue {
    switch (action.type) {
      case 'updateFlightCheckboxes':
        return {
          ...state,
          flightCheckboxes: action.flightCheckboxes,
        };
      case 'updateTravelerForms':
        updatedTravelerForm.current = action.travelerForms;
        return {
          ...state,
          travelerForms: action.travelerForms,
        };
      case 'updateSelectedMainReason':
        return {
          ...state,
          mainRefundReason: action.selectedMainReason,
        };
      case 'updateTncCheckbox':
        return {
          ...state,
          tncCheckbox: action.tncCheckbox,
        };
    }
  }
  const [state, dispatch] = useReducer(reducer, initialValue);

  return (
    <FlightRequestRefundFormContext.Provider
      value={[state, dispatch, updatedTravelerForm]}
    >
      {children}
    </FlightRequestRefundFormContext.Provider>
  );
}

export function useFlightRequestRefundForm() {
  const [state] = useContext(FlightRequestRefundFormContext);
  return state;
}

export function useFlightRequestRefundFormAction(): ActionDispatcher {
  const [state, dispatch, updatedTravelerForm] = useContext(
    FlightRequestRefundFormContext
  );
  const { flightCheckboxes, mainRefundReason, travelerForms } = state;
  const [{ data }] = useFlightRequestRefund();
  const { reasons } = data;

  return {
    addRequiredDocument(travelerId, document, file, publicUrl) {
      dispatch({
        type: 'updateTravelerForms',
        travelerForms: updatedTravelerForm.current.map(travelerForm => {
          if (travelerForm.traveler.travelerId === travelerId) {
            return {
              ...travelerForm,
              requiredDocument: {
                ...travelerForm.requiredDocument,
                [document]: {
                  file,
                  valueToSubmit: publicUrl,
                },
              },
            };
          }
          return travelerForm;
        }),
      });
    },
    addIdenticalTrip(travelerId, document, value) {
      dispatch({
        type: 'updateTravelerForms',
        travelerForms: travelerForms.map(travelerForm => {
          if (travelerForm.traveler.travelerId === travelerId) {
            return {
              ...travelerForm,
              requiredDocument: {
                ...travelerForm.requiredDocument,
                [document]: {
                  valueToSubmit: value,
                },
              },
            };
          }
          return travelerForm;
        }),
      });
    },
    checkShouldRefundAllPax() {
      const shouldRefundAllPax = checkReasonNonPartialPax(
        mainRefundReason && mainRefundReason.mainReason
      );

      if (
        shouldRefundAllPax &&
        travelerForms.some(travelerForm => travelerForm.checkboxValue === false)
      ) {
        dispatch({
          type: 'updateTravelerForms',
          travelerForms: travelerForms.map(travelerForm => ({
            ...travelerForm,
            checkboxValue: true,
          })),
        });
      }

      return shouldRefundAllPax;
    },
    deleteRequiredDocument(travelerId, document) {
      dispatch({
        type: 'updateTravelerForms',
        travelerForms: travelerForms.map(travelerForm => {
          if (travelerForm.traveler.travelerId === travelerId) {
            const formDocument = { ...travelerForm.requiredDocument };
            delete formDocument[document];
            return {
              ...travelerForm,
              requiredDocument: formDocument,
            };
          }
          return travelerForm;
        }),
      });
    },
    updateFlightCheckboxes(journeyIndex) {
      dispatch({
        type: 'updateFlightCheckboxes',
        flightCheckboxes: flightCheckboxes.map((flightCheckbox, formIndex) => {
          if (journeyIndex === formIndex) {
            return {
              ...flightCheckbox,
              checkboxValue: !flightCheckbox.checkboxValue,
            };
          }
          return flightCheckbox;
        }),
      });
    },
    updatePassengerCheckboxes(updatedIndex) {
      dispatch({
        type: 'updateTravelerForms',
        travelerForms: travelerForms.map((traveler, travelerIndex) => {
          if (updatedIndex === travelerIndex) {
            return {
              ...traveler,
              checkboxValue: !traveler.checkboxValue,
            };
          }
          return traveler;
        }),
      });
    },
    updateSecondaryReason(updatedIndex, reason) {
      dispatch({
        type: 'updateTravelerForms',
        travelerForms: travelerForms.map((travelerForm, travelerIndex) => {
          if (updatedIndex === travelerIndex) {
            return {
              ...travelerForm,
              secondaryReason: reason,
              requiredDocument: {},
            } as TravelerForm;
          }
          return travelerForm;
        }),
      });
    },
    updateSelectedMainReason(mainReason) {
      const selectedMainReason = reasons.find(
        reason => reason.mainReason === mainReason
      )!;
      dispatch({
        type: 'updateSelectedMainReason',
        selectedMainReason,
      });

      dispatch({
        type: 'updateTravelerForms',
        travelerForms: travelerForms.map(travelerForm => ({
          ...travelerForm,
          checkboxValue: false,
          secondaryReason: null,
          requiredDocument: {},
        })),
      });
    },
    updateTncCheckbox(value: boolean) {
      dispatch({ type: 'updateTncCheckbox', tncCheckbox: value });
    },
  };
}

// Functions
function checkReasonNonPartialPax(reason: Nullable<FlightMainRefundReason>) {
  switch (reason) {
    case FlightMainRefundReasonWithNoSecondary.FORCE_MAJEURE:
    case FlightMainRefundReasonWithNoSecondary.CANCELLED_BY_AIRLINE:
    case FlightMainRefundReasonWithNoSecondaryButHasDocument.DOUBLE_BOOKING:
      return true;
    default:
      return false;
  }
}

import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { parse } from 'qs';

import { useApi } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { Text, Button, Token, Icon, Card } from '@traveloka/web-components';
import ChevronLeft from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_left_16px.svg';

import { RefundRequestQs } from 'postbooking/booking-detail/components/ProductRefundDetail/ProductRefundDetail';
import ProductType from 'postbooking/shared/constants/ProductType';
import { useFlightRequestRefundNavigator } from 'refund/request-refund/contexts/flight/FlightRequestRefundNavigatorContext';
import { useFlightRequestRefundForm } from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import { useFlightRequestRefund } from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';
import {
  useProductRequestRefundPage,
  RequestRefundStep,
} from 'refund/request-refund/contexts/ProductRequestRefundPageContext';
import {
  FlightSubmitRefundRequest,
  SUBMIT_REQUEST_REFUND_API,
  SubmitRefundFlightResponse,
} from 'refund/shared/api';
import {
  FlightSubmitTraveler,
  FlightMainRefundReasonWithNoSecondary,
  FlightMainRefundReasonWithNoSecondaryButHasDocument,
  RefundDocument,
  FlightSubmitRefundError,
} from 'refund/shared/api/types';
import InfoBox from 'refund/shared/components/InfoBox';

import FlightJourneyList from './FlightJourneyList/FlightJourneyList';
import PassengerList from './PassengerList/PassengerList';
import PartiallyRefundModal from './PartiallyRefundModal/PartiallyRefundModal';

export default function FlightRequestRefundReview() {
  const [partiallyRefundModal, setPartiallyRefundModal] = useState<boolean>(
    false
  );
  const qs: RefundRequestQs = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const [
    _,
    { changeCurrentStep, setError, goToBookingDetail },
  ] = useProductRequestRefundPage();
  const { goToPreviousStep } = useFlightRequestRefundNavigator();
  const {
    mainRefundReason,
    flightCheckboxes,
    travelerForms,
  } = useFlightRequestRefundForm();
  const [
    { isSubmitting },
    { setRefundedJourneys, setIsSubmitting },
  ] = useFlightRequestRefund();

  const content = useContentResource().CorporateFlightRequestRefundReview;

  const submitRefund = useApi<
    SubmitRefundFlightResponse,
    FlightSubmitRefundRequest
  >({
    domain: 'refund',
    method: 'post',
    path: SUBMIT_REQUEST_REFUND_API,
  });
  function handleSubmitRefund() {
    setIsSubmitting(true);
    const submitJourneyIds = flightCheckboxes
      .filter(checkbox => checkbox.checkboxValue)
      .map(checkbox => checkbox.journeyId);

    const submitTravelers = travelerForms
      .filter(travelerForm => travelerForm.checkboxValue)
      .map<FlightSubmitTraveler>(travelerForm => {
        /*
            Converting requiredDocument from:
            Record<RefundDocumentEnum, {
              file?: string;
              valueToSubmit?: string;
            }>

            To SubmitRequestRefund's requiredDocument:

            Record<RefundDocumentEnum, string>

            'string' here is using `valueToSubmit`'s value.
          */
        const requiredDocument: Partial<Record<RefundDocument, string>> = {};
        Object.keys(travelerForm.requiredDocument).map(key => {
          const docKey = key as RefundDocument;
          const currentDocument = travelerForm.requiredDocument[docKey];
          if (currentDocument?.valueToSubmit) {
            requiredDocument[docKey] = currentDocument.valueToSubmit;
          }
        });

        // If a reason doesn't has secondary reason, will be using main reason instead.
        return {
          travelerId: travelerForm.traveler.travelerId,
          secondaryReason:
            travelerForm.secondaryReason ||
            (mainRefundReason!.mainReason as
              | FlightMainRefundReasonWithNoSecondary
              | FlightMainRefundReasonWithNoSecondaryButHasDocument),
          requiredDocument,
        };
      });

    const payload: FlightSubmitRefundRequest = {
      bookingId: qs.bookingId,
      productType: ProductType.FLIGHT,
      flightRefundSubmission: {
        journeyIds: submitJourneyIds,
        travelers: submitTravelers,
        mainReason: mainRefundReason!.mainReason,
      },
    };

    submitRefund(payload)
      .then(res => {
        if (res.success) {
          setError(res.data.flightRefundSubmission.errorMessage);
          setRefundedJourneys(res.data.flightRefundSubmission.refundedJourneys);

          if (res.data.flightRefundSubmission.status === 'OK') {
            goToBookingDetail();
          } else if (
            res.data.flightRefundSubmission.errorMessage ===
            FlightSubmitRefundError.PARTIALLY_FAILED
          ) {
            setPartiallyRefundModal(true);
          } else {
            changeCurrentStep(RequestRefundStep.SELECT_DETAILS);
            window.scrollTo(0, 0);
          }
        }
      })
      .finally(() => setIsSubmitting(false));
  }

  return (
    <>
      <Button
        style={Style.buttonBack}
        size="small"
        variant="text"
        text={content.backButtonText}
        iconStart={() => <Icon src={ChevronLeft} />}
        onPress={() => goToPreviousStep()}
      />
      <Text style={Style.headline} variant="headline">
        {content.reviewHeadline}
      </Text>
      <Text style={Style.title} variant="title-1">
        {content.refundItemTitle}
      </Text>
      <Card style={Style.flightCard}>
        <FlightJourneyList />
      </Card>
      <Text style={Style.title} variant="title-1">
        {content.passengerTitle}
      </Text>
      <View style={Style.passengerListContainer}>
        <PassengerList />
      </View>
      <Text style={Style.title}>{content.refundableAmountTitle}</Text>
      <Card style={Style.refundableAmountContainer}>
        <InfoBox
          style={Style.refundableAmountContent}
          text={content.refundableAmountContent}
          verticalAlign="top"
        />
      </Card>
      <Button
        style={Style.buttonContinue}
        variant="main-cta"
        text={content.submitButtonText}
        onPress={handleSubmitRefund}
        loading={isSubmitting}
      />
      <PartiallyRefundModal isVisible={partiallyRefundModal} />
    </>
  );
}

const Style = StyleSheet.create({
  buttonBack: {
    marginBottom: Token.spacing.m,
    width: 80,
  },
  headline: {
    marginBottom: Token.spacing.xl,
  },
  title: {
    marginBottom: Token.spacing.m,
  },
  flightCard: {
    marginBottom: Token.spacing.xl,
  },
  passengerListContainer: {
    marginBottom: Token.spacing.l,
  },
  refundableAmountContainer: {
    padding: Token.spacing.m,
    marginBottom: Token.spacing.l,
  },
  refundableAmountContent: {
    borderColor: Token.color.uiBluePrimary,
    borderWidth: Token.border.width.thick,
  },
  buttonContinue: {
    marginLeft: 'auto',
    width: 304,
  },
});

import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Icon, Text, Token } from '@traveloka/web-components';

import Divider from '../../shared/components/Divider/Divider';

type Props = {
  border?: boolean;
  children: ReactNode;
  icon: string;
  style?: StyleProp<ViewStyle>;
  title: string;
};

export default function ProfileFieldGroup(props: Props) {
  const { border, children, icon, style, title } = props;

  return (
    <>
      <View style={[Style.container, style]}>
        <Icon src={icon} width={24} height={28} />
        <View style={Style.content}>
          <Text variant="ui-large" style={Style.title}>
            {title}
          </Text>
          {children}
        </View>
      </View>
      {border && <Divider margin="none" />}
    </>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: Token.spacing.ml,
    paddingHorizontal: Token.spacing.m,
  },
  content: {
    marginLeft: Token.spacing.xs,
    flex: 1,
  },
  title: {
    marginBottom: Token.spacing.s,
  },
});

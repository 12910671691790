import React, { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import { useTheme } from '@traveloka/web-components';

type Props = PropsWithChildren<{
  light?: boolean;
  covidInfo?: boolean;
}>;

export default function Page(props: Props) {
  const { children, light } = props;

  const defaultBackground = useDefaultBackground();
  const { color } = useTheme();
  const backgroundColor = light ? color.lightPrimary : defaultBackground;

  return <View style={[Style.container, { backgroundColor }]}>{children}</View>;
}

function useDefaultBackground() {
  const { name } = useTheme();

  // B2C color
  return name === 'default' ? '#e6eaed' : '#1b1b1b';
}

const Style = StyleSheet.create({
  container: {
    minHeight: '100vh',
    minWidth: 1080,
  },
});

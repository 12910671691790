import { useCallback } from 'react';

import { useContentResource } from '@traveloka/ctv-core';

import {
  LocalRequestRefundError,
  RequestRefundStep,
} from 'refund/request-refund/contexts/ProductRequestRefundPageContext';

import {
  FlightSubmitRefundError,
  HotelSubmitRefundError,
  RefundDocument,
} from '../api/types';

export function currentStepSwitcher(step: RequestRefundStep) {
  switch (step) {
    case RequestRefundStep.REFUND_POLICY:
      return 0;
    case RequestRefundStep.SELECT_DETAILS:
    case RequestRefundStep.REFUND_DOCUMENT:
      return 1;
    case RequestRefundStep.REVIEW_REQUEST:
      return 2;
  }
}

export function useRefundDocumentSwitcher() {
  const content = useContentResource().CorporateEnumFlightRefundDocument;
  const b2cContent = useContentResource().GeneralRefundSubmissionFlow_Flight;

  return useCallback((refundDocument: RefundDocument) => {
    switch (refundDocument) {
      case RefundDocument.DEATH_CERTIFICATE:
        return { title: content.deathCerificateTitle };
      case RefundDocument.DOUBLE_BOOKING_PNR:
        return {
          title: content.doubleBookingTitle,
          description: content.doubleBookingDesc,
        };
      case RefundDocument.EMBASSY_REJECTION_LETTER:
        return { title: content.embassyRejectionLetterTitle };
      case RefundDocument.FAMILY_REGISTRATION_CERTIFICATE:
        return { title: content.familyRegistrationCerificateTitle };
      case RefundDocument.IDCARD_PASSPORT:
      case RefundDocument.IDCARD_PASSPORT_DECEASED:
      case RefundDocument.IDCARD_PASSPORT_REFUNDAPPLICANT:
        return {
          title: content.idCardTitle,
          description: content.idCardDesc,
        };
      case RefundDocument.INPATIENT_MEDICALCERTIFICATE:
        return { title: content.inpatientMedicalCerificateTitle };
      case RefundDocument.MEDICAL_CERTIFICATE_PREGNANCY:
        return { title: content.medicalCertificateTitle };
      case RefundDocument.OTHER_MEDICAL_DOCUMENTS:
        return { title: content.otherMedicalDocumentTitle };
      case RefundDocument.OUTPATIENT_INPATIENT_MEDICALCERTIFICATE:
        return { title: content.outpatientInpatientMedicalCertificateTitle };
      case RefundDocument.OUTPATIENT_MEDICALCERTIFICATE:
        return { title: content.outpatientMedicalCertificateTitle };
      case RefundDocument.PHARMACY_INVOICE:
        return { title: content.pharmacyInvoiceTitle };
      case RefundDocument.ULTRASOUND_RESULTS:
        return { title: content.ultrasoundResultTitle };
      case RefundDocument.AIRPORT_OFFICIAL_STATEMENT:
        return {
          title: b2cContent.DocumentUpload_AIRPORT_OFFICIAL_STATEMENT_Title,
          description:
            b2cContent.DocumentUpload_AIRPORT_OFFICIAL_STATEMENT_Description,
        };
      case RefundDocument.AIRLINE_OFFICIAL_STATEMENT:
        return {
          title: b2cContent.DocumentUpload_AIRLINE_OFFICIAL_STATEMENT_Title,
          description:
            b2cContent.DocumentUpload_AIRLINE_OFFICIAL_STATEMENT_Description,
        };
      case RefundDocument.PASSPORT_IMAGE:
        return {
          title: b2cContent.DocumentUpload_PASSPORT_IMAGE_Title,
          description: b2cContent.DocumentUpload_PASSPORT_IMAGE_Description,
        };
      case RefundDocument.FORCE_MAJEURE_REPORT:
        return {
          title: b2cContent.FORCE_MAJEURE,
          description: b2cContent.FORCE_MAJEURE_Explained,
        };
      case RefundDocument.SELFIE_IMAGE:
      case RefundDocument.PASSBOOK_IMAGE:
      case RefundDocument.STATEMENT_LETTER:
      case RefundDocument.PROVIDER_APPROVAL_LETTER:
      case RefundDocument.PREFLIGHT_INFO_PROOF:
      case RefundDocument.IDCARD_PASSPORT_BANK_ACCOUNT:
      case RefundDocument.IDCARD_PASSPORT_TRAVELOKA_ACCOUNT:
      case RefundDocument.TRANSFER_RECEIPT:
      default:
        return {
          title: refundDocument,
        };
    }
  }, []);
}

export function checkFlightJourneyError(
  error: Nullable<FlightSubmitRefundError>
) {
  switch (error) {
    case FlightSubmitRefundError.SHOULD_REFUND_ALL_JOURNEY:
    case FlightSubmitRefundError.SHOULD_REFUND_ALL_ROUTE:
      return true;
    default:
      return false;
  }
}

export function checkMainReasonError(
  error: Nullable<
    FlightSubmitRefundError | HotelSubmitRefundError | LocalRequestRefundError
  >
) {
  switch (error) {
    case FlightSubmitRefundError.INCONSISTENT_REFUND_REASON:
    case HotelSubmitRefundError.INVALID_SPEC:
      return true;
    default:
      return false;
  }
}

export function checkPassengerError(error: Nullable<FlightSubmitRefundError>) {
  switch (error) {
    case FlightSubmitRefundError.PAX_NOT_REFUNDABLE:
    case FlightSubmitRefundError.MUST_REFUND_ALL_PAX:
    case FlightSubmitRefundError.INVALID_CUSTOMER_REASON:
      return true;
    default:
      return false;
  }
}

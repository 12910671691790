import React from 'react';
import { StyleSheet, View } from 'react-native';

import RefundUnavailableIcon from '@traveloka/icon-kit-web/svg/dark/ic_payment_refund_unavailable_16px.svg';
import MultiClassIcon from '@traveloka/icon-kit-web/svg/darkBlue/ic_transport_seat_class_multiple-fill_16px.svg';
import RefundAvailableIcon from '@traveloka/icon-kit-web/svg/greenSecondary/ic_payment_refund_16px.svg';
import { Icon, Image, Text, Token } from '@traveloka/web-components';

import Divider from '../../shared/components/Divider/Divider';
import { FlightData } from '../types';
import { appendTestId } from '../../shared/utils/TestUtil';

type Props = {
  compactLayout?: boolean;
  flight: FlightData;
  testID?: string;
};

export default function FlightFare(props: Props) {
  const { testID, compactLayout } = props;

  return (
    <View testID={testID} style={[!compactLayout && Style.containerNormal]}>
      <FlightBreakdown {...props} />
      <FareBreakdown {...props} />
    </View>
  );
}

function FlightBreakdown(props: Props) {
  const { testID, compactLayout, flight } = props;

  return (
    <View
      style={[Style.journeyRoutes, !compactLayout && Style.journeyRoutesNormal]}
    >
      {flight.journeys.map((journey, index) => (
        <View key={index} style={Style.journeyRoute}>
          <View style={Style.airline}>
            {journey.airlineLogos.map((logo, index) => (
              <Image
                key={index}
                src={logo.src}
                alt={logo.alt}
                width={Token.spacing.l}
                height={Token.spacing.l}
                objectFit="contain"
                style={{ marginRight: Token.spacing.xs }}
              />
            ))}
            <Text variant="ui-small" ink="secondary">
              {journey.airlineName}
            </Text>
          </View>
          <View style={Style.routeAndPolicy}>
            <View style={Style.route}>
              <Text
                testID={appendTestId(testID, 'route')}
                style={Style.routeText}
              >
                {journey.route}
              </Text>
              <View style={Style.seatClass}>
                {journey.isMultiClass && (
                  <Icon
                    src={MultiClassIcon}
                    width={Token.spacing.s}
                    height={Token.spacing.s}
                    style={{ marginRight: Token.spacing.xxs }}
                  />
                )}
                <Text
                  testID={appendTestId(testID, 'seat-class')}
                  variant="ui-small"
                  ink="secondary"
                >
                  {journey.seatClass}
                </Text>
              </View>
            </View>
            <View style={Style.policy}>
              {journey.refund && (
                <View
                  testID={appendTestId(testID, 'refund-info')}
                  style={Style.refund}
                >
                  <Icon
                    src={
                      journey.refund.isAvailable
                        ? RefundAvailableIcon
                        : RefundUnavailableIcon
                    }
                    width={Token.spacing.m}
                    height={Token.spacing.m}
                  />
                  <Text
                    variant="ui-small"
                    ink={journey.refund.isAvailable ? 'positive' : 'secondary'}
                    style={Style.refundText}
                  >
                    {journey.refund.decription}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      ))}
    </View>
  );
}

function FareBreakdown(props: Props) {
  const { testID, compactLayout, flight } = props;
  const { journeys, summary } = flight;

  return (
    <View
      style={[Style.journeyFares, !compactLayout && Style.journeyFaresNormal]}
    >
      {journeys.map((journey, journeyIndex) =>
        journey.fares.map((fare, fareIndex) => (
          <View key={`${journeyIndex}-${fareIndex}`} style={Style.fare}>
            <Text variant="ui-small" ink={fare.color} style={Style.fareText}>
              {fare.label}
            </Text>
            <Text
              testID={appendTestId(testID, `price.${fareIndex}`)}
              variant="ui-small"
              ink={fare.color}
              style={[Style.fareText, Style.fareValue]}
            >
              {fare.value}
            </Text>
          </View>
        ))
      )}
      <Divider spacing="xxs" />
      <View style={Style.fare}>
        <Text
          variant="ui-small"
          ink="secondary"
          style={[Style.fareText, Style.fareTextTotal]}
        >
          {summary.totalPriceLabel}
        </Text>
        <Text
          testID={appendTestId(testID, `total-price`)}
          variant="ui-small"
          ink="secondary"
          style={[Style.fareText, Style.fareTextTotal, Style.fareValue]}
        >
          {summary.totalPriceStr}
        </Text>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  containerNormal: {
    flexDirection: 'row',
  },
  journeyRoutes: {
    flex: 7,
  },
  journeyRoutesNormal: {
    marginRight: Token.spacing.l,
  },
  journeyRoute: {
    marginTop: Token.spacing.l,
  },
  airline: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Token.spacing.m,
  },
  routeAndPolicy: {
    flexDirection: 'row',
  },
  route: {
    flex: 5,
    marginRight: Token.spacing.m,
  },
  routeText: {
    marginBottom: Token.spacing.xs,
  },
  seatClass: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  policy: {
    flex: 4,
  },
  refund: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  refundText: {
    marginLeft: Token.spacing.xs,
    fontWeight: Token.typography.weightMedium.fontWeight,
    lineHeight: Token.typography.uiBaseline.lineHeight,
  },
  journeyFares: {
    marginTop: Token.spacing.l,
  },
  journeyFaresNormal: {
    flex: 4,
    marginTop: 64,
  },
  fare: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fareText: {
    flex: 1,
    lineHeight: Token.typography.uiBaseline.lineHeight,
  },
  fareValue: {
    textAlign: 'right',
  },
  fareTextTotal: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
});

import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import RedirectHome from 'generic/RedirectHome';
import PrivateRoute from 'shared/auth/PrivateRoute';

import CompanyRedirectAttachment from './attachment/components/CompanyRedirectAttachment';

type Props = RouteComponentProps;

function RedirectApp(props: Props) {
  const { match } = props;

  return (
    <Switch>
      <PrivateRoute
        path={`${match.path}/attachment/download`}
        exact
        component={CompanyRedirectAttachment}
      />
      <Route component={RedirectHome} />
    </Switch>
  );
}

export default RedirectApp;

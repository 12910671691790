import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { FormProvider, useForm } from 'react-hook-form';

import { useApi, useContentResource } from '@traveloka/ctv-core';
import { Popup, Text, Token, Button } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import {
  AddOthersEmailRequest,
  AddOthersEmailResponse,
  ADD_OTHERS_EMAIL_API,
} from 'registration/api';
import { useToken } from 'registration/contexts/TokenContext';
import { usePreRegisterConfirm } from 'registration/contexts/PreRegisterConfirmContext';
import InputField from 'shared/components/form/InputField/InputField';
import { useDispatchSnackbar } from 'shared/contexts/Snackbar/SnackbarContext';
import { isEmail, required } from 'shared/utils/validator';
import { formatMessage } from 'shared/utils/intl';

import { FieldRow } from '../FieldRow';

type AddEmailFormValues = { emails: string[] };

type Props = {
  isVisible: boolean;
  onClose(): void;
};

export default function AddEmailModal(props: Props) {
  const { isVisible, onClose } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = useToken();
  const openSnackbar = useDispatchSnackbar();

  const cr = useContentResource().CorporateRegistration;
  const commonCr = useContentResource().CorporateCommon;

  const methods = useForm<AddEmailFormValues>({
    defaultValues: { emails: [] },
  });
  const { handleSubmit: formSubmit } = methods;

  const addEmail = useApi<AddOthersEmailResponse, AddOthersEmailRequest>({
    domain: 'management',
    method: 'post',
    path: ADD_OTHERS_EMAIL_API,
  });

  async function handleSubmit(value: { emails: string[] }) {
    const uniqueEmail = Array.from(new Set(value.emails));
    await addEmail({
      otherEmails: uniqueEmail,
      token,
    })
      .then(res => {
        if (res.success) {
          setIsSubmitting(true);
        } else {
          openSnackbar({ variant: 'alert', message: commonCr.errorMessage });
        }
      })
      .catch(() => {
        openSnackbar({ variant: 'alert', message: commonCr.errorMessage });
      });
  }

  return (
    <>
      <Modal isVisible={isVisible}>
        <Popup showCloseButton={false} onCloseButtonPress={onClose} width={600}>
          <View style={styles.container}>
            <Text style={styles.spacingM} variant="headline">
              {cr.addEmailModalTitle}
            </Text>
            <FormProvider {...methods}>
              <FormContent
                isSubmitting={isSubmitting}
                onClose={onClose}
                onSubmit={formSubmit(handleSubmit)}
              />
            </FormProvider>
          </View>
        </Popup>
      </Modal>
      <ConfirmModal isVisible={isSubmitting} />
    </>
  );
}

type FormProps = {
  isSubmitting: boolean;
  onClose(): void;
  onSubmit(): void;
};
function FormContent(props: FormProps) {
  const { isSubmitting, onClose, onSubmit } = props;

  const cr = useContentResource().CorporateRegistration;

  const data = usePreRegisterConfirm();

  if (!data) {
    return null;
  }

  return (
    <>
      <FieldRow
        style={styles.spacingM}
        label={cr.addEmailModalEmail1Label}
        required
      >
        <InputField
          name="emails.0"
          validate={v => {
            if (!required(v)) {
              return formatMessage(cr.validationRequired, {
                field: cr.addEmailModalEmail1Label,
              });
            } else if (!isEmail(v)) {
              return formatMessage(cr.validationEmailFormat, {
                field: cr.addEmailModalEmail1Label,
              });
            } else if (v === data.picEmail) {
              return cr.addEmailModalSameEmailErrorMessage;
            }

            return;
          }}
          style={styles.input}
        />
      </FieldRow>
      <FieldRow
        style={styles.spacingM}
        label={cr.addEmailModalEmail2Label}
        required
      >
        <InputField
          name="emails.1"
          validate={v => {
            if (v && !isEmail(v)) {
              return formatMessage(cr.validationEmailFormat, {
                field: cr.addEmailModalEmail2Label,
              });
            } else if (v === data.picEmail) {
              return cr.addEmailModalSameEmailErrorMessage;
            }

            return;
          }}
          style={styles.input}
        />
      </FieldRow>

      <View style={styles.actionContainer}>
        <Button
          variant="text"
          text={cr.addEmailModalCancelButton}
          onPress={onClose}
        />
        <Button
          style={styles.submitButton}
          variant="main-cta"
          text={cr.addEmailModalAddButton}
          onPress={onSubmit}
          loading={isSubmitting}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: -Token.spacing.xl,
  },
  spacingM: {
    marginBottom: Token.spacing.m,
  },
  input: {
    flex: 1,
  },
  submitButton: {
    paddingHorizontal: Token.spacing.s,
    paddingVertical: Token.spacing.xs,
    marginLeft: Token.spacing.xl,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

interface InfoModalProps {
  isVisible: boolean;
}
function ConfirmModal(props: InfoModalProps) {
  const { isVisible } = props;

  const cr = useContentResource().CorporateRegistration;

  function handleSubmit() {
    window.location.reload();
  }

  return (
    <Modal isVisible={isVisible}>
      <Popup showCloseButton={false} width={320}>
        <View style={confirmStyles.container}>
          <Text style={confirmStyles.spacingM} variant="headline">
            {cr.addEmailConfirmTitle}
          </Text>
          <Text style={confirmStyles.spacingM} ink="secondary">
            {cr.addEmailConfirmInfo}
          </Text>
          <Button
            style={confirmStyles.submitButton}
            variant="primary"
            text={cr.addEmailConfirmOkButton}
            onPress={handleSubmit}
          />
        </View>
      </Popup>
    </Modal>
  );
}

const confirmStyles = StyleSheet.create({
  container: {
    marginTop: -Token.spacing.xl,
  },
  spacingM: {
    marginBottom: Token.spacing.m,
  },
  submitButton: {
    paddingHorizontal: Token.spacing.s,
    paddingVertical: Token.spacing.xs,
    flex: 1,
  },
});

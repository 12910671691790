import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  Dispatch,
  PropsWithChildren,
} from 'react';

import { useSearchSpec } from 'hotel/shared/contexts/SpecContext';
import { CurrencyValue } from 'shared/utils/currency';
import { formatCurrency } from 'shared/utils/intl';

export enum PriceDisplay {
  TOTAL = 'TOTAL',
  NIGHTLY = 'NIGHTLY',
}

const PriceDisplayContext = createContext<
  [PriceDisplay, Dispatch<PriceDisplay>]
>(undefined!);

export function PriceDisplayProvider(props: PropsWithChildren<{}>) {
  const state = useState(PriceDisplay.NIGHTLY);

  return (
    <PriceDisplayContext.Provider value={state}>
      {props.children}
    </PriceDisplayContext.Provider>
  );
}

export function usePriceDisplay() {
  return useContext(PriceDisplayContext);
}

export function usePriceDisplayFormatter(override?: PriceDisplay) {
  const [defaultDisplay] = usePriceDisplay();
  const priceDisplay = override ?? defaultDisplay;
  const { rooms, duration } = useSearchSpec();

  return useCallback(
    (fare: CurrencyValue) => {
      switch (priceDisplay) {
        case PriceDisplay.NIGHTLY:
          return formatCurrency({
            ...fare,
            amount: fare.amount / duration / rooms,
          });
        case PriceDisplay.TOTAL:
          return formatCurrency(fare);
      }
    },
    [priceDisplay, rooms, duration]
  );
}

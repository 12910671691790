import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { ComplianceLabel, Divider } from '@traveloka/ctvweb-ui';
import { Button, Card, Text, Token } from '@traveloka/web-components';

import { appendTestId } from '../../shared/utils/TestUtil';
import IconLabel from '../IconLabel/IconLabel';
import Tooltip from '../../shared/components/Tooltip/Tooltip';

type Props = {
  allotment?: string;
  bed: string;
  breakfast: string;
  bookLabel: string;
  cancelation: string;
  cancelationPolicy: string;
  complianceLabel: string;
  guest: string;
  isComplying: boolean;
  isFreeBreakfast?: boolean;
  isFreeCancelation?: boolean;
  isFreeWifi?: boolean;
  mainPrice: string;
  name: string;
  onSelectRoomPress(): void;
  selectRoomButtonLoading?: boolean;
  originalPrice?: string;
  priceBreakdown: Array<'separator' | PriceBreakdownRowProps>;
  promos: string[];
  readPolicyLabel: string;
  roomNightLabel: string;
  showBookButton: boolean;
  style?: StyleProp<ViewStyle>;
  taxInclusionLabel: string;
  testID?: string;
  wifi: string;
};

export default function RoomInfo(props: Props) {
  const {
    allotment,
    bed,
    breakfast,
    bookLabel,
    cancelation,
    cancelationPolicy,
    complianceLabel,
    guest,
    isComplying,
    isFreeBreakfast,
    isFreeCancelation,
    isFreeWifi,
    mainPrice,
    name,
    onSelectRoomPress,
    selectRoomButtonLoading,
    originalPrice,
    priceBreakdown,
    promos,
    readPolicyLabel,
    roomNightLabel,
    showBookButton,
    style,
    taxInclusionLabel,
    testID,
    wifi,
  } = props;

  return (
    <Card style={[Style.card, Style.resetIndex, style]}>
      {!isComplying && (
        <ComplianceLabel
          testID={testID}
          text={complianceLabel}
          style={Style.compliance}
        />
      )}
      <Text style={Style.name}>{name}</Text>
      <View style={Style.row}>
        <IconLabel
          testID={testID}
          type="bed"
          text={bed}
          style={Style.firstCol}
        />
        <IconLabel
          testID={testID}
          type="guest"
          text={guest}
          style={Style.secondCol}
        />
        <Text variant="ui-tiny" ink="alert" style={Style.allotment}>
          {allotment}
        </Text>
      </View>
      <Divider subtle />
      <View style={[Style.row, Style.resetIndex]}>
        <View style={Style.firstCol}>
          <IconLabel
            testID={testID}
            type="breakfast"
            text={breakfast}
            isAvailable={isFreeBreakfast}
            style={Style.icon}
          />
          <IconLabel
            testID={testID}
            type="wifi"
            text={wifi}
            isAvailable={isFreeWifi}
          />
        </View>
        <View style={Style.secondCol}>
          <IconLabel
            testID={testID}
            type="cancelation"
            text={cancelation}
            isAvailable={isFreeCancelation}
            style={Style.icon}
          />
          <View style={Style.policyTooltip}>
            <Tooltip
              content={
                <Text variant="ui-small" ink="white" style={Style.cancelation}>
                  {cancelationPolicy}
                </Text>
              }
            >
              <IconLabel ink="highlight" type="info" text={readPolicyLabel} />
            </Tooltip>
          </View>
        </View>
        <View style={[Style.priceAction, Style.resetIndex]}>
          {typeof originalPrice === 'string' && (
            <Text
              testID={appendTestId(testID, 'original-price')}
              variant="ui-tiny"
              ink="secondary"
              style={Style.originalPrice}
            >
              {originalPrice}
            </Text>
          )}
          <Text
            testID={appendTestId(testID, 'main-price')}
            variant="ui-large"
            ink="price"
            style={Style.mainPrice}
          >
            {mainPrice}
          </Text>
          <Text variant="ui-tiny" ink="secondary">
            {roomNightLabel}
          </Text>
          <Tooltip
            position="bottom"
            width={356}
            zIndex={1}
            content={priceBreakdown.map((price, index) => {
              if (typeof price === 'string') {
                return <Divider key={index} spacing="xs" />;
              }

              return <PriceBreakdownRow key={index} {...price} />;
            })}
          >
            <Text variant="ui-tiny" ink="highlight">
              {taxInclusionLabel}
            </Text>
          </Tooltip>
          {showBookButton && (
            <Button
              testID={appendTestId(testID, 'book-button')}
              variant="main-cta"
              text={bookLabel}
              style={Style.action}
              onPress={onSelectRoomPress}
              loading={selectRoomButtonLoading}
            />
          )}
        </View>
      </View>
      {promos.length > 0 && (
        <>
          <Divider subtle />
          {promos.map((promo, index) => (
            <Text
              testID={appendTestId(testID, `promos.${index}`)}
              key={index}
              variant="ui-small"
              ink="positive"
            >
              {promo}
            </Text>
          ))}
        </>
      )}
    </Card>
  );
}

type PriceBreakdownRowProps = {
  label: string;
  value: string;
};

function PriceBreakdownRow(props: PriceBreakdownRowProps) {
  const { label, value } = props;

  return (
    <View style={Style.breakdownRow}>
      <Text variant="ui-small" ink="white">
        {label}
      </Text>
      <Text variant="ui-small" ink="white" style={Style.breakdownPrice}>
        {value}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  resetIndex: {
    zIndex: 'unset' as any,
  },
  card: {
    paddingTop: Token.spacing.l,
    paddingBottom: Token.spacing.m,
    paddingHorizontal: Token.spacing.l,
    overflow: 'visible',
  },
  name: {
    marginBottom: Token.spacing.xs,
  },
  compliance: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  row: {
    flexDirection: 'row',
  },
  firstCol: {
    width: 200,
  },
  secondCol: {
    width: 200,
    marginLeft: Token.spacing.xs,
  },
  allotment: {
    textAlign: 'right',
    flex: 1,
    marginLeft: Token.spacing.xs,
  },
  priceAction: {
    flex: 1,
    alignItems: 'flex-end',
    marginLeft: Token.spacing.xs,
  },
  originalPrice: {
    textDecorationLine: 'line-through',
  },
  mainPrice: {
    textAlign: 'right',
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  action: {
    marginTop: Token.spacing.xs,
  },
  icon: {
    marginBottom: Token.spacing.xs,
  },
  breakdownRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: Token.spacing.xxs,
  },
  breakdownPrice: {
    marginLeft: Token.spacing.xs,
    // @ts-ignore
    whiteSpace: 'nowrap',
  },
  policyTooltip: {
    alignSelf: 'flex-start',
  },
  cancelation: {
    minWidth: 240,
    maxWidth: 380,
  },
});

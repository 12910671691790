import { Permission } from '@traveloka/ctv-core';

import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const CompanyEmployee: PrivatePageComponent = function() {
  return null;
};

CompanyEmployee.routeProtection = function({ user }) {
  if (
    !user.has(
      Permission.USER_READ,
      Permission.USER_CREATE,
      Permission.USER_UPDATE,
      Permission.USER_DELETE
    )
  ) {
    return { path: '/' };
  }

  return { path: '/people-structure' };
};

export default CompanyEmployee;

import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Text, Token, Icon } from '@traveloka/web-components';
import { Divider, TabBarWithIcon } from '@traveloka/ctvweb-ui';
import Phone from '@traveloka/icon-kit-web/svg/dark/ic_contact_phone_24px.svg';
import Email from '@traveloka/icon-kit-web/svg/dark/ic_contact_mail_16px.svg';
import Flight from '@traveloka/icon-kit-web/svg/blue/ic_product_flight-fill_24px.svg';
import TravelerDetails from '@traveloka/icon-kit-web/svg/blue/ic_user_account-fill_24px.svg';

import { usePaymentStateFlight } from 'payment/select/contexts/PaymentContext';

import FlightDetail from './flight/FlightDetail';
import TravelerDetail from './flight/TravelerDetail';

export default function FlightDetailCardModal() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { booking } = usePaymentStateFlight();
  const { tripId, flightBookingDetail } = booking;
  const { contactDetail } = flightBookingDetail;

  const content = useContentResource().CorporateFlightPaymentDetailModal;

  const tabBarItems = [
    {
      key: 'flight',
      text: content.flightTab,
      icon: Flight,
    },
    {
      key: 'travelerDetails',
      text: content.travelerDetailsTab,
      icon: TravelerDetails,
    },
  ];

  return (
    <>
      <View style={Style.container}>
        <Text variant="headline">{content.headlineText}</Text>
        <View style={Style.bookingNumber}>
          <Text variant="ui-small" ink="secondary">
            {content.tripIdText}{' '}
          </Text>
          <Text variant="title-3">{tripId}</Text>
        </View>
      </View>
      <View style={Style.divider}>
        <Divider />
      </View>
      <View style={Style.contentHeader}>
        <Text variant="title-2">{`${contactDetail.firstName} ${contactDetail.lastName}`}</Text>
        <View style={Style.flexRow}>
          <View style={[Style.flexRowVerticalCenter, Style.phone]}>
            <Icon
              style={{ marginRight: Token.spacing.xxs }}
              src={Phone}
              width={18}
              height={18}
            />
            <Text variant="ui-small" ink="secondary">
              {`${contactDetail.customerPhoneNumberCountryCode}${contactDetail.customerPhoneNumber}`}
            </Text>
          </View>
          <View style={Style.flexRowVerticalCenter}>
            <Icon
              style={{ marginRight: Token.spacing.xxs }}
              src={Email}
              width={18}
              height={18}
            />
            <Text variant="ui-small" ink="secondary">
              {contactDetail.customerEmail}
            </Text>
          </View>
        </View>
      </View>
      <View style={Style.tabBar}>
        <TabBarWithIcon
          activeTabIndex={activeTabIndex}
          isScrollable={false}
          items={tabBarItems}
          onChange={setActiveTabIndex}
        />
      </View>
      {activeTabIndex === 0 && <FlightDetail />}
      {activeTabIndex === 1 && <TravelerDetail />}
    </>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  divider: {
    marginHorizontal: -Token.spacing.m,
  },
  bookingNumber: {
    flexDirection: 'row',
    marginRight: Token.spacing.xl,
  },
  contentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Token.spacing.m,
  },
  phone: {
    marginRight: Token.spacing.m,
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexRowVerticalCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabBar: {
    marginBottom: Token.spacing.m,
  },
  menuItemContainer: {
    flexDirection: 'row',
    marginBottom: Token.spacing.m,
  },
});

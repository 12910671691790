import React from 'react';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { StyleSheet, View } from 'react-native';
import { Text, Button, Icon, Token, Image } from '@traveloka/web-components';
import { GenericHomeContent_v2 } from '@traveloka/ctvweb-ui/generic';
import IcSystemChevronRight from '@traveloka/icon-kit-web/svg/light/ic_system_chevron_right_12px.svg';
import IcSystemHelpFill from '@traveloka/icon-kit-web/svg/light/ic_system_help-fill_24px.svg';

export default function GenericHomeFooter() {
  const content = useContentResource().CorporateFooterV2;

  function handleOnPressGetDirection() {
    const mapLink = content.officeAddressMapLink;
    window.open(mapLink, '_blank');
  }

  return (
    <View style={Style.wrapper}>
      <GenericHomeContent_v2 spacing="xxl">
        <View style={Style.headingContainer}>
          <Image height={30} src={'/img/traveloka-for-corporates-white.png'} />
          <View
            accessibilityRole="link"
            // @ts-ignore
            href={content.helpCenter}
            target="_blank"
          >
            <Button
              variant="circular-primary"
              text="Need help?"
              iconStart={() => <Icon src={IcSystemHelpFill} />}
            />
          </View>
        </View>

        <View style={Style.contentContainer}>
          <View style={Style.column}>
            <Text ink="white" variant="title-3" style={Style.columnTitle}>
              {content.officeLabel}
            </Text>
            <Text ink="white">{content.officeAddressLine01}</Text>
            <Text ink="white">{content.officeAddressLine02}</Text>
            <Button
              text={content.directionLabel}
              variant="text-white"
              size="small"
              iconEnd={() => <Icon src={IcSystemChevronRight} />}
              onPress={handleOnPressGetDirection}
            />
          </View>

          <View style={[Style.column, Style.columnMargin]}>
            <Text ink="white" variant="title-3" style={Style.columnTitle}>
              {content.callCenterLabel}
            </Text>
            <a href={'tel:' + content.callCenterNumber} target="_blank">
              <Text ink="white" style={Style.linkText}>
                {content.callCenterNumberLabel}
              </Text>
            </a>
          </View>

          <View style={[Style.column, Style.columnMargin]}>
            <Text ink="white" variant="title-3" style={Style.columnTitle}>
              {content.emailUsLabel}
            </Text>
            <a href={'mailto:' + content.emailAddress} target="_blank">
              <Text ink="white" style={Style.linkText}>
                {content.emailAddress}
              </Text>
            </a>
          </View>
        </View>

        <View style={Style.copyright}>
          <Text ink="secondary" variant="ui-small">
            {content.copyrightText}
          </Text>
        </View>
      </GenericHomeContent_v2>
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    backgroundColor: Token.color.uiDarkPrimary,
  },
  headingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: Token.spacing.m,
  },
  contentContainer: {
    flexDirection: 'row',
    marginTop: Token.spacing.xxl,
  },
  column: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  columnMargin: {
    marginLeft: Token.spacing.xxl * 2,
  },
  columnTitle: {
    marginBottom: Token.spacing.m,
  },
  mapLink: {
    marginTop: Token.spacing.s,
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkText: {
    textDecorationLine: 'underline',
    marginRight: Token.spacing.xs,
  },
  copyright: {
    marginTop: Token.spacing.l,
    paddingTop: Token.spacing.xxl,
    borderTopWidth: Token.border.width.thin,
    borderColor: Token.color.uiDarkNeutral,
  },
});

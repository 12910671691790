import React from 'react';
import { Switch, SwitchProps, RouteProps } from 'react-router-dom';

import { Locale, useLocale } from '../LocaleContext';

export default function LocalizedSwitch(props: SwitchProps) {
  const { children } = props;
  const { locale } = useLocale();

  return (
    <Switch {...props}>
      {React.Children.map(children, child => {
        return React.isValidElement(child)
          ? React.cloneElement<RouteProps>(child, {
              ...child.props,
              path: localizedPath(locale, child.props.path),
            })
          : child;
      })}
    </Switch>
  );
}

function localizedPath(locale: Locale, path?: string | string[]) {
  if (path?.includes('/callback')) {
    return path;
  }

  switch (typeof path) {
    case 'undefined':
      return undefined;
    case 'object':
      return path.map(key => `/${locale}/${key}`);
    default:
      return `/${locale}${path}`;
  }
}

import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  useTheme,
  Card,
  LoadingIndicator,
  Text,
  Token,
} from '@traveloka/web-components';

export default function AuthLoading() {
  const { color } = useTheme();
  const pageStyle = {
    backgroundColor: color.lightNeutral,
  };

  return (
    <View style={[Style.page, pageStyle]}>
      <Card style={Style.card}>
        <Text variant="ui-large" style={Style.spacer}>
          {'Authenticating'}
        </Text>
        <Text variant="ui-small" style={Style.spacer}>
          {
            'You should be redirected to our home page after a few seconds. If redirection fails, please try refresh or reopen the page. If the problem persists, please contact your company PIC or dedicated Account Manager for further assistance.'
          }
        </Text>
        <LoadingIndicator />
      </Card>
    </View>
  );
}

const Style = StyleSheet.create({
  page: {
    // @ts-ignore
    height: '100vh',
  },
  spacer: {
    marginBottom: Token.spacing.xs,
  },
  infoBox: {
    width: '100%',
  },
  card: {
    alignItems: 'center',
    width: 500,
    marginHorizontal: 'auto',
    marginTop: Token.spacing.xxxl,
    padding: Token.spacing.m,
  },
});

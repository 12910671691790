import React, { useRef, useState } from 'react';
import { Animated, FlatList, StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Divider } from '@traveloka/ctvweb-ui';
import { FlightJourney, RefundInfo } from '@traveloka/ctvweb-ui/flight';
import PolicyIcon from '@traveloka/icon-kit-web/svg/blue/ic_policy_24px.svg';
import FlightIcon from '@traveloka/icon-kit-web/svg/blue/ic_product_flight-fill_24px.svg';
import {
  useTheme,
  Button,
  Icon,
  Popup,
  Token,
} from '@traveloka/web-components';
import { useLayout, Modal } from '@traveloka/web-components/future';

import {
  useFlightsContext,
  usePoliciesContext,
} from 'flight/prebook/contexts/FlightPrebookContext';
import { FlightSearchData } from 'flight/search/utils/flight-search-view-util';

type Props = {
  isVisible?: boolean;
  onClosePress?(): void;
};

export default function ProductSummaryModal(props: Props) {
  const { isVisible, onClosePress } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const buttonRefs = useRef<View[]>([]);
  const widthRef = useRef(0);
  const offsetRef = useRef(0);
  const isFirstRenderRef = useRef(true);

  const content = useContentResource().CorporateFlightPrebookProductSummary;

  const { color } = useTheme();
  const flights = useFlightsContext();
  const flightRefundPolicies = usePoliciesContext();

  const [flightRect, flightBindings] = useLayout();
  const [refundRect, refundBindings] = useLayout();

  const tabItems = [{ text: content.modalTabItemFlight, icon: FlightIcon }];
  if (flightRefundPolicies.length) {
    tabItems.push({
      text: content.modalTabItemHasRefundPolicy,
      icon: PolicyIcon,
    });
  }

  const scrollStyle = {
    backgroundColor: color.lightPrimary,
  };
  const activeButtonStyle = {
    borderBottomColor: color.tintPrimary,
  };
  const height = [flightRect.height + 1, refundRect.height + 1][activeIndex];
  const sliderStyle = {
    width: `${tabItems.length * 100}%`,
    transform: [
      {
        translateX: `${(activeIndex / tabItems.length) * -100}%`,
      },
    ],
  };

  function setButtonRef(index: number) {
    return (component: View | null) => {
      if (component) {
        buttonRefs.current[index] = component;
      }
    };
  }

  function handleSetIndex(index: number) {
    return () => {
      isFirstRenderRef.current = false;
      const button = buttonRefs.current[index];

      if (button) {
        button.measure((x, y, width) => {
          widthRef.current = width;
          offsetRef.current = x;

          setActiveIndex(index);
        });
      }
    };
  }

  return (
    <Modal isVisible={isVisible}>
      <View style={[Style.scroll, scrollStyle]}>
        <Popup
          title={content.modalTitle}
          showCloseButton
          width={800}
          maxWidth={800}
          onCloseButtonPress={onClosePress}
        >
          <View>
            <View style={Style.tab}>
              {tabItems.map((item, index) => (
                <View
                  ref={setButtonRef(index)}
                  style={[
                    Style.button,
                    isFirstRenderRef.current && index === activeIndex
                      ? activeButtonStyle
                      : Style.borderTransparent,
                  ]}
                >
                  <Button
                    key={index}
                    iconStart={() => <Icon src={item.icon} />}
                    onPress={handleSetIndex(index)}
                    size="small"
                    text={item.text}
                    variant="text-black"
                  />
                </View>
              ))}
            </View>
            <Animated.View
              style={[
                Style.indicator,
                {
                  backgroundColor: color.tintPrimary,
                  transform: [
                    { translateX: offsetRef.current },
                    { scaleX: widthRef.current },
                  ],
                },
              ]}
            />
          </View>
          <Divider margin="none" />
          <View style={Style.sliderContainer}>
            <View
              // @ts-ignore
              style={[Style.slider, sliderStyle, { height }]}
            >
              <View {...flightBindings} style={Style.sliderItem}>
                <FlatList
                  style={Style.flight}
                  data={flights}
                  keyExtractor={item => item.flightId}
                  ItemSeparatorComponent={Divider}
                  renderItem={({ item }) => <FlightJourney flight={item} />}
                />
              </View>
              {flightRefundPolicies.length > 0 && (
                <View {...refundBindings} style={Style.sliderItem}>
                  {flightRefundPolicies.map((flightPolicy, flightIndex) =>
                    flightPolicy.refundPolicies.map((policy, policyIndex) => {
                      const flight = flights[flightIndex];
                      const journey = flight.journeys[policyIndex];

                      return (
                        <RefundInfo
                          key={flightIndex}
                          journey={journey}
                          policies={policy.refundPolicy}
                          style={Style.refund}
                        />
                      );
                    })
                  )}
                </View>
              )}
            </View>
          </View>
        </Popup>
      </View>
    </Modal>
  );
}

const Style = StyleSheet.create({
  button: {
    marginHorizontal: Token.spacing.s,
    borderBottomWidth: Token.border.width.bold,
  },
  borderTransparent: {
    borderBottomColor: 'transparent',
  },
  tab: {
    flexDirection: 'row',
  },
  scroll: {
    flexShrink: 1,
    flexGrow: 0,
    borderRadius: Token.border.radius.normal,
    overflowY: 'auto',
  },
  indicator: {
    width: 1,
    height: Token.border.width.bold,
    bottom: 0,
    position: 'absolute',
    transformOrigin: 'bottom left',
    transitionProperty: 'transform',
    transitionDuration: `${Token.animation.timing.instant}ms`,
  },
  sliderContainer: {
    overflow: 'hidden',
    marginHorizontal: -Token.spacing.m,
  },
  slider: {
    transitionDuration: `${Token.animation.timing.instant}ms`,
    transitionProperty: 'transform',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  sliderItem: {
    flex: 1,
  },
  flight: {
    paddingHorizontal: Token.spacing.m,
  },
  refund: {
    paddingTop: Token.spacing.m,
  },
});

import React, { useMemo } from 'react';

import { useContentResource } from '@traveloka/ctv-core';

import { usePaymentStateHotel } from 'payment/select/contexts/PaymentContext';
import { convert } from 'shared/utils/currency';
import { formatCurrency } from 'shared/utils/intl';

import HotelDetailCard from '../DetailCard/HotelDetailCard';
import ErrorBoundary from './ErrorBoundary';
import PaymentSelectContent from './PaymentSelectContent';

const fallback = {
  currency: 'IDR',
  amount: '0',
  decimalPoints: '0',
  displayAmount: '0',
};

export default function HotelPaymentSelectContent() {
  const { booking } = usePaymentStateHotel();
  const {
    paymentExpirationTimeInSecond,
    hotelBookingDetail,
    totalPriceCharged,
    status,
    serviceFee,
    vatFee,
    grandTotal,
  } = booking;
  const { roomsInfo, propertyInfo } = hotelBookingDetail;

  const cr = useContentResource().CorporateHotelPayment;

  const [totalPrice, priceBreakdown] = useMemo(() => {
    const [{ name }] = roomsInfo;

    const totalPrice = formatCurrency(
      convert(grandTotal || (totalPriceCharged ?? fallback))
    );

    const breakdown = [
      {
        label: `${propertyInfo.name}, ${name} x${roomsInfo.length}`,
        value: formatCurrency(convert(totalPriceCharged ?? fallback)),
      },
    ];

    if (serviceFee) {
      breakdown.push({
        label: cr.breakdownServiceFeeLabel,
        value: formatCurrency(convert(serviceFee)),
      });
    }

    if (vatFee) {
      breakdown.push({
        label: cr.breakdownVatFeeLabel,
        value: formatCurrency(convert(vatFee)),
      });
    }

    return [totalPrice, breakdown];
  }, [hotelBookingDetail]);

  return (
    <PaymentSelectContent
      status={status}
      expireOn={Number(paymentExpirationTimeInSecond)}
      priceBreakdown={priceBreakdown}
      totalPrice={totalPrice}
    >
      <ErrorBoundary
        componentName={'HotelDetailCard'}
        additionalAttributes={booking}
      >
        <HotelDetailCard />
      </ErrorBoundary>
    </PaymentSelectContent>
  );
}

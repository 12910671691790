import React, {
  forwardRef,
  useCallback,
  useRef,
  PropsWithChildren,
} from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

import { Calendar, Divider, Dropdown } from '@traveloka/ctvweb-ui';
import { Handler } from '@traveloka/ctvweb-ui/src/shared/components/Dropdown/Dropdown';
import Chevron from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';
import Room from '@traveloka/icon-kit-web/svg/dark/ic_hotel_room_24px.svg';
import Guest from '@traveloka/icon-kit-web/svg/dark/ic_hotel_room_guest_24px.svg';
import CheckIn from '@traveloka/icon-kit-web/svg/dark/ic_system_calendar_24px.svg';
import Duration from '@traveloka/icon-kit-web/svg/dark/ic_time_night_stay_24px.svg';
import { Icon, Radio, RadioDisc, Text, Token } from '@traveloka/web-components';
import { ButtonComponentProps as RadioComponentProps } from '@traveloka/web-components/src/momentum/RadioGroup/Radio';

import { appendTestId } from '../../shared/utils/TestUtil';

type Props = {
  checkInDate: Date;
  duration: number;
  rooms: number;

  checkInDateLabel: string;
  durationLabel: string;
  guestLabel: string;
  roomsLabel: string;

  checkInDateValue: string;
  durationValue: string;
  guestValue: string;
  roomsValue: string;

  durationOptions: Array<{
    value: number;
    label: string;
    subLabel: string;
  }>;

  roomOptions: Array<{
    value: number;
    label: string;
  }>;

  onDayChange(date: Date): void;
  onDurationChange(duration: number): void;
  onRoomChange(room: number): void;
  onDropdownPress?(): void;
  onGuestPress?(): void;

  testID?: string;
  labelID: string;
};

export default function RoomSearchForm(props: Props) {
  const {
    checkInDate,
    duration,
    rooms,
    checkInDateLabel,
    durationLabel,
    guestLabel,
    roomsLabel,
    checkInDateValue,
    durationValue,
    guestValue,
    roomsValue,
    durationOptions,
    roomOptions,
    onDayChange,
    onDurationChange,
    onRoomChange,
    onDropdownPress,
    onGuestPress,
    testID,
    labelID,
  } = props;
  const checkIndropdown = useRef<Handler>(null!);
  const durationDropdown = useRef<Handler>(null!);
  const roomDropdown = useRef<Handler>(null!);

  const handleDayChange = useCallback(
    (date: Date) => {
      checkIndropdown.current.setIsCollapsed(true);
      onDayChange && onDayChange(date);
    },
    [onDayChange]
  );

  const handleDurationChange = useCallback(
    (duration: string | number) => {
      durationDropdown.current.setIsCollapsed(true);
      onDurationChange && onDurationChange(Number(duration));
    },
    [onDurationChange]
  );

  const handleRoomChange = useCallback(
    (room: string | number) => {
      roomDropdown.current.setIsCollapsed(true);
      onRoomChange && onRoomChange(Number(room));
    },
    [onRoomChange]
  );

  return (
    <View style={Style.container}>
      <RoomDropdown
        testID={appendTestId(testID, 'check-in')}
        ref={checkIndropdown}
        icon={CheckIn}
        label={checkInDateLabel}
        onPress={onDropdownPress}
        value={checkInDateValue}
      >
        <Calendar
          minDate={new Date()}
          value={checkInDate}
          onDayPress={handleDayChange}
        />
      </RoomDropdown>
      <Divider margin="vertical" spacing="m" style={Style.divider} />
      <RoomDropdown
        testID={appendTestId(testID, 'duration')}
        ref={durationDropdown}
        icon={Duration}
        label={durationLabel}
        onPress={onDropdownPress}
        value={durationValue}
      >
        <ScrollView style={Style.scroll}>
          <Radio.Group
            labelID={`${labelID}.duration`}
            value={duration}
            onChange={handleDurationChange}
          >
            {durationOptions.map(option => (
              <Radio.Button
                testID={appendTestId(testID, `duration.${option.value}`)}
                key={option.value}
                ButtonComponent={props => (
                  <DurationRadioButton {...props} subLabel={option.subLabel} />
                )}
                containerStyle={Style.radio}
                label={option.label}
                value={option.value}
              />
            ))}
          </Radio.Group>
        </ScrollView>
      </RoomDropdown>
      <Divider margin="vertical" spacing="m" style={Style.divider} />
      <View style={Style.col}>
        <Input
          testID={appendTestId(testID, 'guest')}
          icon={Guest}
          label={guestLabel}
          onPress={onGuestPress}
          value={guestValue}
        />
      </View>
      <Divider margin="vertical" spacing="m" style={Style.divider} />
      <RoomDropdown
        testID={appendTestId(testID, 'rooms')}
        ref={roomDropdown}
        icon={Room}
        label={roomsLabel}
        onPress={onDropdownPress}
        value={roomsValue}
      >
        <ScrollView style={Style.scroll}>
          <Radio.Group
            labelID={`${labelID}.rooms`}
            value={rooms}
            onChange={handleRoomChange}
          >
            {roomOptions.map(option => (
              <Radio.Button
                testID={appendTestId(testID, `rooms.${option.value}`)}
                key={option.value}
                containerStyle={Style.radio}
                label={option.label}
                value={option.value}
              />
            ))}
          </Radio.Group>
        </ScrollView>
      </RoomDropdown>
    </View>
  );
}

const RoomDropdown = forwardRef<Handler, PropsWithChildren<InputProps>>(
  (props, ref) => {
    const { onPress, children, ...rest } = props;

    return (
      <Dropdown
        ref={ref}
        onPress={onPress}
        style={Style.col}
        trigger={<Input {...rest} />}
      >
        {children}
      </Dropdown>
    );
  }
);

type InputProps = {
  icon: string;
  label: string;
  onPress?(): void;
  testID?: string;
  value: string;
};

function Input(props: InputProps) {
  const { icon, label, onPress, testID, value } = props;

  return (
    <TouchableOpacity
      testID={testID}
      activeOpacity={0.5}
      style={Style.input}
      onPress={onPress}
    >
      <Icon src={icon} width={24} height={24} />
      <View style={Style.inputText}>
        <Text variant="ui-tiny" ink="secondary">
          {label}
        </Text>
        <Text variant="ui-small" numberOfLines={1}>
          {value}
        </Text>
      </View>
      <Icon src={Chevron} width={16} height={16} />
    </TouchableOpacity>
  );
}

type DurationRadioButton = RadioComponentProps & {
  subLabel: string;
};

function DurationRadioButton(props: DurationRadioButton) {
  const { isFocused, isSelected, label, subLabel } = props;

  return (
    <>
      <RadioDisc isSelected={isSelected} isFocused={isFocused} />
      <View style={Style.radioText}>
        <Text>{label}</Text>
        <Text variant="ui-small" ink="secondary">
          {subLabel}
        </Text>
      </View>
    </>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  col: {
    flex: 1,
  },
  scroll: {
    maxHeight: 325,
  },
  input: {
    paddingVertical: Token.spacing.m,
    paddingHorizontal: Token.spacing.l,
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputText: {
    flex: 1,
    paddingHorizontal: Token.spacing.s,
  },
  divider: {
    marginRight: -1,
  },
  radio: {
    padding: Token.spacing.m,
  },
  radioText: {
    marginLeft: Token.spacing.m,
  },
});

import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  PropsWithChildren,
} from 'react';
import {
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  createElement,
  ViewProps,
} from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import {
  useTheme,
  Button,
  Text,
  Token,
  InfoBox,
  Icon,
} from '@traveloka/web-components';

import { Content } from 'shared/components/Layout';
import { useProperty } from 'hotel/detail/contexts/PropertyContext';

import InfoIcon from '@traveloka/icon-kit-web/svg/dark/ic_system_status_info-fill_24px.svg';

export default function AdditionalInfo() {
  const [
    { policy, checkInInstruction, importantNotice, facilities },
  ] = useProperty();
  const { color } = useTheme();
  const additionalInfoStyle = {
    backgroundColor: color.lightPrimary,
  };

  const content = useContentResource().CorporateHotelDetailAdditionalInfo;

  const sortedFacilityNames = useMemo(
    () =>
      facilities
        .map(f => f.name)
        .sort((a, b) => {
          return a.localeCompare(b);
        }),
    [facilities]
  );

  return (
    <View style={additionalInfoStyle}>
      <Content>
        {isValidString(importantNotice) && (
          <View style={Style.importantNotice}>
            <InfoBox
              isFullWidth={false}
              Icon={() => <Icon src={InfoIcon} width={16} height={16} />}
              variant="INFO"
              message={stripTag(importantNotice)}
              style={Style.richText}
            />
          </View>
        )}
        {isValidString(checkInInstruction) && (
          <CollapsibleInfo
            label={content.checkInInfoTitle}
            style={Style.info}
            text={stripTag(checkInInstruction)}
            lessLabel={content.checkInReadLessText}
            moreLabel={content.checkInReadMoreText}
          />
        )}
        {isValidString(policy) && (
          <CollapsibleInfo
            label={content.policyInfoTitle}
            style={Style.info}
            text={stripTag(policy)}
            lessLabel={content.policyReadLessText}
            moreLabel={content.policyReadMoreText}
          />
        )}
        {sortedFacilityNames.length > 0 && (
          <>
            <Text variant="ui-large" style={Style.facilityTitle}>
              {content.faiclityTitle}
            </Text>
            <Ul style={Style.facilities}>
              {sortedFacilityNames.map(name => (
                <Text
                  // @ts-ignore
                  accessibilityRole="listitem"
                  style={Style.facility}
                >
                  {name}
                </Text>
              ))}
            </Ul>
          </>
        )}
      </Content>
    </View>
  );
}

function Ul(props: PropsWithChildren<ViewProps>) {
  return createElement('ul', props);
}

type CollapsibleInfoProps = {
  label: string;
  style?: StyleProp<ViewStyle>;
  text: string;
  lessLabel: string;
  moreLabel: string;
};

const COLLAPSED_HEIGHT = Token.typography.uiBaseline.lineHeight * 3;

function CollapsibleInfo(props: CollapsibleInfoProps) {
  const { label, style, text, lessLabel, moreLabel } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>();
  const contentHeightRef = useRef(Token.typography.uiBaseline.lineHeight);
  const handleLayout = useCallback((e: LayoutChangeEvent) => {
    const { height } = e.nativeEvent.layout;
    contentHeightRef.current = height;

    setIsButtonVisible(height > COLLAPSED_HEIGHT);
  }, []);
  const handleToggle = useCallback(() => setIsCollapsed(s => !s), []);

  const collapsibleStyle = {
    height:
      isButtonVisible && isCollapsed
        ? COLLAPSED_HEIGHT
        : contentHeightRef.current,
  };

  return (
    <View style={[Style.collapsibleContainer, style]}>
      <Text variant="ui-large" style={Style.collapsibleLabel}>
        {label}
      </Text>
      <View style={Style.content}>
        <View style={[Style.collapsibleText, collapsibleStyle]}>
          <Text onLayout={handleLayout} style={Style.richText}>
            {text}
          </Text>
        </View>
        {isButtonVisible && (
          <Button
            variant="text"
            onPress={handleToggle}
            text={isCollapsed ? moreLabel : lessLabel}
            style={Style.toggleButton}
          />
        )}
      </View>
    </View>
  );
}

const tagRegex = /(<([^>]+)>)/gi;
function stripTag(text: string) {
  return text.replace(tagRegex, '');
}

function isValidString(text: string | undefined | null): text is string {
  return typeof text === 'string' && text.length > 0;
}

const Style = StyleSheet.create({
  space: {
    paddingHorizontal: Token.spacing.l,
  },
  info: {
    marginBottom: Token.spacing.l,
  },
  collapsibleContainer: {
    flexDirection: 'row',
  },
  content: {
    width: 700,
    marginLeft: Token.spacing.l,
  },
  collapsibleLabel: {
    flex: 1,
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  collapsibleText: {
    // @ts-ignore
    transitionProperty: 'height',
    transitionDuration: `${Token.animation.timing.instant}ms`,
    overflow: 'hidden',
  },
  richText: {
    // @ts-ignore
    whiteSpace: 'unset',
  },
  toggleButton: {
    alignSelf: 'flex-start',
  },
  importantNotice: {
    marginBottom: Token.spacing.m,
  },
  facilityTitle: {
    marginBottom: Token.spacing.xs,
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  facilities: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Token.spacing.xl,
  },
  facility: {
    width: `calc(100%/3)`,
    paddingLeft: Token.spacing.s,
    paddingRight: Token.spacing.xl,
    marginBottom: Token.spacing.xs,
    lineHeight: Token.typography.uiLarge.lineHeight,
    // @ts-ignore
    listStyle: 'disc',
    // @ts-ignore
    display: 'list-item',
  },
});

import React, { useMemo } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { useContentResource } from '@traveloka/ctv-core';

import { Token, Text, Badge } from '@traveloka/web-components';
import { ImageCarousel } from '@traveloka/ctvweb-ui';
import { Skeleton } from '@traveloka/web-components/future';

import { useStaticData } from 'flight/search/contexts/StaticDataContext';
import useRecentSearchFlight from 'flight/search/hooks/useRecentSearchFlight';

const CARD_HEIGHT =
  Token.typography.uiTiny.lineHeight * 2 + Token.spacing.m * 2;
const CONTAINER_HEIGHT = CARD_HEIGHT + Token.spacing.s * 2;

type RecentSearchFlightStateData = {
  origin: string;
  destination: string;
  location: string;
  isFrequentlyBooked: boolean;
};

type FlightRecentlySearchProps = {
  onOriginAndDestinationChange: (origin: string, destination: string) => void;
};

export default function FlightRecentlySearch(props: FlightRecentlySearchProps) {
  const { onOriginAndDestinationChange } = props;
  const content = useContentResource();
  const staticData = useStaticData();
  const {
    data: recentSearchData,
    isLoading: recentSearchDataLoading,
    isError: recentSearchDataError,
  } = useRecentSearchFlight();

  const recentSearchFlight: RecentSearchFlightStateData[] = useMemo(() => {
    return (recentSearchData?.details || []).map(detail => {
      let originData = staticData.airportMap[detail.origin];
      if (!originData) {
        const [airportCode] = staticData.areaAirportsMap[detail.origin]!.split(
          '|'
        );
        originData = staticData.airportMap[airportCode]!;
      }
      let destinationData = staticData.airportMap[detail.destination];
      if (!destinationData) {
        const [airportCode] = staticData.areaAirportsMap[
          detail.destination
        ]!.split('|');
        destinationData = staticData.airportMap[airportCode]!;
      }
      return {
        origin: detail.origin,
        destination: detail.destination,
        location: `${originData?.city} (${detail.origin}) → ${destinationData?.city} (${detail.destination})`,
        isFrequentlyBooked: detail.isFrequentlyBooked,
      };
    });
  }, [recentSearchData]);

  const handleSelect = (item: RecentSearchFlightStateData) => {
    onOriginAndDestinationChange(item.origin, item.destination);
  };

  const contentRenderer = () => {
    if (recentSearchDataLoading) {
      return <Skeleton width={'100%'} height={CONTAINER_HEIGHT} />;
    }

    if (recentSearchDataError) return null;

    return (
      <ImageCarousel
        column={4}
        lists={recentSearchFlight}
        containerHeight={CONTAINER_HEIGHT}
        renderItem={({ item, index }) => (
          <TouchableOpacity key={index} onPress={() => handleSelect(item)}>
            {item.isFrequentlyBooked && (
              <View style={Style.badge}>
                <Badge
                  variant="info-subtle"
                  text={
                    content.CorporateFlightRecentlySearch.frequentlyBookedText
                  }
                />
              </View>
            )}
            <View style={[Style.card, { height: CARD_HEIGHT }]}>
              <Text
                variant="ui-tiny"
                numberOfLines={2}
                style={Token.typography.weightMedium}
              >
                {item.location}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      />
    );
  };

  if (!recentSearchDataLoading && recentSearchFlight.length === 0) return null;

  return (
    <View style={Style.container}>
      <Text variant="title-2" style={Style.title}>
        {content.CorporateFlightRecentlySearch.recentlySearchText}
      </Text>
      {contentRenderer()}
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    paddingHorizontal: Token.spacing.m,
    paddingTop: Token.spacing.m,
  },
  title: {
    marginBottom: Token.spacing.m,
  },
  card: {
    ...Token.elevation.float,
    borderRadius: Token.border.radius.normal,
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.m,
  },
  badge: {
    position: 'absolute',
    top: -Token.spacing.s,
    zIndex: 2,
    paddingHorizontal: Token.spacing.s,
  },
});

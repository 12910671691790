import React from 'react';

import { Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

type Props = {
  isVisible: boolean;
  youtubeEmbedSrc: string;
  width?: number;
  onCloseModal: () => void;
};

function GenericHomeVideoModal(props: Props) {
  const { isVisible, youtubeEmbedSrc, width, onCloseModal } = props;
  const iframelWidth = width ? width : 860;
  const iframeHeight = (iframelWidth * 9) / 16;

  return (
    <Modal
      isVisible={isVisible}
      backdropOpacity={0.9}
      backdropColor={Token.color.uiDarkNeutral}
      onBackdropPress={onCloseModal}
    >
      <iframe
        frameBorder={0}
        scrolling="no"
        src={youtubeEmbedSrc}
        width={iframelWidth}
        height={iframeHeight}
      />
    </Modal>
  );
}

export default GenericHomeVideoModal;

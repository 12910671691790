import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  Ref,
} from 'react';
import { StyleSheet, View } from 'react-native';

import { resolve } from 'url';

import { config, useApi } from '@traveloka/ctv-core';
import {
  useContentResource,
  useImageResource,
} from '@traveloka/ctv-core/resource';
import { Button, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import {
  RegisterCardResponse,
  REGISTER_CREDIT_CARD_API,
} from 'company/shared/api';

type DataTransferRequest = {
  type: 'DATA_TRANSFER_REQUEST';
  dataType: 'CONTENT_RESOURCE' | 'IMAGE_RESOURCE' | 'ORIGIN';
  requestId: string;
};

type AuthorizeCard = {
  type: 'HOST_FORM_ACTION';
  actionType: 'AUTHORIZE_CARD';
  params: unknown;
};

type InitError = {
  type: 'HOST_FORM_ACTION';
  actionType: 'INITIALIZATION_ERROR';
  params: {
    cardType: string;
    creditCardNumber: string;
    expirationMonth: number;
    expirationYear: number;
    redirectUri: string;
  };
};

type AdjustHeight = {
  type: 'HOST_FORM_ACTION';
  actionType: 'ADJUST_HEIGHT';
  params: number;
};

type FormData = AuthorizeCard | DataTransferRequest | InitError | AdjustHeight;
type Action = 'VALIDATE' | 'PROCEED_TO_CYBERSOURCE' | 'CLEAR';

export type Handler = {
  validate(): void;
  clear(): void;
};

type Props = {
  onAuthorizeStart?(): void;
  onAuthorizeFailed?(): void;
};

function CreditCardForm(props: Props, ref: Ref<Handler>) {
  const { onAuthorizeStart, onAuthorizeFailed } = props;
  const [modalVisibile, setModalVisible] = useState(false);
  const [error, setError] = useState('');

  useImperativeHandle(ref, () => ({
    validate() {
      postMessage('VALIDATE');
    },
    clear() {
      postMessage('CLEAR');
    },
  }));

  const [height, setHeight] = useState(240);
  const iframeId = 'desktop-corporate-card-form';
  const iframeSrc = resolve(
    config.securePayment,
    `/${
      config.securePayment.includes('localhost')
        ? iframeId
        : iframeId.concat('.html')
    }?callbackUrl=${window.location.pathname}`
  );

  const iframeRef = useRef<HTMLIFrameElement>(null);
  function postMessage(action: Action, value?: any) {
    if (!iframeRef.current) return;

    iframeRef.current.contentWindow!.postMessage(
      {
        type: 'EMBEDDED_FORM_ACTION',
        action,
        value,
      },
      config.securePayment
    );
  }

  const { CorporateCC } = useContentResource();
  const { CommerceAPIImages } = useImageResource();

  function transferData(data: DataTransferRequest) {
    const { dataType, requestId } = data;

    function respondDataTransfer(result: any) {
      if (!iframeRef.current) return;

      iframeRef.current.contentWindow!.postMessage(
        {
          type: 'DATA_TRANSFER_RESPONSE',
          requestId,
          result,
        },
        config.securePayment
      );
    }

    switch (dataType) {
      case 'CONTENT_RESOURCE':
        respondDataTransfer({ CorporateCC });
        break;
      case 'IMAGE_RESOURCE':
        respondDataTransfer({ CommerceAPIImages });
        break;
      case 'ORIGIN':
        respondDataTransfer(window.location.origin);
        break;
    }
  }

  const registerCard = useApi<RegisterCardResponse>({
    domain: 'management',
    method: 'post',
    path: REGISTER_CREDIT_CARD_API,
  });
  async function authorizeCard(data: unknown) {
    onAuthorizeStart?.();
    const res = await registerCard(data);

    if (res.success) {
      postMessage('PROCEED_TO_CYBERSOURCE', res.data.redirectInfo);
    } else {
      onAuthorizeFailed?.();
      setModalVisible(true);
      setError(res.error.message);
    }
  }

  useEffect(() => {
    function handler(event: MessageEvent) {
      if (event.origin !== config.securePayment) {
        event.preventDefault();
        return;
      }

      const data = event.data as FormData;

      switch (data.type) {
        case 'DATA_TRANSFER_REQUEST':
          transferData(data);
          break;
        case 'HOST_FORM_ACTION':
          switch (data.actionType) {
            case 'AUTHORIZE_CARD':
              authorizeCard(data.params);
              break;
            case 'ADJUST_HEIGHT':
              setHeight(data.params);
              break;
          }
          break;
      }
    }

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, []);

  const cr = useContentResource().CorporateManageCard;

  return (
    <>
      <View style={Style.header}>
        <Text ink="white">{cr.addCardTitle}</Text>
      </View>
      <iframe
        frameBorder={0}
        height={height}
        ref={iframeRef}
        scrolling="no"
        src={iframeSrc}
        width="100%"
      />
      <Modal isVisible={modalVisibile}>
        <Popup
          title="Error"
          showCloseButton
          onCloseButtonPress={() => setModalVisible(false)}
          width={450}
        >
          <Text style={Style.text}>{error}</Text>
          <Text style={Style.text}>{cr.manageCardErrorMessage}</Text>
          <View style={Style.control}>
            <Button onPress={() => setModalVisible(false)} text={cr.close} />
          </View>
        </Popup>
      </Modal>
    </>
  );
}

const Style = StyleSheet.create({
  header: {
    padding: Token.spacing.m,
    backgroundColor: Token.color.brandBusinessSuit,
    borderRadius: Token.border.radius.normal,
    flexDirection: 'row',
    marginBottom: Token.spacing.xl,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  control: {
    alignItems: 'flex-end',
  },
  text: {
    marginBottom: Token.spacing.m,
  },
});

export default forwardRef(CreditCardForm);

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { addDays, addYears, startOfDay } from 'date-fns';

import {
  Permission,
  useApi,
  useAuth,
  useContentResource,
  useFeatureControl,
} from '@traveloka/ctv-core';
import {
  EntryListRequest,
  EntryListResponse,
  GET_ENTRY_LIST,
  Operator,
} from '@traveloka/ctv-core/pad';
import { FlightSearchForm as SearchForm } from '@traveloka/ctvweb-ui/flight';
import { Traveler } from '@traveloka/ctvweb-ui/src/generic/TravelerPickerModal/types';
import { Item } from '@traveloka/ctvweb-ui/src/shared/components/form/InputDropdown/types';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import { PartialEmployee } from 'company/types';
import FlightRecentlySearch from 'flight/search/components/FlightRecentlySearch/FlightRecentlySearch';
import {
  defaultFlightNonEmployee,
  useSearchSpec,
  useSearchSpecDispatch,
} from 'flight/search/contexts/SearchSpecContext';
import { useStaticData } from 'flight/search/contexts/StaticDataContext';
import { Airport } from 'flight/search/types';
import {
  convertNonEmployeePayloadToType,
  convertNonEmployeeTypeToPayload,
} from 'flight/search/utils/flight-search-spec-util';
import { guessAirportCity } from 'flight/search/utils/flight-static-data-util';
import {
  FlightNonEmployeeTraveler,
  NonEmployeeType,
} from 'flight/shared/api/types';
import SeatClass from 'flight/shared/constants/seat-class';
import RevampTravelerPickerModal, {
  NonEmployeeField,
  ValidationAdditionalInfo,
  ValidationReturn,
  usePickerDesign,
} from 'shared/components/TravelerPickerModal/RevampTravelerPickerModal';
import useEagerNavigation from 'shared/hooks/useEagerNavigation';
import { formatMessage } from 'shared/utils/intl';

import {
  AVAILABLE_SEAT_CLASS,
  RETURN_DATE_OFFSET,
} from '../../constants/flight-search-constant';
import {
  isInternational,
  parseSearchSpec,
  stringifySearchSpec,
} from '../../utils/flight-search-query-util';

type Props = {
  onSearch?: () => void;
  isSearching?: boolean;
  tripRequestEnabled: boolean;
};

type NonEmployeeFields = [
  {
    type: NonEmployeeType.ADULT;
    value: number;
    onValidate(
      employees: Traveler[],
      nonEmployees: NonEmployeeField[],
      additionalInfo?: ValidationAdditionalInfo
    ): Nullable<ValidationReturn>;
  },
  {
    type: NonEmployeeType.CHILD;
    value: number;
    onValidate(
      employees: Traveler[],
      nonEmployees: NonEmployeeField[],
      additionalInfo?: ValidationAdditionalInfo
    ): Nullable<ValidationReturn>;
  },
  {
    type: NonEmployeeType.INFANT;
    value: number;
    onValidate(
      employees: Traveler[],
      nonEmployees: NonEmployeeField[],
      additionalInfo?: ValidationAdditionalInfo
    ): Nullable<ValidationReturn>;
  }
];

const MAX_PASSENGER = 7;
const MAX_INFANT = 4;
const HIGHLIGHT_DURATION = 1000;

function FlightSearchForm(props: Props) {
  const {
    isSuspended,
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();
  const staticData = useStaticData();

  const defaultSpec = parseSearchSpec(
    '',
    staticData.airportMap,
    staticData.areaAirportsMap
  );

  const { onSearch, isSearching, tripRequestEnabled } = props;
  const { CorporateFlightSeatClass } = useContentResource();
  const { user } = useAuth();
  const spec = useSearchSpec() ?? defaultSpec;
  const setSpec = useSearchSpecDispatch();
  const recentSearchFC = useFeatureControl('b2b-recent-search');

  const destinationTtlRef = useRef<number>();
  const passengerTtlRef = useRef<number>();
  const [origin, setOrigin] = useState(spec.airport.origin);
  const [destination, setDestination] = useState(spec.airport.destination);
  const [isRoundTrip, setIsRoundTrip] = useState(spec.isRoundTrip);
  const [departDate, setDepartDate] = useState(spec.date.depart);
  const [returnDate, setReturnDate] = useState(spec.date.return);
  const [seatClass, setSeatClass] = useState(spec.seatClass);
  const [passengers, setPassengers] = useState<Traveler[]>(spec.passengers);
  const [nonEmployeeTravelers, setNonEmployeeTravelers] = useState(
    spec.nonEmployeeTravelers || defaultFlightNonEmployee
  );
  const [tripRequestId] = useState(spec.tripRequestId);
  const [destinationError, setDestinationError] = useState(false);
  const [passengerError, setPassengerError] = useState(false);

  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const todayNextYear = useRef(startOfDay(addYears(new Date(), 1)));

  const [airportHighlighted, setAirportHighlighted] = useState<boolean>(false);

  const content = useContentResource().CorporateFlightSearchForm;
  const productContent = useContentResource().CorporateProductSearchForm;

  const [canBookForOwn, canBookForOthers, canBookNonEmployee] = useMemo(
    () => [
      user?.has(Permission.BOOK_PRODUCT_FOR_OWN),
      user?.has(Permission.BOOK_PRODUCT_FOR_OTHERS),
      user?.has(Permission.BOOK_PRODUCT_FOR_NON_EMPLOYEE),
    ],
    [user]
  );
  const pickerDesign = usePickerDesign();

  const canBook = canBookForOwn || canBookForOthers || canBookNonEmployee;
  const canBookAll = canBookForOwn && canBookForOthers;

  const fetchEntryList = useApi<
    EntryListResponse<PartialEmployee>,
    EntryListRequest
  >({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_LIST,
  });

  const airportOptions = useMemo(() => {
    return ([] as Item[])
      .concat(
        Object.keys(staticData.areaAirportsMap).map(areaCode => {
          const [airportCode] = staticData.areaAirportsMap[areaCode]!.split(
            '|'
          );
          const airport = staticData.airportMap[airportCode]!;

          return {
            label: airport.city,
            subLabel: formatMessage(content.airportOptionAllAirportText, {
              areaCode,
              city: airport.city,
            }),
            value: areaCode,
          };
        })
      )
      .concat(
        Object.values(staticData.airportMap)
          .filter<Airport>((airport): airport is Airport => !!airport)
          .map(airport => ({
            label: airport.city,
            subLabel: `${airport.airportCode} - ${airport.internationalAirportName}`,
            value: airport.airportCode,
          }))
      )
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        } else if (a.label < b.label) {
          return -1;
        }

        return 0;
      })
      .sort((a, b) => {
        // Make all item with sublabel "All airports" at the top of their city
        if (
          a.label.includes(b.label) &&
          a.subLabel?.split(' - ')[1]?.startsWith('All ')
        ) {
          return -1;
        }

        return 0;
      });
  }, [staticData]);

  const seatClassOptions = useMemo(
    () =>
      AVAILABLE_SEAT_CLASS.map(seatClass => ({
        label: CorporateFlightSeatClass[seatClass],
        value: seatClass,
      })),
    [AVAILABLE_SEAT_CLASS]
  );

  const navigate = useEagerNavigation();
  function handleSearchPress() {
    if (origin === destination) {
      setDestinationError(true);

      clearTimeout(destinationTtlRef.current);
      destinationTtlRef.current = window.setTimeout(() => {
        setDestinationError(false);
      }, 5000);

      return;
    }

    if (passengers.length + nonEmployeeTravelers.adults === 0) {
      setPassengerError(true);

      clearTimeout(passengerTtlRef.current);
      passengerTtlRef.current = window.setTimeout(() => {
        setPassengerError(false);
      }, 5000);

      return;
    }

    const spec = {
      passengers,
      nonEmployeeTravelers,
      airport: {
        origin,
        destination,
      },
      isRoundTrip,
      isInternational: isInternational(
        origin,
        destination,
        staticData.airportMap,
        staticData.areaAirportsMap
      ),
      date: {
        depart: departDate,
        return: returnDate,
      },
      seatClass,
      tripRequestId,
    };

    const stringifySpec = stringifySearchSpec(spec);
    navigate({
      pathname: '/flight/search',
      search: stringifySpec,
    });
    setSpec(
      parseSearchSpec(
        stringifySpec,
        staticData.airportMap,
        staticData.areaAirportsMap
      )
    );

    onSearch && onSearch();
  }

  const handleSwapPress = useCallback(() => {
    setDestination(origin);
    setOrigin(destination);
  }, [destination, origin]);

  const handleDepartChange = useCallback(
    (date: Date) => {
      if (returnDate < date) {
        const next3Days = addDays(date, RETURN_DATE_OFFSET);

        if (next3Days < todayNextYear.current) {
          setReturnDate(next3Days);
        } else {
          setReturnDate(todayNextYear.current);
        }
      }

      setDepartDate(date);
    },
    [todayNextYear.current, returnDate]
  );

  const handleReturnChange = useCallback((date: Date) => {
    setReturnDate(date);
  }, []);

  const handlePassengerChange = useCallback(
    (passengers: Traveler[], nonEmployeeFields?: NonEmployeeFields) => {
      setIsPickerVisible(false);
      setPassengers(passengers);
      if (canBookNonEmployee && nonEmployeeFields) {
        const value = {
          ...defaultFlightNonEmployee,
        };
        nonEmployeeFields.forEach(field => {
          value[convertNonEmployeeTypeToPayload(field.type)] = field.value;
        });
        setNonEmployeeTravelers(value);
      } else {
        setNonEmployeeTravelers({ ...defaultFlightNonEmployee });
      }
      setPassengerError(false);
    },
    []
  );

  useEffect(() => {
    if (pickerDesign === 'own') {
      const payload = {
        entityType: 'employeeList',
        search: {
          entriesCount: 1,
          filter: [
            {
              fieldName: 'email',
              arguments: {
                value: user!.email,
                operator: Operator.EQUAL,
              },
            },
          ],
        },
      };

      fetchEntryList(payload).then(res => {
        if (res.success) {
          setPassengers(res.data.entries);
        }
      });
    }
  }, []);

  const passengerText = useMemo(() => {
    const showDetail: string[] = [];
    const nonEmployeeKeys = Object.keys(nonEmployeeTravelers) as Array<
      keyof typeof nonEmployeeTravelers
    >;

    nonEmployeeKeys.forEach(key => {
      switch (convertNonEmployeePayloadToType(key)) {
        case NonEmployeeType.ADULT:
          if (nonEmployeeTravelers.adults + passengers.length > 0) {
            showDetail.push(
              formatMessage(productContent.nonEmployeeAdultUnit, {
                num: nonEmployeeTravelers.adults + passengers.length,
              })
            );
          }
          break;
        case NonEmployeeType.CHILD:
          if (nonEmployeeTravelers.children > 0) {
            showDetail.push(
              formatMessage(productContent.nonEmployeeChildUnit, {
                num: nonEmployeeTravelers.children,
              })
            );
          }
          break;
        case NonEmployeeType.INFANT:
          if (nonEmployeeTravelers.infants > 0) {
            showDetail.push(
              formatMessage(productContent.nonEmployeeInfantUnit, {
                num: nonEmployeeTravelers.infants,
              })
            );
          }
      }
    });

    const isOneEmployeeOnly =
      passengers.length === 1 &&
      nonEmployeeTravelers.adults +
        nonEmployeeTravelers.children +
        nonEmployeeTravelers.infants ===
        0;

    return showDetail.length === 0
      ? content.emptyPassengerText
      : isOneEmployeeOnly
      ? passengers[0].fullname
      : showDetail.join(', ');
  }, [passengers, nonEmployeeTravelers]);

  const maxTravelersErrorMessage = formatMessage(
    content.errorTooltipMaxTraveler,
    { maxTraveler: MAX_PASSENGER }
  );

  const nonEmployeeFields: NonEmployeeFields | undefined = useMemo(
    () =>
      canBookNonEmployee
        ? [
            {
              type: NonEmployeeType.ADULT,
              value: nonEmployeeTravelers.adults,
              onValidate(
                employees: Traveler[],
                nonEmployees: NonEmployeeFields,
                additionalInfo?: ValidationAdditionalInfo
              ) {
                const employee = employees.length;
                const neAdult = additionalInfo?.value ?? nonEmployees[0].value;
                const neChild = nonEmployees[1].value;
                const neInfant = nonEmployees[2].value;

                if (employee + neAdult + neChild > MAX_PASSENGER) {
                  // This condition will true if current selected travelers is more than the maximum traveler.

                  // Don't need to update the value if the new value is more than or equal the current value.
                  const isNoUpdate = neAdult >= nonEmployees[0].value;
                  return {
                    error: maxTravelersErrorMessage,
                    noUpdate: isNoUpdate,
                  };
                }
                if (employees.length + neAdult !== 0) {
                  return null;
                }

                return neChild !== 0 || neInfant !== 0
                  ? {
                      error: content.nonEmployeeTooltipNoAdult,
                      noUpdate: false,
                    }
                  : null;
              },
            },
            {
              type: NonEmployeeType.CHILD,
              value: nonEmployeeTravelers.children,
              onValidate(
                employees: Traveler[],
                nonEmployees: NonEmployeeFields,
                additionalInfo?: ValidationAdditionalInfo
              ) {
                const employee = employees.length;
                const neAdult = nonEmployees[0].value;
                const neChild = additionalInfo?.value ?? nonEmployees[1].value;
                const isNoUpdate = neChild >= nonEmployees[1].value;

                return employee + neAdult + neChild > MAX_PASSENGER
                  ? {
                      error: maxTravelersErrorMessage,
                      noUpdate: isNoUpdate,
                    }
                  : null;
              },
            },
            {
              type: NonEmployeeType.INFANT,
              value: nonEmployeeTravelers.infants,
              onValidate(
                employees: Traveler[],
                nonEmployees: NonEmployeeFields,
                additionalInfo?: ValidationAdditionalInfo
              ) {
                const employee = employees.length;
                const neAdult = nonEmployees[0].value;
                const neInfant = additionalInfo?.value ?? nonEmployees[2].value;

                if (neInfant > MAX_INFANT) {
                  return {
                    error: formatMessage(content.errorTooltipMaxInfant, {
                      maxInfant: MAX_INFANT,
                    }),
                    noUpdate: true,
                  };
                }
                return neInfant > employee + neAdult
                  ? {
                      error: content.nonEmployeeTooltipLessAdult,
                      noUpdate: false,
                    }
                  : null;
              },
            },
          ]
        : undefined,
    [canBookNonEmployee, nonEmployeeTravelers, passengers]
  );

  const passengerInfoTooltip = useMemo(
    () =>
      canBookAll
        ? formatMessage(content.canBookAllText, { maxPassenger: MAX_PASSENGER })
        : canBookForOthers
        ? formatMessage(content.canBookForOthersText, {
            maxPassenger: MAX_PASSENGER,
          })
        : '',
    [canBookAll, canBookForOthers]
  );

  const handleOriginAndDestinationChange = (
    origin: string,
    destination: string
  ) => {
    setOrigin(origin);
    setDestination(destination);

    // Highlighted form input
    setAirportHighlighted(true);
    setTimeout(() => {
      setAirportHighlighted(false);
    }, HIGHLIGHT_DURATION);
  };

  return (
    <>
      {recentSearchFC.enabled && (
        <FlightRecentlySearch
          onOriginAndDestinationChange={handleOriginAndDestinationChange}
        />
      )}
      <SearchForm
        testID={'flight.form'}
        passenger={passengerText}
        origin={guessAirportCity(origin, staticData)}
        destination={guessAirportCity(destination, staticData)}
        isRoundTrip={isRoundTrip}
        departDate={departDate}
        returnDate={returnDate}
        seatClass={CorporateFlightSeatClass[seatClass]}
        onSearchPress={() => fetchAccountStatus(handleSearchPress)}
        onSwapPress={handleSwapPress}
        onRoundTripToggle={() => setIsRoundTrip(!isRoundTrip)}
        onPassengerFocus={() => setIsPickerVisible(true)}
        onOriginChange={item => setOrigin(item.value)}
        onDestinationChange={item => setDestination(item.value)}
        onDepartChange={handleDepartChange}
        onReturnChange={handleReturnChange}
        onSeatClassChange={item => setSeatClass(item.value as SeatClass)}
        airportOptions={airportOptions}
        seatClassOptions={seatClassOptions}
        dateFormat="SHORT_MONTH"
        maxDate={todayNextYear.current}
        destinationError={destinationError}
        passengerError={passengerError}
        isSearching={isAccountStatusFetching || isSearching}
        searchButtonEnabled={canBook}
        content={{
          passenger: content.passengerLabel,
          origin: content.originLabel,
          destination: content.destinationLabel,
          departureDate: content.departureDateLabel,
          returnDate: content.returnDateLabel,
          seatClass: content.seatClassLabel,
          search: content.searchButtonText,
          destinationTooltip: content.destinationTooltipText,
          passengerTooltip: content.passengerTooltipText,
          passengerInfoTooltip,
        }}
        airportHighlighted={airportHighlighted}
        formDisabled={isSuspended}
      />
      {pickerDesign !== 'own' && (
        <RevampTravelerPickerModal
          isVisible={isPickerVisible}
          maximumTraveler={MAX_PASSENGER}
          onClose={() => setIsPickerVisible(false)}
          onSave={handlePassengerChange}
          nonEmployeeFields={nonEmployeeFields}
          hasNonEmployee={Object.keys(nonEmployeeTravelers).some(key => {
            const k = key as keyof FlightNonEmployeeTraveler;
            return nonEmployeeTravelers[k] !== defaultFlightNonEmployee[k];
          })}
          travelers={passengers}
          tripRequestEnabled={tripRequestEnabled}
          complianceLabel={content.travelerComplianceLabel}
          nonEmployeeEnablementCheckbox={content.nonEmployeeEnablementCheckbox}
          nonEmployeeTooltip={content.nonEmployeeTooltip}
          nonEmployeeSubtitle={content.nonEmployeeSubtitle}
          emptySelectedTravelerText={content.emptySelectedTravelerText}
          selectedTravelerText={content.selectedTravelerText}
          emptyTotalTravelerText={content.emptyTotalTravelerText}
          totalTravelerText={content.totalTravelerText}
          maxTravelersErrorMessage={maxTravelersErrorMessage}
          subtitle={formatMessage(content.travelerSubtitleLabel, {
            maxPassenger: MAX_PASSENGER,
          })}
          title={content.travelerTitleLabel}
        />
      )}
    </>
  );
}
export default FlightSearchForm;

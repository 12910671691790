import { Traveler } from '@traveloka/ctvweb-ui/src/generic/TravelerPickerModal/types';

import { PolicyLocation } from 'company/types';
import { SubmitBookingRequest as FlightSubmitBookingRequest } from 'flight/shared/api';
import { NonEmployeeType } from 'flight/shared/api/types';
import SeatClass from 'flight/shared/constants/seat-class';
import { SubmitBookingRequest as HotelSubmitBookingRequest } from 'hotel/shared/api';
import { JavaCurrencyValue } from 'shared/utils/currency';

import ProductType from '../constants/ProductType';

// Common
export type Employee = {
  employeeId: string;
  fullname: string;
  email: string;
  division: string | null;
};

export enum Status {
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
}

export enum Role {
  APPROVER = 'APPROVER',
  TRAVELER = 'TRAVELER',
  MAIN_BOOKER = 'MAIN_BOOKER',
  REQUESTER = 'REQUESTER',
  REQUESTER_OR_TRAVELER = 'REQUESTER_OR_TRAVELER',
}

export enum NonMatchCategory {
  // Flight
  DEPARTURE_FLIGHT = 'DEPARTURE_FLIGHT',
  RETURN_FLIGHT = 'RETURN_FLIGHT',
  FLIGHT_SEAT_CLASS = 'FLIGHT_SEAT_CLASS',
  PASSENGERS = 'PASSENGERS',
  OUTSIDE_PLANNED_TRIP_DATE_DEPARTURE = 'OUTSIDE_PLANNED_TRIP_DATE_DEPARTURE',
  OUTSIDE_PLANNED_TRIP_DATE_RETURN = 'OUTSIDE_PLANNED_TRIP_DATE_RETURN',
  NO_OF_PASSENGER = 'NO_OF_PASSENGER',
  FLIGHT_TYPE = 'FLIGHT_TYPE',
  NO_OF_INFANT = 'NO_OF_INFANT',

  // Hotel
  DESTINATION = 'DESTINATION',
  GUESTS = 'GUESTS',
  OUTSIDE_PLANNED_TRIP_DATE_CHECK_IN = 'OUTSIDE_PLANNED_TRIP_DATE_CHECK_IN',
  OUTSIDE_PLANNED_TRIP_DATE_CHECK_OUT = 'OUTSIDE_PLANNED_TRIP_DATE_CHECK_OUT',
  NO_OF_ROOM = 'NO_OF_ROOM',
  NO_OF_GUEST = 'NO_OF_GUEST',

  // Shared
  NO_OF_ADULT = 'NO_OF_ADULT',
  NO_OF_CHILD = 'NO_OF_CHILD',
}

export type NonMatchReason = {
  category: NonMatchCategory;
  productRequest: string[];
  booking: string[];
};

// Approval List
export enum FilterStatus {
  ALL = 'ALL',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  PRODUCT_WAITING_FOR_APPROVAL = 'PRODUCT_WAITING_FOR_APPROVAL',
  PRODUCT_WAITING_FOR_PAYMENT = 'PRODUCT_WAITING_FOR_PAYMENT',
  PRODUCT_OPEN_TO_SEARCH = 'PRODUCT_OPEN_TO_SEARCH',
  PRODUCT_PURCHASED = 'PRODUCT_PURCHASED',
}

export enum FilterRole {
  ALL = 'ALL',
  APPROVER = 'APPROVER',
  TRAVELER = 'TRAVELER',
  REQUESTER = 'REQUESTER',
}

export enum Sort {
  LAST_UPDATED = 'LAST_UPDATED',
  ENDED_SOON = 'ENDED_SOON',
}

export type TripRequest = {
  tripRequestId: string;
  tripName: string;
  startDate: string;
  endDate: string;
  approver: Employee;
  requester: Employee;
  status: Status;
  numOfNewUpdates: string;
  numOfOpenToSearch: string;
  numOfAwaitingApprovals: string;
  numOfPurchasedProducts: string;
};

// Approval Detail
export enum ActivityCategory {
  REQUEST_TRIP_REQUEST = 'REQUEST_TRIP_REQUEST',
  REQUEST_PAYMENT_REQUEST = 'REQUEST_PAYMENT_REQUEST',
  APPROVE_TRIP_REQUEST = 'APPROVE_TRIP_REQUEST',
  APPROVE_PAYMENT_REQUEST = 'APPROVE_PAYMENT_REQUEST',
  DECLINE_TRIP_REQUEST = 'DECLINE_TRIP_REQUEST',
  DECLINE_PAYMENT_REQUEST = 'DECLINE_PAYMENT_REQUEST',
}

export enum ProductStatus {
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  OPEN_TO_SEARCH = 'OPEN_TO_SEARCH',
  PURCHASED = 'PURCHASED',
  PURCHASE_FAILED = 'PURCHASE_FAILED',
}

type FlightNonEmployeeTraveler = {
  adults: string;
  children: string;
  infants: string;
};

type HotelNonEmployeeTraveler = {
  adults: string;
  childrenAges: string[];
};

type BaseProduct = {
  type: ProductType;
  productOrigin: string;
  productDestination: string;
  startDate: string;
  endDate: string | null;
  numOfTravelers: string;
  travelers: Employee[];
  status: ProductStatus;
  tripRequestId: string;
  tripName: string;
  tripId: string | null;
};

export type FlightProduct = BaseProduct & {
  type: ProductType.FLIGHT;
  airportOrigin: string;
  airportDestination: string;
  oneWay: boolean;
  seatClass: SeatClass;
  nonEmployeeTravelers: FlightNonEmployeeTraveler;
};

export type HotelProduct = BaseProduct & {
  endDate: string;
  type: ProductType.HOTEL;
  geoId: string;
  geoType: string;
  location: string;
  numOfRooms: string;
  nonEmployeeTravelers: HotelNonEmployeeTraveler;
};

export type Product = FlightProduct | HotelProduct;

type BaseActivity = {
  activityId: string;
  name: string;
  time: string;
};

export type DeclinedActivity = BaseActivity & {
  category:
    | ActivityCategory.DECLINE_TRIP_REQUEST
    | ActivityCategory.DECLINE_PAYMENT_REQUEST;
  comment: string;
  actionable: false;
  detail: null;
  attachments: null;
};

export type ApprovedActivity = BaseActivity & {
  category:
    | ActivityCategory.APPROVE_TRIP_REQUEST
    | ActivityCategory.APPROVE_PAYMENT_REQUEST;
  comment: null;
  actionable: false;
  detail: null;
  attachments: null;
};

export type TripActivity = BaseActivity & {
  category: ActivityCategory.REQUEST_TRIP_REQUEST;
  comment: string;
  actionable: true;
  detail: null;
  attachments: Attachment[];
};

export type Attachment = {
  booker: {
    name: string;
    email: string;
    division: string;
  };
  fileName: string;
  imageUrl: string;
  size: string;
  tripRequestId: string;
  type: 'TRIP_REQUEST' | 'BOOKING';
  uploadDate: string; // yyyy-mm-dd HH:mm
};

export type ActivityTraveler = {
  employeeId: string;
  fullname: string;
  email: string;
  division: string | null;
  baggage: string[] | null;
  isEmployee: boolean;
  type: NonEmployeeType;
  age?: string;
};

export type PolicyData = {
  policyDetailsFlight: Nullable<{
    enabled: boolean;
    excludedAirlines: string[];
    seatClasses: SeatClass[];
  }>;
  policyDetailsHotel: Nullable<{
    enabled: boolean;
    starRating: string;
    currency: 'IDR' | 'THB' | 'VND';
    defaultBudget: string;
    locations: PolicyLocation[];
  }>;
};

type BasePaymentActivity = BaseActivity & {
  category: ActivityCategory.REQUEST_PAYMENT_REQUEST;
  comment: string;
  actionable: true;
  detail: {
    type: ProductType;
    isComply: boolean;
    isMatch: boolean;
    nonComplyReason: string;
    nonMatchReasons: NonMatchReason[];
    policyDetails: PolicyData | null;
    travelers: ActivityTraveler[];
    totalPrice: JavaCurrencyValue;
  };
  attachments: null;
};

export type FlightActivity = BasePaymentActivity & {
  detail: {
    journeys: Array<{
      airline: {
        airlineCode: string;
        airlineName: string;
        logoUrl: string;
      };
      seatClass: SeatClass;
      departureDate: string;
      departureTime: string;
      departureLocation: string;
      arrivalDate: string;
      arrivalTime: string;
      arrivalLocation: string;
    }>;
  };
};

export type HotelActivity = BasePaymentActivity & {
  detail: {
    hotelName: string;
    image: string;
    city: string;
    starRating: string;
    checkInDate: string;
    checkOutDate: string;
    roomType: string;
    numOfRooms: string;
  };
};

export type PaymentActivity = FlightActivity | HotelActivity;

export type Activity =
  | DeclinedActivity
  | ApprovedActivity
  | PaymentActivity
  | TripActivity;

// Trip Action
export enum Action {
  DECLINE = 'DECLINE',
  APPROVE = 'APPROVE',
}

// Create Payment Approval
export type FlightPaymentApproval = {
  productRequestId: string;
  productType: ProductType.FLIGHT;
  reason: string;
  attachmentUrl: string | undefined;
  flightApprovalRequest: {
    flightBookingSubmitAPIRequest: FlightSubmitBookingRequest;
    flightJourneys: PaymentApprovalJourney[];
    price: JavaCurrencyValue;
  };
  serviceFee?: Nullable<JavaCurrencyValue>;
  vatFee?: Nullable<JavaCurrencyValue>;
  grandTotal?: Nullable<JavaCurrencyValue>;
};

type PaymentApprovalJourney = {
  airlineCode: string;
  arrivalDate: string; //yyyy-MM-dd
  arrivalAirportCode: string;
  arrivalTime: string; //xx:xx
  departureDate: string; //yyyy-MM-dd
  departureAirportCode: string;
  departureTime: string; //xx:xx
  duration: string; // in minute
  seatClass: string;
  transit: string; // number of transit
};

export type HotelPaymentApproval = {
  productRequestId: string;
  productType: ProductType.HOTEL;
  reason: string;
  attachmentUrl: string | undefined;
  hotelApprovalRequest: {
    hotelBookingSubmitAPIRequest: HotelSubmitBookingRequest;
    geoId: string;
    geoType: string;
  };
  serviceFee?: Nullable<JavaCurrencyValue>;
  vatFee?: Nullable<JavaCurrencyValue>;
  grandTotal?: Nullable<JavaCurrencyValue>;
};

// Active Trip Request Approval - Product Request
export type FlightTripRequestApproval = {
  type: ProductType.FLIGHT;
  productOrigin: string;
  productDestination: string;
  startDate: string;
  endDate: Nullable<string>;
  numOfTravelers: string;
  travelers: Traveler[];
  nonEmployeeTravelers: FlightNonEmployeeTraveler;
  status: ProductStatus.OPEN_TO_SEARCH;
  tripRequestId: string;
  tripName: string;
  productRequestId: string;
  activityId: string;
  approverName: string;
  airportOrigin: string;
  airportDestination: string;
  oneWay: boolean;
  seatClass: SeatClass;
};

export type HotelTripRequestApproval = {
  type: ProductType.HOTEL;
  productOrigin: string;
  productDestination: string;
  startDate: string;
  endDate: string;
  numOfTravelers: string;
  travelers: Traveler[];
  nonEmployeeTravelers: HotelNonEmployeeTraveler;
  status: ProductStatus.OPEN_TO_SEARCH;
  tripRequestId: string;
  tripName: string;
  productRequestId: string;
  activityId: string;
  approverName: string;
  geoId: string;
  geoType: string;
  location: string;
  numOfRooms: string;
};

// Check Search Spec - Select Product
export enum PrebookStatus {
  CONTINUE = 'CONTINUE',
  NEED_APPROVAL = 'NEED_APPROVAL',
}

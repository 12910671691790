import React from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Button, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

export default function HotelNonRefundableModal(props: Props) {
  const { isVisible, onClose } = props;

  const content = useContentResource()
    .CorporateHotelRequestRefundNonRefundableModal;

  return (
    <Modal isVisible={isVisible}>
      <Popup
        showCloseButton={false}
        onCloseButtonPress={onClose}
        title={content.title}
        width={623}
      >
        <Text>{content.body}</Text>
        <Button
          style={Style.closeButton}
          text={content.redirectButtonText}
          onPress={onClose}
        />
      </Popup>
    </Modal>
  );
}

const Style = StyleSheet.create({
  closeButton: {
    marginTop: Token.spacing.xl,
    marginHorizontal: 'auto',
  },
});

import React, { ReactNode } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import ErrorLabel from './ErrorLabel';
import Helper from './Helper';
import Label from './Label';

export type Props = {
  label?: string;
  error?: string;
  leftHelper?: string;
  rightHelper?: string;
  children: ReactNode;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function InputGroup(props: Props) {
  const {
    label,
    error,
    leftHelper,
    rightHelper,
    disabled,
    children,
    style,
  } = props;

  const hasError = !!error && !disabled;
  const hasHelper = leftHelper || rightHelper;

  return (
    <View style={style}>
      {label && <Label text={label} disabled={disabled} />}
      {children}
      {hasError && <ErrorLabel text={error!} />}
      {hasHelper && (
        <Helper
          leftText={leftHelper}
          rightText={rightHelper}
          disabled={disabled}
        />
      )}
    </View>
  );
}

import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { Divider, InputDropdownRadio } from '@traveloka/ctvweb-ui';
import ChevronDown from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';
import { Card, Icon, Text, Token } from '@traveloka/web-components';

import { useHotelRequestRefund } from 'refund/request-refund/contexts/hotel/HotelRequestRefundContext';
import {
  useHotelRequestRefundForm,
  useHotelRequestRefundFormAction,
} from 'refund/request-refund/contexts/hotel/HotelRequestRefundFormContext';
import { HotelMainRefundReason } from 'refund/shared/api/types';
import InfoBox from 'refund/shared/components/InfoBox';
import { formatCurrency, formatMessage } from 'shared/utils/intl';
import { convert } from 'shared/utils/currency';

export default function SelectRefundReasonSection() {
  const { mainRefundReason } = useHotelRequestRefundForm();
  const { updateSelectedMainReason } = useHotelRequestRefundFormAction();
  const [{ data }] = useHotelRequestRefund();
  const { reasons } = data;

  const { format } = useLocalizedDateFormat();
  const content = useContentResource().CorporateHotelRequestRefundPolicy;
  const mainRefundReasonContent = useContentResource()
    .CorporateEnumHotelMainRefundReason;

  return (
    <>
      <Text style={Style.selectMainReasonTitle} variant="title-1">
        {content.selectMainReasonTitle}
      </Text>
      <Divider margin="bottom" spacing="m" />
      <View style={Style.innerMainReasonContainer}>
        <InputDropdownRadio
          style={Style.mainReasonDropdown}
          cardStyle={Style.mainReasonDropdownCard}
          alignment="left"
          title={content.dropdownMainReasonTitle}
          text={
            mainRefundReason
              ? mainRefundReasonContent[mainRefundReason.mainReason]
              : content.emptyMainReasonText
          }
          items={reasons.map(reason => ({
            label: mainRefundReasonContent[reason.mainReason],
            value: reason.mainReason,
          }))}
          value={mainRefundReason ? mainRefundReason.mainReason : ''}
          onChange={(value: HotelMainRefundReason) =>
            updateSelectedMainReason(value)
          }
          rightIcon={<Icon src={ChevronDown} />}
        />
        <Text
          style={Style.mainReasonDescription}
          variant="ui-small"
          ink="secondary"
        >
          {content.mainReasonDescription}
        </Text>

        {data.cancellationPolicies.map(policy => (
          <Card style={Style.refundableAmountContent}>
            <Text style={Style.bold} variant="ui-baseline">
              {formatMessage(content.hoursLeftText, {
                hour: policy.hoursBeforeCheckIn,
              })}
            </Text>
            <Text variant="ui-small" ink="secondary">
              {formatMessage(content.beforeDateText, {
                time: format(new Date(policy.time), 'FULL_MONTH'),
              })}
            </Text>
            <View style={Style.priceContent}>
              <Text style={Style.bold} variant="ui-baseline">
                {content.adultText}
              </Text>
              <Text style={Style.bold} variant="ui-baseline">
                {formatCurrency(convert(policy.amount))}
              </Text>
            </View>
          </Card>
        ))}

        <InfoBox
          style={Style.refundableAmountInfoBox}
          text={content.refundableAmountContent}
          verticalAlign="top"
        />
      </View>
    </>
  );
}

const Style = StyleSheet.create({
  selectMainReasonTitle: {
    paddingHorizontal: Token.spacing.l,
    paddingBottom: Token.spacing.ml,
  },
  innerMainReasonContainer: {
    paddingHorizontal: Token.spacing.l,
  },
  mainReasonDropdown: {
    zIndex: 1,
    width: 300,
    marginBottom: Token.spacing.xs,
  },
  mainReasonDropdownCard: {
    width: 288,
  },
  itemTitle: {
    marginBottom: Token.spacing.xs,
  },
  itemContent: {
    marginBottom: Token.spacing.l,
  },
  mainReasonDescription: {
    marginBottom: Token.spacing.l,
  },
  refundableAmountContent: {
    marginTop: Token.spacing.m,
    marginBottom: Token.spacing.l,
    padding: Token.spacing.m,
    width: 345,
  },
  bold: Token.typography.weightMedium,
  priceContent: {
    marginTop: Token.spacing.m,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  refundableAmountInfoBox: {
    borderColor: Token.color.uiBluePrimary,
    borderWidth: Token.border.width.thick,
  },
});

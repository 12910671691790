import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { FlightSearchToolbar } from '@traveloka/ctvweb-ui/flight';
import { Text } from '@traveloka/web-components';

import AirlineFilter from './AirlineFilter/AirlineFilter';
import MoreFilter from './MoreFilter/MoreFilter';
import SortMenu from './SortMenu/SortMenu';
import TimeFilter from './TimeFilter/TimeFilter';
import TransitFilter from './TransitFilter/TransitFilter';

type Props = {
  style?: ViewProps['style'];
};

function FlightSearchToolbarContainer(props: Props) {
  const { CorporateFlightSearchToolbar } = useContentResource();
  const { style } = props;

  return (
    <FlightSearchToolbar style={style}>
      <View style={Style.leftFilterMenu}>
        <Text variant="ui-small">{CorporateFlightSearchToolbar.filter}</Text>
        <TransitFilter />
        <TimeFilter />
        <AirlineFilter />
        <MoreFilter />
      </View>
      <SortMenu />
    </FlightSearchToolbar>
  );
}

const Style = StyleSheet.create({
  leftFilterMenu: {
    flexDirection: 'row',
  },
});

export default FlightSearchToolbarContainer;

import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { Icon, Text, Token } from '@traveloka/web-components';
import HotelIcon from '@traveloka/icon-kit-web/svg/darkPrimary/ic_product_hotel-fill_24px.svg';

import { useHotelRequestRefund } from 'refund/request-refund/contexts/hotel/HotelRequestRefundContext';
import { formatMessage } from 'shared/utils/intl';

export default function RefundHotelDetail() {
  const [{ data }] = useHotelRequestRefund();

  const content = useContentResource().CorporateHotelRequestRefundSelectDetails;
  const { format } = useLocalizedDateFormat();

  return (
    <View style={Style.container}>
      <Icon src={HotelIcon} />
      <View style={Style.detailSection}>
        <Text variant="title-1">{data.propertyName}</Text>
        <Text style={Style.city} ink="secondary">
          {data.city}
        </Text>
        <Text style={Style.description} variant="ui-small" ink="secondary">
          {formatMessage(content.hotelDetailDateInfo, {
            date: format(new Date(data.checkInDate), 'FULL_WEEKDAY'),
            numOfNights: data.duration,
            numOfRooms: data.numOfRooms,
            roomType: data.roomName,
          })}
        </Text>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    padding: Token.spacing.l,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  detailSection: {
    marginLeft: Token.spacing.ml,
  },
  city: {
    marginTop: Token.spacing.xs,
  },
  description: {
    marginTop: Token.spacing.m,
  },
});

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useController, useFormContext } from 'react-hook-form';

import { useContentResource } from '@traveloka/ctv-core';
import { Input } from '@traveloka/ctvweb-ui';
import { Text, Token, Button, Icon } from '@traveloka/web-components';
import { Props as TextProps } from '@traveloka/web-components/src/momentum/Text/Text';

import IcSystemStatusOkDoneFill from '@traveloka/icon-kit-web/svg/greenPrimary/ic_system_status_ok_done-fill_24px.svg';
import IcSystemStatusFailFill from '@traveloka/icon-kit-web/svg/redPrimary/ic_system_status_fail-fill_12px.svg';
import IcSystemTrash from '@traveloka/icon-kit-web/svg/light/ic_system_trash_16px.svg';

import { formatMessage } from 'shared/utils/intl';

import { Document, DocumentFieldValue } from './types';

export type DocumentFieldProps = {
  name: string;
  document: Document;
  index?: number;
  helper?: string;
  label?: string;
  disabled?: boolean;
  onChange(documentType: string, file: File, index?: number): Promise<any>;
  saveFileChange?(file: DocumentFieldValue | undefined): void;

  // content
  uploadButtonCr?: string;
  retryButtonCr?: string;
};

export default function DocumentFieldSingle(props: DocumentFieldProps) {
  const cr = useContentResource().CorporateRegistration;
  const {
    name,
    document,
    index,
    helper,
    label,
    disabled,
    onChange,
    saveFileChange,

    uploadButtonCr,
    retryButtonCr,
  } = props;

  const [uploading, setUploading] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { control } = useFormContext();
  const {
    field: input,
    fieldState: { error },
    formState: { isSubmitSuccessful },
  } = useController({
    control,
    name,
    rules: {
      validate(v) {
        if (v && !isAllowedExtension(v.file, document)) {
          return formatMessage(cr.documentExtension, {
            val: document.extensions.join(', '),
          });
        } else if (v && !isBelowMaxSize(v.file, document)) {
          return formatMessage(cr.documentFileSize, {
            val: document.maxSizeInMB,
          });
        } else if (document.mandatory && !v?.url && !index) {
          return cr.documentRequired;
        }

        return;
      },
    },
  });
  const inputValue = input.value as DocumentFieldValue | undefined;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.[0]) {
      const file = {
        url: '',
        file: e.target.files[0],
        label: document.label,
        uploaded: false,
      };
      input.onChange(file);
      input.onBlur();
    }
  }

  useEffect(() => {
    if (!inputValue || inputValue.uploaded) {
      setHasUploaded(false);
      return;
    }

    const file = inputValue.file;

    if (!isBelowMaxSize(file, document) || !isAllowedExtension(file, document))
      return;

    setUploading(true);
    setHasUploaded(false);
    setUploadError(false);

    if (document.docType) {
      onChange(document.docType, file, index)
        .then(res => {
          if (res.isSuccess) {
            const file = {
              ...inputValue,
              url: res.url,
              uploaded: true,
            } as DocumentFieldValue;
            input.onChange(file);
            saveFileChange?.(file);
            setHasUploaded(true);
            return;
          }

          throw new Error();
        })
        .catch(e => {
          input.onChange(undefined);
          saveFileChange?.(undefined);
          setUploadError(true);
        })
        .finally(() => setUploading(false));
    }
  }, [inputValue?.file]);

  if (document.docType === null) {
    return null;
  }

  const isFormatOrSizeError =
    inputValue &&
    (!isAllowedExtension(inputValue.file, document) ||
      !isBelowMaxSize(inputValue.file, document));
  const errorMessage =
    (!isSubmitSuccessful || inputValue?.file) && !uploading && error?.message;
  const hasIcon = hasUploaded;

  let helperInk: TextProps['ink'] = 'secondary';
  let labelInk: TextProps['ink'] = 'secondary';
  let uploadButtonText = uploadButtonCr || 'Upload';

  if (uploadError) {
    uploadButtonText = retryButtonCr || 'Retry';
  }

  if (disabled) {
    helperInk = 'muted';
    labelInk = 'muted';
  }

  return (
    <View style={styles.container}>
      {Boolean(label) && (
        <Text style={styles.label} variant="ui-tiny" ink={labelInk}>
          {label}
        </Text>
      )}
      <View style={[styles.inputGroup, styles.row]}>
        <input
          onChange={handleChange}
          ref={inputRef}
          style={{ display: 'none' }}
          type="file"
        />
        <Input
          style={[styles.input, error && styles.error]}
          value={inputValue?.file?.name || ''}
          placeholder={document.placeholder}
          iconRight={
            hasIcon ? (
              <Icon
                src={
                  uploadError
                    ? IcSystemStatusFailFill
                    : IcSystemStatusOkDoneFill
                }
                width={12}
              />
            ) : (
              undefined
            )
          }
          disabled={disabled}
        />
        {Boolean(inputValue) && (
          <Button
            size="small"
            style={styles.buttonDelete}
            onPress={() => {
              if (inputRef.current) {
                inputRef.current.value = '';
              }

              input.onChange(undefined);
              saveFileChange?.(undefined);
            }}
            iconStart={() => (
              <Icon src={IcSystemTrash} width={16} height={16} />
            )}
            variant="destructive-primary"
          />
        )}
        <Button
          text={uploadButtonText}
          onPress={() => inputRef.current?.click()}
          onBlur={input.onBlur}
          disabled={uploading || disabled}
        />
      </View>
      {Boolean(errorMessage) && (
        <Text style={styles.helper} variant="ui-tiny" ink="alert">
          {errorMessage}
        </Text>
      )}
      {Boolean(helper) && !isFormatOrSizeError && (
        <Text style={styles.helper} variant="ui-tiny" ink={helperInk}>
          {helper}
        </Text>
      )}
    </View>
  );
}

function isBelowMaxSize(file: File, document: Document) {
  return file.size < Number(document.maxSizeInMB) * 1e6;
}

function isAllowedExtension(file: File | undefined, document: Document) {
  const extension = file?.name.split('.').pop();

  return (
    typeof extension === 'string' &&
    document.extensions.includes('.' + extension)
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginBottom: Token.spacing.xxs,
  },
  helper: {
    marginTop: Token.spacing.xxs,
  },
  inputGroup: {
    flex: 1,
  },
  input: {
    flex: 1,
    marginRight: Token.spacing.xxs,
  },
  result: {
    flex: 1,
    backgroundColor: Token.color.uiLightStain,
    borderRadius: Token.border.radius.normal,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.m,
  },
  marginStart: {
    marginStart: Token.spacing.m,
  },
  buttonDelete: {
    marginRight: Token.spacing.xxs,
    paddingVertical: Token.spacing.s,
  },
  uploading: {
    flex: 1,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    marginTop: Token.spacing.xs,
  },
  error: {
    borderColor: Token.color.uiRedPrimary,
    borderWidth: Token.border.width.thick,
    borderRadius: Token.border.radius.normal,
  },
  lineProgress: {
    marginVertical: Token.spacing.xxs,
    height: 4,
    flex: 1,
    backgroundColor: Token.color.uiLightNeutral,
    borderRadius: Token.border.radius.rounded,
  },
  lineValue: {
    textAlign: 'right',
  },
  multipleActionIcon: {
    marginLeft: Token.spacing.ml,
  },
});

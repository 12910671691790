/**
 * Source:
 * packages/nodejs-web/src/shared/utils/browser/SessionStorage.js
 */

/* eslint-env browser */
export function get(key: string) {
  try {
    if (window.sessionStorage) {
      return window.sessionStorage.getItem(key);
    }

    return null;
  } catch (error) {
    return null;
  }
}

export function remove(key: string) {
  try {
    if (window.sessionStorage) {
      return window.sessionStorage.removeItem(key);
    }

    return false;
  } catch (error) {
    return false;
  }
}

export function set(key: string, value: any) {
  try {
    if (window.sessionStorage) {
      return window.sessionStorage.setItem(key, value);
    }
    return false;
  } catch (error) {
    return false;
  }
}

const SessionStorage = { get, set, remove };
export default SessionStorage;

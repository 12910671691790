import {
  ExistingProductType,
  RequestProductType,
} from 'registration/constants/ProductType';

export enum PaymentMethod {
  INVOICE = 'INVOICE',
  CREDIT_CARD = 'CREDIT_CARD',
}

type InititalValue = {
  corporateIndustry: Nullable<string>;
  corporateName: Nullable<string>;
  country: Nullable<string>;
  documents: Nullable<RegistrationDocument[]>;
  expiryDate: Nullable<string>;
  monthlyTravelSpending: Nullable<string>;
  officeAddress: Nullable<string>;
  officeCity: Nullable<string>;
  officePhone: Nullable<string>;
  officePhoneCountryCode: Nullable<string>;
  officePostalCode: Nullable<string>;
  paymentMethod: Nullable<PaymentMethod>;
  picJobTitle: Nullable<string>;
  picName: Nullable<string>;
  picPhone: Nullable<string>;
  picPhoneCountryCode: Nullable<string>;
  productNeeded: Nullable<ExistingProductType[]>;
  productRequest: Nullable<RequestProductType[]>;
  status: Nullable<RegistrationStatus>;
  website: Nullable<string>;
  preferableScheduledMeetingFirst: Nullable<string>; // yyyy-MM-dd HH:mm:ss
  preferableScheduledMeetingSecond: Nullable<string>; // yyyy-MM-dd HH:mm:ss
  discussionTopic: Nullable<string>;
  locationPreference: Nullable<string>;
  ownerIdCard: Nullable<string>;
  ownerAddress: Nullable<string>;
  ownerAddressCity: Nullable<string>;
  ownerPostalCode: Nullable<string>;
  ownerName: Nullable<string>;
  ownerJobTitle: Nullable<string>;
  agreementDocuments: RegistrationDocument[];
  serviceFee: Nullable<string>;
  termsOfPayment: Nullable<string>;
  billingCycle: Nullable<string>;
  creditLimit: Nullable<string>;
  legalNpwpNumber: Nullable<string>;
  legalSppkpNumber: Nullable<string>;
  legalName: Nullable<string>;
  legalFiscalAddress: Nullable<string>;
  legalFiscalCity: Nullable<string>;
  legalFiscalPostalCode: Nullable<string>;
  legalBillingAddress: Nullable<string>;
  legalBillingCity: Nullable<string>;
  legalBillingPostalCode: Nullable<string>;
  documentNotes: Nullable<string>;
  creditAssessmentNotes: Nullable<string>;
  partnerCompanyName: Nullable<string>;
  partnerCompanyRelation: Nullable<string>;
  partnerCompanyPicName: Nullable<string>;
  partnerCompanyPicEmail: Nullable<string>;
  partnerCompanyPhone: Nullable<string>;
  partnerCompanyPhoneCountryCode: Nullable<string>;
  otherEmails: Nullable<string[]>;
};

export type ExpiredConfirmation = InititalValue & {
  tokenStatus: 'EXPIRED';
  picEmail: undefined;
};

export type ValidConfirmation = InititalValue & {
  tokenStatus: 'OK';
  picEmail: string;
  ndaRequested: boolean;
  meetingScheduled: boolean;
};

export enum RegistrationStatus {
  ACTIVE = 'ACTIVE',
  AGREEMENT_SENT_TO_CLIENT = 'AGREEMENT_SENT_TO_CLIENT',
  INCOMPLETE_DATA = 'INCOMPLETE_DATA',
  NDA_PROCESS = 'NDA_PROCESS', // Request NDA
  NEED_VERIFICATION = 'NEED_VERIFICATION',
  NEW_LEADS = 'NEW_LEADS',
  NEW_REFERRAL = 'NEW_REFERRAL',
  NOT_INTERESTED = 'NOT_INTERESTED',
  ONGOING_CREDIT_ASSESSMENT = 'ONGOING_CREDIT_ASSESSMENT',
  ONGOING_LEGAL_REVISION = 'ONGOING_LEGAL_REVISION',
  ONGOING_REGISTRATION = 'ONGOING_REGISTRATION',
  PRODUCT_INTRODUCTION = 'PRODUCT_INTRODUCTION', // Request Schedule Meeting
  REGISTERED = 'REGISTERED',
  REJECTED = 'REJECTED',
  REJECTED_BY_CORPORATE = 'REJECTED_BY_CORPORATE',
  REJECTED_BY_INTERNAL = 'REJECTED_BY_INTERNAL',
  REQUEST_FOR_ACTIVATION = 'REQUEST_FOR_ACTIVATION',
}

export type RegistrationDocument = {
  fileNameEncrypted: string;
  fileName: string;
  documentType: string;
  label: string;
};

export type VerificationStep =
  | 'VERIFICATION'
  | 'CREDIT_ASSESSMENT'
  | 'AGREEMENT_REVIEW'
  | 'ACCOUNT_ACTIVATION';

export type VerificationStatus = 'NOT_YET' | 'ON_GOING' | 'COMPLETED';

export type StepData = {
  step: VerificationStep;
  status: VerificationStatus;
};

export type InternalRejection =
  | 'FRAUD'
  | 'TRAVEL_AGENT'
  | 'CREDIT_ASSESSMENT'
  | 'LEGAL_REVISION'
  | 'OTHERS';

export type CorporateRejection =
  | 'SAME_PRICE_WITH_RETAIL'
  | 'NO_COUPON'
  | 'SERVICE_FEE'
  | 'NO_FINANCIAL_DOCS'
  | 'CASH_COLLATERAL'
  | 'SMALL_CREDIT_LIMIT'
  | 'TERM_OF_PAYMENT'
  | 'OTHERS';

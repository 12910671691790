import React, { useEffect, useRef, useState } from 'react';
import { findNodeHandle, StyleSheet, View } from 'react-native';

import {
  useAuth,
  useContentResource,
  useImageResource,
  useImageSlider,
  useTracker,
} from '@traveloka/ctv-core';
import {
  GenericHomeBenefits,
  GenericHomeFooter,
  GenericHomeHeader,
  GenericHomeHowToRegister,
  GenericHomeIntroduction,
  GenericHomeSolutions,
  GenericHomeTriangleMask,
  GenericHomeUseCases,
  GenericHomeVideoIntroduction,
  GenericHomeVideoModal,
} from '@traveloka/ctvweb-ui/generic';
import {
  SectionName,
  SECTION_NAMES,
} from '@traveloka/ctvweb-ui/src/playground/types';
import IcMobilePhoneFill from '@traveloka/icon-kit-web/svg/blue/ic_mobile_phone-fill_24px.svg';
import IcProductCarRentalFill from '@traveloka/icon-kit-web/svg/blue/ic_product_car_rental-fill_24px.svg';
import IcProductFlightFill from '@traveloka/icon-kit-web/svg/blue/ic_product_flight-fill_24px.svg';
import IcProductHotel from '@traveloka/icon-kit-web/svg/blue/ic_product_hotel_24px.svg';
import IcSystemCalendarReschedule from '@traveloka/icon-kit-web/svg/blue/ic_system_calendar_reschedule_24px.svg';
import { Token } from '@traveloka/web-components';
import { useLayout } from '@traveloka/web-components/future';

import LanguageMenu from 'shared/components/LanguageMenu/LanguageMenu';
import { Page } from 'shared/components/Layout';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';
import { Helmet } from 'react-helmet';

export default function Playground() {
  const content = useContentResource().CorporateHomePage;
  const image = useImageResource().CorporateHomePage;
  const slider = useImageSlider().CorporateHomePage;

  const headerRef = useRef<View>(null);
  const introductionRef = useRef<View>(null);
  const solutionRef = useRef<View>(null);
  const useCaseRef = useRef<View>(null);
  const benefitRef = useRef<View>(null);
  const howToRegisterRef = useRef<View>(null);

  const [visibleSection, setVisibleSection] = useState<SectionName | undefined>(
    undefined
  );
  const [videoModalState, setVideoModalState] = useState({
    isVideoModalVisible: false,
    youtubeEmbedSrc: '',
  });
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const [viewPort, viewPortBindings] = useLayout();

  const sectionRefMap: Record<SectionName, React.RefObject<View>> = {
    introduction: introductionRef,
    solution: solutionRef,
    useCase: useCaseRef,
    benefit: benefitRef,
    howToRegister: howToRegisterRef,
  };

  const allSections = SECTION_NAMES.map(el => {
    const sectionRef = sectionRefMap[el];
    let start = 0;
    let end = 0;

    if (sectionRef) {
      const nodeEl = findNodeHandle(sectionRef.current) as Element | null;
      if (nodeEl) {
        start = window.pageYOffset + nodeEl.getBoundingClientRect().y - 1;
        end = start + nodeEl.getBoundingClientRect().height;
      }
    }

    return {
      section: el,
      start,
      end,
    };
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [allSections, visibleSection]);

  const headerContentRes = {
    solutionText: content.solutionSectionName,
    useCaseText: content.useCaseSectionName,
    benefitText: content.benefitSectionName,
    howToRegisterText: content.howToRegisterSectionName,
    signUpButtonText: content.signUpButtonText,
    loginButtonText: content.loginButtonText,
  };

  const introductionContentRes = {
    heading: content.introductionHeading,
    paragraph: content.introductionParagraph,
    secondaryParagraph: content.introductionSecondaryParagraph,
    partnerParagraph: content.introductionPartnerParagraph,
    signUpButtonText: content.signUpButtonText,
    watchDemoButtonText: content.watchDemoButtonText,
    mainImageUrl: image.introduction.link,
    logoImageUrls: slider
      .filter(el => el.groupName === 'partnerLogo')
      .map(el => el.link),
  };

  const videoIntroductionContentRes = {
    title: content.videoIntroductionHeading,
    buttonText: content.videoIntroductionButtonText,
    videoThumbnailImageUrl: image.introductionVideoThumbnail.link,
  };

  const solutionsContentRes = {
    sectionName: content.solutionSectionName,
    heading: content.solutionHeading,
    solutions: [
      {
        title: content.solutionItemTitle01,
        description: content.solutionItemDesription01,
        imageUrl: image.solution01.link,
        isDisplayButton: true,
      },
      {
        title: content.solutionItemTitle02,
        description: content.solutionItemDesription02,
        imageUrl: image.solution02.link,
        isDisplayButton: true,
        isImageAlignLeft: true,
      },
      {
        title: content.solutionItemTitle03,
        description: content.solutionItemDesription03,
        imageUrl: image.solution03.link,
        isDisplayButton: true,
        isComingSoon: true,
      },
      {
        title: content.solutionItemTitle04,
        description: content.solutionItemDesription04,
        imageUrl: image.solution04.link,
        isImageAlignLeft: true,
        isDisplayButton: true,
        featuredImageUrls: slider
          .filter(el => el.groupName === 'paymentLogo')
          .map(el => el.link),
      },
      {
        title: content.solutionItemTitle05,
        description: content.solutionItemDesription05,
        imageUrl: image.solution05.link,
        isDisplayButton: false,
        featuredImageUrls: slider
          .filter(el => el.groupName === 'certificateLogo')
          .map(el => el.link),
        featuredImageHeight: 68,
      },
    ],
    featuredSolutions: [
      {
        iconSrcList: [IcSystemCalendarReschedule],
        title: content.featuredSolutionItemTitle01,
        description: content.featuredSolutionItemDescription01,
      },
      {
        iconSrcList: [
          IcProductFlightFill,
          IcProductHotel,
          IcProductCarRentalFill,
        ],
        title: content.featuredSolutionItemTitle02,
        description: content.featuredSolutionItemDescription02,
      },
      {
        iconSrcList: [IcMobilePhoneFill],
        title: content.featuredSolutionItemTitle03,
        description: content.featuredSolutionItemDescription03,
      },
    ],
    comingSoonBadgeText: content.comingSoonBadgeText,
    signUpButtonText: content.signUpButtonText,
    bannerText: content.solutionBannerText,
  };

  const useCaseContentRes = {
    sectionName: content.useCaseSectionName,
    heading: content.useCaseHeading,
    useCaseTitle01: content.useCaseTitle01,
    useCaseDescription01: content.useCaseDescription01,
    useCaseImageUrl01: image.usecase01.link,
    useCaseTitle02: content.useCaseTitle02,
    useCaseDescription02: content.useCaseDescription02,
    useCaseImageUrl02: image.usecase02.link,
    useCaseTitle03: content.useCaseTitle03,
    useCaseDescription03: content.useCaseDescription03,
    useCaseImageUrl03: image.usecase03.link,
    bannerText: content.useCaseBannerText,
    signUpButtonText: content.signUpButtonText,
    watchDemoButtonText: content.watchDemoButtonText,
  };

  const benefitContentRes = {
    comingSoon: content.comingSoonBadgeText,
    sectionName: content.benefitSectionName,
    heading: content.benefitHeading,
    headingParagraph: content.benefitHeadingParagraph,
    benefitListTitle: content.benefitListTitle,
    retailColumnImageUrl: image.travelokaLogo.link,
    retailColumnText: content.retailColumnText,
    retailColumnCheckIndexList: content.retailColumnCheckIndexList,
    corporateColumnImageUrl: image.travelokaCorporateLogo.link,
    corporateColumnText: content.corporateColumnText,
    corporateColumnCheckIndexList: content.corporateColumnCheckIndexList,
    benefitList: [
      { text: content.benefitItemDescription01 },
      { text: content.benefitItemDescription02 },
      { text: content.benefitItemDescription03 },
      { text: content.benefitItemDescription04, isComingSoon: true },
      { text: content.benefitItemDescription05 },
      { text: content.benefitItemDescription06 },
    ],
    bannerText: content.benefitBannerText,
    signUpButtonText: content.signUpButtonText,
  };

  const howToRegisterContentRes = {
    sectionName: content.howToRegisterSectionName,
    heading: content.howToRegisterHeading,
    stepTitle01: content.registrationStepTitle01,
    stepTitle02: content.registrationStepTitle02,
    stepTitle03: content.registrationStepTitle03,
    stepDescription01: content.registrationStepDescription01,
    stepDescription02: content.registrationStepDescription02,
    stepDescription03: content.registrationStepDescription03,
    stepImageUrl01: image.registrationStep01.link,
    stepImageUrl02: image.registrationStep02.link,
    stepImageUrl03: image.registrationStep03.link,
    requiredDocumentButtonText: content.requiredDocumentButtonText,
    statisticHeading: content.statisticHeading,
    statistics: [
      {
        title: content.statisticTitle01,
        description: content.statisticDescription01,
      },
      {
        title: content.statisticTitle02,
        description: content.statisticDescription02,
      },
      {
        title: content.statisticTitle03,
        description: content.statisticDescription03,
      },
    ],
    bannerText01: content.howToRegisterBannerText,
    bannerText02: content.statisticBannerText,
    requiredDocumentContentRes: {
      requiredDocumentHeading: content.requiredDocumentHeading,
      requiredDocumentParagraph: content.requiredDocumentParagraph,
      requiredDocumentImportantNoteParagraph:
        content.requiredDocumentImportantNoteParagraph,
      requiredDocumentList: [
        content.requiredDocument01,
        content.requiredDocument02,
        content.requiredDocument03,
        content.requiredDocument04,
      ],
      closeButtonText: content.closeButtonText,
      signUpButtonText: content.signUpButtonText,
    },
    signUpButtonText: content.signUpButtonText,
    watchDemoButtonText: content.watchDemoButtonText,
  };

  const youtubeSrc = content.youtubeEmbedSrc + '?start=01'; // workaround to always start video from second 01 everytime modal is called.

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 65;
    const selected = allSections.find(
      el => scrollPosition >= el.start && scrollPosition < el.end
    );

    // sticky header
    if (scrollPosition === 65 && isHeaderSticky) {
      setIsHeaderSticky(false);
    } else if (scrollPosition > 65) {
      setIsHeaderSticky(true);
    }

    if (selected && selected.section !== visibleSection) {
      setVisibleSection(selected.section);
    }
  };

  function handleOnPressNavigation(section: SectionName) {
    const sectionRef = sectionRefMap[section];

    if (sectionRef) {
      const el = findNodeHandle(sectionRef.current) as Element | null;
      if (el) {
        const nodeYPosition = el.getBoundingClientRect().y;
        const nodeLocation = window.pageYOffset + nodeYPosition;

        // header height is 65
        window.scrollTo({ top: nodeLocation - 65, behavior: 'smooth' });
      }
    }
  }

  const track = useTracker('landing-page');
  const auth = useAuth();
  const history = useLocalizedHistory();

  function sendTracking(buttonName: string) {
    return track('corpB2b.landingPage.beforeLogin', {
      eventName: 'corpB2b.LandingPage',
      eventVersion: '1.0.0',
      pageName: 'HOME_PAGE',
      pageCategory: 'LANDING_PAGE',
      pageReferrer: 'HOME_PAGE',
      buttonName: buttonName.toUpperCase(),
      pageTitle: buttonName,
    });
  }

  async function handleOnPressSignUp() {
    await sendTracking('REGISTER').send();

    history.push('/register');
  }

  async function handleOnPressLogin() {
    await sendTracking('LOGIN').send();

    auth.login();
  }

  function handleOnToggleVideoModal() {
    setVideoModalState({
      isVideoModalVisible: !videoModalState.isVideoModalVisible,
      youtubeEmbedSrc: videoModalState.youtubeEmbedSrc === '' ? youtubeSrc : '', // workaround to make video stop when modal is closed
    });
  }

  return (
    <Page light>
      <Helmet>
        <meta name="description" content={content.metaDescription} />
        <title>{content.title}</title>
      </Helmet>
      <View {...viewPortBindings}>
        <View
          ref={headerRef}
          style={[Style.header, isHeaderSticky && Style.stickyHeader]}
        >
          <GenericHomeHeader
            content={headerContentRes}
            activeNav={visibleSection}
            onPressNavigation={handleOnPressNavigation}
            onPressLogin={() => handleOnPressLogin()}
            onPressSignUp={() => handleOnPressSignUp()}
            languageMenu={<LanguageMenu />}
          />
        </View>
        <View ref={introductionRef}>
          <GenericHomeIntroduction
            content={introductionContentRes}
            onPressSignUp={() => handleOnPressSignUp()}
            onPressWatchVideo={() => handleOnToggleVideoModal()}
          />
          <GenericHomeVideoIntroduction
            content={videoIntroductionContentRes}
            onPressWatchVideo={() => handleOnToggleVideoModal()}
          />
        </View>
        <View ref={solutionRef} style={Style.solutionSection}>
          <GenericHomeTriangleMask
            width={viewPort.width}
            style={Style.triangleMask}
          />
          <GenericHomeSolutions
            content={solutionsContentRes}
            onPressSignUp={() => handleOnPressSignUp()}
          />
        </View>

        <View style={Style.useCasesAndBenefitsSection}>
          <GenericHomeTriangleMask
            width={viewPort.width}
            style={Style.triangleMask}
          />
          <View ref={useCaseRef}>
            <GenericHomeUseCases
              content={useCaseContentRes}
              onPressSignUp={() => handleOnPressSignUp()}
              onPressWatchVideo={() => handleOnToggleVideoModal()}
            />
          </View>
          <View ref={benefitRef}>
            <GenericHomeBenefits
              content={benefitContentRes}
              onPressSignUp={() => handleOnPressSignUp()}
            />
          </View>
        </View>
        <View ref={howToRegisterRef}>
          <GenericHomeHowToRegister
            content={howToRegisterContentRes}
            onPressSignUp={() => handleOnPressSignUp()}
            onPressWatchVideo={() => handleOnToggleVideoModal()}
          />
        </View>

        <GenericHomeFooter />

        <GenericHomeVideoModal
          isVisible={videoModalState.isVideoModalVisible}
          youtubeEmbedSrc={videoModalState.youtubeEmbedSrc}
          onCloseModal={() => handleOnToggleVideoModal()}
        />
      </View>
    </Page>
  );
}

const Style = StyleSheet.create({
  header: {
    zIndex: 999,
  },
  stickyHeader: {
    // @ts-ignore
    position: 'sticky',
    top: '0',
    left: '0',
    shadowColor: Token.color.uiDarkPrimary,
    shadowRadius: Token.border.radius.normal,
    shadowOffset: { width: 0, height: -2 },
    shadowOpacity: 0.2,
  },
  triangleMask: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  solutionSection: {
    position: 'relative',
    // @ts-ignore,
    backgroundImage: `linear-gradient(360deg, #F3F4F7 15%, ${Token.opacity.clear(
      Token.color.uiLightPrimary
    )} 100%)`,
  },
  useCasesAndBenefitsSection: {
    // @ts-ignore,
    backgroundImage: `linear-gradient(0deg, #F3F4F7  30%, ${Token.opacity.clear(
      Token.color.uiLightPrimary
    )} 100%)`,
  },
});

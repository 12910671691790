import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';

import {
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { Divider, Fade } from '@traveloka/ctvweb-ui';
import { SearchHeader } from '@traveloka/ctvweb-ui/hotel';
import { Card, Token } from '@traveloka/web-components';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import { useProductRequest } from 'approval-system/shared/contexts/ProductRequestContext';
import { useResult } from 'hotel/search/contexts/ResultContext';
import { useSortFilter } from 'hotel/search/contexts/SortFilterContext';
import { useSearchSpec } from 'hotel/shared/contexts/SpecContext';
import { formatMessage } from 'shared/utils/intl';

import HotelSearchForm from '../HotelSearchForm/HotelSearchForm';

export default function Header() {
  const {
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();
  const searchSpec = useSearchSpec();
  const { isLoading } = useResult();
  const [, dispatch] = useSortFilter();
  const [isFormExpanded, setFormExpanded] = useState(false);

  const { format } = useLocalizedDateFormat();
  const productRequest = useProductRequest();
  const content = useContentResource().CorporateHotelSearchHeader;

  const handleToggleForm = useCallback(() => {
    setFormExpanded(s => !s);
  }, [setFormExpanded]);

  const headerSubtitle = [
    format(searchSpec.checkInDate, 'FULL_WEEKDAY_MONTH'),
    format(searchSpec.checkOutDate, 'FULL_WEEKDAY_MONTH'),
  ].join(' - ');
  const pax = formatMessage(content.paxInfoText, {
    num:
      searchSpec.travelers.length +
      searchSpec.nonEmployeeTravelers.adults +
      searchSpec.nonEmployeeTravelers.childrenAges.length,
  });
  const room = formatMessage(content.roomInfoText, { num: searchSpec.rooms });

  const handleSearch = useCallback(() => {
    setFormExpanded(false);
    dispatch({ type: 'reset' });
  }, [setFormExpanded, dispatch]);

  return (
    <>
      <SearchHeader
        title={formatMessage(content.searchHeaderTitle, {
          geoName: searchSpec.geoName ?? '',
        })}
        subtitle={headerSubtitle}
        pax={pax}
        room={room}
        onPress={handleToggleForm}
        button={content.changeSearchButtonText}
        style={Style.header}
      />

      <Fade visible={isFormExpanded} style={Style.form}>
        <Card elevation="raised" style={Style.card}>
          <HotelSearchForm
            onSearch={() => fetchAccountStatus(handleSearch)}
            isSearching={isAccountStatusFetching || isLoading}
            funnelSource="SEARCH_RESULT_SF"
            tripRequestEnabled={productRequest.enabled && !!productRequest.data}
          />
        </Card>
      </Fade>
      <Divider margin="bottom" spacing="ml" />
    </>
  );
}

const Style = StyleSheet.create({
  header: {
    marginBottom: Token.spacing.ml,
  },
  form: {
    zIndex: 2,
  },
  card: {
    marginBottom: Token.spacing.ml,
    overflow: 'visible',
  },
});

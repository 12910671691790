import { useApi, useFeatureControl } from '@traveloka/ctv-core';
import { useQuery } from '@tanstack/react-query';
import {
  RecentSearchHotelGeoRequest,
  RecentSearchHotelGeoResponse,
  RECENT_SEARCH_HOTEL_GEO_API,
} from 'recent-search/api';

function useRecentSearchGeo() {
  const recentSearchFC = useFeatureControl('b2b-recent-search');
  const fetcher = useApi<
    RecentSearchHotelGeoResponse,
    RecentSearchHotelGeoRequest
  >({
    domain: 'search',
    method: 'post',
    path: RECENT_SEARCH_HOTEL_GEO_API,
  });

  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    ['recent-search-geo'],
    fetcher,
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: recentSearchFC.enabled,
    }
  );

  return {
    isLoading,
    isSuccess,
    isError: data?.success === false || isError,
    data: data?.success ? data.data : null,
    refetch,
  };
}

export default useRecentSearchGeo;

import React, { ReactNode } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Button, Token, Text, Icon } from '@traveloka/web-components';
import {
  GenericHomeContent_v2,
  GenericHomeButton,
} from '@traveloka/ctvweb-ui/generic';
import { SectionName } from '@traveloka/ctvweb-ui/src/playground/types';

type Props = {
  content: {
    solutionText: string;
    useCaseText: string;
    benefitText: string;
    howToRegisterText: string;
    signUpButtonText: string;
    loginButtonText: string;
  };
  activeNav?: SectionName;
  onPressNavigation: (e: SectionName) => void;
  onPressLogin(): void;
  onPressSignUp(): void;
  languageMenu: ReactNode;
};

export default function GenericHomeHeader(props: Props) {
  const {
    content,
    activeNav,
    onPressNavigation,
    onPressLogin,
    onPressSignUp,
    languageMenu,
  } = props;

  return (
    <GenericHomeContent_v2 style={Style.headerContainer}>
      <View style={[Style.flexBox, Style.header]}>
        <View style={Style.flexBox}>
          <TouchableOpacity onPress={() => onPressNavigation('introduction')}>
            <Icon
              src={'/img/traveloka-for-corporates-compact.png'}
              height={28}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onPressNavigation('solution')}>
            <Text
              variant="ui-small"
              style={[Style.nav, activeNav === 'solution' && Style.navActive]}
            >
              {content.solutionText}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onPressNavigation('useCase')}>
            <Text
              variant="ui-small"
              style={[Style.nav, activeNav === 'useCase' && Style.navActive]}
            >
              {content.useCaseText}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onPressNavigation('benefit')}>
            <Text
              variant="ui-small"
              style={[Style.nav, activeNav === 'benefit' && Style.navActive]}
            >
              {content.benefitText}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onPressNavigation('howToRegister')}>
            <Text
              variant="ui-small"
              style={[
                Style.nav,
                activeNav === 'howToRegister' && Style.navActive,
              ]}
            >
              {content.howToRegisterText}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={Style.flexBox}>
          {languageMenu}
          <Button
            variant="secondary"
            text={content.loginButtonText}
            style={Style.loginButton}
            onPress={onPressLogin}
          />
          <GenericHomeButton
            text={content.signUpButtonText}
            onPress={onPressSignUp}
          />
        </View>
      </View>
    </GenericHomeContent_v2>
  );
}

const Style = StyleSheet.create({
  headerContainer: {
    backgroundColor: Token.color.uiLightPrimary,
  },
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  header: {
    justifyContent: 'space-between',
    height: 65,
  },
  nav: {
    marginLeft: Token.spacing.xl,
  },
  navActive: {
    color: Token.color.uiBlueSecondary,
  },
  loginButton: {
    marginHorizontal: Token.spacing.m,
  },
});

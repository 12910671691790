import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import RedirectHome from 'generic/RedirectHome';
import ProductRequestRefund from 'refund/request-refund/ProductRequestRefund';

type Props = RouteComponentProps;

function RefundApp(props: Props) {
  const { match } = props;

  return (
    <Switch>
      <Route
        path={`${match.path}/request`}
        exact
        component={ProductRequestRefund}
      />
      <Route component={RedirectHome} />
    </Switch>
  );
}

export default RefundApp;

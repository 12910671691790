import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useController, useFormContext } from 'react-hook-form';

import IcSystemButtonSubtract from '@traveloka/icon-kit-web/svg/darkBlue/ic_system_button_subtract_24px.svg';
import IcSystemButtonSubtractMuted from '@traveloka/icon-kit-web/svg/lightSecondary/ic_system_button_subtract_24px.svg';
import { Icon, Token } from '@traveloka/web-components';

import DocumentFieldSingle, { DocumentFieldProps } from './DocumentFieldSingle';
import { DocumentFieldValue } from './types';

export type DocumentFieldArrayProps = DocumentFieldProps & {
  maxItem?: number;
};

export default function DocumentFieldArray(props: DocumentFieldArrayProps) {
  const { control, watch } = useFormContext();
  const {
    field: { onChange },
  } = useController({
    control,
    name: props.name,
  });
  const parsedValue = watch(props.name) as DocumentFieldValue[];

  const [fieldLength, setFieldLength] = useState(
    getFieldLength(parsedValue?.length)
  );

  useEffect(() => {
    // To normalize parsedValue, if parsedValue is an object, then wrap with Array.
    if (parsedValue && !Array.isArray(parsedValue)) {
      const normalizedValue = [parsedValue];
      onChange(normalizedValue);
      setFieldLength(getFieldLength(normalizedValue.length));
    }
  }, []);

  return (
    <>
      {Array.from({ length: fieldLength }).map((_, index) => {
        const file = parsedValue?.[index];
        const disabled = index === fieldLength - 1;

        return (
          <View key={file?.url} style={styles.container}>
            <DocumentFieldSingle
              {...props}
              index={index}
              name={`${props.name}.${index}`}
              saveFileChange={file => {
                if (props.maxItem && props.maxItem <= fieldLength) {
                  return;
                }
                if (
                  file?.label &&
                  parsedValue!.filter(Boolean).length === fieldLength
                ) {
                  setFieldLength(prev => prev + 1);
                }
              }}
            />
            <TouchableOpacity
              style={styles.actionIcon}
              onPress={() => {
                onChange(
                  parsedValue?.filter(
                    (_, removedIndex) => removedIndex !== index
                  )
                );
                setFieldLength(prev => prev - 1);
              }}
              disabled={disabled}
            >
              <Icon
                src={
                  disabled
                    ? IcSystemButtonSubtractMuted
                    : IcSystemButtonSubtract
                }
                width={24}
              />
            </TouchableOpacity>
          </View>
        );
      })}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: Token.spacing.xxs,
  },
  actionIcon: {
    marginLeft: Token.spacing.m,
    marginTop: Token.spacing.xs,
  },
});

function getFieldLength(itemLength?: number, maxItem?: number) {
  const fieldLength = (itemLength || 0) + 1;

  if (maxItem === undefined) {
    return fieldLength;
  }

  return Math.max(fieldLength, maxItem);
}

import React from 'react';

import ProductType from 'approval-system/shared/constants/ProductType';
import { ProductRequestProvider } from 'approval-system/shared/contexts/ProductRequestContext';
import CovidBanner from 'shared/components/CovidBanner';
import { Content, Footer, Header, Page } from 'shared/components/Layout';

import FlightSearchContent from './components/FlightSearchContent/FlightSearchContent';
import {
  SearchSpecProvider,
  useSearchSpec,
} from './contexts/SearchSpecContext';
import StaticDataProvider from './contexts/StaticDataContext';

export default function FlightSearch() {
  return (
    <StaticDataProvider>
      <SearchSpecProvider>
        <SearchContent />
      </SearchSpecProvider>
    </StaticDataProvider>
  );
}

function SearchContent() {
  const { tripRequestId, passengers } = useSearchSpec();

  return (
    <ProductRequestProvider
      tripRequestId={tripRequestId}
      type={ProductType.FLIGHT}
      mainBooker={passengers[0]}
    >
      <Page light>
        <Header additionalInfoHeader={<CovidBanner />} />
        <Content>
          <FlightSearchContent />
        </Content>
        <Footer />
      </Page>
    </ProductRequestProvider>
  );
}

// Copied from packages/web/src/shared/utils/validator.ts
// Will slowly move all the validator here

export function regex(value: unknown, regex: RegExp) {
  return regex.test(String(value));
}

export function isEmail(value: unknown, ignoreLastSpace = false) {
  if (ignoreLastSpace) {
    return regex(
      value,
      /^[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z0-9-]{2,63})\s*$/
    );
  }

  return regex(
    value,
    /^[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z0-9-]{2,63})$/
  );
}

import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { ComplianceLabel, Divider } from '@traveloka/ctvweb-ui';
import { IconLabel, SafeImage, StarRating } from '@traveloka/ctvweb-ui/hotel';
import { Card, Text, Token } from '@traveloka/web-components';

import { usePrebook } from 'hotel/prebook/contexts/PrebookProvider';
import { formatMessage } from 'shared/utils/intl';

export default function ProductSummary() {
  const { room, spec } = usePrebook();

  const content = useContentResource().CorporateHotelPrebookProductSummary;
  const { format } = useLocalizedDateFormat();

  const cancelation = room.isFreeCancelation
    ? content.freeCancellationText
    : content.hasCancellationPolicyText;

  const durationLabel = formatMessage(content.durationContent, {
    num: spec.duration,
  });
  const roomLabel = formatMessage(content.roomContent, {
    num: spec.numOfRooms,
  });
  const guestLabel = formatMessage(content.guestContent, {
    num: room.roomOccupancy,
  });

  const checkInLabel = [
    format(spec.checkInDate, 'SHORT_WEEKDAY'),
    room.checkInTime,
  ]
    .filter(Boolean)
    .join('\n');
  const checkOutLabel = [
    format(spec.checkOutDate, 'SHORT_WEEKDAY'),
    room.checkOutTime,
  ]
    .filter(Boolean)
    .join('\n');

  return (
    <Card>
      <View style={Style.content}>
        <View style={Style.image}>
          <SafeImage src={room.propertyImage} width={80} height={80} />
          <View style={Style.right}>
            <Text style={Style.name}>{room.propertyName}</Text>
            <StarRating star={Number(room.propertyStarRating)} />
          </View>
        </View>
        <View style={Style.firstIconRow}>
          {room.isBreakfastIncluded && (
            <IconLabel
              isAvailable
              variant="ui-tiny"
              type="breakfast"
              text={content.freeBreakfastText}
              style={Style.firstIcon}
            />
          )}
          {room.isWifiIncluded && (
            <IconLabel
              isAvailable
              variant="ui-tiny"
              type="wifi"
              text={content.freeWifiText}
              style={Style.firstIcon}
            />
          )}
        </View>
        {room.isRefundable && (
          <IconLabel
            isAvailable
            variant="ui-tiny"
            type="cancelation"
            text={cancelation}
            style={Style.iconLabel}
          />
        )}
        {!room.isComplying && (
          <ComplianceLabel text={content.nonCompliantText} variant="rounded" />
        )}
      </View>
      <Divider margin="none" subtle />
      <View style={Style.group}>
        <Label label={content.durationText} value={durationLabel} />
        <Label label={content.checkInText} value={checkInLabel} />
        <Label label={content.checkOutText} value={checkOutLabel} />
      </View>
      <Divider margin="none" subtle />
      <View style={Style.group}>
        {isValidString(room.roomTypeName) && (
          <Label label={content.roomTypeText} value={room.roomTypeName} />
        )}
        <Label label={content.roomNameText} value={room.roomName} />
        <Label label={content.numOfRoomsText} value={roomLabel} />
        <Label label={content.guestPerRoomText} value={guestLabel} />
        <Label label={content.bedTypeText} value={room.bedType} />
      </View>
    </Card>
  );
}

function isValidString(text: string | null): text is string {
  return typeof text === 'string' && text !== '';
}

type LabelProps = {
  label: string;
  value: string;
};

function Label(props: LabelProps) {
  const { label, value } = props;

  return (
    <>
      <Text variant="ui-tiny" style={Style.groupLeft}>
        {label}
      </Text>
      <Text variant="ui-tiny" style={Style.groupRight}>
        {value}
      </Text>
    </>
  );
}

const Style = StyleSheet.create({
  image: {
    flexDirection: 'row',
    marginBottom: Token.spacing.m,
  },
  content: {
    padding: Token.spacing.m,
  },
  right: {
    flex: 1,
    marginLeft: Token.spacing.m,
  },
  name: {
    marginBottom: Token.spacing.xxs,
  },
  group: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.s,
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  groupLeft: {
    flex: 1,
    flexBasis: '50%',
    fontWeight: Token.typography.weightMedium.fontWeight,
    paddingRight: Token.spacing.xxs,
    marginVertical: Token.spacing.xxs,
  },
  groupRight: {
    flex: 1,
    flexBasis: '50%',
    textAlign: 'right',
    paddingLeft: Token.spacing.xxs,
    marginVertical: Token.spacing.xxs,
  },
  firstIconRow: {
    flexDirection: 'row',
  },
  firstIcon: {
    marginBottom: Token.spacing.xs,
    flex: 1,
  },
  iconLabel: {
    marginBottom: Token.spacing.xs,
  },
});

import React from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';

import {
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { Popup, Text, Token, Button, Card } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';
import { Divider } from '@traveloka/ctvweb-ui';
import { FlightSegment } from '@traveloka/ctvweb-ui/refund';

import { ONE_WAY_SEPARATOR } from 'flight/search/constants/flight-search-constant';
import { useFlightRequestRefund } from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';
import { useFlightRequestRefundForm } from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import { useProductRequestRefundPage } from 'refund/request-refund/contexts/ProductRequestRefundPageContext';
import { RefundJourney } from 'refund/shared/api/types';

const DOT = '•';

type Props = {
  isVisible: boolean;
};

export default function PartiallyRefundModal(props: Props) {
  const { isVisible } = props;

  const [_, { goToBookingDetail }] = useProductRequestRefundPage();
  const [{ data, refundedJourneys }] = useFlightRequestRefund();
  const { flightCheckboxes } = useFlightRequestRefundForm();

  const content = useContentResource().CorporateFlightRequestRefundReview;

  const selectedJourneys = flightCheckboxes
    .filter(checkbox => checkbox.checkboxValue)
    .map((_, checkboxIndex) => data.flightRefundData.journeys[checkboxIndex]);
  const successfullyRefunedJourneys = selectedJourneys.filter(journey =>
    refundedJourneys.includes(journey.journeyId)
  );
  const failedRefundedJourneys = selectedJourneys.filter(
    journey => !refundedJourneys.includes(journey.journeyId)
  );

  return (
    <Modal isVisible={isVisible}>
      <Popup showCloseButton={false} width={614} height={540}>
        <View>
          <Text variant="headline" style={Style.title}>
            {content.partiallyRefundTitle}
          </Text>
          {!!successfullyRefunedJourneys.length && (
            <RefundedFlightSegment
              label={content.successRefundedTitle}
              refundedJourneys={successfullyRefunedJourneys}
            />
          )}
          {!!failedRefundedJourneys.length && (
            <RefundedFlightSegment
              style={Style.failedRefundedContainer}
              label={content.failedRefundedTitle}
              refundedJourneys={failedRefundedJourneys}
            />
          )}

          <Button
            style={Style.back}
            text={content.partialRefundRedirectButtonText}
            onPress={goToBookingDetail}
          />
        </View>
      </Popup>
    </Modal>
  );
}

type RefundedFlightSegmentProps = {
  label: string;
  refundedJourneys: RefundJourney[];
  style?: StyleProp<ViewStyle>;
};

function RefundedFlightSegment(props: RefundedFlightSegmentProps) {
  const { label, refundedJourneys, style } = props;

  const { format } = useLocalizedDateFormat();

  return (
    <View style={style}>
      <Text variant="ui-baseline">{label}</Text>
      <Card style={Style.journeyCard}>
        {refundedJourneys.map((journey, journeyIndex) => {
          return journey.segments.map((segment, segmentIndex) => {
            const isFirstRow = journeyIndex + segmentIndex === 0;
            const isFirstSegment = segmentIndex === 0;

            const key = `${journeyIndex}-${segmentIndex}`;
            return (
              <React.Fragment key={key}>
                {!isFirstRow && (
                  <Divider
                    margin={!isFirstSegment ? 'left' : 'none'}
                    spacing="m"
                  />
                )}
                <FlightSegment
                  airlineInfo={`${segment.airline.airlineName} ${DOT} ${segment.flightCode}`}
                  flightDatetime={`${format(
                    new Date(segment.departureDetail.departureDate),
                    'FULL_WEEKDAY'
                  )} ${DOT} ${segment.departureDetail.departureTime}`}
                  flightRoute={`${segment.departureDetail.city} (${segment.departureDetail.airportCode}) ${ONE_WAY_SEPARATOR} ${segment.arrivalDetail.city} (${segment.arrivalDetail.airportCode})`}
                />
              </React.Fragment>
            );
          });
        })}
      </Card>
    </View>
  );
}

const Style = StyleSheet.create({
  title: {
    marginBottom: Token.spacing.m,
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  content: {
    marginTop: Token.spacing.m,
    marginHorizontal: Token.spacing.l,
  },
  journeyCard: {
    borderWidth: Token.border.width.thick,
    borderColor: Token.color.uiLightSecondary,
  },
  failedRefundedContainer: {
    marginTop: Token.spacing.m,
  },
  back: {
    marginTop: Token.spacing.xxxl,
    marginHorizontal: 'auto',
    width: 230,
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Text, Token } from '@traveloka/web-components';

import {
  BookingDetailPassengerDetail,
  NonEmployeeType,
} from 'flight/shared/api/types';
import { usePaymentStateFlight } from 'payment/select/contexts/PaymentContext';

export default function TravelerDetail() {
  const { booking } = usePaymentStateFlight();
  const { adults, children, infants } = booking.flightBookingDetail.passengers;

  return (
    <View style={Style.travelerDetails}>
      <Detail data={adults} startIndex={0} type={NonEmployeeType.ADULT} />
      <Detail
        data={children}
        startIndex={adults.length}
        type={NonEmployeeType.CHILD}
      />
      <Detail
        data={infants}
        startIndex={adults.length + children.length}
        type={NonEmployeeType.INFANT}
      />
    </View>
  );
}

type DetailProps = {
  data: BookingDetailPassengerDetail[];
  startIndex: number;
  type: NonEmployeeType;
};
function Detail(props: DetailProps) {
  const { data, startIndex, type } = props;

  const content = useContentResource().CorporateFlightPaymentDetailModal;
  const typeMap = useContentResource().CorporateEnumNonEmployeeType;
  const titleMap = useContentResource().CorporateEnumTravelerTitle;
  const ageMap = {
    ADULT: '',
    CHILD: ` (${content.childAgeText})`,
    INFANT: ` (${content.infantAgeText})`,
  };

  return (
    <>
      {data.map(({ title, firstName, lastName, documentDetail }, index) => (
        <View
          key={index}
          style={[
            (startIndex + index) % 2 === 0
              ? Style.evenIndexContainer
              : Style.oddIndexContainer,
            Style.travelerItemContainer,
          ]}
        >
          <View>
            <Text variant="ui-small">{`${titleMap[title]} ${firstName} ${lastName}`}</Text>
            <Text variant="ui-small" ink="secondary">
              {typeMap[type]}
              {ageMap[type]}
            </Text>
          </View>
          {!!documentDetail && !!documentDetail.documentNo && (
            <>
              <View style={Style.documentDetailTitle}>
                <Text variant="ui-tiny" ink="secondary">
                  {content.idCardText}
                </Text>
              </View>
              <Text variant="ui-small">{`${documentDetail.documentType} - ${documentDetail.documentNo}`}</Text>
            </>
          )}
        </View>
      ))}
    </>
  );
}

const Style = StyleSheet.create({
  travelerDetails: {
    marginHorizontal: -Token.spacing.m,
  },
  travelerItemContainer: {
    padding: Token.spacing.m,
  },
  evenIndexContainer: {
    backgroundColor: Token.color.uiLightStain,
  },
  oddIndexContainer: {
    backgroundColor: Token.color.uiLightNeutral,
  },
  documentDetailTitle: {
    marginTop: Token.spacing.m,
    marginBottom: Token.spacing.xs,
  },
});

import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import StarFull12 from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_reaction_star-fill_12px.svg';
import StarFull16 from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_reaction_star-fill_16px.svg';
import StarFull24 from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_reaction_star-fill_24px.svg';
import StarHalf12 from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_reaction_star_half_left_fill_12px.svg';
import StarHalf16 from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_reaction_star_half_left_fill_16px.svg';
import StarHalf24 from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_reaction_star_half_left_fill_24px.svg';
import { Icon } from '@traveloka/web-components';

type Size = 'small' | 'medium' | 'large';

type Props = {
  size?: Size;
  star: number;
  style?: StyleProp<ViewStyle>;
};

const iconMap: Record<Size, [string, string, number]> = {
  small: [StarFull12, StarHalf12, 12],
  medium: [StarFull16, StarHalf16, 16],
  large: [StarFull24, StarHalf24, 24],
};

export default function StarRating(props: Props) {
  const { size = 'medium', star, style } = props;

  const hasHalfStar = star % 1 > 0;

  const [full, half, wh] = iconMap[size];

  return (
    <View style={[Style.star, style]}>
      {Array.from({ length: star }, (_, index) => (
        <Icon key={index} src={full} width={wh} height={wh} />
      ))}
      {hasHalfStar && <Icon src={half} width={wh} height={wh} />}
    </View>
  );
}

const Style = StyleSheet.create({
  star: {
    flexDirection: 'row',
  },
});

import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Text, Token, Image, Icon } from '@traveloka/web-components';
import { useHoverable } from '@traveloka/web-components/future';
import { GenericHomeContent_v2 } from '@traveloka/ctvweb-ui/generic';
import IcSystemPlayMediaFill from '@traveloka/icon-kit-web/svg/light/ic_system_play_media-fill_24px.svg';

type Props = {
  content: {
    title: string;
    buttonText: string;
    videoThumbnailImageUrl: string;
  };
  onPressWatchVideo(): void;
};

export default function GenericHomeVideoIntroduction(props: Props) {
  const { content, onPressWatchVideo } = props;
  const [isHovered, hoverEvent] = useHoverable();

  return (
    <View style={Style.container}>
      <Image
        src={content.videoThumbnailImageUrl}
        style={Style.image}
        objectFit="cover"
      />
      <View style={Style.overlay}>
        <GenericHomeContent_v2 spacing="xxxxl">
          <Text ink="white" variant="display">
            {content.title}
          </Text>
          <TouchableOpacity
            {...hoverEvent}
            onPress={onPressWatchVideo}
            style={[Style.playButton, isHovered && Style.playButtonHovered]}
          >
            <View style={Style.playButtonIcon}>
              <Icon src={IcSystemPlayMediaFill} width={24} height={24} />
            </View>
            <Text ink="white">{content.buttonText}</Text>
          </TouchableOpacity>
        </GenericHomeContent_v2>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    position: 'relative',
  },
  image: {
    height: '100%',
    maxHeight: 700,
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  playButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Token.spacing.xl,
  },
  playButtonHovered: {
    opacity: 0.8,
  },
  playButtonIcon: {
    width: 56,
    height: 56,
    borderRadius: 28,
    borderWidth: 1,
    borderColor: Token.color.uiLightPrimary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: Token.spacing.l,
  },
});

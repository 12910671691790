import {
  addDays,
  format,
  isBefore,
  isValid,
  parse as parseDate,
  startOfDay,
} from 'date-fns';
import { parse, stringify } from 'qs';

import { FlightNonEmployeeTraveler } from 'flight/shared/api/types';
import SeatClass from 'flight/shared/constants/seat-class';
import { DATE } from 'shared/utils/date';
import { getDefaultFlightRoute } from 'shared/utils/route';

import {
  AVAILABLE_SEAT_CLASS,
  DEFAULT_ROUTE,
  DEFAULT_SEAT_CLASS,
  DEPARTURE_DATE_OFFSET,
  RETURN_DATE_OFFSET,
} from '../constants/flight-search-constant';
import { ComputedSearchSpec } from '../contexts/SearchSpecContext';
import { AirportMap, AreaAirportsMap } from '../types';

type RawSearchSpec = {
  ap?: string;
  dt?: string;
  sc?: string;
  tr?: string;
  et?: string;
  a?: string;
  c?: string;
  i?: string;
};

export type SearchSpec = {
  passengers: string[];
  nonEmployeeTravelers: FlightNonEmployeeTraveler;
  airport: {
    origin: string;
    destination: string;
  };
  isRoundTrip: boolean;
  isInternational: boolean;
  date: {
    depart: Date;
    return: Date;
  };
  seatClass: SeatClass;
  tripRequestId?: string;
};

export function parseSearchSpec(
  qs: string,
  airportMap: AirportMap,
  areaAirportsMap: AreaAirportsMap
): SearchSpec {
  const query: RawSearchSpec = parse(qs, { ignoreQueryPrefix: true });

  const [isRoundTrip, date] = getDate(query.dt);

  const airport = getAirport(query.ap);

  return {
    passengers: getPassengers(query.et),
    nonEmployeeTravelers: getNonEmployeeTravelers(query.a, query.c, query.i),
    airport,
    isRoundTrip,
    isInternational: isInternational(
      airport.origin,
      airport.destination,
      airportMap,
      areaAirportsMap
    ),
    date,
    seatClass: getSeatClass(query.sc),
    tripRequestId: query.tr,
  };
}

export function stringifySearchSpec(obj: ComputedSearchSpec): string {
  const dates = obj.isRoundTrip
    ? [obj.date.depart, obj.date.return]
    : [obj.date.depart];

  const query: RawSearchSpec = {
    ap: [obj.airport.origin, obj.airport.destination].join('.'),
    dt: dates.map(date => format(date, DATE)).join('.'),
    sc: obj.seatClass,
    tr: obj.tripRequestId,
    et: obj.passengers.map(p => p.employeeId).join('.'),
    a: obj.nonEmployeeTravelers.adults.toString(),
    c: obj.nonEmployeeTravelers.children.toString(),
    i: obj.nonEmployeeTravelers.infants.toString(),
  };

  return stringify(query, { addQueryPrefix: true });
}

function getPassengers(et: string | undefined): SearchSpec['passengers'] {
  if (!et) {
    return [];
  }

  // Filter Unique
  return et
    .split('.')
    .filter((value, index, array) => array.indexOf(value) === index);
}

function getNonEmployeeTravelers(
  a: string | undefined,
  c: string | undefined,
  i: string | undefined
): SearchSpec['nonEmployeeTravelers'] {
  return {
    adults: checkNumber(a),
    children: checkNumber(c),
    infants: checkNumber(i),
  };
}

function checkNumber(num: string | undefined): number {
  const number = Number(num);
  return isNaN(number) ? 0 : number;
}

function getAirport(ap: string | undefined): SearchSpec['airport'] {
  const [defaultFrom, defaultTo] = getDefaultFlightRoute(DEFAULT_ROUTE).split(
    '.'
  );

  if (!ap) {
    return {
      origin: defaultFrom,
      destination: defaultTo,
    };
  }

  const [origin = defaultFrom, destination = defaultTo] = ap.split('.');

  return {
    origin: origin.toUpperCase(),
    destination: destination.toUpperCase(),
  };
}

function getDate(dt: string | undefined): [boolean, SearchSpec['date']] {
  const today = startOfDay(new Date());

  if (!dt) {
    const tomorrow = addDays(today, DEPARTURE_DATE_OFFSET);
    const threeDaysAfterTomorrow = addDays(tomorrow, RETURN_DATE_OFFSET);

    return [
      false,
      {
        depart: tomorrow,
        return: threeDaysAfterTomorrow,
      },
    ];
  }

  const [departDate, returnDate] = dt.split('.') as [string, string?];
  const departDateOrToday = normalizeDate(departDate, today);

  return [
    Boolean(returnDate),
    {
      depart: departDateOrToday,
      return: returnDate
        ? normalizeDate(returnDate, departDateOrToday)
        : addDays(departDateOrToday, RETURN_DATE_OFFSET),
    },
  ];
}

function normalizeDate(date: string, fallbackDate: Date): Date {
  const parsedDate = parseDate(date, DATE, 0);

  if (!isValid(parsedDate) || isBefore(parsedDate, fallbackDate)) {
    return fallbackDate;
  }

  return parsedDate;
}

function getSeatClass(sc: string | undefined): SeatClass {
  const seatClass = sc as SeatClass;

  if (AVAILABLE_SEAT_CLASS.includes(seatClass)) {
    return seatClass;
  }

  return DEFAULT_SEAT_CLASS;
}

export function isInternational(
  srcCode: string,
  destCode: string,
  airportMap: AirportMap,
  areaAirportsMap: AreaAirportsMap
) {
  const srcAreaAirports = areaAirportsMap[srcCode];
  const destAreaAirports = areaAirportsMap[destCode];

  const srcAirportCode = srcAreaAirports?.split('|')[0] ?? srcCode;
  const destAirportCode = destAreaAirports?.split('|')[0] ?? destCode;

  const srcCountry = airportMap[srcAirportCode]?.countryCode;
  const destCountry = airportMap[destAirportCode]?.countryCode;

  if (!srcCountry || !destCountry) {
    throw new Error(`Country code not found for ${srcCode} or ${destCode}`);
  }

  return srcCountry !== destCountry;
}

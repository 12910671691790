import { useApi, useFeatureControl } from '@traveloka/ctv-core';
import { useQuery } from '@tanstack/react-query';
import {
  RecentPickerPassengerRequest,
  RecentPickerPassengerResponse,
  RECENT_PICKER_PASSENGER_API,
} from 'recent-search/api';

function useRecentPickerPassengers() {
  const recentSearchFC = useFeatureControl('b2b-recent-search');
  const fetcher = useApi<
    RecentPickerPassengerResponse,
    RecentPickerPassengerRequest
  >({
    domain: 'search',
    method: 'post',
    path: RECENT_PICKER_PASSENGER_API,
  });

  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    ['recent-picker-passenger'],
    fetcher,
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: recentSearchFC.enabled,
    }
  );

  return {
    isLoading,
    isSuccess,
    isError: data?.success === false || isError,
    data: data?.success ? data.data : null,
    refetch,
  };
}

export default useRecentPickerPassengers;

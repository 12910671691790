import { JavaCurrencyValue } from 'shared/utils/currency';

export const INVOICE_UNPAID_API = '/api/v1/invoice/unpaid';
export type InvoiceUnpaidRequest = {};
export type InvoiceUnpaidResponse = {
  invoices: Array<{
    type: 'CORPORATE' | 'CORPORATE_SERVICE_CHARGE';
    statementNumber: string;
    amount: JavaCurrencyValue;
  }>;
  dispute: boolean;
};

export const INVOICE_DISPUTE_API = '/api/v1/invoice/dispute';
export type InvoiceDisputeRequest = {};
export type InvoiceDisputeResponse = {
  status: 'FAILED' | 'SUCCESS';
};

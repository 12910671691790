import React from 'react';
import { StyleSheet } from 'react-native';

import { Text } from '@traveloka/web-components';

export default function RequiredLabel() {
  return (
    <Text ink="alert" style={styles.required}>
      {' *'}
    </Text>
  );
}

const styles = StyleSheet.create({
  required: {
    // @ts-ignore
    userSelect: 'none',
  },
});

import React, {
  ReactChild,
  ReactNode,
  forwardRef,
  useState,
  useImperativeHandle,
  RefAttributes,
  useRef,
  useEffect,
  Ref,
} from 'react';

import {
  Animated,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import { useLayout } from '@traveloka/web-components/future';
import chevronIconSrc from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_24px.svg';

import { Icon, Text, useTheme, Token } from '@traveloka/web-components';

import useCollapsibleHeight from '@traveloka/web-components/src/momentum/Collapsible/useCollapsibleHeight';
import useToggleRotate from '@traveloka/web-components/src/momentum/Collapsible/useToggleRotate';

export type CollapsibleRef = RefAttributes<TouchableOpacity>;

type Props = {
  /**
   * controlled collapsible state, provide if the collapsing state
   * needs to be controlled, true means the content is hidden/collapsed
   * @default undefined
   */
  isCollapsed?: boolean;
  /**
   * controlled collapsible toggler function, paramless because it is
   * intended to only have simple toggle setter (i.e. () => setCollapsed(!isCollapsed))
   * provide if the collapsing state needs to be controlled.
   * @default undefined
   */
  onPress?: () => void;
  /**
   * Initial collapsible state, provide if needed to control initial
   * behavior for uncontrolled collapsible
   * true means content is initially hidden/collapsed
   * @default true
   */
  defaultIsCollapsed?: boolean;
  /**
   * Header
   */
  title: string | React.ReactNode;
  /**
   * custom wrapper style
   * @default {}
   */
  style?: ViewStyle;
  /**
   * custom toggler style
   * @default {}
   */
  headerStyle?: ViewStyle;
  /**
   * custom content style
   * @default {}
   */
  contentStyle?: ViewStyle;
  /**
   * custom chevron style
   * @default {}
   */
  chevronStyle?: ViewStyle;
  /**
   * Left Icon Element
   * @default null
   */
  IconStart?: ReactNode;
  /**
   * Collapsible content
   */
  children: ReactChild;
  /**
   * Touchable opacity Test ID
   */
  touchableTestID?: string;
  /**
   * Animation toogle
   * @default true
   */
  hasAnimation?: boolean;
};

export type CollapsibleHandler = {
  toggle(): void;
  setIsCollapsed(nextCollapsed: boolean): void;
};

function Collapsible(props: Props, ref: Ref<CollapsibleHandler>) {
  const {
    touchableTestID,
    isCollapsed,
    onPress,
    defaultIsCollapsed = true,
    title,
    style = {},
    headerStyle = {},
    contentStyle = {},
    chevronStyle = {},
    IconStart = null,
    hasAnimation = true,
    children,
  } = props;

  const { color } = useTheme();

  const isControlled = typeof isCollapsed === 'boolean';

  const [localIsCollapsed, setLocalIsCollapsed] = useState(defaultIsCollapsed);
  const [contentDimension, contentContainerBindings] = useLayout();
  const isTrulyCollapsed = isControlled ? isCollapsed : localIsCollapsed;

  const contentWrapperStyle = useCollapsibleHeight(
    isControlled ? isCollapsed : localIsCollapsed,
    0,
    contentDimension.height
  );
  const chevronRotateStyle = useToggleRotate(
    isControlled ? !isCollapsed : !localIsCollapsed,
    '0deg',
    '-180deg'
  );

  function onPressToggler() {
    if (typeof onPress === 'function') onPress();
    if (!isControlled) setLocalIsCollapsed(!localIsCollapsed);
  }

  useImperativeHandle(ref, () => ({
    toggle() {
      noopHandler(!isControlled, () => setLocalIsCollapsed(!localIsCollapsed));
    },
    setIsCollapsed(nextCollapsed: boolean) {
      noopHandler(!isControlled, () => setLocalIsCollapsed(nextCollapsed));
    },
  }));

  const EnforcedIconStart = IconStart ? enforceIconSizing(IconStart) : null;

  const defaultBgColor = { backgroundColor: color.lightPrimary };

  const [animatingStyle, setAnimatingStyle] = useState(
    isTrulyCollapsed ? styles.contentContainer : undefined
  );

  const timeoutRef = useRef<number>();
  useEffect(() => {
    if (isTrulyCollapsed) {
      setAnimatingStyle(styles.contentContainer);
    } else {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        setAnimatingStyle(undefined);
      }, Token.animation.timing.instant);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isTrulyCollapsed]);

  return (
    <View style={[defaultBgColor, style]}>
      <TouchableOpacity testID={touchableTestID} onPress={onPressToggler}>
        <View
          style={[
            styles.toggler,
            { borderBottomColor: color.borderSubtle },
            headerStyle,
          ]}
        >
          {EnforcedIconStart && (
            <View style={styles.iconStart}>{EnforcedIconStart}</View>
          )}
          {typeof title === 'string' ? (
            <Text
              variant="ui-baseline"
              ink="primary"
              style={[styles.title, Token.typography.weightMedium]}
            >
              {title}
            </Text>
          ) : (
            <View style={styles.title}>{title}</View>
          )}
          <Animated.View
            style={[styles.chevron, chevronRotateStyle, chevronStyle]}
          >
            <Icon
              src={chevronIconSrc}
              width={Token.spacing.l}
              height={Token.spacing.l}
              alt="Chevron Icon"
            />
          </Animated.View>
        </View>
      </TouchableOpacity>
      {hasAnimation ? (
        <Animated.View style={[animatingStyle, contentWrapperStyle]}>
          <View
            {...contentContainerBindings}
            style={[styles.innerContent, contentStyle]}
          >
            {children}
          </View>
        </Animated.View>
      ) : (
        <View
          {...contentContainerBindings}
          style={[
            styles.innerContent,
            animatingStyle,
            isTrulyCollapsed && { padding: 0, height: 0 },
            contentStyle,
          ]}
        >
          {children}
        </View>
      )}
    </View>
  );
}

function enforceIconSizing(Icon: any) {
  return React.cloneElement(Icon, {
    width: Token.spacing.l,
    height: Token.spacing.l,
  });
}

function noopHandler(flag: boolean, func: (...args: any[]) => void) {
  if (flag) func();
  return;
}

const styles = StyleSheet.create({
  contentContainer: {
    overflow: 'hidden',
  },
  innerContent: {
    padding: Token.spacing.m,
  },
  toggler: {
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    padding: Token.spacing.m,
    borderBottomWidth: Token.border.width.thin,
  },
  iconStart: {
    marginRight: Token.spacing.s,
  },
  title: {
    flex: 1,
  },
  chevron: {
    marginLeft: Token.spacing.s,
  },
});

export default forwardRef(Collapsible);

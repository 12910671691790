import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { LoadingIndicator, Text, Token } from '@traveloka/web-components';

export default function Loading() {
  const content = useContentResource().CorporateProductPayment;

  return (
    <View style={Style.container}>
      <LoadingIndicator style={Style.loading} />
      <Text>{content.loadingText}</Text>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  loading: {
    marginBottom: Token.spacing.xs,
  },
});

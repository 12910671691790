import React from 'react';

import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import RedirectHome from 'generic/RedirectHome';

import PaymentSelect from './select/PaymentSelect';

type Props = RouteComponentProps;

function PaymentApp(props: Props) {
  const { match } = props;

  return (
    <Switch>
      <Route
        path={`${match.path}/select/:type(flight|hotel)/:bookingId`}
        exact
        component={PaymentSelect}
      />
      <Route component={RedirectHome} />
    </Switch>
  );
}

export default PaymentApp;

import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { get, set } from 'es-cookie';

import { useAuth } from '../auth';

export enum Locale {
  ENID = 'en-id',
  IDID = 'id-id',
  ENTH = 'en-th',
  THTH = 'th-th',
  ENVN = 'en-vn',
  VNVN = 'vi-vn',
}
export const localeCookieKey = 'locale';

type Context = {
  isLoading: boolean;
  locale: Locale;
};
type ContextDispatcher = {
  changeLanguage(locale: Locale): Promise<void>;
  setIsLoading(value: boolean): Promise<void>;
};

type Props = {};

const LocaleContext = createContext<Context>(undefined!);
const LocaleDispatcherContext = createContext<ContextDispatcher>(undefined!);

// To prevent double call when locale is first updated on first load.
let previousLocale: Locale | undefined = undefined;

export function LocaleProvider(props: PropsWithChildren<Props>) {
  const history = useHistory();
  const { user } = useAuth();

  const [locale, setLocale] = useState<Locale>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const defaultLocale = useMemo(() => {
    if (!user || !user.country) {
      return Locale.ENID;
    }
    return `en-${user.country.toLowerCase()}` as Locale;
  }, [user]);

  const changeLanguage = useCallback(
    async (paramLocale: Locale) => {
      if (locale === paramLocale) {
        return;
      }

      setIsLoading(true);
      set(localeCookieKey, paramLocale);
      setLocale(paramLocale);
      history.replace(
        window.location.pathname.replace(`/${locale}`, `/${paramLocale}`) +
          window.location.search +
          window.location.hash
      );
    },
    [locale]
  );
  const changeIsLanguage = useCallback(async (value: boolean) => {
    setIsLoading(value);
  }, []);

  function getLocaleByCountry(country: string) {
    const filteredLocale = Object.values(Locale).filter(i =>
      i.includes(country?.toLowerCase())
    );
    return filteredLocale.length ? filteredLocale[0] : defaultLocale;
  }

  // detect if user doesn't have local cookie and set the default locale
  useEffect(() => {
    if (get(localeCookieKey) || !user?.country) return;

    const localByUser = getLocaleByCountry(user?.country);
    changeLanguage(localByUser);
  }, [user]);

  useEffect(() => {
    let isLocaleChanged = false;
    // This will get the 'en-id' from a full path: '/en-id/' then split('/') -> ['/', 'en-id', '/']
    const localeFromUrl = window.location.pathname.split('/')[1] as Locale;

    if (isLocale(localeFromUrl)) {
      // Ref logic: https://app.diagrams.net/#G1Uamm1XEp-sTpJ6bpl2A0qF30VlaDcwy8
      if (hasSupportedLocales(localeFromUrl)) {
        isLocaleChanged = true;
        previousLocale = locale;
        setLocale(localeFromUrl);
      } else {
        if (hasSupportedLocales(get(localeCookieKey))) {
          isLocaleChanged = true;
          setLocale(get(localeCookieKey) as Locale);
        }
      }
    } else if (!window.location.pathname.includes('/callback')) {
      const usedLocale: Locale | undefined = get(localeCookieKey) as Locale;
      if (hasSupportedLocales(usedLocale)) {
        isLocaleChanged = true;
        previousLocale = locale;
        setLocale(usedLocale);
      }
      const path =
        '/' +
        (usedLocale || defaultLocale) +
        window.location.pathname +
        window.location.search;

      history.replace(path);
    }

    if (!isLocaleChanged) {
      const usedLocale: Locale | undefined = get(localeCookieKey) as Locale;
      if (hasSupportedLocales(usedLocale)) {
        previousLocale = locale;
        setLocale(usedLocale);
      } else {
        previousLocale = locale;
        setLocale(defaultLocale);
      }
    }
  }, []);

  if (locale === undefined) {
    return null;
  }

  return (
    <LocaleContext.Provider value={{ isLoading, locale }}>
      <LocaleDispatcherContext.Provider
        value={{ setIsLoading: changeIsLanguage, changeLanguage }}
      >
        {props.children}
      </LocaleDispatcherContext.Provider>
    </LocaleContext.Provider>
  );
}

export function useLocale() {
  return useContext(LocaleContext);
}

export function useLocaleDispatcher() {
  return useContext(LocaleDispatcherContext);
}

export function hasSupportedLocales(path?: string) {
  return (
    path &&
    (Object.keys(Locale) as Array<keyof typeof Locale>)
      .map(locale => Locale[locale])
      .some(locale => path.includes(locale))
  );
}

export function isLocale(str: string) {
  return /^[a-z]{2}-[a-z]{2}$/.test(str);
}

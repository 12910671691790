import { useMemo, useReducer } from 'react';

import { SmartComboSearchResponse } from 'flight/shared/api';
import {
  FlightSearchData,
  SmartComboSearchData,
} from '../utils/flight-search-view-util';

type State = {
  isLoading: boolean;
  results?: FlightSearchData[];
  searchId: Nullable<string>;
  selected?: FlightSearchData;
  smartComboResults?: SmartComboSearchData[];
  smartComboResponse?: Nullable<SmartComboSearchResponse>;
};

type Action =
  | { type: 'reset' }
  | { type: 'result'; data: FlightSearchData[]; searchId: Nullable<string> }
  | { type: 'select'; data: FlightSearchData }
  | { type: 'cancel' }
  | {
      type: 'smartCombo';
      data: SmartComboSearchData[];
      response: Nullable<SmartComboSearchResponse>;
    }
  | { type: 'loading'; value: boolean };

const initialState: State = {
  isLoading: true,
  results: undefined,
  smartComboResults: undefined,
  searchId: null,
  selected: undefined,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'result':
      return {
        ...state,
        isLoading: false,
        results: action.data,
        searchId: action.searchId,
      };
    case 'select':
      return {
        ...state,
        selected: action.data,
      };
    case 'cancel':
      return {
        ...state,
        selected: undefined,
      };
    case 'smartCombo':
      return {
        ...state,
        smartComboResults: action.data,
        smartComboResponse: action.response,
      };
    case 'loading':
      return {
        ...state,
        isLoading: action.value,
      };
  }
}

export default function useFlightSearchReducer() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const action = useMemo(
    () => ({
      reset() {
        dispatch({ type: 'reset' });
      },
      result(data: FlightSearchData[], searchId: Nullable<string>) {
        dispatch({ type: 'result', data, searchId });
      },
      select(data: FlightSearchData) {
        dispatch({ type: 'select', data });
      },
      cancel() {
        dispatch({ type: 'cancel' });
      },
      smartCombo(
        data: SmartComboSearchData[],
        response: Nullable<SmartComboSearchResponse>
      ) {
        dispatch({ type: 'smartCombo', data, response });
      },
      loading(value: boolean) {
        dispatch({ type: 'loading', value });
      },
    }),
    []
  );

  return [state, action] as const;
}

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { differenceInCalendarDays, format, parse } from 'date-fns';
import { useParams } from 'react-router-dom';

import { useApi } from '@traveloka/ctv-core';
import { ApiResult } from '@traveloka/ctv-core/api/types';
import {
  EntryListRequest,
  EntryListResponse,
  GET_ENTRY_LIST,
  Operator,
} from '@traveloka/ctv-core/pad';

import {
  CHECK_SEARCH_SPEC_API,
  CheckSearchSpecRequest,
  CheckSearchSpecResponse,
} from 'approval-system/shared/api';
import { PartialEmployee } from 'company/types';
import { generateSearchSpecPayload } from 'hotel/detail/utils/searchSpecCheckUtil';
import {
  ROOM_AVAILABILITY_API,
  RoomAvailabilityRequest,
  RoomAvailabilityResponse,
} from 'hotel/shared/api';
import { HotelNonEmployeeForm, NonEmployeeType } from 'hotel/shared/api/types';
import { GET_PROFILE, ProfileResponse } from 'profile/api';
import { Profile } from 'profile/types';
import { convertToJava } from 'shared/utils/currency';
import { DATE, JAVA_DATE } from 'shared/utils/date';
import expirableStorage from 'shared/utils/expirable-storage';

import { Store } from '../types';

type Api<T> = Promise<ApiResult<T>>;

type State = {
  isLoading: boolean;
  isSuccess: boolean;
  checkSearchSpec: CheckSearchSpecResponse;
  room: RoomAvailabilityResponse;
  trackingId?: string;
  travelers: PartialEmployee[];
  nonEmployeeTravelers: HotelNonEmployeeForm[];
  profile: Profile;
  spec: Spec;
};

export type Spec = OmitTyped<
  Store,
  'checkInDate' | 'checkOutDate' | 'travelers' | 'nonEmployeeTravelers'
> & {
  checkInDate: Date;
  checkOutDate: Date;
  duration: number;
  initialGuestCount: number;
};

function initializer(isLoading: boolean): State {
  return {
    isLoading,
    isSuccess: false,
    checkSearchSpec: undefined!,
    room: undefined!,
    trackingId: undefined,
    travelers: undefined!,
    nonEmployeeTravelers: undefined!,
    profile: undefined!,
    spec: undefined!,
  };
}

const PrebookContext = createContext<State>(undefined!);

export function PrebookProvider(props: PropsWithChildren<{}>) {
  const [state, setState] = useState(initializer(true));

  const searchRoom = useApi<RoomAvailabilityResponse, RoomAvailabilityRequest>({
    domain: 'search',
    method: 'post',
    path: ROOM_AVAILABILITY_API,
  });

  const getProfile = useApi<ProfileResponse>({
    domain: 'management',
    method: 'post',
    path: GET_PROFILE,
  });

  const searchTravelers = useApi<
    EntryListResponse<PartialEmployee>,
    EntryListRequest
  >({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_LIST,
  });

  const checkSearchSpec = useApi<
    CheckSearchSpecResponse,
    CheckSearchSpecRequest
  >({
    domain: 'booking',
    method: 'post',
    path: CHECK_SEARCH_SPEC_API,
  });

  const { key } = useParams<{ key: string }>();

  useEffect(() => {
    const store = expirableStorage.get<Store>(key);

    if (!store) {
      setState(initializer(false));
      return;
    }

    const checkInDate = parse(store.checkInDate, DATE, 0);
    const checkOutDate = parse(store.checkOutDate, DATE, 0);

    const prebookSpec: RoomAvailabilityRequest = {
      checkInDate: format(checkInDate, JAVA_DATE),
      checkOutDate: format(checkOutDate, JAVA_DATE),
      employeeIds: store.travelers.map(traveler => traveler.employeeId),
      nonEmployeeTravelers: store.nonEmployeeTravelers,
      numOfRooms: store.numOfRooms,
      propertyId: store.propertyId,
      roomId: store.roomId,
      rateKey: store.rateKey,
      roomOccupancy: store.roomOccupancy,
      locale: 'en-ID',
      currencyCode: 'IDR',
      tripRequestId: store.tripRequestId,
      trackingSpec: {
        pageName: 'HOTEL_PREBOOK',
        pageCategory: 'HOTEL',
        data: {
          visitId: store.visitId,
          searchId: store.searchId,
          detailId: store.detailId,
        },
      },
      totalFareFromClient: convertToJava(store.totalFare),
    };
    const travelersSpec: EntryListRequest = {
      entityType: 'employeeList',
      search: {
        entriesCount: store.travelers.length,
        filter: [
          {
            fieldName: 'employee_id',
            arguments: {
              value: store.travelers.map(traveler => traveler.employeeId),
              operator: Operator.IN,
            },
          },
        ],
      },
    };

    const searchSpecPayload = generateSearchSpecPayload({
      ...store,
      checkInDate,
      checkOutDate,
    });

    const apis: [
      Api<RoomAvailabilityResponse>,
      Api<EntryListResponse<PartialEmployee>> | undefined,
      Api<ProfileResponse>,
      Api<CheckSearchSpecResponse>
    ] = [
      searchRoom(prebookSpec),
      store.travelers.length !== 0 ? searchTravelers(travelersSpec) : undefined,
      getProfile({}),
      checkSearchSpec(searchSpecPayload),
    ];

    Promise.all(apis)
      .then<State>(res => {
        const [roomRes, travelersRes, profileRes, checkSearchSpecRes] = res;

        if (
          roomRes.success &&
          profileRes.success &&
          checkSearchSpecRes.success
        ) {
          let sortedTravelers: PartialEmployee[] = [];

          if (travelersRes && travelersRes.success) {
            sortedTravelers = travelersRes.data.entries.sort(
              (a, b) =>
                findTravelerIndex(store, a.employeeId) -
                findTravelerIndex(store, b.employeeId)
            );
          }

          const spec: Spec = {
            checkInDate,
            checkOutDate,
            propertyId: store.propertyId,
            propertyName: store.propertyName,
            duration: differenceInCalendarDays(checkOutDate, checkInDate),
            numOfRooms: store.numOfRooms,
            roomId: store.roomId,
            rateKey: store.rateKey,
            totalFare: store.totalFare,
            roomOccupancy: store.roomOccupancy,
            initialGuestCount: store.travelers.length,
            visitId: store.visitId,
            searchId: store.searchId,
            detailId: store.detailId,
            tripRequestId: store.tripRequestId,
            tripName: store.tripName,
            approverName: store.approverName,
            approverReason: store.approverReason,
            productRequestId: store.productRequestId,
            geoId: store.geoId,
            geoType: store.geoType,
          };

          const nonEmployeeTravelerForms: HotelNonEmployeeForm[] = ([] as HotelNonEmployeeForm[]).concat(
            createNonEmployeeTravelerForm(
              NonEmployeeType.ADULT,
              store.nonEmployeeTravelers.adults
            ),
            createNonEmployeeTravelerForm(
              NonEmployeeType.CHILD_AGE,
              store.nonEmployeeTravelers.childrenAges.length,
              store.nonEmployeeTravelers.childrenAges
            )
          );

          return {
            isLoading: false,
            isSuccess: true,
            checkSearchSpec: checkSearchSpecRes.data,
            room: roomRes.data,
            trackingId: roomRes.trackingSpec?.id,
            travelers: sortedTravelers,
            nonEmployeeTravelers: nonEmployeeTravelerForms,
            profile: profileRes.data.profile,
            spec,
          };
        }

        throw new Error();
      })
      .catch(() => initializer(false))
      .then(setState);
  }, [key]);

  return (
    <PrebookContext.Provider value={state}>
      {props.children}
    </PrebookContext.Provider>
  );
}

function findTravelerIndex(store: Store, id: string) {
  return store.travelers.findIndex(t => t.employeeId === id);
}

export function usePrebook() {
  return useContext(PrebookContext);
}

function createNonEmployeeTravelerForm(
  type: NonEmployeeType,
  length: number,
  ages?: string[]
): HotelNonEmployeeForm[] {
  return Array.from({
    length,
  }).map((_, index) => ({
    title: null!,
    type,
    fullName: '',
    age: ages ? ages[index] : undefined,
  }));
}

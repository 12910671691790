import { StyleSheet } from 'react-native';
import { Token } from '@traveloka/web-components';
import { Theme } from '@traveloka/web-components/src/momentum/Theme';

export const CommonStyle = StyleSheet.create({
  menuItem: {
    marginLeft: Token.spacing.xxxl,
  },
  container: {
    marginTop: Token.spacing.xxs,
    borderWidth: Token.border.width.thick,
    padding: Token.spacing.xs,
  },
  section: {
    marginBottom: Token.spacing.s,
  },
  checkbox: {
    marginTop: Token.spacing.xxs,
  },
  slider: {
    marginTop: Token.spacing.xs,
    marginBottom: Token.spacing.xxs,
  },
  noMarginTop: {
    marginTop: 0,
  },
  checkboxText: {
    marginLeft: Token.spacing.xs,
  },
  checkboxTextJustify: {
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-between',
    marginLeft: Token.spacing.xs,
  },
});

export function getThemeStyle(theme: Theme) {
  return {
    container: {
      borderColor: theme.color.lightSecondary,
    },
  };
}

import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { parse } from 'qs';
import {
  ConfirmationRequest,
  ConfirmationResponse,
  CONFIRMATION_API,
} from 'registration/api';
import { RegistrationStatus, ValidConfirmation } from 'registration/api/types';
import { PreRegisterConfirmProvider } from 'registration/contexts/PreRegisterConfirmContext';
import { useDispatchSnackbar } from 'shared/contexts/Snackbar/SnackbarContext';

import { useApi, useContentResource } from '@traveloka/ctv-core';
import { LoadingIndicator } from '@traveloka/web-components';

import { TokenProvider } from '../contexts/TokenContext';
import RegistrationProcess from './RegistrationProcess';
import RegistrationWizard from './RegistrationWizard';
import RegistrationUserRequest from './RegistrationUserRequest';

type OuterStep =
  | 'REGISTRATION_HOMEPAGE'
  | 'REGISTRATION_FORM'
  | 'REQUEST_NDA'
  | 'REQUEST_MEETING';

export default function RegistrationContent() {
  const [step, setStep] = useState<OuterStep>('REGISTRATION_HOMEPAGE');
  const [loading, setLoading] = useState(false);
  const [registrationData, setRegistrationData] = useState<
    Nullable<ValidConfirmation>
  >(null);

  const cr = useContentResource().CorporateRegistration;

  const confirmToken = useApi<ConfirmationResponse, ConfirmationRequest>({
    domain: 'management',
    method: 'post',
    path: CONFIRMATION_API,
  });
  const openSnackbar = useDispatchSnackbar();

  const [token, setToken] = useState('');

  function fetchData() {
    const token = parse(window.location.search, { ignoreQueryPrefix: true })
      .token as string | undefined;

    if (!token) return;

    setToken(token);
    setLoading(true);
    confirmToken({ token }).then(res => {
      if (res.success && res.data.tokenStatus === 'OK') {
        setRegistrationData(res.data);
        setStep(getCurrentStep(res.data.status));
      } else {
        setStep('REGISTRATION_HOMEPAGE');
        openSnackbar({ message: cr.tokenExpired, variant: 'alert' });
      }

      setLoading(false);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <LoadingIndicator />
      </View>
    );
  }

  return (
    <TokenProvider value={token}>
      <PreRegisterConfirmProvider value={registrationData}>
        {step === 'REGISTRATION_HOMEPAGE' && <RegistrationProcess />}
        {step === 'REGISTRATION_FORM' && (
          <RegistrationWizard
            onRequestNda={() => setStep('REQUEST_NDA')}
            onRequestMeeting={() => setStep('REQUEST_MEETING')}
          />
        )}
        {step === 'REQUEST_NDA' && (
          <RegistrationUserRequest title={cr.userRequestNdaTitle} />
        )}
        {step === 'REQUEST_MEETING' && (
          <RegistrationUserRequest title={cr.userRequestMeetingTitle} />
        )}
      </PreRegisterConfirmProvider>
    </TokenProvider>
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    alignItems: 'center',
  },
});

function getCurrentStep(status: Nullable<RegistrationStatus>): OuterStep {
  switch (status) {
    case null:
    case RegistrationStatus.NEW_LEADS:
    case RegistrationStatus.ONGOING_REGISTRATION:
    case RegistrationStatus.INCOMPLETE_DATA:
      return 'REGISTRATION_FORM';
    case RegistrationStatus.NDA_PROCESS:
      return 'REQUEST_NDA';
    case RegistrationStatus.PRODUCT_INTRODUCTION:
      return 'REQUEST_MEETING';
    default:
      return 'REGISTRATION_HOMEPAGE';
  }
}

import { useController, useFormContext } from 'react-hook-form';

type Props = {
  value: string;
  name: string;
};

export default function HiddenField(props: Props) {
  const { value, name } = props;

  const { control } = useFormContext();
  const _ = useController({
    control,
    name,
    defaultValue: value,
  });

  return null;
}

import React from 'react';
import { StyleSheet } from 'react-native';

import { useFeatureControl } from '@traveloka/ctv-core/feature-control';
import { Token } from '@traveloka/web-components';

import AccountSuspendedPopup from 'account-status/components/AccountSuspendedPopup/AccountSuspendedPopup';
import { PriceDisplayProvider } from 'hotel/search/contexts/PriceDisplayContext';
import { TwoColumn } from 'shared/components/Layout';

import FilterBox from '../FilterBox/FilterBox';
import Header from '../Header/Header';
import SearchBar from '../SearchBar/SearchBar';
import SearchList from '../SearchList/SearchList';
import SortBox from '../SortBox/SortBox';

export default function HotelSearchContent() {
  const sortFc = useFeatureControl('b2b-hotel-sort');

  return (
    <>
      <Header />
      <TwoColumn>
        <TwoColumn.Small isSticky={false}>
          {sortFc.enabled && <SortBox style={Style.section} />}
          <FilterBox />
        </TwoColumn.Small>
        <TwoColumn.Big style={Style.right}>
          <PriceDisplayProvider>
            <SearchBar style={Style.searchBar} />
            <SearchList />
          </PriceDisplayProvider>
        </TwoColumn.Big>
        <AccountSuspendedPopup backToHome />
      </TwoColumn>
    </>
  );
}

const Style = StyleSheet.create({
  section: {
    marginBottom: Token.spacing.m,
  },
  searchBar: {
    marginBottom: Token.spacing.m,
    zIndex: 1,
  },
  right: {
    marginLeft: Token.spacing.m,
  },
});

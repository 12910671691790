import { format } from 'date-fns';

import {
  FlightSearchRequest,
  SmartComboSearchRequest,
} from 'flight/shared/api';
import {
  FlightNonEmployeeTraveler,
  NonEmployeeType,
} from 'flight/shared/api/types';
import { JAVA_DATE } from 'shared/utils/date';

import {
  ComputedSearchSpec,
  defaultFlightNonEmployee,
} from '../contexts/SearchSpecContext';

export function getBasicSearchSpec(
  searchSpec: ComputedSearchSpec,
  isDeparture: boolean
): FlightSearchRequest {
  const airport = isDeparture
    ? searchSpec.airport
    : {
        origin: searchSpec.airport.destination,
        destination: searchSpec.airport.origin,
      };

  return {
    depAirportOrAreaCode: airport.origin,
    arrAirportOrAreaCode: airport.destination,
    depDate: format(searchSpec.date.depart, JAVA_DATE),
    retDate: searchSpec.isRoundTrip
      ? format(searchSpec.date.return, JAVA_DATE)
      : undefined,
    direction: isDeparture ? 'AWAY' : 'RETURN',
    seatClass: searchSpec.seatClass,
    emails: searchSpec.passengers.map(passenger => passenger.email),
    nonEmployeeTravelers:
      searchSpec.nonEmployeeTravelers || defaultFlightNonEmployee,
    tripRequestId: searchSpec.tripRequestId,
    trackingSpec: {
      pageName: 'FLIGHT_SEARCH',
      pageCategory: 'FLIGHT',
      data: {
        funnelSource: 'SEARCH_RESULT',
      },
    },
  };
}

export function getSmartComboSearchSpec(
  searchSpec: ComputedSearchSpec
): SmartComboSearchRequest {
  return {
    depAirportOrAreaCode: searchSpec.airport.origin,
    arrAirportOrAreaCode: searchSpec.airport.destination,
    depDate: format(searchSpec.date.depart, JAVA_DATE),
    retDate: format(searchSpec.date.return, JAVA_DATE),
    seatClass: searchSpec.seatClass,
    emails: searchSpec.passengers.map(passenger => passenger.email),
    nonEmployeeTravelers:
      searchSpec.nonEmployeeTravelers || defaultFlightNonEmployee,
    tripRequestId: searchSpec.tripRequestId,
    trackingSpec: {
      pageName: 'FLIGHT_SEARCH',
      pageCategory: 'FLIGHT',
      data: {
        funnelSource: 'SEARCH_RESULT',
      },
    },
  };
}

export function convertNonEmployeeTypeToPayload(type: NonEmployeeType) {
  switch (type) {
    case NonEmployeeType.ADULT:
      return 'adults';
    case NonEmployeeType.CHILD:
      return 'children';
    case NonEmployeeType.INFANT:
      return 'infants';
  }
}

export function convertNonEmployeePayloadToType(
  type: keyof FlightNonEmployeeTraveler
) {
  switch (type) {
    case 'adults':
      return NonEmployeeType.ADULT;
    case 'children':
      return NonEmployeeType.CHILD;
    case 'infants':
      return NonEmployeeType.INFANT;
  }
}

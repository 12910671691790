import React from 'react';

import { useParams } from 'react-router-dom';

import { Content, Footer, Header, Page } from 'shared/components/Layout';

import HotelPaymentSelectContent from './components/PaymentSelectContent/HotelPaymentSelectContent';
import FlightPaymentSelectContent from './components/PaymentSelectContent/FlightPaymentSelectContent';
import {
  PaymentStateProvider,
  usePaymentState,
} from './contexts/PaymentContext';
import Loading from './components/Loading/Loading';
import NotAuthorized from './components/NotAuthorized/NotAuthorized';

type ProductType = 'flight' | 'hotel';

const componentMap: Record<ProductType, JSX.Element> = {
  flight: <FlightPaymentSelectContent />,
  hotel: <HotelPaymentSelectContent />,
};

export default function PaymentSelect() {
  return (
    <Page>
      <Header />
      <Content>
        <PaymentStateProvider>
          <PaymentPageGate />
        </PaymentStateProvider>
      </Content>
      <Footer />
    </Page>
  );
}

function PaymentPageGate() {
  const { type } = useParams<{ type: string }>();
  const { isLoading, isSuccess } = usePaymentState();

  const Component = componentMap[type as ProductType];

  if (isLoading) {
    return <Loading />;
  }
  if (!isSuccess) {
    return <NotAuthorized />;
  }
  return Component;
}

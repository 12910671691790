import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { addHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useLocation } from 'react-router';

import { Button, Card, Image, Text, Token } from '@traveloka/web-components';

import { getQueryString } from '../query-string/query-string';
import { getLocaleByUrl } from '../utils/locale';
import { isEmail } from '../utils/validator';
import { useCognitoAuth } from './CognitoAuthContext';
import { Field } from './components/Field';
import NewPasswordValidity, {
  validatePassword,
} from './components/NewPasswordValidity';
import ResendCodeModal from './components/ResendCodeModal';
import SuccessResetPassword from './components/SuccessResetPassword';
import { decodeBase64, doForgetPassword } from './utils/auth';

type EncryptedQs = {
  data?: string;
  nc?: boolean;
};
type DecryptedQs = {
  data?: {
    ea?: string;
    ed?: string; // Epoch date
  };
  nc?: boolean;
};

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageState, setPageState] = useState<
    | 'SIGN_IN'
    | 'REQUEST_RESET_PASSWORD'
    | 'RESET_PASSWORD_FORM'
    | 'SUCCESS_RESET_PASSWORD'
  >('SIGN_IN');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showNewPasswordValidity, setShowNewPasswordValidity] = useState(false);
  const [isResendCodeModalVisible, setIsResendCodeModalVisible] = useState(
    false
  );

  const { applyUser, isAuthenticated } = useCognitoAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/home';

  const { CorporateCognitoLogin } = CRQuery;

  function legacyLogic() {
    const query = getQueryString();

    if (isAuthenticated) {
      // Automatically detect if user is already logged in
      // Will logout the user and go to the reset password page
      if (query.ea) {
        const emailAddress = isEmail(query.ea)
          ? query.ea
          : decodeBase64(query.ea);

        if (emailAddress.length > 0 && isEmail(emailAddress)) {
          Auth.signOut().then(() => window.location.reload());
          return;
        }
      }
      window.location.replace(from);
    }

    if (query.ea) {
      // To handle if the email was sent from the BE (no clientmeta data)
      // TODO: Will remove this if the Forgot Password repository already support CryptoJS encryption
      const emailAddress = isEmail(query.ea)
        ? query.ea
        : decodeBase64(query.ea);

      if (emailAddress.length > 0 && isEmail(emailAddress)) {
        setEmail(emailAddress);
        setPageState('RESET_PASSWORD_FORM');
      }

      if (query.nc) {
        const locale = getLocaleByUrl();

        doForgetPassword(emailAddress).then(data =>
          window.location.replace(`/${locale}/login?data=${data}`)
        );
      }
    }
  }
  useEffect(() => {
    const qs = getQueryString();
    if (qs.ea) {
      legacyLogic();
      return;
    }

    const query = decryptQs(getQueryString<EncryptedQs>());

    if (isAuthenticated) {
      // Automatically detect if user is already logged in
      // Will logout the user and go to the reset password page
      if (query?.data) {
        const { ea: emailAddress } = query.data;
        if (emailAddress && isEmail(emailAddress)) {
          Auth.signOut().then(() => window.location.reload());
          return;
        }
      }
      window.location.replace(from);
    }

    handleQueryString(query);
  }, []);

  function handleQueryString(query: ReturnType<typeof decryptQs>) {
    if (!query?.data?.ea) {
      return;
    }

    const { ea: emailAddress } = query.data;

    if (emailAddress.length === 0 || !isEmail(emailAddress)) {
      return;
    }

    handleResendCode(query);
    const lowerCaseEmail = emailAddress.toLowerCase();
    setEmail(lowerCaseEmail);
    setPageState('RESET_PASSWORD_FORM');

    if (!query?.nc) {
      return;
    }

    // Resend email and remove nc query string
    const locale = getLocaleByUrl();
    doForgetPassword(lowerCaseEmail).then(data =>
      window.location.replace(`/${locale}/login?data=${data}`)
    );
  }

  function handleResendCode(query: ReturnType<typeof decryptQs>) {
    if (!query?.data?.ed || !query?.data?.ea) {
      return;
    }

    const { ed: expiryDate } = query.data;
    const { ea: emailAddress } = query.data;
    const lowerCaseEmail = emailAddress.toLowerCase();

    const timeoutDate = addHours(new Date(Number(expiryDate)), 1).getTime();
    setTimeout(() => {
      doForgetPassword(lowerCaseEmail).then(data => {
        setIsResendCodeModalVisible(true);
        setCode('');
        const locale = getLocaleByUrl();
        window.location.replace(`/${locale}/login?data=${data}`);
      });
    }, Math.max(timeoutDate - Date.now(), 0));
  }

  function handleLogin() {
    const lowerCaseEmail = email.toLowerCase();

    if (!isEmail(lowerCaseEmail)) {
      setErrorMessage(CorporateCognitoLogin.emailFieldError);
      return;
    }

    setIsSubmit(true);
    if (isTravelokaEmail(lowerCaseEmail)) {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
      return;
    }

    Auth.signIn(lowerCaseEmail, password)
      .then(res => {
        applyUser(res.signInUserSession);
        window.location.replace(from);
      })
      .catch(res => setErrorMessage(res.message))
      .finally(() => setIsSubmit(false));
  }

  function handleForgotPassword() {
    if (!isEmail(email)) {
      setErrorMessage(CorporateCognitoLogin.emailFieldError);
      return;
    }

    setIsSubmit(true);
    doForgetPassword(email)
      .then(() => setPageState('RESET_PASSWORD_FORM'))
      .catch(res => setErrorMessage(res.message))
      .finally(() => setIsSubmit(false));
  }

  function handleConfirmNewPassword() {
    if (newPassword !== confirmNewPassword) {
      setErrorMessage(CorporateCognitoLogin.confirmPasswordNotMatchError);
      return;
    }

    if (!validatePassword(newPassword).isValid) {
      setShowNewPasswordValidity(true);
      return;
    }

    setIsSubmit(true);
    Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword)
      .then(() => setPageState('SUCCESS_RESET_PASSWORD'))
      .catch(res => setErrorMessage(res.message))
      .finally(() => setIsSubmit(false));
  }

  function handleSubmit() {
    if (buttonDisabled) {
      return;
    }

    setErrorMessage('');
    if (pageState === 'SIGN_IN') {
      handleLogin();
    } else if (pageState === 'REQUEST_RESET_PASSWORD') {
      handleForgotPassword();
    } else if (pageState === 'RESET_PASSWORD_FORM') {
      handleConfirmNewPassword();
    }
  }

  useEffect(() => {
    setErrorMessage('');

    if (pageState === 'SUCCESS_RESET_PASSWORD') {
      setTimeout(() => setPageState('SIGN_IN'), 4000);
    }
  }, [pageState]);

  let showErrorMessage = Boolean(errorMessage);
  if (pageState === 'SIGN_IN') {
    showErrorMessage = Boolean(errorMessage) && !isTravelokaEmail(email);
  }

  let buttonDisabled = !email || !password;
  if (isTravelokaEmail(email) || pageState === 'REQUEST_RESET_PASSWORD') {
    buttonDisabled = !email;
  } else if (pageState === 'RESET_PASSWORD_FORM') {
    buttonDisabled = !newPassword || !confirmNewPassword || !code;
  }

  return (
    <>
      <View style={styles.container}>
        <Card style={styles.wrapper} elevation="float">
          {pageState !== 'SUCCESS_RESET_PASSWORD' && (
            <View style={styles.travelokaLogo}>
              <Image
                style={{ marginBottom: 11 }}
                alt="traveloka-for-corporates"
                src="https://corporates.ctv.traveloka.com/img/traveloka-for-corporates.png"
                width={384}
                height={54.5}
                objectFit="contain"
              />
            </View>
          )}
          <View style={styles.content}>
            {pageState === 'SIGN_IN' && (
              <>
                <Field
                  label={CorporateCognitoLogin.emailFieldLabel}
                  placeholder={CorporateCognitoLogin.emailFieldPlaceholder}
                  value={email}
                  onChange={setEmail}
                  handleSubmit={handleSubmit}
                />
                {!isTravelokaEmail(email) && (
                  <>
                    <Field
                      label={CorporateCognitoLogin.passwordFieldLabel}
                      placeholder={
                        CorporateCognitoLogin.passwordFieldPlaceholder
                      }
                      password
                      value={password}
                      onChange={setPassword}
                      handleSubmit={handleSubmit}
                    />
                    <Text
                      style={styles.toRequestResetPasswordText}
                      variant="title-3"
                      ink="primary-interactive"
                      onPress={() => setPageState('REQUEST_RESET_PASSWORD')}
                    >
                      {CorporateCognitoLogin.forgotPasswordText}
                    </Text>
                  </>
                )}
              </>
            )}
            {pageState === 'REQUEST_RESET_PASSWORD' && (
              <>
                <Text style={styles.infoText}>
                  {CorporateCognitoLogin.requestResetPasswordInfoText}
                </Text>
                <Field
                  label={CorporateCognitoLogin.emailFieldLabel}
                  placeholder={CorporateCognitoLogin.emailFieldPlaceholder}
                  value={email}
                  onChange={setEmail}
                  handleSubmit={handleSubmit}
                />
              </>
            )}
            {pageState === 'RESET_PASSWORD_FORM' && (
              <>
                <Text style={styles.infoText}>
                  {CorporateCognitoLogin.submitResetPasswordInfoText}
                </Text>
                <Field
                  label={CorporateCognitoLogin.emailFieldLabel}
                  placeholder={CorporateCognitoLogin.emailFieldPlaceholder}
                  value={email}
                  onChange={setEmail}
                  disabled
                />
                <Field
                  label={CorporateCognitoLogin.yourNewPasswordLabel}
                  placeholder={CorporateCognitoLogin.yourNewPasswordPlaceholder}
                  password
                  value={newPassword}
                  onChange={setNewPassword}
                  handleSubmit={handleSubmit}
                />
                <NewPasswordValidity
                  password={newPassword}
                  show={showNewPasswordValidity}
                />
                <Field
                  label={CorporateCognitoLogin.confirmNewPasswordLabel}
                  placeholder={
                    CorporateCognitoLogin.confirmNewPasswordPlaceholder
                  }
                  password
                  value={confirmNewPassword}
                  onChange={setConfirmNewPassword}
                  handleSubmit={handleSubmit}
                />
                <Field
                  label={CorporateCognitoLogin.verificationCodeLabel}
                  placeholder={
                    CorporateCognitoLogin.verificationCodePlaceholder
                  }
                  value={code}
                  onChange={setCode}
                  handleSubmit={handleSubmit}
                />
              </>
            )}
            {pageState === 'SUCCESS_RESET_PASSWORD' && <SuccessResetPassword />}
          </View>
          {showErrorMessage && (
            <Text
              style={styles.error}
              variant="ui-small"
              ink="destructive-interactive"
            >
              {errorMessage}
            </Text>
          )}
          {pageState !== 'SUCCESS_RESET_PASSWORD' && (
            <Button
              style={[styles.button]}
              innerStyle={styles.innerButton}
              text={
                pageState === 'SIGN_IN'
                  ? CorporateCognitoLogin.loginButtonText
                  : pageState === 'REQUEST_RESET_PASSWORD'
                  ? CorporateCognitoLogin.sendEmailButtonText
                  : CorporateCognitoLogin.resetPasswordButtonText
              }
              loading={isSubmit}
              disabled={buttonDisabled}
              onPress={handleSubmit}
            />
          )}
        </Card>
      </View>
      <ResendCodeModal
        isVisible={isResendCodeModalVisible}
        setIsVisible={setIsResendCodeModalVisible}
      />
    </>
  );
}

const CRQuery = {
  CorporateCognitoLogin: {
    emailFieldLabel: 'Email',
    emailFieldPlaceholder: 'yours@example.com',
    passwordFieldLabel: 'Password',
    passwordFieldPlaceholder: 'your password',
    forgotPasswordText: "Don't remember your password?",
    requestResetPasswordInfoText:
      'Please enter your email address. We will send you an email with a code to reset your password.',
    submitResetPasswordInfoText:
      'Please enter your new password and re-confirm your new password and input the code you receive from your email.',
    yourNewPasswordLabel: 'Your new password',
    yourNewPasswordPlaceholder: 'your new password',
    confirmNewPasswordLabel: 'Confirm new password',
    confirmNewPasswordPlaceholder: 'confirm new password',
    verificationCodeLabel: 'Verification code',
    verificationCodePlaceholder: '123456',
    loginButtonText: 'Log in',
    sendEmailButtonText: 'Send email',
    resetPasswordButtonText: 'Reset password',
    confirmPasswordNotMatchError:
      'New password and confirm new password is not match',
    emailFieldError: 'Please enter a valid email address format',
  },
};

const styles = StyleSheet.create({
  container: {
    width: '100vw',
    height: '100vh',
    backgroundColor: Token.color.uiLightNeutral,
  },
  wrapper: {
    width: 432,
    margin: 'auto',
    overflow: 'visible',
    paddingHorizontal: Token.spacing.l,
    paddingTop: Token.spacing.m,
    paddingBottom: Token.spacing.xl,
  },
  travelokaLogo: {
    marginBottom: 10,
    padding: 11,
    alignItems: 'center',
  },
  content: {
    alignItems: 'center',
  },
  toRequestResetPasswordText: {
    marginVertical: Token.spacing.xs,
    marginRight: 'auto',
  },
  infoText: {
    marginBottom: Token.spacing.m,
  },
  error: {
    marginVertical: Token.spacing.xs,
  },
  button: {
    color: '#fff',
    marginTop: Token.spacing.l,
  },
  innerButton: {
    width: 384,
  },
});

function isTravelokaEmail(email: string) {
  return isEmail(email) && email.endsWith('@traveloka.com');
}

function decryptQs(query: EncryptedQs): DecryptedQs | undefined {
  const obj: DecryptedQs = {};
  if (query.data) {
    const data = decodeBase64(decodeURIComponent(query.data));

    if (data) {
      obj.data = JSON.parse(data) as DecryptedQs['data'];
    }
  }

  if (query.nc) {
    obj.nc = query.nc;
  }

  if (Object.keys(obj).length === 0) {
    return undefined;
  }

  return obj;
}

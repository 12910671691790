import { RouteComponentProps } from 'react-router';

import { Permission } from '@traveloka/ctv-core';

import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

type Props = RouteComponentProps<{ policyId?: string }>;

const CompanyPolicy: PrivatePageComponent<Props> = function(props: Props) {
  return null;
};

CompanyPolicy.routeProtection = function({ user, params }) {
  const hasPermission = user.has(
    Permission.POLICY_CREATE,
    Permission.POLICY_UPDATE
  );

  if (!hasPermission) {
    return { path: '/' };
  }

  if (params.policyId !== undefined && Permission.POLICY_UPDATE) {
    return { path: '/company/permissions/policy/' + params.policyId };
  }

  return { path: '/company/permissions/policy' };
};

export default CompanyPolicy;

import React, { MutableRefObject, useRef } from 'react';
import { View, StyleSheet } from 'react-native';
import { FormProvider, useForm } from 'react-hook-form';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import WarningNotMatch from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_system_status_warning-fill_24px.svg';
import { Modal } from '@traveloka/web-components/future';
import {
  Popup,
  Token,
  Text,
  Icon,
  Button,
  useTheme,
} from '@traveloka/web-components';

import {
  NonMatchReason,
  NonMatchCategory,
} from 'approval-system/shared/api/types';
import { checkApprovalReason } from 'approval-system/shared/utils/validations';
import InputField from 'shared/components/form/InputField/InputField';
import { formatMessage } from 'shared/utils/intl';

type FormValue = {
  reason?: string;
};

type Props = {
  nonMatchReasons: NonMatchReason[];
  isNonCompliance: boolean;
  isNotMatch: boolean;
  isVisible: boolean;
  onClose: () => void;
  onContinue: (approverReason?: string) => void;
};

export default function HotelPaymentApprovalModal(props: Props) {
  const {
    isVisible,
    onClose,
    onContinue,
    isNonCompliance,
    isNotMatch,
    nonMatchReasons,
  } = props;

  const content = useContentResource()
    .CorporateApprovalSystemProductConfirmation;
  const { color } = useTheme();
  const methods = useForm<FormValue>();
  const { handleSubmit: formSubmit } = methods;

  const scrollStyle = {
    backgroundColor: color.lightPrimary,
  };

  function handleSubmit(form: FormValue) {
    onContinue(form.reason);
  }

  return (
    <Modal isVisible={isVisible}>
      <View style={[Style.scroll, scrollStyle]}>
        <Popup
          showCloseButton={false}
          width={640}
          maxWidth={640}
          title={content.paymentApprovalModalTitle}
        >
          <View style={Style.content}>
            {isNotMatch && (
              <Warning
                testID="hotel.issue.not-match.warning"
                text={content.warningNotMatchText}
              />
            )}
            {isNonCompliance && (
              <Warning
                testID="hotel.issue.non-comply.warning"
                text={content.warningNonCompliant}
              />
            )}
            {isNotMatch && (
              <>
                <Text style={Style.bookingDiscrepancyTitle}>
                  {content.bookingDiscrepancyTitle}
                </Text>
                <DiscrepancyTable nonMatchReasons={nonMatchReasons} />
              </>
            )}
            <Text style={Style.proceedBookingTitle} variant="title-2">
              {content.proceedBookingTitle}
            </Text>
            <FormProvider {...methods}>
              <InputField
                testID="hotel.issue.reason.input"
                inputStyle={Style.textarea}
                name="reason"
                multiline
                placeholder={content.inputPlaceholder}
                maxLength={100}
                validate={value => {
                  if (!value) {
                    return content.reasonEmptyValidationText;
                  }
                  if (checkApprovalReason(value)) {
                    return content.reasonInvalidSymbolValidationText;
                  }
                  return undefined;
                }}
              />

              <View style={Style.footer}>
                <Button
                  testID="hotel.issue.close.button"
                  style={Style.buttonCancel}
                  text={content.cancelButtonText}
                  variant="secondary"
                  onPress={onClose}
                />
                <Button
                  testID="hotel.issue.submit.button"
                  text={content.continueButtonText}
                  variant="main-cta"
                  onPress={formSubmit(handleSubmit)}
                />
              </View>
            </FormProvider>
          </View>
        </Popup>
      </View>
    </Modal>
  );
}

type WarningProps = {
  testID?: string;
  text: string;
};

function Warning({ testID, text }: WarningProps) {
  return (
    <View testID={testID} style={Style.warning}>
      <Icon src={WarningNotMatch} />
      <Text style={Style.warningText} variant="ui-baseline" ink="secondary">
        {text}
      </Text>
    </View>
  );
}

type DiscrepancyTableProps = {
  nonMatchReasons: NonMatchReason[];
};

function DiscrepancyTable(props: DiscrepancyTableProps) {
  const { nonMatchReasons } = props;

  const content = useContentResource()
    .CorporateApprovalSystemProductConfirmation;

  const isTripDateShown = useRef<boolean>(false);

  return (
    <View style={Style.tableContainer}>
      <View style={Style.tableRow}>
        <View style={Style.tableColHeader}></View>
        <Text style={Style.tableCol} variant="title-3">
          {content.requestedTripHeader}
        </Text>
        <Text style={Style.tableCol} variant="title-3">
          {content.thisBookingHeader}
        </Text>
      </View>
      {nonMatchReasons.map((reason, index) => (
        <DiscrepancyItem
          key={index}
          reason={reason}
          isTripDateShown={isTripDateShown}
        />
      ))}
    </View>
  );
}

type DiscrepancyItemProps = {
  reason: NonMatchReason;
  isTripDateShown: MutableRefObject<boolean>;
};

function DiscrepancyItem(props: DiscrepancyItemProps) {
  const { reason, isTripDateShown } = props;

  const content = useContentResource()
    .CorporateApprovalSystemProductConfirmation;

  switch (reason.category) {
    case NonMatchCategory.DESTINATION:
      return (
        <RowValue
          testID="hotel.issue.discrepancy.destination"
          title={content.destination}
          productRequestValue={reason.productRequest[0]}
          bookingValue={reason.booking[0]}
        />
      );
    case NonMatchCategory.GUESTS:
      return (
        <RowValue
          testID="hotel.issue.discrepancy.guests"
          title={content.guests}
          productRequestValue={reason.productRequest.join('\n')}
          bookingValue={reason.booking.join('\n')}
        />
      );

    case NonMatchCategory.OUTSIDE_PLANNED_TRIP_DATE_CHECK_IN:
      isTripDateShown.current = true;
      return (
        <RowValue
          testID="hotel.issue.discrepancy.outside-planned-trip-date-check-in"
          title={content.plannedTripDate}
          productRequestValue={`${reason.productRequest[0]} ${content.fromOldDateUnit}`}
          bookingValue={`${reason.booking[0]} ${content.fromNewDateUnitHotel}`}
          alignCenter
        />
      );

    case NonMatchCategory.OUTSIDE_PLANNED_TRIP_DATE_CHECK_OUT:
      return (
        <RowValue
          testID="hotel.issue.discrepancy.-outside-planned-trip-date-check-out"
          title={isTripDateShown.current ? '' : content.plannedTripDate}
          productRequestValue={`${reason.productRequest[0]} ${content.toOldDateUnit}`}
          bookingValue={`${reason.booking[0]} ${content.toNewDateUnitHotel}`}
          alignCenter
        />
      );
    case NonMatchCategory.NO_OF_ROOM:
      return (
        <RowValue
          testID="hotel.issue.discrepancy.no-of-room"
          title={content.numOfRooms}
          productRequestValue={`${reason.productRequest[0]} ${content.roomUnit}`}
          bookingValue={`${reason.booking[0]} ${content.roomUnit}`}
        />
      );
    case NonMatchCategory.NO_OF_GUEST:
      return (
        <RowValue
          testID="hotel.issue.discrepancy.no-of-guest"
          title={content.numOfGuestsV2}
          productRequestValue={`${reason.productRequest[0]} ${content.guestUnit}`}
          bookingValue={`${reason.booking} ${content.guestUnit}`}
        />
      );
    case NonMatchCategory.NO_OF_ADULT:
      return (
        <RowValue
          testID="hotel.issue.discrepancy.no-of-adult"
          title={content.numOfAdults}
          productRequestValue={formatMessage(content.adultUnit, {
            num: reason.productRequest[0],
          })}
          bookingValue={formatMessage(content.adultUnit, {
            num: reason.booking[0],
          })}
        />
      );
    case NonMatchCategory.NO_OF_CHILD:
      return (
        <RowValue
          testID="hotel.issue.discrepancy.no-of-child"
          title={content.numOfChildren}
          productRequestValue={formatMessage(content.childUnit, {
            num: reason.productRequest[0],
          })}
          bookingValue={formatMessage(content.childUnit, {
            num: reason.booking[0],
          })}
        />
      );
  }

  return null;
}

type RowValueProps = {
  testID?: string;
  title: string;
  productRequestValue: string;
  bookingValue: string;
  alignCenter?: boolean;
};

function RowValue({
  testID,
  title,
  productRequestValue,
  bookingValue,
  alignCenter,
}: RowValueProps) {
  return (
    <View style={Style.tableRow}>
      <View style={Style.tableColHeader}>
        <Text
          testID={appendTestId(testID, 'title')}
          style={Style.tableHeaderText}
          variant="title-3"
        >
          {title}
        </Text>
      </View>
      <View
        style={[Style.tableCol, alignCenter && { justifyContent: 'center' }]}
      >
        <Text
          testID={appendTestId(testID, 'product-request')}
          variant="ui-small"
          ink="secondary"
        >
          {productRequestValue}
        </Text>
      </View>
      <View
        style={[Style.tableCol, alignCenter && { justifyContent: 'center' }]}
      >
        <Text
          testID={appendTestId(testID, 'booking-value')}
          variant="ui-small"
          ink="secondary"
        >
          {bookingValue}
        </Text>
      </View>
    </View>
  );
}

type GenericHotelData = {
  employees: string[];
  fromDate: Date;
  toDate: Date;
  destination: string;
  numOfRoom: string;
  numOfGuest: string;
};

const Style = StyleSheet.create({
  content: {
    marginTop: -Token.spacing.ml,
    paddingHorizontal: Token.spacing.xs,
    paddingBottom: Token.spacing.xs,
  },
  scroll: {
    flexShrink: 1,
    flexGrow: 0,
    borderRadius: Token.border.radius.normal,
    overflowY: 'auto',
  },
  title: {
    marginBottom: Token.spacing.xs,
  },
  warning: {
    paddingVertical: Token.spacing.m,
    flexDirection: 'row',
    alignItems: 'center',
  },
  warningText: {
    marginLeft: Token.spacing.m,
  },
  bookingDiscrepancyTitle: {
    marginTop: Token.spacing.xl,
  },
  tableContainer: {
    marginBottom: Token.spacing.xs,
  },
  tableRow: {
    paddingVertical: Token.spacing.ml,
    flexDirection: 'row',
  },
  tableColHeader: {
    flex: 1,
  },
  tableHeaderText: {
    maxWidth: 120,
  },
  tableCol: {
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
  },
  proceedBookingTitle: {
    marginVertical: Token.spacing.l,
  },
  textarea: {
    height: 88,
  },
  footer: {
    marginTop: Token.spacing.xs,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonCancel: {
    marginRight: Token.spacing.m,
  },
});

import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useApi, useContentResource } from '@traveloka/ctv-core';
import {
  Button,
  LoadingIndicator,
  Text,
  Token,
} from '@traveloka/web-components';

import IcSystemStatusOkDone from '@traveloka/icon-kit-web/svg/greenPrimary/ic_system_status_ok_done_24px.svg';

import IcBagBaggage from '@traveloka/icon-kit-web/svg/darkBlue/ic_bag_baggage_24px.svg';
import IcBagBaggageMuted from '@traveloka/icon-kit-web/svg/lightSecondary/ic_bag_baggage_24px.svg';
import IcPaymentCreditCard from '@traveloka/icon-kit-web/svg/darkBlue/ic_payment_credit_card_24px.svg';
import IcPaymentCreditCardMuted from '@traveloka/icon-kit-web/svg/lightSecondary/ic_payment_credit_card_24px.svg';
import IcPolicyCheck from '@traveloka/icon-kit-web/svg/darkBlue/ic_policy_check_24px.svg';
import IcPolicyCheckMuted from '@traveloka/icon-kit-web/svg/lightSecondary/ic_policy_check_24px.svg';
import IcUserAccount from '@traveloka/icon-kit-web/svg/darkBlue/ic_user_account_24px.svg';
import IcUserAccountMuted from '@traveloka/icon-kit-web/svg/lightSecondary/ic_user_account_24px.svg';

import {
  RegistrationStatusRequest,
  RegistrationStatusResponse,
  REGISTRATION_STATUS_API,
} from 'registration/api';
import { PaymentMethod, StepData } from 'registration/api/types';
import { usePreRegisterConfirm } from 'registration/contexts/PreRegisterConfirmContext';
import { useToken } from 'registration/contexts/TokenContext';

import { ProcessStep } from '../RegistrationProcess';

import RenderVerificationStep from './RenderVerificationStep';

type State =
  | {
      isLoading: false;
      data?: RegistrationStatusResponse;
    }
  | {
      isLoading: true;
    };

type Props = {
  status: Exclude<ProcessStep, 'INITIAL_PROCESS'>;
};

export default function VerificationStepsContent(props: Props) {
  const { status } = props;

  const [state, setState] = useState<State>({
    isLoading: true,
  });
  const data = usePreRegisterConfirm();
  const token = useToken();
  const cr = useContentResource().CorporateRegistration;
  const commonCr = useContentResource().CorporateCommon;

  const registrationStatus = useApi<
    RegistrationStatusResponse,
    RegistrationStatusRequest
  >({
    domain: 'management',
    method: 'post',
    path: REGISTRATION_STATUS_API,
  });

  useEffect(() => {
    registrationStatus({
      token,
    })
      .then(res => {
        if (res.success) {
          setState({
            isLoading: false,
            data: res.data,
          });
        } else {
          setState({
            isLoading: false,
          });
        }
      })
      .catch(e => {
        console.error(e);
        setState({
          isLoading: false,
        });
      });
  }, []);

  function stepMap(step: StepData) {
    const mapping = {
      REGISTER_FORM: {
        label: cr.registrationForm,
        info: cr.verificationDefaultInfo,
        desc: cr.registrationFormDesc,
        icon: IcSystemStatusOkDone,
      },
      VERIFICATION: {
        label: cr.companyVerification,
        info:
          data?.paymentMethod === PaymentMethod.INVOICE
            ? cr.verificationCompanyVerifInvoiceInfo
            : cr.verificationCompanyVerifCCInfo,
        desc: cr.companyVerificationDesc,
        icon: step.status === 'ON_GOING' ? IcBagBaggage : IcBagBaggageMuted,
      },
      CREDIT_ASSESSMENT: {
        label: cr.creditAssessment,
        info: cr.verificationCreditAssessmentInfo,
        desc: cr.creditAssessmentDesc,
        icon:
          step.status === 'ON_GOING'
            ? IcPaymentCreditCard
            : IcPaymentCreditCardMuted,
      },
      AGREEMENT_REVIEW: {
        label: cr.agreementSigning,
        info: cr.verificationAgreementSigningInfo,
        desc: cr.agreementSigningDesc,
        icon: step.status === 'ON_GOING' ? IcPolicyCheck : IcPolicyCheckMuted,
      },
      ACCOUNT_ACTIVATION: {
        label: cr.preparingAccount,
        info: cr.verificationCompleteInfo,
        desc: cr.preparingAccountDesc,
        icon: step.status === 'ON_GOING' ? IcUserAccount : IcUserAccountMuted,
      },
    };

    const choosen = { ...mapping[step.step] };

    if (step.status === 'COMPLETED') {
      choosen.icon = IcSystemStatusOkDone;
    }

    return choosen;
  }

  if (state.isLoading) {
    return (
      <View style={styles.centerContainer}>
        <LoadingIndicator />
      </View>
    );
  }

  if (!state.data) {
    return (
      <View style={styles.centerContainer}>
        <Text variant="ui-small">{commonCr.errorMessage}</Text>
      </View>
    );
  }

  if (status === 'REJECTED') {
    const { rejectionReason, notes } = state.data;

    const rejectionMap: Record<string, string> = {
      FRAUD: cr.rejectionFraudDesc,
      DOUBLE_DATA: cr.rejectionDoubleDataDesc,
      LEGAL_REVISION: cr.rejectionLegalAssessmentDesc,
      CREDIT_ASSESSMENT: cr.rejectionCreditAssessmentDesc,
      TRAVEL_AGENT: cr.rejectionTravelAgentDesc,
    };

    const reason = rejectionMap[rejectionReason];

    if (reason === undefined) {
      return null;
    }

    return (
      <>
        <Text style={styles.reasonInfo} variant="ui-small">
          {reason}
        </Text>
        {rejectionReason === 'TRAVEL_AGENT' ? (
          <Button
            variant="main-cta"
            text={cr.rejectionTravelAgentLearnMoreButton}
            onPress={() =>
              window.open('https://www.traveloka.com/en-id/affiliate', '_blank')
            }
          />
        ) : (
          <>
            <Text
              style={[styles.amNotes, Token.typography.weightMedium]}
              variant="ui-small"
            >
              {cr.rejectionAmNotes}
            </Text>
            <Text variant="ui-small">{notes}</Text>
          </>
        )}
      </>
    );
  }

  let currentInfo = cr.verificationDefaultInfo;
  for (let i = state.data.steps.length - 1; i >= 0; i--) {
    const step = state.data.steps[i];
    if (step.status === 'COMPLETED') {
      currentInfo = stepMap(step).info;
      break;
    }
  }

  return (
    <>
      <Text style={styles.info} variant="ui-small">
        {currentInfo}
      </Text>
      {state.data.steps.map((step, index) => (
        <>
          {index !== 0 && <View style={styles.spacing} />}
          <RenderVerificationStep status={step.status} {...stepMap(step)} />
        </>
      ))}
    </>
  );
}

const styles = StyleSheet.create({
  centerContainer: {
    alignItems: 'center',
  },
  spacing: {
    marginTop: Token.spacing.l,
  },
  reasonInfo: {
    marginBottom: Token.spacing.m,
  },
  amNotes: {
    marginBottom: Token.spacing.xs,
  },
  info: {
    marginBottom: Token.spacing.m,
  },
});

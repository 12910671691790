import { useRef } from 'react';

import { useActiveDetail, ActiveDetail } from '../contexts/DetailContext';

type ToggleBehaviorMap = Record<ActiveDetail, number>;

export default function useAccentDefault(
  behaviorValueMap: Omit<ToggleBehaviorMap, 'none'>
): ToggleBehaviorMap {
  const activeDetail = useActiveDetail();
  const prevActiveDetail = useRef(activeDetail);

  const map = {
    none: 0,
    ...behaviorValueMap,
  };
  const defaultValue = map[prevActiveDetail.current] || map[activeDetail];

  prevActiveDetail.current = activeDetail;

  return Object.assign(map, { none: defaultValue });
}

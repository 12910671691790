import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useApi, useAuth } from '@traveloka/ctv-core';
import { Traveler } from '@traveloka/ctvweb-ui/src/generic/TravelerPickerModal/types';

import {
  PRODUCT_REQUEST_DETAIL_API,
  ProductRequestDetailRequest,
  ProductRequestDetailResponse,
} from 'approval-system/shared/api';
import {
  useSearchSpec as useFlightSearchSpec,
  useSearchSpecDispatch as useFlightSearchSpecDispatch,
} from 'flight/search/contexts/SearchSpecContext';
import { useStaticData } from 'flight/search/contexts/StaticDataContext';
import {
  parseSearchSpec as parseFlightSearchSpec,
  stringifySearchSpec as stringifyFlightSearchSpec,
} from 'flight/search/utils/flight-search-query-util';
import {
  FlightTrip,
  HotelTrip,
} from 'generic/postlogin/contexts/ProductSearchContext';
import {
  parseSearchSpec as parseHotelSearchSpec,
  stringifySearchSpec as stringifyHotelSearchSpec,
} from 'hotel/search/utils/SearchQueryUtil';
import {
  ComputedSearchSpec,
  useSearchSpec as useHotelSearchSpec,
  useSearchSpecDispatch as useHotelSearchSpecDispatch,
} from 'hotel/shared/contexts/SpecContext';
import useEagerNavigation from 'shared/hooks/useEagerNavigation';

import ProductRequestFooter from '../components/ProductRequestFooter/ProductRequestFooter';
import ProductType from '../constants/ProductType';

type Context = {
  enabled: boolean;
  data?: FlightTrip | HotelTrip;
};

type Props = {
  tripRequestId?: string;
  type: ProductType;
  mainBooker?: Traveler;
};

const ProductRequestContext = createContext<Context>(undefined!);

export function ProductRequestProvider(props: PropsWithChildren<Props>) {
  const [productRequestDetail, setProductRequestDetail] = useState<Context>({
    enabled: true,
    data: undefined,
  });

  const { user } = useAuth();
  const navigate = useEagerNavigation();
  const flightSearchSpec = useFlightSearchSpec();
  const setFlightSearchSpec = useFlightSearchSpecDispatch();
  const hotelSearchSpec = useHotelSearchSpec();
  const setHotelSearchSpec = useHotelSearchSpecDispatch();
  const { airportMap, areaAirportsMap } = useStaticData();

  const fetchProductRequestDetail = useApi<
    ProductRequestDetailResponse,
    ProductRequestDetailRequest
  >({
    domain: 'management',
    method: 'post',
    path: PRODUCT_REQUEST_DETAIL_API,
  });

  useEffect(() => {
    if (!props.tripRequestId) {
      setProductRequestDetail({
        enabled: !!user?.privateTripRequestApproval,
        data: undefined,
      });
      return;
    }

    fetchProductRequestDetail({
      tripRequestId: props.tripRequestId,
      type: props.type,
    }).then(res => {
      const searchSpecMainBooker = props.mainBooker;
      if (!res.success || !res.data.productRequestId || !searchSpecMainBooker) {
        setProductRequestDetail({
          enabled: !!user?.privateTripRequestApproval,
          data: undefined,
        });
      } else {
        const tripRequestMainBooker = res.data.travelers[0];
        const isDifferentMainBooker =
          tripRequestMainBooker.email !== searchSpecMainBooker.email;

        switch (res.data.type) {
          case ProductType.FLIGHT:
            if (isDifferentMainBooker) {
              const stringifySpec = stringifyFlightSearchSpec({
                ...flightSearchSpec,
                passengers: res.data.travelers,
              });
              navigate({
                pathname: '/flight/search',
                search: stringifySpec,
              });
              setFlightSearchSpec(
                parseFlightSearchSpec(
                  stringifySpec,
                  airportMap,
                  areaAirportsMap
                )
              );
            }
            setProductRequestDetail({
              enabled: !!user?.privateTripRequestApproval,
              data: {
                ...res.data,
                nonEmployeeTravelers: {
                  adults: Number(res.data.nonEmployeeTravelers.adults),
                  children: Number(res.data.nonEmployeeTravelers.children),
                  infants: Number(res.data.nonEmployeeTravelers.infants),
                },
              },
            });
            break;
          case ProductType.HOTEL:
            if (isDifferentMainBooker) {
              const stringifySpec = stringifyHotelSearchSpec({
                ...hotelSearchSpec,
                travelers: res.data.travelers,
              } as Required<ComputedSearchSpec>);
              navigate({
                pathname:
                  res.data.geoType === 'HOTEL'
                    ? '/hotel/detail'
                    : '/hotel/search',
                search: stringifySpec,
              });
              setHotelSearchSpec(parseHotelSearchSpec(stringifySpec));
            }
            setProductRequestDetail({
              enabled: !!user?.privateTripRequestApproval,
              data: {
                ...res.data,
                nonEmployeeTravelers: {
                  adults: Number(res.data.nonEmployeeTravelers.adults),
                  childrenAges: res.data.nonEmployeeTravelers.childrenAges,
                },
              },
            });
            break;
        }
      }
    });
  }, []);

  return (
    <ProductRequestContext.Provider value={productRequestDetail}>
      {props.children}
      <ProductRequestFooter productType={props.type} />
    </ProductRequestContext.Provider>
  );
}

export function useProductRequest() {
  return useContext(ProductRequestContext);
}

import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import {
  FlightSearchEmpty,
  FlightSearchResultSkeleton,
} from '@traveloka/ctvweb-ui/flight';
import { Token } from '@traveloka/web-components';

import { FlightSortFilterProvider } from 'flight/search/contexts/FlightSortFilterContext';
import { FlightSearchData } from 'flight/search/utils/flight-search-view-util';

import FlightSearchResultList from '../FlightSearchResultList/FlightSearchResultList';

type Props = {
  isReturnFlight: boolean;
  isLoading: boolean;
  scState?: {
    departIsScAndHasNonSc: boolean;
    toSmartComboPage: boolean;
    toNonSmartComboPage: boolean;
    setState: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    selectedDepart: FlightSearchData;
  };
  results?: FlightSearchData[];
  onSelect: (e: FlightSearchData) => void;
};

function FlightSearchResultBlock(props: Props) {
  const { CorporateFlightSearch } = useContentResource();
  const { isReturnFlight, isLoading, scState, results, onSelect } = props;
  const content = {
    noFlights: CorporateFlightSearch.noFlights,
    pleaseModify: CorporateFlightSearch.pleaseModify,
  };

  useEffect(() => {
    scState?.setLoading(false);
  }, [isLoading]);

  if (isLoading || !results) {
    return (
      <>
        {Array.from({ length: 3 }, (_, index) => (
          <FlightSearchResultSkeleton
            key={index}
            compactLayout={isReturnFlight}
            style={Style.searchRow}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {!results.length && (
        <FlightSearchEmpty
          mainText={content.noFlights}
          subText={content.pleaseModify}
        />
      )}

      {!!results.length && (
        <FlightSortFilterProvider results={results}>
          <FlightSearchResultList
            scState={scState}
            isReturnFlight={isReturnFlight}
            onSelect={onSelect}
          />
        </FlightSortFilterProvider>
      )}
    </>
  );
}

FlightSearchResultBlock.defaultProps = {
  isReturnFlight: false,
};

const Style = StyleSheet.create({
  searchRow: {
    marginBottom: Token.spacing.m,
  },
});

export default FlightSearchResultBlock;

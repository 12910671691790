import React, { Fragment, MutableRefObject, useEffect, useRef } from 'react';
import {
  Animated,
  Easing,
  LayoutChangeEvent,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';

import { Text, Icon, useTheme, Token } from '@traveloka/web-components';

import { appendTestId } from '../../utils/TestUtil';

// Types
type Props = {
  /**
   * Indicates active tab index
   */
  activeTabIndex: number;
  /**
   * Indicates if tab bar should overflow the parent's width and is horizontally scrollable
   *
   * @default false
   */
  isScrollable?: boolean;
  /**
   * Tab items to be rendered. Key prop is used as the key for mapping the items array
   */
  items: Array<{
    key: string;
    text: string;
    icon: string;
  }>;
  /**
   * Event handler to be called whenever active tab index value changes
   */
  onChange: (index: number) => void;
  /**
   * TestID prefix for each tab
   */
  testIDPrefix?: string;
};

// Component
function TabBarWithIcon(props: Props) {
  const { activeTabIndex, isScrollable, items, onChange, testIDPrefix } = props;

  const isFirstRender = useRef(true);
  const scrollRef: MutableRefObject<ScrollView | null> = useRef(null);
  const tabRefs = useRef(Array(items.length));
  const scaleX = useRef(new Animated.Value(0));
  const translateX = useRef(new Animated.Value(0));

  const { color } = useTheme();

  function handleLayoutChange(index: number) {
    return (e: LayoutChangeEvent) => {
      if (isScrollable === false && index === activeTabIndex) {
        const x = e.nativeEvent.layout.x;
        const width = e.nativeEvent.layout.width;

        translateX.current.setValue(x);
        scaleX.current.setValue(width);
      }
    };
  }

  function handleTabPress(index: number) {
    return () => {
      if (typeof onChange === 'function' && activeTabIndex !== index) {
        onChange(index);
      }
    };
  }

  function setScrollViewRef(component: ScrollView) {
    if (scrollRef.current === null) {
      scrollRef.current = component;
    }
  }

  function setTabRef(index: number) {
    return (component: View) => {
      tabRefs.current[index] = component;
    };
  }

  useEffect(() => {
    tabRefs.current[activeTabIndex].measure(
      (x: number, y: number, width: number) => {
        if (isFirstRender.current === true) {
          translateX.current.setValue(x);
          scaleX.current.setValue(width);

          isFirstRender.current = false;
        } else {
          Animated.parallel([
            Animated.timing(translateX.current, {
              duration: Token.animation.timing.normal,
              easing: Easing.out(Easing.ease),
              toValue: x,
            }),
            Animated.timing(scaleX.current, {
              duration: Token.animation.timing.normal,
              easing: Easing.out(Easing.ease),
              toValue: width,
            }),
          ]).start();
        }

        if (scrollRef.current !== null) {
          scrollRef.current.scrollTo({ x, y: 0, animated: true });
        }
      }
    );
  }, [activeTabIndex]);

  const content = (
    <Fragment>
      <View style={{ flexDirection: 'row' }}>
        {items.map((tab, index) => (
          <View
            key={tab.key}
            ref={setTabRef(index)}
            style={[
              { marginRight: Token.spacing.l },
              // In the first render, we highlight the active tab using border bottom
              // So that the indicator will show instantly since measuring the tab x position and width happens asynchronously
              // We change border color to transparent instead of removing the border to avoid triggering onLayout()
              isFirstRender.current === true && index === activeTabIndex
                ? [
                    Style.borderActive,
                    {
                      borderBottomColor: color.tintPrimary,
                    },
                  ]
                : Style.borderTransparent,
            ]}
            onLayout={handleLayoutChange(index)}
          >
            <TouchableOpacity
              style={Style.tab}
              onPress={handleTabPress(index)}
              testID={appendTestId(testIDPrefix, tab.key)}
            >
              <Icon
                style={{ marginRight: Token.spacing.xxs }}
                src={tab.icon}
                width={16}
                height={16}
              />
              <Text
                ink={
                  index === activeTabIndex ? 'primary-interactive' : 'secondary'
                }
                variant="ui-tiny"
                style={[Token.typography.weightMedium, Style.tabText]}
              >
                {tab.text}
              </Text>
            </TouchableOpacity>
          </View>
        ))}
      </View>
      <Animated.View
        style={[
          Style.indicator,
          {
            transform: [
              { translateX: translateX.current },
              { scaleX: scaleX.current },
            ],
            backgroundColor: color.tintPrimary,
          },
          // Hide the indicator component in the first render
          isFirstRender.current === true && { display: 'none' },
        ]}
      />
    </Fragment>
  );

  return isScrollable === true ? (
    <ScrollView
      ref={setScrollViewRef}
      horizontal
      style={[
        Style.tabBar,
        {
          borderBottomColor: color.lightNeutral,
          borderTopColor: color.lightNeutral,
        },
      ]}
    >
      <View>{content}</View>
    </ScrollView>
  ) : (
    <View
      style={[
        Style.tabBar,
        {
          borderBottomColor: color.lightNeutral,
          borderTopColor: color.lightNeutral,
        },
      ]}
    >
      {content}
    </View>
  );
}

TabBarWithIcon.defaultProps = {
  isScrollable: false,
};

const Style = StyleSheet.create({
  tabBar: {
    borderBottomWidth: Token.border.width.thick,
    borderTopWidth: Token.border.width.thick,
  },
  tab: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: Token.spacing.s,
  },
  tabText: {
    fontSize: Token.typography.fontSize.small,
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  indicator: {
    height: Token.border.width.bold,
    position: 'absolute',
    bottom: 0,
    transformOrigin: 'left top',
    width: 1,
  },
  borderActive: {
    borderBottomWidth: Token.border.width.bold,
  },
  borderTransparent: {
    borderBottomColor: 'transparent',
    borderBottomWidth: Token.border.width.bold,
  },
});

export default TabBarWithIcon;

import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { useTheme, Popup, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import { DetailProvider } from '../FlightSearchResultItem/contexts/DetailContext';
import { FlightData } from '../types';
import { PropsProvider } from './contexts/PropsContext';
import FlightDetail from './fragments/FlightDetail';
import FlightSummary from './fragments/FlightSummary';
import { appendTestId } from '../../shared/utils/TestUtil';

export type Props = {
  flights: Array<FlightData & { flightLabel: string }>;
  isVisible?: boolean;
  onCloseButtonPress(): void;
  onContinuePress(): void;
  onModalHide?(): void;
  totalPrice: string;
  label: {
    button: string;
    duration: string;
    fareDetail: string;
    flightDetail: string;
    priceHelper: string;
    priceSuffix: string;
    title: string;
  };
  testID?: string;
};

export default function RoundTripSummaryModal(props: Props) {
  const { testID, isVisible, label, onCloseButtonPress, onModalHide } = props;
  const { color } = useTheme();

  const flightSummary = useMemo(
    () => <FlightSummary testID={appendTestId(testID, 'content')} />,
    []
  );
  const flightDetail = useMemo(() => <FlightDetail />, []);

  const scrollStyle = {
    backgroundColor: color.lightPrimary,
  };

  return (
    <PropsProvider value={props}>
      <DetailProvider>
        <Modal isVisible={isVisible} onModalHide={onModalHide}>
          <View testID={testID} style={[Style.scroll, scrollStyle]}>
            <Popup
              title={label.title}
              showCloseButton
              width={860}
              maxWidth={860}
              onCloseButtonPress={onCloseButtonPress}
            >
              {flightSummary}
              {flightDetail}
            </Popup>
          </View>
        </Modal>
      </DetailProvider>
    </PropsProvider>
  );
}

const Style = StyleSheet.create({
  scroll: {
    flexShrink: 1,
    flexGrow: 0,
    borderRadius: Token.border.radius.normal,
    overflowY: 'auto',
  },
});

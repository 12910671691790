import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Keyboard,
  NativeSyntheticEvent,
  StyleSheet,
  TextInputKeyPressEventData,
  View,
} from 'react-native';

import ChevronDownIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';
import ChevronDownIconGray from '@traveloka/icon-kit-web/svg/lightSecondary/ic_system_chevron_down_16px.svg';
import { Icon, Token } from '@traveloka/web-components';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { appendTestId } from '../../../utils/TestUtil';
import Fade from '../../Fade/Fade';
import Input, { Props as InputProps } from '../Input/Input';
import InputDropdownList, {
  Props as InputDropdownListProps,
} from './InputDropdownList';
import { Item } from './types';

export type Props<T> = {
  width?: number;
  onPressItem?: InputDropdownListProps<T>['onPressItem'];
  items: T[];
  searchable?: boolean;
  position?: 'down' | 'up';
  dropdownHeight?: number;
  ItemComponent?: FC<any>;
  // TODO Chore: Need to check all usage if this props logic can be implemented permanently
  labelAsInputValue?: boolean;
} & InputProps;

export default function InputDropdown<T extends Item>(props: Props<T>) {
  const {
    items,
    onChangeText = noop,
    onPressItem = noop,
    value,
    style,
    width,
    editable,
    searchable,
    position = 'down',
    testID,
    dropdownHeight,
    ItemComponent,
    labelAsInputValue = false,
    ...inputProps
  } = props;
  const rootRef = useRef<View>(null);
  const itemsLength = useRef(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterText, setFilterText] = useState<string>('');
  const [filterItems, setFilterItems] = useState<T[]>([]);

  const itemMap = useMemo(() => {
    return items.reduce((obj, info) => {
      obj[info.value] = info.label;

      return obj;
    }, {} as Dictionary<string>);
  }, [items]);

  function switchPosition() {
    switch (position) {
      case 'down':
        return Style.positionDown;
      case 'up':
        return Style.positionUp;
    }
  }

  function handleFocus() {
    setActiveIndex(0);
    setShowDropdown(true);
    handleChangeText('');
  }

  function handlePressItem(item: T) {
    setShowDropdown(false);
    onPressItem(item);
  }

  function filter(text: string) {
    if (searchable) {
      const list = items.filter(item => {
        if (item.label.toLocaleLowerCase().includes(text.toLowerCase())) {
          return true;
        } else if (item.subLabel) {
          return item.subLabel.toLocaleLowerCase().includes(text.toLowerCase());
        }

        return false;
      });

      setFilterItems(list);
    }
  }

  function handleChangeText(text: string) {
    filter(text);
    setFilterText(text);
    onChangeText(text);
  }

  function moveCursor(increment: number) {
    let newIndex = activeIndex + increment;

    if (newIndex < 0) {
      newIndex = itemsLength.current - 1;
    } else if (newIndex >= itemsLength.current) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  }

  function handleKeyPress(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    switch (e.nativeEvent.key) {
      case 'ArrowUp':
        moveCursor(-1);
        break;
      case 'ArrowDown':
        moveCursor(+1);
        break;
      case 'Escape':
      case 'Tab':
        Keyboard.dismiss();
        setShowDropdown(false);
        break;
      case 'Enter':
        if (filterItems[activeIndex]) {
          handlePressItem(filterItems[activeIndex]);
        } else {
          setShowDropdown(false);
        }
        break;
    }
    return;
  }

  useOnClickOutside(rootRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    setFilterItems(prev => (prev === items ? prev : items));
  }, [setFilterItems, items]);

  useEffect(() => {
    itemsLength.current = filterItems.length;
  }, [filterItems]);

  let shownValue = value;
  if (showDropdown && editable) {
    shownValue = filterText;
  } else if (labelAsInputValue && value) {
    shownValue = itemMap[value];
  }

  return (
    <View ref={rootRef} style={style}>
      <Input
        {...inputProps}
        testID={appendTestId(testID, 'input')}
        editable={editable}
        onFocus={handleFocus}
        onChangeText={handleChangeText}
        onKeyPress={handleKeyPress}
        value={shownValue}
        iconRight={
          inputProps.disabled ? (
            <Icon src={ChevronDownIconGray} />
          ) : (
            <Icon src={ChevronDownIcon} />
          )
        }
      />
      <Fade
        visible={showDropdown}
        style={[Style.dropdown, switchPosition(), { width }]}
      >
        <InputDropdownList
          testID={testID}
          items={filterItems}
          onPressItem={handlePressItem}
          activeIndex={activeIndex}
          dropdownHeight={dropdownHeight}
          ItemComponent={ItemComponent}
        />
      </Fade>
    </View>
  );
}

const Style = StyleSheet.create({
  dropdown: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 10,
  },
  positionDown: {
    paddingTop: Token.spacing.xs,
    top: '100%',
  },
  positionUp: {
    bottom: '100%',
  },
});

// ===== HELPERS
function noop() {}

import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { NonEmployeeField, Traveler } from '../RevampTravelerPickerModal';

type Context = {
  nonEmployeeTravelers: NonEmployeeField[] | undefined;
  setNonEmployeeTravelers: Dispatch<
    SetStateAction<NonEmployeeField[] | undefined>
  >;
  hasNonEmployee: boolean;

  travelers: Traveler[];
  onTravelerChange(employee: Traveler): void;

  isVisible: boolean;
  isNonEmployeeChecked: boolean;
  setIsNonEmployeeChecked: Dispatch<SetStateAction<boolean>>;
  tripRequestEnabled: boolean;
  maximumTraveler: number;
  hasError: boolean[];
  setHasError: Dispatch<SetStateAction<boolean[]>>;
  isOpen: CollapsibleVisibility;
  handleIsOpen(value: CollapsibleVisibility, isKeepValue?: boolean): void;
  isChildAgeAutoFill: AutoFillState;
  setIsChildAgeAutoFill: Dispatch<SetStateAction<AutoFillState>>;
};

export type CollapsibleVisibility = 'NON_EMPLOYEE' | 'EMPLOYEE' | undefined;
type AutoFillState = { value: boolean; stopReRender: boolean };

const NonEmployeeContext = createContext<Context>(null!);

type Props = Omit<
  Context,
  | 'hasError'
  | 'setHasError'
  | 'isOpen'
  | 'handleIsOpen'
  | 'isChildAgeAutoFill'
  | 'setIsChildAgeAutoFill'
>;
export function NonEmployeeProvider(props: PropsWithChildren<Props>) {
  const { children, ...rest } = props;
  const [hasError, setHasError] = useState<boolean[]>([]);

  const [isOpen, setIsOpen] = useState<CollapsibleVisibility>(
    rest.hasNonEmployee ? 'NON_EMPLOYEE' : undefined
  );
  const [isChildAgeAutoFill, setIsChildAgeAutoFill] = useState<AutoFillState>({
    value: false,
    stopReRender: false,
  });

  function handleIsOpen(value: CollapsibleVisibility, isKeepValue?: boolean) {
    if (isKeepValue) {
      setIsOpen(value);
      return;
    }
    setIsOpen(isOpen === value ? undefined : value);
  }

  const firstLoad = useRef<boolean>(true);
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    setIsOpen(rest.hasNonEmployee ? 'NON_EMPLOYEE' : undefined);
    setIsChildAgeAutoFill({ value: false, stopReRender: false });
  }, [rest.isVisible]);

  return (
    <NonEmployeeContext.Provider
      value={{
        ...rest,
        hasError,
        setHasError,
        isOpen,
        handleIsOpen,
        isChildAgeAutoFill,
        setIsChildAgeAutoFill,
      }}
    >
      {props.children}
    </NonEmployeeContext.Provider>
  );
}

export function useNonEmployee() {
  return useContext(NonEmployeeContext);
}

import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Room from '@traveloka/icon-kit-web/svg/dark/ic_hotel_room_24px.svg';

import { Button, Text, Token, Icon } from '@traveloka/web-components';
import { appendTestId } from '../../shared/utils/TestUtil';

type Props = {
  button?: string;
  help: string;
  onPress?(): void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  title: string;
};

export default function RoomEmptyResult(props: Props) {
  const { button, help, onPress, style, testID, title } = props;

  return (
    <View style={[Style.container, style]}>
      <Icon src={Room} width={24} height={24} />
      <Text variant="ui-large" style={Style.title}>
        {title}
      </Text>
      <Text style={Style.help}>{help}</Text>
      {button && (
        <Button
          testID={appendTestId(testID, 'button')}
          text={button}
          onPress={onPress}
        />
      )}
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    marginTop: Token.spacing.xs,
  },
  help: {
    textAlign: 'center',
    marginTop: Token.spacing.xxs,
    marginBottom: Token.spacing.m,
  },
});

import React from 'react';
import { StyleProp, ViewStyle, StyleSheet, View } from 'react-native';

import { Card, Icon, Text, Token } from '@traveloka/web-components';
import Info from '@traveloka/icon-kit-web/svg/blue/ic_system_status_info-fill_16px.svg';

type Props = {
  text: string;
  style?: StyleProp<ViewStyle>;
  noBorderRadius?: boolean;
  verticalAlign?: 'top' | 'center';
};

export default function InfoBox(props: Props) {
  const { noBorderRadius, text, style, verticalAlign = 'center' } = props;

  const Container = noBorderRadius ? View : Card;

  const additionalStyle: StyleProp<ViewStyle>[] = [];

  if (verticalAlign === 'top') {
    additionalStyle.push({
      alignItems: 'flex-start',
    });
  } else if (verticalAlign === 'center') {
    additionalStyle.push({
      alignItems: 'center',
    });
  }

  return (
    <Container style={[Style.container, ...additionalStyle, style]}>
      <Icon src={Info} />
      <Text style={Style.text} variant="ui-small" ink="secondary">
        {text}
      </Text>
    </Container>
  );
}

const Style = StyleSheet.create({
  container: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.s,
    flexDirection: 'row',
    backgroundColor: Token.color.uiBlueLight,
  },
  text: {
    marginLeft: Token.spacing.m,
  },
});

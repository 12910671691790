import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Text,
  Token,
  Card,
  Icon,
  useTheme,
  Image,
  Badge,
} from '@traveloka/web-components';
import {
  GenericHomeContent_v2,
  GenericHomeSectionName,
  GenericHomeButton,
} from '@traveloka/ctvweb-ui/generic';
import IcSystemStatusOkDoneFill from '@traveloka/icon-kit-web/svg/greenPrimary/ic_system_status_ok_done-fill_24px.svg';
import IcSystemCrossClose from '@traveloka/icon-kit-web/svg/lightSecondary/ic_system_cross_close_16px.svg';
import { DRNHtmlText } from '@traveloka/district-ui';

type Props = {
  content: {
    comingSoon: string;
    sectionName: string;
    heading: string;
    headingParagraph: string;
    benefitListTitle: string;
    retailColumnImageUrl: string;
    retailColumnText: string;
    retailColumnCheckIndexList: string;
    corporateColumnImageUrl: string;
    corporateColumnText: string;
    corporateColumnCheckIndexList: string;
    benefitList: Array<{
      text: string;
      isComingSoon?: boolean;
    }>;
    bannerText: string;
    signUpButtonText: string;
  };
  onPressSignUp(): void;
};

export default function GenericHomeBenefits(props: Props) {
  const { content, onPressSignUp } = props;
  const { color } = useTheme();

  const retailColumnCheckIndexListArr = content.retailColumnCheckIndexList
    .split(',')
    .map(el => parseInt(el));

  const corporateColumnCheckIndexListArr = content.corporateColumnCheckIndexList
    .split(',')
    .map(el => parseInt(el));

  const paragraphHtmlStyle = {
    fontSize: Token.typography.uiBaseline.fontSize,
    lineHeight: Token.typography.uiBaseline.lineHeight,
    margin: 0,
  };

  return (
    <GenericHomeContent_v2 style={Style.benefitsContainer}>
      <GenericHomeSectionName text={content.sectionName} />
      <View style={Style.heading}>
        <Text variant="hero">{content.heading}</Text>
        <View style={Style.headingParagraph}>
          <DRNHtmlText
            htmlText={'<p>' + content.headingParagraph + '</p>'}
            htmlStyles={{
              p: paragraphHtmlStyle,
            }}
          />
        </View>
      </View>

      <View style={Style.table}>
        <View style={Style.tableUnderlay}>
          <View style={Style.spacer}></View>
          <Card elevation="hover" style={Style.card} />
        </View>

        <View
          style={[Style.tableRow, { borderBottomColor: color.borderSubtle }]}
        >
          <View style={Style.tableFirstColumn}>
            <Text variant="headline" ink="primary-interactive">
              {content.benefitListTitle}
            </Text>
          </View>
          <View style={Style.tableColumn}>
            <Image
              src={content.retailColumnImageUrl}
              style={Style.columnImage}
            />
            <Text variant="ui-small" ink="secondary">
              {content.retailColumnText}
            </Text>
          </View>
          <View style={Style.tableColumn}>
            <Image
              src={content.corporateColumnImageUrl}
              style={Style.columnImage}
            />
            <Text variant="ui-small" ink="secondary">
              {content.corporateColumnText}
            </Text>
          </View>
        </View>

        {content.benefitList.map((el, index) => {
          return (
            <View
              key={index}
              style={[
                Style.tableRow,
                { borderBottomColor: color.borderSubtle },
              ]}
            >
              <View style={Style.tableFirstColumn}>
                <Text>{el.text}</Text>
                {el.isComingSoon && (
                  <View style={Style.comingSoon}>
                    <Badge variant="alert" text={content.comingSoon} />
                  </View>
                )}
              </View>
              <View style={Style.tableColumn}>
                {retailColumnCheckIndexListArr.includes(index + 1) ? (
                  <Icon src={IcSystemStatusOkDoneFill} />
                ) : (
                  <Icon src={IcSystemCrossClose} />
                )}
              </View>
              <View style={Style.tableColumn}>
                {corporateColumnCheckIndexListArr.includes(index + 1) ? (
                  <Icon src={IcSystemStatusOkDoneFill} />
                ) : (
                  <Icon src={IcSystemCrossClose} />
                )}
              </View>
            </View>
          );
        })}

        <View style={Style.tableBottomRow}>
          <View style={Style.spacer}>
            <Text variant="headline">{content.bannerText}</Text>
          </View>
          <View style={Style.tableColumn}>
            <GenericHomeButton
              text={content.signUpButtonText}
              style={Style.signUpButton}
              onPress={onPressSignUp}
            />
          </View>
        </View>
      </View>
    </GenericHomeContent_v2>
  );
}

const Style = StyleSheet.create({
  benefitsContainer: {
    paddingTop: Token.spacing.xxxxl,
    paddingBottom: Token.spacing.xxxxl * 3,
  },
  heading: {
    marginTop: Token.spacing.xxxl,
    marginBottom: Token.spacing.xxxxl,
  },
  headingParagraph: {
    marginTop: Token.spacing.m,
  },
  table: {
    position: 'relative',
  },
  comingSoon: {
    marginTop: Token.spacing.xxs,
  },
  tableUnderlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
  },
  spacer: {
    flex: 1,
  },
  card: {
    flexBasis: 240,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    paddingVertical: Token.spacing.ml,
  },
  tableBottomRow: {
    flexDirection: 'row',
    paddingVertical: Token.spacing.xxl,
  },
  tableColumn: {
    flexBasis: 240,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: Token.spacing.xxl,
  },
  tableFirstColumn: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  columnImage: {
    height: 22,
    marginBottom: Token.spacing.xs,
  },
  signUpButton: {
    width: '100%',
  },
});

import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import Input, {
  Props as InputProps,
} from '@traveloka/ctvweb-ui/src/shared/components/form/Input/Input';

import { FieldProps } from '../types';

type Props = FieldProps<string> &
  Omit<InputProps, 'value' | 'defaultValue' | 'onBlur'> & {
    onBlur?(value: string, onChange: (event: any) => void): void;
  };

export default function InputField(props: Props) {
  const { name, validate, maxLength, defaultValue = '', ...inputProps } = props;

  const { control } = useFormContext();
  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({ control, name, defaultValue, rules: { validate } });

  function handleBlur() {
    onBlur();
    props.onBlur?.(value, onChange);
  }

  const max = typeof maxLength !== 'undefined' && maxLength > 0;
  const rightHelperText = max ? `${value.length}/${maxLength}` : undefined;

  return (
    <Input
      {...inputProps}
      onBlur={handleBlur}
      onChange={e => {
        onChange(e);
        props.onChange?.(e);
      }}
      value={value}
      error={error?.message}
      maxLength={maxLength}
      rightHelper={rightHelperText}
    />
  );
}

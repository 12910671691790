import React, { useCallback, useMemo, useRef } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { InputDropdown } from '@traveloka/ctvweb-ui';
import { Item } from '@traveloka/ctvweb-ui/src/shared/components/form/InputDropdown/types';
import Chevron from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';
import Search from '@traveloka/icon-kit-web/svg/dark/ic_system_search_24px.svg';
import { Icon, Token } from '@traveloka/web-components';

import {
  usePriceDisplay,
  PriceDisplay,
} from 'hotel/search/contexts/PriceDisplayContext';
import useAutocomplete from 'hotel/search/hooks/useAutocomplete';
import { useSortFilter } from 'hotel/search/contexts/SortFilterContext';
import {
  parseSearchSpec,
  stringifySearchSpec,
} from 'hotel/search/utils/SearchQueryUtil';
import {
  useSearchSpec,
  useSearchSpecDispatch,
} from 'hotel/shared/contexts/SpecContext';
import useEagerNavigation from 'shared/hooks/useEagerNavigation';

type Props = {
  style?: StyleProp<ViewStyle>;
};

export default function SearchBar(props: Props) {
  const { style } = props;
  const searchSpec = useSearchSpec();
  const setSearchSpec = useSearchSpecDispatch();
  const [priceDisplay, setPriceDisplay] = usePriceDisplay();
  const [, dispatch] = useSortFilter();

  const content = useContentResource().CorporateHotelSearchSearchBar;

  const [autocompleteOptions, fetchAutocomplete] = useAutocomplete();
  const omniOptions = useMemo<Item[]>(
    () =>
      autocompleteOptions.map(option => ({
        label: option.name,
        value: option.id,
      })),
    [autocompleteOptions]
  );

  const priceDisplayItems: Item[] = useMemo(
    () => [
      {
        label: content.priceDisplayNightlyText,
        value: PriceDisplay.NIGHTLY,
      },
      {
        label: content.priceDisplayTotalText,
        value: PriceDisplay.TOTAL,
      },
    ],
    []
  );
  const displayValue = useMemo(
    () => priceDisplayItems.find(item => item.value === priceDisplay)!.label,
    [priceDisplay, priceDisplayItems]
  );

  const handleDisplayPress = useCallback(
    (item: Item) => {
      setPriceDisplay(item.value as PriceDisplay);
    },
    [setPriceDisplay]
  );

  const navigate = useEagerNavigation();
  const handleOmniPress = useCallback(
    (item: Item) => {
      const option = autocompleteOptions.find(
        option => option.id === item.value
      );

      if (option) {
        const spec = {
          ...searchSpec,
          type: option.type,
          id: option.id,
          geoName: option.name,
        };

        const stringifiedSpec = stringifySearchSpec(spec);
        navigate({
          pathname: '/hotel/search',
          search: stringifiedSpec,
        });
        setSearchSpec(parseSearchSpec(stringifiedSpec));
        dispatch({ type: 'reset' });
      }
    },
    [searchSpec, autocompleteOptions]
  );

  return (
    <View style={[Style.container, style]}>
      <View style={Style.dropdown}>
        <InputDropdown
          testID="hotel.search.omni"
          containerStyle={Style.input}
          editable
          items={omniOptions}
          iconLeft={<Icon src={Search} />}
          onChangeText={fetchAutocomplete}
          onPressItem={handleOmniPress}
          placeholder={content.omniSearchPlaceholder}
          value={searchSpec.geoName}
        />
      </View>
      <View style={Style.priceDisplay}>
        <InputDropdown
          testID="hotel.search.price-display"
          value={displayValue}
          items={priceDisplayItems}
          iconRight={<Icon src={Chevron} />}
          containerStyle={Style.input}
          onPressItem={handleDisplayPress}
        />
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  dropdown: {
    flex: 1,
  },
  priceDisplay: {
    width: 232,
    marginLeft: Token.spacing.m,
  },
  input: {
    paddingVertical: Token.spacing.s,
    paddingHorizontal: Token.spacing.m,
  },
});

import React, { useCallback, useEffect, useRef } from 'react';
import { findNodeHandle, StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import InfoIcon from '@traveloka/icon-kit-web/svg/dark/ic_system_status_info-fill_24px.svg';
import {
  Button,
  Icon,
  InfoBox,
  LoadingIndicator,
  Text,
  Token,
} from '@traveloka/web-components';

import AccountSuspendedPopup from 'account-status/components/AccountSuspendedPopup/AccountSuspendedPopup';
import { useProperty } from 'hotel/detail/contexts/PropertyContext';
import { Content } from 'shared/components/Layout';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

import AdditionalInfo from '../AdditionalInfo/AdditionalInfo';
import Header from '../Header/Header';
import Overview from '../Overview/Overview';
import RoomList from '../RoomList/RoomList';
import RoomSearch from '../RoomSearch/RoomSearch';

function HotelDetailContent() {
  const [{ isLoading, name }] = useProperty();

  const content = useContentResource().CorporateHotelDetail;

  const roomListRef = useRef<View>(null);
  const handleSelectRoomPress = useCallback(() => {
    const el = findNodeHandle(roomListRef.current) as Element | null;

    if (el) {
      const { bottom } = el.getBoundingClientRect();
      const nodeLocation = window.pageYOffset + bottom;

      // 60 is the height of header
      window.scrollTo({ top: nodeLocation - 60, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (name && window.location.hash === '#room') {
      handleSelectRoomPress();
    }
  }, [name]);

  if (isLoading) {
    return (
      <Content>
        <View style={Style.loadingContainer}>
          <LoadingIndicator style={Style.loading} />
          <Text>{content.loadingText}</Text>
        </View>
      </Content>
    );
  }

  if (!name) {
    return <NotAvailable />;
  }

  return (
    <>
      <Content>
        <Header />
        <Overview onSelectRoomPress={handleSelectRoomPress} />
        <RoomSearch ref={roomListRef} />
        <RoomList />
      </Content>
      <AdditionalInfo />
      <AccountSuspendedPopup backToHome />
    </>
  );
}

function NotAvailable() {
  const content = useContentResource().CorporateHotelDetail;

  const history = useLocalizedHistory();
  const redirectToHome = useCallback(() => {
    history.push('/');
  }, []);

  return (
    <Content>
      <InfoBox
        message={content.notAvailableText}
        Icon={() => <Icon src={InfoIcon} width={16} height={16} />}
        isFullWidth={false}
        variant="ALERT"
        Action={
          <Button
            onPress={redirectToHome}
            variant="text"
            size="small"
            text={content.goBackToHomeButtonText}
          />
        }
      />
    </Content>
  );
}

const Style = StyleSheet.create({
  loadingContainer: {
    alignItems: 'center',
  },
  loading: {
    marginBottom: Token.spacing.xs,
  },
});

export default HotelDetailContent;

import React, { useCallback } from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Icon, Text, Token } from '@traveloka/web-components';
import SufficientIcon from '@traveloka/icon-kit-web/svg/greenSecondary/ic_system_status_ok_done-fill_24px.svg';
import InsufficientIcon from '@traveloka/icon-kit-web/svg/redSecondary/ic_system_status_fail-fill_24px.svg';
import RetryIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_retry_24px.svg';

import { CreditPaymentStatus } from 'credit-limit/api';
import {
  usePaymentStateDispatch,
  usePaymentState,
} from 'payment/select/contexts/PaymentContext';

export default function CreditLimitPaymentStatus() {
  const { creditLimit } = usePaymentState();
  const { refetchPaymentStatus } = usePaymentStateDispatch();

  const { creditPaymentStatus, isFetching } = creditLimit;

  const switcherCreditPaymentComponent = useSwitcherCreditPaymentComponent();
  const creditPayment = switcherCreditPaymentComponent(creditPaymentStatus);

  if (creditPaymentStatus) {
    return (
      <>
        <Icon src={creditPayment.icon} />
        <Text style={Style.statusText}>{creditPayment.status}</Text>
      </>
    );
  }

  return (
    <View style={Style.containerFail}>
      <Text style={Style.failStatusText} variant="ui-small" ink="highlight">
        {creditPayment.status}
      </Text>

      {!isFetching && (
        <TouchableOpacity
          testID={`confirmation-payment.content.credit-limit-status.retry`}
          onPress={refetchPaymentStatus}
        >
          <Icon src={creditPayment.icon} />
        </TouchableOpacity>
      )}
    </View>
  );
}

function useSwitcherCreditPaymentComponent() {
  const content = useContentResource().CorporateCreditLimitPayment;

  return useCallback((status?: CreditPaymentStatus) => {
    switch (status) {
      case 'SUFFICIENT':
        return { icon: SufficientIcon, status: content.sufficientText };
      case 'INSUFFICIENT':
        return { icon: InsufficientIcon, status: content.insufficientText };
      case undefined:
        return {
          icon: RetryIcon,
          status: content.failToLoadText,
        };
    }
  }, []);
}

const Style = StyleSheet.create({
  statusText: {
    marginLeft: Token.spacing.xs,
  },
  containerFail: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  failStatusText: {
    marginRight: Token.spacing.xs,
  },
});

import { useContentResource } from '@traveloka/ctv-core';
import { Divider } from '@traveloka/ctvweb-ui';
import { Icon, Text, Token } from '@traveloka/web-components';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export default function TermsAndConditions() {
  const content = useContentResource().CorporateTermsOfUse;

  return (
    <View style={Style.container}>
      <Header />
      <Text variant="title-1">{content.termsOfUseTitle}</Text>
      <Divider />
      <Text style={Style.text}>{content.termsOfUseContent}</Text>
      <Divider />
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          1.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.scopeOfOurServicesTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              1.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.scopeOfOurServicesContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              1.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.scopeOfOurServicesContentNo2}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              1.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.scopeOfOurServicesContentNo3}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              1.4.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.scopeOfOurServicesContentNo4}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              1.5.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.scopeOfOurServicesContentNo5}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              1.6.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.scopeOfOurServicesContentNo6}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              1.7.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.scopeOfOurServicesContentNo7}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          2.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.cancellationsTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              2.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.cancellationsContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              2.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.cancellationsContentNo2}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          3.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.specialRequestsTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              3.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.specialRequestsContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              3.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.specialRequestsContentNo2}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          4.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.travelAdviceTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              4.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.travelAdviceContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              4.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.travelAdviceContentNo2}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              4.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.travelAdviceContentNo3}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          5.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.ratingsTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              5.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.ratingsContentNo1}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          6.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.priceAndPromotionTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              6.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.priceAndPromotionContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              6.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.priceAndPromotionContentNo2}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          7.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.serviceFeeTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              7.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.serviceFeeContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              7.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.serviceFeeContentNo2}
              </Text>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  a.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.serviceFeeContentNo2a}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  b.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.serviceFeeContentNo2b}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              7.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.serviceFeeContentNo3}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          8.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.dataDiscrepanciesTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              8.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.dataDiscrepanciesContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              8.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.dataDiscrepanciesContentNo2}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              8.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.dataDiscrepanciesContentNo3}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          9.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.additionalChargesRefundsTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              9.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.additionalChargesRefundsContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              9.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.additionalChargesRefundsContentNo2}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              9.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.additionalChargesRefundsContentNo3}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          10.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.additionalChargesFromHotelsTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              10.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.additionalChargesFromHotelsContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              10.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.additionalChargesFromHotelsContentNo2}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              10.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.additionalChargesFromHotelsContentNo3}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              10.4.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.additionalChargesFromHotelsContentNo4}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              10.5.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.additionalChargesFromHotelsContentNo5}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          11.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.userAccountTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              11.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.userAccountContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              11.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.userAccountContentNo2}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              11.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.userAccountContentNo3}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              11.4.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.userAccountContentNo4}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              11.5.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.userAccountContentNo5}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              11.6.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.userAccountContentNo6}
              </Text>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  1.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub1}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  2.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub2}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  3.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub3}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  4.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub4}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  5.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub5}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  6.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub6}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  7.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub7}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  8.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub8}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  9.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub9}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  10.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub10}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  11.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.userAccountContentNo6Sub11}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          12.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.paymentDetailsAndProceduresTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              12.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentDetailsAndProceduresContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              12.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentDetailsAndProceduresContentNo2}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              12.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentDetailsAndProceduresContentNo3}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              12.4.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentDetailsAndProceduresContentNo4}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              12.5.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentDetailsAndProceduresContentNo5}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              12.6.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentDetailsAndProceduresContentNo6}
              </Text>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  1.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.paymentDetailsAndProceduresContentNo6Sub1}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  2.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.paymentDetailsAndProceduresContentNo6Sub2}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  3.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.paymentDetailsAndProceduresContentNo6Sub3}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  4.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.paymentDetailsAndProceduresContentNo6Sub4}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  5.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.paymentDetailsAndProceduresContentNo6Sub5}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          13.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.paymentByCreditCardFraudTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              13.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentByCreditCardFraudContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              13.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentByCreditCardFraudContentNo2}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              13.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentByCreditCardFraudContentNo3}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              13.4.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentByCreditCardFraudContentNo4}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              13.5.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentByCreditCardFraudContentNo5}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              13.6.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentByCreditCardFraudContentNo6}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              13.7.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.paymentByCreditCardFraudContentNo7}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          14.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.rightsAndObligationsTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              14.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.rightsAndObligationsContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              14.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.rightsAndObligationsContentNo2}
              </Text>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  1.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub1}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  2.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub2}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  3.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub3}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  4.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub4}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  5.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub5}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  6.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub6}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  7.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub7}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  8.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub8}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  9.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub9}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  10.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub10}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  11.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub11}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  12.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub12}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  13.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub13}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  14.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub14}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  15.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub15}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  16.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub16}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  17.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub17}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  18.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub18}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  19.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub19}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  20.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub20}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  21.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub21}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  22.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.rightsAndObligationsContentNo2Sub22}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          15.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.intellectualPropertyRightsTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              15.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.intellectualPropertyRightsContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              15.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.intellectualPropertyRightsContentNo2}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              15.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.intellectualPropertyRightsContentNo3}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              15.4.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.intellectualPropertyRightsContentNo4}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              15.5.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.intellectualPropertyRightsContentNo5}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              15.6.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.intellectualPropertyRightsContentNo6}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          16.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.claimsOfIntellectualPropertyRightsInfringementTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              16.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {
                  content.claimsOfIntellectualPropertyRightsInfringementContentNo1
                }
              </Text>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  1.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {
                      content.claimsOfIntellectualPropertyRightsInfringementContentNo1Sub1
                    }
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  2.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {
                      content.claimsOfIntellectualPropertyRightsInfringementContentNo1Sub2
                    }
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  3.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {
                      content.claimsOfIntellectualPropertyRightsInfringementContentNo1Sub3
                    }
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  4.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {
                      content.claimsOfIntellectualPropertyRightsInfringementContentNo1Sub4
                    }
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  5.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {
                      content.claimsOfIntellectualPropertyRightsInfringementContentNo1Sub5
                    }
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  6.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {
                      content.claimsOfIntellectualPropertyRightsInfringementContentNo1Sub6
                    }
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  7.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {
                      content.claimsOfIntellectualPropertyRightsInfringementContentNo1Sub7
                    }
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  8.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {
                      content.claimsOfIntellectualPropertyRightsInfringementContentNo1Sub8
                    }
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  9.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {
                      content.claimsOfIntellectualPropertyRightsInfringementContentNo1Sub9
                    }
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  10.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {
                      content.claimsOfIntellectualPropertyRightsInfringementContentNo1Sub10
                    }
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              16.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {
                  content.claimsOfIntellectualPropertyRightsInfringementContentNo2
                }
              </Text>
              <Text style={Style.content} variant="ui-small">
                {
                  content.claimsOfIntellectualPropertyRightsInfringementContentNo2Sub1
                }
              </Text>
              <Text style={Style.content} variant="ui-small">
                {
                  content.claimsOfIntellectualPropertyRightsInfringementContentNo2Sub2
                }
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              16.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {
                  content.claimsOfIntellectualPropertyRightsInfringementContentNo3
                }
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              16.4.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {
                  content.claimsOfIntellectualPropertyRightsInfringementContentNo4
                }
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          17.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.limitationOfLiabilityTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              17.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.limitationOfLiabilityContentNo1}
              </Text>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  1.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.limitationOfLiabilityContentNo1Sub1}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  2.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.limitationOfLiabilityContentNo1Sub2}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  3.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.limitationOfLiabilityContentNo1Sub3}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  4.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.limitationOfLiabilityContentNo1Sub4}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  5.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.limitationOfLiabilityContentNo1Sub5}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text style={Style.index} variant="ui-small" ink="secondary">
                  6.
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.limitationOfLiabilityContentNo1Sub6}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              17.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.limitationOfLiabilityContentNo2}
              </Text>
              <View style={Style.wrapper}>
                <Text variant="ui-small" ink="secondary">
                  •
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.limitationOfLiabilityContentNo2Sub1}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text variant="ui-small" ink="secondary">
                  •
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.limitationOfLiabilityContentNo2Sub2}
                  </Text>
                </View>
              </View>
              <View style={Style.wrapper}>
                <Text variant="ui-small" ink="secondary">
                  •
                </Text>
                <View style={Style.indentation}>
                  <Text
                    style={Style.content}
                    variant="ui-small"
                    ink="secondary"
                  >
                    {content.limitationOfLiabilityContentNo2Sub3}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              17.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.limitationOfLiabilityContentNo3}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              17.4.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.limitationOfLiabilityContentNo4}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              17.5.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.limitationOfLiabilityContentNo5}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              17.6.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.limitationOfLiabilityContentNo6}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              17.7.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.limitationOfLiabilityContentNo7}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              17.8.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.limitationOfLiabilityContentNo8}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          18.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.governingLawTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              18.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.governingLawContentNo1}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          19.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.disputesResolutionTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              19.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.disputesResolutionContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              19.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.disputesResolutionContentNo2}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              19.3.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.disputesResolutionContentNo3}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              19.4.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.disputesResolutionContentNo4}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              19.5.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.disputesResolutionContentNo5}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              19.6.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.disputesResolutionContentNo6}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              19.7.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.disputesResolutionContentNo7}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              19.8.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.disputesResolutionContentNo8}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              19.9.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.disputesResolutionContentNo9}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              19.10.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.disputesResolutionContentNo10}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={Style.outerWrapper}>
        <Text style={Style.index} variant="title-2">
          20.
        </Text>
        <View style={Style.indentation}>
          <Text style={Style.content} variant="title-2">
            {content.forceMajeureTitle}
          </Text>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              20.1.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.forceMajeureContentNo1}
              </Text>
            </View>
          </View>
          <View style={Style.wrapper}>
            <Text style={Style.index} variant="ui-small" ink="secondary">
              20.2.
            </Text>
            <View style={Style.indentation}>
              <Text style={Style.content} variant="ui-small" ink="secondary">
                {content.forceMajeureContentNo2}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <Footer />
    </View>
  );
}

function Header() {
  return (
    <View style={Style.header}>
      <Icon src={'/img/traveloka-for-corporates.png'} width={279} height={28} />
    </View>
  );
}

function Footer() {
  return (
    <View style={Style.footer}>
      <Text>&copy; {new Date().getFullYear()} Traveloka</Text>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    width: 960,
    marginHorizontal: 'auto',
  },
  header: {
    flex: 1,
    alignItems: 'center',
    marginTop: Token.spacing.xxl,
    marginBottom: Token.spacing.xxxl,
  },
  text: {
    marginBottom: Token.spacing.m,
  },
  outerWrapper: {
    flexDirection: 'row',
    marginBottom: Token.spacing.xs,
  },
  wrapper: {
    flexDirection: 'row',
    marginBottom: Token.spacing.xxs,
  },
  index: {
    width: 38, //This value is based on the point number 19.10.
  },
  content: {
    marginBottom: Token.spacing.xs,
  },
  indentation: {
    flex: 1,
    marginLeft: Token.spacing.xs,
  },
  footer: {
    flex: 1,
    alignItems: 'center',
    marginTop: Token.spacing.xxl,
    marginBottom: Token.spacing.xxxxl,
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Image, Text, Token } from '@traveloka/web-components';

import image from '../../../img/flight-search-empty.png';

type Props = {
  mainText: string;
  subText: string;
};

export default function FlightSearchEmpty(props: Props) {
  return (
    <View style={Style.container}>
      <Image src={image} height={180} alt="Flight not available" />
      <Text variant="ui-large" style={Style.topText}>
        {props.mainText}
      </Text>
      <Text ink="secondary" style={Style.bottomText}>
        {props.subText}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    height: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: Token.spacing.xxxxl,
  },
  topText: {
    marginTop: Token.spacing.xxl,
  },
  bottomText: {
    marginTop: Token.spacing.m,
  },
});

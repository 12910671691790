import React from 'react';

import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Divider } from '@traveloka/ctvweb-ui';

import RedirectHome from 'generic/RedirectHome';
import { Content, Footer, Header, Page } from 'shared/components/Layout';
import TwoColumn from 'shared/components/Layout/TwoColumn';

import EditProfile from './edit/EditProfile';
import EmploymentDetail from './employment-detail/EmploymentDetail';
import ProfileMenu from './shared/components/ProfileMenu/ProfileMenu';

type Props = RouteComponentProps;

export default function ProfileApp(props: Props) {
  const { match, location } = props;

  return (
    <Page>
      <Header />
      <Content>
        <TwoColumn>
          <TwoColumn.Small>
            <ProfileMenu testID="sidebar" currentPath={location.pathname} />
          </TwoColumn.Small>
          <Divider margin="none" />
          <TwoColumn.Big>
            <Switch>
              <Route
                path={`${match.path}/edit`}
                exact
                component={EditProfile}
              />
              <Route
                path={`${match.path}/employment-detail`}
                exact
                component={EmploymentDetail}
              />
              <Route component={RedirectHome} />
            </Switch>
          </TwoColumn.Big>
        </TwoColumn>
      </Content>
      <Footer />
    </Page>
  );
}

import React from 'react';
import { Card, Text, Button, Token, useTheme } from '@traveloka/web-components';
import { View, StyleSheet } from 'react-native';

type Props = {
  switcherLabel: {
    title: string;
    description: string;
    changedPrice: string;
    continueButton: string;
  };
  onClickHandler: () => void;
};

export default function FlightSearchReturnSmartComboHandler({
  switcherLabel,
  onClickHandler,
}: Props) {
  const { color } = useTheme();
  const { title, description, changedPrice, continueButton } = switcherLabel;
  return (
    <Card style={[Style.card, { backgroundColor: color.uiLightStain }]}>
      <View style={Style.leftColumn}>
        <Text variant="ui-baseline">{title}</Text>
        <Text variant="ui-small" ink="secondary">
          {description}{' '}
          <Text
            style={Style.priceChange}
            variant="ui-small"
            ink="destructive-interactive"
          >
            {changedPrice}
          </Text>
        </Text>
      </View>
      <View style={Style.buttonContinue}>
        <Button
          variant="primary"
          text={continueButton}
          onPress={onClickHandler}
        />
      </View>
    </Card>
  );
}

const Style = StyleSheet.create({
  card: {
    flexDirection: 'row',
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.m,
    marginBottom: Token.spacing.m,
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'justify',
  },
  leftColumn: {
    flex: 1,
  },
  buttonContinue: {
    width: '24%',
  },
  priceChange: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
});

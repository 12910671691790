import React, { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Text,
  Token,
  Image,
  Icon,
  Badge,
  Card,
} from '@traveloka/web-components';
import {
  GenericHomeContent_v2,
  GenericHomeSectionName,
  GenericHomeButton,
} from '@traveloka/ctvweb-ui/generic';

type Solution = {
  title: string;
  description: string;
  imageUrl: string;
  isImageAlignLeft?: boolean;
  isComingSoon?: boolean;
  isDisplayButton?: boolean;
  featuredImageUrls?: Array<string>;
  featuredImageHeight?: number;
};

type FeaturedSolution = {
  title: string;
  description: string;
  iconSrcList: Array<string>;
};

type Props = {
  content: {
    sectionName: string;
    heading: string;
    solutions: Array<Solution>;
    featuredSolutions: Array<FeaturedSolution>;
    comingSoonBadgeText: string;
    signUpButtonText: string;
    bannerText: string;
  };
  onPressSignUp(): void;
};

export default function GenericHomeSolutions(props: Props) {
  const { content, onPressSignUp } = props;

  return (
    <GenericHomeContent_v2 style={Style.container}>
      <GenericHomeSectionName text={content.sectionName} />

      <Text variant="hero" style={Style.heading}>
        {content.heading}
      </Text>

      {content.solutions.map((solution, _index) => {
        return (
          <Solution
            key={_index}
            solution={solution}
            comingSoonBadgeText={content.comingSoonBadgeText}
            signUpButtonText={content.signUpButtonText}
            onPressSignUp={onPressSignUp}
          />
        );
      })}

      <View style={Style.otherFeatureSection}>
        <View style={Style.otherFeatureHeading}>
          <Text variant="headline" style={Style.otherFeatureHeadingText}>
            {content.bannerText}
          </Text>
          <GenericHomeButton
            text={content.signUpButtonText}
            onPress={onPressSignUp}
          />
        </View>

        <View style={Style.cardList}>
          {content.featuredSolutions.map((featuredSolution, index) => {
            return (
              <View
                key={index}
                style={[Style.cardContainer, index > 0 && Style.cardMargin]}
              >
                <FeaturedSolution
                  iconSrcList={featuredSolution.iconSrcList}
                  title={featuredSolution.title}
                  description={featuredSolution.description}
                />
              </View>
            );
          })}
        </View>
      </View>
    </GenericHomeContent_v2>
  );
}

type SolutionLayoutProps = {
  solution: Solution;
  signUpButtonText: string;
  comingSoonBadgeText: string;
  onPressSignUp(): void;
};

function Solution(solutionItemProps: SolutionLayoutProps) {
  const {
    solution,
    comingSoonBadgeText,
    signUpButtonText,
    onPressSignUp,
  } = solutionItemProps;
  const {
    title,
    description,
    imageUrl,
    isImageAlignLeft,
    isComingSoon,
    isDisplayButton,
    featuredImageUrls,
    featuredImageHeight,
  } = solution;

  return (
    <View
      style={[
        Style.featureLayout,
        { flexDirection: isImageAlignLeft ? 'row-reverse' : 'row' },
      ]}
    >
      <View style={Style.contentContainer}>
        {isComingSoon && (
          <View style={Style.badge}>
            <Badge variant="alert" text={comingSoonBadgeText} />
          </View>
        )}

        <Text variant="headline">{title}</Text>
        <Text style={Style.description}>{description}</Text>

        {isDisplayButton && (
          <GenericHomeButton
            text={signUpButtonText}
            variant="secondary"
            style={Style.ctaButton}
            onPress={onPressSignUp}
          />
        )}

        {featuredImageUrls && (
          <View style={Style.featuredImageList}>
            {featuredImageUrls.map((url, index) => {
              return (
                <Image
                  key={index}
                  src={url}
                  height={featuredImageHeight ? featuredImageHeight : 32}
                  style={Style.featuredImage}
                />
              );
            })}
          </View>
        )}
      </View>

      <View
        style={[Style.imageContainer, isImageAlignLeft && Style.imageAlignLeft]}
      >
        <Image src={imageUrl} objectFit="contain" style={Style.image} />
      </View>
    </View>
  );
}

function FeaturedSolution(props: FeaturedSolution) {
  const { title, description, iconSrcList } = props;

  return (
    <Card style={Style.card} elevation="float">
      <View>
        <View style={Style.cardIconList}>
          {iconSrcList.map((src, index) => {
            return (
              <Icon
                key={index}
                src={src}
                style={{ marginRight: Token.spacing.m }}
              />
            );
          })}
        </View>
        <Text variant="headline" style={{ marginTop: Token.spacing.m }}>
          {title}
        </Text>
      </View>
      <Text style={{ marginTop: Token.spacing.m }}>{description}</Text>
    </Card>
  );
}

const Style = StyleSheet.create({
  container: {
    paddingTop: Token.spacing.xxl * 2,
    paddingBottom: Token.spacing.xxxxl,
  },
  heading: {
    marginTop: Token.spacing.xxxl,
    marginBottom: Token.spacing.xxl,
  },
  featureLayout: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: Token.spacing.xxl * 2,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  imageContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: Token.spacing.xxxxl * 2,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  image: {
    width: '100%',
  },
  imageAlignLeft: {
    paddingLeft: 0,
    paddingRight: Token.spacing.xxxxl * 2,
    justifyContent: 'flex-start',
  },
  badge: {
    marginBottom: Token.spacing.xxxl,
  },
  description: {
    marginTop: Token.spacing.xxxl,
  },
  ctaButton: {
    marginTop: Token.spacing.xxxl,
  },
  featuredImageList: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: Token.spacing.xxxl,
  },
  featuredImage: {
    marginRight: Token.spacing.l,
  },
  otherFeatureSection: {
    marginTop: Token.spacing.xxl * 2,
  },
  otherFeatureHeading: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  otherFeatureHeadingText: {
    flex: 1,
    paddingRight: Token.spacing.l,
  },
  cardList: {
    flexDirection: 'row',
    marginTop: Token.spacing.xxl,
  },
  cardContainer: {
    flex: 1,
  },
  cardMargin: {
    marginLeft: Token.spacing.l,
  },
  card: {
    flex: 1,
    flexDirection: 'column',
    padding: Token.spacing.m,
    justifyContent: 'space-between',
  },
  cardIconList: {
    flexDirection: 'row',
  },
  triangleMask: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightColor: 'transparent',
  },
});

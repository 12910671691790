import React, { Fragment } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import {
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { ComplianceLabel, Divider } from '@traveloka/ctvweb-ui';
import { SafeImage } from '@traveloka/ctvweb-ui/hotel';
import {
  Button,
  Popup,
  Text,
  Token,
  useTheme,
} from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import { usePrebook } from 'hotel/prebook/contexts/PrebookProvider';
import { FormValue } from 'hotel/prebook/types';
import { NonEmployeeType } from 'hotel/shared/api/types';
import { convert } from 'shared/utils/currency';
import { formatCurrency, formatMessage } from 'shared/utils/intl';

import { useNonEmployeeFormContent } from '../NonEmployeeTravelerForm/NonEmployeeTravelerForm';

type Props = {
  isLoading: boolean;
  isVisible: boolean;
  onClosePress(): void;
  onContinuePress(): void;
};

export default function ReviewModal(props: Props) {
  const { isLoading, isVisible, onClosePress, onContinuePress } = props;
  const { room, spec, travelers, nonEmployeeTravelers } = usePrebook();
  const { format } = useLocalizedDateFormat();

  const content = useContentResource().CorporateHotelPrebookReviewModal;
  const nonEmployeeContent = useNonEmployeeFormContent();

  const { control } = useFormContext<FormValue>();
  const nonEmployeeTravelersValue = useWatch({
    control,
    name: 'nonEmployeeTravelers',
  });
  const checkInLabel = format(spec.checkInDate, 'SHORT_WEEKDAY');
  const checkOutLabel = format(spec.checkOutDate, 'SHORT_WEEKDAY');
  const durationLabel = formatMessage(content.durationContent, {
    num: spec.duration,
  });
  const roomLabel = formatMessage(content.roomContent, {
    num: spec.numOfRooms,
  });
  const guestLabel = formatMessage(content.guestContent, {
    num: room.roomOccupancy,
  });
  const travelerLabel = formatMessage(content.travelerContent, {
    num: travelers.length + nonEmployeeTravelers.length,
  });
  const totalPrice = formatCurrency(convert(room.grandTotal || room.totalFare));

  const { color } = useTheme();
  const whiteBackgroundStyle = {
    backgroundColor: color.lightPrimary,
  };

  return (
    <Modal isVisible={isVisible}>
      <View style={[Style.scroll, whiteBackgroundStyle]}>
        <Popup
          showCloseButton={false}
          title={content.title}
          width={640}
          maxWidth={640}
        >
          <Text variant="ui-large" style={Style.title}>
            {content.hotelDetailHeader}
          </Text>
          <View style={Style.propertyDetail}>
            <SafeImage
              src={room.propertyImage}
              width={96}
              height={96}
              style={Style.propertyImage}
            />
            <View>
              <Text variant="ui-large" style={Style.roomName}>
                {room.propertyName}
              </Text>
              {!room.isComplying && (
                <ComplianceLabel
                  variant="rounded"
                  text={content.nonCompliantText}
                  style={Style.compliance}
                />
              )}
              <View style={Style.checkInInfo}>
                <SpecLabel label={content.checkInText} value={checkInLabel} />
                <SpecLabel label={content.checkOutText} value={checkOutLabel} />
                <SpecLabel label={content.durationText} value={durationLabel} />
              </View>
            </View>
          </View>
          <Divider style={Style.divider} />
          <Text variant="ui-large" style={Style.title}>
            {content.roomDetailHeader}
          </Text>
          <View style={Style.roomDetail}>
            {isValidString(room.roomTypeName) && (
              <RoomLabel
                label={content.roomTypeText}
                value={room.roomTypeName}
              />
            )}
            <RoomLabel label={content.roomNameText} value={room.roomName} />
            <RoomLabel label={content.numOfRooms} value={roomLabel} />
            <RoomLabel label={content.guestPerRoom} value={guestLabel} />
          </View>
          <Divider style={Style.divider} />
          <Text variant="ui-large" style={Style.title}>
            {travelerLabel}
          </Text>
          {travelers.map(traveler => (
            <Fragment key={traveler.employeeId}>
              <Text>{traveler.fullname}</Text>
              <Text variant="ui-small" ink="secondary">
                {traveler.email}
              </Text>
              <Divider subtle />
            </Fragment>
          ))}
          {nonEmployeeTravelersValue?.adults?.map((adult, index) => (
            <Fragment key={index}>
              <Text>{adult.fullName}</Text>
              <Text variant="ui-small" ink="secondary">
                {nonEmployeeContent(
                  NonEmployeeType.ADULT,
                  travelers.length + index + 1
                )}
              </Text>
              <Divider subtle />
            </Fragment>
          ))}
          {nonEmployeeTravelersValue?.children?.map((child, index) => (
            <Fragment key={index}>
              <Text>{child.fullName}</Text>
              <Text variant="ui-small" ink="secondary">
                {nonEmployeeContent(
                  NonEmployeeType.CHILD_AGE,
                  index + 1,
                  child.age
                )}
              </Text>
              <Divider subtle />
            </Fragment>
          ))}
          <View style={[Style.action, whiteBackgroundStyle]}>
            <Divider margin="none" />
            <View style={Style.price}>
              <Text>{content.totalPriceText}</Text>
              <Text variant="ui-large" ink="price">
                {totalPrice}
              </Text>
            </View>
            <View style={Style.actionContainer}>
              <Text variant="ui-tiny" ink="secondary">
                {content.infoText}
              </Text>
              <Button
                variant="secondary"
                text={content.closeButtonText}
                onPress={onClosePress}
                style={Style.close}
              />
              <Button
                variant="main-cta"
                text={content.submitButtonText}
                loading={isLoading}
                onPress={onContinuePress}
                style={Style.continue}
              />
            </View>
          </View>
        </Popup>
      </View>
    </Modal>
  );
}

function isValidString(text: string | null): text is string {
  return typeof text === 'string' && text !== '';
}

type SpecLabelProps = {
  label: string;
  value: string;
};

function SpecLabel(props: SpecLabelProps) {
  return (
    <View style={Style.checkInLabel}>
      <Text variant="ui-tiny">{props.label}</Text>
      <Text variant="ui-small" ink="secondary">
        {props.value}
      </Text>
    </View>
  );
}

type RoomLabelProps = {
  label: string;
  value: string;
};

function RoomLabel(props: RoomLabelProps) {
  return (
    <>
      <Text variant="ui-small" style={Style.label}>
        {props.label}
      </Text>
      <Text variant="ui-small" ink="secondary" style={Style.value}>
        {props.value}
      </Text>
      <View style={Style.break} />
    </>
  );
}

const Style = StyleSheet.create({
  title: {
    marginBottom: Token.spacing.m,
  },
  divider: {
    marginHorizontal: -Token.spacing.m,
  },
  label: {
    flexBasis: 104,
    marginRight: Token.spacing.m,
    marginBottom: Token.spacing.xs,
  },
  value: {
    flex: 1,
    marginBottom: Token.spacing.xs,
  },
  break: {
    flexBasis: '100%',
  },
  roomDetail: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  propertyDetail: {
    flexDirection: 'row',
  },
  checkInInfo: {
    flexDirection: 'row',
  },
  checkInLabel: {
    flexBasis: 150,
  },
  propertyImage: {
    marginRight: Token.spacing.l,
  },
  roomName: {
    marginBottom: Token.spacing.xs,
  },
  compliance: {
    marginBottom: Token.spacing.xs,
  },
  scroll: {
    flexShrink: 1,
    flexGrow: 0,
    borderRadius: Token.border.radius.normal,
    // @ts-ignore
    overflowY: 'auto',
  },
  action: {
    // @ts-ignore
    position: 'sticky',
    bottom: 0,
    marginBottom: -Token.spacing.m,
    marginHorizontal: -Token.spacing.m,
  },
  close: {
    marginLeft: 'auto',
  },
  continue: {
    marginLeft: Token.spacing.m,
  },
  actionContainer: {
    flexDirection: 'row',
    padding: Token.spacing.m,
  },
  price: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: Token.spacing.m,
  },
});

import React, { ReactNode, createContext, useContext } from 'react';

import { PartialEmployee } from 'company/types';
import { FlightNonEmployeeForm } from 'flight/prebook/types';
import { FlightSearchData } from 'flight/search/utils/flight-search-view-util';
import {
  AddOnsResponse,
  BookingRulesResponse,
  RefundPolicyResponse,
} from 'flight/shared/api';
import JourneyType from 'flight/shared/constants/journey-type';
import { Profile } from 'profile/types';

import { PaymentApproval } from '../types';

const SearchIdContext = createContext<Nullable<string>>(null!);
const FlightsContext = createContext<FlightSearchData[]>(null!);
const AddOnsContext = createContext<AddOnsResponse['flights']>(null!);
const PoliciesContext = createContext<RefundPolicyResponse['flights']>(null!);
const BookingRuleContext = createContext<BookingRulesResponse>(null!);
const IsInternationalContext = createContext<boolean>(null!);
const JourneyTypeContext = createContext<JourneyType>(null!);
const PassengersContext = createContext<PartialEmployee[]>(null!);
const NonEmployeeTravelersContext = createContext<FlightNonEmployeeForm[]>(
  null!
);
const ProfileContext = createContext<Profile>(null!);
const PaymentApprovalContext = createContext<PaymentApproval>(null!);
const SearchSpecContext = createContext<string>(null!);

type Props = {
  searchId: Nullable<string>;
  flights: FlightSearchData[];
  addOns: AddOnsResponse['flights'];
  policies: RefundPolicyResponse['flights'];
  bookingRule: BookingRulesResponse;
  isInternational: boolean;
  journeyType: JourneyType;
  passengers: PartialEmployee[];
  nonEmployeeTravelers: FlightNonEmployeeForm[];
  profile: Profile;
  paymentApproval: PaymentApproval;
  searchSpec: string;
  children: ReactNode;
};

export function FlightPrebookProvider(props: Props) {
  const {
    searchId,
    flights,
    addOns,
    policies,
    bookingRule,
    isInternational,
    journeyType,
    passengers,
    nonEmployeeTravelers,
    profile,
    paymentApproval,
    searchSpec,
    children,
  } = props;

  return (
    <SearchSpecContext.Provider value={searchSpec}>
      <SearchIdContext.Provider value={searchId}>
        <FlightsContext.Provider value={flights}>
          <AddOnsContext.Provider value={addOns}>
            <PoliciesContext.Provider value={policies}>
              <BookingRuleContext.Provider value={bookingRule}>
                <IsInternationalContext.Provider value={isInternational}>
                  <JourneyTypeContext.Provider value={journeyType}>
                    <PassengersContext.Provider value={passengers}>
                      <NonEmployeeTravelersContext.Provider
                        value={nonEmployeeTravelers}
                      >
                        <ProfileContext.Provider value={profile}>
                          <PaymentApprovalContext.Provider
                            value={paymentApproval}
                          >
                            {children}
                          </PaymentApprovalContext.Provider>
                        </ProfileContext.Provider>
                      </NonEmployeeTravelersContext.Provider>
                    </PassengersContext.Provider>
                  </JourneyTypeContext.Provider>
                </IsInternationalContext.Provider>
              </BookingRuleContext.Provider>
            </PoliciesContext.Provider>
          </AddOnsContext.Provider>
        </FlightsContext.Provider>
      </SearchIdContext.Provider>
    </SearchSpecContext.Provider>
  );
}

export function useSearchIdContext() {
  return useContext(SearchIdContext);
}
export function useFlightsContext() {
  return useContext(FlightsContext);
}
export function useAddOnsContext() {
  return useContext(AddOnsContext);
}
export function usePoliciesContext() {
  return useContext(PoliciesContext);
}
export function useBookingRuleContext() {
  return useContext(BookingRuleContext);
}
export function useIsInternationalContext() {
  return useContext(IsInternationalContext);
}
export function useJourneyTypeContext() {
  return useContext(JourneyTypeContext);
}
export function usePassengersContext() {
  return useContext(PassengersContext);
}
export function useNonEmployeeTravelersContext() {
  return useContext(NonEmployeeTravelersContext);
}
export function useProfileContext() {
  return useContext(ProfileContext);
}
export function usePaymentApprovalContext() {
  return useContext(PaymentApprovalContext);
}
export function useSearchSpecContext() {
  return useContext(SearchSpecContext);
}

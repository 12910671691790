import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  LocalizedLink,
  useAuth,
  useContentResource,
} from '@traveloka/ctv-core';
import { useFeatureControl } from '@traveloka/ctv-core/feature-control';
import Hours24 from '@traveloka/icon-kit-web/svg/light/ic_contact_24_hours_24px.svg';
import { Icon, Image, Text, Token } from '@traveloka/web-components';

import { formatMessage } from 'shared/utils/intl';

function Footer() {
  const contactUsFC = useFeatureControl('b2b-contact-us');
  const { user } = useAuth();
  const content = useContentResource().CorporateFooter;

  const hasContactUs = contactUsFC.enabled && user;

  return (
    <View style={Style.wrapper}>
      <View style={Style.content}>
        <View style={Style.topContent}>
          <View style={Style.leftContainer}>
            <Image
              style={Style.image}
              height={30}
              src={'/img/traveloka-for-corporates-white.png'}
            />
            <View style={Style.contactUsContainer}>
              <Icon src={Hours24} height={40} width={40} />
              <View style={Style.contactUsLabel}>
                <Text variant="ui-tiny" ink="muted">
                  {content.contactUsText}
                </Text>
                <Text style={Style.weightMedium} ink="white">
                  {content.contactUsValue}
                </Text>
              </View>
            </View>
          </View>
          {hasContactUs && (
            <View style={Style.container}>
              <>
                <Text style={Style.weightRegular} ink="white">
                  {content.travelokaBusinessTitle}
                </Text>
                <LocalizedLink to="/contact-us">
                  <Text variant="ui-tiny" ink="secondary">
                    {content.redirectContactUsText}
                  </Text>
                </LocalizedLink>
              </>
            </View>
          )}
          <View style={Style.container}>
            <>
              <Text style={Style.weightRegular} ink="white">
                {content.othersTitle}
              </Text>
              <LocalizedLink to="/terms-and-conditions" target="_blank">
                <Text variant="ui-tiny" ink="secondary">
                  {content.redirectTncText}
                </Text>
              </LocalizedLink>
            </>
          </View>
        </View>
        <View style={Style.copyright}>
          <Text variant="ui-small" ink="muted">
            {formatMessage(content.copyrightText, {
              year: new Date().getFullYear(),
            })}
          </Text>
        </View>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    marginTop: 'auto',
    backgroundColor: Token.color.uiDarkPrimary,
    alignItems: 'center',
  },
  content: {
    width: '100%',
    marginHorizontal: 'auto',
    maxWidth: 1260,
    paddingHorizontal: Token.spacing.l,
  },
  topContent: {
    width: '100%',
    paddingVertical: Token.spacing.xxl,
    flexDirection: 'row',
  },
  leftContainer: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  container: {
    flex: 1,
  },
  image: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  rightDivider: {
    borderRightWidth: 1,
    borderColor: Token.color.uiLightPrimary,
    paddingRight: Token.spacing.xs,
    marginRight: Token.spacing.xs,
  },
  contactUsContainer: {
    marginTop: Token.spacing.s,
    marginLeft: Token.spacing.l,
    flexDirection: 'row',
  },
  contactUsLabel: {
    marginLeft: Token.spacing.xs,
  },
  weightMedium: Token.typography.weightMedium,
  weightRegular: Token.typography.weightRegular,
  copyright: {
    width: '100%',
    alignItems: 'center',
    paddingVertical: Token.spacing.xxxxl,
    borderTopColor: Token.color.borderDivide,
    borderTopWidth: Token.border.width.thin,
  },
});

export default Footer;

export type JsonValue = string | number | object | [] | boolean | null;

type Cached<T> = T extends Date
  ? string
  : T extends Function
  ? never
  : T extends Object | any[]
  ? { [P in keyof T]: Cached<T[P]> }
  : T;

export function getRaw(key: string): string | null {
  return window.localStorage.getItem(key);
}

export function get<T extends any>(key: string): Nullable<Cached<T>> {
  try {
    const raw = window.localStorage.getItem(key);

    if (raw !== null) {
      return JSON.parse(raw) as Cached<T>;
    }

    return null;
  } catch (e) {
    if (e instanceof SyntaxError) {
      remove(key);
    }

    return null;
  }
}

export function set(key: string, data: JsonValue): boolean {
  try {
    if (data !== null && typeof data === 'object') {
      window.localStorage.setItem(key, JSON.stringify(data));
    } else {
      window.localStorage.setItem(key, String(data));
    }

    return true;
  } catch {
    return false;
  }
}

export function remove(key: string) {
  window.localStorage.removeItem(key);
}

export default { getRaw, get, set, remove };

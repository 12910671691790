import React, { useCallback } from 'react';
import { StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';

import { addDays } from 'date-fns';

import { Format, useLocalizedDateFormat } from '@traveloka/ctv-core';
import { InputDatepicker, InputDropdown } from '@traveloka/ctvweb-ui';
import { Item } from '@traveloka/ctvweb-ui/src/shared/components/form/InputDropdown/types';
import AutocompleteType, {
  Item as HotelAutocompleteItem,
} from '@traveloka/ctvweb-ui/src/hotel/HotelAutocomplete/types';
import { Item as HotelCardItem } from '@traveloka/ctvweb-ui/src/hotel/HotelCard/types';
import Chevron from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';
import Info from '@traveloka/icon-kit-web/svg/blue/ic_system_status_info-fill_16px.svg';
import InfoGray from '@traveloka/icon-kit-web/svg/lightSecondary/ic_system_status_info-fill_16px.svg';
import Guest from '@traveloka/icon-kit-web/svg/dark/ic_hotel_room_guest_24px.svg';
import CheckIn from '@traveloka/icon-kit-web/svg/dark/ic_system_calendar_in_24px.svg';
import Location from '@traveloka/icon-kit-web/svg/dark/ic_system_map_location_24px.svg';
import Duration from '@traveloka/icon-kit-web/svg/dark/ic_time_night_stay_24px.svg';
import Minus from '@traveloka/icon-kit-web/svg/darkBlue/ic_symbol_add_minus__16px.svg';
import MinusGray from '@traveloka/icon-kit-web/svg/lightSecondary/ic_symbol_add_minus__16px.svg';
import Plus from '@traveloka/icon-kit-web/svg/darkBlue/ic_symbol_add_plus_16px.svg';
import PlusGray from '@traveloka/icon-kit-web/svg/lightSecondary/ic_symbol_add_plus_16px.svg';
import Search from '@traveloka/icon-kit-web/svg/light/ic_system_search_24px.svg';
import Room from '@traveloka/icon-kit-web/svg/dark/ic_hotel_room_24px.svg';
import SearchDisabled from '@traveloka/icon-kit-web/svg/lightSecondary/ic_system_search_24px.svg';
import {
  useTheme,
  Button,
  Icon,
  Input,
  Text,
  Token,
  Tooltip,
} from '@traveloka/web-components';
import Label from '@traveloka/web-components/src/momentum/Input/Label';
import HotelAutocomplete from '../HotelAutocomplete/HotelAutocomplete';

import { appendTestId } from '../../shared/utils/TestUtil';

type Props = {
  // Spec
  numOfRoom: number;
  checkInDate: Date;
  numOfNight: number;

  // Form
  traveler: string;
  room: string;
  destination: string;
  destinationOptions: HotelAutocompleteItem[];
  destinationLoading: boolean;
  duration: string;
  durationOptions: Item[];
  destinationPlaceholder: string;

  // Handler
  onTravelerFocus?(): void;
  onRoomChange?(room: number): void;
  onDestinationChangeText?(text: string): void;
  onDestinationChange?(item: Item, type?: AutocompleteType): void;
  onCheckInDateChange?(date: Date): void;
  onDurationChange?(item: Item): void;
  onSearchPress(): void;

  // Label
  travelerLabel: string;
  roomLabel: string;
  destinationLabel: string;
  checkInLabel: string;
  durationLabel: string;
  checkOutLabel: string;

  // Misc
  dateFormat: Format;
  maxDate?: Date;

  guestError: boolean;
  guestErrorTooltip: string;
  roomError: boolean;
  roomErrorTooltip: string;
  destinationError: boolean;
  destinationErrorTooltip: string;
  destinationTypeMore: string;
  popularDestinationText: string;
  destinationEmptyTitle: string;
  destinationEmptyDescription: string;

  // Recently Search
  enableRecentSearch: boolean;
  recentlySearchCityText: string;
  recentlyViewedHotelText: string;
  recentlyViewedHotelWithKeywordText: string;
  startFromText: string;
  recentlySearchCityOptions: HotelAutocompleteItem[];
  recentlySearchCityLoading: boolean;
  recentlyViewedHotelOptions: HotelCardItem[];
  recentlyViewedHotelLoading: boolean;

  guestInfoTooltip?: string;
  searchLabel: string;
  isSearching?: boolean;
  searchEnabled?: boolean;
  testID?: string;
  formDisabled?: boolean;
};

export default function SearchForm(props: Props) {
  const {
    traveler,
    travelerLabel,
    room,
    roomLabel,
    numOfRoom,
    destination,
    destinationOptions,
    destinationLoading,
    destinationLabel,
    destinationPlaceholder,
    checkInDate,
    checkInLabel,
    checkOutLabel,
    duration,
    durationOptions,
    durationLabel,
    numOfNight,
    onTravelerFocus,
    onRoomChange,
    onDestinationChangeText,
    onDestinationChange,
    onCheckInDateChange,
    onDurationChange,
    onSearchPress,
    dateFormat,
    maxDate,
    guestError,
    guestErrorTooltip,
    roomError,
    roomErrorTooltip,
    destinationError,
    destinationErrorTooltip,
    guestInfoTooltip,
    enableRecentSearch,
    destinationTypeMore,
    popularDestinationText,
    destinationEmptyTitle,
    destinationEmptyDescription,
    recentlySearchCityText,
    recentlyViewedHotelText,
    recentlyViewedHotelWithKeywordText,
    startFromText,
    recentlySearchCityOptions,
    recentlySearchCityLoading,
    recentlyViewedHotelOptions,
    recentlyViewedHotelLoading,
    searchLabel,
    searchEnabled,
    isSearching,
    testID,
    formDisabled,
  } = props;

  const { format } = useLocalizedDateFormat();

  const checkOut = format(addDays(checkInDate, numOfNight), dateFormat);

  return (
    <View style={Style.container}>
      <View style={[Style.row, Style.row1]}>
        <View style={[Style.col, Style.longCol]}>
          <Tooltip
            content={
              <Text variant="ui-small" ink="muted" style={Style.tooltip}>
                {guestErrorTooltip}
              </Text>
            }
            show={guestError}
            offset={{ y: -20 }}
          >
            <View style={Style.labelWithIcon}>
              <Label text={travelerLabel} />
              {!!guestInfoTooltip &&
                (!formDisabled ? (
                  <Tooltip
                    content={
                      <Text
                        variant="ui-small"
                        ink="muted"
                        style={Style.tooltip}
                      >
                        {guestInfoTooltip}
                      </Text>
                    }
                    width={255}
                  >
                    <Icon src={Info} />
                  </Tooltip>
                ) : (
                  <Icon src={InfoGray} />
                ))}
            </View>
            <Input
              testID={appendTestId(testID, 'traveler.input')}
              value={traveler}
              editable={false}
              onFocus={onTravelerFocus}
              iconLeft={<Icon src={Guest} />}
              error={guestError}
              disabled={formDisabled}
            />
          </Tooltip>
        </View>
        <View style={[Style.col, Style.longCol]}>
          <Tooltip
            content={
              <Text variant="ui-small" ink="muted" style={Style.tooltip}>
                {roomErrorTooltip}
              </Text>
            }
            show={roomError}
            offset={{ y: -20 }}
          >
            <Label text={roomLabel} />
            <Input
              testID={appendTestId(testID, 'room.input')}
              variant="formal"
              value={room}
              editable={false}
              iconLeft={<Icon src={Room} />}
              iconRight={
                <Stepper
                  testID={appendTestId(testID, 'room')}
                  room={numOfRoom}
                  onChange={onRoomChange}
                  disabled={formDisabled}
                />
              }
              disabled={formDisabled}
            />
          </Tooltip>
        </View>
      </View>

      <View style={Style.destination}>
        <Tooltip
          content={
            <Text variant="ui-small" ink="muted">
              {destinationErrorTooltip}
            </Text>
          }
          show={destinationError}
          offset={{ y: -20 }}
        >
          {enableRecentSearch ? (
            <>
              <Label text={destinationLabel} />
              <HotelAutocomplete
                testID={appendTestId(testID, 'destination')}
                cityTestID={appendTestId(testID, 'recently-search-city')}
                value={destination}
                items={destinationOptions}
                itemsLoading={destinationLoading}
                cityItems={recentlySearchCityOptions}
                cityLoading={recentlySearchCityLoading}
                propertyItems={recentlyViewedHotelOptions}
                propertyLoading={recentlyViewedHotelLoading}
                placeholder={destinationPlaceholder}
                editable
                searchable
                error={destinationError ? destinationErrorTooltip : undefined}
                showError={false}
                onChangeText={onDestinationChangeText}
                onPressItem={onDestinationChange}
                iconLeft={<Icon src={Location} />}
                disabled={formDisabled}
                destinationTypeMore={destinationTypeMore}
                popularDestinationText={popularDestinationText}
                destinationEmptyTitle={destinationEmptyTitle}
                destinationEmptyDescription={destinationEmptyDescription}
                recentlySearchCityText={recentlySearchCityText}
                recentlyViewedHotelText={recentlyViewedHotelText}
                recentlyViewedHotelWithKeywordText={
                  recentlyViewedHotelWithKeywordText
                }
                startFromText={startFromText}
              />
            </>
          ) : (
            <>
              <Label text={destinationLabel} />
              <InputDropdown
                testID={appendTestId(testID, 'destination')}
                value={destination}
                items={destinationOptions}
                placeholder={destinationPlaceholder}
                editable
                error={destinationError ? destinationErrorTooltip : undefined}
                showError={false}
                onChangeText={onDestinationChangeText}
                onPressItem={onDestinationChange}
                iconLeft={<Icon src={Location} />}
                disabled={formDisabled}
              />
            </>
          )}
        </Tooltip>
      </View>

      <View style={[Style.row, Style.row3]}>
        <View style={Style.col}>
          <Label text={checkInLabel} />
          <InputDatepicker
            testID={appendTestId(testID, 'check-in')}
            value={checkInDate}
            dateFormat={dateFormat}
            minDate={new Date()}
            maxDate={maxDate}
            iconLeft={<Icon src={CheckIn} />}
            onDatePress={onCheckInDateChange}
            disabled={formDisabled}
          />
        </View>
        <View style={Style.col}>
          <Label text={durationLabel} />
          <InputDropdown
            testID={appendTestId(testID, 'duration')}
            value={duration}
            items={durationOptions}
            onPressItem={onDurationChange}
            iconLeft={<Icon src={Duration} />}
            iconRight={<Icon src={Chevron} />}
            disabled={formDisabled}
          />
        </View>
        <View style={Style.col}>
          <Label text={checkOutLabel} />
          <Text
            testID={appendTestId(testID, 'check-out')}
            style={Style.checkOut}
          >
            {checkOut}
          </Text>
        </View>
      </View>

      <View style={Style.action}>
        <Button
          testID={appendTestId(testID, 'search-button')}
          text={searchLabel}
          iconStart={() => {
            if (isSearching) {
              return null;
            }

            const iconSrc = searchEnabled ? Search : SearchDisabled;

            return <Icon src={iconSrc} />;
          }}
          onPress={onSearchPress}
          loading={isSearching}
          disabled={formDisabled || !searchEnabled}
        />
      </View>
    </View>
  );
}

type StepperProps = {
  room: number;
  onChange?(room: number): void;
  testID?: string;
  disabled?: boolean;
};

const exceptNumberRegex = /[^0-9]/g;
function Stepper(props: StepperProps) {
  const { room, onChange, testID, disabled } = props;
  const { color } = useTheme();
  const borderColorStyle = {
    borderColor: color.borderDivide,
  };

  const handleChangeText = useCallback(
    (text: string) => {
      const value = Number(text.replace(exceptNumberRegex, ''));

      !disabled && onChange && onChange(value);
    },
    [onChange, disabled]
  );

  function handleMinus() {
    onChange && onChange(room - 1);
  }
  function handlePlus() {
    onChange && onChange(room + 1);
  }

  return (
    <View style={Style.stepper}>
      <TouchableOpacity
        testID={appendTestId(testID, 'stepper.minus')}
        activeOpacity={0.5}
        style={[Style.stepperButton, Style.stepperButtonLeft, borderColorStyle]}
        onPress={handleMinus}
        disabled={disabled}
      >
        {disabled ? <Icon src={MinusGray} /> : <Icon src={Minus} />}
      </TouchableOpacity>
      <TextInput
        testID={appendTestId(testID, 'stepper.input')}
        style={[Style.input, borderColorStyle]}
        onChangeText={handleChangeText}
        value={String(room)}
      />
      <TouchableOpacity
        testID={appendTestId(testID, 'stepper.plus')}
        activeOpacity={0.5}
        style={[
          Style.stepperButton,
          Style.stepperButtonRight,
          borderColorStyle,
        ]}
        onPress={handlePlus}
        disabled={disabled}
      >
        {disabled ? <Icon src={PlusGray} /> : <Icon src={Plus} />}
      </TouchableOpacity>
    </View>
  );
}

const Style = StyleSheet.create({
  input: {
    // flex: 1,
    fontSize: Token.typography.uiSmall.fontSize,
    lineHeight: Token.typography.uiSmall.lineHeight,
    outlineWidth: 0,
    outlineStyle: 'none',
    width: 32,
    padding: Token.spacing.xxs,
    borderTopWidth: Token.border.width.thin,
    borderBottomWidth: Token.border.width.thin,
    textAlign: 'center',
  },
  stepper: {
    flexDirection: 'row',
    height: 32,
    marginVertical: -Token.spacing.xxs,
    marginRight: -Token.spacing.xxs,
  },
  stepperButton: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: Token.spacing.xs - 1,
    borderWidth: Token.border.width.thin,
  },
  stepperButtonLeft: {
    borderTopLeftRadius: Token.border.radius.normal,
    borderBottomLeftRadius: Token.border.radius.normal,
  },
  stepperButtonRight: {
    borderTopRightRadius: Token.border.radius.normal,
    borderBottomRightRadius: Token.border.radius.normal,
  },
  container: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.l,
  },
  row: {
    flexDirection: 'row',
    marginHorizontal: -Token.spacing.xs,
    marginBottom: Token.spacing.m,
  },
  row1: {
    zIndex: 3,
  },
  destination: {
    zIndex: 2,
    marginBottom: Token.spacing.m,
  },
  row3: {
    zIndex: 1,
  },
  col: {
    paddingHorizontal: Token.spacing.xs,
    flex: 1,
  },
  longCol: {
    flex: 1.5,
  },
  labelWithIcon: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  action: {
    alignItems: 'flex-end',
  },
  tooltip: {
    textAlign: 'center',
  },
  checkOut: {
    paddingVertical: Token.spacing.xs,
  },
});

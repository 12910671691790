import { StyleSheet } from 'react-native';

import { Token } from '@traveloka/web-components';

export const Style = StyleSheet.create({
  card: {
    padding: Token.spacing.m,
    overflow: 'visible',
  },
  sectionTitle: {
    marginBottom: Token.spacing.xs,
  },
  row: {
    marginHorizontal: -Token.spacing.s,
    flexDirection: 'row',
  },
  col: {
    paddingHorizontal: Token.spacing.s,
    flex: 1,
  },
  section: {
    marginBottom: Token.spacing.l,
  },
  group: {
    marginBottom: Token.spacing.m,
  },
  bold: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
});

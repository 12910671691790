import React, {
  createContext,
  useCallback,
  useMemo,
  PropsWithChildren,
  useContext,
} from 'react';

import { useFlightRequestRefundForm } from './FlightRequestRefundFormContext';
import {
  RequestRefundStep,
  useProductRequestRefundPage,
} from '../ProductRequestRefundPageContext';

type Action = {
  goToNextStep(): void;
  goToPreviousStep(): void;
};

const FlightRequestRefundNavigatorContext = createContext<Action>(undefined!);

export function FlightRequestRefundNavigatorProvider(
  props: PropsWithChildren<{}>
) {
  const [
    { currentStep },
    { changeCurrentStep },
  ] = useProductRequestRefundPage();
  const { mainRefundReason, travelerForms } = useFlightRequestRefundForm();

  const isReasonRequiresDocument = useMemo(
    () =>
      travelerForms
        .filter(travelerForm => travelerForm.checkboxValue)
        .some(travelerForm => {
          const check = mainRefundReason?.secondaryReasons?.find(
            secondaryReason =>
              secondaryReason.reason === travelerForm.secondaryReason
          )?.requiredDocument.length;

          return !!check;
        }),
    [travelerForms, mainRefundReason]
  );

  const goToNextStep = useCallback(() => {
    switch (currentStep) {
      case RequestRefundStep.REFUND_POLICY:
        changeCurrentStep(RequestRefundStep.SELECT_DETAILS);
        return;
      case RequestRefundStep.SELECT_DETAILS:
        if (isReasonRequiresDocument) {
          changeCurrentStep(RequestRefundStep.REFUND_DOCUMENT);
        } else {
          changeCurrentStep(RequestRefundStep.REVIEW_REQUEST);
        }
        return;
      case RequestRefundStep.REFUND_DOCUMENT:
        changeCurrentStep(RequestRefundStep.REVIEW_REQUEST);
    }
  }, [currentStep, isReasonRequiresDocument]);

  const goToPreviousStep = useCallback(() => {
    switch (currentStep) {
      case RequestRefundStep.SELECT_DETAILS:
        changeCurrentStep(RequestRefundStep.REFUND_POLICY);
        return;
      case RequestRefundStep.REFUND_DOCUMENT:
        changeCurrentStep(RequestRefundStep.SELECT_DETAILS);
        return;
      case RequestRefundStep.REVIEW_REQUEST:
        if (isReasonRequiresDocument) {
          changeCurrentStep(RequestRefundStep.REFUND_DOCUMENT);
        } else {
          changeCurrentStep(RequestRefundStep.SELECT_DETAILS);
        }
    }
  }, [currentStep, isReasonRequiresDocument]);

  return (
    <FlightRequestRefundNavigatorContext.Provider
      value={{ goToNextStep, goToPreviousStep }}
    >
      {props.children}
    </FlightRequestRefundNavigatorContext.Provider>
  );
}

export function useFlightRequestRefundNavigator() {
  return useContext(FlightRequestRefundNavigatorContext);
}

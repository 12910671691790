import React, { RefObject, useState, useRef, ChangeEvent } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import useUpload from '@traveloka/ctv-core/upload/useUpload';
import { Icon, Text, Token } from '@traveloka/web-components';
import PlusIcon from '@traveloka/icon-kit-web/svg/blue/ic_symbol_add_plus_16px.svg';
import DocumentIcon from '@traveloka/icon-kit-web/svg/dark/ic_system_document_24px.svg';

import {
  RefundDocumentType,
  useFlightRequestRefundFormAction,
} from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import {
  UploadFileResponse,
  UploadFileRequest,
  UPLOAD_FILE_API,
} from 'refund/shared/api';
import { RefundDocument } from 'refund/shared/api/types';

const acceptedMimes = ['image/jpeg', 'image/png'];

const acceptedExtension = ['jpeg', 'jpg', 'png'];

type RefundFileUploadProps = {
  travelerId: string;
  documentCategory: RefundDocument;
  document?: RefundDocumentType;
};

export default function RefundFileUpload(props: RefundFileUploadProps) {
  const { documentCategory, travelerId } = props;

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const inputFile: RefObject<HTMLInputElement> = useRef(null);

  const {
    addRequiredDocument,
    deleteRequiredDocument,
  } = useFlightRequestRefundFormAction();

  const content = useContentResource().CorporateFlightRequestRefundDocument;

  const uploadFile = useUpload<UploadFileResponse, UploadFileRequest>({
    path: UPLOAD_FILE_API,
    config: {
      onUploadProgress(progressEvent) {
        setUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    },
  });

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    event.preventDefault();
    const files = event.target.files;

    if (!files || !files.length) return;
    const file = files[0];
    const extension = file.name.split('.').pop() || '';

    // File size more than 2 MB
    if (
      file.size > 2000000 ||
      !acceptedMimes.includes(file.type) ||
      !acceptedExtension.includes(extension)
    ) {
      setError(true);
    } else {
      addRequiredDocument(travelerId, documentCategory, file);
      uploadFile({ uploadType: 'FLIGHT_REFUND' }, file)
        .then(res => {
          if (res.success) {
            addRequiredDocument(
              travelerId,
              documentCategory,
              file,
              res.data.url
            );
            setError(false);
            return;
          }
          deleteRequiredDocument(travelerId, documentCategory);
        })
        .catch(e => deleteRequiredDocument(travelerId, documentCategory));
    }
  }

  let fileNameText = content.selectUploadFileText;

  if (props.document?.file !== undefined && !props.document.valueToSubmit) {
    fileNameText = `${content.uploadingText} (${uploadProgress}%)`;
  } else if (props.document?.file) {
    fileNameText = props.document.file.name;
  }

  return (
    <>
      <input
        ref={inputFile}
        style={{ display: 'none' }}
        type="file"
        onChange={handleFileChange}
      />
      <TouchableOpacity
        onPress={() => {
          inputFile.current?.click();
        }}
      >
        <View style={Style.uploadFileContainer}>
          <Icon
            src={props.document ? DocumentIcon : PlusIcon}
            width={16}
            height={16}
          />
          <Text style={Style.selectUploadFileText} variant="ui-small">
            {fileNameText}
          </Text>
          {props.document && (
            <TouchableOpacity
              onPress={() => {
                deleteRequiredDocument(travelerId, documentCategory);
                inputFile.current!.value = '';
              }}
            >
              <Text variant="ui-small" ink="destructive-interactive">
                {content.deleteFileText}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </TouchableOpacity>
      {error && (
        <Text variant="ui-small" ink="destructive-interactive">
          {content.documentErrorMessage}
        </Text>
      )}
    </>
  );
}

const Style = StyleSheet.create({
  uploadFileContainer: {
    paddingVertical: Token.spacing.m,
    paddingHorizontal: Token.spacing.ml,
    flexDirection: 'row',
    borderColor: Token.color.borderDivide,
    borderWidth: Token.border.width.thick,
    borderRadius: Token.border.radius.normal,
  },
  selectUploadFileText: {
    marginLeft: Token.spacing.ml,
    marginRight: Token.spacing.xl,
  },
});

import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import htmr from 'htmr';

import InfoIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_status_info-fill_16px.svg';
import { Icon, Text, Token } from '@traveloka/web-components';
import { Props as TextProps } from '@traveloka/web-components/src/momentum/Text/Text';

type Props = {
  style?: StyleProp<ViewStyle>;
  header: ReactNode;
  content?: string;
  contentInk?: TextProps['ink'];
  ink?: TextProps['ink'];
  variant?: TextProps['variant'];
  action?: ReactNode;
};

export default function InfoBox(props: Props) {
  const {
    style,
    header: text,
    content,
    contentInk = 'highlight',
    ink = 'secondary',
    variant = 'ui-tiny',
    action,
  } = props;

  return (
    <View style={[styles.container, style]}>
      <View style={styles.info}>
        <Icon src={InfoIcon} height={16} width={16} />
        <Text ink={ink} variant={variant} style={styles.infoText}>
          {text}
        </Text>
      </View>
      {content && (
        <Text variant="ui-small" ink={contentInk}>
          {htmr(content)}
        </Text>
      )}
      {action && <View style={styles.action}>{action}</View>}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: Token.spacing.s,
    backgroundColor: Token.color.uiBlueLight,
    borderRadius: Token.border.radius.normal,
    borderColor: Token.color.uiBluePrimary,
    borderWidth: Token.border.width.thin,
  },
  info: {
    flexDirection: 'row',
    marginBottom: Token.spacing.xxs,
  },
  infoText: {
    marginStart: Token.spacing.xs,
  },
  action: {
    marginTop: Token.spacing.s,
    marginBottom: Token.spacing.xs,
  },
});

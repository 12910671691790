import React from 'react';
import { View, ViewProps } from 'react-native';

import {
  ErrorMessage,
  Input as MomentumInput,
} from '@traveloka/web-components';
import { Props as MomentumInputProps } from '@traveloka/web-components/src/momentum/Input/Input';

import Helper from './Helper';
import Label from './Label';

export type Props = {
  label?: string;
  error?: string;
  leftHelper?: string;
  rightHelper?: string;
  style?: ViewProps['style'];
  inputStyle?: MomentumInputProps['style'];
  showError?: boolean;
} & Omit<MomentumInputProps, 'variant' | 'error' | 'style'>;

export default function Input(props: Props) {
  const {
    label,
    error,
    leftHelper,
    rightHelper,
    disabled,
    style,
    inputStyle,
    showError = true,
    ...inputProps
  } = props;

  const hasError = !!error && !disabled;
  const hasHelper = (leftHelper || rightHelper) && !hasError;

  return (
    <View style={style}>
      {label && <Label text={label} disabled={disabled} />}
      <label>
        <MomentumInput
          {...inputProps}
          textInputStyle={inputStyle}
          disabled={disabled}
          error={hasError}
          variant="formal"
        />
      </label>
      {hasError && showError && <ErrorMessage text={error!} />}
      {hasHelper && (
        <Helper
          leftText={leftHelper}
          rightText={rightHelper}
          disabled={disabled}
        />
      )}
    </View>
  );
}

import React, { useMemo, ComponentProps, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useController, useFormContext } from 'react-hook-form';

import { InputDropdown, InputGroup } from '@traveloka/ctvweb-ui';
import { Icon } from '@traveloka/web-components';
import Chevron from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_24px.svg';
import { Item } from '@traveloka/ctvweb-ui/src/shared/components/form/InputDropdown/types';

import { BaggageAddOns } from 'flight/shared/api/types';

import { FieldProps } from '../types';

type InputDropdownProps = ComponentProps<typeof InputDropdown>;

type BaggageItem = {
  label: string;
  value: BaggageAddOns;
};

export type Props = {
  items: BaggageItem[];
  onPressItem?: (item: BaggageItem) => void;
} & FieldProps<BaggageAddOns> &
  Omit<InputDropdownProps, 'value' | 'items' | 'onPressItem' | 'defaultValue'>;

export default function BaggageField(props: Props) {
  const {
    defaultValue,
    name,
    validate,
    onFocus,
    label,
    leftHelper,
    rightHelper,
    disabled,
    style,
    onPressItem,
    items,
    testID,
    ...inputDropdownProps
  } = props;

  const itemMap = useMemo(() => {
    return items.reduce((obj, info) => {
      obj[info.value.id] = {
        label: info.label,
        value: info.value,
      };

      return obj;
    }, {} as Dictionary<BaggageItem>);
  }, [items]);

  const { control, resetField } = useFormContext();
  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });

  useEffect(() => {
    resetField(name, { defaultValue });
  }, []);

  const itemList = useMemo(() => {
    return items.map(item => ({
      label: item.label,
      value: item.value.id,
    }));
  }, [items]);

  function handleFocus() {
    if (typeof onFocus === 'function') {
      onFocus();
    }
  }

  function handlePressItem(item: Item, onChange: (event: any) => void) {
    const baggageItem = itemMap[item.value];

    onChange(baggageItem.value);
    onPressItem && onPressItem(baggageItem);
  }

  return (
    <InputGroup
      label={label}
      error={error?.message}
      leftHelper={leftHelper}
      rightHelper={rightHelper}
      disabled={disabled}
      style={style}
    >
      <InputDropdown
        {...inputDropdownProps}
        testID={testID}
        disabled={disabled}
        error={error?.message}
        showError={false}
        items={itemList}
        onFocus={handleFocus}
        onPressItem={item => handlePressItem(item, onChange)}
        onBlur={onBlur}
        value={value ? itemMap[value.id].label : ''}
        style={Style.input}
        iconRight={<Icon src={Chevron} />}
      />
    </InputGroup>
  );
}

const Style = StyleSheet.create({
  input: {
    zIndex: 1,
  },
});

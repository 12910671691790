import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Animated, ViewProps } from 'react-native';

import { Token } from '@traveloka/web-components';

type FadeDirectionBehavior = 'up' | 'down' | 'none';

type DirectionBehaviorValueMap = { [key in FadeDirectionBehavior]: number };

type Props = {
  children: ReactNode;
  direction: FadeDirectionBehavior;
  style?: ViewProps['style'];
  visible: boolean;
};

const directionValue: DirectionBehaviorValueMap = {
  up: Token.spacing.xs,
  down: -Token.spacing.xs,
  none: 0,
};

function Fade(props: Props) {
  const { children, direction, style, visible } = props;
  const [showChildren, setShowChildren] = useState(visible);
  const animatable = useRef(new Animated.Value(Number(visible)));

  useEffect(() => {
    Animated.timing(animatable.current, {
      toValue: Number(visible),
      duration: Token.animation.timing.instant,
    }).start(() => !visible && setShowChildren(visible));

    visible && setShowChildren(visible);
  }, [visible]);

  const animationStyle = {
    opacity: animatable.current,
    transform: [
      {
        translateY: animatable.current.interpolate({
          inputRange: [0, 1],
          outputRange: [directionValue[direction], 0],
        }),
      },
    ],
  };

  if (!showChildren) return null;

  return (
    <Animated.View style={[animationStyle, style]}>{children}</Animated.View>
  );
}

Fade.defaultProps = {
  direction: 'up',
};

export default Fade;

import { Permission } from '@traveloka/ctv-core';

import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const CompanyInvoiceList: PrivatePageComponent = function() {
  return null;
};

CompanyInvoiceList.routeProtection = function({ user }) {
  const hasPermission = user.has(Permission.INVOICE_LIST_VIEW);

  if (!hasPermission) {
    return { path: '/' };
  }

  return { path: '/financial/?tab=invoice' };
};

export default CompanyInvoiceList;

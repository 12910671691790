import { format } from 'date-fns';

import { CheckSearchSpecRequest } from 'approval-system/shared/api';
import ProductType from 'approval-system/shared/constants/ProductType';
import { Store } from 'hotel/prebook/types';
import { convertToJava } from 'shared/utils/currency';
import { JAVA_DATE } from 'shared/utils/date';

export function generateSearchSpecPayload(
  spec: Omit<Store, 'checkInDate' | 'checkOutDate'> & {
    checkInDate: Date;
    checkOutDate: Date;
  }
): CheckSearchSpecRequest {
  return {
    productRequestId: spec.productRequestId || '',
    productType: ProductType.HOTEL,
    travelers: spec.travelers.map(t => t.email),
    hotelPreBookRequest: {
      checkInDate: format(spec.checkInDate, JAVA_DATE),
      checkOutDate: format(spec.checkOutDate, JAVA_DATE),
      geoId: spec.geoId!,
      geoType: spec.geoType!,
      numOfRooms: spec.numOfRooms.toString(),
      propertyId: spec.propertyId,
      rateKey: spec.rateKey,
      roomId: spec.roomId,
      roomOccupancy: spec.roomOccupancy.toString(),
      nonEmployeeTravelers: spec.nonEmployeeTravelers,
      totalFareFromClient: convertToJava(spec.totalFare),
    },
  };
}

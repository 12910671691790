import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Link } from '@traveloka/ctvweb-ui';
import { Text, Token, Icon } from '@traveloka/web-components';
import BellIcon from '@traveloka/icon-kit-web/svg/dark/ic_system_alert_24px.svg';
import { useContentResource } from '@traveloka/ctv-core';

// Hide Covid Banner
// handle visibility with this const
// set this const with 'false' since it is not relevant anymore
// issue: https://29022131.atlassian.net/browse/TRAFE-8111
const SHOW_BANNER = false;

export default function CovidBanner() {
  const content = useContentResource().CorporateCovidBanner;

  if (!SHOW_BANNER) return null;

  return (
    <View style={Style.container}>
      <Icon src={BellIcon} width={20} height={20} />
      <Text style={Style.text} variant="title-2">
        {content.infoText}{' '}
        <Link to="https://www.traveloka.com/en-id/safe-travel" target="_blank">
          <Text variant="title-2" ink="highlight">
            {content.linkText}
          </Text>
        </Link>
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    backgroundColor: Token.color.uiYellowPrimary,
    width: '100%',
    height: 55,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    top: 0,
    // @ts-ignore
    position: 'sticky',
  },
  text: {
    marginLeft: Token.spacing.xs,
  },
});

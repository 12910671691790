import React, { useRef, useState } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { useOnClickOutside, Fade, Input } from '@traveloka/ctvweb-ui';
import ClockIcon from '@traveloka/icon-kit-web/svg/darkBlue/ic_system_clock_24px.svg';
import { Card, Icon, Text, Token } from '@traveloka/web-components';
import { useHoverable } from '@traveloka/web-components/future';

import { formatValue } from 'hotel/prebook/utils/SpecialRequestUtils';

type Value = [number, number];

type Props = {
  value: Value;
  onChange(value: Value): void;
};

const hours = Array.from({ length: 24 }, (_, index) => index);
const minutes = Array.from({ length: 12 }, (_, index) => index * 5);

export default function TimePicker(props: Props) {
  const { value, onChange } = props;

  const [visible, setVisible] = useState(false);
  const cr = useContentResource().CorporateHotelPrebookSpecialRequest;
  const rootRef = useRef<View>(null);

  useOnClickOutside(rootRef, () => {
    setVisible(false);
  });

  function handleHourPress(hour: number) {
    onChange([hour, value[1]]);
  }
  function handleMinutePress(minute: number) {
    onChange([value[0], minute]);
  }

  return (
    <View ref={rootRef}>
      <Input
        value={formatValue(value)}
        editable={false}
        onFocus={() => setVisible(true)}
        iconLeft={<Icon src={ClockIcon} />}
      />
      <Fade visible={visible} style={Style.dropdown}>
        <Card style={Style.content} elevation="float">
          <ValueList
            options={hours}
            label={cr.hour}
            value={value[0]}
            onPress={handleHourPress}
          />
          <ValueList
            options={minutes}
            label={cr.minute}
            value={value[1]}
            onPress={handleMinutePress}
          />
        </Card>
      </Fade>
    </View>
  );
}

type ValueListProps = {
  options: number[];
  value: number;
  onPress(value: number): void;
  label: string;
};

function ValueList(props: ValueListProps) {
  const { options, value, onPress, label } = props;

  const [hovered, bindings] = useHoverable();

  return (
    <View style={Style.list}>
      <Text variant="ui-small">{label}</Text>
      <ScrollView style={Style.flatList}>
        {options.map(item => {
          const selected = item === value;

          return (
            <TouchableOpacity
              key={item}
              {...bindings}
              onPress={() => onPress(item)}
              style={[Style.item, (selected || hovered) && Style.itemActive]}
            >
              {selected && <View style={Style.dot} />}
              <Text>{item.toString().padStart(2, '0')}</Text>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
}

const Style = StyleSheet.create({
  content: {
    flexDirection: 'row',
    paddingVertical: Token.spacing.s,
    paddingHorizontal: Token.spacing.xs,
    height: 200,
  },
  dropdown: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 10,
    paddingTop: Token.spacing.xs,
    top: '100%',
    width: 240,
    height: 200,
  },
  list: {
    flex: 1,
    paddingHorizontal: Token.spacing.xs,
  },
  flatList: {
    height: 250,
    borderWidth: Token.border.width.thin,
    borderColor: Token.color.uiLightSecondary,
  },
  item: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: Token.spacing.s,
  },
  itemActive: {
    backgroundColor: Token.color.uiLightStain,
  },
  dot: {
    marginVertical: 'auto',
    position: 'absolute',
    borderRadius: Token.border.radius.rounded,
    width: 8,
    height: 8,
    backgroundColor: Token.color.uiBluePrimary,
    start: 12,
  },
});

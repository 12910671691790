import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Token, Image, Button } from '@traveloka/web-components';
import {
  GenericHomeContent_v2,
  GenericHomeSectionName,
  GenericHomeButton,
  GenericHomeRegistrationDocumentModal,
  GenericHomeTriangleMask,
} from '@traveloka/ctvweb-ui/generic';
import GenericHomeWatchDemoButton from '../GenericHomeWatchDemoButton/GenericHomeWatchDemoButton';
import { useLayout } from '@traveloka/web-components/future';

type Props = {
  content: {
    sectionName: string;
    heading: string;
    stepTitle01: string;
    stepTitle02: string;
    stepTitle03: string;
    stepDescription01: string;
    stepDescription02: string;
    stepDescription03: string;
    stepImageUrl01: string;
    stepImageUrl02: string;
    stepImageUrl03: string;
    requiredDocumentButtonText: string;
    statisticHeading: string;
    statistics: Array<{
      title: string;
      description: string;
    }>;
    bannerText01: string;
    bannerText02: string;
    requiredDocumentContentRes: {
      requiredDocumentHeading: string;
      requiredDocumentParagraph: string;
      requiredDocumentImportantNoteParagraph: string;
      requiredDocumentList: Array<string>;
      signUpButtonText: string;
      closeButtonText: string;
    };
    signUpButtonText: string;
    watchDemoButtonText: string;
  };
  onPressSignUp(): void;
  onPressWatchVideo(): void;
};

export default function GenericHomeHowToRegister(props: Props) {
  const { content, onPressSignUp, onPressWatchVideo } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewPort, viewPortBindings] = useLayout();
  const viewPortWidth = viewPort.width;

  return (
    <View>
      <GenericHomeContent_v2>
        <GenericHomeSectionName text={content.sectionName} />
        <Text variant="hero" style={Style.sectionHeading}>
          {content.heading}
        </Text>

        <View style={Style.flexContainer}>
          <View style={Style.column}>
            <Image src={content.stepImageUrl01} height={60} />
            <Text variant="headline" style={Style.stepTitle}>
              {content.stepTitle01}
            </Text>
            <Text>{content.stepDescription01}</Text>
          </View>

          <View style={[Style.column, Style.columnMargin]}>
            <Image src={content.stepImageUrl02} height={60} />
            <Text variant="headline" style={Style.stepTitle}>
              {content.stepTitle02}
            </Text>
            <Text>{content.stepDescription02}</Text>
            <Button
              variant="text"
              text={content.requiredDocumentButtonText}
              onPress={() => setIsModalVisible(true)}
              style={Style.buttonRegistrationDocument}
            />
          </View>

          <View style={[Style.column, Style.columnMargin]}>
            <Image src={content.stepImageUrl03} height={60} />
            <Text variant="headline" style={Style.stepTitle}>
              {content.stepTitle03}
            </Text>
            <Text>{content.stepDescription03}</Text>
          </View>
        </View>

        <View style={Style.banner}>
          <Text variant="headline" style={Style.bannerText}>
            {content.bannerText01}
          </Text>
          <GenericHomeButton
            text={content.signUpButtonText}
            onPress={onPressSignUp}
          />
        </View>
      </GenericHomeContent_v2>

      <View
        style={{
          position: 'relative',
          // @ts-ignore,
          backgroundImage: `linear-gradient(259.89deg, #1BA0E2 -0.23%, #004D7A 99.41%)`,
        }}
        {...viewPortBindings}
      >
        <GenericHomeTriangleMask
          width={viewPortWidth}
          style={Style.triangleMask}
        />
        <GenericHomeContent_v2 style={Style.statisticSection}>
          <View style={[Style.sectionHeading, Style.statisticSectionHeading]}>
            <Text variant="display" ink="white">
              {content.statisticHeading}
            </Text>
            <View style={Style.tileContainer}>
              <Image src={'/img/tile.png'} style={Style.tile} />
            </View>
          </View>

          <View style={Style.flexContainer}>
            {content.statistics.map((statistic, index) => {
              return (
                <View
                  key={index}
                  style={[Style.column, index > 0 && Style.columnMargin]}
                >
                  <Text
                    ink="white"
                    variant="display"
                    style={Style.statisticTitle}
                  >
                    {statistic.title}
                  </Text>
                  <Text ink="white">{statistic.description}</Text>
                </View>
              );
            })}
          </View>

          <View style={[Style.banner, Style.borderTop]}>
            <Text variant="headline" ink="white" style={Style.bannerText}>
              {content.bannerText02}
            </Text>
            <View style={Style.buttonGroup}>
              <GenericHomeButton
                text={content.signUpButtonText}
                style={Style.bannerButton}
                onPress={onPressSignUp}
              />
              <GenericHomeWatchDemoButton
                text={content.watchDemoButtonText}
                onPress={onPressWatchVideo}
              />
            </View>
          </View>
        </GenericHomeContent_v2>
      </View>

      <GenericHomeRegistrationDocumentModal
        content={content.requiredDocumentContentRes}
        isVisible={isModalVisible}
        onCloseModal={() => setIsModalVisible(false)}
        onPressSignUp={onPressSignUp}
      />
    </View>
  );
}

const Style = StyleSheet.create({
  sectionHeading: {
    marginTop: Token.spacing.xxxl,
    marginBottom: Token.spacing.xxxxl,
  },
  flexContainer: {
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  columnMargin: {
    marginLeft: Token.spacing.xxl * 2,
  },
  stepTitle: {
    marginTop: Token.spacing.xxl,
    marginBottom: Token.spacing.l,
  },
  buttonRegistrationDocument: {
    marginTop: Token.spacing.m,
  },
  banner: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingVertical: Token.spacing.xxxl,
    marginTop: Token.spacing.xxxxl,
  },
  bannerText: {
    flex: 1,
    paddingRight: Token.spacing.l,
  },
  bannerButton: {
    marginRight: Token.spacing.m,
  },
  statisticSection: {
    paddingTop: Token.spacing.xxxl * 2,
  },
  statisticSectionHeading: {
    flexDirection: 'row',
  },
  tileContainer: {
    flexBasis: 300,
    flexGrow: 0,
    position: 'relative',
    marginLeft: Token.spacing.xxxxl,
  },
  tile: {
    width: 300,
    height: 'auto',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  statisticTitle: {
    fontWeight: '300',
    marginBottom: Token.spacing.l,
  },
  buttonGroup: {
    flexDirection: 'row',
  },
  borderTop: {
    borderTopWidth: Token.border.width.thin,
    borderColor: Token.opacity.seeThrough(Token.color.uiLightPrimary),
  },
  triangleMask: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    transform: [{ rotate: '180deg' }],
  },
});

import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Fade } from '@traveloka/ctvweb-ui';
import { Button, Card, Token } from '@traveloka/web-components';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import { useProductRequest } from 'approval-system/shared/contexts/ProductRequestContext';
import HotelSearchForm from 'hotel/search/components/HotelSearchForm/HotelSearchForm';

export default function Header() {
  const [isFormExpanded, setIsFormExpanded] = useState(
    window.location.hash === '#form'
  );
  const productRequest = useProductRequest();
  const {
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();
  const content = useContentResource().CorporateHotelDetail;

  const handleToggleForm = useCallback(() => {
    setIsFormExpanded(s => !s);
  }, []);

  return (
    <View style={Style.section}>
      <View style={Style.container}>
        <Button
          text={content.changeSearchButtonText}
          loading={isAccountStatusFetching}
          onPress={() => fetchAccountStatus(handleToggleForm)}
        />
      </View>
      <Fade visible={isFormExpanded} style={Style.form}>
        <Card elevation="raised" style={Style.card}>
          <HotelSearchForm
            funnelSource="HOTEL_DETAIL_SF"
            tripRequestEnabled={productRequest.enabled && !!productRequest.data}
          />
        </Card>
      </Fade>
    </View>
  );
}

const Style = StyleSheet.create({
  section: {
    marginBottom: Token.spacing.l,
    zIndex: 1,
  },
  container: {
    alignItems: 'flex-end',
  },
  form: {
    marginTop: Token.spacing.ml,
  },
  card: {
    overflow: 'visible',
  },
});

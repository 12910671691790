import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import useUpload from '@traveloka/ctv-core/upload/useUpload';
import { InfoBoxV2 as InfoBox } from '@traveloka/ctvweb-ui';
import { Card, Text } from '@traveloka/web-components';

import { PrebookStatus } from 'approval-system/shared/api/types';
import { usePrebook } from 'hotel/prebook/contexts/PrebookProvider';
import SharedStyle from 'hotel/prebook/hotelPrebook.style';
import {
  PrebookingAttachmentUploadRequest,
  PrebookingAttachmentUploadResponse,
  PREBOOKING_ATTACHMENT_UPLOAD,
} from 'hotel/shared/api';
import { regex, required } from 'shared/utils/validator';
import DocumentField from 'shared/components/form/DocumentField/DocumentField';
import InputField from 'shared/components/form/InputField/InputField';
import { formatMessage } from 'shared/utils/intl';

const documentExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
const documentMaxSizeInMB = '2';

export default function AdditionalForm() {
  const prebook = usePrebook();
  const { preBookingStatus } = prebook.checkSearchSpec;
  const content = useContentResource().CorporateHotelPrebookAdditionalInfo;

  const upload = useUpload<
    PrebookingAttachmentUploadResponse,
    PrebookingAttachmentUploadRequest
  >({
    path: PREBOOKING_ATTACHMENT_UPLOAD,
  });

  if (preBookingStatus === PrebookStatus.NEED_APPROVAL) {
    return null;
  }

  async function handleUpload(_documentType: string, file: File) {
    const res = await upload({ type: 'BOOKING' }, file);

    if (res.success) return res.data;
    else throw res.error;
  }

  return (
    <>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {content.title}
      </Text>
      <Card style={SharedStyle.card}>
        <InfoBox
          style={SharedStyle.fieldGroup}
          header={content.attachmentInfoBoxTitle}
          content={content.attachmentInfoBoxContent}
        />
        <View style={SharedStyle.fieldGroup}>
          <Text style={SharedStyle.sectionTitle} variant="title-3">
            {content.uploadAttachmentField}
          </Text>
          <DocumentField
            document={{
              checkbox: null,
              docType: 'BOOKING',
              extensions: documentExtensions,
              label: content.uploadAttachmentField,
              mandatory: false,
              maxSizeInMB: documentMaxSizeInMB,
              multiple: false,
              placeholder: '',
              subLabel: null,
              tooltip: null,
            }}
            helper={formatMessage(content.attachmentInfo, {
              size: documentMaxSizeInMB,
              extension: documentExtensions.join('/'),
            })}
            name="attachment"
            onChange={handleUpload}
            uploadButtonCr={content.uploadAttachmentButtonText}
            retryButtonCr={content.uploadAttachmentRetryButtonText}
          />
        </View>
        <InputField
          inputStyle={styles.textarea}
          name="notes"
          label={content.notesField}
          leftHelper={content.notesFieldDescription}
          multiline
          maxLength={200}
          validate={value => {
            if (required(value) && regex(value, /[<>]/)) {
              return content.notesRequiredErrorMessage;
            }

            return undefined;
          }}
        />
      </Card>
    </>
  );
}

const styles = StyleSheet.create({
  textarea: {
    height: 72,
  },
});

import React, { ReactNode } from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';

import { Token } from '@traveloka/web-components';
import { Spacing } from '@traveloka/web-components/src/momentum/Token';

type Props = {
  children: ReactNode;
  spacing?: Spacing;
  style?: StyleProp<ViewStyle>;
};

function GenericHomeContent(props: Props) {
  const { children, spacing, style } = props;

  const paddingVertical = {
    paddingVertical: spacing && Token.spacing[spacing],
  };

  return (
    <View style={[Style.wrapper, paddingVertical, style]}>
      <View style={Style.container}>{children}</View>
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    flex: 1,
    zIndex: 1,
    minWidth: 1008,
    position: 'relative',
  },
  container: {
    width: 1008,
    marginHorizontal: 'auto',
  },
});

export default GenericHomeContent;

import { Country } from '@traveloka/ctv-core/auth/types';

export type JavaCurrencyValue = {
  currency: string;
  amount: string;
  decimalPoints: string;
  displayAmount: string;
};

export type CurrencyValue = {
  currency: string;
  amount: number;
  decimalPoints: number;
};

export function convert(value: JavaCurrencyValue): CurrencyValue {
  return {
    currency: value.currency,
    amount: Number(value.displayAmount),
    decimalPoints: Number(value.decimalPoints),
  };
}

export function convertToJava(value: CurrencyValue): JavaCurrencyValue {
  const { currency, amount, decimalPoints } = value;

  return {
    currency,
    amount: String(amount * Math.pow(10, decimalPoints)),
    decimalPoints: String(decimalPoints),
    displayAmount: amount.toFixed(decimalPoints),
  };
}

export function getCurrencyPosition(country: Country) {
  switch (country) {
    case Country.ID:
    case Country.TH:
      return 'left';
    case Country.VN:
      return 'right';
  }
}

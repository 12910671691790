import { useEffect } from 'react';

import { getQueryString, useTracker } from '@traveloka/ctv-core';

import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

export default function EmailLoginRedirect() {
  const history = useLocalizedHistory();
  const track = useTracker('ors-page');

  const qs = getQueryString<{ email: string }>();

  useEffect(() => {
    track('corpB2b.onlineRegistration.webEvent', {
      eventName: 'CORP_B2B.ONLINE_REGISTRATION.WEB_EVENT',
      eventVersion: '1.0.0',
      pageName: 'ORS_PAGE',
      pageCategory: 'ORS_PAGE',
      eventValue: 'ACCOUNT_IS_ACTIVATED',
      emailAddress: qs.email,
    }).send();

    history.replace('/');
  }, []);

  return null;
}

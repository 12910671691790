import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  Card,
  Text,
  Token,
  Icon,
  useTheme,
  Button,
} from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';
import { GenericHomeButton } from '@traveloka/ctvweb-ui/generic';
import IcSystemDocumentMultiple from '@traveloka/icon-kit-web/svg/blue/ic_system_document_multiple_24px.svg';
import IcSystemStatusInfo from '@traveloka/icon-kit-web/svg/blue/ic_system_status_info_24px.svg';
import IcSystemCheckmark from '@traveloka/icon-kit-web/svg/dark/ic_system_checkmark_24px.svg';

type Props = {
  content: {
    requiredDocumentHeading: string;
    requiredDocumentParagraph: string;
    requiredDocumentImportantNoteParagraph: string;
    requiredDocumentList: Array<string>;
    signUpButtonText: string;
    closeButtonText: string;
  };
  isVisible: boolean;
  onCloseModal: () => void;
  onPressSignUp: () => void;
};

function GenericHomeRegistrationDocumentModal(props: Props) {
  const { color } = useTheme();
  const { content, isVisible, onCloseModal, onPressSignUp } = props;

  return (
    <Modal
      isVisible={isVisible}
      backdropOpacity={0.9}
      backdropColor={Token.color.uiDarkNeutral}
      onBackdropPress={onCloseModal}
    >
      <Card style={Style.card}>
        <View style={Style.marginBottom}>
          <Icon src={IcSystemDocumentMultiple} width={32} height={32} />
        </View>

        <Text variant="display" style={Style.marginBottom}>
          {content.requiredDocumentHeading}
        </Text>

        <Text style={Style.marginBottom}>
          {content.requiredDocumentParagraph}
        </Text>

        <View
          style={[
            Style.infoBox,
            {
              borderColor: color.brandGodwitBlue,
              backgroundColor: color.uiBlueLight,
            },
          ]}
        >
          <Icon src={IcSystemStatusInfo} />
          <Text style={Style.boxText}>
            {content.requiredDocumentImportantNoteParagraph}
          </Text>
        </View>

        {content.requiredDocumentList.map((document, _index) => {
          return (
            <View
              key={_index}
              style={[Style.box, { borderBottomColor: color.borderSubtle }]}
            >
              <Icon src={IcSystemCheckmark} />
              <Text style={Style.boxText}>{document}</Text>
            </View>
          );
        })}

        <View style={Style.buttonGroup}>
          <Button
            variant="secondary"
            text={content.closeButtonText}
            onPress={onCloseModal}
          />
          <GenericHomeButton
            text={content.signUpButtonText}
            style={Style.buttonSignUp}
            onPress={onPressSignUp}
          />
        </View>
      </Card>
    </Modal>
  );
}

const Style = StyleSheet.create({
  card: {
    width: 600,
    paddingVertical: Token.spacing.xl,
    paddingHorizontal: Token.spacing.xxl,
  },
  infoBox: {
    padding: Token.spacing.m,
    borderWidth: Token.border.width.thin,
    borderRadius: Token.border.radius.normal,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  box: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: Token.spacing.l,
    borderBottomWidth: Token.border.width.thin,
  },
  boxText: {
    marginLeft: Token.spacing.m,
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: Token.spacing.l,
  },
  buttonSignUp: {
    marginLeft: Token.spacing.m,
  },
  marginBottom: {
    marginBottom: Token.spacing.l,
  },
});

export default GenericHomeRegistrationDocumentModal;

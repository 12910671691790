import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactElement,
  ReactNode,
} from 'react';
import { findNodeHandle, StyleSheet, View, ViewStyle } from 'react-native';
import {
  useFormContext,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
  useController,
} from 'react-hook-form';

import { useContentResource } from '@traveloka/ctv-core';
import { Input } from '@traveloka/ctvweb-ui';
import {
  Card,
  Checkbox,
  Radio as MRadio,
  RadioDisc,
  Text,
  Token,
} from '@traveloka/web-components';

import SharedStyle from 'hotel/prebook/hotelPrebook.style';
import { MAX_GUESTS } from 'hotel/search/constants/SearchConstant';

import {
  SpecialRequestType,
  SpecialRequestValue,
  FormValue,
} from '../../types';
import TimePicker from '../TimePicker/TimePicker';

type ElementProps = {
  field: ControllerRenderProps<FormValue, 'specialRequest.option'>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FormValue>;
};

type RequestOption = { key: string } & (
  | { type: Exclude<SpecialRequestType, 'select' | 'time'> }
  | {
      type: 'select';
      options: string[];
    }
  | {
      type: 'time';
      default: [number, number];
    }
);

const OPTIONS: RequestOption[] = [
  { type: 'boolean', key: 'NON_SMOKING_ROOM' },
  { type: 'boolean', key: 'CONNECTING_ROOM' },
  { type: 'boolean', key: 'HIGH_FLOOR' },
  { type: 'select', key: 'BED_TYPE', options: ['TWIN', 'DOUBLE'] },
  { type: 'time', key: 'CHECK_IN_TIME', default: [15, 0] },
  { type: 'time', key: 'CHECK_OUT_TIME', default: [11, 0] },
  { type: 'text', key: 'OTHER' },
];

const MAX_LENGTH = 100;

export default function SpecialRequest() {
  const cr = useContentResource().CorporateHotelPrebookSpecialRequest;

  return (
    <>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {cr.title}
      </Text>
      <Card style={[Style.card, { zIndex: MAX_GUESTS + 1 }]}>
        <Text variant="ui-tiny" style={Style.forwarding}>
          {cr.requestInfo}
        </Text>
        <View style={Style.content}>
          {OPTIONS.map((option, index) => (
            <RequestField
              key={index}
              option={option}
              style={{ zIndex: OPTIONS.length - index }}
            />
          ))}
        </View>
      </Card>
    </>
  );
}

type Props = {
  option: RequestOption;
  style: ViewStyle;
};

function RequestField(props: Props) {
  const { option, style } = props;
  const {
    CorporateHotelPrebookSpecialRequest: cr,
    CorporateHotelPrebookSpecialRequestOption: optionCr,
  } = useContentResource();

  const label = optionCr[option.key];
  const defaultValue = useMemo(() => {
    let value: SpecialRequestValue;
    switch (option.type) {
      case 'select':
        value = optionCr[option.options[0]];
        break;
      case 'time':
        value = option.default;
        break;
      case 'text':
        value = '';
        break;
      default:
        value = label;
        break;
    }

    return {
      selected: false,
      value,
      type: option.type,
      label,
    };
  }, [option]);

  const { control } = useFormContext<FormValue>();
  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: `specialRequest.${option.key}`,
    rules: {
      validate(v) {
        if (!v?.selected) {
          return;
        } else if (!v.value) {
          return cr.required;
        }

        return undefined;
      },
    },
    defaultValue,
  });

  let element: ReactElement | null = null;
  switch (option.type) {
    case 'select':
      element = (
        <Radio
          value={value.value as string}
          onChange={newValue =>
            onChange({ ...value, value: newValue.toString(), onChange })
          }
          options={option.options.map(option => optionCr[option])}
        />
      );
      break;
    case 'time':
      element = (
        <TimePicker
          value={value.value as [number, number]}
          onChange={newValue => onChange({ ...value, value: newValue })}
        />
      );
      break;
    case 'text':
      element = (
        <Input
          value={value.value as string}
          multiline
          numberOfLines={3}
          onChangeText={text => onChange({ ...value, value: text })}
          maxLength={MAX_LENGTH}
          rightHelper={`${value.value.length}/${MAX_LENGTH}`}
          error={error?.message}
          onBlur={onBlur}
        />
      );
      break;
    default:
      element = null;
      break;
  }

  return (
    <View style={[Style.field, style]}>
      <Checkbox
        label={label}
        checked={value.selected}
        onChange={selected => onChange({ ...value, selected })}
      />
      {element && <Collapsible visible={value.selected}>{element}</Collapsible>}
    </View>
  );
}

type RadioProps = {
  value: string;
  options: string[];
  onChange(value: string | number): void;
};

function Radio(props: RadioProps) {
  const { value, options, onChange } = props;

  return (
    <MRadio.Group value={value} onChange={onChange} labelID={'sr'}>
      {options.map(option => (
        <MRadio.Button
          key={option}
          label={option}
          value={option}
          ButtonComponent={props => {
            const { isSelected, isFocused, label } = props;

            return (
              <View style={Style.radio} key={option}>
                <RadioDisc isSelected={isSelected} isFocused={isFocused} />
                <Text style={Style.radioText}>{label}</Text>
              </View>
            );
          }}
        />
      ))}
    </MRadio.Group>
  );
}

type CollapsibleProps = {
  visible: boolean;
  children?: ReactNode;
};

function Collapsible(props: CollapsibleProps) {
  const { visible, children } = props;
  const heightRef = useRef(0);
  const viewRef = useRef<any>();
  const prevVisible = useRef(visible);
  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (!viewRef.current) return;

    const el = (findNodeHandle(viewRef.current) as any) as HTMLElement;

    if (!visible && prevVisible.current) setHide(true);
    prevVisible.current = visible;

    function listener(e: TransitionEvent) {
      if (e.target !== el) return;

      setHide(!visible);
    }

    el.addEventListener('transitionend', listener);

    return () => {
      el.removeEventListener('transitionend', listener);
    };
  }, [visible]);

  const rootStyle = [
    Style.collapsible,
    { height: 0 },
    visible && hide && { height: undefined },
    visible && { height: heightRef.current },
    hide && Style.hide,
  ];

  return (
    <View ref={viewRef} style={rootStyle}>
      <View
        onLayout={e => (heightRef.current = e.nativeEvent.layout.height)}
        style={Style.collapsibleContent}
      >
        {children}
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  card: {
    marginBottom: Token.spacing.l,
    overflow: 'visible',
  },
  forwarding: {
    padding: Token.spacing.m,
    backgroundColor: Token.color.uiLightStain,
    borderTopEndRadius: Token.border.radius.normal,
    borderTopStartRadius: Token.border.radius.normal,
  },
  content: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: Token.spacing.m,
    paddingTop: Token.spacing.xxs,
  },
  field: {
    flex: 1,
    flexBasis: '50%',
    marginTop: Token.spacing.s,
  },
  collapsible: {
    transitionProperty: 'height',
    transitionDuration: `${Token.animation.timing.instant}ms`,
  } as ViewStyle,
  collapsibleContent: {
    paddingStart: 36,
    paddingTop: Token.spacing.xs,
  },
  hide: {
    overflow: 'hidden',
  },
  radio: {
    flexDirection: 'row',
    paddingVertical: Token.spacing.xs,
  },
  radioText: {
    marginStart: Token.spacing.s,
  },
});

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';
import { useParams } from 'react-router-dom';

import {
  CreditPaymentRequest,
  CreditPaymentResponse,
  CreditPaymentStatus,
  CREDIT_PAYMENT,
} from 'credit-limit/api';

import { useApi, useAuth, PaymentMethod } from '@traveloka/ctv-core';

import {
  CreditCardExistResponse,
  CREDIT_CARD_EXIST_API,
} from 'company/shared/api';
import {
  BookingDetailsRequest as FlightBookingDetailsRequest,
  BookingDetailsResponse as FlightBookingDetailsResponse,
  FLIGHT_DETAIL as FLIGHT_DETAIL_API,
} from 'flight/shared/api';
import {
  BookingDetailRequest as HotelBookingDetailsRequest,
  BookingDetailResponse as HotelBookingDetailsResponse,
  BOOKING_DETAIL_API as HOTEL_DETAIL_API,
} from 'hotel/shared/api';
import { ProductType } from 'payment/select/api';
import BookingStatus from 'postbooking/shared/constants/BookingStatus';

type ProductTypeParam = 'flight' | 'hotel';

type State = {
  isLoading: boolean;
  isSuccess: boolean;
  booking: FlightBookingDetailsResponse | HotelBookingDetailsResponse;
  creditLimit: {
    creditPaymentStatus: CreditPaymentStatus | undefined;
    isFetching: boolean;
  };
  creditCard: {
    registered: boolean;
    isFetching: boolean;
  };
};

function initialzer(isLoading: boolean, isFetching: boolean): State {
  return {
    isLoading,
    isSuccess: false,
    booking: undefined!,
    creditLimit: {
      creditPaymentStatus: undefined,
      isFetching,
    },
    creditCard: {
      registered: false,
      isFetching,
    },
  };
}

const PaymentContext = createContext<State>(undefined!);
const PaymentDispatchContext = createContext({
  confirmBookingStatus() {},
  refetchPaymentStatus() {},
});

export function PaymentStateProvider(props: PropsWithChildren<{}>) {
  const { type: productType, bookingId } = useParams<{
    type: string;
    bookingId: string;
  }>();

  const { paymentMethod } = useAuth().user!;

  const [state, setState] = useState(initialzer(true, true));
  const refetchPaymentStatus = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        creditLimit: {
          ...prevState.creditLimit,
          isFetching: true,
        },
      })),
    []
  );

  const fetchFlightBooking = useApi<
    FlightBookingDetailsResponse,
    FlightBookingDetailsRequest
  >({
    domain: 'booking',
    method: 'post',
    path: FLIGHT_DETAIL_API,
  });
  const fetchHotelBooking = useApi<
    HotelBookingDetailsResponse,
    HotelBookingDetailsRequest
  >({
    domain: 'booking',
    method: 'post',
    path: HOTEL_DETAIL_API,
  });

  const fetchCreditPaymentStatus = useApi<
    CreditPaymentResponse,
    CreditPaymentRequest
  >({
    domain: 'booking',
    method: 'post',
    path: CREDIT_PAYMENT,
  });

  const fetchCC = useApi<CreditCardExistResponse>({
    domain: 'management',
    method: 'post',
    path: CREDIT_CARD_EXIST_API,
  });

  useEffect(() => {
    if (paymentMethod !== PaymentMethod.INVOICE) {
      setState(prevState => ({
        ...prevState,
        creditLimit: {
          isFetching: false,
          creditPaymentStatus: 'SUFFICIENT',
        },
      }));
      return;
    }

    fetchCreditPaymentStatus({
      bookingId,
      productType: convertProductType(productType as ProductTypeParam),
    })
      .then(res => {
        if (res.success) {
          return {
            creditLimit: {
              isFetching: false,
              creditPaymentStatus: res.data.creditPaymentStatus,
            },
          };
        }

        throw new Error();
      })
      .catch(() => ({
        creditLimit: {
          isFetching: false,
          creditPaymentStatus: undefined,
        },
      }))
      .then(res => setState(prevState => ({ ...prevState, ...res })));
  }, [state.creditLimit.isFetching]);

  useEffect(() => {
    if (paymentMethod !== PaymentMethod.CREDIT_CARD) {
      setState(prevState => ({
        ...prevState,
        creditCard: {
          isFetching: false,
          registered: true,
        },
      }));
      return;
    }

    fetchCC({})
      .then(res => {
        if (res.success) {
          return {
            creditCard: {
              isFetching: false,
              registered: res.data.exist,
            },
          };
        }

        throw new Error();
      })
      .catch(() => ({
        creditCard: {
          isFetching: false,
          registered: false,
        },
      }))
      .then(res => setState(prevState => ({ ...prevState, ...res })));
  }, []);

  useEffect(() => {
    if ((productType as ProductTypeParam) === 'flight') {
      fetchFlightBooking({ bookingId })
        .then(res => {
          if (res.success) {
            return {
              isLoading: false,
              isSuccess: true,
              booking: res.data,
            };
          }

          throw new Error();
        })
        .catch(() => initialzer(false, false))
        .then(res => setState(prevState => ({ ...prevState, ...res })));
    } else if ((productType as ProductTypeParam) === 'hotel') {
      fetchHotelBooking({ bookingId })
        .then(res => {
          if (res.success) {
            return {
              isLoading: false,
              isSuccess: true,
              booking: res.data,
            };
          }

          throw new Error();
        })
        .catch(() => initialzer(false, false))
        .then(res => setState(prevState => ({ ...prevState, ...res })));
    }
  }, [bookingId, fetchFlightBooking, fetchHotelBooking, productType]);

  const confirmBookingStatus = useCallback(() => {
    setState(s => {
      // Spread to force new object creation
      const newState = { ...s };
      if ((productType as ProductTypeParam) === 'flight') {
        (newState.booking as FlightBookingDetailsResponse).flightBookingDetail.status =
          BookingStatus.WAITING_FOR_ISSUANCE;
      } else if ((productType as ProductTypeParam) === 'hotel') {
        (newState.booking as HotelBookingDetailsResponse).status =
          BookingStatus.WAITING_FOR_ISSUANCE;
      }
      return newState;
    });
  }, [productType]);

  const dispatchContextValue = useMemo(
    () => ({
      confirmBookingStatus,
      refetchPaymentStatus,
    }),
    [confirmBookingStatus, refetchPaymentStatus]
  );

  return (
    <PaymentContext.Provider value={state}>
      <PaymentDispatchContext.Provider value={dispatchContextValue}>
        {props.children}
      </PaymentDispatchContext.Provider>
    </PaymentContext.Provider>
  );
}

export function usePaymentStateFlight() {
  const payment = useContext(PaymentContext);

  return {
    ...payment,
    booking: payment.booking as FlightBookingDetailsResponse,
  };
}

export function usePaymentStateHotel() {
  const payment = useContext(PaymentContext);

  return {
    ...payment,
    booking: payment.booking as HotelBookingDetailsResponse,
  };
}

export function usePaymentState() {
  return useContext(PaymentContext);
}

export function usePaymentStateDispatch() {
  return useContext(PaymentDispatchContext);
}

function convertProductType(productType: ProductTypeParam): ProductType {
  switch (productType) {
    case 'flight':
      return 'FLIGHT';
    case 'hotel':
      return 'HOTEL';
  }
}

import React from 'react';

import { View, ViewStyle } from 'react-native';
import { useTheme } from '@traveloka/web-components';

type Props = {
  width: number;
  style?: ViewStyle;
};

export default function GenericHomeTriangleMask(props: Props) {
  const { width, style } = props;
  const { color } = useTheme();

  return (
    <View
      style={[
        {
          width: 0,
          height: 0,
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderRightColor: 'transparent',
          borderRightWidth: width,
          borderBottomWidth: width * 0.1,
          borderBottomColor: color.lightPrimary,
        },
        style,
      ]}
    />
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApi } from '@traveloka/ctv-core';
import { ApiResult } from '@traveloka/ctv-core/api/types';
import {
  EntryListRequest,
  EntryListResponse,
  GET_ENTRY_LIST,
  Operator,
} from '@traveloka/ctv-core/pad';
import { useContentResource } from '@traveloka/ctv-core/resource';

import {
  CheckSearchSpecRequest,
  CheckSearchSpecResponse,
  CHECK_SEARCH_SPEC_API,
} from 'approval-system/shared/api';
import { PrebookStatus } from 'approval-system/shared/api/types';
import { PartialEmployee } from 'company/types';
import { FlightNonEmployeeForm } from 'flight/prebook/types';
import { convertNonEmployeeTypeToPayload } from 'flight/search/utils/flight-search-spec-util';
import { generateSearchSpecPayload } from 'flight/search/utils/searchSpecCheckUtil';
import {
  AddOnsRequest,
  AddOnsResponse,
  ADDONS_API,
  BookingRulesRequest,
  BookingRulesResponse,
  BOOKING_RULES_API,
  RefundPolicyRequest,
  RefundPolicyResponse,
  REFUND_POLICY_API,
} from 'flight/shared/api';
import { NonEmployeeType } from 'flight/shared/api/types';
import JourneyType from 'flight/shared/constants/journey-type';
import { GET_PROFILE, ProfileResponse } from 'profile/api';
import { Profile } from 'profile/types';
import { StepHeader, Content, Footer, Page } from 'shared/components/Layout';

import FlightPrebookContent from './components/FlightPrebookContent/FlightPrebookContent';
import { PaymentApproval } from './types';
import store, { FlightPrebookStore } from './utils/store';

type Api<T> = Promise<ApiResult<T>>;

export default function FlightPrebook() {
  const params = useParams<{ storage: string }>();

  const content = useContentResource().CorporateFlightPrebook;
  const [isLoading, setIsLoading] = useState(true);
  const [addOns, setAddOns] = useState<AddOnsResponse['flights']>([]);
  const [policies, setPolicies] = useState<RefundPolicyResponse['flights']>([]);
  const [profile, setProfile] = useState<Profile>({} as Profile);
  const [passengers, setPassengers] = useState<PartialEmployee[]>([]);
  const [nonEmployeeTravelers, setNonEmployeeTravelers] = useState<
    FlightNonEmployeeForm[]
  >([]);
  const [bookingRule, setBookingRule] = useState<BookingRulesResponse>({
    requiresBirthDateForInternational: false,
    requiresBirthDate: false,
    requiresBirthDateForChildren: false,
    requiresBirthDateForInfant: false,
    requiresDocumentNoForInternational: false,
    requiresNationality: false,
    requiresDocumentNoForDomestic: false,
    requiresId: false,
  });
  const [paymentApproval, setPaymentApproval] = useState<PaymentApproval>({
    preBookingStatus: 'FAILED',
  });

  const prebook = useMemo(() => store.get(params.storage), [params.storage]);

  const getProfile = useApi<ProfileResponse>({
    domain: 'management',
    method: 'post',
    path: GET_PROFILE,
  });

  const entryList = useApi<
    EntryListResponse<PartialEmployee>,
    EntryListRequest
  >({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_LIST,
  });

  const getRefundPolicy = useApi<RefundPolicyResponse, RefundPolicyRequest>({
    domain: 'booking',
    method: 'post',
    path: REFUND_POLICY_API,
  });

  const retrieveAddOns = useApi<AddOnsResponse, AddOnsRequest>({
    domain: 'booking',
    method: 'post',
    path: ADDONS_API,
  });

  const getBookingRules = useApi<BookingRulesResponse, BookingRulesRequest>({
    domain: 'booking',
    method: 'post',
    path: BOOKING_RULES_API,
  });

  const checkSearchSpec = useApi<
    CheckSearchSpecResponse,
    CheckSearchSpecRequest
  >({
    domain: 'booking',
    method: 'post',
    path: CHECK_SEARCH_SPEC_API,
  });

  useEffect(() => {
    if (prebook) {
      const selectedFlightIds =
        prebook.journeyType === JourneyType.PACKAGE_RT
          ? [prebook.flights[0].flightId]
          : prebook.flights.map(flight => flight.flightId);

      const commonReq = {
        journeyType: prebook.journeyType,
        flightIds: selectedFlightIds,
      };

      const searchSpecPayload = generateSearchSpecPayload(prebook);

      const apis: [
        Api<AddOnsResponse>,
        Api<RefundPolicyResponse>,
        Promise<ApiResult<BookingRulesResponse>>,
        Api<ProfileResponse>,
        Api<EntryListResponse<PartialEmployee>> | undefined,
        Api<CheckSearchSpecResponse>
      ] = [
        retrieveAddOns(commonReq),
        getRefundPolicy(commonReq),
        getBookingRules(commonReq),
        getProfile({}),
        prebook.passengers.length !== 0
          ? entryList({
              entityType: 'employeeList',
              search: {
                entriesCount: prebook.passengers.length,
                filter: [
                  {
                    fieldName: 'employee_id',
                    arguments: {
                      value: prebook.passengers.map(
                        traveler => traveler.employeeId
                      ),
                      operator: Operator.IN,
                    },
                  },
                ],
              },
            })
          : undefined,
        checkSearchSpec(searchSpecPayload),
      ];

      Promise.all(apis).then(res => {
        const [
          addOns,
          refundPolicy,
          bookingRule,
          profile,
          employeeList,
          checkSearchSpecRes,
        ] = res;

        if (bookingRule.success) {
          setBookingRule(bookingRule.data);
        }

        if (addOns.success) {
          setAddOns(addOns.data.flights);
        }

        if (refundPolicy.success) {
          setPolicies(refundPolicy.data.flights);
        }

        if (profile.success) {
          setProfile(profile.data.profile);
        }

        if (employeeList?.success) {
          const sortedTravelers = employeeList.data.entries.sort(
            (a, b) =>
              findTravelerIndex(prebook, a.employeeId) -
              findTravelerIndex(prebook, b.employeeId)
          );

          setPassengers(sortedTravelers);
        }

        if (checkSearchSpecRes.success) {
          setPaymentApproval({
            tripRequestId: prebook.tripRequestId,
            preBookingStatus: checkSearchSpecRes.data.preBookingStatus,
            productRequestId: prebook.productRequestId,
            tripName: prebook.tripName,
            approverName: prebook.approverName,
            approverReason: prebook.approverReason,
          });
        }

        // Non-employee Bookings
        if (prebook.nonEmployeeTravelers) {
          setNonEmployeeTravelers(
            ([] as FlightNonEmployeeForm[]).concat(
              createNonEmployeeTravelerForm(
                NonEmployeeType.ADULT,
                prebook.nonEmployeeTravelers[
                  convertNonEmployeeTypeToPayload(NonEmployeeType.ADULT)
                ]
              ),
              createNonEmployeeTravelerForm(
                NonEmployeeType.CHILD,
                prebook.nonEmployeeTravelers[
                  convertNonEmployeeTypeToPayload(NonEmployeeType.CHILD)
                ]
              ),
              createNonEmployeeTravelerForm(
                NonEmployeeType.INFANT,
                prebook.nonEmployeeTravelers[
                  convertNonEmployeeTypeToPayload(NonEmployeeType.INFANT)
                ]
              )
            )
          );
        }

        setIsLoading(false);
      });
    }
  }, []);

  const hasPaymentApproval =
    paymentApproval.preBookingStatus === PrebookStatus.NEED_APPROVAL;

  const steps = useMemo(() => {
    if (!paymentApproval.preBookingStatus) {
      return [];
    }

    if (hasPaymentApproval) {
      return [content.step1Approval, content.step2Approval];
    }

    return [content.step1, content.step2];
  }, [paymentApproval, hasPaymentApproval]);

  return (
    <Page>
      <StepHeader current={0} steps={steps} />
      <Content>
        <FlightPrebookContent
          addOns={addOns}
          bookingRule={bookingRule}
          isLoading={isLoading}
          passengers={passengers}
          nonEmployeeTravelers={nonEmployeeTravelers}
          policies={policies}
          profile={profile}
          paymentApproval={paymentApproval}
        />
      </Content>
      <Footer />
    </Page>
  );
}

function findTravelerIndex(prebook: FlightPrebookStore, id: string) {
  return prebook.passengers.findIndex(t => t.employeeId === id);
}

function createNonEmployeeTravelerForm(
  type: NonEmployeeType,
  length: number
): FlightNonEmployeeForm[] {
  return Array.from({
    length,
  }).map(() => ({
    isEmployee: false,
    title: null!,
    type,
    fullName: '',
    addOns: [],
  }));
}

import React, { useEffect, useRef } from 'react';
import { Animated, Easing, StyleSheet, View } from 'react-native';

// @ts-ignore
import IcSystemCheckmark from '@traveloka/icon-kit-web/react/IcSystemCheckmark';

import { Text, Token } from '@traveloka/web-components';

export default function SuccessResetPassword() {
  const { CorporateCognitoLogin } = CRQuery;

  const translateY = useRef(new Animated.Value(-50)).current;
  const scale = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(scale, {
      toValue: 1,
      duration: 1000,
      easing: Easing.in(Easing.ease),
    }).start();
    Animated.timing(translateY, {
      toValue: 0,
      duration: 2500,
      easing: Easing.bounce,
    }).start();
  }, []);

  return (
    <>
      <View style={styles.imageWrapper}>
        <Animated.View
          style={[
            styles.image,
            {
              transform: [
                {
                  translateY,
                },
                {
                  scaleX: scale,
                },
                {
                  scaleY: scale,
                },
              ],
              opacity: scale,
            },
          ]}
        >
          <IcSystemCheckmark
            color={Token.color.uiGreenPrimary}
            width={36}
            height={36}
          />
        </Animated.View>
      </View>
      <Text style={styles.text}>
        {CorporateCognitoLogin.successResetPasswordText}
      </Text>
    </>
  );
}

const CRQuery = {
  CorporateCognitoLogin: {
    successResetPasswordText:
      'Your password has been successfully reset. Please login with your new password.',
  },
};

const styles = StyleSheet.create({
  imageWrapper: {
    width: '100%',
    alignItems: 'center',
  },
  image: {
    borderRadius: Token.border.radius.rounded,
    borderWidth: 3,
    borderColor: Token.color.uiGreenPrimary,
    padding: Token.spacing.xs,
  },
  text: {
    textAlign: 'center',
    marginTop: Token.spacing.m,
  },
});

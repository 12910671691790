import React, { useMemo } from 'react';

import { differenceInCalendarDays } from 'date-fns';

import {
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { Token } from '@traveloka/web-components';

import { usePaymentStateHotel } from 'payment/select/contexts/PaymentContext';
import { formatMessage } from 'shared/utils/intl';

import DetailCard from './DetailCard';

export default function HotelDetailCard() {
  const { booking } = usePaymentStateHotel();
  const { tripId, guests } = booking;
  const {
    propertyInfo,
    checkInDate,
    checkOutDate,
    roomsInfo,
  } = booking.hotelBookingDetail;
  const [{ name }] = roomsInfo;

  const { format } = useLocalizedDateFormat();
  const content = useContentResource().CorporateHotelPayment;

  const checkIn = new Date(checkInDate);
  const checkOut = new Date(checkOutDate);
  const duration = differenceInCalendarDays(checkOut, checkIn);

  const travelers = useMemo(
    () =>
      guests.map(guest => ({
        name: [guest.firstName, guest.lastName].filter(Boolean).join(' '),
        subtitle: guest.division
          ? guest.division
          : guest.age
          ? formatMessage(content.nonEmployeeAgeText, { num: guest.age })
          : !guest.isEmployee
          ? content.nonEmployeeText
          : undefined,
      })),
    [guests]
  );

  const productBreakdown = useMemo(
    () => [
      {
        testID: 'confirmation-payment.trip-detail.hotel.property-name',
        label: format(checkIn, 'SHORT_WEEKDAY'),
      },
      {
        testID: 'confirmation-payment.trip-detail.hotel.duration',
        label: formatMessage(content.durationContent, { num: duration }),
      },
      {
        testID: 'confirmation-payment.trip-detail.hotel.room-name',
        label: name,
      },
      {
        testID: 'confirmation-payment.trip-detail.hotel.num-of-rooms',
        label: formatMessage(content.roomContent, { num: roomsInfo.length }),
      },
    ],
    [checkIn, duration, name, roomsInfo.length]
  );

  return (
    <DetailCard
      productBreakdown={productBreakdown}
      // @ts-ignore
      productColor={Token.color.brandGoodNight}
      productName={propertyInfo.name}
      travelers={travelers}
      travelersLabel={content.travelersLabelText}
      tripId={tripId}
    />
  );
}

import React, {
  useEffect,
  useState,
  PropsWithChildren,
  ReactChild,
} from 'react';
import { StyleSheet, View } from 'react-native';

import { useAuth } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { Divider } from '@traveloka/ctvweb-ui';
import { StarRating } from '@traveloka/ctvweb-ui/hotel';
import {
  Card,
  Checkbox,
  Collapsible,
  Text,
  Token,
} from '@traveloka/web-components';

import { useSortFilter } from 'hotel/search/contexts/SortFilterContext';
import AccomodationType from 'hotel/shared/constants/AccomodationType';
import Slider from 'legacy/Slider/Slider';
import { CurrencyValue } from 'shared/utils/currency';
import { formatCurrency, getCurrencyByCountry } from 'shared/utils/intl';

const MIN_PRICE = 0;
const MAX_PRICE = 15000000;

export default function FilterBox() {
  const [{ stars, accommodationTypes }, dispatch] = useSortFilter();

  const content = useContentResource().CorporateHotelSearchFilter;

  const availableTypes = [
    { value: AccomodationType.HOTEL, label: content.typeHotelText },
    { value: AccomodationType.HOSTEL, label: content.typeHostelText },
    { value: AccomodationType.VILLA, label: content.typeVillaText },
    { value: AccomodationType.RESORT, label: content.typeResortText },
    { value: AccomodationType.APARTMENT, label: content.typeApartmentText },
    {
      value: AccomodationType.BED_AND_BREAKFAST,
      label: content.typeBedAndBreakfastText,
    },
    { value: AccomodationType.CAMPING, label: content.typeCampingText },
    { value: AccomodationType.HOMESTAY, label: content.typeHomestayText },
    { value: AccomodationType.GUESTHOUSE, label: content.typeGuesthouseText },
    { value: AccomodationType.OTHER, label: content.typeOtherText },
  ];

  return (
    <Card>
      <View style={Style.content}>
        <Text>{content.filterTitle}</Text>
        <Text variant="ui-small" ink="secondary">
          {content.filterInfoText}
        </Text>
      </View>
      <Divider margin="none" subtle />
      <FilterCollapsible title={content.priceFilterTitle}>
        <PriceFilter />
      </FilterCollapsible>
      <Divider margin="none" subtle />
      <FilterCollapsible title={content.starFilterTitle}>
        {Array.from({ length: 5 }, (_, index) => {
          const star = index + 1;

          return (
            <StarFilter
              testID={`hotel.search.filter.star.${star}`}
              key={star}
              checked={stars.includes(star)}
              star={star}
              onChange={() => dispatch({ type: 'toggleStar', star })}
            />
          );
        })}
      </FilterCollapsible>
      <Divider margin="none" subtle />
      <FilterCollapsible title={content.typeFilterTitle}>
        {availableTypes.map(type => (
          <AccomodationFilter
            testID={`hotel.search.filter.accommodation-type.${type.value}`}
            key={type.value}
            checked={accommodationTypes.includes(type.value)}
            onChange={() =>
              dispatch({
                type: 'toggleAccommodationType',
                accommodationType: type.value,
              })
            }
            label={type.label}
          />
        ))}
      </FilterCollapsible>
    </Card>
  );
}

type FilterCollapsibleProps = {
  title: string;
};

function FilterCollapsible(props: PropsWithChildren<FilterCollapsibleProps>) {
  const { title, children } = props;

  return (
    <Collapsible
      title={<Text>{title}</Text>}
      headerStyle={Style.collapsibleHeader}
      contentStyle={Style.collapsibleContent}
      defaultIsCollapsed={false}
    >
      {children as ReactChild}
    </Collapsible>
  );
}

const step = 50000;

function PriceFilter() {
  const [filter, dispatch] = useSortFilter();
  const minPrice = filter.minPrice ?? MIN_PRICE;
  const maxPrice = filter.maxPrice ?? MAX_PRICE;
  const [display, setDisplay] = useState({
    min: minPrice,
    max: maxPrice,
  });
  const { user } = useAuth();

  const defaultCurrency: CurrencyValue = {
    currency: getCurrencyByCountry(user?.country),
    decimalPoints: 0,
    amount: 0,
  };

  useEffect(() => {
    setDisplay(s => {
      if (s.min !== minPrice || s.max !== maxPrice) {
        return {
          min: minPrice,
          max: maxPrice,
        };
      }

      return s;
    });
  }, [setDisplay, minPrice, maxPrice]);

  return (
    <>
      <View style={Style.priceDisplay}>
        <Text variant="ui-small">
          {formatCurrency({ ...defaultCurrency, amount: display.min })}
        </Text>
        <Text variant="ui-small">
          {formatCurrency({ ...defaultCurrency, amount: display.max })}
        </Text>
      </View>
      <Slider
        min={0}
        max={15000000}
        step={step}
        value={[display.min, display.max]}
        onChange={([min, max]) => {
          setDisplay({
            min: Math.min(min, display.max - step),
            max: Math.max(max, display.min + step),
          });
        }}
        pushable={false}
        onAfterChange={([min, max]) => {
          let minPrice: number | null = min;
          let maxPrice: number | null = max;

          if (min === MIN_PRICE && max === MAX_PRICE) {
            minPrice = maxPrice = null;
          }

          dispatch({ type: 'setMinPrice', minPrice });
          dispatch({ type: 'setMaxPrice', maxPrice });
        }}
      />
    </>
  );
}

type StarFilterProps = {
  checked: boolean;
  onChange(value: boolean): void;
  star: number;
  testID?: string;
};

function StarFilter(props: StarFilterProps) {
  const { checked, onChange, star, testID } = props;

  return (
    <View style={Style.filter}>
      <Checkbox checked={checked} onChange={onChange} testID={testID}>
        <Checkbox.Control />
        <StarRating size="large" star={star} style={Style.starRating} />
      </Checkbox>
    </View>
  );
}

type AccomodationFilterProps = {
  checked: boolean;
  onChange(value: boolean): void;
  label: string;
  testID?: string;
};

function AccomodationFilter(props: AccomodationFilterProps) {
  const { checked, onChange, label, testID } = props;

  return (
    <View style={Style.filter}>
      <Checkbox checked={checked} onChange={onChange} testID={testID}>
        <Checkbox.Control />
        <Text variant="ui-small" style={Style.filterLabel}>
          {label}
        </Text>
      </Checkbox>
    </View>
  );
}

const Style = StyleSheet.create({
  content: {
    padding: Token.spacing.m,
  },
  collapsibleHeader: {
    borderBottomWidth: 0,
  },
  collapsibleContent: {
    paddingTop: 0,
  },
  collapsibleInnerContent: {
    padding: Token.spacing.m,
    paddingBottom: Token.spacing.xs,
  },
  filter: {
    marginBottom: Token.spacing.xs,
  },
  filterLabel: {
    marginLeft: Token.spacing.s,
  },
  starRating: {
    marginLeft: Token.spacing.xs,
  },
  priceDisplay: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Token.spacing.m,
  },
});

import { JavaCurrencyValue } from 'shared/utils/currency';

export const RECENT_SEARCH_FLIGHT_API = '/api/v1/recent/search/flight';
export type RecentSearchFlightRequest = {};
export type RecentSearchFlightResponse = {
  details: Array<{
    origin: string;
    destination: string;
    isFrequentlyBooked: boolean;
  }>;
};

export const RECENT_SEARCH_HOTEL_PROPERTY_API =
  '/api/v1/recent/search/hotel/property';
export type RecentSearchHotelPropertyRequest = {};
export type RecentSearchHotelPropertyResponse = {
  details: Array<{
    hotelId: string;
    image: string;
    price: JavaCurrencyValue;
    hotelName: string;
    location: string;
    isFrequentlyBooked: boolean;
  }>;
};

export const RECENT_SEARCH_HOTEL_GEO_API = '/api/v1/recent/search/hotel/geo';
export type RecentSearchHotelGeoRequest = {};
export type RecentSearchHotelGeoResponse = {
  details: Array<{
    id: string;
    name: string;
    location: string;
    numOfHotels: string;
    type: 'REGION' | 'CITY' | 'AREA';
  }>;
};

export const RECENT_PICKER_PASSENGER_API = '/api/v1/recent/picker/passenger';
export type RecentPickerPassengerRequest = {};
export type RecentPickerPassengerResponse = {
  details: Array<{
    id: string;
    fullName: string;
    email: string;
    division: string;
  }>;
};

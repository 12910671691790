import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const ApprovalDetail: PrivatePageComponent = function() {
  return null;
};

ApprovalDetail.routeProtection = function({ params }) {
  if (params.requestId !== undefined) {
    return { path: '/approval/' + params.requestId };
  }

  return { path: '/approval/list' };
};

export default ApprovalDetail;

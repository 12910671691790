import React, { useState, useCallback, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { useAuth, useContentResource } from '@traveloka/ctv-core';
import { Input, InputDropdown } from '@traveloka/ctvweb-ui';
import Girl from '@traveloka/ctvweb-ui/img/popup-girl.png';
import {
  Card,
  Text,
  Token,
  Button,
  Icon,
  Image,
  Checkbox,
  useTheme,
} from '@traveloka/web-components';
import Chevron24 from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_24px.svg';
import Email from '@traveloka/icon-kit-web/svg/dark/ic_contact_mail_24px.svg';
import Phone from '@traveloka/icon-kit-web/svg/dark/ic_contact_phone_answer_24px.svg';

import InputField from 'shared/components/form/InputField/InputField';
import InputDropdownField from 'shared/components/form/InputDropdownField/InputDropdownField';

type FormValues = {
  tripId: string;
  productType: string;
  name: string;
  email: string;
  reason: string;
};

export default function ContactUsContent() {
  return (
    <View style={Style.wrapper}>
      <LeftColumn />
      <RightColumn />
    </View>
  );
}

function LeftColumn() {
  const [hasTripID, setHasTripID] = useState(true);
  const { user } = useAuth();

  const content = useContentResource().CorporateContactUs;
  const { contactUsValue } = useContentResource().CorporateFooter;
  const handleSubmitForm = useCallback(values => {}, []);

  const methods = useForm<FormValues>();
  const { handleSubmit: formSubmit } = methods;

  const productTypeItems = useMemo(
    () => [
      { label: content.flightText, value: 'FLIGHT' },
      { label: content.hotelText, value: 'HOTEL' },
    ],
    []
  );

  return (
    <View style={Style.leftColumn}>
      <Card style={Style.card} elevation="raised">
        <FormProvider {...methods}>
          <>
            <HeaderText text={content.title} />
            <View style={Style.tripID}>
              <Checkbox
                label={
                  <Text style={Style.bold} variant="ui-small">
                    {content.haveTripCheckboxText}
                  </Text>
                }
                checked={hasTripID}
                onChange={setHasTripID}
              />
              <InputField
                style={Style.input}
                name="tripId"
                disabled={!hasTripID}
              />
            </View>
            <InputDropdownField
              name="productType"
              iconRight={<Icon src={Chevron24} />}
              items={productTypeItems}
              label={content.selectProductField}
              placeholder={content.selectProductPlaceholder}
              style={Style.productDropdown}
            />
            <InputField
              style={Style.input}
              name="name"
              label={content.nameField}
              defaultValue={user?.name}
            />
            <InputField
              style={Style.input}
              name="email"
              label={content.emailField}
            />
            <InputField
              style={Style.input}
              name="reason"
              inputStyle={Style.textarea}
              label={content.reasonField}
              multiline
              leftHelper={content.reasonInfoText}
            />
            <Button
              style={Style.submit}
              text={content.submitButtonText}
              variant="primary"
              onPress={formSubmit(handleSubmitForm)}
            />
          </>
        </FormProvider>
      </Card>
      <Card style={Style.lastCard}>
        <HeaderText text="Other Channels" />
        <Text variant="ui-small">{content.otherChannelInfoText}</Text>
        <InfoText
          isFirst
          img={Email}
          label={content.otherChannelEmailText}
          value="corporateops@traveloka.com"
        />
        <InfoText
          img={Phone}
          label={content.otherChannelPhoneText}
          value={contactUsValue}
        />
      </Card>
    </View>
  );
}

function RightColumn() {
  const content = useContentResource().CorporateContactUs;

  return (
    <View style={Style.rightColumn}>
      <View style={Style.girlImage}>
        <Image src={Girl} height={153} width={132} />
      </View>
      <Text style={Style.textCenter} variant="ui-small" ink="secondary">
        {content.rightSideInfoText}
      </Text>
    </View>
  );
}

type HeaderProps = {
  text: string;
};

function HeaderText(props: HeaderProps) {
  const { text } = props;
  return (
    <Text style={Style.bold} variant="ui-baseline">
      {text}
    </Text>
  );
}

type InfoTextProps = {
  label: string;
  value: string;
  img: string;
  isFirst?: boolean;
};

function InfoText(props: InfoTextProps) {
  const { label, value, img, isFirst } = props;

  const { color } = useTheme();

  return (
    <View
      style={[
        Style.infoText,
        !isFirst && {
          borderTopColor: color.borderSubtle,
          borderTopWidth: Token.border.width.thin,
        },
      ]}
    >
      <Icon src={img} />
      <View style={Style.infoData}>
        <Text variant="ui-small" ink="secondary">
          {label}:
        </Text>
        <Text variant="ui-small">{value}</Text>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    width: '80%',
    marginHorizontal: 'auto',
    flexDirection: 'row',
    paddingTop: Token.spacing.m,
    paddingBottom: Token.spacing.xxxxl,
  },
  leftColumn: {
    flex: 2,
    marginRight: Token.spacing.l,
  },
  card: {
    paddingHorizontal: Token.spacing.s,
    paddingVertical: Token.spacing.m,
    marginBottom: Token.spacing.l,
  },
  lastCard: {
    paddingHorizontal: Token.spacing.s,
    paddingVertical: Token.spacing.m,
  },
  tripID: {
    marginTop: Token.spacing.s,
  },
  infoText: {
    paddingHorizontal: Token.spacing.xs,
    paddingVertical: Token.spacing.s,
    marginVertical: Token.spacing.xxs,
    flexDirection: 'row',
  },
  infoData: {
    marginLeft: Token.spacing.s,
  },
  rightColumn: {
    flex: 3,
    paddingHorizontal: Token.spacing.s,
    paddingVertical: Token.spacing.m,
    alignItems: 'center',
  },
  productDropdown: {
    zIndex: 2,
    marginVertical: Token.spacing.l,
  },
  girlImage: {
    marginVertical: Token.spacing.l,
  },
  textCenter: {
    textAlign: 'center',
  },
  submit: {
    marginTop: Token.spacing.l,
  },
  bold: Token.typography.weightMedium,
  textarea: {
    height: 100,
  },
  input: {
    marginTop: Token.spacing.s,
  },
});

import React, { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Girl from '@traveloka/ctvweb-ui/img/popup-girl.png';
import { Image, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

type Props = {
  isVisible?: boolean;
  title?: string;
  style?: StyleProp<ViewStyle>;
};

export default function PopupGirl(props: PropsWithChildren<Props>) {
  const { isVisible, title, children, style } = props;

  return (
    <Modal isVisible={isVisible}>
      <Popup showCloseButton={false} width={600} maxWidth={600}>
        <View testID="pre-book.confirmation.price-change" style={Style.popup}>
          <Image src={Girl} width={221} height={256} />
          <View style={Style.right}>
            {title && (
              <Text variant="ui-large" style={Style.title}>
                {title}
              </Text>
            )}

            <View style={[Style.content, style]}>{children}</View>
          </View>
        </View>
      </Popup>
    </Modal>
  );
}

const Style = StyleSheet.create({
  popup: {
    flexDirection: 'row',
  },
  title: {
    marginBottom: Token.spacing.m,
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  right: {
    flex: 1,
    marginLeft: Token.spacing.m,
  },
  content: {
    flex: 1,
  },
});

import {
  ContentResourceResponse,
  ImageResourceResponse,
  SliderResourceResponse,
} from './api';
import { content, image, slider } from './payload';
import { ContentQuery } from './types';

const { data: fallback }: { data: ContentQuery } = require('./fallback.json');

export function createContentMock(): ContentResourceResponse {
  const fallbackResourceNames = Object.keys(fallback);

  const newC: any = {};
  for (const k of Object.keys(content) as Array<keyof typeof content>) {
    if (fallbackResourceNames.includes(k)) {
      newC[k] = { ...fallback[k] };
      continue;
    }

    for (const k2 of Object.keys(content[k])) {
      newC[k] = {
        ...newC[k],
        [k2]: k2,
      };
    }
  }
  return newC;
}

export function createImageMock(): ImageResourceResponse {
  return image;
}

export function createSliderMock(): SliderResourceResponse {
  const result = {} as any;
  Object.keys(slider).forEach(key => {
    result[key] = [];
  });

  return result;
}

import {
  Button,
  Card,
  Checkbox,
  Icon,
  Text,
  Token,
  Tooltip,
  useTheme,
} from '@traveloka/web-components';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import Info from '@traveloka/icon-kit-web/svg/blue/ic_system_status_info-fill_16px.svg';
import Cross from '@traveloka/icon-kit-web/svg/lightSecondary/ic_symbol_add_plus_16px.svg';
import Adult from '@traveloka/icon-kit-web/svg/dark/ic_passenger_adult_24px.svg';
import Child from '@traveloka/icon-kit-web/svg/dark/ic_passenger_child_kid_24px.svg';
import Infant from '@traveloka/icon-kit-web/svg/dark/ic_passenger_infant_baby_24px.svg';
import Plus from '@traveloka/icon-kit-web/svg/blue/ic_symbol_add_plus_16px.svg';
import Minus from '@traveloka/icon-kit-web/svg/blue/ic_symbol_add_minus__16px.svg';

import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import {
  Divider,
  Input,
  InputDropdown,
  TextTooltip,
} from '@traveloka/ctvweb-ui';
import { useContentResource } from '@traveloka/ctv-core';

import Collapsible from 'flight/prebook/components/Collapsible/Collapsible';
import { formatMessage } from 'shared/utils/intl';

import {
  NonEmployeeField,
  NonEmployeeType,
  usePickerDesign,
  ValidationAdditionalInfo,
  ValidationReturn,
} from '../RevampTravelerPickerModal';
import { useNonEmployee } from '../contexts/NonEmployeeContext';

type Props = {
  testID?: string;
  emptySelectedTravelerText: string;
  selectedTravelerText: string;
  complianceLabel: string;
  nonEmployeeEnablementCheckbox?: string;
  nonEmployeeTooltip?: string;
  nonEmployeeSubtitle?: string;
};

export const CHILD_AGE_DEFAULT_VALUE = '8';
export const childAgeItems = [
  {
    label: '<1',
    value: '<1',
  },
  ...Array.from({ length: 17 }).map((_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
  })),
];

export default function RightSection(props: Props) {
  const { nonEmployeeTravelers } = useNonEmployee();
  const pickerDesign = usePickerDesign();

  const hasNeb =
    (pickerDesign === 'ne' || pickerDesign === 'has-all') &&
    nonEmployeeTravelers;
  const hasEmployee = pickerDesign !== 'ne';

  return (
    <View style={Style.container}>
      {hasNeb && <NonEmployeeTraveler {...props} />}
      {hasEmployee && <EmployeeTraveler {...props} />}
    </View>
  );
}

function NonEmployeeTraveler(props: Props) {
  const {
    nonEmployeeEnablementCheckbox,
    nonEmployeeSubtitle,
    nonEmployeeTooltip,
  } = props;
  const [_, setReRenderCollapsible] = useState<[]>([]);
  const { color } = useTheme();
  const {
    travelers,
    nonEmployeeTravelers,
    isNonEmployeeChecked: isChecked,
    setIsNonEmployeeChecked: setIsChecked,
    isOpen,
    handleIsOpen,
    setHasError,
    isChildAgeAutoFill,
    setIsChildAgeAutoFill,
    setNonEmployeeTravelers,
  } = useNonEmployee();

  function handleCollapsible() {
    if (isChecked) {
      setIsChecked(false);

      if (isOpen === 'NON_EMPLOYEE') {
        handleIsOpen(undefined);
      }
    } else {
      handleIsOpen('NON_EMPLOYEE');
      setIsChecked(prevValue => !prevValue);

      if (isOpen !== 'NON_EMPLOYEE') {
        setIsChecked(true);
      }
    }
  }

  useEffect(() => {
    if (isChildAgeAutoFill.stopReRender) {
      setIsChildAgeAutoFill({ value: true, stopReRender: false });
    } else {
      setIsChildAgeAutoFill({ value: false, stopReRender: false });
    }
    if (isChecked) {
      const hasError = nonEmployeeTravelers!
        .map(field => !!field.onValidate?.(travelers, nonEmployeeTravelers!))
        .filter(Boolean);
      setHasError(hasError);
      if (hasError.length) {
        handleIsOpen('NON_EMPLOYEE', true);
      }
    } else {
      setHasError([]);
    }
  }, [travelers, nonEmployeeTravelers, isChecked]);

  return (
    <Card style={NonEmployeeTravelerStyle.container}>
      <Collapsible
        style={NonEmployeeTravelerStyle.collapsible}
        headerStyle={NonEmployeeTravelerStyle.collapsibleHeader}
        contentStyle={NonEmployeeTravelerStyle.collapsibleContent}
        chevronStyle={NonEmployeeTravelerStyle.chevron}
        isCollapsed={!isChecked && isOpen !== 'NON_EMPLOYEE'}
        onPress={handleCollapsible}
        hasAnimation={false}
        title={
          <View style={NonEmployeeTravelerStyle.header}>
            <Checkbox
              label={
                <Text variant="title-3">{nonEmployeeEnablementCheckbox}</Text>
              }
              checked={isChecked}
              onChange={handleCollapsible}
            />

            <Tooltip
              contentZIndex={10000}
              content={
                <Text
                  variant="ui-small"
                  style={{
                    color: color.lightPrimary,
                    textAlign: 'center',
                  }}
                >
                  {nonEmployeeTooltip}
                </Text>
              }
              variant="normal"
              position="top"
              width={255}
            >
              <Icon src={Info} />
            </Tooltip>
          </View>
        }
      >
        <NonEmployeeFields
          nonEmployeeSubtitle={nonEmployeeSubtitle}
          setNonEmployeeTravelers={setNonEmployeeTravelers}
          setReRenderCollapsible={setReRenderCollapsible}
        />
      </Collapsible>
    </Card>
  );
}

type NonEmployeeFields = {
  setNonEmployeeTravelers: Dispatch<
    SetStateAction<NonEmployeeField[] | undefined>
  >;
  setReRenderCollapsible: Dispatch<SetStateAction<[]>>;
  nonEmployeeSubtitle?: string;
};

function NonEmployeeFields(props: NonEmployeeFields) {
  const { setReRenderCollapsible, nonEmployeeSubtitle } = props;
  const nonEmployeeContent = useNonEmployeeContent();
  const content = useContentResource().CorporateProductSearchForm;
  const {
    travelers,
    nonEmployeeTravelers,
    setNonEmployeeTravelers,
    isOpen,
    handleIsOpen,
    hasError,
  } = useNonEmployee();

  function handleButtonChange(type: NonEmployeeType, value: number) {
    if (type === NonEmployeeType.CHILD_AGE) {
      setNonEmployeeTravelers(prevValue =>
        prevValue?.map(field => {
          if (field.type === type && typeof field.value !== 'number') {
            const valueLength = field.value.length;
            const diff = value - valueLength;
            if (diff > 0) {
              return {
                ...field,
                value: [
                  ...field.value,
                  ...Array.from({ length: diff }).map(_ => null),
                ],
              };
            } else {
              return {
                ...field,
                value: field.value.slice(0, field.value.length + diff),
              };
            }
          }
          return field;
        })
      );
      setReRenderCollapsible([]);
    } else {
      setNonEmployeeTravelers(prevValue =>
        prevValue?.map(field => {
          if (field.type === type) {
            return {
              ...field,
              value: value,
            };
          }
          return field;
        })
      );
    }
  }

  function handleAgeValueChange(
    type: NonEmployeeType,
    value: string,
    index: number
  ) {
    setNonEmployeeTravelers(prevValue =>
      prevValue?.map(field => {
        if (field.type === type && typeof field.value !== 'number') {
          return {
            ...field,
            value: field.value.map((v, idx) => (idx === index ? value : v)),
          };
        }
        return field;
      })
    );
  }

  const showDetail: string[] = [];
  nonEmployeeTravelers!.forEach(field => {
    const fieldValue =
      typeof field.value === 'number' ? field.value : field.value.length;

    if (field.type === NonEmployeeType.ADULT) {
      showDetail.push(
        formatMessage(content.nonEmployeeAdultUnit, { num: fieldValue })
      );
    } else if (
      field.type === NonEmployeeType.CHILD ||
      field.type === NonEmployeeType.CHILD_AGE
    ) {
      showDetail.push(
        formatMessage(content.nonEmployeeChildUnit, { num: fieldValue })
      );
    } else if (field.type === NonEmployeeType.INFANT) {
      showDetail.push(
        formatMessage(content.nonEmployeeInfantUnit, { num: fieldValue })
      );
    }
  });

  return (
    <>
      {isOpen === 'NON_EMPLOYEE' && (
        <>
          <Text
            style={NonEmployeeTravelerStyle.subtitle}
            ink="secondary"
            variant="ui-small"
          >
            {nonEmployeeSubtitle}
          </Text>
          {nonEmployeeTravelers!.map((field, index) => {
            const item = nonEmployeeContent(field.type);
            const { onValidate: onValidateProps = () => null } = field;

            return (
              <NonEmployeeItem
                {...field}
                value={field.value}
                key={index}
                icon={item.icon}
                title={item.title}
                subtitle={item.subtitle}
                onValidate={(additionalInfo?: ValidationAdditionalInfo) =>
                  onValidateProps(
                    travelers,
                    nonEmployeeTravelers!,
                    additionalInfo
                  )
                }
                handleButtonChange={handleButtonChange}
                handleAgeValueChange={handleAgeValueChange}
              />
            );
          })}
        </>
      )}

      <View style={NonEmployeeTravelerStyle.actionContainer}>
        {isOpen !== 'NON_EMPLOYEE' && <Text>{showDetail.join(', ')}</Text>}
        <Button
          style={NonEmployeeTravelerStyle.doneButton}
          text={
            isOpen !== 'NON_EMPLOYEE'
              ? content.nonEmployeeShowButton
              : content.nonEmployeeHideButton
          }
          onPress={() => {
            if (isOpen !== 'NON_EMPLOYEE') {
              handleIsOpen('NON_EMPLOYEE');
            } else {
              handleIsOpen(undefined);
            }
          }}
          variant="primary"
          disabled={hasError.some(e => e)}
        />
      </View>
    </>
  );
}

function useNonEmployeeContent() {
  const content = useContentResource().CorporateProductSearchForm;
  return useCallback(
    (
      type: NonEmployeeType
    ): {
      icon: string;
      title: string;
      subtitle?: string;
    } => {
      switch (type) {
        case NonEmployeeType.ADULT:
          return {
            icon: Adult,
            title: content.adultText,
          };
        case NonEmployeeType.CHILD:
          return {
            icon: Child,
            title: content.childrenText,
            subtitle: content.childrenDescription,
          };
        case NonEmployeeType.CHILD_AGE:
          return {
            icon: Child,
            title: content.childrenText,
          };
        case NonEmployeeType.INFANT:
          return {
            icon: Infant,
            title: content.infantText,
            subtitle: content.infantDescription,
          };
      }
    },
    [content]
  );
}

type ItemProps = Omit<NonEmployeeField, 'onValidate'> & {
  icon: string;
  title: string;
  subtitle?: string;
  onValidate: (additionalInfo?: ValidationAdditionalInfo) => ValidationReturn;
  handleButtonChange(type: NonEmployeeType, value: number): void;
  handleAgeValueChange(
    type: NonEmployeeType,
    value: string,
    index: number
  ): void;
};
function NonEmployeeItem(props: ItemProps) {
  const {
    icon,
    title,
    subtitle,
    type,
    value: valueProps,
    onValidate,
    handleButtonChange,
    handleAgeValueChange,
  } = props;
  const localValidation = useRef<boolean>(false);
  const [tooltipError, setTooltipError] = useState<Nullable<string>>(null);
  const { isNonEmployeeChecked, isChildAgeAutoFill } = useNonEmployee();
  const childAgeContent = useContentResource().CorporateHotelSearchForm;
  const content = useContentResource().CorporateProductSearchForm;

  function onValueChange(type: NonEmployeeType, valueParam: string | number) {
    const value = Number(valueParam);
    if (isNaN(value)) {
      return;
    }
    localValidation.current = true;
    const validate = onValidate({ value });

    if (validate) {
      setTooltipError(validate.error);

      !validate.noUpdate && handleButtonChange(type, value);
    } else {
      setTooltipError(null);
      handleButtonChange(type, value);
    }
  }

  const value = typeof valueProps === 'number' ? valueProps : valueProps.length;

  useEffect(() => {
    if (!isNonEmployeeChecked) {
      return;
    }
    if (localValidation.current) {
      localValidation.current = false;
      return;
    }
    const validate = onValidate();
    if (!validate?.noUpdate) {
      setTooltipError(validate ? validate.error : null);
    }
  });

  return (
    <>
      <View style={NonEmployeeTravelerStyle.itemContainer}>
        <Icon src={icon} />
        <Tooltip
          contentZIndex={10000}
          variant="alert"
          content={
            <Text style={NonEmployeeTravelerStyle.tooltipText} ink="white">
              {childAgeContent.nonEmployeeTooltipAutoFillChildAge}
            </Text>
          }
          show={type === NonEmployeeType.CHILD_AGE && isChildAgeAutoFill.value}
          width={'auto'}
        >
          <View style={NonEmployeeTravelerStyle.itemText}>
            <Text>{title}</Text>
            {subtitle && (
              <Text variant="ui-tiny" ink="secondary">
                {subtitle}
              </Text>
            )}
          </View>
        </Tooltip>
        <View style={NonEmployeeTravelerStyle.itemActions}>
          <Button
            style={NonEmployeeTravelerStyle.itemButton}
            variant="secondary"
            iconStart={() => <Icon src={Minus} />}
            onPress={() => onValueChange(type, value - 1)}
            disabled={value <= 0}
          />
          <Tooltip
            contentZIndex={10000}
            variant="alert"
            content={
              <Text style={NonEmployeeTravelerStyle.tooltipText} ink="white">
                {tooltipError}
              </Text>
            }
            show={isNonEmployeeChecked && !!tooltipError}
            width={'auto'}
          >
            <Input
              style={NonEmployeeTravelerStyle.itemInputContainer}
              inputStyle={NonEmployeeTravelerStyle.itemInput}
              value={value.toString()}
              onChangeText={value => onValueChange(type, value)}
            />
          </Tooltip>
          <Button
            style={NonEmployeeTravelerStyle.itemButton}
            variant="secondary"
            iconStart={() => <Icon src={Plus} />}
            onPress={() => onValueChange(type, value + 1)}
          />
        </View>
      </View>
      {typeof valueProps !== 'number' && (
        <View style={NonEmployeeTravelerStyle.childAgeContainer}>
          {valueProps.map((value, index, valueArray) => (
            <InputDropdown
              key={index}
              style={{
                zIndex: valueArray.length - index,
                marginRight: (index + 1) % 3 !== 0 ? 28.5 : 0,
              }}
              containerStyle={
                NonEmployeeTravelerStyle.childAgeDropdownContainer
              }
              dropdownHeight={200}
              label={formatMessage(childAgeContent.nonEmployeeChildAgeLabel, {
                index: index + 1,
              })}
              value={value || ''}
              placeholder={content.childAgePlaceholder}
              items={childAgeItems}
              onPressItem={({ value }) =>
                handleAgeValueChange(type, value, index)
              }
              editable
            />
          ))}
        </View>
      )}
    </>
  );
}

function EmployeeTraveler(props: Props) {
  const {
    testID,

    emptySelectedTravelerText,
    selectedTravelerText,
    complianceLabel,
  } = props;

  const {
    isNonEmployeeChecked,
    tripRequestEnabled,
    travelers,
    isOpen,
    handleIsOpen,
    onTravelerChange,
  } = useNonEmployee();
  const { color } = useTheme();

  return (
    <Card>
      {travelers.length ? (
        <Collapsible
          isCollapsed={isOpen !== 'EMPLOYEE'}
          onPress={() => handleIsOpen('EMPLOYEE')}
          hasAnimation={false}
          title={
            <Text variant="title-3">
              {formatMessage(selectedTravelerText, {
                num: travelers.length,
              })}
            </Text>
          }
        >
          <View
            style={[
              EmployeeTravelerStyle.employeeTravelerContainer,
              isNonEmployeeChecked &&
                EmployeeTravelerStyle.smallEmployeeContainer,
            ]}
          >
            {travelers.map((traveler, index) => {
              return (
                <>
                  {!!index && <Divider subtle margin="vertical" spacing="m" />}
                  <View style={EmployeeTravelerStyle.travelerItem}>
                    <View style={EmployeeTravelerStyle.travelerData}>
                      <Text>{traveler.fullname}</Text>
                      <TextTooltip
                        text={`${
                          traveler.division ? `${traveler.division} • ` : ''
                        }${traveler.email}`}
                        maxLength={28}
                        ink="secondary"
                        variant="ui-small"
                        styleContainer={
                          EmployeeTravelerStyle.travelerDataSubtitle
                        }
                      />
                    </View>
                    <View style={EmployeeTravelerStyle.travelerItemIcons}>
                      {!index && (
                        <Tooltip
                          contentZIndex={10000}
                          content={
                            <Text
                              variant="ui-small"
                              style={{
                                color: color.lightPrimary,
                                textAlign: 'center',
                              }}
                            >
                              {complianceLabel}
                            </Text>
                          }
                          variant="normal"
                          position="top"
                          width={255}
                        >
                          <Icon src={Info} />
                        </Tooltip>
                      )}
                      {!(tripRequestEnabled && index === 0) && (
                        <TouchableOpacity
                          testID={appendTestId(
                            testID,
                            `added.list.${index}.remove`
                          )}
                          style={EmployeeTravelerStyle.xIcon}
                          onPress={() => onTravelerChange(traveler)}
                        >
                          <Icon
                            src={Cross}
                            style={{ transform: 'rotate(45deg)' }}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                </>
              );
            })}
          </View>
        </Collapsible>
      ) : (
        <Text
          style={EmployeeTravelerStyle.emptySelectedTravler}
          variant="title-3"
        >
          {emptySelectedTravelerText}
        </Text>
      )}
    </Card>
  );
}

const Style = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: Token.spacing.m,
  },
});

const EmployeeTravelerStyle = StyleSheet.create({
  employeeTravelerContainer: {
    maxHeight: 372,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  smallEmployeeContainer: {
    maxHeight: 330,
  },
  emptySelectedTravler: {
    padding: Token.spacing.m,
  },
  travelerItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: Token.spacing.m,
    paddingRight: Token.spacing.ml,
  },
  travelerData: {
    zIndex: 1,
  },
  travelerDataSubtitle: {
    marginTop: Token.spacing.xxs,
  },
  travelerItemIcons: {
    flexDirection: 'row',
  },
  xIcon: {
    marginLeft: Token.spacing.ml,
  },
});

const NonEmployeeTravelerStyle = StyleSheet.create({
  container: {
    marginBottom: Token.spacing.m,
    overflow: 'visible',
    zIndex: 1,
  },
  collapsible: { overflow: 'visible' },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  collapsibleHeader: {
    borderBottomWidth: 0,
  },
  collapsibleContent: {
    paddingTop: 0,
  },
  chevron: {
    display: 'none',
  },

  subtitle: {
    paddingBottom: Token.spacing.m,
    borderBottomColor: Token.color.borderDivide,
    borderBottomWidth: Token.border.width.thick,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: Token.spacing.l,
    borderBottomColor: Token.color.borderDivide,
    borderBottomWidth: Token.border.width.thick,
  },
  itemText: {
    marginLeft: Token.spacing.xs,
    justifyContent: 'center',
  },
  itemActions: {
    flexDirection: 'row',
    marginLeft: 'auto',
  },
  itemButton: {
    width: 40,
    height: 40,
  },
  itemInputContainer: {
    width: 45,
    height: 40,
    marginHorizontal: Token.spacing.xxs,
  },
  itemInput: {
    textAlign: 'center',
  },
  childAgeContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: Token.spacing.s,
    zIndex: 1,
  },
  childAgeDropdownContainer: {
    width: 80,
    height: 40,
  },
  tooltipText: {
    width: 'max-content',
  },

  actionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: Token.spacing.m,
    paddingBottom: Token.spacing.xxs,
  },
  doneButton: {
    marginLeft: 'auto',
  },
});

import React from 'react';

import { useContentResource } from '@traveloka/ctv-core';

import { CommonStyle } from '../filter-search-toolbar.style';
import FlightSearchToolbarMenuContainer from '../FlightSearchToolbarMenuContainer';
import TransitCountFilter from './TransitCountFilter';
import TransitDurationFilter from './TransitDurationFilter';
import TransitLocationFilter from './TransitLocationFilter';

function TransitFilter() {
  const { CorporateFlightSearchToolbar } = useContentResource();
  const content = {
    title: CorporateFlightSearchToolbar.transit,
  };

  return (
    <FlightSearchToolbarMenuContainer
      alignment="left"
      text={content.title}
      style={CommonStyle.menuItem}
      testID="flight.search.toolbar.transit"
    >
      <TransitCountFilter style={CommonStyle.section} />
      <TransitDurationFilter style={CommonStyle.section} />
      <TransitLocationFilter />
    </FlightSearchToolbarMenuContainer>
  );
}

export default TransitFilter;

import React, { useEffect, useRef, useState, PropsWithChildren } from 'react';
import {
  Animated,
  Easing,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import { Image, Text, Token } from '@traveloka/web-components';

type Props = {
  images: string[];
  interval?: number;
  noImageLabel: string;
  onPress?(): void;
  showAllLabel: string;
};

const MAX_NUM_OF_IMAGES = 5;

export default function ImageSlideshow(props: Props) {
  const {
    images,
    interval = 5000,
    noImageLabel,
    onPress,
    showAllLabel,
  } = props;
  const [slides, setSlides] = useState(images.slice(0, MAX_NUM_OF_IMAGES));

  useEffect(() => {
    let handle: number;

    if (slides.length > 1) {
      handle = window.setInterval(() => {
        setSlides(slides => {
          const [first, ...rest] = slides;
          const last = rest.pop();

          return [...rest, first, last!];
        });
      }, interval);
    }

    return () => window.clearInterval(handle);
  }, [setSlides]);

  useEffect(() => setSlides(images.slice(0, MAX_NUM_OF_IMAGES)), [images]);

  const lastSmallImageIndex = slides.length - 2;
  const [first, ...rest] = Array.from(
    { length: MAX_NUM_OF_IMAGES },
    (_, index) => slides[index]
  );

  return (
    <View style={Style.slideshow}>
      <TransitionImage
        src={first}
        width={768}
        height={488}
        style={Style.large}
        noImageLabel={noImageLabel}
        onPress={onPress}
      />
      {rest.map((slide, index) => {
        const isLast = index === rest.length - 1;
        const style = !isLast && Style.small;

        if (index === lastSmallImageIndex) {
          return (
            <Background key={index} touchable style={style} onPress={onPress}>
              <Image src={slide} width={152} height={116} objectFit="cover" />
              <Background clear style={[Style.center, StyleSheet.absoluteFill]}>
                <Text style={Style.text} ink="white">
                  {showAllLabel}
                </Text>
              </Background>
            </Background>
          );
        }

        return (
          <TransitionImage
            key={index}
            src={slide}
            width={152}
            height={116}
            style={style}
            noImageLabel={noImageLabel}
            onPress={onPress}
          />
        );
      })}
    </View>
  );
}

type TransitionImageProps = {
  height: number;
  src?: string;
  width: number;
  style?: StyleProp<ViewStyle>;
  noImageLabel: string;
  onPress?(): void;
};

function TransitionImage(props: TransitionImageProps) {
  const { height, src, width, style, noImageLabel, onPress } = props;

  const prevSrc = usePreviousSrc(src);

  if (!src) {
    return (
      <Background style={[Style.center, style, { width, height }]}>
        <Text style={Style.text} ink="white">
          {noImageLabel}
        </Text>
      </Background>
    );
  }

  return (
    <Background touchable onPress={onPress} style={style}>
      <Image src={src} width={width} height={height} objectFit="cover" />
      {prevSrc && (
        <FadeOutImage
          key={prevSrc}
          src={prevSrc}
          width={width}
          height={height}
        />
      )}
    </Background>
  );
}

type BackgroundProps = PropsWithChildren<{
  clear?: boolean;
  touchable?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?(): void;
}>;

function Background(props: BackgroundProps) {
  const { clear, touchable, children, style, onPress } = props;

  const commonProps = {
    children,
    style: [Style.slide, clear && Style.slideFade, style],
  };

  if (touchable) {
    return (
      <TouchableOpacity
        {...commonProps}
        onPress={onPress}
        activeOpacity={0.5}
      />
    );
  }

  return <View {...commonProps} />;
}

type FadeOutImageProps = {
  height: number;
  src: string;
  width: number;
};

function FadeOutImage(props: FadeOutImageProps) {
  const [shouldMount, setShouldMount] = useState(true);
  const { height, src, width } = props;
  const animationRef = useRef(new Animated.Value(1));

  useEffect(() => {
    Animated.timing(animationRef.current, {
      toValue: 0,
      duration: Token.animation.timing.instant,
      easing: Easing.inOut(Easing.linear),
    }).start(() => setShouldMount(false));
  }, []);

  const transitionStyle = {
    opacity: animationRef.current,
  };

  if (!shouldMount) {
    return null;
  }

  return (
    <Animated.View style={[StyleSheet.absoluteFill, transitionStyle]}>
      <Image src={src} width={width} height={height} objectFit="cover" />
    </Animated.View>
  );
}

function usePreviousSrc(src?: string) {
  const ref = useRef<string>();

  useEffect(() => {
    ref.current = src;
  }, [src]);

  return ref.current;
}

const Style = StyleSheet.create({
  slideshow: {
    flexWrap: 'wrap',
    height: 488,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
  },
  slide: {
    backgroundColor: Token.color.uiDarkNeutral,
    borderRadius: Token.border.radius.normal,
    overflow: 'hidden',
  },
  slideFade: {
    backgroundColor: Token.opacity.translucent(Token.color.uiDarkNeutral),
  },
  large: {
    marginRight: Token.spacing.xs,
  },
  small: {
    marginBottom: Token.spacing.xs,
  },
});

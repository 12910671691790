import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { differenceInCalendarDays } from 'date-fns';

import { useApi } from '@traveloka/ctv-core';
import {
  EntryListRequest,
  EntryListResponse,
  GET_ENTRY_LIST,
  Operator,
} from '@traveloka/ctv-core/pad';
import { Traveler } from '@traveloka/ctvweb-ui/src/generic/TravelerPickerModal/types';

import { Employee } from 'company/types';
import {
  parseSearchSpec,
  SearchSpec,
} from 'hotel/search/utils/SearchQueryUtil';

import { HotelNonEmployeeTraveler } from '../api/types';

export const defaultHotelNonEmployee: HotelNonEmployeeTraveler = {
  adults: 0,
  childrenAges: [],
};

export type ComputedSearchSpec = Omit<SearchSpec, 'travelers'> & {
  travelers: Traveler[];
  duration: number;
};

const SpecContext = createContext<
  [ComputedSearchSpec, Dispatch<SetStateAction<SearchSpec>>]
>(undefined!);

export function SearchSpecProvider(props: PropsWithChildren<{}>) {
  const [passengers, setPassengers] = useState<Traveler[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchSpec, setSearchSpec] = useState<SearchSpec>(
    parseSearchSpec(window.location.search)
  );

  const getEntries = useApi<EntryListResponse<Employee>, EntryListRequest>({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_LIST,
  });

  useEffect(() => {
    if (searchSpec.travelers.length === 0) {
      setPassengers([]);
      return;
    }
    setIsLoading(true);
    const spec: EntryListRequest = {
      entityType: 'employeeList',
      search: {
        entriesCount: searchSpec.travelers.length,
        filter: [
          {
            fieldName: 'employeeId',
            arguments: {
              operator: Operator.IN,
              value: searchSpec.travelers,
            },
          },
        ],
      },
    };

    getEntries(spec)
      .then(res => {
        if (res.success) {
          const { entries } = res.data;

          setPassengers(
            searchSpec.travelers
              .map(p => entries.find(e => e.employeeId.toString() === p))
              .filter(Boolean) as Traveler[]
          );
        }
      })
      .finally(() => setIsLoading(false));
  }, [searchSpec]);

  if (isLoading) {
    return null;
  }

  const { checkOutDate, checkInDate } = searchSpec;
  const duration = differenceInCalendarDays(checkOutDate, checkInDate);

  return (
    <SpecContext.Provider
      value={[
        {
          ...searchSpec,
          travelers: passengers,
          duration,
          nonEmployeeTravelers: searchSpec.nonEmployeeTravelers,
        },
        setSearchSpec,
      ]}
    >
      {props.children}
    </SpecContext.Provider>
  );
}

export function useSearchSpec() {
  const [spec] = useContext(SpecContext) ?? [];
  return spec;
}

export function useSearchSpecDispatch() {
  const [_, dispatch] = useContext(SpecContext) ?? [undefined, () => {}];
  return dispatch;
}

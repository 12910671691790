import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Text, Token, Button } from '@traveloka/web-components';

import PopupGirl from '../PopupGirl/PopupGirl';

type Props = {
  isLoading?: boolean;
  isVisible: boolean;
  newPrice: string;
  oldPrice: string;
  onPrimaryPress(): void;
  onSecondaryPress(): void;
  primaryLabel: string;
  secondaryLabel: string;
};

export default function PriceChangeModal(props: Props) {
  const {
    isLoading,
    isVisible,
    newPrice,
    oldPrice,
    onPrimaryPress,
    onSecondaryPress,
    primaryLabel,
    secondaryLabel,
  } = props;

  const content = useContentResource().CorporateHotelPrebookPriceChangeModal;

  return (
    <PopupGirl isVisible={isVisible} title={content.title}>
      <Text ink="secondary" style={Style.text}>
        {`${content.totalPriceUpdateText} `}
        <Text ink="secondary" style={Style.bold}>
          {oldPrice}
        </Text>
        {` ${content.toText} `}
        <Text ink="secondary" style={Style.bold}>
          {newPrice}
        </Text>
        {content.totalPriceUpdateText2}
      </Text>
      <View style={Style.action}>
        <Button
          variant="secondary"
          text={secondaryLabel}
          onPress={onSecondaryPress}
        />
        <Button
          variant="main-cta"
          text={primaryLabel}
          loading={isLoading}
          onPress={onPrimaryPress}
          style={Style.continue}
        />
      </View>
    </PopupGirl>
  );
}

const Style = StyleSheet.create({
  text: {
    textAlign: 'center',
    marginBottom: Token.spacing.l,
  },
  bold: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  action: {
    flexDirection: 'row',
  },
  continue: {
    marginLeft: Token.spacing.m,
  },
});

import React, { useCallback, useMemo } from 'react';

import { eachDayOfInterval, format } from 'date-fns';
import {
  Calendar as CalendarUI,
  DateObject,
  PeriodMarking,
  LocaleConfig,
} from 'react-native-calendars';

import { Locale, useLocale } from '@traveloka/ctv-core';
import ChevronLeft from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_left_16px.svg';
import ChevronRight from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_right_16px.svg';
import { useTheme, Icon } from '@traveloka/web-components';

import { id } from './locale';

type Props = {
  maxDate?: Date;
  minDate?: Date;
  onDayPress?(date: Date): void;
  relatedValue?: Date;
  value: Date;
};

export default function Calendar(props: Props) {
  const { maxDate, minDate, onDayPress, relatedValue, value } = props;

  const { color } = useTheme();
  const { locale } = useLocale();
  if (locale === Locale.IDID) {
    LocaleConfig.defaultLocale = locale;
  }

  const markedDates: Dictionary<PeriodMarking> = useMemo(() => {
    const DATE_FORMAT = 'yyyy-MM-dd';

    if (relatedValue && value !== relatedValue) {
      let startingDate = value;
      let endingDate = relatedValue;
      if (value > relatedValue) {
        startingDate = relatedValue;
        endingDate = value;
      }

      const dates = eachDayOfInterval({
        start: startingDate,
        end: endingDate,
      });

      return dates.reduce((markedDatesObj, date, index) => {
        const key = format(date, DATE_FORMAT);

        markedDatesObj[key] = {
          color: color.tintPrimary,
          textColor: color.lightPrimary,
        };

        if (index === 0) {
          markedDatesObj[key].startingDay = true;
        }

        if (index === dates.length - 1) {
          markedDatesObj[key].endingDay = true;
        }
        return markedDatesObj;
      }, {} as Dictionary<PeriodMarking>);
    } else {
      const valueKey = format(value, DATE_FORMAT);

      return {
        [valueKey]: {
          startingDay: true,
          endingDay: true,
          color: color.uiBluePrimary,
          textColor: color.lightPrimary,
        },
      };
    }
  }, [value, relatedValue]);

  const handleDayPress = useCallback(
    ({ year, month, day }: DateObject) => {
      onDayPress && onDayPress(new Date(year, month - 1, day));
    },
    [onDayPress]
  );

  return (
    <CalendarUI
      current={value}
      hideExtraDays
      markedDates={markedDates}
      markingType="period"
      maxDate={maxDate}
      minDate={minDate}
      onDayPress={handleDayPress}
      renderArrow={direction => (
        <Icon
          width={16}
          height={16}
          src={direction === 'left' ? ChevronLeft : ChevronRight}
        />
      )}
    />
  );
}

LocaleConfig.locales['id-id'] = id;

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Popup, Image, Text, Token, Button } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import InsufficientWallet from '../../../img/wallet-insufficient.png';
import { appendTestId } from '../../shared/utils/TestUtil';

type Props = {
  title: string;
  description: string;
  isVisible: boolean;
  onClose: () => void;
  buttonBackText: string;
  testID?: string;
};

export default function InsufficientCreditLimitModal(props: Props) {
  const {
    isVisible,
    onClose,
    title,
    description,
    buttonBackText,
    testID,
  } = props;
  return (
    <Modal isVisible={isVisible}>
      <Popup
        showCloseButton={false}
        onCloseButtonPress={onClose}
        width={614}
        height={540}
      >
        <View testID={testID} style={Style.content}>
          <Image
            src={InsufficientWallet}
            width={204}
            height={220}
            style={Style.image}
          />
          <Text variant="headline" style={Style.title}>
            {title}
          </Text>
          <Text variant="ui-baseline" ink="secondary" style={Style.caption}>
            {description}
          </Text>
          <Button
            testID={appendTestId(testID, 'back')}
            style={Style.back}
            text={buttonBackText}
            onPress={onClose}
          />
        </View>
      </Popup>
    </Modal>
  );
}

const Style = StyleSheet.create({
  image: {
    marginBottom: Token.spacing.xxxl,
  },
  title: {
    marginBottom: Token.spacing.m,
    fontWeight: Token.typography.weightMedium.fontWeight,
    textAlign: 'center',
  },
  caption: {
    textAlign: 'center',
  },
  content: {
    alignItems: 'center',
  },
  back: {
    marginTop: Token.spacing.m,
  },
});

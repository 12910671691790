import { useCallback } from 'react';

import { stringifySearchSpec } from 'hotel/search/utils/SearchQueryUtil';
import { defaultHotelNonEmployee } from 'hotel/shared/contexts/SpecContext';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

import { usePrebook } from '../contexts/PrebookProvider';

export default function useRedirectToRoom() {
  const { spec } = usePrebook();
  const history = useLocalizedHistory();

  return useCallback(
    (hash?: string) => {
      history.push({
        pathname: '/hotel/detail',
        hash,
        search: stringifySearchSpec({
          type: 'HOTEL',
          visitId: spec.visitId,
          searchId: spec.searchId,
          id: spec.propertyId,
          geoName: spec.propertyName,
          checkInDate: spec.checkInDate,
          checkOutDate: spec.checkOutDate,
          rooms: spec.numOfRooms,
          travelers: [],
          nonEmployeeTravelers: defaultHotelNonEmployee,
          tripRequestId: spec.tripRequestId || '0',
        }),
      });
    },
    [spec]
  );
}

import { useContentResource } from '@traveloka/ctv-core';
import { Button, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useProductRequestRefundPage } from 'refund/request-refund/contexts/ProductRequestRefundPageContext';
import { HotelSubmitRefundError } from 'refund/shared/api/types';

export default function HotelErrorModal() {
  const [{ error }, { goToBookingDetail }] = useProductRequestRefundPage();

  const content = useContentResource().CorporateEnumHotelSubmitRefundError;

  if (
    error !== HotelSubmitRefundError.ALREADY_CANCELLED &&
    error !== HotelSubmitRefundError.NON_REFUNDABLE_BOOKING
  ) {
    return null;
  }

  const mappingContent = {
    ALREADY_CANCELLED: {
      title: content.alreadyCancelledTitle,
      body: content.alreadyCancelledBody,
    },
    NON_REFUNDABLE_BOOKING: {
      title: content.nonRefundableTitle,
      body: content.nonRefundableBody,
    },
  };

  return (
    <Modal isVisible={true}>
      <Popup showCloseButton={false} width={600} height={260}>
        <View style={Style.container}>
          <Text style={Style.title} variant="title-1">
            {error && mappingContent[error].title}
          </Text>
          <Text variant="ui-small">{error && mappingContent[error].body}</Text>
          <Button
            style={Style.button}
            onPress={goToBookingDetail}
            variant="main-cta"
            text={content.redirectBookingDetailButtonText}
          />
        </View>
      </Popup>
    </Modal>
  );
}

const Style = StyleSheet.create({
  container: {
    paddingHorizontal: Token.spacing.m,
  },
  title: {
    marginBottom: Token.spacing.xxl,
  },
  button: {
    marginTop: Token.spacing.xxl,
    marginLeft: 'auto',
  },
});

import React from 'react';
import {
  StyleSheet,
  TouchableWithoutFeedbackProps,
  View,
  ViewProps,
} from 'react-native';

import {
  useTheme,
  Button,
  Image,
  Text,
  Token,
  Tooltip,
} from '@traveloka/web-components';

import { FlightSegmentData, FlightSummaryData } from '../types';
import FlightSingleSummaryTooltipContent from './FlightSingleSummaryTooltipContent';

type Props = {
  summary: FlightSummaryData;
  segments: FlightSegmentData[];
  label: {
    duration: string;
    detail: string;
    price: string;
    tax: string;
    changeFlight: string;
  };
  onPressChangeFlight: TouchableWithoutFeedbackProps['onPress'];
  style?: ViewProps['style'];
};

export default function FlightSearchSingleSummary(props: Props) {
  const { summary, segments, label, onPressChangeFlight, style } = props;

  const { color } = useTheme();

  return (
    <View
      style={[
        {
          backgroundColor: color.lightPrimary,
        },
        style,
      ]}
    >
      <View style={Style.row}>
        {summary.airlineLogos.map((logo, index) => (
          <View key={index} style={Style.image}>
            <Image
              src={logo.src}
              alt={logo.alt}
              width={40}
              height={40}
              objectFit="contain"
            />
          </View>
        ))}
        <View style={Style.stretch}>
          <Text variant="ui-small">{summary.airlineName}</Text>
          <Text variant="ui-small" ink="secondary">
            {summary.seatClass}
          </Text>
        </View>
      </View>
      <View style={Style.row}>
        <View style={Style.stretch}>
          <Text>{summary.departureTime}</Text>
          <Text variant="ui-tiny" ink="secondary">
            {summary.departureLocation}
          </Text>
        </View>
        <View style={Style.stretch}>
          <Text>
            {summary.arrivalTime}
            {summary.offsetStr && (
              <Text variant="ui-tiny" style={Style.offset}>
                {summary.offsetStr}
              </Text>
            )}
          </Text>
          <Text variant="ui-tiny" ink="secondary">
            {summary.arrivalLocation}
          </Text>
        </View>
      </View>
      <View style={Style.row}>
        <View style={Style.stretch}>
          <Text variant="ui-small">{label.duration}</Text>
          <Text
            variant="ui-tiny"
            ink="secondary"
          >{`${summary.durationStr}, ${summary.transitStr}`}</Text>
        </View>
        <View style={[Style.stretch, Style.detail]}>
          <Tooltip
            position="right"
            content={<FlightSingleSummaryTooltipContent segments={segments} />}
          >
            <Text variant="ui-small" ink="primary-interactive">
              {label.detail}
            </Text>
          </Tooltip>
        </View>
      </View>
      <View style={Style.row}>
        <View style={Style.stretch}>
          <Text variant="ui-small">{label.price}</Text>
          <Text variant="ui-tiny" ink="secondary">
            {label.tax}
          </Text>
        </View>
        <View style={Style.stretch}>
          {summary.originPriceStr && (
            <Text variant="ui-tiny" ink="muted" style={Style.strike}>
              {summary.originPriceStr}
            </Text>
          )}
          <Text ink="price" style={Style.price}>
            {summary.mainPriceStr}
          </Text>
        </View>
      </View>
      <View style={Style.button}>
        <Button
          variant="text"
          size="small"
          text={label.changeFlight}
          onPress={onPressChangeFlight}
        />
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  image: {
    marginRight: Token.spacing.xs,
  },
  row: {
    marginBottom: Token.spacing.m,
    flexDirection: 'row',
  },
  stretch: {
    flex: 1,
  },
  offset: {
    marginLeft: Token.spacing.xxs,
  },
  strike: {
    textDecorationLine: 'line-through',
  },
  price: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  button: {
    alignItems: 'center',
  },
  detail: {
    alignItems: 'flex-start',
    // @ts-ignore
    cursor: 'default',
  },
});

import React, {
  createContext,
  useState,
  PropsWithChildren,
  useContext,
} from 'react';

import { CreditLimitResponse, REMAINING_CREDIT_LIMIT_API } from './api';
import { CreditLimit, HasViewPermission, NoViewPermission } from './types';
import { useApi, useAuth, Permission } from '@traveloka/ctv-core';
import { convert } from 'shared/utils/currency';

const CreditLimitContext = createContext<CreditLimit>({
  data: undefined,
  fetchCreditLimit() {},
  isFetching: false,
});

export function useCreditLimit() {
  return useContext(CreditLimitContext);
}

export function CreditLimitProvider(props: PropsWithChildren<{}>) {
  const [creditLimit, setCreditLimit] = useState<CreditLimit['data']>(); //Deleted after BE ready
  const [isFetching, setIsFetching] = useState(false);

  const { user } = useAuth();

  const fetchCreditLimit = useApi<CreditLimitResponse>({
    domain: 'booking',
    method: 'post',
    path: REMAINING_CREDIT_LIMIT_API,
  });

  function fetchData() {
    setIsFetching(true);
    fetchCreditLimit({})
      .then(res => {
        if (res.success) {
          const hasPermission: boolean =
            user?.has(Permission.BILLING_READ) ?? false;

          if (!hasPermission) {
            setCreditLimit({
              hasPermission: false,
              remainingCreditLimit: {
                amount: null,
                status: res.data.remainingCreditLimit.status,
              },
              remainingPercentage: null,
              totalCreditLimit: null,
            } as NoViewPermission);
          } else if (hasPermission) {
            setCreditLimit({
              hasPermission: true,
              remainingCreditLimit: {
                amount: convert(res.data.remainingCreditLimit.amount!),
                status: res.data.remainingCreditLimit.status,
              },
              remainingPercentage: res.data.remainingPercentage,
              totalCreditLimit: {
                amount: convert(res.data.totalCreditLimit!.amount),
              },
            } as HasViewPermission);
          }
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  return (
    <CreditLimitContext.Provider
      value={{ data: creditLimit, fetchCreditLimit: fetchData, isFetching }}
    >
      {props.children}
    </CreditLimitContext.Provider>
  );
}

import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Token, Text } from '@traveloka/web-components';

import {
  LocalRequestRefundError,
  useProductRequestRefundPage,
} from 'refund/request-refund/contexts/ProductRequestRefundPageContext';
import {
  FlightSubmitRefundError,
  HotelSubmitRefundError,
} from 'refund/shared/api/types';

export default function FlightRequestRefundErrorBar() {
  const [{ error }] = useProductRequestRefundPage();

  const content = useContentResource().CorporateEnumFlightSubmitRefundError;

  switch (error) {
    case null:
    case HotelSubmitRefundError.ALREADY_CANCELLED:
    case HotelSubmitRefundError.NON_REFUNDABLE_BOOKING:
    case FlightSubmitRefundError.PARTIALLY_FAILED:
      return null;
    default:
      return (
        <View style={Style.container}>
          <Text variant="ui-small" ink="white">
            {content[fallbackUnknownError(error)]}
          </Text>
        </View>
      );
  }
}

function fallbackUnknownError(
  error:
    | FlightSubmitRefundError
    | HotelSubmitRefundError
    | LocalRequestRefundError
) {
  switch (error) {
    case LocalRequestRefundError.MUST_NOT_CHILD_INFANT_ONLY:
    case FlightSubmitRefundError.INCONSISTENT_REFUND_REASON:
    case FlightSubmitRefundError.INVALID_CUSTOMER_REASON:
    case FlightSubmitRefundError.PAX_NOT_REFUNDABLE:
    case FlightSubmitRefundError.MUST_REFUND_ALL_PAX:
    case FlightSubmitRefundError.SHOULD_REFUND_ALL_JOURNEY:
    case FlightSubmitRefundError.SHOULD_REFUND_ALL_ROUTE:
    case FlightSubmitRefundError.GENERAL_ERROR:
    case FlightSubmitRefundError.PARTIALLY_FAILED:
    case FlightSubmitRefundError.BOOKING_NOT_FOUND:
    case HotelSubmitRefundError.ALREADY_CANCELLED:
    case HotelSubmitRefundError.NON_REFUNDABLE_BOOKING:
    case HotelSubmitRefundError.INVALID_SPEC:
      return error;
    default:
      return FlightSubmitRefundError.GENERAL_ERROR;
  }
}

const Style = StyleSheet.create({
  container: {
    backgroundColor: Token.color.uiRedSecondary,
    paddingVertical: Token.spacing.l,
    alignItems: 'center',
  },
});

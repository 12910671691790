import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useAuth, Permission, LocalizedLink } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { Link } from '@traveloka/ctvweb-ui';
import Lock from '@traveloka/icon-kit-web/svg/light/ic_system_lock_24px.svg';
import { Button, Icon, Text, Token } from '@traveloka/web-components';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import AccountSuspendedPopup from 'account-status/components/AccountSuspendedPopup/AccountSuspendedPopup';
import { usePaymentState } from 'payment/select/contexts/PaymentContext';

import ConfirmPaymentModal from '../ConfirmPaymentModal/ConfirmPaymentModal';

type Props = {
  confirmPayment(): Promise<string | undefined>;
};

export default function PayNow(props: Props) {
  const { confirmPayment } = props;
  const { user } = useAuth();
  const hasPermission = user?.has(Permission.ISSUE_PAYMENT_AND_BOOKING);

  const content = useContentResource().CorporateProductPayment;

  const {
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();

  const { creditLimit, creditCard } = usePaymentState();
  const { creditPaymentStatus } = creditLimit;
  const { registered: creditCardRegistered } = creditCard;

  const [isVisible, setIsVisible] = useState(false);

  const showConfirmation = useCallback(() => setIsVisible(true), []);
  const hideConfirmation = useCallback(() => setIsVisible(false), []);

  return (
    <View style={Style.container}>
      <Text variant="ui-small" style={Style.tnc}>
        {`${content.agreementInfoText} `}
        <LocalizedLink to={`/terms-and-conditions`} target="_blank">
          <Text ink="primary-interactive" variant="ui-small">
            {` ${content.agreementText} `}
          </Text>
        </LocalizedLink>
        {`${content.agreementPolicySeparatorText} `}
        <Link
          to="https://www.traveloka.com/en-id/privacypolicy"
          target="_blank"
        >
          <Text ink="primary-interactive" variant="ui-small">
            {content.policyText}
          </Text>
        </Link>
        {'.'}
      </Text>
      <Button
        testID="confirmation-payment.content.submit"
        variant="main-cta"
        iconStart={() => <Icon src={Lock} />}
        text={content.submitButtonText}
        onPress={() => fetchAccountStatus(showConfirmation)}
        disabled={
          !hasPermission ||
          creditPaymentStatus !== 'SUFFICIENT' ||
          !creditCardRegistered
        }
        loading={isAccountStatusFetching}
      />
      <ConfirmPaymentModal
        isVisible={isVisible}
        confirmPayment={confirmPayment}
        onClosePress={hideConfirmation}
      />
      <AccountSuspendedPopup backToHome />
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
    paddingHorizontal: Token.spacing.l,
  },
  tnc: {
    textAlign: 'right',
    marginBottom: Token.spacing.xl,
  },
});

import React, { useState, useCallback } from 'react';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import Broken from '@traveloka/icon-kit-web/svg/dark/ic_system_img_placeholder_broken_24px.svg';
import { Button, Card, Icon, Image, Token } from '@traveloka/web-components';

import { appendTestId } from '../../shared/utils/TestUtil';
import IconLabel from '../IconLabel/IconLabel';

type Props = {
  roomDetailLabel: string;
  images: string[];
  onImagePress?(url: string): void;
  onRoomDetailPress(): void;
  roomSize?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

export default function RoomStaticInfo(props: Props) {
  const {
    roomDetailLabel,
    images: rawImages,
    roomSize,
    style,
    onImagePress,
    onRoomDetailPress,
    testID,
  } = props;

  const [first, ...rest] = Array.from(
    { length: 4 },
    (_, index) => rawImages[index]
  );

  return (
    <Card style={style}>
      <SafeImage
        testID={appendTestId(testID, 'large-image')}
        onPress={onImagePress}
        src={first}
        style={Style.big}
      />
      <View style={Style.thumbnails}>
        {rest.map((image, index) => (
          <SafeImage
            testID={appendTestId(testID, `thumbnails.${index}`)}
            key={image}
            onPress={onImagePress}
            src={image}
            style={Style.thumbnail}
          />
        ))}
      </View>
      <View style={Style.content}>
        {!!roomSize && (
          <IconLabel testID={testID} type="size" text={roomSize} />
        )}
        <Button
          testID={appendTestId(testID, 'see-detail-button')}
          variant="secondary"
          size="small"
          text={roomDetailLabel}
          onPress={onRoomDetailPress}
          style={Style.button}
        />
      </View>
    </Card>
  );
}

type SafeImageProps = {
  src?: string;
  onPress?(url: string): void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

function SafeImage(props: SafeImageProps) {
  const { onPress, src, style, testID } = props;
  const [isError, setIsError] = useState(false);

  const handleOnPress = useCallback(() => {
    if (src) {
      onPress?.(src);
    }
  }, [onPress, src]);
  const handleError = useCallback(() => setIsError(true), []);

  if (!src || isError) {
    return (
      <View
        testID={appendTestId(testID, 'no-image')}
        style={[Style.noImage, Style.background, style]}
      >
        <Icon src={Broken} width={24} height={24} />
      </View>
    );
  }

  return (
    <TouchableOpacity
      testID={testID}
      activeOpacity={0.5}
      style={[Style.background, style]}
      onPress={handleOnPress}
    >
      <Image
        src={src}
        objectFit="cover"
        height="100%"
        width="100%"
        onError={handleError}
      />
    </TouchableOpacity>
  );
}

const Style = StyleSheet.create({
  content: {
    padding: Token.spacing.m,
  },
  button: {
    marginTop: Token.spacing.m,
    alignSelf: 'stretch',
  },
  thumbnails: {
    flexDirection: 'row',
  },
  big: {
    height: 144,
  },
  thumbnail: {
    flex: 1,
    height: 48,
  },
  noImage: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    backgroundColor: Token.color.uiDarkNeutral,
  },
});

import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';

import {
  RequestRefundStep,
  useProductRequestRefundPage,
} from '../ProductRequestRefundPageContext';

type Action = {
  goToNextStep(): void;
  goToPreviousStep(): void;
};

const HotelRequestRefundNavigatorContext = createContext<Action>(undefined!);

export function HotelRequestRefundNavigatorProvider(
  props: PropsWithChildren<{}>
) {
  const [
    { currentStep },
    { changeCurrentStep },
  ] = useProductRequestRefundPage();

  const goToNextStep = useCallback(() => {
    switch (currentStep) {
      case RequestRefundStep.REFUND_POLICY:
        return changeCurrentStep(RequestRefundStep.SELECT_DETAILS);
      case RequestRefundStep.SELECT_DETAILS:
        return changeCurrentStep(RequestRefundStep.REVIEW_REQUEST);
    }
  }, [currentStep, changeCurrentStep]);

  const goToPreviousStep = useCallback(() => {
    switch (currentStep) {
      case RequestRefundStep.SELECT_DETAILS:
        return changeCurrentStep(RequestRefundStep.REFUND_POLICY);
      case RequestRefundStep.REVIEW_REQUEST:
        return changeCurrentStep(RequestRefundStep.SELECT_DETAILS);
    }
  }, [currentStep, changeCurrentStep]);

  return (
    <HotelRequestRefundNavigatorContext.Provider
      value={{ goToNextStep, goToPreviousStep }}
    >
      {props.children}
    </HotelRequestRefundNavigatorContext.Provider>
  );
}

export function useHotelRequestRefundNavigator() {
  return useContext(HotelRequestRefundNavigatorContext);
}

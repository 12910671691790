import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useAuth } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { Text, Token } from '@traveloka/web-components';

import CreditLimitPaymentStatus from 'credit-limit/components/CreditLimitPaymentStatus/CreditLimitPaymentStatus';

export default function Invoice() {
  const auth = useAuth();

  const content = useContentResource().CorporateProductPaymentInvoiceContent;

  return (
    <>
      <Text variant="ui-large" style={Style.title}>
        {content.title}
      </Text>
      <View style={Style.section}>
        <Text variant="ui-small" ink="secondary">
          {content.corporateNameText}
        </Text>
        <Text style={Style.value}>{auth.user?.corporateName}</Text>
        <Text variant="ui-small" ink="secondary">
          {content.creditLimitStatusText}
        </Text>
        <View
          testID="confirmation-payment.content.credit-limit-status"
          style={Style.icon}
        >
          <CreditLimitPaymentStatus />
        </View>
      </View>
    </>
  );
}

const Style = StyleSheet.create({
  title: {
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.m,
  },
  section: {
    paddingHorizontal: Token.spacing.l,
  },
  value: {
    marginBottom: Token.spacing.m,
  },
  icon: {
    flexDirection: 'row',
    marginBottom: Token.spacing.m,
  },
});

import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import {
  Token,
  Checkbox,
  Text,
  Tooltip,
  Icon,
} from '@traveloka/web-components';
import { InputDropdownRadio, TextTooltip } from '@traveloka/ctvweb-ui';
import ChevronDown from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';
import WarningIcon from '@traveloka/icon-kit-web/svg/yellowSecondary/ic_system_status_warning-fill_24px.svg';

import {
  FlightTraveler,
  FlightMainRefundReasonWithSecondary,
  FlightRefundPersonalReason,
} from 'refund/shared/api/types';
import { useFlightRequestRefund } from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';
import {
  useFlightRequestRefundForm,
  useFlightRequestRefundFormAction,
} from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';

type PassengerSegmentProps = {
  traveler: FlightTraveler;
  travelerIndex: number;
};

export default function FlightPassengerSegment(props: PassengerSegmentProps) {
  const { traveler, travelerIndex } = props;

  const [{ data }] = useFlightRequestRefund();
  const { travelerForms } = useFlightRequestRefundForm();
  const {
    checkShouldRefundAllPax,
    updatePassengerCheckboxes,
  } = useFlightRequestRefundFormAction();

  const { flightRefundData } = data;

  function changeCheckbox() {
    updatePassengerCheckboxes(props.travelerIndex);
  }
  const shouldRefundAllPax = checkShouldRefundAllPax();
  const partialRefundedPassenger = traveler.refundedJourneyIndex.map(index =>
    flightRefundData.journeys[index].segments
      .map(segment => segment.flightCode)
      .join(', ')
  );

  const content = useContentResource()
    .CorporateFlightRequestRefundSelectDetails;
  const travelerTitleContent = useContentResource().CorporateEnumTravelerTitle;
  const nonEmployeeTypeContent = useContentResource()
    .CorporateEnumNonEmployeeType;

  return (
    <View
      style={[
        Style.container,
        { zIndex: travelerForms.length - travelerIndex },
      ]}
    >
      <View style={Style.checkbox}>
        <Checkbox
          checked={travelerForms[travelerIndex].checkboxValue}
          onChange={changeCheckbox}
          disabled={shouldRefundAllPax}
        />
      </View>
      <View style={Style.content}>
        <TouchableOpacity
          style={Style.clickableContent}
          onPress={changeCheckbox}
          disabled={shouldRefundAllPax}
        >
          <View>
            <TextTooltip
              style={Style.fullnameText}
              styleContainer={Style.fullnameContainer}
              styleTooltip={Style.fullnameTooltip}
              variant="title-3"
              text={`${travelerTitleContent[traveler.title]} ${
                traveler.fullname
              }`}
              maxLength={20}
            />
            {!!partialRefundedPassenger.length && (
              <Tooltip
                content={
                  <Text variant="ui-tiny" ink="white">
                    {content.passengerRefundedTooltipContentText}{' '}
                    {partialRefundedPassenger.join(', ')}
                  </Text>
                }
                variant="normal"
                position="top"
                width={255}
              >
                <View style={Style.tooltip}>
                  <Icon src={WarningIcon} width={16} height={16} />
                  <Text style={Style.tooltipText} variant="ui-small">
                    {content.passengerRefundedItemInfoText}
                  </Text>
                </View>
              </Tooltip>
            )}
          </View>
          <Text ink="secondary">{nonEmployeeTypeContent[traveler.type]}</Text>
        </TouchableOpacity>
        <SecondaryReason travelerIndex={travelerIndex} />
      </View>
    </View>
  );
}

type SecondaryReasonProps = {
  travelerIndex: number;
};

function SecondaryReason(props: SecondaryReasonProps) {
  const { travelerIndex } = props;

  const { mainRefundReason, travelerForms } = useFlightRequestRefundForm();
  const { updateSecondaryReason } = useFlightRequestRefundFormAction();

  const content = useContentResource()
    .CorporateFlightRequestRefundSelectDetails;
  const personalReasonContent = useContentResource()
    .CorporateEnumFlightPersonalRefundReason;

  if (
    travelerForms[travelerIndex].checkboxValue &&
    mainRefundReason!.mainReason ===
      FlightMainRefundReasonWithSecondary.PERSONAL_REASON
  ) {
    return (
      <InputDropdownRadio
        style={Style.secondaryReasonDropdown}
        cardStyle={Style.secondaryReasonDropdownCard}
        items={mainRefundReason!.secondaryReasons!.map(reason => ({
          label: personalReasonContent[reason.reason!],
          value: reason.reason!,
        }))}
        onChange={(value: FlightRefundPersonalReason) =>
          updateSecondaryReason(travelerIndex, value)
        }
        alignment="left"
        text={
          travelerForms[travelerIndex].secondaryReason
            ? personalReasonContent[
                travelerForms[travelerIndex].secondaryReason!
              ]
            : content.emptySelectSecondaryReason
        }
        value={travelerForms[travelerIndex].secondaryReason ?? ''}
        rightIcon={<Icon src={ChevronDown} />}
      />
    );
  }

  return null;
}

const Style = StyleSheet.create({
  container: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.ml,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  checkbox: {
    width: 24,
    height: 24,
    marginRight: Token.spacing.ml,
  },
  content: {
    flex: 1,
    minHeight: 24,
  },
  clickableContent: {
    flex: 1,
    minHeight: 24,
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullnameContainer: {
    marginRight: Token.spacing.m,
  },
  fullnameText: {
    minWidth: 150,
  },
  fullnameTooltip: {
    width: 'max-content',
  },
  secondaryReasonDropdown: {
    marginTop: Token.spacing.ml,
    width: 280,
  },
  secondaryReasonDropdownCard: {
    width: 256,
  },
  tooltip: {
    flexDirection: 'row',
  },
  tooltipText: {
    marginLeft: Token.spacing.xs,
    color: Token.color.uiYellowSecondary,
  },
});

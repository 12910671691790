import {
  ExistingProductType,
  RequestProductType,
} from 'registration/constants/ProductType';

import {
  Document,
  ExpiredConfirmation,
  PaymentMethod,
  ValidConfirmation,
  StepData,
  CorporateRejection,
  InternalRejection,
  RegistrationStatus,
} from './types';

// Send Email confirmation
export const PREREGISTATION_API = '/api/v2/registration/pre-register';
export type PreregistrationRequest = {
  picEmail: string;
  timeZone: string;
};
export type PreregistrationResponse = {
  status: 'OK' | 'EMAIL_HAS_REGISTERED';
};

// Confirm email
export const CONFIRMATION_API = '/api/v2/registration/pre-register-confirm';
export type ConfirmationRequest = {
  token: string;
};
export type ConfirmationResponse = ExpiredConfirmation | ValidConfirmation;

// Document Requirement
export const DOCUMENT_REQUIREMENT_API = '/api/v2/registration/requirement';
export type DocumentRequirementRequest = {
  token: string;
};
export type DocumentRequirementResponse = {
  documents: Document[];
  creditAssessmentDocuments: Document[];
};

// Registration
export const REGISTRATION_API = '/api/v2/registration/register';
export type RegistrationRequest = {
  picName: string;
  picPhoneCountryCode: string;
  picPhone: string;
  picJobTitle: string;
  corporateName: string;
  corporateIndustry: string;
  country: string;
  officeAddress: string;
  officeCity: string;
  officePostalCode: string;
  officePhoneCountryCode: string;
  officePhone: string;
  website?: string;
  monthlyTravelSpending: string;
  productNeeded: ExistingProductType[];
  productRequest: RequestProductType[];
  paymentMethod: PaymentMethod;
  draft: boolean;
  documents: Array<{
    fileName: string;
    documentType: string;
  }>;
  preferableScheduledMeetingFirst: string; // yyyy-MM-dd HH:mm:ss
  preferableScheduledMeetingSecond: string; // yyyy-MM-dd HH:mm:ss
  discussionTopic: string;
  locationPreference: string;
  ownerIdCard: string;
  ownerAddress: string;
  ownerAddressCity: string;
  ownerPostalCode: string;
  ownerName: string;
  ownerJobTitle: string;
  agreementDocuments: Array<{
    fileName: string;
    documentType: string;
  }>;
  serviceFee: string;
  termsOfPayment: string;
  billingCycle: string;
  creditLimit: string;
  legalNpwpNumber: string;
  legalSppkpNumber: string;
  legalName: string;
  legalFiscalAddress: string;
  legalFiscalCity: string;
  legalFiscalPostalCode: string;
  legalBillingAddress: string;
  legalBillingCity: string;
  legalBillingPostalCode: string;
  documentNotes: string;
  creditAssessmentNotes: string;
  pagePosition: 'REGISTRATION_FORM' | 'PAYMENT_METHOD' | 'DOCUMENT' | 'REVIEW';
  partnerCompanyName: string;
  partnerCompanyRelation: string;
  partnerCompanyPicName: string;
  partnerCompanyPicEmail: string;
  partnerCompanyPhone: string;
  partnerCompanyPhoneCountryCode: string;
  otherEmails: string[];
  token: string;
};
export type RegistrationResponse = {
  status: string;
};

// Save and Exit
export const SAVE_AND_EXIT_API = '/api/v2/registration/save';
export type SaveAndExitRequest = Omit<
  RegistrationRequest,
  'draft' | 'pagePosition'
>;
export type SaveAndExitResponse = {
  status: string;
};

// Upload Document
export const UPLOAD_DOCUMENT_API = '/api/v2/registration/upload';
export type UploadDocumentRequest = {
  country: string;
  documentType: string;
  paymentMethod: PaymentMethod;
  token: string;
};
export type UploadDocumentResponse = {
  isSuccess: boolean;
  url: string;
};

// Upload Multiple Document
export const UPLOAD_MULTIPLE_DOCUMENT_API =
  '/api/v2/registration/upload/multiple';
export type UploadMultipleDocumentRequest = {
  country: string;
  documentType: string;
  paymentMethod: PaymentMethod;
  token: string;
  index: number;
};
export type UploadMultipleDocumentResponse = {
  isSuccess: boolean;
  url: string;
};

// Schedule Meeting
export const SCHEDULE_MEETING_API = '/api/v2/registration/schedule-meeting';
export type ScheduleMeetingRequest = {
  token: string;
  preferableScheduledMeetingFirst: string;
  preferableScheduledMeetingSecond: string;
  discussionTopic: string;
  locationPreference: string;
};
export type ScheduleMeetingResponse = {
  status: string;
};

// Upload NDA
export const UPLOAD_NDA_API = '/api/v2/registration/upload/nda';
export type UploadNdaRequest = {
  token: string;
  country: string;
};
export type UploadNdaResponse = {
  isSuccess: boolean;
  url: string;
};

// Submit NDA
export const SUBMIT_NDA_API = '/api/v2/registration/submit-nda';
export type SubmitNdaRequest = {
  token: string;
  fileName: string;
};
export type SubmitNdaResponse = {
  status: string;
};

// Download Document
export const DOWNLOAD_DOCUMENT_API = '/api/v2/registration/download/document';
export type DownloadDocumentRequest = {
  documentType:
    | 'CC_AGREEMENT_FORM'
    | 'INVOICE_AGREEMENT_FORM'
    | 'NDA'
    | 'CORPORATE_DECK'
    | 'PRODUCT_OVERVIEW';
  token: string;
};
export type DownloadDocumentResponse = {
  url: string;
};

// View Document
export const VIEW_DOCUMENT_API = '/api/v2/registration/view/document';
export type ViewDocumentRequest = {
  documentType: string;
  token: string;
};
export type ViewDocumentResponse = {
  url: string;
};

// View Multiple Document
export const VIEW_MULTIPLE_DOCUMENT_API =
  '/api/v2/registration/view/document/multiple';
export type ViewMultipleDocumentRequest = {
  index: number;
  documentType: string;
  token: string;
};
export type ViewMultipleDocumentResponse = {
  url: string;
};

// Add Others Email
export const ADD_OTHERS_EMAIL_API = '/api/v2/registration/add/other-emails';
export type AddOthersEmailRequest = {
  otherEmails: string[];
  token: string;
};
export type AddOthersEmailResponse = {
  status: 'OK';
};

// Registration Status
export const REGISTRATION_STATUS_API = '/api/v2/registration/status';
export type RegistrationStatusRequest = {
  token: string;
};
export type RegistrationStatusResponse = {
  registrationStatus: RegistrationStatus;
  rejectionReason: InternalRejection | CorporateRejection;
  notes: string;
  paymentMethod: 'INVOICE' | 'CREDIT_CARD';
  incompleteFields: string[];
  steps: StepData[];
};

import React, { useCallback, useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { useTheme, Text } from '@traveloka/web-components';

import {
  useFlightSearchOptionContext,
  useFlightSearchTransitDurationFilterContext,
} from 'flight/search/contexts/FlightSortFilterContext';
import Slider from 'legacy/Slider/Slider';
import { formatMessage } from 'shared/utils/intl';

import { getThemeStyle, CommonStyle } from '../filter-search-toolbar.style';

type Props = {
  style?: ViewProps['style'];
};

function TransitDurationFilter(props: Props) {
  const { style } = props;
  const contentResource = useContentResource();
  const content = {
    hour: contentResource.CorporateFlightGeneral.durationH,
    title: contentResource.CorporateFlightSearchToolbar.transitDuration,
    subtitle: contentResource.CorporateFlightSearchToolbar.transitHourRange,
  };

  const ThemeStyle = getThemeStyle(useTheme());
  const { filterOption, setFilter } = useFlightSearchOptionContext();
  const value = useFlightSearchTransitDurationFilterContext();
  const rangeHour = {
    min: minuteToHour(filterOption.transitDuration.min),
    max: minuteToHour(filterOption.transitDuration.max),
  };
  const [display, setDisplay] = useState({
    min: minuteToHour(value.min),
    max: minuteToHour(value.max),
  });

  const handleSliderChange = useCallback(
    ([min, max]) => {
      setDisplay({ min, max });
    },
    [setDisplay]
  );
  const handleSliderRelease = useCallback(
    ([min, max]) => {
      setFilter('transitDuration', {
        min: min * 60,
        max: max * 60,
      });
    },
    [setFilter]
  );

  return (
    <View style={style}>
      <Text ink="primary" variant="ui-small">
        {content.title}
      </Text>
      <View style={[CommonStyle.container, ThemeStyle.container]}>
        <Text
          testID="flight.search.toolbar.transit.duration.subtitle"
          ink="secondary"
          variant="ui-tiny"
        >
          {formatMessage(content.subtitle, {
            min: display.min,
            max: display.max,
          })}
        </Text>
        <View
          testID="flight.search.toolbar.transit.duration.slider-container"
          style={CommonStyle.slider}
        >
          <Slider
            min={rangeHour.min}
            max={rangeHour.max}
            defaultValue={[display.min, display.max]}
            onChange={handleSliderChange}
            onAfterChange={handleSliderRelease}
            pushable
          />
        </View>
        <View style={Style.spread}>
          <Text variant="ui-tiny">
            {formatMessage(content.hour, { h: rangeHour.min })}
          </Text>
          <Text variant="ui-tiny">
            {formatMessage(content.hour, { h: rangeHour.max })}
          </Text>
        </View>
      </View>
    </View>
  );
}

function minuteToHour(minute: number) {
  return Math.ceil(minute / 60);
}

const Style = StyleSheet.create({
  spread: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default TransitDurationFilter;

import React, { Dispatch, SetStateAction, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

// @ts-ignore
import EyeShow from '@traveloka/icon-kit-web/react/IcSystemEyeShow';
// @ts-ignore
import EyeHide from '@traveloka/icon-kit-web/react/IcSystemEyeHide';

import { Input, Text, Token } from '@traveloka/web-components';

type FieldProps = {
  label: string;
  placeholder: string;
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
  handleSubmit?(): void;
  password?: boolean;
  disabled?: boolean;
};
export function Field(props: FieldProps) {
  const {
    label,
    password = false,
    placeholder,
    value,
    onChange,
    handleSubmit,
    disabled,
  } = props;

  if (password) {
    return <PasswordField {...props} />;
  }

  return (
    <View style={styles.container}>
      <Text
        style={styles.label}
        variant="ui-small"
        ink={disabled ? 'muted' : 'secondary'}
      >
        {label}
      </Text>
      <Input
        containerStyle={styles.inputContainer}
        placeholder={placeholder}
        value={value}
        onChangeText={onChange}
        onKeyPress={(e: { key: string }) =>
          e.key === 'Enter' && handleSubmit?.()
        }
        disabled={disabled}
      />
    </View>
  );
}

function PasswordField({
  label,
  placeholder,
  value,
  onChange,
  handleSubmit,
  disabled,
}: FieldProps) {
  const [isHideText, setIsHideText] = useState(true);

  const HideShowIcon = isHideText ? EyeHide : EyeShow;

  return (
    <View style={styles.container}>
      <Text
        style={styles.label}
        variant="ui-small"
        ink={disabled ? 'muted' : 'secondary'}
      >
        {label}
      </Text>
      <Input
        containerStyle={styles.inputContainer}
        placeholder={placeholder}
        secureTextEntry={isHideText}
        value={value}
        onChangeText={onChange}
        onKeyPress={(e: { key: string }) =>
          e.key === 'Enter' && handleSubmit?.()
        }
        iconRight={
          <TouchableOpacity onPress={() => setIsHideText(prev => !prev)}>
            <HideShowIcon
              color={Token.color.uiDarkSecondary}
              width={16}
              height={16}
            />
          </TouchableOpacity>
        }
        disabled={disabled}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: Token.spacing.xs,
    marginBottom: Token.spacing.m,
  },
  label: {
    marginBottom: Token.spacing.xxs,
  },
  inputContainer: {
    width: 384,
  },
});

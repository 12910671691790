import { useCallback, useState } from 'react';

import {
  AutocompleteRequest,
  AutocompleteResponse,
  AUTOCOMPLETE_API,
} from 'hotel/shared/api';
import { AutocompleteItem } from 'hotel/shared/api/types';
import debounce from 'lodash/debounce';

import { useApi } from '@traveloka/ctv-core';

export default function useAutocomplete() {
  const [items, setItems] = useState<AutocompleteItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const fetchAutocomplete = useApi<AutocompleteResponse, AutocompleteRequest>({
    domain: 'search',
    method: 'post',
    path: AUTOCOMPLETE_API,
  });

  const search = useCallback(
    debounce(async (query: string) => {
      setLoading(true);
      const res = await fetchAutocomplete({ query });

      if (res.success) {
        setItems(res.data.results);
      }
      setLoading(false);
    }, 300),
    []
  );

  return [items, search, loading] as const;
}

import React, { useRef, useState } from 'react';
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInputKeyPressEventData,
  View,
} from 'react-native';

import { useLocalizedDateFormat, Format } from '@traveloka/ctv-core';
import { Card, Token } from '@traveloka/web-components';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { appendTestId } from '../../../utils/TestUtil';
import Calendar from '../../Calendar/Calendar';
import Fade from '../../Fade/Fade';
import Input, { Props as InputProps } from '../Input/Input';

type Props = {
  value: Date;
  relatedValue?: Date;
  minDate?: Date;
  maxDate?: Date;
  dateFormat: Format;
  onDatePress?: (date: Date) => void;
} & OmitTyped<InputProps, 'value' | 'editable'>;

export default function InputDatepicker(props: Props) {
  const {
    style,
    value,
    relatedValue,
    minDate,
    maxDate,
    dateFormat,
    onDatePress,
    testID,
    ...inputProps
  } = props;

  const { format } = useLocalizedDateFormat();
  const rootRef = useRef<View>(null);
  const [showCalendar, setShowCalendar] = useState(false);

  function handleFocus() {
    setShowCalendar(true);
  }

  function handleKeyPress(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    switch (e.nativeEvent.key) {
      case 'Tab':
        setShowCalendar(false);
        break;
    }
    return;
  }

  function handleDayPress(date: Date) {
    setShowCalendar(false);

    if (onDatePress) {
      onDatePress(date);
    }
  }

  useOnClickOutside(rootRef, () => {
    setShowCalendar(false);
  });

  return (
    <View ref={rootRef} style={style}>
      <Input
        {...inputProps}
        testID={appendTestId(testID, 'input')}
        editable={false}
        onKeyPress={handleKeyPress}
        onFocus={handleFocus}
        value={format(value, dateFormat)}
      />
      <Fade visible={showCalendar} style={Style.dropdown}>
        <Card elevation="float">
          <Calendar
            maxDate={maxDate}
            minDate={minDate}
            onDayPress={handleDayPress}
            relatedValue={relatedValue}
            value={value}
          />
        </Card>
      </Fade>
    </View>
  );
}

const Style = StyleSheet.create({
  dropdown: {
    paddingTop: Token.spacing.xs,
    position: 'absolute',
    left: 0,
    top: '100%',
    zIndex: 10,
  },
});

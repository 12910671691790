import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  MutableRefObject,
  ReactNode,
} from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import {
  Control,
  Controller,
  FormProvider,
  useForm,
  UseFormReturn,
} from 'react-hook-form';

import { useApi, useContentResource } from '@traveloka/ctv-core';
import {
  CreateEntryRequest,
  CreateEntryResponse,
  CREATE_ENTRY,
  EntryListRequest,
  EntryListResponse,
  GET_ENTRY_LIST,
  ResultStatus,
  UpdateEntryRequest,
  UpdateEntryResponse,
  UPDATE_ENTRY,
  EntryDetailRequest,
  EntryDetailResponse,
  GET_ENTRY_DETAIL,
} from '@traveloka/ctv-core/pad';
import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import { Input, InputDropdown } from '@traveloka/ctvweb-ui';
import Chevron from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_24px.svg';
import {
  useTheme,
  Button,
  Icon,
  InfoBox,
  Popup,
  Token,
  Checkbox,
} from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import { ItemWithNewEntry } from 'company/shared/types/ItemWithNewEntry';
import {
  Employee,
  PartialDivision,
  PartialEmployee,
  PartialRole,
  PartialTier,
  UpsertEmployee,
} from 'company/types';
import InputField from 'shared/components/form/InputField/InputField';
import InputDropdownField from 'shared/components/form/InputDropdownField/InputDropdownField';
import CheckboxField from 'shared/components/form/CheckboxField/CheckboxField';
import {
  isEmail,
  isName,
  maxLength,
  required,
  isAlphaNumericSpace,
  minLength,
} from 'shared/utils/validator';
import { formatMessage } from 'shared/utils/intl';

type FormValue = {
  fullname: string;
  email: string;
  isApprover: boolean;
  divisionId?: string;
  tierId?: string;
  roleId: string;
  managerId?: string;
};

type Props = {
  visible: boolean;
  employee?: Partial<Employee>;
  refRefetch?: MutableRefObject<boolean>;
  onCloseModal(): void;
  onSubmitSuccess?(data: Partial<Employee>): void;
  onAddDivisionSuccess?: () => void;
  onAddTierSuccess?: () => void;
  onAddRoleSuccess?: () => void;
  hasDivisionField?: boolean;
  testIDPrefix?: string;
};

const defaultAdditionalPayload = { corporateId: 0 };
const roleAdditionalPayload = { corporateId: 0, roleMatrixes: [] };
const testID = 'employee-modal';
const defaultEmployee: FormValue = {
  fullname: '',
  email: '',
  isApprover: false,
  divisionId: '',
  tierId: '',
  roleId: '',
  managerId: '',
};

export default React.memo(function CompanyEmployeeUpsertModal(props: Props) {
  const { visible, onCloseModal, employee, testIDPrefix } = props;

  const [versionNo, setVersionNo] = useState<string>();

  const content = useContentResource().CorporateEmployeeListUpsertModal;
  const { color } = useTheme();

  const getEntryDetail = useApi<
    EntryDetailResponse<Employee>,
    EntryDetailRequest
  >({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_DETAIL,
  });

  const scrollStyle = {
    backgroundColor: color.lightPrimary,
  };

  const isInsert = employee?.employeeId === undefined;

  useEffect(() => {
    if (visible && employee?.employeeId) {
      getEntryDetail({
        entityType: 'employee',
        entryId: employee.employeeId,
      }).then(detail => {
        if (detail.success) {
          setVersionNo(detail.data.version?.number);
        }
      });
    }
  }, [visible]);

  return (
    <Modal isVisible={visible}>
      <View
        testID={appendTestId(
          testIDPrefix,
          `${testID}.${isInsert ? 'insert' : 'update'}`
        )}
        style={[Style.scroll, scrollStyle]}
      >
        <Popup
          title={isInsert ? content.insertTitle : content.updateTitle}
          showCloseButton={true}
          onCloseButtonPress={onCloseModal}
          width={350}
        >
          <EmployeeForm {...props} isInsert={isInsert} versionNo={versionNo} />
        </Popup>
      </View>
    </Modal>
  );
});

type EmployeeFormProps = Props & {
  isInsert: boolean;
  versionNo?: string;
};

function EmployeeForm(props: EmployeeFormProps) {
  const {
    isInsert,
    visible,
    employee,
    versionNo,
    refRefetch,
    onCloseModal,
    onSubmitSuccess,
    onAddDivisionSuccess,
    onAddRoleSuccess,
    onAddTierSuccess,
    hasDivisionField = true,
    testIDPrefix,
  } = props;

  const content = useContentResource().CorporateEmployeeListUpsertModal;

  const [divisions, setDivisions] = useState<ItemWithNewEntry[]>([]);
  const [tiers, setTiers] = useState<ItemWithNewEntry[]>([]);
  const [roles, setRoles] = useState<ItemWithNewEntry[]>([]);
  const [employees, setEmployees] = useState<ItemWithNewEntry[]>([]);

  const defaultValues = useMemo<FormValue>(() => {
    const e = employee
      ? {
          fullname: employee.fullname || '',
          email: employee.email || '',
          isApprover: employee.isApprover || false,
          divisionId: employee.divisionId || undefined,
          tierId: employee.tierId || undefined,
          roleId: employee.roleId || undefined!,
          managerId: employee.managerId || undefined,
        }
      : defaultEmployee;

    return e;
  }, [visible, employee]);

  const methods = useForm<FormValue>({ defaultValues });
  const {
    formState: { isSubmitting, errors },
    handleSubmit: formSubmit,
    setValue,
    reset: formReset,
    setError,
  } = methods;

  const fetchEntryList = useApi<EntryListResponse<unknown>, EntryListRequest>({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_LIST,
  });

  const fetchEntries = useCallback(
    (entityType: string) => {
      const spec = {
        entityType,
        search: {
          entriesCount: 9999,
          filter: [],
        },
      };

      return fetchEntryList(spec);
    },
    [fetchEntryList]
  );

  const createEntryList = useApi<
    CreateEntryResponse,
    CreateEntryRequest<UpsertEmployee>
  >({
    domain: 'management',
    method: 'post',
    path: CREATE_ENTRY,
  });

  const updateEntryList = useApi<
    UpdateEntryResponse,
    UpdateEntryRequest<UpsertEmployee>
  >({
    domain: 'management',
    method: 'post',
    path: UPDATE_ENTRY,
  });

  const handleSubmit = useCallback(
    async (values: FormValue) => {
      const upsert: UpsertEmployee = {
        ...employee,
        // TODO: Remove next line after FE and BE agreed to implement birthDate
        birthDate: null,
        corporateId: 0,
        employeeId:
          employee && employee.employeeId ? Number(employee.employeeId) : 0,
        fullname: values.fullname,
        email: values.email,
        isApprover: values.isApprover,
        divisionId: values.divisionId || null,
        tierId: values.tierId || null,
        roleId: values.roleId,
        managerId: values.managerId || null,
      };

      const fn =
        employee && employee.employeeId ? updateEntryList : createEntryList;

      try {
        const res = await fn({
          entityType: 'employee',
          entry: upsert,
          versionNo,
        });

        if (res.success) {
          if (res.data.result === ResultStatus.SUCCESS) {
            onSubmitSuccess &&
              onSubmitSuccess({
                ...upsert,
                employeeId: res.data.id,
                corporateId: upsert.corporateId.toString(),
              });

            return;
          }

          throw new Error(res.data.message);
        }

        throw res.error;
      } catch (e) {
        return setError('root.submitError', { message: (e as any).message });
      }
    },
    [
      onSubmitSuccess,
      createEntryList,
      employee,
      updateEntryList,
      versionNo,
      setError,
    ]
  );

  useEffect(() => {
    Promise.all([
      fetchEntries('divisionList'),
      fetchEntries('tierList'),
      fetchEntries('roleList'),
    ]).then(values => {
      const [division, tier, role] = values;

      if (division.success) {
        const divisionList: ItemWithNewEntry[] = (division.data
          .entries as PartialDivision[]).map(division => ({
          label: division.division,
          value: division.divisionId,
        }));
        divisionList.unshift({
          label: content.insertDivisionDropdownItemText,
          value: '',
          newEntry: true,
        });

        setDivisions(divisionList);
      }

      if (tier.success) {
        const tierList: ItemWithNewEntry[] = (tier.data
          .entries as PartialTier[]).map(tier => ({
          label: tier.tier,
          value: tier.tierId,
        }));
        tierList.unshift({
          label: content.insertTierDropdownItemText,
          value: '',
          newEntry: true,
        });

        setTiers(tierList);
      }

      if (role.success) {
        const roleList: ItemWithNewEntry[] = (role.data
          .entries as PartialRole[]).map(role => ({
          label: role.role,
          value: role.roleId,
        }));
        roleList.unshift({
          label: content.insertRoleDropdownItemText,
          value: '',
          newEntry: true,
        });

        setRoles(roleList);
      }
    });
  }, []);

  useEffect(() => {
    if (visible) {
      formReset(defaultValues);
    }

    if ((refRefetch && refRefetch.current === false) || visible === false) {
      return;
    }

    fetchEntries('employeeList').then(employee => {
      if (employee.success) {
        setEmployees(
          (employee.data.entries as PartialEmployee[]).map(employee => ({
            label: employee.fullname,
            value: employee.employeeId,
          }))
        );

        if (refRefetch) {
          refRefetch.current = false;
        }
      }
    });
  }, [visible]);

  const injectNewDivision = useCallback(
    (entity: any) => {
      setDivisions(s =>
        s.concat({
          label: entity.division,
          value: entity.id,
        })
      );

      setValue('divisionId', entity.id);
    },
    [setDivisions, setValue]
  );

  const injectNewTier = useCallback(
    (entity: any) => {
      setTiers(s =>
        s.concat({
          label: entity.tier,
          value: entity.id,
        })
      );

      setValue('tierId', entity.id);
    },
    [setTiers, setValue]
  );

  const injectNewRole = useCallback(
    (entity: any) => {
      setRoles(s =>
        s.concat({
          label: entity.role,
          value: entity.id,
        })
      );

      setValue('roleId', entity.id);
    },
    [setRoles, setValue]
  );

  const isInsertTestID = isInsert ? 'add' : 'edit';

  return (
    <FormProvider {...methods}>
      <View
        testID={appendTestId(testIDPrefix, `${testID}.${isInsertTestID}.form`)}
      >
        <InputField
          testID={appendTestId(
            testIDPrefix,
            `${testID}.${isInsertTestID}.form.input.fullname`
          )}
          label={content.fullNameField}
          name="fullname"
          placeholder={content.fullNamePlaceholder}
          style={Style.marginBottom}
          validate={value => {
            if (required(value) === false) {
              return content.fullNameRequiredErrorMessage;
            } else if (maxLength(value, 256) === false) {
              return formatMessage(content.fullNameMaxLengthErrorMessage, {
                length: 256,
              });
            } else if (isName(value) === false) {
              return content.fullNameAlphabetErrorMessage;
            }

            return;
          }}
        />

        <InputField
          testID={appendTestId(
            testIDPrefix,
            `${testID}.${isInsertTestID}.form.input.email`
          )}
          label={content.emailField}
          name="email"
          placeholder={content.emailPlaceholder}
          style={Style.marginBottom}
          disabled={!isInsert}
          validate={value => {
            if (required(value) === false) {
              return content.emailRequiredErrorMessage;
            } else if (maxLength(value, 256) === false) {
              return formatMessage(content.emailMaxLengthErrorMessage, {
                length: 256,
              });
            } else if (isEmail(value) === false) {
              return content.emailFormatErrorMessage;
            }

            return;
          }}
        />

        <CheckboxField
          style={Style.isApproverCheckbox}
          name="isApprover"
          label={content.approverCheckboxLabel}
        />

        {hasDivisionField && (
          <InputDropdownWithNewEntry
            testIDPrefix={appendTestId(
              testIDPrefix,
              `${testID}.${isInsertTestID}`
            )}
            visible={visible}
            label={content.divisionDropdownField}
            name="divisionId"
            items={divisions}
            style={[Style.marginBottom, { zIndex: 4 }]}
            additionalPayload={defaultAdditionalPayload}
            entityType="division"
            onSuccess={entity => {
              injectNewDivision(entity);
              onAddDivisionSuccess?.();
            }}
          >
            <InputField
              testID={appendTestId(
                testIDPrefix,
                `${testID}.${isInsertTestID}.new-division.form.input.name`
              )}
              label={content.divisionNameField}
              name="division"
              placeholder={content.divisionNamePlaceholder}
              style={Style.marginBottom}
              validate={value => {
                if (required(value) === false) {
                  return content.divisionNameRequiredErrorMessage;
                } else if (maxLength(value, 256) === false) {
                  return formatMessage(
                    content.divisionNameMaxLengthErrorMessage,
                    { length: 256 }
                  );
                } else if (isName(value) === false) {
                  return content.divisionNameAlphabetErrorMessage;
                }

                return;
              }}
            />
            <InputDropdownField
              testID={appendTestId(
                testIDPrefix,
                `${testID}.${isInsertTestID}.new-division.form.dropdown.division-head`
              )}
              label={content.divisionHeadField}
              name="divisionHeadId"
              items={employees}
              iconRight={<Icon src={Chevron} />}
              style={[Style.marginBottom, { zIndex: 1 }]}
              validate={value => {
                if (required(value) === false) {
                  return content.divisionHeadRequiredErrorMessage;
                }

                return;
              }}
              editable
              searchable
            />
          </InputDropdownWithNewEntry>
        )}

        <InputDropdownWithNewEntry
          testIDPrefix={appendTestId(
            testIDPrefix,
            `${testID}.${isInsertTestID}`
          )}
          visible={visible}
          label={content.tierDropdownField}
          name="tierId"
          items={tiers}
          style={[Style.marginBottom, { zIndex: 3 }]}
          additionalPayload={defaultAdditionalPayload}
          entityType="tier"
          onSuccess={entity => {
            injectNewTier(entity);
            onAddTierSuccess?.();
          }}
        >
          <InputField
            testID={appendTestId(
              testIDPrefix,
              `${testID}.${isInsertTestID}.new-tier.form.input.name`
            )}
            label={content.tierNameField}
            name="tier"
            placeholder={content.tierNamePlaceholder}
            style={Style.marginBottom}
            validate={value => {
              if (required(value) === false) {
                return content.tierNameRequiredErrorMessage;
              } else if (maxLength(value, 256) === false) {
                return formatMessage(content.tierNameMaxLengthErrorMessage, {
                  length: 256,
                });
              } else if (isAlphaNumericSpace(value) === false) {
                return content.tierNameAlphanumericErrorMessage;
              }

              return;
            }}
          />
        </InputDropdownWithNewEntry>

        <InputDropdownWithNewEntry
          testIDPrefix={appendTestId(
            testIDPrefix,
            `${testID}.${isInsertTestID}`
          )}
          visible={visible}
          label={content.roleDropdownField}
          name="roleId"
          items={roles}
          style={[Style.marginBottom, { zIndex: 2 }]}
          validate={value => {
            if (required(value) === false) {
              return content.roleDropdownRequiredErrorMessage;
            }

            return;
          }}
          additionalPayload={roleAdditionalPayload}
          entityType="role"
          onSuccess={entity => {
            injectNewRole(entity);
            onAddRoleSuccess?.();
          }}
        >
          <InputField
            testID={appendTestId(
              testIDPrefix,
              `${testID}.${isInsertTestID}.new-role.form.input.name`
            )}
            label={content.roleNameField}
            name="role"
            placeholder={content.roleNamePlaceholder}
            style={Style.marginBottom}
            validate={value => {
              if (required(value) === false) {
                return content.roleNameRequiredErrorMessage;
              } else if (minLength(value, 2) === false) {
                return formatMessage(content.roleNameMinLengthErrorMessage, {
                  length: 256,
                });
              } else if (maxLength(value, 256) === false) {
                return formatMessage(content.roleNameMaxLengthErrorMessage, {
                  length: 256,
                });
              } else if (isName(value) === false) {
                return content.roleNameAlphabetErrorMessage;
              }

              return;
            }}
          />
        </InputDropdownWithNewEntry>

        <InputDropdownField
          testID={appendTestId(
            testIDPrefix,
            `${testID}.${isInsertTestID}.form.dropdown.manager`
          )}
          label={content.managerDropdownField}
          name="managerId"
          items={employees}
          iconRight={<Icon src={Chevron} />}
          style={[Style.marginBottom, { zIndex: 1 }]}
          editable
          searchable
        />

        {!!errors.root?.submitError?.message && (
          <View style={Style.marginBottom}>
            <InfoBox
              testID={appendTestId(
                testIDPrefix,
                `${testID}.${isInsertTestID}.form.alert`
              )}
              variant="ALERT"
              message={errors.root.submitError.message}
            />
          </View>
        )}

        <View style={Style.wrapper}>
          <Button
            testID={appendTestId(
              testIDPrefix,
              `${testID}.${isInsertTestID}.form.cancel`
            )}
            variant="secondary"
            text={content.closeButtonText}
            onPress={onCloseModal}
          />
          <Button
            testID={appendTestId(
              testIDPrefix,
              `${testID}.${isInsertTestID}.form.submit`
            )}
            style={Style.submit}
            text={
              isInsert
                ? content.insertSubmitButtonText
                : content.updateSubmitButtonText
            }
            onPress={formSubmit(handleSubmit)}
            loading={isSubmitting}
          />
        </View>
      </View>
    </FormProvider>
  );
}

type InputDropdownWithNewEntryProps = {
  visible: boolean;
  label: string;
  name: 'divisionId' | 'tierId' | 'roleId';
  items: ItemWithNewEntry[];
  validate?(value?: string): string | undefined;
  additionalPayload: object;
  entityType: string;
  onSuccess?(entity: any): void;
  style?: StyleProp<ViewStyle>;
  testIDPrefix?: string;
  children: ReactNode;
};

function InputDropdownWithNewEntry(props: InputDropdownWithNewEntryProps) {
  const {
    visible,
    label,
    name,
    items,
    style,
    validate,
    additionalPayload,
    children,
    entityType,
    onSuccess,
    testIDPrefix,
  } = props;

  const { color } = useTheme();
  const formStyle = {
    backgroundColor: color.lightStain,
  };

  const [showNewForm, setShowNewForm] = useState(false);

  const methods = useForm();
  const {
    handleSubmit: submitForm,
    formState: { isSubmitting, errors },
    setError,
  } = methods;

  const create = useApi<CreateEntryResponse, CreateEntryRequest<unknown>>({
    domain: 'management',
    method: 'post',
    path: CREATE_ENTRY,
  });

  const handleItemPress = useCallback(
    (item: ItemWithNewEntry) => {
      setShowNewForm(item.newEntry === true);
    },
    [setShowNewForm]
  );

  const handleSubmit = useCallback(
    async (formValues: any) => {
      try {
        const res = await create({
          entityType,
          entry: { ...additionalPayload, ...formValues },
        });

        if (res.success) {
          if (res.data.result === ResultStatus.SUCCESS) {
            setShowNewForm(false);
            onSuccess?.({
              ...formValues,
              id: res.data.id,
            });

            return;
          }

          throw new Error(res.data.message);
        }

        throw res.error;
      } catch (e) {
        return setError('root.submitError', { message: (e as any).message });
      }
    },
    [additionalPayload, create, entityType, onSuccess, setError]
  );

  useEffect(() => {
    setShowNewForm(false);
  }, [visible]);

  return (
    <View style={style}>
      <InputDropdownField
        testID={appendTestId(testIDPrefix, `form.dropdown.${entityType}`)}
        name={name}
        label={label}
        items={items}
        validate={validate}
        iconRight={<Icon src={Chevron} />}
        style={Style.newEntryDropdown}
        onPressItem={handleItemPress}
        editable
        searchable
      />
      {showNewForm && (
        <View style={[Style.newEntryForm, formStyle]}>
          <FormProvider {...methods}>
            <View testID={appendTestId(testIDPrefix, `new-${entityType}.form`)}>
              {children}
              {!!errors.root?.submitError?.message && (
                <View style={Style.marginBottom}>
                  <InfoBox
                    testID={appendTestId(
                      testIDPrefix,
                      `new-${entityType}.form.alert`
                    )}
                    variant="ALERT"
                    message={errors.root.submitError.message}
                  />
                </View>
              )}
              <View style={Style.wrapper}>
                <Button
                  testID={appendTestId(
                    testIDPrefix,
                    `new-${entityType}.form.submit`
                  )}
                  size="small"
                  text="Add"
                  loading={isSubmitting}
                  onPress={submitForm(handleSubmit)}
                />
              </View>
            </View>
          </FormProvider>
        </View>
      )}
    </View>
  );
}

const Style = StyleSheet.create({
  scroll: {
    flexShrink: 1,
    flexGrow: 0,
    borderRadius: Token.border.radius.normal,
    overflowY: 'auto',
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  isApproverCheckbox: {
    marginTop: Token.spacing.s,
    marginBottom: Token.spacing.ml,
  },
  marginBottom: {
    marginBottom: Token.spacing.s,
  },
  newEntryDropdown: {
    zIndex: 1,
  },
  newEntryForm: {
    marginTop: Token.spacing.s,
    marginHorizontal: -Token.spacing.m,
    padding: Token.spacing.m,
  },
  submit: {
    marginLeft: Token.spacing.s,
  },
});

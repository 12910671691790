import { JavaCurrencyValue } from 'shared/utils/currency';

export const REMAINING_CREDIT_LIMIT_API = '/api/v1/creditlimit/get';

export type CreditLimitResponse = {
  remainingCreditLimit: {
    amount: Nullable<JavaCurrencyValue>;
    status: CreditLimitStatus;
  };
  totalCreditLimit: Nullable<{
    amount: JavaCurrencyValue;
  }>;
  remainingPercentage: Nullable<string>;
};

export type CreditLimitStatus =
  | 'SUFFICIENT'
  | 'LOW'
  | 'VERY_LOW'
  | 'EMPTY'
  | 'MINUS';

import { useEffect, useRef } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { config, useAuth } from '@traveloka/ctv-core';

export default function DatadogInitializer() {
  const { user } = useAuth();
  const userRef = useRef(user);

  useEffect(() => {
    if (!user) return;
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    // Only initialize Datadog RUM for enabled environments.
    if (!config.datadog.env || config.datadog.env !== 'prod') {
      return;
    }

    // Prevent multiple initialization, expected to be initialized once.
    if (datadogRum.getInitConfiguration()) {
      return;
    }

    try {
      datadogRum.init({
        ...config.datadog,
        beforeSend: (event, _context) => {
          // Filter out user data from action event.
          if (event.type === 'action') {
            // Filter data if needed.
            if (event.action.target?.name && userRef.current) {
              const targetNameArr = event.action.target.name.toLowerCase();
              const filteredWords = [
                userRef.current.email,
                userRef.current.corporateCode,
                userRef.current.corporateName,
                userRef.current.corporateAlias,
                userRef.current.name,
              ]
                .filter(Boolean)
                .map(word => word.toLowerCase());

              if (
                filteredWords?.some(filteredWord =>
                  targetNameArr.includes(filteredWord)
                )
              ) {
                event.action.target.name = '[REDACTED]';
              }
            }
          }

          return true;
        },
      });
    } catch (error) {
      // No op.
    }
  }, []);

  return null;
}

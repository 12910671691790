import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Token } from '@traveloka/web-components';

import Skeleton from '../../shared/components/Skeleton/Skeleton';

type Props = {
  style?: StyleProp<ViewStyle>;
};

export default function ProfileFieldGroupLoading(props: Props) {
  const { style } = props;

  return (
    <View style={[Style.container, style]}>
      <View style={Style.icon}>
        <Skeleton width={24} height={24} />
      </View>
      <View style={Style.content}>
        <Skeleton width={150} height={28} style={Style.title} />
        <View style={Style.row}>
          <Skeleton width={250} height={20} style={Style.col} />
          <Skeleton width={250} height={20} style={Style.col} />
        </View>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: Token.spacing.ml,
    paddingHorizontal: Token.spacing.m,
  },
  icon: {
    height: 28,
    justifyContent: 'center',
  },
  content: {
    marginLeft: Token.spacing.xs,
    flex: 1,
  },
  title: {
    marginBottom: Token.spacing.s,
  },
  row: {
    flexDirection: 'row',
    marginHorizontal: -Token.spacing.xs,
  },
  col: {
    flex: 1,
    marginHorizontal: Token.spacing.xs,
  },
});

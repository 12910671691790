import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { Format } from '@traveloka/ctv-core';
import Chevron from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_24px.svg';
import Info from '@traveloka/icon-kit-web/svg/blue/ic_system_status_info-fill_16px.svg';
import InfoGray from '@traveloka/icon-kit-web/svg/lightSecondary/ic_system_status_info-fill_16px.svg';
import Landing from '@traveloka/icon-kit-web/svg/dark/ic_flight_landing_24px.svg';
import TakeOff from '@traveloka/icon-kit-web/svg/dark/ic_flight_take_off_24px.svg';
import Return from '@traveloka/icon-kit-web/svg/dark/ic_system_calendar_in_24px.svg';
import Departure from '@traveloka/icon-kit-web/svg/dark/ic_system_calendar_out_24px.svg';
import Guest from '@traveloka/icon-kit-web/svg/dark/ic_system_guest_passenger_24px.svg';
import SeatClass from '@traveloka/icon-kit-web/svg/dark/ic_transport_seat_class_24px.svg';
import Search from '@traveloka/icon-kit-web/svg/light/ic_system_search_24px.svg';
import SearchDisabled from '@traveloka/icon-kit-web/svg/lightSecondary/ic_system_search_24px.svg';
import RoundTrip from '@traveloka/icon-kit-web/svg/light/ic_trip_two_way_roundtrip_16px.svg';
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Text,
  Token,
  Tooltip,
} from '@traveloka/web-components';
import Label from '@traveloka/web-components/src/momentum/Input/Label';

import Fade from '../../shared/components/Fade/Fade';
import InputDatepicker from '../../shared/components/form/InputDatepicker/InputDatepicker';
import InputDropdown from '../../shared/components/form/InputDropdown/InputDropdown';
import { Item } from '../../shared/components/form/InputDropdown/types';
import { appendTestId } from '../../shared/utils/TestUtil';

type Props = {
  // Spec
  passenger: string;
  origin: string;
  destination: string;
  isRoundTrip: boolean;
  departDate: Date;
  returnDate: Date;
  seatClass: string;
  // Handler
  onSearchPress: () => void;
  onSwapPress: () => void;
  onRoundTripToggle: () => void;
  onPassengerFocus?: () => void;
  onOriginChange?: (item: Item) => void;
  onDestinationChange?: (item: Item) => void;
  onDepartChange?: (date: Date) => void;
  onReturnChange?: (date: Date) => void;
  onSeatClassChange?: (item: Item) => void;
  // Options
  airportOptions: Item[];
  seatClassOptions: Item[];
  // Misc
  dateFormat: Format;
  maxDate?: Date;
  destinationError: boolean;
  passengerError: boolean;
  isSearching?: boolean;
  searchButtonEnabled?: boolean;
  content: {
    passenger: string;
    origin: string;
    destination: string;
    departureDate: string;
    returnDate: string;
    seatClass: string;
    search: string;
    destinationTooltip: string;
    passengerTooltip: string;
    passengerInfoTooltip: string;
  };
  testID?: string;
  airportHighlighted: boolean;
  formDisabled?: boolean;
};

export default function FlightSearchForm(props: Props) {
  const {
    content,
    passenger,
    origin,
    destination,
    isRoundTrip,
    departDate,
    returnDate,
    seatClass,
    onSearchPress,
    onSwapPress,
    onRoundTripToggle,
    onPassengerFocus,
    onOriginChange,
    onDestinationChange,
    onDepartChange,
    onReturnChange,
    onSeatClassChange,
    airportOptions,
    seatClassOptions,
    dateFormat,
    maxDate,
    destinationError,
    passengerError,
    isSearching,
    searchButtonEnabled,
    testID,
    airportHighlighted,
    formDisabled,
  } = props;

  return (
    <View style={Style.container}>
      <View style={[Style.row, Style.row1]}>
        <View style={[Style.input, Style.longerInput]}>
          <Tooltip
            content={
              <Text
                testID={appendTestId(testID, 'passenger.tooltip.no-passenger')}
                variant="ui-small"
                ink="white"
              >
                {content.passengerTooltip}
              </Text>
            }
            show={passengerError}
            offset={{ y: -20 }}
          >
            <View style={Style.wrapper}>
              <Label text={content.passenger} />
              {content.passengerInfoTooltip.length > 0 &&
                (!formDisabled ? (
                  <Tooltip
                    content={
                      <Text
                        variant="ui-small"
                        ink="white"
                        style={{ textAlign: 'center' }}
                      >
                        {content.passengerInfoTooltip}
                      </Text>
                    }
                    variant="normal"
                    position="top"
                    width={255}
                  >
                    <Icon src={Info} style={{ marginTop: Token.spacing.xs }} />
                  </Tooltip>
                ) : (
                  <Icon
                    src={InfoGray}
                    style={{ marginTop: Token.spacing.xs }}
                  />
                ))}
            </View>
            <label>
              <Input
                testID={appendTestId(testID, 'passenger.input')}
                value={passenger}
                editable={false}
                onFocus={onPassengerFocus}
                iconLeft={<Icon src={Guest} />}
                error={passengerError}
                disabled={formDisabled}
              />
            </label>
          </Tooltip>
        </View>
        <View style={[Style.input, Style.from]}>
          <Label text={content.origin} />
          <InputDropdown
            testID={appendTestId(testID, 'origin')}
            iconLeft={<Icon src={TakeOff} />}
            value={origin}
            items={airportOptions}
            onPressItem={onOriginChange}
            editable
            searchable
            containerStyle={airportHighlighted && Style.highlightInput}
            disabled={formDisabled}
          />
          <TouchableOpacity
            testID={appendTestId(testID, 'swap-location')}
            style={Style.floating}
            disabled={formDisabled}
            onPress={onSwapPress}
          >
            <View style={Style.switch}>
              <Icon src={RoundTrip} />
            </View>
          </TouchableOpacity>
        </View>
        <View style={Style.input}>
          <Tooltip
            content={
              <Text
                testID={appendTestId(
                  testID,
                  'desination.tooltip.same-destination'
                )}
                variant="ui-small"
                ink="white"
              >
                {content.destinationTooltip}
              </Text>
            }
            show={destinationError}
            offset={{ y: -20 }}
          >
            <Label text={content.destination} />
            <InputDropdown
              testID={appendTestId(testID, 'destination')}
              iconLeft={<Icon src={Landing} />}
              value={destination}
              items={airportOptions}
              onPressItem={onDestinationChange}
              error={destinationError ? content.destinationTooltip : undefined}
              showError={false}
              editable
              searchable
              containerStyle={airportHighlighted && Style.highlightInput}
              disabled={formDisabled}
            />
          </Tooltip>
        </View>
      </View>
      <View style={[Style.row, Style.row2]}>
        <View style={Style.input}>
          <Label text={content.departureDate} />
          <InputDatepicker
            testID={appendTestId(testID, 'departure-date')}
            value={departDate}
            dateFormat={dateFormat}
            minDate={new Date()}
            maxDate={maxDate}
            iconLeft={<Icon src={Departure} />}
            onDatePress={onDepartChange}
            disabled={formDisabled}
          />
        </View>
        <View style={Style.input}>
          <View>
            <Checkbox
              testID={appendTestId(testID, 'check-box.round-trip')}
              onChange={onRoundTripToggle}
              checked={isRoundTrip}
              disabled={formDisabled}
            >
              <View style={Style.returnDateCheckbox}>
                <Checkbox.Control />
                <Text style={Style.returnDateLabel}>{content.returnDate}</Text>
              </View>
            </Checkbox>
          </View>
          <Fade visible={isRoundTrip}>
            <InputDatepicker
              testID={appendTestId(testID, 'return-date')}
              value={returnDate}
              dateFormat={dateFormat}
              minDate={departDate}
              maxDate={maxDate}
              iconLeft={<Icon src={Return} />}
              onDatePress={onReturnChange}
              disabled={formDisabled}
            />
          </Fade>
        </View>
        <View style={[Style.input, Style.longerInput]}>
          <Label text={content.seatClass} />
          <InputDropdown
            testID={appendTestId(testID, 'seat-class')}
            value={seatClass}
            iconLeft={<Icon src={SeatClass} />}
            iconRight={<Icon src={Chevron} />}
            items={seatClassOptions}
            onPressItem={onSeatClassChange}
            disabled={formDisabled}
          />
        </View>
      </View>
      <View style={[Style.buttonContainer, Style.row3]}>
        <Button
          testID={appendTestId(testID, 'submit')}
          text={content.search}
          iconStart={() => {
            if (isSearching) {
              return null;
            }

            const iconSrc = searchButtonEnabled ? Search : SearchDisabled;

            return <Icon src={iconSrc} />;
          }}
          onPress={onSearchPress}
          loading={isSearching}
          disabled={formDisabled || !searchButtonEnabled}
        />
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.l,
  },
  row: {
    flexDirection: 'row',
    marginHorizontal: -Token.spacing.xs,
    marginBottom: Token.spacing.xl,
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    flex: 1,
    paddingHorizontal: Token.spacing.xs,
  },
  longerInput: {
    flex: 1.5,
  },
  from: {
    zIndex: 1,
  },
  floating: {
    position: 'absolute',
    right: -12,
    bottom: 8,
    zIndex: 2,
  },
  switch: {
    padding: Token.spacing.xxs,
    borderRadius: Token.border.radius.rounded,
    backgroundColor: Token.color.uiBluePrimary,
    ...Token.elevation.hover,
  },
  buttonContainer: {
    alignItems: 'flex-end',
  },
  returnDateCheckbox: {
    paddingVertical: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  returnDateLabel: {
    ...Token.typography.uiSmall,
    flex: 1,
    marginLeft: Token.spacing.s,
    fontWeight: '700',
  },
  row1: {
    zIndex: 3,
  },
  row2: {
    zIndex: 2,
  },
  row3: {
    zIndex: 1,
  },
  highlightInput: {
    borderColor: Token.color.uiBluePrimary,
    borderWidth: Token.border.width.thick,
    borderRadius: 7,
  },
});

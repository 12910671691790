import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useAuth, useContentResource, useLocale } from '@traveloka/ctv-core';
import { MenuItem } from '@traveloka/ctvweb-ui';
import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import { Token } from '@traveloka/web-components';

import { useProfileContext } from 'generic/contexts/ProfileContext';
import { key } from 'shared/data-tracking/localStorage';
import { set } from 'shared/utils/expirable-storage';

type Props = {
  currentPath?: string;
  headerStyle?: StyleProp<ViewStyle>;
  testID?: string;
};

type Menu = {
  text: string;
  url: string;
  name: string;
  onPress?(): void;
};

export default function LeftSidebar(props: Props) {
  const { currentPath = '', testID } = props;

  const profile = useProfileContext();
  const { user } = useAuth();
  const { locale } = useLocale();
  const content = useContentResource().CorporateApprovalMenu;

  if (!user?.globalTripRequestApproval) {
    return null;
  }

  const menus: Menu[] = [
    {
      text: content.myRequestText,
      url: '/approval',
      name: 'my-request',
    },
    {
      text: content.createTripRequestText,
      url: '/approval/create',
      name: 'create-trip-request',
      onPress() {
        set(
          key.CREATE_APPROVAL,
          {
            pageCategory: 'APPROVAL_HEADER',
            webReferrer: window.location.href,
            eventType: 'CLICK',
            clickSource: 'CORPORATE_HEADER',
          },
          5 * 60 * 1000 //5 Mins expired
        );
      },
    },
  ];

  if (profile.data?.employmentDetail.isApprover) {
    menus.splice(1, 0, {
      text: content.needMyApprovalText,
      url: '/approval#my-approval',
      name: 'need-my-approval',
    });
  }

  // Process to deprecate the repository
  // Decoupling from local react redirection state
  function redirectTo(url: string) {
    window.location.href = '/' + locale + url;
  }

  return (
    <View style={Style.dropdownApprovalMenu}>
      {menus.map(menu => (
        <MenuItem
          {...menu}
          testID={appendTestId(testID, `menu.approval.${menu.name}`)}
          key={menu.url}
          style={Style.menuItem}
          isActive={currentPath.startsWith(menu.url)}
          onPress={() => redirectTo(menu.url)}
        />
      ))}
    </View>
  );
}

const Style = StyleSheet.create({
  dropdownApprovalMenu: {
    minWidth: 180,
  },
  menuItem: {
    paddingVertical: Token.spacing.s,
    marginHorizontal: 0,
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useController, useFormContext } from 'react-hook-form';

import { Input, InputGroup } from '@traveloka/ctvweb-ui';
import InputDropdown, {
  Props as InputDropdownProps,
} from '@traveloka/ctvweb-ui/src/shared/components/form/InputDropdown/InputDropdown';
import { Item } from '@traveloka/ctvweb-ui/src/shared/components/form/InputDropdown/types';
import { Token } from '@traveloka/web-components';

import countryInfo from 'shared/static-data/country-info';

import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import { FieldProps } from '../types';

export type Value = {
  prefix: string;
  phone: string;
};

export type Props = FieldProps<Value> &
  Omit<
    InputDropdownProps<any>,
    'items' | 'onPressItem' | 'defaultValue' | 'onBlur'
  > & {
    testIDPrefix?: string;
    onBlur?(value: Value, onChange: (event: any) => void): void;
  };

export default function PhoneField(props: Props) {
  const {
    defaultValue,
    name,
    validate,
    onFocus,
    label,
    leftHelper,
    rightHelper,
    disabled,
    style,
    testIDPrefix,
    ...inputProps
  } = props;

  const { control } = useFormContext();
  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });

  function handleFocus() {
    if (typeof onFocus === 'function') {
      onFocus();
    }
  }

  function handleBlurText() {
    onChange({ ...value, phone: value.phone.replace(/^0+/, '') });
    onBlur();
    props.onBlur?.(value, onChange);
  }

  function handlePressItem(item: Item) {
    onChange({
      ...value,
      prefix: item.value,
    });
  }

  function handleChangeText(text: string) {
    onChange({
      ...value,
      phone: text,
    });
  }

  return (
    <InputGroup
      label={label}
      error={error?.message}
      leftHelper={leftHelper}
      rightHelper={rightHelper}
      disabled={disabled}
      style={style}
    >
      <View style={Style.input}>
        <InputDropdown
          {...inputProps}
          testID={appendTestId(testIDPrefix, 'phone.prefix')}
          disabled={disabled}
          showError={false}
          onFocus={handleFocus}
          onPressItem={handlePressItem}
          items={phoneList}
          style={Style.prefix}
          width={200}
          editable
          searchable
          onBlur={onBlur}
          value={value?.prefix}
          error={error?.message}
        />
        <Input
          {...inputProps}
          testID={appendTestId(testIDPrefix, 'phone')}
          disabled={disabled}
          showError={false}
          onBlur={handleBlurText}
          onFocus={handleFocus}
          style={Style.phone}
          onChangeText={handleChangeText}
          value={value?.phone}
          error={error?.message}
        />
      </View>
    </InputGroup>
  );
}

const Style = StyleSheet.create({
  input: {
    flexDirection: 'row',
    zIndex: 1,
  },
  prefix: {
    width: 80,
  },
  phone: {
    flex: 1,
    marginLeft: Token.spacing.xs,
  },
});

const phoneList = countryInfo.map(info => ({
  label: info.countryName,
  value: `+${info.telPref}`,
}));

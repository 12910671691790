import { parse } from 'qs';

// Logic from: https://github.com/ljharb/qs/issues/91#issuecomment-864680091
// `qs` package doesn't provide this decoder built inside the package.
function decoder(str: string, _decoder: typeof decoder, charset: string) {
  const strWithoutPlus = str.replace(/\+/g, ' ');
  if (charset === 'iso-8859-1') {
    // unescape never throws, no try...catch needed:
    return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
  }

  if (/^[+-]?\d+(\.\d+)?$/.test(str)) {
    return parseFloat(str);
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined,
  };
  if (str in keywords) {
    return keywords[str as keyof typeof keywords];
  }

  // utf-8
  try {
    return decodeURIComponent(strWithoutPlus);
  } catch (e) {
    return strWithoutPlus;
  }
}

export function getQueryString<T = Record<string, any>>(
  typeCoerce?: boolean
): T {
  return parse(window.location.search, {
    ignoreQueryPrefix: true,
    // @ts-expect-error This is somehow raise a typing error
    decoder: typeCoerce ? decoder : v => v,
  });
}

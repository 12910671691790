import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react';

import { useApi } from '@traveloka/ctv-core';

import {
  CreditCardListResponse,
  CREDIT_CARD_LIST_API,
} from 'company/shared/api';
import { CreditCard } from 'company/shared/api/types';

type State = {
  isLoading: boolean;
  error?: string;
  data: CreditCard[] | undefined;
};

const ManageCardStateContext = createContext<State>(undefined!);
const ManageCardFetchContext = createContext<Function>(undefined!);

const initialState = {
  isLoading: true,
  data: undefined,
};

export function ManageCardProvider(props: PropsWithChildren<{}>) {
  const [state, setState] = useState<State>(initialState);

  const getCardList = useApi<CreditCardListResponse>({
    domain: 'management',
    method: 'post',
    path: CREDIT_CARD_LIST_API,
  });

  function fetch() {
    setState(initialState);

    getCardList({})
      .then(res => {
        if (res.success) {
          // Always put main card in front
          return res.data.cards.sort(
            (a, b) => Number(b.mainCard) - Number(a.mainCard)
          );
        }

        throw res.error;
      })
      .catch(() => [])
      .then(data => setState({ isLoading: false, data }));
  }

  useEffect(fetch, []);

  return (
    <ManageCardStateContext.Provider value={state}>
      <ManageCardFetchContext.Provider value={fetch}>
        {props.children}
      </ManageCardFetchContext.Provider>
    </ManageCardStateContext.Provider>
  );
}

export function useManageCardState() {
  return useContext(ManageCardStateContext);
}

export function useManageCardFetch() {
  return useContext(ManageCardFetchContext);
}

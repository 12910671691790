export type ProductType = 'FLIGHT' | 'HOTEL';

// ===== Confirm Payment
export const CONFIRM_PAYMENT = '/api/v1/payment-confirmation';
export type ConfirmPaymentRequest = {
  tripId: string;
  paymentMethod: 'INVOICE';
  productType: ProductType;
};

export type ConfirmPaymentResponse = {
  paymentConfirmationStatus:
    | 'OK'
    | 'PAYMENT_EXPIRED'
    | 'BOOKING_NOT_FOUND'
    | 'ALREADY_CONFIRMED'
    | 'FAILED'
    | 'CREDIT_LIMIT_NOT_SUFFICIENT';
};

import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import {
  Button,
  InfoBox,
  InputField,
  Popup,
  Text,
  Token,
} from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';

type Props = {
  isVisible: boolean;
  confirmPayment(): Promise<string | undefined> | void;
  onClosePress(): void;
};

export default function ConfirmPaymentModal(props: Props) {
  const { isVisible, confirmPayment, onClosePress } = props;
  const [inputText, setInputText] = useState('');
  const [inputError, setInputError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const content = useContentResource().CorporateProductPaymentConfirmationModal;
  const {
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();

  const handleConfirmPayment = useCallback(async () => {
    if (inputText !== content.confirmationFieldPlaceholder) {
      setInputError(true);
      return;
    }

    setIsLoading(true);

    const message = await confirmPayment();

    if (typeof message === 'string') {
      setErrorMessage(message);
    }

    setIsLoading(false);
  }, [inputText, confirmPayment]);

  const handleFocus = useCallback(() => {
    if (inputError) {
      setInputError(false);
    }
  }, [inputError]);

  const handleChangeText = useCallback(
    (text: string) => {
      if (inputError) {
        setInputError(false);
      }

      setInputText(text.toUpperCase());
    },
    [inputError]
  );

  return (
    <Modal isVisible={isVisible}>
      <Popup
        showCloseButton
        width={610}
        maxWidth={610}
        onCloseButtonPress={onClosePress}
      >
        <View style={Style.container}>
          <Text variant="ui-large" style={Style.text}>
            {content.headline}
          </Text>
          <Text ink="secondary" style={Style.text}>
            {content.infoText}
          </Text>
          <InputField
            testID="confirmation-payment.confirm-modal.form.confirm"
            variant="formal"
            label={content.confirmationField}
            placeholder={content.confirmationFieldPlaceholder}
            value={inputText}
            onFocus={handleFocus}
            onChangeText={handleChangeText}
            error={inputError}
            editable={!isLoading}
          />
          {!!errorMessage && (
            <InfoBox
              variant="ALERT"
              message={errorMessage}
              style={Style.error}
            />
          )}
          <View style={Style.control}>
            <Button
              testID="confirmation-payment.confirm-modal.cancel"
              variant="secondary"
              text={content.closeButtonText}
              onPress={onClosePress}
            />
            <Button
              testID="confirmation-payment.confirm-modal.submit"
              variant="main-cta"
              text={content.submitButtonText}
              onPress={() => fetchAccountStatus(handleConfirmPayment)}
              loading={isLoading || isAccountStatusFetching}
              style={Style.payButton}
            />
          </View>
        </View>
      </Popup>
    </Modal>
  );
}

const Style = StyleSheet.create({
  container: {
    paddingHorizontal: Token.spacing.xxxxl,
    justifyContent: 'center',
  },
  text: {
    marginBottom: Token.spacing.s,
    textAlign: 'center',
  },
  error: {
    marginTop: Token.spacing.m,
  },
  control: {
    marginTop: Token.spacing.m,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  payButton: {
    marginLeft: Token.spacing.s,
  },
});

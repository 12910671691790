import React from 'react';
import { View, ViewProps } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { useTheme, Checkbox, Text } from '@traveloka/web-components';

import {
  useFlightSearchOptionContext,
  useFlightSearchTransitPointFilterContext,
} from 'flight/search/contexts/FlightSortFilterContext';

import { getThemeStyle, CommonStyle } from '../filter-search-toolbar.style';

type Props = {
  style?: ViewProps['style'];
};

function TransitLocationFilter(props: Props) {
  const { style } = props;
  const { CorporateFlightSearchToolbar } = useContentResource();
  const content = {
    title: CorporateFlightSearchToolbar.transitLocation,
    selectAll: CorporateFlightSearchToolbar.selectAll,
  };

  const ThemeStyle = getThemeStyle(useTheme());
  const { filterOption, setFilter } = useFlightSearchOptionContext();
  const values = useFlightSearchTransitPointFilterContext();

  function selectAll() {
    if (values.length === filterOption.transitPoint.length) {
      setFilter('transitPoint', []);
      return;
    }

    setFilter(
      'transitPoint',
      filterOption.transitPoint.map(transitPoint => transitPoint.airportCode)
    );
  }

  return (
    <View style={style}>
      <Text ink="primary" variant="ui-small">
        {content.title}
      </Text>
      <View style={[CommonStyle.container, ThemeStyle.container]}>
        <View style={CommonStyle.checkbox}>
          <Checkbox
            checked={values.length === filterOption.transitPoint.length}
            onChange={selectAll}
          >
            <Checkbox.Control />
            <Text variant="ui-small" style={CommonStyle.checkboxText}>
              {content.selectAll}
            </Text>
          </Checkbox>
        </View>
        {filterOption.transitPoint.map((option, index) => (
          <View
            key={option.airportCode}
            testID={`flight.search.toolbar.transit.location.${index}`}
            style={CommonStyle.checkbox}
          >
            <Checkbox
              checked={values.includes(option.airportCode)}
              onChange={() => setFilter('transitPoint', option.airportCode)}
            >
              <Checkbox.Control />
              <View style={CommonStyle.checkboxTextJustify}>
                <Text ink="primary" variant="ui-small">
                  {option.city}
                </Text>
                <Text
                  testID={`flight.search.toolbar.transit.location.${index}.airport-code`}
                  ink="primary"
                  variant="ui-small"
                >
                  {option.airportCode}
                </Text>
              </View>
            </Checkbox>
          </View>
        ))}
      </View>
    </View>
  );
}

export default TransitLocationFilter;

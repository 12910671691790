import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Card, Collapsible, Text, Token } from '@traveloka/web-components';

import { usePrice } from 'flight/prebook/contexts/PriceContext';
import { formatCurrency } from 'shared/utils/intl';

import { Style as SharedStyle } from '../../flight-prebook.style';

export default function PriceBreakdown() {
  const { items, total } = usePrice();
  const content = useContentResource().CorporateFlightPrebookPriceBreakdown;

  return (
    <View style={SharedStyle.section}>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {content.title}
      </Text>
      <Card>
        <Collapsible
          title={
            <View style={[Style.spread, Style.header]}>
              <Text>{content.totalPriceLabel}</Text>
              <Text testID="pre-book.price.main-price" variant="ui-large">
                {formatCurrency(total)}
              </Text>
            </View>
          }
        >
          <>
            {items.map((item, index) => (
              <View key={index} style={[Style.spread, Style.item]}>
                <Text>{item.label}</Text>
                <Text>{formatCurrency(item.value)}</Text>
              </View>
            ))}
          </>
        </Collapsible>
      </Card>
    </View>
  );
}

const Style = StyleSheet.create({
  spread: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    alignItems: 'center',
  },
  item: {
    marginBottom: Token.spacing.xs,
  },
});

import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { Token } from '@traveloka/web-components';

import CompanyEntityModalRow from './CompanyEntityModalRow';

type Item = {
  left: string;
  right: ReactNode;
  rightStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
};

type Props = {
  title: string;
  items: Item[];
  lastItemBorder?: boolean;
  firstRowTint?: boolean;
  testIDPrefix?: string;
};

export default function CompanyEntityModalGroup(props: Props) {
  const {
    title,
    items,
    firstRowTint = false,
    lastItemBorder = false,
    testIDPrefix,
  } = props;

  let tint = firstRowTint;

  return (
    <>
      <CompanyEntityModalRow
        left={title}
        leftVariant="title-3"
        testIDPrefix={`${testIDPrefix}.title`}
        tint={tint}
        border
      />
      {items.map((item, index, arr) => {
        tint = !tint;

        return (
          <CompanyEntityModalRow
            {...item}
            style={[styles.row, item.style]}
            testIDPrefix={`${testIDPrefix}.${index}`}
            tint={tint}
            border={lastItemBorder ? true : arr.length - 1 !== index}
          />
        );
      })}
    </>
  );
}

const styles = StyleSheet.create({
  row: {
    paddingLeft: Token.spacing.m,
  },
});

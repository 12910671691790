import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const BookingDetail: PrivatePageComponent = function() {
  return null;
};

BookingDetail.routeProtection = function({ params }) {
  if (
    params.productType !== undefined &&
    params.bookingId !== undefined &&
    params.index !== undefined
  ) {
    return {
      path: `/booking/ongoing/${params.productType}/${params.bookingId}/${params.index}`,
    };
  }

  return { path: '/booking' };
};

export default BookingDetail;

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Text, Token } from '@traveloka/web-components';

type HotelAutocompleteHeaderProps = {
  label: string;
};

export default function HotelAutocompleteHeader(
  props: HotelAutocompleteHeaderProps
) {
  const { label } = props;

  return (
    <View style={ItemStyle.content}>
      <Text variant="title-2" ink="primary">
        {label}
      </Text>
    </View>
  );
}

const ItemStyle = StyleSheet.create({
  content: {
    backgroundColor: Token.color.uiLightNeutral,
    paddingVertical: Token.spacing.s,
    paddingHorizontal: Token.spacing.m,
  },
});

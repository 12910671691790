import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native';

import { useTheme, Text, Token } from '@traveloka/web-components';

type Props = {
  text: string;
  style?: TouchableOpacityProps['style'];
  onPress?: () => void;
  testID?: string;
};

export default function FlightSearchToolbarMenu(props: Props) {
  const { testID, text, style, onPress } = props;
  const { color } = useTheme();

  return (
    <TouchableOpacity
      testID={testID}
      style={[Style.menu, style]}
      onPress={onPress}
    >
      <Text ink="secondary" variant="ui-small">
        {text}
      </Text>
      <View
        style={[
          Style.chevronDown,
          {
            borderTopColor: color.uiBluePrimary,
          },
        ]}
      />
    </TouchableOpacity>
  );
}

const Style = StyleSheet.create({
  chevronDown: {
    borderLeftColor: 'transparent',
    borderLeftWidth: 3,
    borderRightColor: 'transparent',
    borderRightWidth: 3,
    borderTopWidth: 3,
    marginLeft: Token.spacing.xxs,
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { format } from 'date-fns';

import { useContentResource } from '@traveloka/ctv-core/resource';
import {
  RoomCard,
  RoomCardSkeleton,
  RoomDetailModal as RoomDetailModalUI,
  RoomEmptyResult,
} from '@traveloka/ctvweb-ui/hotel';
import { Token } from '@traveloka/web-components';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import { useProductRequest } from 'approval-system/shared/contexts/ProductRequestContext';
import {
  BATHROOM_AMENITIES,
  ROOM_AMENITIES,
} from 'hotel/detail/constants/RoomDetailConstant';
import {
  useFilteredRooms,
  useProperty,
} from 'hotel/detail/contexts/PropertyContext';
import { Store } from 'hotel/prebook/types';
import {
  PriceDisplay,
  usePriceDisplay,
  usePriceDisplayFormatter,
} from 'hotel/search/contexts/PriceDisplayContext';
import { Room } from 'hotel/shared/api/types';
import ImageType from 'hotel/shared/constants/ImageType';
import { useSearchSpec } from 'hotel/shared/contexts/SpecContext';
import { convert } from 'shared/utils/currency';
import { DATE } from 'shared/utils/date';
import { formatCurrency, formatMessage } from 'shared/utils/intl';

import HotelCheckBookingModal from '../HotelCheckBookingModal/HotelCheckBookingModal';

type PriceBreakdown =
  | {
      label: string;
      value: string;
    }
  | 'separator';

export type CheckBookingData = {
  isVisible: boolean;
} & Omit<Store, 'approverReason'>;

const initCheckBookingData: CheckBookingData = {
  isVisible: false,
  checkInDate: undefined!,
  checkOutDate: undefined!,
  travelers: undefined!,
  nonEmployeeTravelers: undefined!,
  numOfRooms: undefined!,
  propertyId: undefined!,
  propertyName: undefined!,
  roomId: undefined!,
  rateKey: undefined!,
  roomOccupancy: undefined!,
  totalFare: undefined!,
  visitId: undefined!,
  searchId: undefined!,
  detailId: undefined!,
  tripRequestId: undefined!,
  approverName: undefined!,
  geoId: undefined!,
  geoType: undefined!,
  productRequestId: undefined!,
  tripName: undefined!,
};

export default function RoomList() {
  const [{ isLoading, trackingId }, dispatch] = useProperty();
  const [isFiltered, rooms] = useFilteredRooms();
  const productRequest = useProductRequest();

  const content = useContentResource().CorporateHotelDetailRoomList;

  const [checkBookingData, setCheckBookingData] = useState<CheckBookingData>(
    initCheckBookingData
  );
  const [detailModal, setDetailModal] = useState({
    isVisible: false,
    room: undefined as Room | undefined,
  });
  const openDetailModal = useCallback(
    (room: Room) => setDetailModal({ isVisible: true, room }),
    []
  );
  const closeDetailModal = useCallback(
    () => setDetailModal(s => ({ ...s, isVisible: false })),
    []
  );

  const handleResetFilter = useCallback(
    () => dispatch({ type: 'reset-filter' }),
    [dispatch]
  );

  const searchSpec = useSearchSpec();

  const selectRoom = useCallback(
    (room: Room) => {
      if (!searchSpec.id) {
        return;
      }

      let productRequestId: string | undefined;
      let geoId: string | undefined;
      let geoType: string | undefined;
      let tripName: string | undefined;
      let approverName: string | undefined;

      if (
        productRequest.enabled &&
        productRequest.data &&
        productRequest.data.type === 'HOTEL'
      ) {
        productRequestId = productRequest.data.productRequestId;
        geoId = productRequest.data.geoId;
        geoType = productRequest.data.geoType;
        tripName = productRequest.data.tripName;
        approverName = productRequest.data.approverName;
      }

      // Show modal, move store value to a state, pass to modal and store the value there.
      setCheckBookingData({
        isVisible: true,
        checkInDate: format(searchSpec.checkInDate, DATE),
        checkOutDate: format(searchSpec.checkOutDate, DATE),
        travelers: searchSpec.travelers,
        nonEmployeeTravelers: searchSpec.nonEmployeeTravelers,
        numOfRooms: searchSpec.rooms,
        propertyId: searchSpec.id,
        propertyName: searchSpec.geoName!,
        roomId: room.roomId,
        rateKey: room.rateKey,
        roomOccupancy: Number(room.roomOccupancy),
        totalFare: convert(room.totalFare),
        visitId: searchSpec.visitId,
        searchId: searchSpec.searchId,
        detailId: trackingId,
        tripRequestId: searchSpec.tripRequestId,
        tripName,
        approverName,
        productRequestId,
        geoId,
        geoType,
      });
    },
    [searchSpec, trackingId, productRequest]
  );

  if (isLoading) {
    return <RoomCardSkeleton style={Style.room} />;
  }

  if (!rooms.length) {
    const help = isFiltered
      ? content.emptyResultWithFilter
      : content.emptyResultNoFilter;
    const button = isFiltered ? content.emptyResultButtonText : undefined;

    return (
      <RoomEmptyResult
        title={content.emptyResultTitle}
        help={help}
        button={button}
        onPress={handleResetFilter}
      />
    );
  }

  return (
    <>
      <View>
        {rooms.map((room, index) => (
          <RoomListContent
            testID={`hotel.detail.room.list.${index}`}
            room={room}
            zIndex={rooms.length - index}
            onRoomDetailPress={openDetailModal}
            selectRoom={selectRoom}
          />
        ))}
      </View>
      {detailModal.room && (
        <RoomDetailModal
          isVisible={detailModal.isVisible}
          room={detailModal.room}
          onClosePress={closeDetailModal}
          onSeeOptionsPress={closeDetailModal}
        />
      )}
      <HotelCheckBookingModal
        {...checkBookingData}
        onClose={() =>
          setCheckBookingData({ ...checkBookingData, isVisible: false })
        }
      />
    </>
  );
}

type ContentProps = {
  testID?: string;
  room: Room;
  zIndex: number;
  onRoomDetailPress: (room: Room) => void;
  selectRoom: (room: Room) => void;
};

function RoomListContent(props: ContentProps) {
  const { testID, room, zIndex, onRoomDetailPress, selectRoom } = props;

  const {
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();
  const searchSpec = useSearchSpec();
  const { duration, rooms: numOfRooms } = searchSpec;

  const tripRequestDetailState = useProductRequest();

  const formatDisplayPrice = usePriceDisplayFormatter();
  const [priceDisplay] = usePriceDisplay();

  const content = useContentResource().CorporateHotelDetailRoomList;

  const durationLabels = useMemo(
    () =>
      Array.from({ length: duration }, (_, index) => {
        return `${content.durationLabel} ${index + 1}`;
      }),
    [duration]
  );

  const [highRate, totalFare] = useMemo(
    () => [convert(room.highRate), convert(room.totalFare)],
    [room.highRate, room.totalFare]
  );

  const breakdown = useMemo(() => {
    const surchargeTotal = convert(room.surchargeTotal);

    const nightlyRateTotal = {
      ...totalFare,
      amount: totalFare.amount - surchargeTotal.amount,
    };

    const nightlyRate = {
      ...nightlyRateTotal,
      amount: nightlyRateTotal.amount / duration,
    };

    const breakdown: PriceBreakdown[] = durationLabels.map(label => ({
      label,
      value: formatCurrency(nightlyRate),
    }));

    breakdown.unshift({
      label: formatMessage(content.breakdownTitleContent, {
        room: numOfRooms,
        night: duration,
      }),
      value: '',
    });

    breakdown.push(
      'separator',
      {
        label: content.breakdownIncludingTaxText,
        value: formatCurrency(nightlyRateTotal),
      },
      {
        label: content.breakdownTaxAndOtherFeeText,
        value: formatCurrency(surchargeTotal),
      },
      {
        label: content.breakdownTravelokaFeeText,
        value: 'FREE',
      },
      'separator',
      {
        label: content.breakdownTotalPaymentText,
        value: formatCurrency(totalFare),
      }
    );

    return breakdown;
  }, [duration, durationLabels, numOfRooms, room.surchargeTotal, totalFare]);

  const allotment =
    Number(room.remainingAllotment) < 5
      ? formatMessage(content.roomRemainingContent, {
          num: room.remainingAllotment,
        })
      : undefined;

  const images = useMemo(
    () =>
      room.images
        .filter(image => image.type === ImageType.LARGE)
        .map(image => image.url),
    [room.images]
  );

  const isFreeWifi = !!room.isWifiIncluded;
  const wifiLabel = isFreeWifi ? content.freeWifiText : content.noWifiText;

  const isFreeBreakfast = !!room.isBreakfastIncluded;
  const breakfastLabel = isFreeBreakfast
    ? content.freeBreakfastText
    : content.noBreakfastText;

  const guestLabel = formatMessage(content.guestContent, {
    num: room.roomOccupancy,
  });

  const roomNightLabel = useMemo(() => {
    const roomNight = { room: 1, night: 1 };
    if (priceDisplay === PriceDisplay.TOTAL) {
      roomNight.room = numOfRooms;
      roomNight.night = duration;
    }
    const roomNightLabel = formatMessage(content.roomNightContent, roomNight);
    return roomNightLabel;
  }, [numOfRooms, duration, priceDisplay]);

  const isFreeCancelation = room.isFreeCancelation;
  const cancelation = isFreeCancelation
    ? content.freeCancellationText
    : room.isRefundable
    ? content.refundableRoomText
    : content.nonRefundableRoomText;

  const originalPrice =
    highRate.amount > totalFare.amount
      ? formatDisplayPrice(highRate)
      : undefined;

  const handleRoomDetailPress = useCallback(() => onRoomDetailPress(room), [
    onRoomDetailPress,
    room,
  ]);
  const handleSelectRoom = useCallback(() => selectRoom(room), [
    selectRoom,
    room,
  ]);

  return (
    <RoomCard
      key={room.rateKey}
      testID={testID}
      allotment={allotment}
      bed={room.bedType}
      bookLabel={content.bookButtonText}
      breakfast={breakfastLabel}
      cancelation={cancelation}
      cancelationPolicy={room.refundPolicy}
      complianceLabel={content.nonCompliantText}
      guest={guestLabel}
      images={images}
      isComplying={room.isComplying}
      isFreeBreakfast={isFreeBreakfast}
      isFreeCancelation={isFreeCancelation}
      isFreeWifi={isFreeWifi}
      mainPrice={formatDisplayPrice(totalFare)}
      name={room.roomName}
      onImagePress={handleRoomDetailPress}
      onRoomDetailPress={handleRoomDetailPress}
      onSelectRoomPress={() => fetchAccountStatus(handleSelectRoom)}
      selectRoomButtonLoading={isAccountStatusFetching}
      originalPrice={originalPrice}
      priceBreakdown={breakdown}
      promos={room.promos}
      readPolicyLabel={content.readPolicyText}
      roomDetailLabel={content.roomDetailButtonText}
      roomNightLabel={roomNightLabel}
      roomSize={room.roomSize}
      roomType={room.roomTypeName || undefined}
      showBookButton={
        !tripRequestDetailState.enabled || !!tripRequestDetailState.data
      }
      style={[Style.room, { zIndex }]}
      taxInclusionLabel={content.inclusiveTaxText}
      wifi={wifiLabel}
    />
  );
}

type RoomDetailModalProps = {
  isVisible: boolean;
  room: Room;
  onClosePress(): void;
  onSeeOptionsPress(): void;
};

function RoomDetailModal(props: RoomDetailModalProps) {
  const { isVisible, room, onClosePress, onSeeOptionsPress } = props;

  const content = useContentResource().CorporateHotelDetailRoomList;

  const images = room.images
    .filter(image => image.type === ImageType.LARGE)
    .map(image => image.url);

  const formatPrice = usePriceDisplayFormatter(PriceDisplay.NIGHTLY);
  const guest = formatMessage(content.guestContent, {
    num: room.roomOccupancy,
  });
  const facilityMap = useMemo(() => {
    return room.amenities.reduce((obj, amenity) => {
      obj[amenity.type] = amenity.name;

      return obj;
    }, {} as Record<string, string>);
  }, [room.amenities]);

  const bathroomAmenities = useMemo(
    () =>
      BATHROOM_AMENITIES.map(amenity => facilityMap[amenity])
        .filter(Boolean)
        .sort((a, b) => a.localeCompare(b)),
    [facilityMap]
  );

  const roomFacilities = useMemo(
    () =>
      ROOM_AMENITIES.map(amenity => facilityMap[amenity])
        .filter(Boolean)
        .sort((a, b) => a.localeCompare(b)),
    [facilityMap]
  );

  return (
    <RoomDetailModalUI
      bathroomAmenities={bathroomAmenities}
      bathroomAmenitiesLabel={content.bathroomAmenitiesText}
      bed={room.bedType}
      guest={guest}
      images={images}
      isVisible={isVisible}
      noPhotoText={content.noPhotoText}
      onClosePress={onClosePress}
      onSeeOptionsPress={onSeeOptionsPress}
      roomFacilities={roomFacilities}
      roomFacilitiesLabel={content.roomFacilityText}
      roomInfoLabel={content.roomInfoText}
      roomName={room.roomName}
      roomPrice={formatPrice(convert(room.totalFare))}
      roomPriceLabel={content.roomPriceUnit}
      seeOptionsLabel={content.seeOptionButtonText}
      size={room.roomSize}
      startingFromLabel={content.startingFromText}
    />
  );
}

const Style = StyleSheet.create({
  room: {
    marginBottom: Token.spacing.m,
  },
});

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react';

import { useApi, useLocale } from '@traveloka/ctv-core';

import {
  AirlineDataResponse,
  AirportDataResponse,
  AIRLINE_DATA_API,
  AIRPORT_DATA_API,
} from 'flight/shared/api';
import expirableStorage from 'shared/utils/expirable-storage';

import { AirlineMap, AirportMap, AreaAirportsMap, StaticData } from '../types';

const initialValue = {
  airlineMap: {},
  airportMap: {},
  areaAirportsMap: {},
};

const Context = createContext<StaticData>(initialValue);

const STORAGE_KEY = 'flight-static-data';
const STORAGE_TTL = 5 * 60 * 1000; // 5 minutes

export default function StaticDataProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const [state, setState] = useState({
    loading: true,
    staticData: initialValue,
  });

  const { locale } = useLocale();

  const getAirlineData = useApi<AirlineDataResponse, {}>({
    domain: 'search',
    method: 'post',
    path: AIRLINE_DATA_API,
  });

  const getAirportData = useApi<AirportDataResponse, {}>({
    domain: 'search',
    method: 'post',
    path: AIRPORT_DATA_API,
  });

  const storageKey = `${STORAGE_KEY}-${locale}`;

  useEffect(() => {
    const cached = expirableStorage.get<StaticData>(storageKey);

    if (
      cached &&
      Object.keys(cached.airlineMap).length !== 0 &&
      Object.keys(cached.airportMap).length !== 0 &&
      Object.keys(cached.areaAirportsMap).length !== 0
    ) {
      setState({ loading: false, staticData: cached });
      return;
    }

    Promise.all([getAirlineData({}), getAirportData({})]).then(res => {
      const [airlineRes, airportRes] = res;

      const staticData = {
        airlineMap: {},
        airportMap: {},
        areaAirportsMap: {},
      };

      if (airlineRes.success) {
        const { airlines } = airlineRes.data;

        staticData.airlineMap = airlines.reduce((obj, airline) => {
          obj[airline.airlineCode] = airline;

          return obj;
        }, {} as AirlineMap);
      }

      if (airportRes.success) {
        const { airports } = airportRes.data;

        staticData.airportMap = airports.reduce((obj, airport) => {
          obj[airport.airportCode] = {
            airportCode: airport.airportCode,
            city: airport.city,
            countryId: airport.countryId,
            countryCode: airport.countryCode,
            internationalAirportName: airport.internationalAirportName,
          };

          return obj;
        }, {} as AirportMap);

        staticData.areaAirportsMap = airports.reduce((obj, airport) => {
          const areaAiports = obj[airport.areaCode];

          if (airport.airportCode !== airport.areaCode) {
            const newValue =
              typeof areaAiports === 'string' ? `${areaAiports}|` : '';

            obj[airport.areaCode] = newValue.concat(airport.airportCode);
          }

          return obj;
        }, {} as AreaAirportsMap);
      }

      expirableStorage.set(storageKey, staticData, STORAGE_TTL);
      setState({ loading: false, staticData });
    });
  }, []);

  return (
    <Context.Provider value={state.staticData}>
      {!state.loading && children}
    </Context.Provider>
  );
}

export function useStaticData() {
  return useContext(Context);
}

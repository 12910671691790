import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import InfoIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_status_info_16px.svg';
import { Button, Icon, Text, Token } from '@traveloka/web-components';

import { key } from 'shared/data-tracking/localStorage';
import useEagerNavigation from 'shared/hooks/useEagerNavigation';
import { set } from 'shared/utils/expirable-storage';

import ProductType from '../../constants/ProductType';
import { useProductRequest } from '../../contexts/ProductRequestContext';

type Props = {
  productType: ProductType;
};

const dot = '\u2022';

export default function ProductRequestFooter(props: Props) {
  const { productType } = props;

  const [isClosed, setIsClosed] = useState<boolean>(false);

  const productRequestState = useProductRequest();
  const content = useContentResource().CorporateApprovalSystemSearchFooter;

  const navigate = useEagerNavigation();

  if (!productRequestState.enabled || isClosed) {
    return null;
  }

  function handleClose() {
    setIsClosed(true);
  }

  function goToApprovalList() {
    navigate({ pathname: '/approval' });
  }

  function goToCreateTripRequestForm() {
    set(
      key.CREATE_APPROVAL,
      {
        ...trackerProductSwitcher(productType),
        webReferrer: window.location.href,
        eventType: 'CLICK',
        clickSource: 'SEARCH_RESULT',
      },
      5 * 60 * 1000 //5 Mins expired
    );
    navigate({ pathname: '/approval/create' });
  }

  if (!productRequestState.data)
    return (
      <View style={Style.container}>
        <View style={Style.noTripFooterRowContainer}>
          <Text
            testID="footer.product-request.no-detail.description"
            ink="white"
            style={Style.noTripFooterText}
            variant="ui-small"
          >
            {content.noTripRequestApprovalDescription}{' '}
          </Text>
          <View style={Style.noTripFooterContent}>
            <TouchableOpacity
              testID="footer.product-request.no-detail.approval-list.link"
              onPress={goToApprovalList}
            >
              <Text ink="white" variant="ui-small">
                {content.approvalListLinkText}
              </Text>
            </TouchableOpacity>
            <Button
              testID="footer.product-request.no-detail.create-trip-request.button"
              style={Style.createTripButton}
              variant="main-cta"
              text={content.createApprovalRequestButton}
              onPress={goToCreateTripRequestForm}
            />
          </View>
        </View>
      </View>
    );

  function goToTripDetail() {
    if (productRequestState.data) {
      navigate({
        pathname: `/approval/${productRequestState.data.tripRequestId}`,
      });
    }
  }

  return (
    <View style={Style.container}>
      <View style={Style.rowContainer}>
        <Icon src={InfoIcon} />
        <Text
          testID="footer.product-request.has-detail.description"
          ink="white"
          style={Style.firstText}
          variant="ui-baseline"
        >
          {content.hasTripRequestApprovalDescription}{' '}
          <Text
            testID="footer.product-request.has-detail.trip-name"
            ink="white"
            style={Style.bold}
            variant="ui-baseline"
          >
            {productRequestState.data.tripName}
          </Text>
        </Text>
        <Text ink="white" variant="ui-baseline" style={Style.dot}>
          {dot}
        </Text>
        <TouchableOpacity
          testID="footer.product-request.has-detail.trip-detail.link"
          onPress={goToTripDetail}
        >
          <Text ink="white" variant="ui-baseline">
            {content.tripDetailLinkText}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          testID="footer.product-request.has-detail.close"
          style={Style.close}
          onPress={handleClose}
        >
          <Text ink="white" variant="ui-small">
            {content.closeText}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

function trackerProductSwitcher(productType: ProductType) {
  switch (productType) {
    case ProductType.FLIGHT:
      return {
        pageCategory: 'FLIGHT',
        pageReferrer: 'FLIGHT_SEARCH',
      };
    case ProductType.HOTEL:
      return {
        pageCategory: 'HOTEL',
        pageReferrer: 'HOTEL_SEARCH',
      };
  }
}

const Style = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    // @ts-ignore
    position: 'fixed',
    bottom: 0,
    height: 72,
    width: '100%',
    backgroundColor: Token.color.uiDarkPrimary,
    zIndex: 2,
  },
  rowContainer: {
    flexDirection: 'row',
    width: 960,
  },
  firstText: {
    marginLeft: Token.spacing.xs,
  },
  bold: Token.typography.weightMedium,
  dot: {
    marginHorizontal: Token.spacing.xs,
  },
  close: {
    marginLeft: 'auto',
    marginRight: Token.spacing.xs,
  },
  noTripFooterRowContainer: {
    flexDirection: 'row',
    paddingHorizontal: Token.spacing.m,
    width: 960,
  },
  noTripFooterText: {
    flex: 2,
  },
  noTripFooterContent: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  createTripButton: {
    marginLeft: Token.spacing.xxxl,
  },
});

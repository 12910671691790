import { JavaCurrencyValue } from 'shared/utils/currency';

import {
  Approver,
  PaymentApprovalType,
  ExceptionEmployee,
  CreditCard,
  TotalSpendingSummary,
  ProductTypeSpendingSummmary,
  SpendingSummary,
  SeatClassSpendingSummary,
  AirlineSpendingSummary,
  FlightRouteSpendingSummary,
  SubmittedApproverType,
  GlobalApproverResponse,
  Attachment,
  InvoiceStatus,
  InvoiceObj,
  PolicyAutoCompleteLocation,
} from './types';

// Reset Password
export const RESET_PASSWORD = '/api/v1/authorization/reset-password';
export type ResetPasswordRequest = {
  email: string;
};
export type ResetPasswordResponse = {
  errorMessage: Nullable<string>;
  status: string;
};

// Resend Ticket
export const RESEND_FLIGHT_TICKET_API = '/api/v1/booking/flight/resend-eticket';
export type ResendTicketRequest = {
  bookingId: string;
};
export type ResendTicketResponse = {
  errorMessage: Nullable<string>;
  status: string;
};

// Approval System Config - Get Config
export const GET_APPROVAL_SYSTEM_CONFIG = '/api/v1/approval/configuration';
export type GetApprovalSystemResponse = {
  isSaved: boolean;
  configuration: {
    tripRequestApproval: boolean;
    paymentApproval: PaymentApprovalType;
  };
  exceptionEmployees: ExceptionEmployee[];
};

// Approval System Config - Submit Config
export const SUBMIT_APPROVAL_SYSTEM_CONFIG =
  '/api/v1/approval/configuration/submit';
export type SubmitApprovalSystemRequest = {
  updateTravelerBookerRole: boolean;
  appointApprover: boolean;
  configuration: {
    tripRequestApproval: boolean;
    paymentApproval: PaymentApprovalType;
  };
  exceptionEmployees: ExceptionEmployee[];
};

// Get Approver List
export const GET_APPROVER_LIST = '/api/v1/approval/approver/list';
export type GetApproverListRequest = Partial<{
  isUnassignedOnly: boolean;
  filter: string; // Only available when isOverview is false
  isOverview: boolean;
  entriesCount: number;
  offset: number;
}>;
export type GetApproverListResponse = {
  approvers: Approver[];
  assignmentState: 'ALL_ASSIGNED' | 'SOME_UNASSIGNED' | 'ALL_UNASSIGNED';
  totalEntries: string;
  totalUnassigned: string;
};

// Assign Approver
export const ASSIGN_APPROVER_API = '/api/v1/approval/approver/assign';
export type AssignApproverRequest = {
  employeeIds: string[];
};
export type AssignApproverResponse = GlobalApproverResponse;

// Update Approver
export const UPDATE_APPROVER_API = '/api/v1/approval/approver/update';
export type UpdateApproverRequest = {
  employeeId: string;
} & SubmittedApproverType;
export type UpdateApproverResponse = GlobalApproverResponse;

// Unassign Approver
export const UNASSIGN_APPROVER_API = '/api/v1/approval/approver/unassign';
export type UnassignApproverRequest = {
  employeeIds: string[];
};
export type UnassignApproverResponse = GlobalApproverResponse;

// Employee Bulk Upload
export const BULK_UPLOAD_API = '/api/v2/crud/upload';

// Retrieve Credit Card
export const CREDIT_CARD_LIST_API = '/api/v1/payment/credit-card/list';
export type CreditCardListResponse = {
  cards: CreditCard[];
};

// Credit Card Exist
export const CREDIT_CARD_EXIST_API = '/api/v1/payment/credit-card/exist';
export type CreditCardExistResponse = {
  exist: boolean;
};

// Register Credit Card
export const REGISTER_CREDIT_CARD_API = '/api/v1/payment/credit-card/register';
export type RegisterCardResponse = {
  cardId: string;
  redirectInfo: unknown;
};

// Manage Card
export const MANAGE_CREDIT_CARD_API = '/api/v1/payment/credit-card/manage';
export type ManageCardRequest = {
  cardId: string;
  action: 'SET_AS_MAIN' | 'REMOVE';
};

// Expense Report
export const EXPENSE_REPORT_DASHBOARD_API = '/api/v1/expense-report/dashboard';
export type ExpenseReportDashboardRequest = {
  fromDate: string;
  toDate: string;
};
export type ExpenseReportDashboardResponse = {
  policyComplianceRate: string;
  totalUniqueFlightPassenger: string;
  totalUniqueHotelGuest: string;
  totalTripRequest: string;
  totalApprovedTripRequest: string;
  totalUniqueTripRequestTraveler: string;
  totalSpending: TotalSpendingSummary;
  spendingPerProduct: ProductTypeSpendingSummmary[];
  spendingPerFlightAirline: AirlineSpendingSummary[];
  spendingPerFlightSeatClass: SeatClassSpendingSummary[];
  spendingPerFlightRoute: FlightRouteSpendingSummary[];
  spendingPerHotelStar: SpendingSummary[];
  spendingPerHotelCity: SpendingSummary[];
  spendingPerDivision: SpendingSummary[];
};

export const EXPENSE_REPORT_DOWNLOAD_API = '/api/v1/expense-detail/download';
export type ExpenseReportDownloadRequest = {
  fromDate: string;
  toDate: string;
  timeZone: string;
};
export type ExpenseReportDownloadResponse = {
  csv: string;
};

export const USER_ROLE_PERMISSION_API =
  '/api/v2/management/get/user-access-role';

// Attachment List
export const ATTACHMENT_LIST_API = '/api/v1/expense-report/attachments';
export type AttachmentListRequest = {
  tripId: string | null;
  fromDate: string; // YYYY-MM-DD
  toDate: string; // YYYY-MM-DD
  filterEmployees: string[]; // email
  limit: number; // max 10
  offset: number;
};
export type AttachmentListResponse = {
  attachments: Attachment[];
  totalEntries: string;
};

// Retrieve Attachment
export const RETRIEVE_ATTACHMENT_API = '/api/v1/booking/retrieve/attachment';
export type RetrieveAttachmentRequest = {
  tripId: string;
  type: 'TRIP_REQUEST' | 'BOOKING';
  isDownload: boolean;
};
export type RetrieveAttachmentResponse = {
  url: string;
  isSuccess: boolean;
};

// Retrieve Multiple Attachment
export const RETRIEVE_MULTIPLE_ATTACHMENT_API =
  '/api/v1/booking/retrieve/attachments';
export type RetrieveMultipleAttachmentRequest = {
  attachments: Array<{
    tripId: string;
    type: 'TRIP_REQUEST' | 'BOOKING';
  }>;
  email: string | null;
};
export type RetrieveMultipleAttachmentResponse = {
  url: string | null;
  isSuccess: boolean;
};

// Retrieve All Attachment
export const RETRIEVE_ALL_ATTACHMENT_API =
  '/api/v1/booking/retrieve/all-attachments';
export type RetrieveAllAttachmentRequest = {
  tripId: string | null;
  fromDate: string;
  toDate: string;
  filterEmployees: Array<string>;
  email: string | null;
};
export type RetrieveAllAttachmentResponse = {
  url: string | null;
  isSuccess: boolean;
};

// Invoice Summary
export const INVOICE_SUMMARY_API = '/api/v1/invoice/summary';
export type InvoiceSummaryResponse = {
  totalUnpaid: JavaCurrencyValue;
  unpaidBeforeDueDate: JavaCurrencyValue;
  unpaidAfterDueDate: JavaCurrencyValue;
};

// Invoice List
export const INVOICE_LIST_API = '/api/v1/invoice/list';
export type InvoiceListRequest = {
  fromDate: string;
  toDate: string;
  status: InvoiceStatus;
  page: number;
};
export type InvoiceListResponse = {
  invoices: Array<{
    statementDate: string;
    invoices: InvoiceObj[];
    dueDate: string;
    statementAmount: JavaCurrencyValue;
    paymentStatus: Exclude<InvoiceStatus, 'ALL'>;
    unpaidAmount: JavaCurrencyValue;
    paymentDates: string[];
  }>;
  hasNext: boolean;
};

// Invoice Summary
export const RETRIEVE_INVOICE_API = '/api/v1/invoice/retrieve';
export type RetrieveInvoiceRequest = {
  id: string;
  type: 'PDF' | 'XLSX';
};
export type RetrieveInvoiceResponse = {
  data: string | null;
  fileName: string;
  fileType: 'CSV_XLSX' | 'PDF';
};

// Hotel Policy Auto Complete
export const HOTEL_POLICY_AUTO_COMPLETE_API = '/api/v1/search/geo/autocomplete';
export type HotelPolicyAutoCompleteRequest = {
  query: string;
};
export type HotelPolicyAutoCompleteResponse = {
  countries: Array<PolicyAutoCompleteLocation>;
  provinces: Array<PolicyAutoCompleteLocation>;
  cities: Array<PolicyAutoCompleteLocation>;
};

import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import useUpload from '@traveloka/ctv-core/upload/useUpload';
import {
  Text,
  Card,
  Token,
  Label,
  useTheme,
  Icon,
} from '@traveloka/web-components';
import { InfoBoxV2 } from '@traveloka/ctvweb-ui';
import Tooltip from '@traveloka/ctvweb-ui/src/shared/components/Tooltip/Tooltip';

import IcSystemStatusInfoFill from '@traveloka/icon-kit-web/svg/blue/ic_system_status_info-fill_16px.svg';

import { PrebookStatus } from 'approval-system/shared/api/types';
import { checkApprovalReason } from 'approval-system/shared/utils/validations';
import { usePaymentApprovalContext } from 'flight/prebook/contexts/FlightPrebookContext';
import { Style as SharedStyle } from 'flight/prebook/flight-prebook.style';
import {
  PrebookingAttachmentUploadRequest,
  PrebookingAttachmentUploadResponse,
  PREBOOKING_ATTACHMENT_UPLOAD,
} from 'flight/shared/api';
import DocumentField from 'shared/components/form/DocumentField/DocumentField';
import InputField from 'shared/components/form/InputField/InputField';
import { formatMessage } from 'shared/utils/intl';

const documentExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
const documentMaxSizeInMB = '2';

export default function PaymentApproval() {
  const { color } = useTheme();
  const paymentApproval = usePaymentApprovalContext();

  const content = useContentResource().CorporateFlightPrebookApprovalSystem;
  const approvalSystemContent = useContentResource()
    .CorporateApprovalSystemProductConfirmation;

  const upload = useUpload<
    PrebookingAttachmentUploadResponse,
    PrebookingAttachmentUploadRequest
  >({
    path: PREBOOKING_ATTACHMENT_UPLOAD,
  });

  if (paymentApproval?.preBookingStatus !== PrebookStatus.NEED_APPROVAL) {
    return null;
  }

  async function handleUpload(_documentType: string, file: File) {
    const res = await upload({ type: 'BOOKING' }, file);

    if (res.success) return res.data;
    else throw res.error;
  }

  const inputStyle = {
    borderColor: color.lightSecondary,
  };

  return (
    <View style={SharedStyle.section}>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {content.title}
      </Text>
      <Card style={SharedStyle.card}>
        <InfoBoxV2
          style={SharedStyle.group}
          header={content.paymentApprovalInfoBoxTitle}
          content={formatMessage(content.paymentApprovalInfoBoxContent, {
            tripName: paymentApproval.tripName!,
          })}
        />
        <View style={SharedStyle.group}>
          <Label text={content.approverNameLabel} />
          <Text
            testID="pre-book.form.payment-approval.approver-name"
            ink="secondary"
            style={[Style.inputLike, inputStyle]}
          >
            {paymentApproval.approverName}
          </Text>
        </View>
        <InputField
          inputStyle={Style.textarea}
          testID="pre-book.form.payment-approval.reason"
          name="paymentApprovalReason"
          multiline
          maxLength={200}
          label={content.paymentApprovalBookingNotesLabel}
          placeholder={content.approvalReasonPlaceholder}
          defaultValue={paymentApproval.approverReason}
          validate={value => {
            if (!value) {
              return content.approvalReasonRequiredErrorMessage;
            }
            if (checkApprovalReason(value)) {
              return approvalSystemContent.reasonInvalidSymbolValidationText;
            }
            return undefined;
          }}
        />
        <View style={SharedStyle.group}>
          <View style={[SharedStyle.sectionTitle, Style.titleContainer]}>
            <Text variant="title-3">
              {content.paymentApprovalBookingAttachmentLabel}
            </Text>
            <Tooltip
              content={content.paymentApprovalBookingAttachmentTooltip}
              position="right"
              variant="info"
              width={450}
            >
              <View style={Style.titleTooltip}>
                <Icon src={IcSystemStatusInfoFill} width={16} height={16} />
              </View>
            </Tooltip>
          </View>
          <DocumentField
            document={{
              checkbox: null,
              docType: 'BOOKING',
              extensions: documentExtensions,
              label: '',
              mandatory: false,
              maxSizeInMB: documentMaxSizeInMB,
              multiple: false,
              placeholder: content.paymentApprovalBookingAttachmentPlaceholder,
              subLabel: null,
              tooltip: null,
            }}
            helper={formatMessage(
              content.paymentApprovalBookingAttachmentInfo,
              {
                size: documentMaxSizeInMB,
                extension: documentExtensions.join('/'),
              }
            )}
            name="attachment"
            onChange={handleUpload}
            uploadButtonCr={content.paymentApprovalBookingAttachmentButtonText}
            retryButtonCr={
              content.paymentApprovalBookingAttachmentRetryButtonText
            }
          />
        </View>
      </Card>
    </View>
  );
}

const Style = StyleSheet.create({
  titleContainer: {
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoBox: {
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.m,
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: Token.spacing.xxs,
    backgroundColor: Token.color.uiBlueLight,
  },
  infoText: {
    flex: 1,
    marginLeft: Token.spacing.m,
  },
  bold: Token.typography.weightMedium,
  inputLike: {
    borderWidth: Token.border.width.thick,
    borderRadius: Token.border.radius.normal,
    padding: Token.spacing.xs - 1,
  },
  textarea: {
    height: 72,
  },
  titleTooltip: {
    marginLeft: Token.spacing.s,
  },
});

import React from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import {
  Button,
  Card,
  Checkbox,
  Icon,
  Text,
  Token,
} from '@traveloka/web-components';
import ChevronLeft from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_left_16px.svg';

import { useProductRequestRefundPage } from 'refund/request-refund/contexts/ProductRequestRefundPageContext';
import { useHotelRequestRefundNavigator } from 'refund/request-refund/contexts/hotel/HotelRequestRefundNavigatorContext';
import {
  useHotelRequestRefundForm,
  useHotelRequestRefundFormAction,
} from 'refund/request-refund/contexts/hotel/HotelRequestRefundFormContext';

import PolicyCard from './PolicyCard/PolicyCard';
import SelectRefundReasonSection from './SelectRefundReasonSection/SelectRefundReasonSection';

export default function HotelRequestRefundPolicy() {
  const [_, { goToBookingDetail }] = useProductRequestRefundPage();
  const { goToNextStep } = useHotelRequestRefundNavigator();
  const { mainRefundReason, tncCheckbox } = useHotelRequestRefundForm();
  const { updateTncCheckbox } = useHotelRequestRefundFormAction();

  const content = useContentResource().CorporateHotelRequestRefundPolicy;

  return (
    <>
      <Button
        style={Style.buttonBack}
        size="small"
        variant="text"
        text={content.backButtonText}
        iconStart={() => <Icon src={ChevronLeft} />}
        onPress={goToBookingDetail}
      />
      <Text variant="headline">{content.refundPolicyHeadline}</Text>
      <Card style={Style.policyContainer}>
        <PolicyCard />
      </Card>
      <Card style={Style.mainReasonContainer}>
        <SelectRefundReasonSection />
      </Card>
      <Card style={Style.checkBoxContainer}>
        <Checkbox
          label={content.tncText}
          checked={tncCheckbox}
          onChange={updateTncCheckbox}
        />
      </Card>
      <Button
        style={Style.buttonContinue}
        variant="main-cta"
        text={content.continueButtonText}
        disabled={!mainRefundReason || !tncCheckbox}
        onPress={() => {
          goToNextStep();
          window.scrollTo(0, 0);
        }}
      />
    </>
  );
}

const Style = StyleSheet.create({
  buttonBack: {
    marginBottom: Token.spacing.m,
    width: 80,
  },
  policyContainer: {
    marginTop: Token.spacing.l,
  },
  mainReasonContainer: {
    marginTop: Token.spacing.l,
    marginBottom: Token.spacing.m,
    paddingTop: Token.spacing.ml,
    paddingBottom: Token.spacing.l,
  },
  checkBoxContainer: {
    marginBottom: Token.spacing.l,
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.l,
    flexDirection: 'row',
  },
  buttonContinue: {
    marginLeft: 'auto',
    width: 304,
  },
});

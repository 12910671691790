import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';

import FlightDurationIcon from '@traveloka/icon-kit-web/svg/blue/ic_flight_duration_24px.svg';
import WarningIcon from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_system_status_warning-fill_24px.svg';
import CheckIcon from '@traveloka/icon-kit-web/svg/greenPrimary/ic_system_checkmark_24px.svg';
import { useTheme, Icon, Image, Text, Token } from '@traveloka/web-components';

import { FlightSegmentData, FlightData, AdditionalTransitInfo } from '../types';
import FlightFacility from '../FlightFacility/FlightFacility';
import Divider from '../../shared/components/Divider/Divider';
import { appendTestId } from '../../shared/utils/TestUtil';

type Props = {
  compactLayout?: boolean;
  flight: FlightData;
  testID?: string;
};

export default function FlightJourney(props: Props) {
  const { testID: prefixTestID, compactLayout, flight } = props;

  return (
    <>
      {flight.segments.map((segment, index) => {
        const testID = appendTestId(prefixTestID, `${index}`);

        return (
          <Fragment key={index}>
            {segment.additionalTransitInfo.length > 0 && (
              <TransitInfo
                testID={appendTestId(testID, 'transit-info')}
                compactLayout={compactLayout}
                segment={segment}
              />
            )}
            <View style={Style.journey} testID={testID}>
              <View
                style={[
                  Style.airlineAndSegment,
                  !compactLayout && Style.airlineAndSegmentNormal,
                ]}
              >
                <AirlineInfo
                  testID={appendTestId(testID, 'airline')}
                  compactLayout={compactLayout}
                  segment={segment}
                />
                <SegmentInfo
                  testID={appendTestId(testID, 'journey-info')}
                  compactLayout={compactLayout}
                  segment={segment}
                />
              </View>
              <FacilityInfo
                testID={appendTestId(testID, 'facilities')}
                compactLayout={compactLayout}
                segment={segment}
              />
            </View>
          </Fragment>
        );
      })}
    </>
  );
}

type CommonProps = {
  compactLayout?: boolean;
  segment: FlightSegmentData;
  testID?: string;
};

function AirlineInfo(props: CommonProps) {
  const { testID, compactLayout } = props;
  const { airlineLogo, flightName, operatedBy, seatClass } = props.segment;

  return (
    <View
      style={[
        !compactLayout ? Style.airlineInfoNormal : Style.airlineInfoCompact,
      ]}
    >
      <View style={Style.airlineLogo}>
        <Image
          src={airlineLogo.src}
          alt={airlineLogo.alt}
          width={28}
          height={24}
          objectFit="contain"
        />
      </View>
      <Text
        testID={appendTestId(testID, 'flight-name')}
        variant="ui-small"
        ink="primary"
      >
        {flightName}
      </Text>
      {operatedBy && (
        <Text variant="ui-small" ink="secondary" style={Style.airlineText}>
          {operatedBy}
        </Text>
      )}
      <Text
        testID={appendTestId(testID, 'seat-class')}
        variant="ui-small"
        ink="secondary"
        style={Style.airlineText}
      >
        {seatClass}
      </Text>
    </View>
  );
}

function SegmentInfo(props: CommonProps) {
  const { testID, segment } = props;
  const {
    departureTime,
    departureDate,
    departureLocation,
    departureAirportName,
    durationStr,
    stopOver,
    arrivalTime,
    arrivalDate,
    arrivalLocation,
    arrivalAirportName,
  } = segment;
  const { color } = useTheme();

  const circleStartStyle = {
    borderColor: color.tintPrimary,
  };
  const circleEndStyle = {
    backgroundColor: color.tintPrimary,
    borderColor: color.tintPrimary,
  };
  const lineStyle = { backgroundColor: color.darkSecondary };

  return (
    <View>
      <View style={Style.segmentRow}>
        <View style={Style.segmentLineCol}>
          <View style={[Style.segmentCircle, circleStartStyle]} />
          <View style={[Style.segmentLine, lineStyle]} />
        </View>
        <View style={Style.segmentDateTime}>
          <Text
            testID={appendTestId(testID, 'departure-time')}
            variant="ui-small"
            ink="primary"
          >
            {departureTime}
          </Text>
          <Text
            testID={appendTestId(testID, 'departure-date')}
            variant="ui-small"
            ink="secondary"
          >
            {departureDate}
          </Text>
        </View>
        <View style={Style.segmentLocation}>
          <Text variant="ui-small" ink="primary">
            {departureLocation}
          </Text>
          <Text variant="ui-small" ink="secondary">
            {departureAirportName}
          </Text>
        </View>
      </View>
      <View style={Style.segmentRow}>
        <View style={Style.segmentLineCol}>
          <View style={[Style.segmentLine, lineStyle]} />
        </View>
        <View
          testID={appendTestId(testID, 'flight-duration')}
          style={Style.segmentDuration}
        >
          <Icon
            src={FlightDurationIcon}
            width={Token.spacing.l}
            height={Token.spacing.l}
          />
          <Text
            variant="ui-tiny"
            ink="primary"
            style={Style.segmentDurationText}
          >
            {durationStr}
          </Text>
        </View>
        {stopOver && (
          <View style={Style.segmentStopOver}>
            <Text variant="ui-tiny" ink="primary">
              {stopOver.description}
            </Text>
            <Text variant="ui-tiny" ink="secondary">
              {stopOver.airportName}
            </Text>
          </View>
        )}
      </View>
      <View style={Style.segmentRow}>
        <View style={Style.segmentLineCol}>
          <View style={[Style.segmentCircle, circleEndStyle]} />
        </View>
        <View style={Style.segmentDateTime}>
          <Text variant="ui-small" ink="primary">
            {arrivalTime}
          </Text>
          <Text variant="ui-small" ink="secondary">
            {arrivalDate}
          </Text>
        </View>
        <View style={Style.segmentLocation}>
          <Text variant="ui-small" ink="primary">
            {arrivalLocation}
          </Text>
          <Text variant="ui-small" ink="secondary">
            {arrivalAirportName}
          </Text>
        </View>
      </View>
    </View>
  );
}

function FacilityInfo(props: CommonProps) {
  const { testID, compactLayout, segment } = props;

  return (
    <View
      style={[
        !compactLayout ? Style.facilityInfoNormal : Style.facilityInfoCompact,
      ]}
    >
      {segment.facilities.map((facility, index) => (
        <FlightFacility
          testID={appendTestId(testID, `${index}`)}
          key={index}
          facility={facility}
          style={Style.facility}
        />
      ))}
    </View>
  );
}

function TransitInfo(props: CommonProps) {
  const { testID, compactLayout, segment } = props;
  const { color } = useTheme();

  const { additionalTransitInfo } = segment;

  return (
    <View
      testID={testID}
      style={[
        Style.transit,
        !compactLayout && Style.transitNormal,
        { backgroundColor: color.lightStain },
      ]}
    >
      <Icon
        src={WarningIcon}
        width={Token.spacing.l}
        height={Token.spacing.l}
      />
      <View style={Style.additionalTransit}>
        {additionalTransitInfo.map((transitInfo, index) => (
          <TransitInfoContent key={index} index={index} {...transitInfo} />
        ))}
      </View>
    </View>
  );
}

type InfoProps = AdditionalTransitInfo & {
  index: number;
};

export function TransitInfoContent(props: InfoProps) {
  const { index, type, content } = props;

  switch (type) {
    case 'text':
      return (
        <Text
          variant="ui-small"
          ink="secondary"
          style={[index > 0 && Style.transitText]}
        >
          {content}
        </Text>
      );
    case 'list':
      return (
        <View style={Style.transitList}>
          <Icon src={CheckIcon} width={24} height={24} />
          <Text
            variant="ui-small"
            ink="secondary"
            style={Style.transitListContent}
          >
            {content}
          </Text>
        </View>
      );
    case 'divide':
      return (
        <>
          <Divider spacing="xs" />
          <Text
            variant="ui-small"
            ink="secondary"
            style={[index > 0 && Style.transitText]}
          >
            {content}
          </Text>
        </>
      );
  }
}

const Style = StyleSheet.create({
  journey: {
    marginTop: Token.spacing.l,
    flexDirection: 'row',
  },
  airlineAndSegment: {
    flex: 1,
    marginRight: Token.spacing.m,
  },
  airlineAndSegmentNormal: {
    flexDirection: 'row',
  },

  // Airline Info
  airlineInfoNormal: {
    width: 160,
    marginRight: Token.spacing.m,
  },
  airlineInfoCompact: {
    marginBottom: Token.spacing.m,
  },
  airlineLogo: {
    marginBottom: Token.spacing.s,
  },
  airlineText: {
    marginTop: Token.spacing.xxs,
  },

  // Segment Info
  segmentRow: {
    flexDirection: 'row',
  },
  segmentLineCol: {
    alignItems: 'center',
    width: Token.spacing.xs,
    marginRight: Token.spacing.xs,
  },
  segmentCircle: {
    width: Token.spacing.xs,
    height: Token.spacing.xs,
    borderWidth: Token.border.width.thick,
    borderRadius: Token.border.radius.rounded,
    marginVertical: 6,
  },
  segmentLine: {
    flex: 1,
    width: Token.border.width.thick,
  },
  segmentDateTime: {
    width: 96,
  },
  segmentLocation: {
    flex: 1,
  },
  segmentDuration: {
    width: 96,
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: Token.spacing.m,
  },
  segmentDurationText: {
    flex: 1,
    marginLeft: Token.spacing.xs,
  },
  segmentStopOver: {
    flex: 1,
    justifyContent: 'center',
  },

  // Facility Info
  facilityInfoNormal: {
    width: 272,
  },
  facilityInfoCompact: {
    width: 212,
  },
  facility: {
    marginBottom: Token.spacing.xs,
  },

  // Transit Info
  transit: {
    marginVertical: Token.spacing.m,
    flexDirection: 'row',
    borderRadius: Token.border.radius.normal,
    padding: Token.spacing.m,
  },
  transitNormal: {
    marginLeft: 176,
  },
  additionalTransit: {
    flex: 1,
    marginLeft: Token.spacing.m,
  },
  transitText: {
    marginTop: Token.spacing.xxs,
  },
  transitList: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  transitListContent: {
    marginLeft: Token.spacing.xs,
  },
});

export const FEATURE_CONTROL_API =
  '/v2/content/featurecontrol/getfeatureconfigweb';

export type FeatureControlResponse = {
  features: Record<
    string,
    {
      enabled: string;
      properties: Object;
    }
  >;
};

import React, { PropsWithChildren } from 'react';

import { StyleSheet, View } from 'react-native';
import { Popup, Token, useTheme } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

type Props = PropsWithChildren<{
  visible: boolean;
  onCloseModal(): void;
}>;

function DetailCardModal(props: Props) {
  const { visible, onCloseModal, children } = props;

  const { color } = useTheme();

  const scrollStyle = {
    backgroundColor: color.lightPrimary,
  };

  return (
    <Modal isVisible={visible} onBackdropPress={onCloseModal}>
      <View style={[Style.scroll, scrollStyle]}>
        <Popup
          width={650}
          maxWidth={650}
          showCloseButton={true}
          onCloseButtonPress={onCloseModal}
        >
          <View style={Style.container}>{children}</View>
        </Popup>
      </View>
    </Modal>
  );
}

const Style = StyleSheet.create({
  container: {
    marginTop: -Token.spacing.l,
  },
  scroll: {
    flexShrink: 1,
    flexGrow: 0,
    borderRadius: Token.border.radius.normal,
    overflowY: 'auto',
  },
});

export default DetailCardModal;

import React, {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  PropsWithChildren,
} from 'react';

import AccomodationType from 'hotel/shared/constants/AccomodationType';
import SortType from 'hotel/shared/constants/SortType';

type State = {
  isFiltered: boolean;
  sort: SortType;
  minPrice: number | null;
  maxPrice: number | null;
  stars: number[];
  accommodationTypes: AccomodationType[];
  page: number;
};

type Action =
  | { type: 'reset' }
  | { type: 'setSort'; sort: SortType }
  | { type: 'setMinPrice'; minPrice: number | null }
  | { type: 'setMaxPrice'; maxPrice: number | null }
  | { type: 'toggleStar'; star: number }
  | { type: 'toggleAccommodationType'; accommodationType: AccomodationType }
  | { type: 'setPage'; page: number };

const SortFilterStateContext = createContext<[State, Dispatch<Action>]>(
  undefined!
);
SortFilterStateContext.displayName = 'SortFilterStateContext';

const initialState: State = {
  isFiltered: false,
  sort: SortType.POPULARITY,
  minPrice: null,
  maxPrice: null,
  stars: [],
  accommodationTypes: [],
  page: 1,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'toggleStar': {
      const { stars: prevStars } = state;

      let stars;
      if (prevStars.includes(action.star)) {
        stars = prevStars.filter(type => type !== action.star);
      } else {
        stars = prevStars.concat(action.star);
      }

      return { ...state, isFiltered: true, stars };
    }
    case 'toggleAccommodationType': {
      const { accommodationTypes: prevAccommodationTypes } = state;

      let accommodationTypes;
      if (prevAccommodationTypes.includes(action.accommodationType)) {
        accommodationTypes = prevAccommodationTypes.filter(
          type => type !== action.accommodationType
        );
      } else {
        accommodationTypes = prevAccommodationTypes.concat(
          action.accommodationType
        );
      }

      return { ...state, isFiltered: true, accommodationTypes };
    }
    case 'setSort':
    case 'setMinPrice':
    case 'setMaxPrice':
    case 'setPage': {
      const { type, ...rest } = action;

      return { ...state, isFiltered: true, ...rest };
    }
  }
}

export function SortFilterProvider(props: PropsWithChildren<{}>) {
  const state = useReducer(reducer, initialState);

  return (
    <SortFilterStateContext.Provider value={state}>
      {props.children}
    </SortFilterStateContext.Provider>
  );
}

export function useSortFilter() {
  return useContext(SortFilterStateContext);
}

import React, { ComponentProps } from 'react';

import { Link } from '@traveloka/ctvweb-ui';
import { Anchor } from '@traveloka/web-components/future';

import { useLocale } from '../LocaleContext';

type AnchorProps = ComponentProps<typeof Anchor>;

type Props = AnchorProps & {
  to: string;
  replace?: boolean;
};

export default function LocalizedLink(props: Props) {
  const { to, ...rest } = props;
  const { locale } = useLocale();

  return <Link to={'/' + locale + to} {...rest} />;
}

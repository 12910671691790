import { createContext, useContext } from 'react';

import { Props } from '../FlightSearchResultItem';

const PropsContext = createContext<Props | undefined>(undefined);

export const PropsProvider = PropsContext.Provider;

export function useProps() {
  const value = useContext(PropsContext);

  if (value === undefined) {
    throw new Error('`useProps` could not find any provider');
  }

  return value;
}

// Flight
export function validatePnrCode(value?: string) {
  return !!value && !/^[a-zA-Z0-9]{6}$/.test(value);
}

// Hotel
export function validateTripId(value?: string) {
  return !!value && !/^[0-9]{19}$/.test(value);
}

export function validateRefundReason(value?: string) {
  return !!value && value.length > 160;
}

import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useSortFilter } from 'hotel/search/contexts/SortFilterContext';
import SortType from 'hotel/shared/constants/SortType';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Divider } from '@traveloka/ctvweb-ui';
import { Card, Radio, RadioDisc, Text, Token } from '@traveloka/web-components';
import { ButtonComponentProps as RadioComponentProps } from '@traveloka/web-components/src/momentum/RadioGroup/Radio';

type Props = {
  style?: StyleProp<ViewStyle>;
};

export default function SortBox(props: Props) {
  const { style } = props;

  const [{ sort }, dispatch] = useSortFilter();

  const content = useContentResource().CorporateHotelSearchSort;

  const sortTypes = [
    { value: SortType.HIGHEST_PRICE, label: content.highPriceText },
    { value: SortType.LOWEST_PRICE, label: content.lowestPriceText },
    { value: SortType.REVIEW, label: content.reviewScoreText },
    { value: SortType.POPULARITY, label: content.populatiryText },
  ];

  return (
    <Card style={style}>
      <View style={Style.content}>
        <Text>{content.sortTitle}</Text>
        <Text variant="ui-small" ink="secondary">
          {content.sortInfoText}
        </Text>
      </View>
      <Divider margin="none" subtle />
      <Radio.Group
        labelID="hotel.search.sort"
        style={Style.radioGroup}
        value={sort}
        onChange={value =>
          dispatch({ type: 'setSort', sort: value as SortType })
        }
      >
        {sortTypes.map(type => (
          <Radio.Button
            testID={`hotel.search.sort.${type.value}`}
            key={type.value}
            label={type.label}
            value={type.value}
            containerStyle={Style.radioButton}
            ButtonComponent={SortRadioButton}
          />
        ))}
      </Radio.Group>
    </Card>
  );
}

function SortRadioButton(props: RadioComponentProps) {
  const { isFocused, isSelected, label } = props;

  return (
    <>
      <RadioDisc isSelected={isSelected} isFocused={isFocused} />
      <Text variant="ui-small" style={Style.label}>
        {label}
      </Text>
    </>
  );
}

const Style = StyleSheet.create({
  content: {
    padding: Token.spacing.m,
  },
  radioGroup: {
    padding: Token.spacing.m,
    paddingBottom: Token.spacing.xs,
  },
  radioButton: {
    marginBottom: Token.spacing.xs,
  },
  label: {
    marginLeft: Token.spacing.s,
  },
});

import React from 'react';
import { View, ViewProps } from 'react-native';

import { useTheme, Checkbox, Icon, Text } from '@traveloka/web-components';

import {
  useFlightSearchComplianceFilterContext,
  useFlightSearchOptionContext,
} from 'flight/search/contexts/FlightSortFilterContext';

import { getThemeStyle, CommonStyle } from '../filter-search-toolbar.style';
import { useContentResource } from '@traveloka/ctv-core';

type Props = {
  style?: ViewProps['style'];
};

function MoreComplianceFilter(props: Props) {
  const { style } = props;

  const content = useContentResource().CorporateFlightSearchToolbar;
  const ThemeStyle = getThemeStyle(useTheme());
  const { filterOption, setFilter } = useFlightSearchOptionContext();
  const values = useFlightSearchComplianceFilterContext();

  return (
    <View style={style}>
      <Text ink="primary" variant="ui-small">
        {content.complianceTitle}
      </Text>
      <View style={[CommonStyle.container, ThemeStyle.container]}>
        {filterOption.compliance.map(option => (
          <View key={option.value} style={CommonStyle.checkbox}>
            <Checkbox
              checked={values.includes(option.value)}
              disabled={option.disabled}
              onChange={() => setFilter('compliance', option.value)}
            >
              <Checkbox.Control />
              <View style={CommonStyle.checkboxTextJustify}>
                <Text
                  ink={option.disabled ? 'muted' : 'primary'}
                  variant="ui-small"
                >
                  {content[option.value]}
                </Text>
                <Icon src="" />
              </View>
            </Checkbox>
          </View>
        ))}
      </View>
    </View>
  );
}

export default MoreComplianceFilter;

import React, { ReactNode, createContext, useContext } from 'react';

import useFlightSortFilter from '../hooks/use-flight-sort-filter';
import { FlightSearchData } from '../utils/flight-search-view-util';
import { useStaticData } from './StaticDataContext';

type SortFilter = ReturnType<typeof useFlightSortFilter>;
type FilterValue = SortFilter['filterValue'];

type SortFilterOption = {
  filterOption: SortFilter['filterOption'];
  sortOptions: SortFilter['sortOptions'];
  setFilter: SortFilter['setFilter'];
  setSort: SortFilter['setSort'];
};

const OptionContext = createContext<SortFilterOption>(null!);
const ResultContext = createContext<FlightSearchData[]>(null!);
const TransitCountFilterContext = createContext<FilterValue['transitCount']>(
  null!
);
const TransitDurationFilterContext = createContext<
  FilterValue['transitDuration']
>(null!);
const TransitPointFilterContext = createContext<FilterValue['transitPoint']>(
  null!
);
const DepartureTimeFilterContext = createContext<FilterValue['departureTime']>(
  null!
);
const ArrivalTimeFilterContext = createContext<FilterValue['arrivalTime']>(
  null!
);
const AirlineFilterContext = createContext<FilterValue['airline']>(null!);
const PriceFilterContext = createContext<FilterValue['price']>(null!);
const ComplianceFilterContext = createContext<FilterValue['compliance']>(null!);
const FacilityFilterContext = createContext<FilterValue['facility']>(null!);
const PreferenceFilterContext = createContext<FilterValue['preference']>(null!);
const SortContext = createContext<SortFilter['sortValue']>(null!);

type Props = {
  results: FlightSearchData[];
  children: ReactNode;
};

export function FlightSortFilterProvider(props: Props) {
  const { children, results } = props;

  const staticData = useStaticData();
  const {
    results: newResults,
    sortValue,
    setSort,
    filterValue,
    setFilter,
    sortOptions,
    filterOption,
  } = useFlightSortFilter(results, staticData);

  const optionContextValue = {
    filterOption,
    sortOptions,
    setFilter,
    setSort,
  };

  // Lol pyramid
  return (
    <OptionContext.Provider value={optionContextValue}>
      <ResultContext.Provider value={newResults}>
        <TransitCountFilterContext.Provider value={filterValue.transitCount}>
          <TransitDurationFilterContext.Provider
            value={filterValue.transitDuration}
          >
            <TransitPointFilterContext.Provider
              value={filterValue.transitPoint}
            >
              <DepartureTimeFilterContext.Provider
                value={filterValue.departureTime}
              >
                <ArrivalTimeFilterContext.Provider
                  value={filterValue.arrivalTime}
                >
                  <AirlineFilterContext.Provider value={filterValue.airline}>
                    <ComplianceFilterContext.Provider
                      value={filterValue.compliance}
                    >
                      <PriceFilterContext.Provider value={filterValue.price}>
                        <FacilityFilterContext.Provider
                          value={filterValue.facility}
                        >
                          <PreferenceFilterContext.Provider
                            value={filterValue.preference}
                          >
                            <SortContext.Provider value={sortValue}>
                              {children}
                            </SortContext.Provider>
                          </PreferenceFilterContext.Provider>
                        </FacilityFilterContext.Provider>
                      </PriceFilterContext.Provider>
                    </ComplianceFilterContext.Provider>
                  </AirlineFilterContext.Provider>
                </ArrivalTimeFilterContext.Provider>
              </DepartureTimeFilterContext.Provider>
            </TransitPointFilterContext.Provider>
          </TransitDurationFilterContext.Provider>
        </TransitCountFilterContext.Provider>
      </ResultContext.Provider>
    </OptionContext.Provider>
  );
}

export function useFlightSearchOptionContext() {
  return useContext(OptionContext);
}
export function useFlightSearchResultContext() {
  return useContext(ResultContext);
}
export function useFlightSearchTransitCountFilterContext() {
  return useContext(TransitCountFilterContext);
}
export function useFlightSearchTransitDurationFilterContext() {
  return useContext(TransitDurationFilterContext);
}
export function useFlightSearchTransitPointFilterContext() {
  return useContext(TransitPointFilterContext);
}
export function useFlightSearchDepartureTimeFilterContext() {
  return useContext(DepartureTimeFilterContext);
}
export function useFlightSearchArrivalTimeFilterContext() {
  return useContext(ArrivalTimeFilterContext);
}
export function useFlightSearchAirlineFilterContext() {
  return useContext(AirlineFilterContext);
}
export function useFlightSearchComplianceFilterContext() {
  return useContext(ComplianceFilterContext);
}
export function useFlightSearchPriceFilterContext() {
  return useContext(PriceFilterContext);
}
export function useFlightSearchFacilityFilterContext() {
  return useContext(FacilityFilterContext);
}
export function useFlightSearchPreferenceFilterContext() {
  return useContext(PreferenceFilterContext);
}
export function useFlightSearchSortContext() {
  return useContext(SortContext);
}

import React, { useCallback, useRef, useState } from 'react';
import {
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import { Divider } from '@traveloka/ctvweb-ui';
import Hotel from '@traveloka/icon-kit-web/svg/blue/ic_product_hotel-fill_24px.svg';
import Chevron from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';
import Godwit from '@traveloka/icon-kit-web/svg/dark/ic_brand_traveloka_24px.svg';
import Location from '@traveloka/icon-kit-web/svg/dark/ic_system_map_location-fill_24px.svg';
import CleanStay from '@traveloka/icon-kit-web/svg/greenPrimary/ic_symbol_clean_stay-fill_24px.svg';
import {
  useTheme,
  Badge,
  Card,
  Icon,
  Image,
  Text,
  Token,
} from '@traveloka/web-components';
import { useHoverable } from '@traveloka/web-components/future';

import ComplianceLabel from '../../shared/components/ComplianceLabel/ComplianceLabel';
import { appendTestId } from '../../shared/utils/TestUtil';
import StarRating from '../StarRating/StarRating';

type Props = {
  complianceLabel: string;
  hasCleanStay: boolean;
  image?: string;
  isComplying: boolean;
  location: string;
  mainPrice: string;
  name: string;
  onPress(): void;
  originalPrice?: string;
  priceBreakdown: Array<'separator' | PriceBreakdownRowProps>;
  propertyType: string;
  rating?: string;
  star: number;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  toggleLabel: string;
  totalPayment: string;
  totalPaymentLabel: string;
};

export default function PropertyCard(props: Props) {
  const {
    complianceLabel,
    hasCleanStay,
    image,
    isComplying,
    location,
    mainPrice,
    name,
    onPress,
    originalPrice,
    priceBreakdown,
    propertyType,
    rating,
    star,
    style,
    testID,
    toggleLabel,
    totalPayment,
    totalPaymentLabel,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const contentHeightRef = useRef(0);
  const { color } = useTheme();

  const accentStyle = {
    backgroundColor: color.tintPrimary,
    transform: [{ scaleY: isCollapsed ? 0 : 2 }],
  };
  const breakdownStyle = {
    backgroundColor: color.lightNeutral,
    height: isCollapsed ? 0 : contentHeightRef.current,
  };
  const breakdownTotalStyle = {
    backgroundColor: color.darkSecondary,
  };
  const rotateStyle = {
    transform: [{ rotate: isCollapsed ? '0deg' : '-180deg' }],
  };
  const shiftingStyle = {
    opacity: Number(!isCollapsed),
    transform: [{ translateY: isCollapsed ? Token.spacing.xs : 0 }],
  };

  const handleBreakdownToggle = useCallback(() => setIsCollapsed(s => !s), [
    setIsCollapsed,
  ]);

  const handleLayout = useCallback((e: LayoutChangeEvent) => {
    contentHeightRef.current = e.nativeEvent.layout.height;
  }, []);

  const [isHovered, hoverEvent] = useHoverable();

  return (
    <Card
      elevation={isHovered ? 'float' : 'raised'}
      style={[Style.card, style]}
    >
      <TouchableOpacity
        {...hoverEvent}
        testID={appendTestId(testID, 'summary')}
        activeOpacity={0.5}
        onPress={onPress}
        style={Style.summary}
      >
        <PropertyImage src={image} />
        <View style={Style.content}>
          <View style={Style.hotelName}>
            <Text>{name}</Text>
            {hasCleanStay && <Icon src={CleanStay} />}
          </View>
          <View style={Style.tagStar}>
            <Badge variant="info" text={propertyType} />
            <StarRating star={star} style={Style.star} />
          </View>
          <View style={Style.iconLabel}>
            <Icon src={Location} width={16} height={16} />
            <Text
              variant="ui-tiny"
              ink="secondary"
              style={Style.iconLabelText}
              numberOfLines={1}
            >
              {location}
            </Text>
          </View>
          {rating && (
            <View style={Style.iconLabel}>
              <Icon src={Godwit} width={16} height={16} />
              <Text
                variant="ui-tiny"
                ink="highlight"
                style={Style.iconLabelText}
              >
                {rating}
              </Text>
            </View>
          )}
        </View>
        <Divider margin="none" />
        <View style={Style.priceContainer}>
          {!isComplying && (
            <ComplianceLabel
              variant="rounded"
              text={complianceLabel}
              style={Style.compliance}
            />
          )}
          <View style={Style.price}>
            {typeof originalPrice === 'string' && (
              <Text
                testID={appendTestId(testID, 'summary.original-price')}
                variant="ui-tiny"
                ink="muted"
                style={Style.originPrice}
              >
                {originalPrice}
              </Text>
            )}
            <Text
              testID={appendTestId(testID, 'summary.main-price')}
              variant="ui-large"
              ink="price"
              style={Style.mainPrice}
            >
              {mainPrice}
            </Text>

            <TouchableOpacity
              testID={appendTestId(testID, 'summary.toggle')}
              activeOpacity={0.5}
              onPress={handleBreakdownToggle}
              style={Style.toggle}
            >
              <Text
                variant="ui-tiny"
                ink="primary-interactive"
                style={Style.toggleText}
              >
                {toggleLabel}
              </Text>
              <View style={[Style.chevron, rotateStyle]}>
                <Icon src={Chevron} />
              </View>
            </TouchableOpacity>

            <View style={Style.accentContainer}>
              <View style={[Style.accent, accentStyle]} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
      <View style={[Style.priceBreakdown, breakdownStyle]}>
        <View
          onLayout={handleLayout}
          style={[Style.breakdownCollapsible, shiftingStyle]}
        >
          <View style={Style.breakdownSection}>
            {priceBreakdown.map((price, index) => {
              if (typeof price === 'string') {
                return <Divider key={index} spacing="s" />;
              }

              return <PriceBreakdownRow key={index} {...price} />;
            })}
          </View>
          <View style={[Style.breakdownSection, breakdownTotalStyle]}>
            <View style={Style.breakdownRow}>
              <Text variant="ui-small" ink="white">
                {totalPaymentLabel}
              </Text>
              <Text
                testID={appendTestId(testID, 'price-breakdown.total')}
                variant="ui-small"
                ink="white"
                style={Style.breakdownPrice}
              >
                {totalPayment}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Card>
  );
}

type PropertyImageProps = {
  src?: string;
};

function PropertyImage(props: PropertyImageProps) {
  const { src } = props;
  const { color } = useTheme();

  const placeholderStyle = {
    backgroundColor: color.lightStain,
  };

  const [isError, setIsError] = useState(false);
  const handleError = useCallback(() => setIsError(true), [setIsError]);

  if (!src || isError) {
    return (
      <View style={[Style.placeholder, placeholderStyle]}>
        <Icon src={Hotel} />
      </View>
    );
  }

  return (
    <Image src={src} width={132} objectFit="cover" onError={handleError} />
  );
}

type PriceBreakdownRowProps = {
  label: string;
  value: string;
  isPositive?: true;
};

function PriceBreakdownRow(props: PriceBreakdownRowProps) {
  const { label, value, isPositive } = props;
  const ink = isPositive && 'positive';

  return (
    <View style={Style.breakdownRow}>
      <Text variant="ui-small" ink={ink}>
        {label}
      </Text>
      <Text variant="ui-small" ink={ink} style={Style.breakdownPrice}>
        {value}
      </Text>
    </View>
  );
}

const animateTransform = {
  transitionProperty: 'transform',
  transitionDuration: `${Token.animation.timing.instant}ms`,
};

const Style = StyleSheet.create({
  card: {
    ...animateTransform,
    // @ts-ignore
    transitionProperty: 'box-shadow',
  },
  summary: {
    flexDirection: 'row',
    minHeight: 176,
  },
  placeholder: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 132,
  },
  content: {
    padding: Token.spacing.m,
    flex: 1,
    alignItems: 'flex-start',
  },
  hotelName: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: Token.spacing.xxs,
  },
  compliance: {
    margin: Token.spacing.xxs,
    maxWidth: 152,
  },
  tagStar: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Token.spacing.m,
  },
  star: {
    marginLeft: Token.spacing.xxs,
  },
  iconLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Token.spacing.xxs,
    maxWidth: '100%',
  },
  iconLabelText: {
    marginLeft: Token.spacing.xs,
    flex: 1,
  },
  priceContainer: {
    justifyContent: 'space-between',
  },
  price: {
    padding: Token.spacing.m,
    paddingBottom: 0,
    width: 160,
  },
  originPrice: {
    textDecorationLine: 'line-through',
  },
  mainPrice: {
    marginBottom: Token.spacing.xxs,
  },
  accentContainer: {
    height: Token.border.width.bold,
    justifyContent: 'flex-end',
  },
  accent: {
    ...animateTransform,
    height: 1,
    // @ts-ignore
    transformOrigin: 'bottom',
  },
  toggle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: Token.spacing.xs,
  },
  toggleText: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  chevron: {
    ...animateTransform,
    justifyContent: 'center',
    marginLeft: Token.spacing.xs,
  },
  priceBreakdown: {
    ...animateTransform,
    overflow: 'hidden',
    // @ts-ignore
    transitionProperty: 'height',
  },
  breakdownCollapsible: {
    ...animateTransform,
    // @ts-ignore
    transitionProperty: 'opacity transform',
  },
  breakdownSection: {
    padding: Token.spacing.m,
    paddingLeft: 148,
  },
  breakdownRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  breakdownPrice: {
    marginLeft: Token.spacing.xs,
    // @ts-ignore
    whiteSpace: 'nowrap',
  },
});

import React, { useCallback, useMemo, useState } from 'react';

import {
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { Token } from '@traveloka/web-components';

import { NonEmployeeType } from 'flight/shared/api/types';
import {
  ONE_WAY_SEPARATOR,
  ROUND_TRIP_SEPARATOR,
} from 'flight/search/constants/flight-search-constant';
import { useNonEmployeeFormContent } from 'flight/prebook/components/NonEmployeeTravelerForm/NonEmployeeTravelerForm';
import { usePaymentStateFlight } from 'payment/select/contexts/PaymentContext';
import { JAVA_DATE_ALT } from 'shared/utils/date';

import DetailCardModal from '../DetailCardModal/DetailCardModal';
import FlightDetailCardModal from '../DetailCardModal/FlightDetailCardModal';
import DetailCard from './DetailCard';

export default function FlightDetailCard() {
  const [isVisible, setIsVisible] = useState(false);
  const showModal = useCallback(() => setIsVisible(true), []);
  const hideModal = useCallback(() => setIsVisible(false), []);

  const { changeFormat } = useLocalizedDateFormat();
  const content = useContentResource().CorporateFlightPayment;
  const nonEmployeeContent = useNonEmployeeFormContent();
  const titleMap = useContentResource().CorporateEnumTravelerTitle;

  const { booking } = usePaymentStateFlight();
  const { flightBookingDetail, tripId } = booking;

  const { journeys, passengers } = flightBookingDetail;

  const travelers = useMemo(
    () => [
      ...passengers.adults.map((passenger, index) => ({
        name: [
          titleMap[passenger.title],
          passenger.firstName,
          passenger.lastName,
        ].join(' '),
        subtitle: `${nonEmployeeContent(NonEmployeeType.ADULT).type} ${index +
          1}${
          passenger.division
            ? ` - ${passenger.division}`
            : !passenger.isEmployee
            ? ` (${content.adultAgeText})`
            : ''
        }`,
      })),
      ...passengers.children.map((passenger, index) => ({
        name: [
          titleMap[passenger.title],
          passenger.firstName,
          passenger.lastName,
        ].join(' '),
        subtitle: `${nonEmployeeContent(NonEmployeeType.CHILD).type} ${index +
          1} (${content.childAgeText})`,
      })),
      ...passengers.infants.map((passenger, index) => ({
        name: [
          titleMap[passenger.title],
          passenger.firstName,
          passenger.lastName,
        ].join(' '),
        subtitle: `${nonEmployeeContent(NonEmployeeType.INFANT).type} ${index +
          1} (${content.infantAgeText})`,
      })),
    ],
    [passengers]
  );

  const productBreakdown = useMemo(() => {
    const { 0: firstJourney, [journeys.length - 1]: lastJourney } = journeys;

    const { departureDetail } = firstJourney;
    const { arrivalDetail } = lastJourney;

    const route = [
      departureDetail.airportCode,
      firstJourney.arrivalDetail.airportCode,
    ].join(journeys.length === 1 ? ONE_WAY_SEPARATOR : ROUND_TRIP_SEPARATOR);

    const date =
      journeys.length === 1
        ? changeFormat(
            departureDetail.departureDate,
            JAVA_DATE_ALT,
            'SHORT_MONTH'
          )
        : [
            changeFormat(
              departureDetail.departureDate,
              JAVA_DATE_ALT,
              'SHORT_MONTH'
            ),
            changeFormat(
              arrivalDetail.arrivalDate,
              JAVA_DATE_ALT,
              'SHORT_MONTH'
            ),
          ].join(' - ');

    return [
      {
        testID: 'confirmation-payment.trip-detail.flight.date',
        label: date,
      },
      {
        testID: 'confirmation-payment.trip-detail.flight.route',
        label: route,
      },
    ];
  }, [journeys]);

  return (
    <>
      <DetailCard
        productBreakdown={productBreakdown}
        onDetailPress={showModal}
        // @ts-ignore
        productColor={Token.color.brandFuchsiaFusion}
        productName={content.productNameText}
        travelers={travelers}
        travelersLabel={content.travelersLabelText}
        tripId={tripId}
      />
      <DetailCardModal visible={isVisible} onCloseModal={hideModal}>
        <FlightDetailCardModal />
      </DetailCardModal>
    </>
  );
}

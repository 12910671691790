import { content } from '@traveloka/ctv-core/resource/payload';

import { formatMessage } from 'shared/utils/intl';

// Referring to this document: https://docs.google.com/spreadsheets/d/1jJIMAO6v42OY3YFcDT4fAvKyeASdW4COElp4ky4O7V8/edit?gid=0#gid=0
export type FlightErrorMessage = {
  title: string;
  message: string;
  action?(): void;
};
export default function getFlightBookingErrorMessage(
  bookingStatus: string,
  cr: typeof content['CorporateFlightBookingErrorMessages'],
  action?: () => void
): FlightErrorMessage {
  function generateDescWithEnum(cr: string) {
    return formatMessage(cr, {
      errorEnum: bookingStatus,
    });
  }

  switch (bookingStatus) {
    case 'BOOKING_SPEC_NOT_ALLOWED':
      return {
        title: cr.bookingSpecNotAllowedTitle,
        message: generateDescWithEnum(cr.bookingSpecNotAllowedDesc),
        action,
      };
    case 'INAPPROPRIATE_WORDS':
      return {
        title: cr.inappropriateWordsTitle,
        message: generateDescWithEnum(cr.inappropriateWordsDesc),
      };
    case 'FLIGHT_NOT_AVAILABLE':
      return {
        title: cr.flightNotAvailableTitle,
        message: cr.flightNotAvailableDesc,
      };
    case 'TIMEOUT':
      return {
        title: cr.timeoutTitle,
        message: generateDescWithEnum(cr.timeoutDesc),
      };
    case 'GENERAL_ERROR':
      return {
        title: cr.generalErrorTitle,
        message: generateDescWithEnum(cr.generalErrorDesc),
      };
    case 'RESERVATION_SYSTEM_ERROR':
      return {
        title: cr.reservationSystemErrorTitle,
        message: generateDescWithEnum(cr.reservationSystemErrorDesc),
      };
    default:
      return {
        title: cr.fallbackErrorTitle,
        message: generateDescWithEnum(cr.fallbackErrorDesc),
      };
  }
}

import React from 'react';
import { StyleSheet } from 'react-native';

import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Token } from '@traveloka/web-components';

import ApprovalDetail from 'approval-system/approval-detail/ApprovalDetail';
import ApprovalList from 'approval-system/approval-list/ApprovalList';
import CreateApproval from 'approval-system/create-approval/CreateApproval';
import RedirectHome from 'generic/RedirectHome';
import BookingDetail from 'postbooking/booking-detail/BookingDetail';
import BookingList from 'postbooking/booking-list/BookingList';
import PurchaseDetail from 'postbooking/purchase-detail/PurchaseDetail';
import PurchaseList from 'postbooking/purchase-list/PurchaseList';
import ProductType from 'postbooking/shared/constants/ProductType';
import PrivateRoute from 'shared/auth/PrivateRoute';

import CompanyApprover from './approver/CompanyApprover';
import CompanyAttachmentList from './attachment-list/CompanyAttachmentList';
import ManageCard from './cards/ManageCard';
import CompanyEmployee from './employee/CompanyEmployee';
import CompanyExpenseReport from './expense-report/CompanyExpenseReport';
import CompanyInvoiceList from './invoice-list/CompanyInvoiceList';
import CompanyPolicy from './policy/CompanyPolicy';
import CompanyAccessRole from './role/CompanyAccessRole';
import CompanyStructure from './structure/CompanyStructure';

type Props = RouteComponentProps;

const enabledProductQuery = [ProductType.FLIGHT, ProductType.HOTEL].join('|');
export default function CompanyApp(props: Props) {
  const { match } = props;

  return (
    <Switch>
      <PrivateRoute
        path={`${match.path}/structure`}
        exact
        component={CompanyStructure}
      />
      <PrivateRoute
        path={`${match.path}/structure/policy/:policyId?`}
        exact
        component={CompanyPolicy}
      />
      <PrivateRoute
        path={`${match.path}/approver`}
        exact
        component={CompanyApprover}
      />
      <PrivateRoute
        path={`${match.path}/approval`}
        exact
        component={ApprovalList}
      />
      <PrivateRoute
        path={`${match.path}/approval/create`}
        exact
        component={CreateApproval}
      />
      <PrivateRoute
        path={`${match.path}/approval/:requestId`}
        exact
        component={ApprovalDetail}
      />
      <PrivateRoute
        path={`${match.path}/employee`}
        exact
        component={CompanyEmployee}
      />
      <PrivateRoute
        path={`${match.path}/structure/role/:roleId?`}
        exact
        component={CompanyAccessRole}
      />
      <PrivateRoute
        path={`${match.path}/booking`}
        exact
        component={BookingList}
      />
      <PrivateRoute
        path={`${match.path}/booking/:productType(${enabledProductQuery})/:bookingId/:index`}
        exact
        component={BookingDetail}
      />
      <PrivateRoute
        path={`${match.path}/purchase`}
        exact
        component={PurchaseList}
      />
      <PrivateRoute
        path={`${match.path}/purchase/:tripId`}
        exact
        component={PurchaseDetail}
      />
      <PrivateRoute
        path={`${match.path}/manage/cards`}
        exact
        component={ManageCard}
      />
      <PrivateRoute
        path={`${match.path}/expense-report`}
        exact
        component={CompanyExpenseReport}
      />
      <PrivateRoute
        path={`${match.path}/attachment-list`}
        exact
        component={CompanyAttachmentList}
      />
      <PrivateRoute
        path={`${match.path}/invoice`}
        exact
        component={CompanyInvoiceList}
      />
      <Route component={RedirectHome} />
    </Switch>
  );
}

const Style = StyleSheet.create({
  menu: {
    minWidth: 240,
  },
  smallColumn: {
    width: 240,
  },
  bigColumn: {
    marginLeft: Token.spacing.l,
  },
});

import { useContentResource } from '@traveloka/ctv-core';
import { useMemo } from 'react';

export default function useStarRatingSwitcher(
  score: number
): string | undefined {
  const content = useContentResource().CorporateHotelStarRating;

  return useMemo(() => {
    if (!score) {
      return undefined;
    }

    if (score > 9) {
      return content.superbRatingText;
    } else if (score > 8.25) {
      return content.impressiveRatingText;
    } else if (score > 7) {
      return content.convenientRatingText;
    } else if (score > 6) {
      return content.goodRatingText;
    } else {
      return content.acceptableRatingText;
    }
  }, []);
}

import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import { Token, Text } from '@traveloka/web-components';

import { CreditLimitStatus } from 'credit-limit/context';
import {
  creditLimitColorSwitcher,
  creditLimitPercentageInkSwitcher,
} from 'credit-limit/shared/utils/StatusSwitcher';

type Props = {
  style?: StyleProp<ViewStyle>;
  status: CreditLimitStatus;
  percentage: string;
  testID?: string;
};

function CreditLimitBadge(props: Props) {
  const { style, status, percentage, testID } = props;

  const color = {
    backgroundColor: creditLimitColorSwitcher(status),
  };

  const textInk = creditLimitPercentageInkSwitcher(status);

  return (
    <View
      testID={appendTestId(testID, `credit-limit.badge.${status}`)}
      style={[Style.badge, color, style]}
    >
      <Text style={textInk} variant="ui-tiny">
        {percentage}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  badge: {
    paddingVertical: Token.spacing.xxs,
    paddingHorizontal: Token.spacing.s,
    borderRadius: Token.border.radius.rounded,
    width: 'fit-content',
  },
});

export default CreditLimitBadge;

import React from 'react';
import {
  StyleSheet,
  TouchableWithoutFeedbackProps,
  View,
  ViewProps,
} from 'react-native';

import { Button, Text, Token } from '@traveloka/web-components';

type Props = {
  style?: ViewProps['style'];
  title: string;
  subtitle: string;
  pax: string;
  room: string;
  onPress?: TouchableWithoutFeedbackProps['onPress'];
  button: string;
};

export default function SearchHeader(props: Props) {
  const { style, title, subtitle, pax, room, onPress, button } = props;

  return (
    <View style={style}>
      <Text variant="title-1" style={Style.title}>
        {title}
      </Text>
      <View style={Style.detail}>
        <View>
          <Text variant="ui-small" ink="secondary">
            {subtitle}
          </Text>
          <View style={Style.bottomInfo}>
            <Text variant="ui-small" ink="secondary">
              {pax}
            </Text>
            <Text variant="ui-small" ink="secondary" style={Style.leftMargin}>
              |
            </Text>
            <Text variant="ui-small" ink="secondary" style={Style.leftMargin}>
              {room}
            </Text>
          </View>
        </View>
        <Button style={Style.button} text={button} onPress={onPress} />
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  title: {
    marginBottom: Token.spacing.l,
  },
  detail: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomInfo: {
    flexDirection: 'row',
    marginTop: Token.spacing.xxs,
  },
  leftMargin: {
    marginLeft: Token.spacing.m,
  },
  button: {
    alignSelf: 'flex-end',
  },
});

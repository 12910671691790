import { Country } from '@traveloka/ctv-core/auth/types';
import { getCompanyCountry } from '@traveloka/ctv-core/locale/utils/country';

export function getDefaultFlightRoute(defaultRoute: string) {
  const companyCountry = getCompanyCountry();

  switch (companyCountry) {
    case Country.ID:
      return 'JKTA.DPS';
    case Country.TH:
      return 'BKKA.HKT';
    case Country.VN:
      return 'SGN.HAN';
    default:
      return defaultRoute;
  }
}

import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useApi } from '@traveloka/ctv-core';
import { ApiResult } from '@traveloka/ctv-core/api/types';
import {
  useContentResource,
  useImageResource,
} from '@traveloka/ctv-core/resource';
import { CardListItem } from '@traveloka/ctvweb-ui/company';
import { Button, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import { ManageCardRequest, MANAGE_CREDIT_CARD_API } from 'company/shared/api';
import { CreditCard } from 'company/shared/api/types';

import { useManageCardFetch } from '../context/CardListContext';
import { formatMessage } from 'shared/utils/intl';

type ModalState = {
  visible: boolean;
  type: 'remove' | 'main' | undefined;
};

type Props = CreditCard & {
  backupCardNumber?: string;
};

export default function CardWithModal(props: Props) {
  const ir = useImageResource().CommerceAPIImages;
  const images: Record<string, string> = {
    AMERICAN_EXPRESS: ir.amex.link,
    JCB: ir.jcb.link,
    MASTERCARD: ir.mastercard.link,
    VISA: ir.visa.link,
  };

  const cr = useContentResource().CorporateManageCard;

  const { cardId, cardName, cardDetail, mainCard, backupCardNumber } = props;
  const { cardNumber, expirationMonth, expirationYear, cardType } = cardDetail;

  const [loading, setLoading] = useState(false);

  // @ts-ignore
  // This is safe to ignore as Date.UTC accepts string on runtime
  const expiryDate = Date.UTC(expirationYear, expirationMonth);
  const status = Date.now() >= expiryDate ? cr.expired : undefined;

  const fetchCard = useManageCardFetch();
  const manageCard = useApi<unknown, ManageCardRequest>({
    domain: 'management',
    method: 'post',
    path: MANAGE_CREDIT_CARD_API,
  });

  const [modal, setModal] = useState<ModalState>({
    visible: false,
    type: undefined,
  });

  function setModalType(type: Exclude<ModalState['type'], undefined>) {
    setModal({ visible: true, type });
  }

  function hideModal() {
    setModal(s => ({ ...s, visible: false }));
  }

  function destroyModal() {
    setModal(s => ({ ...s, type: undefined }));
  }

  async function handleManageCard() {
    setLoading(true);
    let res: ApiResult<unknown> | undefined;

    switch (modal.type) {
      case 'remove':
        res = await manageCard({ cardId, action: 'REMOVE' });
        break;
      case 'main':
        res = await manageCard({ cardId, action: 'SET_AS_MAIN' });
        break;
    }
    setLoading(false);

    if (res?.success) fetchCard();
  }

  let modalTitle = '';
  let modalBody = '';
  let primaryButton = '';
  const currentCardParams = { val: cardDetail.cardNumber.substr(-4) };
  switch (modal.type) {
    case 'main':
      modalTitle = formatMessage(cr.setMainCardTitle, currentCardParams);
      modalBody = formatMessage(cr.setMainCardBody, currentCardParams);
      primaryButton = cr.confirm;
      break;
    case 'remove':
      modalTitle = formatMessage(cr.removeTitle, currentCardParams);

      if (mainCard) {
        if (backupCardNumber === undefined) {
          modalBody = cr.removeMainCardNoBackup;
        } else {
          modalBody = formatMessage(cr.removeMainCard, {
            val: backupCardNumber.substr(-4),
          });
        }
      } else {
        modalBody = cr.removeBackupCard;
      }
      primaryButton = cr.remove;
      break;
  }

  return (
    <>
      <CardListItem
        cardholderName={cardName}
        cardNumber={cardNumber.substr(-4)}
        expiryDate={[expirationMonth, expirationYear.substr(-2)].join('/')}
        image={images[cardType]}
        status={status}
      />
      <View style={Style.control}>
        <Button
          variant="text-destructive"
          size="small"
          text={cr.remove}
          onPress={() => setModalType('remove')}
          style={mainCard && Style.removeCard}
        />
        {!mainCard && (
          <Button
            variant="secondary"
            size="small"
            text={cr.setMainCard}
            onPress={() => setModalType('main')}
            style={Style.mainCardButton}
          />
        )}
      </View>

      <Modal isVisible={modal.visible} onModalHide={destroyModal}>
        <Popup
          showCloseButton={false}
          title={modalTitle}
          width={500}
          maxWidth={500}
        >
          <Text style={Style.modalBody}>{modalBody}</Text>
          <View style={Style.modalControl}>
            <Button
              variant="text"
              loading={loading}
              text={cr.cancel}
              onPress={hideModal}
            />
            <Button
              variant="main-cta"
              loading={loading}
              text={primaryButton}
              onPress={handleManageCard}
              style={Style.manageCardButton}
            />
          </View>
        </Popup>
      </Modal>
    </>
  );
}

const Style = StyleSheet.create({
  control: {
    marginTop: Token.spacing.m,
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  removeCard: {
    flex: 1,
  },
  mainCardButton: {
    flex: 1,
    marginStart: Token.spacing.m,
  },
  modalBody: {
    minHeight: 100,
  },
  modalControl: {
    marginTop: Token.spacing.m,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  manageCardButton: {
    marginStart: Token.spacing.m,
  },
});

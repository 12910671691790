import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useAuth } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { Divider } from '@traveloka/ctvweb-ui';
import {
  CompanyEntityModalGroup,
  CompanyEntityModalRow,
  CompanyInfo,
} from '@traveloka/ctvweb-ui/company';
import { Card, Text, Token } from '@traveloka/web-components';

import { AVAILABLE_SEAT_CLASS } from 'flight/search/constants/flight-search-constant';
import { AirlineMap } from 'flight/search/types';
import { ProfileResponse } from 'profile/api';
import { formatCurrency, getCurrencyByCountry } from 'shared/utils/intl';

type Props = {
  airlineMap: AirlineMap;
  profile: ProfileResponse;
};

export default function EmploymentDetailContent(props: Props) {
  const { airlineMap } = props;
  const { user } = useAuth();

  const { employmentDetail, profile } = props.profile;
  const { enabled: flightPolicyEnabled, excludedAirlines, seatClasses } =
    employmentDetail.policyDetails?.policyDetailsFlight || {};
  const {
    enabled: isHotelEnabled,
    starRating,
    currency = getCurrencyByCountry(user?.country),
    defaultBudget,
    locations,
  } = employmentDetail.policyDetails?.policyDetailsHotel || {};

  const content = useContentResource().CorporateEmploymentDetail;

  const seatClassContent = useContentResource().CorporateEnumSeatClass;

  let policyText;
  if (employmentDetail.tiers.length === 0) {
    policyText = employmentDetail.tiers.join(', ');
  }

  let excludedAirlineText = '-';
  if (excludedAirlines) {
    excludedAirlineText = excludedAirlines
      .map(airportCode => {
        const airline = airlineMap[airportCode];
        if (airline) {
          return airline.airlineName;
        }

        return null;
      })
      .filter<string>((val): val is string => !!val)
      .join(', ');
  }

  let seatClassText = '-';
  if (Array.isArray(seatClasses)) {
    seatClassText =
      seatClasses.length === AVAILABLE_SEAT_CLASS.length
        ? seatClassContent.ALL
        : seatClasses.map(seatClass => seatClassContent[seatClass]).join(', ');
  }

  let starRatingText = '-';
  if (starRating) {
    starRatingText =
      parseInt(starRating, 10) > 1 ? `1-${starRating}` : starRating;
  }

  let defaultBudgetText = '-';
  if (defaultBudget && parseInt(defaultBudget, 10)) {
    defaultBudgetText = formatCurrency({
      amount: Number(defaultBudget),
      currency,
      decimalPoints: 2,
    });
  }

  const showPolicy = policyText || flightPolicyEnabled || isHotelEnabled;

  return (
    <>
      <CompanyInfo
        address={employmentDetail.address}
        addressLabel={content.addressText}
        corporateId={`Corporate ID #${employmentDetail.corporateId}`}
        corporateName={employmentDetail.corporate}
        phone={`${employmentDetail.phoneCountryCode} ${employmentDetail.phone}`}
        phoneLabel={content.phoneText}
        style={Style.section}
      />

      <Card style={Style.section}>
        <Text variant="title-1" style={Style.sectionContent}>
          {content.employmentTitle}
        </Text>
        <Divider margin="none" />

        <View style={Style.employmentContent}>
          <View style={Style.column}>
            <Text variant="ui-small">{content.divisionText}</Text>
            <Text variant="ui-small" ink="secondary">
              {profile.division || '-'}
            </Text>
          </View>
          <View style={Style.column}>
            <Text variant="ui-small">{content.tierText}</Text>
            <Text variant="ui-small" ink="secondary">
              {profile.tier || '-'}
            </Text>
          </View>
          <View style={Style.column}>
            <Text variant="ui-small">{content.roleText}</Text>
            <Text variant="ui-small" ink="secondary">
              {profile.role}
            </Text>
          </View>
          <View style={Style.column}>
            <Text variant="ui-small">{content.managerText}</Text>
            <Text variant="ui-small" ink="secondary">
              {profile.manager || '-'}
            </Text>
          </View>
          <View style={Style.column}>
            <Text variant="ui-small">{content.approverRoleText}</Text>
            <Text
              style={employmentDetail.isApprover && Style.approver}
              variant="ui-small"
              ink={employmentDetail.isApprover ? 'highlight' : 'secondary'}
            >
              {employmentDetail.isApprover ? content.approverLabelText : '-'}
            </Text>
          </View>
        </View>
      </Card>
      {showPolicy && (
        <Card>
          <Text variant="title-1" style={Style.sectionContent}>
            {content.policyTitle}
          </Text>
          <Divider margin="none" />
          <View style={Style.sectionContent}>
            {policyText && (
              <>
                <Text style={Style.section}>
                  {content.policyUserClassificationText}
                </Text>
                <CompanyEntityModalRow
                  left={content.policyTierText}
                  right={policyText}
                  style={Style.rowMargin}
                  tint
                />
              </>
            )}
            {flightPolicyEnabled && (
              <>
                <Text style={Style.section}>{content.policyFlightText}</Text>
                <CompanyEntityModalRow
                  left={content.policyExcludedAirlineText}
                  right={excludedAirlineText}
                  tint
                  border
                />
                <CompanyEntityModalRow
                  left={content.policyIncludedSeatclassText}
                  right={seatClassText}
                />
              </>
            )}
            {isHotelEnabled && (
              <>
                <Text style={Style.section}>{content.policyHotelText}</Text>
                <CompanyEntityModalRow
                  left={content.policyStarRatingText}
                  right={starRatingText}
                  tint
                  border
                />
                <CompanyEntityModalGroup
                  title={content.policyDefaultBudgetText}
                  items={[
                    {
                      left: content.policyAllLocationText,
                      right: defaultBudgetText,
                    },
                  ]}
                  lastItemBorder
                />
                <CompanyEntityModalGroup
                  title={content.policySpecificLocationText}
                  items={
                    locations && locations.length !== 0
                      ? locations.map(l => {
                          let budgetValueText = '-';
                          if (l.budgetValue && parseInt(l.budgetValue, 10)) {
                            budgetValueText = formatCurrency({
                              amount: Number(l.budgetValue),
                              currency,
                              decimalPoints: 2,
                            });
                          }
                          return {
                            left: l.geoName,
                            right: budgetValueText,
                          };
                        })
                      : [
                          {
                            left: '-',
                            right: '-',
                          },
                        ]
                  }
                />
              </>
            )}
          </View>
        </Card>
      )}
    </>
  );
}

const Style = StyleSheet.create({
  section: {
    marginBottom: Token.spacing.m,
  },
  companyTitle: {
    fontWeight: Token.typography.weightMedium.fontWeight,
    marginBottom: Token.spacing.m,
    textTransform: 'uppercase',
  },
  sectionContent: {
    padding: Token.spacing.m,
  },
  employmentContent: {
    flexDirection: 'row',
    paddingVertical: Token.spacing.m,
    paddingHorizontal: Token.spacing.xs,
  },
  column: {
    flex: 1,
    paddingHorizontal: Token.spacing.xs,
  },
  rowMargin: {
    marginBottom: Token.spacing.xl,
  },
  approver: {
    paddingVertical: Token.spacing.xxs,
    paddingHorizontal: Token.spacing.s,
    backgroundColor: Token.color.uiBlueLight,
    width: 'fit-content',
    borderRadius: Token.border.radius.rounded,
  },
});

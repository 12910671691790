import React from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Token } from '@traveloka/web-components';

import { currentStepSwitcher } from 'refund/shared/utils/request-refund-utils';
import { Content, Footer, Page, StepHeader } from 'shared/components/Layout';

import {
  useProductRequestRefundPage,
  ProductRequestRefundPageProvider,
} from './contexts/ProductRequestRefundPageContext';
import ProductRequestRefundContent from './components/ProductRequestRefundContent/ProductRequestRefundContent';
import ProductRequestRefundErrorBar from './components/ProductRequestRefundContent/ProductRequestRefundErrorBar';

export default function ProductRequestRefund() {
  return (
    <ProductRequestRefundPageProvider>
      <Page>
        <Header />
        <ProductRequestRefundErrorBar />
        <Content style={Style.container}>
          <ProductRequestRefundContent />
        </Content>
        <Footer />
      </Page>
    </ProductRequestRefundPageProvider>
  );
}

function Header() {
  const [{ currentStep }] = useProductRequestRefundPage();
  const content = useContentResource().CorporateProductRequestRefund;

  // FLIGHT and HOTEL steps are the same
  let steps = [content.step1Text, content.step2Text, content.step3Text];

  return (
    <StepHeader current={currentStepSwitcher(currentStep)} steps={steps} />
  );
}

const Style = StyleSheet.create({
  container: {
    zIndex: 1,
    width: 960,
    paddingTop: Token.spacing.xxl,
    paddingBottom: Token.spacing.m,
    marginHorizontal: 'auto',
  },
});

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import { useApi } from '@traveloka/ctv-core';

import ProductType from 'postbooking/shared/constants/ProductType';
import {
  FlightRefundEligibilityResponse,
  RefundEligibilityRequest,
  REFUND_ELIGIBILITY_API,
} from 'refund/shared/api';
import {
  FlightMainRefundReasonWithNoSecondaryButHasDocument,
  RefundDocument,
} from 'refund/shared/api/types';

type Props = {
  bookingId: string;
};

type State = {
  isFetching: boolean;
  isSubmitting: boolean;
  data: FlightRefundEligibilityResponse['flightRefundEligibility'];
  refundedJourneys: string[];
};

type Action = {
  setIsSubmitting(isSubmitting: boolean): void;
  setRefundedJourneys(refundedJourneys: string[]): void;
};

const initialState: State = {
  isFetching: false,
  isSubmitting: false,
  data: undefined!,
  // refundeJourneys: This is used for Error Handling, storing the data from the Submit Request Response.
  refundedJourneys: [],
};

const FlightRequestRefundContext = createContext<[State, Action]>(undefined!);

export function FlightRequestRefundProvider(props: PropsWithChildren<Props>) {
  const { bookingId, children } = props;
  const [state, setState] = useState<State>({
    ...initialState,
    isFetching: true,
  });

  const refundEligibility = useApi<
    FlightRefundEligibilityResponse,
    RefundEligibilityRequest
  >({
    domain: 'refund',
    method: 'post',
    path: REFUND_ELIGIBILITY_API,
  });

  useEffect(() => {
    setState(prevValue => ({ ...prevValue, isFetching: true }));
    refundEligibility({ bookingId, productType: ProductType.FLIGHT })
      .then(res => {
        if (res.success) {
          // Need to manually add Double Booking's required document,
          // as the API doesn't provide the Double Booking's Refund Document.
          return {
            isFetching: false,
            isSubmitting: false,
            data: {
              ...res.data.flightRefundEligibility,
              reasons: res.data.flightRefundEligibility.reasons.map(reason => {
                if (
                  reason.mainReason ===
                  FlightMainRefundReasonWithNoSecondaryButHasDocument.DOUBLE_BOOKING
                ) {
                  return {
                    mainReason:
                      FlightMainRefundReasonWithNoSecondaryButHasDocument.DOUBLE_BOOKING,
                    secondaryReasons: [
                      {
                        reason: null,
                        requiredDocument: [RefundDocument.DOUBLE_BOOKING_PNR],
                      },
                    ],
                  };
                }
                return reason;
              }),
            },
            refundedJourneys: [],
          };
        }

        return initialState;
      })
      .catch(() => initialState)
      .then(state => setState(state));
  }, []);

  const setRefundedJourneys = useCallback(
    (refundedJourneys: string[]) =>
      setState(prevValue => ({ ...prevValue, refundedJourneys })),
    []
  );

  const setIsSubmitting = useCallback(
    (isSubmitting: boolean) =>
      setState(prevValue => ({ ...prevValue, isSubmitting })),
    []
  );

  const providerValue: [State, Action] = useMemo(
    () => [state, { setRefundedJourneys, setIsSubmitting }],
    [state, setRefundedJourneys, setIsSubmitting]
  );

  return (
    <FlightRequestRefundContext.Provider value={providerValue}>
      {children}
    </FlightRequestRefundContext.Provider>
  );
}

export function useFlightRequestRefund() {
  return useContext(FlightRequestRefundContext);
}

import React, { ComponentProps, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useController, useFormContext } from 'react-hook-form';

import { useLocalizedDateFormat } from '@traveloka/ctv-core';
import { InputDropdown, InputGroup } from '@traveloka/ctvweb-ui';
import { Token } from '@traveloka/web-components';

import { FieldProps } from '../types';

type InputDropdownProps = ComponentProps<typeof InputDropdown>;

export type Value = {
  day: string;
  /**
   * 0 = January
   */
  month: string;
  year: string;
};

export type Props = {
  maxYear?: number;
  yearRange?: number;
  reverse?: boolean;
} & FieldProps<Value> &
  Omit<InputDropdownProps, 'items' | 'onPressItem' | 'defaultValue'>;

export default function DateField(props: Props) {
  const {
    defaultValue,
    name,
    validate,
    onFocus,
    label,
    leftHelper,
    rightHelper,
    disabled,
    style,
    maxYear = new Date().getFullYear(),
    yearRange = 100,
    reverse,
    ...inputProps
  } = props;

  const { format } = useLocalizedDateFormat();
  const { control } = useFormContext();
  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });

  const [yearItems, monthItems] = useMemo(() => {
    const years = Array.from({ length: yearRange }, (v, i) => ({
      label: String(maxYear - i),
      value: String(maxYear - i),
    }));

    if (reverse) {
      years.reverse();
    }

    const months = Array.from({ length: 12 }, (v, i) => ({
      label: format(new Date(0, i), 'FULL_MONTH_ONLY'),
      value: String(i),
    }));

    return [years, months];
  }, [maxYear, yearRange, reverse]);

  function handleFocus() {
    if (typeof onFocus === 'function') {
      onFocus();
    }
  }

  const displayMonth = value?.month
    ? format(new Date(0, Number(value.month)), 'FULL_MONTH_ONLY')
    : '';

  return (
    <InputGroup
      label={label}
      error={error?.message}
      leftHelper={leftHelper}
      rightHelper={rightHelper}
      style={style}
      disabled={disabled}
    >
      <View style={Style.input}>
        <>
          <InputDropdown
            {...inputProps}
            disabled={disabled}
            error={error?.message}
            showError={false}
            onBlur={onBlur}
            onFocus={handleFocus}
            onPressItem={item => onChange({ ...value, day: item.value })}
            value={value?.day ?? ''}
            items={dayItems}
            style={Style.day}
            editable
            searchable
          />
          <InputDropdown
            {...inputProps}
            disabled={disabled}
            error={error?.message}
            showError={false}
            onBlur={onBlur}
            onFocus={handleFocus}
            onPressItem={item => onChange({ ...value, month: item.value })}
            value={value?.month && displayMonth}
            items={monthItems}
            style={Style.month}
            editable
            searchable
          />
          <InputDropdown
            {...inputProps}
            disabled={disabled}
            error={error?.message}
            showError={false}
            onBlur={onBlur}
            onFocus={handleFocus}
            onPressItem={item => onChange({ ...value, year: item.value })}
            value={value?.year ?? ''}
            items={yearItems}
            style={Style.year}
            editable
            searchable
          />
        </>
      </View>
    </InputGroup>
  );
}

const Style = StyleSheet.create({
  input: {
    flexDirection: 'row',
    zIndex: 1,
  },
  day: {
    flex: 2,
  },
  month: {
    flex: 4,
    marginLeft: Token.spacing.xs,
  },
  year: {
    flex: 3,
    marginLeft: Token.spacing.xs,
  },
});

const dayItems = Array.from({ length: 31 }, (v, i) => ({
  label: String(i + 1),
  value: String(i + 1),
}));

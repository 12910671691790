import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Text, Icon, Token } from '@traveloka/web-components';
import { Divider, InputDropdownRadio } from '@traveloka/ctvweb-ui';
import ChevronDown from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';

import { useFlightRequestRefund } from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';
import {
  useFlightRequestRefundFormAction,
  useFlightRequestRefundForm,
} from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import { FlightMainRefundReason } from 'refund/shared/api/types';
import InfoBox from 'refund/shared/components/InfoBox';

export default function SelectRefundReasonCard() {
  const { mainRefundReason } = useFlightRequestRefundForm();
  const { updateSelectedMainReason } = useFlightRequestRefundFormAction();
  const [{ data }] = useFlightRequestRefund();
  const { reasons } = data;

  const content = useContentResource().CorporateFlightRequestRefundPolicy;
  const mainRefundReasonContent = useContentResource()
    .CorporateEnumFlightMainRefundReason;

  return (
    <>
      <Text style={Style.selectMainReasonTitle} variant="title-1">
        {content.selectMainReasonTitle}
      </Text>
      <Divider margin="bottom" spacing="m" />
      <View style={Style.innerMainReasonContainer}>
        <InputDropdownRadio
          style={Style.mainReasonDropdown}
          cardStyle={Style.mainReasonDropdownCard}
          alignment="left"
          title={content.dropdownMainReasonTitle}
          text={
            mainRefundReason
              ? mainRefundReasonContent[mainRefundReason.mainReason]
              : content.emptyMainReasonText
          }
          items={reasons.map(reason => ({
            label: mainRefundReasonContent[reason.mainReason],
            value: reason.mainReason,
          }))}
          value={mainRefundReason ? mainRefundReason.mainReason : ''}
          onChange={(value: FlightMainRefundReason) =>
            updateSelectedMainReason(value)
          }
          rightIcon={<Icon src={ChevronDown} />}
        />
        <Text variant="ui-small" ink="secondary">
          {content.mainReasonDescription}
        </Text>
        <Divider margin="vertical" spacing="l" />
        <Text style={Style.itemTitle} variant="title-3">
          {content.requiredDocumentTitle}
        </Text>
        <Text style={Style.itemContent} variant="ui-small" ink="secondary">
          {content.requiredDocumentContent}
        </Text>
        <Text style={Style.itemTitle} variant="title-3">
          {content.refundableAmountTitle}
        </Text>
        <InfoBox
          style={Style.refundableAmountContent}
          text={content.refundableAmountContent}
          verticalAlign="top"
        />
      </View>
    </>
  );
}

const Style = StyleSheet.create({
  selectMainReasonTitle: {
    paddingHorizontal: Token.spacing.l,
    paddingBottom: Token.spacing.ml,
  },
  innerMainReasonContainer: {
    paddingHorizontal: Token.spacing.l,
  },
  mainReasonDropdown: {
    zIndex: 1,
    width: 281,
    marginBottom: Token.spacing.xs,
  },
  mainReasonDropdownCard: {
    width: 256,
  },
  itemTitle: {
    marginBottom: Token.spacing.xs,
  },
  itemContent: {
    marginBottom: Token.spacing.l,
  },
  refundableAmountContent: {
    borderColor: Token.color.uiBluePrimary,
    borderWidth: Token.border.width.thick,
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useTheme, Badge, Image, Text, Token } from '@traveloka/web-components';

type Props = {
  cardholderName: string;
  cardNumber: string;
  expiryDate: string;
  image: string;
  status?: string;
};

export default function CardListItem(props: Props) {
  const { cardholderName, cardNumber, expiryDate, image, status } = props;

  const { color } = useTheme();
  const rootStyle = {
    borderColor: color.borderDivide,
  };

  const maskedCardNumber = `${'\u2022'.repeat(4)} ${cardNumber}`;

  return (
    <View style={[Style.root, rootStyle]}>
      <View style={[Style.align, Style.top]}>
        <Text>{maskedCardNumber}</Text>
        <Image src={image} height={24} />
      </View>
      <View style={Style.align}>
        <View>
          <Text variant="ui-small" ink="secondary">
            {expiryDate}
          </Text>
          <Text variant="ui-small" ink="secondary">
            {cardholderName}
          </Text>
        </View>
        {status !== undefined && <Badge variant="negative" text={status} />}
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  root: {
    borderRadius: Token.border.radius.normal,
    borderWidth: Token.border.width.thick,
    minHeight: 120,
    padding: 16,
    width: 248,
  },
  align: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  top: {
    marginBottom: Token.spacing.l,
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import { Button, Text, Token, Tooltip } from '@traveloka/web-components';

import { formatMessage } from 'shared/utils/intl';

import { NonEmployeeType, Traveler } from '../RevampTravelerPickerModal';
import { useNonEmployee } from '../contexts/NonEmployeeContext';
import { CHILD_AGE_DEFAULT_VALUE } from './RightSection';

type Props = {
  testID?: string;

  onClose(): void;
  onSave(travelers: Traveler[], nonEmployee?: any): void;

  emptyTotalTravelerText: string;
  totalTravelerText: string;
  maxTravelersErrorMessage: string;
};

export default function ActionSection(props: Props) {
  const {
    testID,

    onClose,
    onSave,

    emptyTotalTravelerText,
    totalTravelerText,
    maxTravelersErrorMessage,
  } = props;
  const {
    travelers,
    nonEmployeeTravelers,
    setNonEmployeeTravelers,
    isNonEmployeeChecked,
    maximumTraveler,
    hasError,
    handleIsOpen,
    setIsChildAgeAutoFill,
  } = useNonEmployee();

  const content = useContentResource().CorporateProductSearchForm;

  let totalTraveler = 0;
  let validateTotalTraveler = 0;
  const showDetail: string[] = [];
  if (isNonEmployeeChecked && nonEmployeeTravelers) {
    nonEmployeeTravelers.forEach(field => {
      const fieldValue =
        typeof field.value === 'number' ? field.value : field.value.length;

      if (
        field.type === NonEmployeeType.ADULT &&
        (travelers.length > 0 || field.value > 0)
      ) {
        const usedCount = fieldValue + travelers.length;
        totalTraveler += usedCount;
        validateTotalTraveler += usedCount;
        showDetail.push(
          formatMessage(content.nonEmployeeAdultUnit, { num: usedCount })
        );
      } else if (field.type === NonEmployeeType.CHILD && fieldValue > 0) {
        totalTraveler += fieldValue;
        validateTotalTraveler += fieldValue;
        showDetail.push(
          formatMessage(content.nonEmployeeChildUnit, { num: fieldValue })
        );
      } else if (field.type === NonEmployeeType.CHILD_AGE && fieldValue > 0) {
        totalTraveler += fieldValue;
        showDetail.push(
          formatMessage(content.nonEmployeeChildUnit, { num: fieldValue })
        );
      } else if (field.type === NonEmployeeType.INFANT && fieldValue > 0) {
        totalTraveler += fieldValue;
        showDetail.push(
          formatMessage(content.nonEmployeeInfantUnit, { num: fieldValue })
        );
      }
    });
  } else if (travelers.length) {
    totalTraveler += travelers.length;
    showDetail.push(
      formatMessage(content.nonEmployeeAdultUnit, { num: travelers.length })
    );
  }

  const isMaxTravelerError = validateTotalTraveler > maximumTraveler;

  return (
    <View style={ActionStyle.container}>
      <Tooltip
        variant="alert"
        contentZIndex={10000}
        content={maxTravelersErrorMessage}
        show={isMaxTravelerError}
      >
        <View style={ActionStyle.employeeInfo}>
          <Text variant="ui-large" style={Token.typography.weightMedium}>
            {showDetail.length
              ? formatMessage(totalTravelerText, {
                  num: totalTraveler,
                })
              : emptyTotalTravelerText}
          </Text>
          {!!showDetail.length && <Text>{showDetail.join(', ')}</Text>}
        </View>
      </Tooltip>
      <View style={ActionStyle.buttonGroup}>
        <Button
          testID={appendTestId(testID, 'cancel-button')}
          style={ActionStyle.buttonCancel}
          variant="secondary"
          onPress={onClose}
          text={content.cancelButtonText}
        />
        <Button
          testID={appendTestId(testID, 'save-button')}
          variant="main-cta"
          onPress={() => {
            const isChildrenAgeNullValue =
              isNonEmployeeChecked &&
              nonEmployeeTravelers?.some(ne => {
                if (ne.type === NonEmployeeType.CHILD_AGE) {
                  return (ne.value as Nullable<string>[]).some(v => v === null);
                }
                return false;
              });

            if (isChildrenAgeNullValue) {
              setNonEmployeeTravelers(prevValue =>
                prevValue?.map(ne => {
                  if (ne.type === NonEmployeeType.CHILD_AGE) {
                    return {
                      ...ne,
                      value: (ne.value as Nullable<string>[]).map(
                        v => v ?? CHILD_AGE_DEFAULT_VALUE
                      ),
                    };
                  }
                  return ne;
                })
              );
              handleIsOpen('NON_EMPLOYEE', true);
              setIsChildAgeAutoFill({ value: true, stopReRender: true });
            } else {
              onSave(travelers, nonEmployeeTravelers);
            }
          }}
          text={content.submitButtinText}
          disabled={[!totalTraveler, isMaxTravelerError, ...hasError].some(
            err => err
          )}
        />
      </View>
    </View>
  );
}

const ActionStyle = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.s,
    boxShadow: '0px -2px 5px rgba(3, 18, 26, 0.15)',
  },
  employeeInfo: {
    justifyContent: 'center',
    height: 51,
  },
  buttonGroup: {
    flexDirection: 'row',
  },
  buttonCancel: {
    marginRight: Token.spacing.m,
  },
});

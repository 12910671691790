// Retrieve Attachment from CSV
export const RETRIEVE_ATTACHMENT_CSV =
  '/api/v1/booking/retrieve/attachment-trip';
export type RetrieveAttachmentCsvRequest = {
  tripId: string;
};
export type RetrieveAttachmentCsvResponse = {
  url: string;
  isSuccess: string;
};

// Retrieve Attachment from Email
export const RETRIEVE_ATTACHMENT_EMAIL =
  '/api/v1/booking/retrieve/attachments-by-email';
export type RetrieveAttachmentEmailRequest = {
  fileName: string;
};
export type RetrieveAttachmentEmailResponse = {
  url: string;
  isSuccess: string;
};

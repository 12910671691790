import React, { useCallback, useState, CSSProperties } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Hotel from '@traveloka/icon-kit-web/svg/blue/ic_product_hotel-fill_24px.svg';
import { useTheme, Icon, Image, Token } from '@traveloka/web-components';

type Props = {
  height?: number;
  src?: string | null;
  style?: StyleProp<ViewStyle>;
  width?: number;
};

export default function SafeImage(props: Props) {
  const { height, src, style, width } = props;
  const { color } = useTheme();

  const placeholderStyle = {
    backgroundColor: color.lightStain,
    height,
    width,
  };

  const [isError, setIsError] = useState(false);
  const handleError = useCallback(() => setIsError(true), [setIsError]);

  if (!src || isError) {
    return (
      <View style={[Style.placeholder, placeholderStyle, style]}>
        <Icon src={Hotel} width={24} height={24} />
      </View>
    );
  }

  return (
    <Image
      variant="rounded"
      src={src}
      width={width}
      height={height}
      objectFit="cover"
      onError={handleError}
      style={style as CSSProperties}
    />
  );
}

const Style = StyleSheet.create({
  placeholder: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: Token.border.radius.normal,
  },
});

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useApi } from '@traveloka/ctv-core';

import {
  DocumentRequirementRequest,
  DocumentRequirementResponse,
  DOCUMENT_REQUIREMENT_API,
} from 'registration/api';
import { Document } from 'shared/components/form/DocumentField/types';

import { useToken } from './TokenContext';

export type RequiredDocumentState = {
  loading: boolean;
  documents: Document[];
  creditAssessmentDocuments: Document[];
};

const RequiredDocumentContext = createContext<RequiredDocumentState>({
  creditAssessmentDocuments: [],
  documents: [],
  loading: true,
});

export function RequiredDocumentProvider(props: PropsWithChildren<{}>) {
  const [data, setData] = useState<RequiredDocumentState>({
    loading: true,
    documents: [],
    creditAssessmentDocuments: [],
  });

  const token = useToken();

  const getDocuments = useApi<
    DocumentRequirementResponse,
    DocumentRequirementRequest
  >({
    domain: 'management',
    method: 'post',
    path: DOCUMENT_REQUIREMENT_API,
  });

  useEffect(() => {
    setData(s => ({ ...s, loading: true }));

    getDocuments({ token }).then(res => {
      if (res.success) {
        setData({
          loading: false,
          ...res.data,
        });
      } else {
        setData({
          loading: true,
          creditAssessmentDocuments: [],
          documents: [],
        });
      }
    });
  }, []);

  return (
    <RequiredDocumentContext.Provider value={data}>
      {props.children}
    </RequiredDocumentContext.Provider>
  );
}

export function useRequiredDocument() {
  return useContext(RequiredDocumentContext);
}

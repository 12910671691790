import React from 'react';

import { useFeatureControl } from '@traveloka/ctv-core';

import { Content, Header, Page } from 'shared/components/Layout';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

import InvoiceDispute from './components/InvoiceDispute';

export default function InvoiceDisputeApp() {
  const { enabled } = useFeatureControl('ctv-invoice-dispute');
  const history = useLocalizedHistory();

  if (!enabled) {
    history.replace('/');
    return null;
  }

  return (
    <Page>
      <Header />
      <Content>
        <InvoiceDispute />
      </Content>
    </Page>
  );
}

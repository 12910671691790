import { Field, ResultStatus, SearchParams, ValidationResult } from './types';

export const GET_ENTITY_SCHEMA = '/api/v2/crud/getEntitySchema';
export const GET_ENTRY_LIST = '/api/v2/crud/getEntryList';
export const GET_ENTRY_DETAIL = '/api/v2/crud/getEntryDetail';
export const CREATE_ENTRY = '/api/v2/crud/createEntry';
export const CREATE_ENTRY_BATCH = '/api/v2/crud/createEntryBatch';
export const UPDATE_ENTRY = '/api/v2/crud/updateEntry';
export const DELETE_ENTRY = '/api/v2/crud/deleteEntry';

export type EntitySchemaRequest = {
  entityType: string;
};

export type EntitySchemaResponse = {
  activityHistoryEnabled: boolean;
  fields: Field[];
};

export type EntryListRequest = {
  entityType: string;
  search: SearchParams;
};

export type EntryListResponse<T> = {
  entries: T[];
  totalEntries: string;
  message: Nullable<string>;
};

export type EntryDetailRequest = {
  entityType: string;
  entryId: string;
};

export type EntryDetailResponse<T> = {
  value: T;
  version?: EntryDetailVersion;
};

export type EntryDetailVersion = {
  createdAt: string;
  number: string;
  retryAttemptLeft: string;
  status: string;
  updatedBy: Nullable<string>;
};

export type CreateEntryRequest<T> = {
  entityType: string;
  entry: T;
};

export type CreateEntryResponse = {
  errorMessage: Nullable<string>;
  id: string;
  message: string;
  result: ResultStatus;
  validationResult: ValidationResult;
};

export type CreateEntryBatchRequest<T> = {
  entityType: string;
  entries?: Array<{
    entry: T;
  }>;
};

export type CreateEntryBatchResponse = { result: Array<CreateEntryResponse> };

export type UpdateEntryRequest<T> = {
  entityType: string;
  entry: T;
  versionNo?: Nullable<string>;
};

export type UpdateEntryResponse = {
  errorMessage: Nullable<string>;
  id: string;
  message: string;
  result: ResultStatus;
  validationResult: ValidationResult;
};

export type DeleteEntryRequest = {
  entityType: string;
  entryId: string;
  versionNo?: Nullable<string>;
};

export type DeleteEntryResponse = {
  errorMessage: Nullable<string>;
  message: string;
  result: ResultStatus;
};

import { Honorific, NonEmployeeType } from 'flight/shared/api/types';
import { JavaCurrencyValue } from 'shared/utils/currency';

// Flight
// Refund Request
export type FlightMainRefundReason =
  | FlightMainRefundReasonWithNoSecondary
  | FlightMainRefundReasonWithNoSecondaryButHasDocument
  | FlightMainRefundReasonWithSecondary;
export enum FlightMainRefundReasonWithNoSecondary {
  RESCHEDULED_BY_AIRLINE = 'RESCHEDULED_BY_AIRLINE',
  FORCE_MAJEURE = 'FORCE_MAJEURE',
  CANCELLED_BY_AIRLINE = 'CANCELLED_BY_AIRLINE',
}
export enum FlightMainRefundReasonWithNoSecondaryButHasDocument {
  DOUBLE_BOOKING = 'DOUBLE_BOOKING',
}
export enum FlightMainRefundReasonWithSecondary {
  PERSONAL_REASON = 'PERSONAL_REASON',
}

export enum FlightRefundPersonalReason {
  ILLNESS = 'ILLNESS',
  DEATH = 'DEATH',
  CHANGE_OF_PLAN = 'CHANGE_OF_PLAN',
  PREGNANCY = 'PREGNANCY',
  VISA_REJECTION = 'VISA_REJECTION',
}

export enum RefundDocument {
  INPATIENT_MEDICALCERTIFICATE = 'INPATIENT_MEDICALCERTIFICATE',
  OUTPATIENT_INPATIENT_MEDICALCERTIFICATE = 'OUTPATIENT_INPATIENT_MEDICALCERTIFICATE',
  PHARMACY_INVOICE = 'PHARMACY_INVOICE',
  OTHER_MEDICAL_DOCUMENTS = 'OTHER_MEDICAL_DOCUMENTS',
  FAMILY_REGISTRATION_CERTIFICATE = 'FAMILY_REGISTRATION_CERTIFICATE',
  IDCARD_PASSPORT = 'IDCARD_PASSPORT',
  DEATH_CERTIFICATE = 'DEATH_CERTIFICATE',
  IDCARD_PASSPORT_DECEASED = 'IDCARD_PASSPORT_DECEASED',
  MEDICAL_CERTIFICATE_PREGNANCY = 'MEDICAL_CERTIFICATE_PREGNANCY',
  ULTRASOUND_RESULTS = 'ULTRASOUND_RESULTS',
  EMBASSY_REJECTION_LETTER = 'EMBASSY_REJECTION_LETTER',
  DOUBLE_BOOKING_PNR = 'DOUBLE_BOOKING_PNR',
  IDCARD_PASSPORT_REFUNDAPPLICANT = 'IDCARD_PASSPORT_REFUNDAPPLICANT',
  OUTPATIENT_MEDICALCERTIFICATE = 'OUTPATIENT_MEDICALCERTIFICATE',
  AIRPORT_OFFICIAL_STATEMENT = 'AIRPORT_OFFICIAL_STATEMENT',
  AIRLINE_OFFICIAL_STATEMENT = 'AIRLINE_OFFICIAL_STATEMENT',
  FORCE_MAJEURE_REPORT = 'FORCE_MAJEURE_REPORT',
  PROVIDER_APPROVAL_LETTER = 'PROVIDER_APPROVAL_LETTER',
  PASSPORT_IMAGE = 'PASSPORT_IMAGE',
  PREFLIGHT_INFO_PROOF = 'PREFLIGHT_INFO_PROOF',
  IDCARD_PASSPORT_BANK_ACCOUNT = 'IDCARD_PASSPORT_BANK_ACCOUNT',
  IDCARD_PASSPORT_TRAVELOKA_ACCOUNT = 'IDCARD_PASSPORT_TRAVELOKA_ACCOUNT',
  TRANSFER_RECEIPT = 'TRANSFER_RECEIPT',
  SELFIE_IMAGE = 'SELFIE_IMAGE',
  PASSBOOK_IMAGE = 'PASSBOOK_IMAGE',
  STATEMENT_LETTER = 'STATEMENT_LETTER',
}

export type FlightReasonsWithNoSecondary = {
  mainReason: FlightMainRefundReasonWithNoSecondary;
  secondaryReasons: null;
};
export type FlightReasonsWithSecondary = {
  mainReason:
    | FlightMainRefundReasonWithSecondary
    | FlightMainRefundReasonWithNoSecondaryButHasDocument;
  secondaryReasons: FlightSecondaryReason[];
};

type FlightSecondaryReason = {
  reason: FlightRefundPersonalReason | null;
  requiredDocument: RefundDocument[];
};

export type Segment = {
  flightCode: string;
  airline: {
    airlineCode: string;
    airlineName: string;
    logoUrl: string;
  };
  departureDetail: {
    airportCode: string;
    city: string;
    departureDate: string; // yyyy-MM-dd
    departureTime: string; // hh:mm
  };
  arrivalDetail: {
    airportCode: string;
    city: string;
    arrivalDate: string;
    arrivalTime: string;
  };
};

export type FlightTraveler = {
  travelerId: string;
  type: NonEmployeeType;
  fullname: string;
  title: Honorific;
  refundedJourneyIndex: number[];
};

export type RefundJourney = {
  journeyId: string;
  isJourneyRefunded: boolean;
  segments: Segment[];
};

// Submit Request Refund
export type FlightSubmitTraveler = {
  travelerId: string;
  secondaryReason: FlightSubmitSecondaryReason;
  requiredDocument: Partial<Record<RefundDocument, string>>;
};
type FlightSubmitSecondaryReason =
  | FlightRefundPersonalReason
  | FlightMainRefundReasonWithNoSecondary
  | FlightMainRefundReasonWithNoSecondaryButHasDocument;

export enum FlightSubmitRefundError {
  INCONSISTENT_REFUND_REASON = 'INCONSISTENT_REFUND_REASON',
  INVALID_CUSTOMER_REASON = 'INVALID_CUSTOMER_REASON',
  PAX_NOT_REFUNDABLE = 'PAX_NOT_REFUNDABLE',
  MUST_REFUND_ALL_PAX = 'MUST_REFUND_ALL_PAX',
  SHOULD_REFUND_ALL_JOURNEY = 'SHOULD_REFUND_ALL_JOURNEY',
  SHOULD_REFUND_ALL_ROUTE = 'SHOULD_REFUND_ALL_ROUTE',
  GENERAL_ERROR = 'GENERAL_ERROR',
  PARTIALLY_FAILED = 'PARTIALLY_FAILED',
  BOOKING_NOT_FOUND = 'BOOKING_NOT_FOUND',
}

// Hotel
// Refund Request
export type HotelMainRefundReason =
  | HotelMainRefundReasonWithNoAdditionalInfo
  | HotelMainRefundReasonWithAdditionalInfo;
export enum HotelMainRefundReasonWithNoAdditionalInfo {
  RESCHEDULED_BY_ACCOMMODATION = 'RESCHEDULED_BY_ACCOMMODATION',
  FORCE_MAJEURE = 'FORCE_MAJEURE',
  CANCELLED_BY_ACCOMMODATION = 'CANCELLED_BY_ACCOMMODATION',
  PERSONAL_REASON = 'PERSONAL_REASON',
}
export enum HotelMainRefundReasonWithAdditionalInfo {
  DOUBLE_BOOKING = 'DOUBLE_BOOKING',
  OTHERS = 'OTHERS',
}
export type HotelReasonsWithNoAdditionalInfo = {
  mainReason: HotelMainRefundReasonWithNoAdditionalInfo;
  additionalInformation: undefined;
};
export type HotelReasonsWithAdditionalInfo = {
  mainReason: HotelMainRefundReasonWithAdditionalInfo;
  additionalInformation: string;
};
export type HotelTraveler = {
  travelerId: null;
  type: NonEmployeeType;
  age: Nullable<number>;
  fullname: string;
  title: null;
  refundedJourneyIndex: null;
};
export type CancellationPolicies = {
  time: string; //YYYY-MM-DD HH:mm
  amount: JavaCurrencyValue;
  timeZone: string; // "GMT+7:00" This is used for BE Logging/Debugging
  hoursBeforeCheckIn: string;
};
export enum HotelSubmitRefundError {
  ALREADY_CANCELLED = 'ALREADY_CANCELLED',
  NON_REFUNDABLE_BOOKING = 'NON_REFUNDABLE_BOOKING',
  INVALID_SPEC = 'INVALID_SPEC',
}

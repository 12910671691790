import React, {
  PropsWithChildren,
  useRef,
  ReactElement,
  cloneElement,
  CSSProperties,
  ReactNode,
} from 'react';
import {
  StyleProp,
  ViewStyle,
  TouchableOpacity,
  View,
  StyleSheet,
} from 'react-native';

import {
  Text,
  Radio,
  RadioDisc,
  Token,
  useTheme,
} from '@traveloka/web-components';
import { Props as TextProps } from '@traveloka/web-components/src/momentum/Text/Text';
import { useHoverable } from '@traveloka/web-components/future';
import { ButtonComponentProps as RadioComponentProps } from '@traveloka/web-components/src/momentum/RadioGroup/Radio';

import Dropdown, { Handler } from '../Dropdown/Dropdown';
import { appendTestId } from '../../utils/TestUtil';

type RadioItem = {
  value: string;
  label: ReactNode;
};

type InputDropdownRadioProps = PropsWithChildren<{
  alignment: 'left' | 'right';
  fadeAlignment?: 'up' | 'down';
  items: RadioItem[];
  // return false to prevent collapsing the dropdown
  onChange(value: string): boolean | void;
  style?: StyleProp<ViewStyle>;
  cardStyle?: StyleProp<ViewStyle>;
  title?: string;
  value: string;
}> &
  FakeInputProps;

export default function InputDropdownRadio(props: InputDropdownRadioProps) {
  const {
    children,
    alignment,
    fadeAlignment,
    title,
    items,
    onChange,
    style,
    cardStyle,
    value,
    ...inputProps
  } = props;

  const ref = useRef<Handler>(null);
  function handleChange(val: string | number) {
    const res = onChange(String(val));

    if (res !== false) {
      ref.current?.setIsCollapsed(true);
    }
  }

  return (
    <Dropdown
      ref={ref}
      alignment={alignment}
      fadeAlignment={fadeAlignment}
      style={style}
      cardStyle={cardStyle}
      trigger={<FakeInput {...inputProps} />}
    >
      {title && <Text style={Style.dropdownTitle}>{title}</Text>}
      <Radio.Group
        testID={appendTestId(props.testID, 'radio-group')}
        labelID=""
        onChange={handleChange}
        style={Style.radioGroup}
        value={value}
      >
        {items.map((item, index) => (
          <Radio.Button
            testID={appendTestId(props.testID, `radio-button-${index}`)}
            key={item.value}
            value={item.value}
            label={item.label as string}
            containerStyle={Style.radio}
            ButtonComponent={FilterRadio}
          />
        ))}
      </Radio.Group>
      {children}
    </Dropdown>
  );
}

function FilterRadio(props: RadioComponentProps) {
  const { isFocused, isSelected, label } = props;

  return (
    <>
      <RadioDisc isSelected={isSelected} isFocused={isFocused} />
      <Text variant="ui-small" style={Style.radioLabel}>
        {label}
      </Text>
    </>
  );
}

type FakeInputProps = {
  testID?: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  text: string;
  onPress?(): void;
  ink?: TextProps['ink'];
  variant?: TextProps['variant'];
  inputStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
};

function FakeInput(props: FakeInputProps) {
  const {
    testID,
    leftIcon,
    rightIcon,
    text,
    onPress,
    ink,
    variant,
    inputStyle,
    disabled,
  } = props;

  const forcedLeftIcon =
    leftIcon &&
    enforceIconStyle(leftIcon, 24, { marginRight: Token.spacing.xs });
  const forcedRightIcon =
    rightIcon &&
    enforceIconStyle(rightIcon, 16, { marginLeft: Token.spacing.xs });

  const [isHovered, eventMap] = useHoverable();
  const { color } = useTheme();
  const containerStyle = {
    borderColor: isHovered ? color.tintPrimary : color.lightSecondary,
    backgroundColor: color.lightPrimary,
  };

  return (
    <TouchableOpacity
      testID={appendTestId(testID, 'input.trigger')}
      activeOpacity={0.5}
      onPress={onPress}
      disabled={disabled}
    >
      <View
        {...eventMap}
        style={[Style.inputContainer, containerStyle, inputStyle]}
      >
        {forcedLeftIcon}
        <Text
          numberOfLines={1}
          style={Style.inputText}
          ink={disabled ? 'muted' : ink}
          variant={variant}
        >
          {text}
        </Text>
        {forcedRightIcon}
      </View>
    </TouchableOpacity>
  );
}

function enforceIconStyle(
  icon: ReactElement,
  size: number,
  style: CSSProperties
) {
  return cloneElement(icon, {
    width: size,
    height: size,
    style,
  });
}

const Style = StyleSheet.create({
  dropdownTitle: {
    paddingHorizontal: Token.spacing.m,
    paddingTop: Token.spacing.s,
    paddingBottom: Token.spacing.xs,
  },
  inputContainer: {
    padding: Token.spacing.xs - Token.border.width.thin,
    borderRadius: Token.border.radius.normal,
    borderWidth: Token.border.width.thin,
    transitionDuration: `${Token.animation.timing.instant}ms`,
    transitionProperty: 'border-color',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputText: {
    flex: 1,
  },
  radioGroup: {
    paddingBottom: Token.spacing.xs,
  },
  radio: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.xs,
  },
  radioLabel: {
    marginLeft: Token.spacing.m,
  },
});

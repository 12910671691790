import React, { useMemo, useRef } from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Text, Card, Icon, Token, Button } from '@traveloka/web-components';
import ChevronLeft from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_left_16px.svg';

import { useFlightRequestRefund } from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';
import { useFlightRequestRefundForm } from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import { useFlightRequestRefundNavigator } from 'refund/request-refund/contexts/flight/FlightRequestRefundNavigatorContext';
import {
  LocalRequestRefundError,
  useProductRequestRefundPage,
} from 'refund/request-refund/contexts/ProductRequestRefundPageContext';
import {
  FlightMainRefundReasonWithSecondary,
  FlightSubmitRefundError,
} from 'refund/shared/api/types';
import InfoBox from 'refund/shared/components/InfoBox';
import {
  checkFlightJourneyError,
  checkMainReasonError,
  checkPassengerError,
} from 'refund/shared/utils/request-refund-utils';

import RefundFlightJourneys from './RefundFlightJourneys/RefundFlightJourneys';
import SelectMainReason from './SelectMainReason/SelectMainReason';
import FlightPassengers from './FlightPassengers/FlightPassengers';

export default function FlightRequestRefundSelectDetails() {
  const [pageData, { setError }] = useProductRequestRefundPage();
  const error = pageData.error as Nullable<FlightSubmitRefundError>;
  const { goToNextStep, goToPreviousStep } = useFlightRequestRefundNavigator();
  const [{ data }] = useFlightRequestRefund();
  const { isPartialJourney } = data;

  const {
    flightCheckboxes,
    travelerForms,
    mainRefundReason,
  } = useFlightRequestRefundForm();

  const content = useContentResource()
    .CorporateFlightRequestRefundSelectDetails;

  const flightJourneyError = checkFlightJourneyError(error);
  const mainReasonError = checkMainReasonError(error);
  const passengerError = useRef(checkPassengerError(error));

  const hasSelectFlight = useMemo(
    () => flightCheckboxes.some(checkbox => checkbox.checkboxValue === true),
    [flightCheckboxes]
  );

  const canContinue = useMemo(() => {
    let canContinue = true;
    const allTraveler = {
      ADULT: 0,
      CHILD: 0,
      INFANT: 0,
    };
    const selectedPerType = {
      ADULT: 0,
      CHILD: 0,
      INFANT: 0,
    };
    const allSelectedTraveler = travelerForms.filter(
      ({ checkboxValue: cbVal, traveler: t }) => {
        allTraveler[t.type] += 1;
        if (cbVal) {
          selectedPerType[t.type] += 1;
        }

        return cbVal;
      }
    );

    if (!allSelectedTraveler.length) {
      canContinue = false;
    }

    const isAllAdultSelected = allTraveler.ADULT === selectedPerType.ADULT;
    const isAllSelected = travelerForms.length === allSelectedTraveler.length;
    const isChildInfantOnly = isAllAdultSelected && !isAllSelected;

    const remainingAdult = allTraveler.ADULT - selectedPerType.ADULT;
    const remainingInfant = allTraveler.INFANT - selectedPerType.INFANT;
    const isAdultLessThanInfant = remainingAdult < remainingInfant;

    if (isChildInfantOnly || isAdultLessThanInfant) {
      passengerError.current = true;
      setError(LocalRequestRefundError.MUST_NOT_CHILD_INFANT_ONLY);
      canContinue = false;
    } else if (passengerError.current === true) {
      setError(null);
      passengerError.current = false;
    }

    if (
      mainRefundReason!.mainReason ===
      FlightMainRefundReasonWithSecondary.PERSONAL_REASON
    ) {
      canContinue = allSelectedTraveler.length
        ? allSelectedTraveler.every(
            ({ secondaryReason }) => secondaryReason !== null
          )
        : false;
    }

    return canContinue;
  }, [travelerForms, mainRefundReason]);

  return (
    <>
      <Button
        style={Style.buttonBack}
        size="small"
        variant="text"
        text={content.backButtonText}
        iconStart={() => <Icon src={ChevronLeft} />}
        onPress={() => goToPreviousStep()}
      />
      <Text style={Style.title} variant="headline">
        {content.refundFormHeadline}
      </Text>
      {!isPartialJourney && (
        <InfoBox
          style={Style.partialJourney}
          text={content.partialJourneyInfoText}
        />
      )}
      <Text style={Style.title} variant="title-1">
        {content.flightToRefundTitle}
      </Text>
      <Card
        style={[Style.flightJourneys, flightJourneyError && Style.errorBorder]}
      >
        <RefundFlightJourneys />
      </Card>
      <Text style={Style.title} variant="title-1">
        {content.selectMainReasonTitle}
      </Text>
      <Card
        style={[
          Style.mainReasonContainer,
          mainReasonError && Style.errorBorder,
        ]}
      >
        <SelectMainReason />
      </Card>
      <Text style={Style.title} variant="title-1">
        {content.passengerTitle}
      </Text>
      <Card
        style={[
          Style.passengerContainer,
          passengerError.current && Style.errorBorder,
        ]}
      >
        <FlightPassengers />
      </Card>
      <Button
        style={Style.buttonContinue}
        variant="main-cta"
        text={content.continueButtonText}
        disabled={!hasSelectFlight || !canContinue}
        onPress={() => {
          goToNextStep();
          window.scrollTo(0, 0);
        }}
      />
    </>
  );
}

const Style = StyleSheet.create({
  buttonBack: {
    marginBottom: Token.spacing.m,
    width: 80,
  },
  partialJourney: {
    marginBottom: Token.spacing.l,
  },
  title: {
    marginBottom: Token.spacing.m,
  },
  flightJourneys: {
    marginBottom: Token.spacing.xl,
  },
  mainReasonContainer: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.ml,
    marginBottom: Token.spacing.xl,
    overflow: 'visible',
    zIndex: 2,
  },
  passengerContainer: {
    overflow: 'visible',
    marginBottom: Token.spacing.l,
  },
  shouldRefundAllPaxText: {
    marginLeft: Token.spacing.m,
  },
  buttonContinue: {
    marginLeft: 'auto',
    width: 304,
  },
  errorBorder: {
    borderColor: Token.color.uiRedPrimary,
    borderWidth: Token.border.width.thick,
  },
});

import React from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Token, Icon, Text } from '@traveloka/web-components';
import { InputDropdownRadio } from '@traveloka/ctvweb-ui';
import ChevronDown from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';

import { useFlightRequestRefund } from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';
import {
  useFlightRequestRefundForm,
  useFlightRequestRefundFormAction,
} from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import { FlightMainRefundReason } from 'refund/shared/api/types';

export default function SelectMainReason() {
  const { mainRefundReason } = useFlightRequestRefundForm();
  const { updateSelectedMainReason } = useFlightRequestRefundFormAction();
  const [{ data }] = useFlightRequestRefund();
  const { reasons } = data;

  const content = useContentResource()
    .CorporateFlightRequestRefundSelectDetails;
  const mainRefundReasonContent = useContentResource()
    .CorporateEnumFlightMainRefundReason;

  return (
    <>
      <InputDropdownRadio
        style={Style.mainReasonDropdown}
        cardStyle={Style.mainReasonDropdownCard}
        alignment="left"
        text={mainRefundReasonContent[mainRefundReason!.mainReason]}
        title={content.mainReasonTitle}
        items={reasons.map(reason => ({
          label: mainRefundReasonContent[reason.mainReason],
          value: reason.mainReason,
        }))}
        value={mainRefundReason!.mainReason}
        onChange={(value: FlightMainRefundReason) =>
          updateSelectedMainReason(value)
        }
        rightIcon={<Icon src={ChevronDown} />}
      />
      <Text variant="ui-small" ink="secondary">
        {content.mainReasonDesc}
      </Text>
    </>
  );
}

const Style = StyleSheet.create({
  mainReasonDropdown: {
    zIndex: 1,
    width: 281,
    marginBottom: Token.spacing.xs,
  },
  mainReasonDropdownCard: {
    width: 256,
  },
});

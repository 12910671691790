import React, { CSSProperties } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Info from '@traveloka/icon-kit-web/svg/blueDark/ic_system_status_info-fill_16px.svg';
import WifiNotAvailable from '@traveloka/icon-kit-web/svg/dark/ic_facilities_internet_wi_fi_unavailable_16px.svg';
import MealNotAvailable from '@traveloka/icon-kit-web/svg/dark/ic_facilities_meal_unavailable_16px.svg';
import CancelationNotAvailable from '@traveloka/icon-kit-web/svg/dark/ic_policy_cancellation_unavailable_16px.svg';
import BedType from '@traveloka/icon-kit-web/svg/darkPrimary/ic_hotel_room_bed_type_16px.svg';
import Guest from '@traveloka/icon-kit-web/svg/darkPrimary/ic_hotel_room_guest_16px.svg';
import Measure from '@traveloka/icon-kit-web/svg/darkPrimary/ic_hotel_room_measure_16px.svg';
import WifiAvailable from '@traveloka/icon-kit-web/svg/greenSecondary/ic_facilities_internet_wi_fi_16px.svg';
import MealAvailable from '@traveloka/icon-kit-web/svg/greenSecondary/ic_facilities_meal_16px.svg';
import CancelationAvailable from '@traveloka/icon-kit-web/svg/greenSecondary/ic_policy_check_16px.svg';
import { Icon, Text, Token } from '@traveloka/web-components';

import { appendTestId } from '../../shared/utils/TestUtil';

type IconType =
  | 'size'
  | 'bed'
  | 'guest'
  | 'breakfast'
  | 'wifi'
  | 'cancelation'
  | 'info';

type TextVariant = 'ui-small' | 'ui-tiny';

type Props = {
  /**
   * Override text ink. Have the highest priority to determine text ink
   */
  ink?: 'highlight';
  /**
   * Text ink will be based on this props only when `ink` is undefined
   * - undefined: primary
   * - true: positive
   * - false: secondary
   *
   * For icon variant, only `false` value will result in not available icon
   */
  isAvailable?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  text: string;
  type: IconType;
  variant?: TextVariant;
};

/**
 * For easy access to icon, these array should be in this format
 * [available, not available]
 * */
const iconMap: Record<IconType, [string, string]> = {
  info: [Info, Info],
  size: [Measure, Measure],
  bed: [BedType, BedType],
  guest: [Guest, Guest],
  wifi: [WifiAvailable, WifiNotAvailable],
  breakfast: [MealAvailable, MealNotAvailable],
  cancelation: [CancelationAvailable, CancelationNotAvailable],
};

export default function IconLabel(props: Props) {
  const {
    ink,
    isAvailable,
    style,
    testID,
    text,
    type,
    variant = 'ui-small',
  } = props;

  const [iconLight, iconNoLight] = iconMap[type];
  const textInk =
    ink ??
    (typeof isAvailable === 'boolean'
      ? isAvailable
        ? 'positive'
        : 'secondary'
      : undefined);

  const src = isAvailable === false ? iconNoLight : iconLight;

  return (
    <View style={[Style.icon, style]}>
      <Icon src={src} width={16} height={16} style={iconStyleMap[variant]} />
      <Text
        testID={appendTestId(testID, `${type}.text`)}
        variant={variant}
        ink={textInk}
        style={Style.iconText}
      >
        {text}
      </Text>
    </View>
  );
}

const iconStyleMap: Record<TextVariant, CSSProperties> = {
  'ui-small': { marginTop: 2 },
  'ui-tiny': {},
};

const Style = StyleSheet.create({
  icon: {
    flexDirection: 'row',
  },
  iconText: {
    marginLeft: Token.spacing.xs,
  },
});

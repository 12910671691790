import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useTheme, Image, Token } from '@traveloka/web-components';

import RouteIcon from '../../../svg/16/ic_flight_route_16px.svg';
import Skeleton from '../../shared/components/Skeleton/Skeleton';

type Props = {
  compactLayout?: boolean;
  flexibleFlight?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function FlightSearchResultSkeleton(props: Props) {
  const { compactLayout, style } = props;
  const { color } = useTheme();

  return (
    <View
      style={[Style.container, { borderColor: color.lightSecondary }, style]}
    >
      <View
        style={[getBaseOrCompactStyle('content', compactLayout), Style.content]}
      >
        <AirlineInfoSkeleton {...props} />
        <JourneyActionSkeleton {...props} />
      </View>
      <DetailTogglerSkeleton />
    </View>
  );
}

function AirlineInfoSkeleton(props: Props) {
  const { compactLayout, flexibleFlight } = props;

  return (
    <View
      style={[
        getBaseOrFlexibleStyle('airlineWrapper', flexibleFlight),
        Style.airlineWrapper,
      ]}
    >
      <Skeleton
        width={flexibleFlight ? 40 : 32}
        height={flexibleFlight ? 40 : 32}
      />
      <View style={Style.airlineInfo}>
        <Skeleton width={128} height={flexibleFlight ? 16 : 24} />
        {flexibleFlight && (
          <Skeleton width={128} height={12} style={Style.airlineInfoFlexible} />
        )}
        {flexibleFlight && !compactLayout && (
          <Skeleton width={128} height={12} style={Style.airlineInfoFlexible} />
        )}
      </View>
    </View>
  );
}

function JourneyActionSkeleton(props: Props) {
  const { compactLayout } = props;

  return (
    <View
      style={[
        getBaseOrCompactStyle('journeyActionWrapper', compactLayout),
        Style.journeyActionWrapper,
      ]}
    >
      <View>
        <Skeleton width={compactLayout ? 48 : 96} height={16} />
        <Skeleton
          width={compactLayout ? 72 : 96}
          height={12}
          style={Style.journeyDestination}
        />
      </View>
      <View style={Style.journeyDivider}>
        <Image
          src={RouteIcon}
          width={Token.spacing.xl}
          height={Token.spacing.s}
          objectFit="cover"
        />
      </View>
      <View>
        <Skeleton width={compactLayout ? 48 : 96} height={16} />
        <Skeleton
          width={compactLayout ? 72 : 96}
          height={12}
          style={Style.journeyDestination}
        />
      </View>
      <View style={Style.journeyDuration}>
        <Skeleton width={compactLayout ? 72 : 96} height={16} />
        <Skeleton
          width={compactLayout ? 72 : 96}
          height={12}
          style={Style.journeyDestination}
        />
      </View>
      <Skeleton
        width={compactLayout ? 72 : 96}
        height={24}
        style={Style.journeyFacilities}
      />
      <View style={Style.actionWrapper}>
        <Skeleton width={144} height={24} />
        <Skeleton width={144} height={32} style={Style.action} />
      </View>
    </View>
  );
}

function DetailTogglerSkeleton() {
  return <Skeleton width={200} height={12} />;
}

type BaseOrCompactStyleVariant = 'content' | 'journeyActionWrapper';

function getBaseOrCompactStyle(
  variant: BaseOrCompactStyleVariant,
  compactLayout?: boolean
): any {
  return compactLayout ? Style[`${variant}Compact`] : Style[`${variant}Base`];
}

type BaseOrFlexibleStyleVariant = 'airlineWrapper';

function getBaseOrFlexibleStyle(
  variant: BaseOrFlexibleStyleVariant,
  flexibleFlight?: boolean
): any {
  return flexibleFlight ? Style[`${variant}Flexible`] : Style[`${variant}Base`];
}

const Style: { [key in string]: any } = StyleSheet.create({
  container: {
    padding: Token.spacing.l,
    borderWidth: Token.border.width.thick,
    borderRadius: Token.border.radius.normal,
  },
  content: {
    marginBottom: Token.spacing.l,
    justifyContent: 'flex-start',
  },
  contentBase: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  contentCompact: {
    flexDirection: 'column',
    alignItems: 'stretch',
    alignContent: 'stretch',
  },
  skeleton: {
    overflow: 'hidden',
    borderRadius: Token.border.radius.normal,
  },
  airlineWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  airlineWrapperBase: {
    alignItems: 'center',
    alignContent: 'center',
  },
  airlineWrapperFlexible: {
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  airlineInfo: {
    flex: 1,
    marginLeft: Token.spacing.xs,
  },
  airlineInfoFlexible: {
    marginTop: Token.spacing.xs,
  },
  journeyActionWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  journeyActionWrapperBase: {
    marginLeft: Token.spacing.xl,
  },
  journeyActionWrapperCompact: {
    marginTop: Token.spacing.l,
  },
  journeyDestination: {
    marginTop: Token.spacing.xs,
  },
  journeyDivider: {
    height: Token.typography.uiBaseline.lineHeight,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    marginHorizontal: Token.spacing.m,
  },
  journeyDuration: {
    marginLeft: Token.spacing.xl,
  },
  journeyFacilities: {
    marginLeft: Token.spacing.xl,
  },
  actionWrapper: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
  },
  action: {
    marginTop: Token.spacing.xs,
  },
});

import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import uniq from 'lodash/uniq';

import { useTheme, Badge, Text, Token } from '@traveloka/web-components';

import { FlightJourneyData } from '../types';

type Props = {
  journey: FlightJourneyData;
  policies: string[];
  style: StyleProp<ViewStyle>;
};

const dot = '\u2022';

export default function RefundInfo(props: Props) {
  const { journey, policies, style } = props;

  const { color } = useTheme();

  if (policies.length === 0) {
    return null;
  }

  const {
    0: firstSegment,
    [journey.segments.length - 1]: lastSegment,
  } = journey.segments;

  const title = [
    firstSegment.departureLocation,
    lastSegment.arrivalLocation,
  ].join(' → ');

  const subtitle = uniq(
    journey.segments.map(segment => segment.airlineLogo.alt)
  )
    .concat(firstSegment.seatClass)
    .concat(firstSegment.departureDate)
    .join(` ${dot} `);

  const refundInfo = journey.refund;

  const policyListStyle = {
    backgroundColor: color.lightNeutral,
    borderColor: color.lightSecondary,
  };

  return (
    <View style={[Style.refundInfo, style]}>
      <View style={Style.summary}>
        <View>
          <Text variant="ui-small">{title}</Text>
          <Text variant="ui-small" ink="secondary">
            {subtitle}
          </Text>
        </View>
        {refundInfo && (
          <Badge
            variant={refundInfo.isAvailable ? 'positive' : 'neutral'}
            text={refundInfo.decription}
          />
        )}
      </View>
      {policies.length > 0 && (
        <View style={[Style.policyList, policyListStyle]}>
          {policies.map(policy => (
            <View style={Style.policy}>
              <Text variant="ui-small" ink="secondary" style={Style.bullet}>
                {'\u2022'}
              </Text>
              <Text variant="ui-small" ink="secondary">
                {policy}
              </Text>
            </View>
          ))}
        </View>
      )}
    </View>
  );
}

const Style = StyleSheet.create({
  refundInfo: {
    paddingHorizontal: Token.spacing.m,
  },
  summary: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingVertical: Token.spacing.xs,
  },
  detailToggle: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: Token.spacing.xxs,
  },
  policyList: {
    marginHorizontal: -Token.spacing.m,
    paddingHorizontal: Token.spacing.m,
    paddingBottom: Token.spacing.m,
    borderTopWidth: Token.border.width.thick,
    borderBottomWidth: Token.border.width.thick,
  },
  policy: {
    marginTop: Token.spacing.s,
    flexDirection: 'row',
  },
  bullet: {
    width: 16,
    userSelect: 'none',
  },
});

import React from 'react';
import { StyleProp, ViewStyle, View } from 'react-native';
import { useController, useFormContext } from 'react-hook-form';

import { Checkbox } from '@traveloka/web-components';

import { FieldProps } from '../types';

type CheckboxProps = {
  onChange?: (value: boolean) => void;
  /**
   * @default false
   */
  disabled?: boolean;
  /**
   * @default true
   */
  inline?: boolean;
  /**
   * Use custom colour
   * @default Token.color.uiBluePrimary
   */
  color?: string;
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  testID?: string;
  style?: StyleProp<ViewStyle>;
};

type Props = FieldProps<boolean> & CheckboxProps;

export default function CheckboxField(props: Props) {
  const {
    name,
    validate,
    onChange,
    style,
    defaultValue,
    ...checkboxFieldProps
  } = props;

  const { control } = useFormContext();
  const {
    field: { value, onChange: onChangeField },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });

  function handleChange(value: boolean) {
    onChangeField(value);
    onChange && onChange(value);
  }

  return (
    <View style={style}>
      <Checkbox
        {...checkboxFieldProps}
        onChange={handleChange}
        checked={value ?? false}
      />
    </View>
  );
}

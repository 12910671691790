import { ProductType } from 'payment/select/api';

// ===== Credit Payment
export const CREDIT_PAYMENT = '/api/v1/booking/credit-payment';
export type CreditPaymentRequest = {
  bookingId: string;
  productType: ProductType;
};

export type CreditPaymentResponse = {
  creditPaymentStatus: CreditPaymentStatus;
};
export type CreditPaymentStatus = 'SUFFICIENT' | 'INSUFFICIENT';

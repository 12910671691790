import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const PurchaseList: PrivatePageComponent = function() {
  return null;
};

PurchaseList.routeProtection = function() {
  return { path: '/booking' };
};

export default PurchaseList;

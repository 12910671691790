import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Token, Image, Card } from '@traveloka/web-components';
import {
  GenericHomeContent_v2,
  GenericHomeSectionName,
  GenericHomeButton,
  GenericHomeWatchDemoButton,
} from '@traveloka/ctvweb-ui/generic';
import { DRNHtmlText } from '@traveloka/district-ui';

type Props = {
  content: {
    sectionName: string;
    heading: string;
    useCaseTitle01: string;
    useCaseTitle02: string;
    useCaseTitle03: string;
    useCaseDescription01: string;
    useCaseDescription02: string;
    useCaseDescription03: string;
    useCaseImageUrl01: string;
    useCaseImageUrl02: string;
    useCaseImageUrl03: string;
    bannerText: string;
    signUpButtonText: string;
    watchDemoButtonText: string;
  };
  onPressSignUp(): void;
  onPressWatchVideo(): void;
};

export default function GenericHomeUseCases(props: Props) {
  const { content, onPressSignUp, onPressWatchVideo } = props;

  const paragraphHtmlStyle = {
    fontSize: Token.typography.uiBaseline.fontSize,
    lineHeight: Token.typography.uiBaseline.lineHeight,
    margin: 0,
  };

  return (
    <GenericHomeContent_v2 spacing="xxxxl">
      <GenericHomeSectionName text={content.sectionName} />
      <Text variant="hero" style={Style.heading}>
        {content.heading}
      </Text>

      <View style={Style.flexBox}>
        <View style={Style.featuredArticleColumn}>
          <View style={Style.article}>
            <View style={Style.articleImage}>
              <Image
                src={content.useCaseImageUrl01}
                objectFit="cover"
                style={Style.image}
              />
            </View>
            <Text variant="headline" style={Style.articleHeading}>
              {content.useCaseTitle01}
            </Text>
            <View style={Style.flexAuto}>
              <DRNHtmlText
                htmlText={'<p>' + content.useCaseDescription01 + '</p>'}
                htmlStyles={{
                  p: paragraphHtmlStyle,
                }}
              />
            </View>
          </View>
        </View>

        <View style={Style.flexAuto}>
          <View style={[Style.flexBox, Style.article]}>
            <View style={Style.articleImageColumn}>
              <View style={Style.articleImage}>
                <Image
                  src={content.useCaseImageUrl02}
                  objectFit="cover"
                  style={Style.image}
                />
              </View>
              <Text variant="headline" style={Style.articleHeading}>
                {content.useCaseTitle02}
              </Text>
            </View>
            <View style={Style.flexAuto}>
              <DRNHtmlText
                htmlText={'<p>' + content.useCaseDescription02 + '</p>'}
                htmlStyles={{
                  p: paragraphHtmlStyle,
                }}
              />
            </View>
          </View>

          <View style={[Style.flexBox, Style.article]}>
            <View style={Style.articleImageColumn}>
              <View style={Style.articleImage}>
                <Image
                  src={content.useCaseImageUrl03}
                  objectFit="cover"
                  style={Style.image}
                />
              </View>
              <Text variant="headline" style={Style.articleHeading}>
                {content.useCaseTitle03}
              </Text>
            </View>
            <View style={Style.flexAuto}>
              <DRNHtmlText
                htmlText={'<p>' + content.useCaseDescription03 + '</p>'}
                htmlStyles={{
                  p: paragraphHtmlStyle,
                }}
              />
            </View>
          </View>
        </View>
      </View>

      <Card elevation="flat" style={Style.banner}>
        <Text variant="headline" style={Style.bannerText}>
          {content.bannerText}
        </Text>
        <View style={Style.flexBox}>
          <GenericHomeButton
            text={content.signUpButtonText}
            style={Style.bannerButton}
            onPress={onPressSignUp}
          />
          <GenericHomeWatchDemoButton
            text={content.watchDemoButtonText}
            onPress={onPressWatchVideo}
          />
        </View>
      </Card>
    </GenericHomeContent_v2>
  );
}

const Style = StyleSheet.create({
  heading: {
    marginTop: Token.spacing.xxxl,
    marginBottom: Token.spacing.xxxxl,
  },
  flexBox: {
    flexDirection: 'row',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  article: {
    marginBottom: Token.spacing.xxl * 2,
  },
  articleImage: {
    height: 120,
    width: 'auto',
    borderRadius: Token.border.radius.normal,
  },
  image: {
    width: '100%',
    overflow: 'hidden',
  },
  articleHeading: {
    marginTop: Token.spacing.s,
    marginBottom: Token.spacing.m,
  },
  featuredArticleColumn: {
    flexBasis: 272,
    marginRight: Token.spacing.xxl * 2,
  },
  articleImageColumn: {
    flexBasis: 272,
    marginRight: Token.spacing.l,
  },
  flexAuto: {
    flex: 1,
  },
  banner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingVertical: Token.spacing.xxxl,
    paddingHorizontal: Token.spacing.xl,
  },
  bannerText: {
    flex: 1,
    paddingRight: Token.spacing.l,
  },
  bannerButton: {
    marginRight: Token.spacing.m,
  },
  triangleMask: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightColor: 'transparent',
  },
});

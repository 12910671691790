import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useTheme, Token } from '@traveloka/web-components';

type Props = {
  /**
   * Skeleton height in pixel
   */
  height: number;
  /**
   * Additional style
   */
  style?: StyleProp<ViewStyle>;
  /**
   * Skeleton width in pixel
   */
  width?: number;
};

export default function Skeleton(props: Props) {
  const { color } = useTheme();
  const { height, style, width } = props;
  const startingColor = Token.opacity.clear(color.darkSecondary);
  const endingColor = Token.opacity.seeThrough(color.darkSecondary);

  return (
    <View
      style={[
        Style.skeleton,
        style,
        {
          // @ts-ignore,
          backgroundImage: `linear-gradient(to right, ${startingColor} 0%, ${endingColor} 25%, ${startingColor} 50%)`,
          height,
          width,
        },
      ]}
    />
  );
}

const Style = StyleSheet.create({
  skeleton: {
    animationDuration: `750ms`,
    backgroundSize: `200% 100%`,
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    borderRadius: Token.border.radius.normal,
    willChange: 'background-position',
    animationKeyframes: [
      {
        from: { backgroundPosition: `100% 0` },
        to: { backgroundPosition: `-100% 0` },
      },
    ],
  },
});

import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import WarningIcon from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_system_status_warning-fill_24px.svg';
import { useTheme, Icon, Text, Token } from '@traveloka/web-components';

type Props = {
  label: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

export default function FlightCompliance(props: Props) {
  const { testID, label, style } = props;
  const { color } = useTheme();

  return (
    <View
      testID={testID}
      style={[Style.compliance, { backgroundColor: color.lightStain }, style]}
    >
      <Icon
        src={WarningIcon}
        width={Token.spacing.m}
        height={Token.spacing.m}
      />
      <Text variant="ui-tiny" ink="secondary" style={Style.complianceLabel}>
        {label}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  compliance: {
    flexDirection: 'row',
    paddingHorizontal: Token.spacing.xs,
    paddingVertical: Token.spacing.xxs,
  },
  complianceLabel: {
    marginLeft: Token.spacing.xs,
  },
});

import PaymentMethod from 'payment/shared/constants/PaymentMethod';
import { JavaCurrencyValue } from 'shared/utils/currency';

import JourneyType from '../constants/journey-type';
import SeatClass from '../constants/seat-class';
import {
  BookingDetailPnrCode,
  FlightAddOns,
  FlightBookingDetail,
  FlightNonEmployeeTraveler,
  FlightSearchResult,
  FlightTable,
  Passengers,
  RefundPolicy,
  SmartComboReturnResult,
  SmartComboSearchResult,
} from './types';

// Prebook - Refund
export const REFUND_POLICY_API = '/api/v1/booking/flight/refund-policy';
export type RefundPolicyRequest = {
  journeyType: JourneyType;
  flightIds: string[];
};
export type RefundPolicyResponse = {
  flights: Array<{
    refundPolicies: RefundPolicy[];
  }>;
};

// Prebook - Addons
export const ADDONS_API = '/api/v1/booking/flight/add-ons';
export type AddOnsRequest = {
  journeyType: JourneyType;
  flightIds: string[];
};
export type AddOnsResponse = {
  flights: FlightAddOns[];
};

// Booking rules
export const BOOKING_RULES_API = '/api/v1/booking/flight/check-rules';
export type BookingRulesRequest = {
  journeyType: JourneyType;
  flightIds: string[];
};
export type BookingRulesResponse = {
  requiresBirthDateForInternational: boolean;
  requiresBirthDate: boolean;
  requiresBirthDateForChildren: boolean;
  requiresBirthDateForInfant: boolean;
  requiresDocumentNoForInternational: boolean;
  requiresNationality: boolean;
  requiresDocumentNoForDomestic: boolean;
  requiresId: boolean;
};

// Calculate transaction fee
export const CALCULATE_TRANSACTION_FEE_API =
  '/api/v1/booking/flight/calculate-transaction-fee';
export type CalculateTransactionFeeRequest = {
  totalFareFromClient: JavaCurrencyValue;
};
export type CalculateTransactionFeeResponse = {
  totalFareFromClient: JavaCurrencyValue;
  serviceFee?: Nullable<JavaCurrencyValue>;
  vatFee?: Nullable<JavaCurrencyValue>;
  grandTotal?: Nullable<JavaCurrencyValue>;
};

// Prebook - Submit booking
export const SUBMIT_BOOKING_API = '/api/v1/booking/submit';
export type SubmitBookingRequest = {
  type: 'FLIGHT';
  confirmSubmit: boolean;
  locale: string;
  policyBypassRemarks?: string;
  productRequestId?: string;
  attachmentUrl?: string;
  notes?: string;
  tripRequestId?: string;
  contact: {
    phoneNumber: string;
    phoneNumberPrefix: string;
  };
  totalFareFromClient: JavaCurrencyValue;
  flight: {
    journeyType: JourneyType;
    passengers: Passengers;
    flightIds: Array<string>;
    airlineCodes: Array<string>;
  };
  trackingSpec?: {
    pageName: string;
    pageCategory: string;
    pageTitle?: string;
    pageReferrer?: string;
    data?: Dictionary<Nullable<string>>;
  };
  serviceFee?: Nullable<JavaCurrencyValue>;
  vatFee?: Nullable<JavaCurrencyValue>;
  grandTotal?: Nullable<JavaCurrencyValue>;
};
export type SubmitBookingResponse = {
  bookingId: string;
  tripId: string;
  status: 'BOOKED' | 'FAILED';
  bookingFailureReason:
    | 'PRICE_CHANGED'
    | 'SOLD_OUT'
    | 'INVALID_SPEC'
    | 'CANCELLED'
    | 'FAILED'
    | 'CREDIT_LIMIT_NOT_SUFFICIENT'
    | 'NON_COMPLYING'
    | 'BOOKING_SPEC_NOT_ALLOWED'
    | null;
  bookingErrorMessage: string | null;
  paymentExpirationTime: string; // (YYYY-MM-DD HH:MI);
  detailPrice: Array<{
    description: string;
    price: JavaCurrencyValue;
  }>;
  totalPrice: JavaCurrencyValue;
  totalPriceCharged: JavaCurrencyValue;
  serviceFee: Nullable<JavaCurrencyValue>;
  vatFee: Nullable<JavaCurrencyValue>;
  grandTotal: Nullable<JavaCurrencyValue>;
};

// Flight Search
export const ONE_WAY_API = '/api/v1/search/flight/one-way';
export const ROUND_TRIP_API = '/api/v1/search/flight/basic-round-trip';
export type FlightSearchRequest = {
  depAirportOrAreaCode: string;
  arrAirportOrAreaCode: string;
  depDate: string;
  retDate?: string;
  direction: 'AWAY' | 'RETURN';
  seatClass: SeatClass;
  emails: string[];
  nonEmployeeTravelers: FlightNonEmployeeTraveler;
  tripRequestId?: string;
  trackingSpec?: {
    pageName: string;
    pageCategory: string;
    pageTitle?: string;
    pageReferrer?: string;
    data?: Dictionary<string>;
  };
};
export type FlightSearchResponse = {
  completed: boolean;
  searchResults: FlightSearchResult[];
};

// Static Data
export const AIRLINE_DATA_API = '/api/v2/search/flight/static/airlines';
export type AirlineDataResponse = {
  airlines: Array<{
    airlineCode: string;
    airlineName: string;
    logoUrl: string;
  }>;
};

export const AIRPORT_DATA_API = '/api/v2/search/flight/static/airports';
export type AirportDataResponse = {
  airports: Array<{
    airportCode: string;
    airportIcaoCode: string;
    areaCode: string;
    city: string;
    countryCode: string;
    countryId: string;
    internationalAirportName: string;
    timeZone: string;
  }>;
};

// Smart Combo
export const SMART_COMBO_API = '/api/v1/search/flight/smart-combo';
export type SmartComboSearchRequest = {
  depAirportOrAreaCode: string;
  arrAirportOrAreaCode: string;
  depDate: string;
  retDate: string;
  seatClass: string;
  emails: string[];
  nonEmployeeTravelers: FlightNonEmployeeTraveler;
  tripRequestId?: string;
  trackingSpec?: {
    pageName: string;
    pageCategory: string;
    pageTitle?: string;
    pageReferrer?: string;
    data?: Dictionary<string>;
  };
};
export type SmartComboSearchResponse = {
  completed: boolean;
  departureFlightDetail: SmartComboSearchResult[];
  returnFlightDetail: SmartComboReturnResult[];
  flightTable: FlightTable[];
};

// Booking Detail
export const FLIGHT_DETAIL = '/api/v1/booking/flight/detail';
export type BookingDetailsRequest = {
  bookingId: string;
};
export type BookingDetailsResponse = {
  paymentMethod: PaymentMethod;
  bookingSubmissionStatus: string;
  bookingFailureReason: string;
  bookingId: string;
  tripId: string;
  paymentExpirationTimeInSecond: string;
  flightBookingDetail: FlightBookingDetail;
  pnrCode: Array<BookingDetailPnrCode[]>;
  grandTotal: Nullable<JavaCurrencyValue>;
  serviceFee: Nullable<JavaCurrencyValue>;
  vatFee: Nullable<JavaCurrencyValue>;
  totalPriceCharged: JavaCurrencyValue;
};

// Prebooking Attachment Upload
export const PREBOOKING_ATTACHMENT_UPLOAD = '/api/v1/booking/upload';
export type PrebookingAttachmentUploadRequest = {
  type: 'BOOKING';
};
export type PrebookingAttachmentUploadResponse = {
  url: string;
  isSuccess: boolean;
};

import { Token } from '@traveloka/web-components';
import { CreditLimitStatus } from 'credit-limit/context';

export function creditLimitColorSwitcher(
  status: CreditLimitStatus | undefined
) {
  switch (status) {
    case 'EMPTY':
    case 'MINUS':
      return Token.color.uiDarkSecondary;
    case 'VERY_LOW':
      return Token.color.uiRedPrimary;
    case 'LOW':
      return Token.color.uiYellowPrimary;
    case 'SUFFICIENT':
      return Token.color.uiGreenPrimary;
    default:
      return Token.color.uiLightStain;
  }
}

export function creditLimitPercentageInkSwitcher(status: CreditLimitStatus) {
  switch (status) {
    case 'EMPTY':
    case 'SUFFICIENT':
    case 'VERY_LOW':
    case 'MINUS':
      return {
        color: Token.color.uiLightPrimary,
      };
    case 'LOW':
      return {
        color: Token.color.uiDarkPrimary,
      };
  }
}

import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useHistory } from 'react-router';

import { useContentResource } from '@traveloka/ctv-core/resource';
import Clock from '@traveloka/icon-kit-web/svg/darkBlue/ic_system_clock_16px.svg';
import {
  useTheme,
  Button,
  Icon,
  Popup,
  Text,
  Token,
} from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';
import { formatMessage } from 'shared/utils/intl';

type Props = {
  expireOn: number;
};

export default function ExpirationTimer(props: Props) {
  const { expireOn } = props;

  const [timeLeft, setTimeLeft] = useState(expireOn);
  const [isExpired, setIsExpired] = useState(false);
  const { color } = useTheme();

  const content = useContentResource().CorporateProductPayment;
  const expiredModalContent = useContentResource()
    .CorporateProductPaymentExpiredModal;

  const history = useLocalizedHistory();
  const goToHome = useCallback(() => history.push('/'), []);

  useEffect(() => {
    let forcedId: number;
    const id = window.setInterval(() => {
      if (timeLeft <= 0) {
        clearInterval(id);
        setIsExpired(true);
        forcedId = window.setTimeout(goToHome, 10000);
      } else {
        setTimeLeft(prev => prev - 1);
      }
    }, 1000);

    return () => {
      window.clearInterval(id);
      window.clearTimeout(forcedId);
    };
  }, [expireOn]);

  return (
    <>
      <View style={[Style.header, { backgroundColor: color.tintLight }]}>
        <Text
          testID="confirmation-payment.content.payment-timer"
          variant="ui-small"
          ink="highlight"
          style={Style.countdown}
        >
          {formatMessage(content.expirationTime, {
            time: getRemainingTime(timeLeft),
          })}
        </Text>
        <Icon src={Clock} />
      </View>
      <Modal isVisible={isExpired}>
        <Popup
          showCloseButton={false}
          title={expiredModalContent.title}
          width={480}
          minHeight={150}
        >
          <Text>{expiredModalContent.body}</Text>
          <View style={Style.control}>
            <Button
              onPress={goToHome}
              text={expiredModalContent.closeButtonText}
            />
          </View>
        </Popup>
      </Modal>
    </>
  );
}

const Style = StyleSheet.create({
  header: {
    padding: Token.spacing.s,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  countdown: {
    marginRight: Token.spacing.xs,
  },
  control: {
    marginTop: Token.spacing.m,
    alignItems: 'flex-end',
  },
});

function getRemainingTime(remainingSecond: number) {
  var hours = Math.floor((remainingSecond / 60 / 60) % 24);
  var minutes = Math.floor((remainingSecond / 60) % 60);
  var seconds = remainingSecond % 60;

  const hour = ('0' + hours).slice(-2);
  const minute = ('0' + minutes).slice(-2);
  const second = ('0' + seconds).slice(-2);

  return `${hour}:${minute}:${second}`;
}

enum Permission {
  BOOK_PRODUCT_FOR_OWN = 'y:cbookown',
  BOOK_PRODUCT_FOR_OTHERS = 'y:cbookoth',
  BOOK_PRODUCT_FOR_NON_EMPLOYEE = 'y:cbookne',
  ISSUE_PAYMENT_AND_BOOKING = 'y:cpurc',
  BOOKING_READ = 'r:xbook',
  BOOKING_MODIFY = 'm:xbook',
  PURCHASE_READ = 'r:xpurc',
  CORPORATE_READ = 'r:xcorp',
  CORPORATE_CREATE = 'c:xcorp',
  CORPORATE_UPDATE = 'u:xcorp',
  CORPORATE_DELETE = 'd:xcorp',
  DIVISION_READ = 'r:xdivs',
  DIVISION_CREATE = 'c:xdivs',
  DIVISION_UPDATE = 'u:xdivs',
  DIVISION_DELETE = 'd:xdivs',
  EXPENSE_REPORT_VIEW = 'r:xexp',
  INVOICE_LIST_VIEW = 'r:xinv',
  TIER_READ = 'r:xtier',
  TIER_CREATE = 'c:xtier',
  TIER_UPDATE = 'u:xtier',
  TIER_DELETE = 'd:xtier',
  POLICY_READ = 'r:xpolc',
  POLICY_CREATE = 'c:xpolc',
  POLICY_UPDATE = 'u:xpolc',
  POLICY_DELETE = 'd:xpolc',
  USER_READ = 'r:xuser',
  USER_CREATE = 'c:xuser',
  USER_UPDATE = 'u:xuser',
  USER_DELETE = 'd:xuser',
  USER_ROLE_READ = 'r:xrole',
  USER_ROLE_CREATE = 'c:xrole',
  USER_ROLE_UPDATE = 'u:xrole',
  USER_ROLE_DELETE = 'd:xrole',
  APPROVAL_CONFIG_VIEW = 'r:xappr',
  APPROVAL_CONFIG_MODIFY = 'm:xappr',
  BILLING_READ = 'r:xbill',
  BILLING_MODIFY = 'm:xbill',
}

export default Permission;

import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Card, Text, Token } from '@traveloka/web-components';

import { useFlightRequestRefundForm } from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import { RefundDocument } from 'refund/shared/api/types';
import { useRefundDocumentSwitcher } from 'refund/shared/utils/request-refund-utils';

import RefundFileUpload from '../RefundFileUpload/RefundFileUpload';
import RefundInput from '../RefundInput/RefundInput';

const DOT = '•';

type Props = {
  isIdenticalTrip: boolean;
  travelerIndex: number;
};

export default function PassengerDocumentCard(props: Props) {
  const { mainRefundReason } = useFlightRequestRefundForm();
  const { travelerForms } = useFlightRequestRefundForm();
  const {
    traveler,
    secondaryReason: secondaryReasonProps,
    checkboxValue,
  } = travelerForms[props.travelerIndex];

  const content = useContentResource().CorporateFlightRequestRefundDocument;
  const mainRefundReasonContent = useContentResource()
    .CorporateEnumFlightMainRefundReason;
  const personalReasonContent = useContentResource()
    .CorporateEnumFlightPersonalRefundReason;
  const travelerTitleContent = useContentResource().CorporateEnumTravelerTitle;

  if (!checkboxValue) {
    return null;
  }

  const secondaryReason = secondaryReasonProps
    ? `${DOT} ${personalReasonContent[secondaryReasonProps]}`
    : undefined;

  return (
    <Card style={Style.container}>
      <View style={Style.header}>
        <Text variant="title-1">{`${travelerTitleContent[traveler.title]} ${
          traveler.fullname
        }`}</Text>
        <Text variant="ui-baseline" ink="secondary">
          {content.refundReasonText}{' '}
          {mainRefundReasonContent[mainRefundReason!.mainReason]}{' '}
          {secondaryReason}
        </Text>
      </View>
      <DocumentList {...props} />
    </Card>
  );
}

function DocumentList(props: Props) {
  const { mainRefundReason, travelerForms } = useFlightRequestRefundForm();
  const { isIdenticalTrip, travelerIndex } = props;
  const { secondaryReason, traveler, requiredDocument } = travelerForms[
    travelerIndex
  ];

  const currentSecondaryReason = mainRefundReason!.secondaryReasons?.find(
    reason => reason.reason === secondaryReason
  );

  const refundDocumentSwitcher = useRefundDocumentSwitcher();

  return (
    <>
      {currentSecondaryReason?.requiredDocument.map((document, index) => {
        const refundDocument = refundDocumentSwitcher(document);

        return (
          <View key={index} style={Style.itemContainer}>
            <Text style={Style.itemTitle} variant="ui-small">
              {refundDocument.title}
            </Text>
            {refundDocument.description && (
              <Text style={Style.itemDesc} variant="ui-tiny" ink="secondary">
                {refundDocument.description}
              </Text>
            )}
            {document === RefundDocument.DOUBLE_BOOKING_PNR ? (
              <RefundInput
                travelerId={traveler.travelerId}
                value={requiredDocument[document]?.valueToSubmit}
                document={document}
                isIdenticalTrip={isIdenticalTrip}
              />
            ) : (
              <RefundFileUpload
                travelerId={traveler.travelerId}
                documentCategory={document}
                document={requiredDocument[document]}
              />
            )}
          </View>
        );
      })}
    </>
  );
}

const Style = StyleSheet.create({
  container: {
    marginBottom: Token.spacing.m,
  },
  header: {
    paddingHorizontal: Token.spacing.l,
    paddingTop: Token.spacing.l,
    paddingBottom: Token.spacing.m,
  },
  itemContainer: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.l,
    borderTopColor: Token.color.borderDivide,
    borderTopWidth: Token.border.width.thick,
  },
  itemTitle: {
    marginBottom: Token.spacing.xxs,
  },
  itemDesc: {
    marginBottom: Token.spacing.xs,
  },
});

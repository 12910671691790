import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import { Token } from '@traveloka/web-components';

import { convertNonEmployeeTypeToPayload } from 'flight/search/utils/flight-search-spec-util';
import {
  BaggageAddOns as RawBaggageAddOns,
  FlightNonEmployeeTraveler,
  JourneyAddOns,
  NonEmployeeType,
} from 'flight/shared/api/types';
import { useFlightsContext } from 'flight/prebook/contexts/FlightPrebookContext';
import { usePrice } from 'flight/prebook/contexts/PriceContext';
import { convert } from 'shared/utils/currency';
import { formatCurrency, formatMessage } from 'shared/utils/intl';

import BaggageField, {
  Props as BaggageFieldProps,
} from 'shared/components/form/BaggageField/BaggageField';

type Props = {
  type?: keyof FlightNonEmployeeTraveler;
  flightIndex: number;
  journeyAddOnsIndex: number;
  adultIndex: number;
  journeyAddOn: JourneyAddOns;
  testID?: string;
};

type BaggageAddOn = {
  departure: string;
  arrival: string;
  options: BaggageOption[];
};

type BaggageOption = {
  label: string;
  value: RawBaggageAddOns;
};

export default function BaggageAddonForm(props: Props) {
  const {
    testID,
    type = convertNonEmployeeTypeToPayload(NonEmployeeType.ADULT),
    journeyAddOn,
    adultIndex,
    flightIndex,
    journeyAddOnsIndex,
  } = props;

  const content = useContentResource().CorporateFlightPrebookTravelerForm;
  const flights = useFlightsContext();

  const flight = flights[flightIndex];
  const journey = flight.journeys[journeyAddOnsIndex];
  const {
    0: firstSegment,
    [journey.segments.length - 1]: lastSegment,
  } = journey.segments;

  const { set, remove } = usePrice();

  const baggageAddOns = useMemo<BaggageAddOn | BaggageAddOn[]>(() => {
    const isJourney =
      journeyAddOn.availableAddOnsOptions &&
      journeyAddOn.availableAddOnsOptions.baggageOptions.length > 0;

    if (isJourney) {
      const { departureAirport } = firstSegment;
      const { arrivalAirport } = lastSegment;

      return {
        departure: departureAirport.airportCode,
        arrival: arrivalAirport.airportCode,
        options: journeyAddOn.availableAddOnsOptions!.baggageOptions.map(
          mapBaggage
        ),
      };
    }

    return journeyAddOn.segmentsWithAvailableAddOns.map(
      ({ segment, availableAddOnsOptions }) => ({
        departure: segment.sourceAirport,
        arrival: segment.destinationAirport,
        options: availableAddOnsOptions.baggageOptions.map(mapBaggage),
      })
    );
  }, [journeyAddOn, firstSegment, lastSegment]);

  const normalizedAddOns = useMemo(
    () => (Array.isArray(baggageAddOns) ? baggageAddOns : [baggageAddOns]),
    [baggageAddOns]
  );

  useEffect(() => {
    const hasBaggage = normalizedAddOns.length > 0;
    const { city, airportCode } = lastSegment.arrivalAirport;

    hasBaggage &&
      set(airportCode, {
        label: formatMessage(content.baggageCityText, { city }),
        value: convert(normalizedAddOns[0].options[0].value.priceWithCurrency),
      });

    return () => {
      hasBaggage && remove(airportCode);
    };
  }, [normalizedAddOns, lastSegment, set]);

  return (
    <>
      {normalizedAddOns.map((addOn, segmentAddonIndex) => {
        const { arrival, departure, options } = addOn;
        const fieldNameSuffix = Array.isArray(baggageAddOns)
          ? `[${segmentAddonIndex}]`
          : ``;

        return (
          <BaggageFieldItem
            testID={appendTestId(testID, `${flightIndex}-${segmentAddonIndex}`)}
            airportCode={lastSegment.arrivalAirport.airportCode}
            key={[adultIndex, flightIndex, segmentAddonIndex].join('-')}
            name={`passengers.${type}[${adultIndex}].addOns[${flightIndex}].journeys[${journeyAddOnsIndex}]${fieldNameSuffix}`}
            label={[departure, arrival].join(' - ')}
            defaultValue={options[0].value}
            items={options}
            style={Style.field}
          />
        );
      })}
    </>
  );
}

type BaggageFieldItemProps = {
  airportCode: string;
} & BaggageFieldProps;

function BaggageFieldItem(props: BaggageFieldItemProps) {
  const { airportCode, ...rest } = props;

  const prevRef = useRef(convert(props.defaultValue!.priceWithCurrency).amount);
  const { update } = usePrice();

  const handlePressItem = useCallback(
    (option: BaggageOption) => {
      const currentValue = convert(option.value.priceWithCurrency);

      update(airportCode, currentValue.amount - prevRef.current);
      prevRef.current = currentValue.amount;
    },
    [update, airportCode]
  );

  return <BaggageField {...rest} onPressItem={handlePressItem} />;
}

const Style = StyleSheet.create({
  field: {
    paddingHorizontal: Token.spacing.s,
    width: 'calc(100% / 3)',
  },
});

// ===== HELPERS
function mapBaggage(baggage: RawBaggageAddOns) {
  let label = '';
  const value = convert(baggage.priceWithCurrency);

  switch (baggage.baggageType) {
    case 'KG':
      label = `${baggage.baggageWeight} kg`;
      break;
    case 'PIECE':
      label = `${baggage.baggageQuantity} piece`;
      break;
  }

  return {
    label: `${label} - ${formatCurrency(value)}`,
    value: baggage,
  };
}

import React, { useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { Checkbox, Text, Token } from '@traveloka/web-components';
import { Item } from './DropdownChecklist';

type Props<T> = {
  testID?: string;
  item: Item<T>;
  onPress: (checked: boolean, value: Item<T>) => void;
};

export default function DropdownChecklistItem<T = string>(props: Props<T>) {
  const { testID, onPress, item } = props;

  const handleOnChange = useCallback(
    (checked: boolean) => {
      onPress(checked, item);
    },
    [item, onPress]
  );

  return (
    <View style={Style.checkbox}>
      <Checkbox
        testID={testID}
        checked={item.checked}
        onChange={handleOnChange}
        label={
          <Text style={item.value === '' && Style.boldLabel}>{item.label}</Text>
        }
      />
    </View>
  );
}

const Style = StyleSheet.create({
  checkbox: {
    paddingVertical: Token.spacing.xs,
  },
  boldLabel: Token.typography.weightMedium,
});

import {
  addDays,
  addMonths,
  addYears,
  isValid,
  parse,
  startOfDay,
  subMonths,
  subYears,
} from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import {
  useContentResource,
  useFeatureControl,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { Card, Label, Text, Token, useTheme } from '@traveloka/web-components';

import {
  useAddOnsContext,
  useBookingRuleContext,
  useFlightsContext,
  useIsInternationalContext,
  useNonEmployeeTravelersContext,
  usePassengersContext,
} from 'flight/prebook/contexts/FlightPrebookContext';
import { Style as SharedStyle } from 'flight/prebook/flight-prebook.style';
import { FlightNonEmployeeForm, PrebookForm } from 'flight/prebook/types';
import { convertNonEmployeeTypeToPayload } from 'flight/search/utils/flight-search-spec-util';
import { NonEmployeeType } from 'flight/shared/api/types';
import CountryField from 'shared/components/form/CountryField/CountryField';
import DateField from 'shared/components/form/DateField/DateField';
import HiddenField from 'shared/components/form/HiddenField/HiddenField';
import InputDropdownField from 'shared/components/form/InputDropdownField/InputDropdownField';
import InputField from 'shared/components/form/InputField/InputField';
import { JAVA_DATE } from 'shared/utils/date';
import { formatMessage } from 'shared/utils/intl';
import {
  isAlphaNumeric,
  isEmail,
  isName,
  maxLength,
  minLength,
  required,
} from 'shared/utils/validator';

import BaggageAddonForm from '../AddonForm/BaggageAddonForm';
import Collapsible, { CollapsibleHandler } from '../Collapsible/Collapsible';

export default function NonEmployeeTravelers() {
  const passengers = usePassengersContext();
  const nonEmployeeTravelers = useNonEmployeeTravelersContext();

  const content = useContentResource().CorporateFlightPrebookTravelerForm;
  const getContent = useNonEmployeeFormContent();

  if (!nonEmployeeTravelers.length) {
    return null;
  }

  const nonEmployeeIndex = {
    ADULT: passengers.length - 1,
    CHILD: -1,
    INFANT: -1,
  };

  return (
    <View style={Style.container}>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {content.nonEmployeeTitle}
      </Text>
      {nonEmployeeTravelers.map((traveler, index) => {
        nonEmployeeIndex[traveler.type]++;
        const showContent = getContent(traveler.type);
        return (
          <NonEmployeeTravelerItem
            {...traveler}
            collapsibleTitle={showContent.type}
            dobDescription={showContent.dobDesc}
            nonEmployeeIndex={nonEmployeeIndex[traveler.type]}
            index={index}
            zIndex={nonEmployeeTravelers.length - index}
          />
        );
      })}
    </View>
  );
}

type Props = FlightNonEmployeeForm & {
  collapsibleTitle: string;
  dobDescription: string;
  nonEmployeeIndex: number;
  index: number;
  zIndex: number;
};

function NonEmployeeTravelerItem(props: Props) {
  const {
    collapsibleTitle,
    dobDescription,
    nonEmployeeIndex,
    index,
    zIndex,
    type: nonEmployeeType,
    ...initialValues
  } = props;

  const collapsibleRef = useRef<CollapsibleHandler>();
  const { color } = useTheme();
  const flightAddOns = useAddOnsContext();
  const flights = useFlightsContext();
  const bookingRule = useBookingRuleContext();
  const isInternational = useIsInternationalContext();
  const {
    formState: { errors, isSubmitSuccessful },
    control,
  } = useFormContext<PrebookForm>();
  const passengersForm = useWatch({
    control,
    name: 'passengers',
  });

  const { format } = useLocalizedDateFormat();
  const content = useContentResource().CorporateFlightPrebookTravelerForm;
  const titleContext = useContentResource().CorporateEnumTravelerTitle;
  const titleItems = useMemo(
    () =>
      nonEmployeeType === NonEmployeeType.ADULT
        ? [
            { label: titleContext.MR, value: 'MR' },
            { label: titleContext.MRS, value: 'MRS' },
            { label: titleContext.MISS, value: 'MISS' },
          ]
        : [
            { label: titleContext.MR, value: 'MR' },
            { label: titleContext.MISS, value: 'MISS' },
          ],
    []
  );
  const { enabled } = useFeatureControl('b2b-document-type-new-behavior');

  useEffect(() => {
    if (!isSubmitSuccessful) {
      const hasError = !!errors.passengers?.[
        convertNonEmployeeTypeToPayload(nonEmployeeType)
      ]?.at?.(nonEmployeeIndex)?.message;

      if (collapsibleRef.current && hasError) {
        collapsibleRef.current.setIsCollapsed(false);
      }
    }
  }, [errors, isSubmitSuccessful]);

  const departureDate = flights[0].summary.departureDateTime;
  const departureYear = departureDate.getFullYear();

  const currentNationality =
    passengersForm?.[convertNonEmployeeTypeToPayload(nonEmployeeType)]?.[
      nonEmployeeIndex
    ]?.nationality;

  let requiresBirthDate = false;
  switch (nonEmployeeType) {
    case NonEmployeeType.ADULT:
      requiresBirthDate = isInternational
        ? bookingRule.requiresBirthDateForInternational
        : bookingRule.requiresBirthDate;
      break;
    case NonEmployeeType.CHILD:
      requiresBirthDate = bookingRule.requiresBirthDateForChildren;
      break;
    case NonEmployeeType.INFANT:
      requiresBirthDate = bookingRule.requiresBirthDateForInfant;
      break;
  }

  const internationalFieldsProps: InternationalFieldsProps = useMemo(
    () => ({
      departureDate,
      departureYear,
      nonEmployeeIndex,
      nonEmployeeType,
    }),
    []
  );

  const isDomesticId =
    !isInternational && flights[0].summary.departureAirport.countryId === 'ID';
  const isDomesticNonId =
    !isInternational && flights[0].summary.departureAirport.countryId !== 'ID';
  const isSameNationalityAsRoute =
    currentNationality === flights[0].summary.departureAirport.countryId;

  return (
    <Card style={[SharedStyle.section, Style.section, { zIndex }]}>
      <Collapsible
        touchableTestID={`pre-book.form.non-employee-traveler.${index}.colapsible`}
        title={
          <>
            <Text>
              {collapsibleTitle} {nonEmployeeIndex + 1}
            </Text>
          </>
        }
        defaultIsCollapsed={false}
        // @ts-ignore
        ref={collapsibleRef}
      >
        <>
          <HiddenField
            name={`passengers.${convertNonEmployeeTypeToPayload(
              nonEmployeeType
            )}[${nonEmployeeIndex}].type`}
            value={nonEmployeeType}
          />
          <View style={[SharedStyle.group, Style.flexRow, { zIndex: 4 }]}>
            <View style={[Style.shortField, Style.titleField]}>
              <InputDropdownField
                testID={`pre-book.form.non-employee-traveler.${index}.title`}
                name={`passengers.${convertNonEmployeeTypeToPayload(
                  nonEmployeeType
                )}[${nonEmployeeIndex}].title`}
                label={content.travelerTitleField}
                items={titleItems}
                defaultValue={initialValues.title}
                validate={value => {
                  if (required(value) === false) {
                    return content.travelerTitleRequiredErrorMessage;
                  }

                  return;
                }}
              />
            </View>
            <InputField
              name={`passengers.${convertNonEmployeeTypeToPayload(
                nonEmployeeType
              )}[${nonEmployeeIndex}].fullName`}
              label={content.fullNameField}
              validate={value => {
                if (required(value) === false) {
                  return content.fullNameRequiredErrorMessage;
                } else if (isName(value) === false) {
                  return content.fullNameInvalidCharacterErrorMessage;
                }

                return;
              }}
            />
          </View>
          {nonEmployeeType === 'ADULT' && (
            <View style={SharedStyle.group}>
              <Label text={content.emailField} />
              <Text
                style={Style.emailSubtitle}
                variant="ui-tiny"
                ink="secondary"
              >
                {content.emailFieldSubtitle}
              </Text>
              <View style={Style.mediumField}>
                <InputField
                  name={`passengers.${convertNonEmployeeTypeToPayload(
                    nonEmployeeType
                  )}[${nonEmployeeIndex}].email`}
                  validate={value => {
                    if (value && !isEmail(value)) {
                      return content.emailFormatErrorMessage;
                    }
                    return;
                  }}
                />
              </View>
            </View>
          )}
          <View style={[SharedStyle.row, { zIndex: 3 }]}>
            {requiresBirthDate && (
              <View style={[SharedStyle.col, SharedStyle.group]}>
                <DateField
                  name={`passengers.${convertNonEmployeeTypeToPayload(
                    nonEmployeeType
                  )}[${nonEmployeeIndex}].dateOfBirth`}
                  label={content.dobField}
                  leftHelper={dobDescription}
                  maxYear={departureYear - getEarliestYear(nonEmployeeType)}
                  yearRange={getYearRange(nonEmployeeType)}
                  validate={value => {
                    const isRequired = content.DobRequiredErrorMessage;
                    if (value === undefined) {
                      return isRequired;
                    }

                    const { year, month, day } = value;

                    if (year === '' || month === '' || day === '') {
                      return isRequired;
                    }

                    const date = parse(
                      `${year}-${Number(month) + 1}-${day}`,
                      JAVA_DATE,
                      0
                    );
                    const minDate = startOfDay(
                      addDays(
                        subYears(departureDate, getOldestYear(nonEmployeeType)),
                        1
                      )
                    );
                    const maxDate = startOfDay(
                      subMonths(
                        subYears(
                          departureDate,
                          getEarliestYear(nonEmployeeType)
                        ),
                        getEarliestMonth(nonEmployeeType)
                      )
                    );

                    if (!isValid(date)) {
                      return isRequired;
                    } else if (date < minDate) {
                      return formatMessage(content.earliestDobChosen, {
                        date: format(minDate, 'SHORT_MONTH'),
                      });
                    } else if (date > maxDate) {
                      return formatMessage(content.latestDobChosen, {
                        date: format(maxDate, 'SHORT_MONTH'),
                      });
                    }

                    return;
                  }}
                />
              </View>
            )}
            <View style={[SharedStyle.col, SharedStyle.group]}>
              <CountryField
                testID={`pre-book.form.non-employee-traveler.${index}.nationality`}
                name={`passengers.${convertNonEmployeeTypeToPayload(
                  nonEmployeeType
                )}[${nonEmployeeIndex}].nationality`}
                label={content.nationalityField}
                defaultValue={initialValues.nationality}
                validate={value => {
                  if (required(value) === false) {
                    return content.nationalityRequiredErrorMessage;
                  }

                  return;
                }}
              />
            </View>
          </View>

          {!isInternational && (
            <>
              {!enabled && (
                <HiddenField
                  name={`passengers.${convertNonEmployeeTypeToPayload(
                    nonEmployeeType
                  )}[${nonEmployeeIndex}].documentDetail.documentType`}
                  value={bookingRule.requiresId ? 'NATIONAL_ID' : 'PASSPORT'}
                />
              )}
              <DomesticFields
                showField={!!currentNationality}
                internationalFieldsProps={internationalFieldsProps}
                nonEmployeeIndex={nonEmployeeIndex}
                nonEmployeeType={nonEmployeeType}
                isSameNationalityAsRoute={isSameNationalityAsRoute}
                isDomesticId={isDomesticId}
                isDomesticNonId={isDomesticNonId}
              />
            </>
          )}

          {isInternational && bookingRule.requiresDocumentNoForInternational && (
            <>
              {!enabled && (
                <HiddenField
                  name={`passengers.${convertNonEmployeeTypeToPayload(
                    nonEmployeeType
                  )}[${nonEmployeeIndex}].documentDetail.documentType`}
                  value="PASSPORT"
                />
              )}
              <InternationalFields {...internationalFieldsProps} />
            </>
          )}

          {(nonEmployeeType === 'ADULT' || nonEmployeeType === 'CHILD') &&
            flightAddOns.length > 0 && (
              <View style={{ zIndex: 1 }}>
                <View
                  style={[
                    Style.addOnTitle,
                    SharedStyle.section,
                    { backgroundColor: color.lightStain },
                  ]}
                >
                  <Text style={SharedStyle.bold}>{content.addOnTitle}</Text>
                </View>
                <Text>{content.baggageLabel}</Text>
                {flightAddOns.map((flightAddOn, flightIndex) => (
                  <View
                    style={[
                      Style.row,
                      Style.addOnFlightWrapper,
                      { zIndex: flightAddOns.length - flightIndex },
                    ]}
                  >
                    {flightAddOn.journeysWithAvailableAddOnsOptions.map(
                      (journeyAddOn, journeyAddOnsIndex) => (
                        <BaggageAddonForm
                          key={journeyAddOnsIndex}
                          type={convertNonEmployeeTypeToPayload(
                            nonEmployeeType
                          )}
                          flightIndex={flightIndex}
                          journeyAddOnsIndex={journeyAddOnsIndex}
                          adultIndex={nonEmployeeIndex}
                          journeyAddOn={journeyAddOn}
                          testID={`pre-book.form.non-employee-traveler.${nonEmployeeIndex}.baggage.${flightIndex}.${journeyAddOnsIndex}`}
                        />
                      )
                    )}
                  </View>
                ))}
              </View>
            )}
        </>
      </Collapsible>
    </Card>
  );
}

type InternationalFieldsProps = {
  departureYear: number;
  departureDate: Date;
  nonEmployeeType: NonEmployeeType;
  nonEmployeeIndex: number;
};

function InternationalFields(props: InternationalFieldsProps) {
  const {
    nonEmployeeType,
    nonEmployeeIndex,
    departureDate,
    departureYear,
  } = props;

  const content = useContentResource().CorporateFlightPrebookTravelerForm;
  const { format } = useLocalizedDateFormat();
  const { enabled } = useFeatureControl('b2b-document-type-new-behavior');

  return (
    <>
      {enabled && (
        <HiddenField
          name={`passengers.${convertNonEmployeeTypeToPayload(
            nonEmployeeType
          )}[${nonEmployeeIndex}].documentDetail.documentType`}
          value="PASSPORT"
        />
      )}
      <View style={{ zIndex: 2 }}>
        <View style={[SharedStyle.group, Style.mediumField]}>
          <InputField
            name={`passengers.${convertNonEmployeeTypeToPayload(
              nonEmployeeType
            )}[${nonEmployeeIndex}].documentDetail.documentNo`}
            label={content.passportNumberField}
            validate={value => {
              if (required(value) === false) {
                return content.passportNumberRequiredErrorMessage;
              } else if (isAlphaNumeric(value) === false) {
                return content.passportNumberAlphanumericErrorMessage;
              } else if (minLength(value, 6) === false) {
                return formatMessage(
                  content.passportNumberMinLengthErrorMessage,
                  { length: 6 }
                );
              } else if (maxLength(value, 9) === false) {
                return formatMessage(
                  content.passportNumberMaxLengthErrorMessage,
                  { length: 9 }
                );
              }

              return;
            }}
          />
        </View>
        <View style={[SharedStyle.row, SharedStyle.group]}>
          <View style={SharedStyle.col}>
            <CountryField
              name={`passengers.${convertNonEmployeeTypeToPayload(
                nonEmployeeType
              )}[${nonEmployeeIndex}].documentDetail.issuingCountry`}
              label={content.issuingCountryField}
              validate={value => {
                if (required(value) === false) {
                  return content.issuingCountryRequiredErrorMessage;
                }

                return;
              }}
            />
          </View>
          <View style={SharedStyle.col}>
            <DateField
              name={`passengers.${convertNonEmployeeTypeToPayload(
                nonEmployeeType
              )}[${nonEmployeeIndex}].documentDetail.expirationDate`}
              label={content.expirationDateField}
              maxYear={departureYear + 23}
              yearRange={24}
              reverse
              validate={value => {
                const isRequired = content.expirationDateRequiredErrorMessage;
                if (value === undefined) {
                  return isRequired;
                }

                const { year, month, day } = value;

                if (year === '' || month === '' || day === '') {
                  return isRequired;
                }

                const date = parse(
                  `${year}-${Number(month) + 1}-${day}`,
                  JAVA_DATE,
                  0
                );
                const minDate = addMonths(departureDate, 6);
                const maxDate = addYears(departureDate, 24);

                if (!isValid(date)) {
                  return isRequired;
                } else if (date < minDate) {
                  return formatMessage(content.earliestexpirationDateChosen, {
                    date: format(minDate, 'SHORT_MONTH'),
                  });
                } else if (date > maxDate) {
                  return formatMessage(content.latestexpirationDateChosen, {
                    date: format(maxDate, 'SHORT_MONTH'),
                  });
                }

                return;
              }}
            />
          </View>
        </View>
      </View>
    </>
  );
}

type DomesticFieldsProps = {
  showField: boolean;
  internationalFieldsProps: InternationalFieldsProps;
  nonEmployeeType: NonEmployeeType;
  nonEmployeeIndex: number;
  isSameNationalityAsRoute: boolean;
  isDomesticId: boolean;
  isDomesticNonId: boolean;
};

function DomesticFields(props: DomesticFieldsProps) {
  const {
    showField,
    nonEmployeeIndex,
    nonEmployeeType,
    internationalFieldsProps,
    isSameNationalityAsRoute,
    isDomesticId,
    isDomesticNonId,
  } = props;

  const content = useContentResource().CorporateFlightPrebookTravelerForm;
  // TODO: Need to create separate PR to move all common validation CR to new entry
  const validationContent = useContentResource().CorporateRegistration;
  const bookingRule = useBookingRuleContext();
  const { enabled } = useFeatureControl('b2b-document-type-new-behavior');

  if (!showField) {
    return null;
  }

  // Issue: https://29022131.atlassian.net/browse/CTV-5999
  // If and only if `requiresId` is true and `requiresDocumentNoForDomestic` is true and route is domestic non id
  // Then will bypass the KTP field and render Passport instead
  const patchPassportAndKtpIssue =
    isDomesticNonId &&
    bookingRule.requiresId &&
    bookingRule.requiresDocumentNoForDomestic;

  const patchDomesticNonIdRequiresIdIssue =
    isDomesticNonId && !isSameNationalityAsRoute;
  if (
    bookingRule.requiresId &&
    !patchPassportAndKtpIssue &&
    !patchDomesticNonIdRequiresIdIssue
  ) {
    return (
      <>
        {enabled && (
          <HiddenField
            name={`passengers.${convertNonEmployeeTypeToPayload(
              nonEmployeeType
            )}[${nonEmployeeIndex}].documentDetail.documentType`}
            value="NATIONAL_ID"
          />
        )}
        <View style={[SharedStyle.group, Style.mediumField]}>
          <InputField
            name={`passengers.${convertNonEmployeeTypeToPayload(
              nonEmployeeType
            )}[${nonEmployeeIndex}].documentDetail.documentNo`}
            label={content.identityNumberField}
            validate={value => {
              if (value && value.length > 16) {
                return formatMessage(validationContent.validationMaxLength, {
                  value: 16,
                });
              }

              if (!isAlphaNumeric(value)) {
                return formatMessage(
                  validationContent.validationAlphaNumericOnly,
                  { field: content.identityNumberField }
                );
              }
              return;
            }}
          />
        </View>
      </>
    );
  } else if (
    bookingRule.requiresDocumentNoForDomestic &&
    ((!isSameNationalityAsRoute && isDomesticId) || isDomesticNonId)
  ) {
    return <InternationalFields {...internationalFieldsProps} />;
  }

  return null;
}

const Style = StyleSheet.create({
  container: {
    zIndex: 1,
  },
  section: {
    overflow: 'visible',
  },
  flexRow: {
    flexDirection: 'row',
  },
  addOnTitle: {
    paddingVertical: Token.spacing.s,
    paddingHorizontal: Token.spacing.m,
    marginHorizontal: -Token.spacing.m,
  },
  addOnFlightWrapper: {
    marginHorizontal: -Token.spacing.s,
    flexDirection: 'row',
  },
  row: {
    marginTop: Token.spacing.m,
  },
  shortField: {
    width: '33.33%',
  },
  mediumField: {
    width: '66.66%',
  },
  titleField: {
    marginRight: Token.spacing.l,
  },
  emailSubtitle: {
    marginTop: Token.spacing.xs,
    marginBottom: Token.spacing.s,
  },
});

export function useNonEmployeeFormContent() {
  const content = useContentResource().CorporateFlightPrebookTravelerForm;
  return useCallback(
    (type: NonEmployeeType) => {
      switch (type) {
        case NonEmployeeType.ADULT:
          return {
            type: content.adultText,
            dobDesc: content.dobAdultDescription,
          };
        case NonEmployeeType.CHILD:
          return {
            type: content.childText,
            dobDesc: content.dobChildDescription,
          };
        case NonEmployeeType.INFANT:
          return {
            type: content.infantText,
            dobDesc: content.dobInfantDescription,
          };
      }
    },
    [content]
  );
}

function getEarliestMonth(type: NonEmployeeType) {
  // Current Month - <return value>
  switch (type) {
    case NonEmployeeType.ADULT:
      return 0;
    case NonEmployeeType.CHILD:
      return 0;
    case NonEmployeeType.INFANT:
      return 1;
  }
}

function getEarliestYear(type: NonEmployeeType) {
  // Current Year - <return value>
  switch (type) {
    case NonEmployeeType.ADULT:
      return 12;
    case NonEmployeeType.CHILD:
      return 2;
    case NonEmployeeType.INFANT:
      return 0;
  }
}

function getOldestYear(type: NonEmployeeType) {
  // Current Year - <return value>
  switch (type) {
    case NonEmployeeType.ADULT:
      return 112;
    case NonEmployeeType.CHILD:
      return 12;
    case NonEmployeeType.INFANT:
      return 2;
  }
}

function getYearRange(type: NonEmployeeType) {
  // Current Year - <return value>
  switch (type) {
    case NonEmployeeType.ADULT:
      return 101;
    case NonEmployeeType.CHILD:
      return 11;
    case NonEmployeeType.INFANT:
      return 3;
  }
}

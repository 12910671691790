import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native';

import { useTheme, Icon, Text, Token } from '@traveloka/web-components';
import { useHoverable } from '@traveloka/web-components/future';

type MenuProps = {
  testID?: string;
  isActive?: boolean;
  onPress?(): void;
  text: string;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  activeStyle?: StyleProp<ViewStyle>;
};

type MenuPropsWithLeftIcon = MenuProps & {
  leftIcon: string;
  leftIconActive: string;
};

type MenuPropsWithLRightIcon = MenuProps & {
  rightIcon: string;
  rightIconActive: string;
};

type MenuPropsWithIcons = MenuProps & {
  leftIcon: string;
  leftIconActive: string;
  rightIcon: string;
  rightIconActive: string;
};

type Props =
  | MenuProps
  | MenuPropsWithLeftIcon
  | MenuPropsWithLRightIcon
  | MenuPropsWithIcons;

export default function MenuItem(props: Props) {
  const {
    testID,
    isActive,
    onPress,
    text,
    textStyle: textStyleProps,
    style,
    activeStyle: activeStyleProps,
  } = props;
  const { color } = useTheme();

  const textStyle = textStyleProps || {
    color: isActive ? color.uiLightPrimary : color.uiDarkSecondary,
  };

  const [isHovered, eventHandlers] = useHoverable();
  const hoveredStyle = isHovered && {
    backgroundColor: String(Token.opacity.washedOut(color.lightSecondary)),
  };
  const activeStyle =
    isActive &&
    (activeStyleProps || {
      backgroundColor: color.uiBluePrimary,
    });

  return (
    <TouchableOpacity testID={testID} activeOpacity={0.5} onPress={onPress}>
      <View
        {...eventHandlers}
        style={[Style.menu, hoveredStyle, activeStyle, style]}
      >
        {'leftIcon' in props && (
          <View style={Style.leftIcon}>
            <Icon src={isActive ? props.leftIconActive : props.leftIcon} />
          </View>
        )}
        <Text style={textStyle}>{text}</Text>
        {'rightIcon' in props && (
          <View style={Style.rightIcon}>
            <Icon src={isActive ? props.rightIconActive : props.rightIcon} />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}

const Style = StyleSheet.create({
  menu: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: Token.spacing.m,
    transitionProperty: 'background-color',
    transitionDuration: `${Token.animation.timing.instant}ms`,
    marginHorizontal: Token.spacing.xs,
  },
  leftIcon: {
    marginRight: Token.spacing.xs,
  },
  rightIcon: {
    marginLeft: 'auto',
  },
});

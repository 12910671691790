import React from 'react';
import { View, ViewProps } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { useTheme, Checkbox, Icon, Text } from '@traveloka/web-components';

import {
  useFlightSearchOptionContext,
  useFlightSearchPreferenceFilterContext,
} from 'flight/search/contexts/FlightSortFilterContext';

import { getThemeStyle, CommonStyle } from '../filter-search-toolbar.style';

type Props = {
  style?: ViewProps['style'];
};

function MorePreferenceFilter(props: Props) {
  const { style } = props;
  const { CorporateFlightSearchToolbar } = useContentResource();
  const content = {
    title: CorporateFlightSearchToolbar.morePreference,
    excludeOvernightTransit: CorporateFlightSearchToolbar.moreExcludeOvernight,
    excludeLateNightFlights: CorporateFlightSearchToolbar.moreExcludeLateNight,
    excludeMultipleCheckins: CorporateFlightSearchToolbar.moreExcludeMultiple,
  };

  const ThemeStyle = getThemeStyle(useTheme());
  const { filterOption, setFilter } = useFlightSearchOptionContext();
  const values = useFlightSearchPreferenceFilterContext();

  return (
    <View style={style}>
      <Text ink="primary" variant="ui-small">
        {content.title}
      </Text>
      <View style={[CommonStyle.container, ThemeStyle.container]}>
        {filterOption.preference.map(option => (
          <View key={option.value} style={CommonStyle.checkbox}>
            <Checkbox
              checked={values.includes(option.value)}
              disabled={option.disabled}
              onChange={() => setFilter('preference', option.value)}
            >
              <Checkbox.Control />
              <View style={CommonStyle.checkboxTextJustify}>
                <Text
                  ink={option.disabled ? 'muted' : 'primary'}
                  variant="ui-small"
                >
                  {content[option.value]}
                </Text>
                <Icon src="" />
              </View>
            </Checkbox>
          </View>
        ))}
      </View>
    </View>
  );
}

export default MorePreferenceFilter;

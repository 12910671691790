import React from 'react';

import countryInfo from 'shared/static-data/country-info';

import { FieldProps } from '../types';
import InputDropdownField, {
  Props as InputDropdownFieldProps,
} from '../InputDropdownField/InputDropdownField';

type Props = FieldProps<string> & Omit<InputDropdownFieldProps, 'items'>;

export default function CountryField(props: Props) {
  return (
    <InputDropdownField {...props} items={countryList} editable searchable />
  );
}

const countryList = countryInfo.map(info => ({
  label: info.countryName,
  value: info.countryId,
}));

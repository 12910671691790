import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Card, Collapsible, Text, Token } from '@traveloka/web-components';

import { usePrebook } from 'hotel/prebook/contexts/PrebookProvider';
import SharedStyle from 'hotel/prebook/hotelPrebook.style';
import { convert } from 'shared/utils/currency';
import { formatCurrency, formatMessage } from 'shared/utils/intl';

type PriceBreakdownType = {
  label: string;
  value: string;
};

export default function PriceBreakdown() {
  const { room, spec } = usePrebook();

  const content = useContentResource().CorporateHotelPrebookPriceBreakdown;

  const roomPrice = formatCurrency(convert(room.totalFare));
  const grandTotal = formatCurrency(convert(room.grandTotal || room.totalFare));

  const breakdownText = [
    `(${spec.numOfRooms}x)`,
    room.roomName,
    `(${formatMessage(content.duration, { num: spec.duration })})`,
  ].join(' ');

  const priceBreakdown = [
    { label: breakdownText, value: roomPrice },
    room.serviceFee && {
      label: content.serviceFeeText,
      value: formatCurrency(convert(room.serviceFee)),
    },
    room.vatFee && {
      label: content.vatFeeText,
      value: formatCurrency(convert(room.vatFee)),
    },
  ].filter(Boolean) as PriceBreakdownType[];

  return (
    <>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {content.title}
      </Text>
      <Card style={SharedStyle.section}>
        <Collapsible
          title={
            <View style={[Style.spread, Style.header]}>
              <Text>{content.totalPriceText}</Text>
              <Text variant="ui-large">{grandTotal}</Text>
            </View>
          }
        >
          <>
            {priceBreakdown.map((item, index) => (
              <View key={index} style={Style.spread}>
                <Text>{item.label}</Text>
                <Text>{item.value}</Text>
              </View>
            ))}
          </>
        </Collapsible>
      </Card>
    </>
  );
}

const Style = StyleSheet.create({
  spread: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    alignItems: 'center',
  },
  item: {
    marginBottom: Token.spacing.xs,
  },
});

import React from 'react';
import { StyleSheet, View, createElement } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Divider } from '@traveloka/ctvweb-ui';
import HotelIcon from '@traveloka/icon-kit-web/svg/darkPrimary/ic_product_hotel-fill_24px.svg';
import { Icon, Text, Token, useTheme } from '@traveloka/web-components';

import { useHotelRequestRefund } from 'refund/request-refund/contexts/hotel/HotelRequestRefundContext';

export default function PolicyCard() {
  const [{ data }] = useHotelRequestRefund();
  const { color } = useTheme();

  const content = useContentResource().CorporateHotelRequestRefundPolicy;

  return (
    <>
      <View style={[Style.header, { borderBottomColor: color.borderDivide }]}>
        <Icon src={HotelIcon} />
        <Text style={Style.policyTitle} variant="title-1">
          {content.policyTitle}
        </Text>
      </View>
      <View style={Style.content}>
        <Text variant="title-1">{data.propertyName}</Text>
        <Divider />
        <Text style={Style.policyPointTitle} variant="title-3">
          {content.generalRefundPolicyTitle}
        </Text>
        <ul>
          {content.generalRefundPolicyContent
            .split('|')
            .map(text =>
              createElement(
                'li',
                { style: Style.policyPoint },
                <Text variant="ui-small">{text}</Text>
              )
            )}
        </ul>
      </View>
    </>
  );
}

const Style = StyleSheet.create({
  header: {
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.ml,
    borderLeftWidth: Token.border.width.bold,
    borderLeftColor: Token.color.uiBluePrimary,
    borderBottomWidth: Token.border.width.thick,
    flexDirection: 'row',
  },
  policyTitle: {
    marginLeft: Token.spacing.ml,
  },
  content: {
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.ml,
  },
  policyPointTitle: {
    marginTop: Token.spacing.xxs,
    marginBottom: Token.spacing.m,
  },
  policyPoint: {
    marginTop: Token.spacing.xxs,
    marginBottom: Token.spacing.m,
    listStyle: 'disc',
  },
});

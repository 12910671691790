import { useCallback, useEffect } from 'react';

import { getParser } from 'bowser';

import {
  ATTACHMENT_ACTIVITY,
  LANDING_PAGE,
  ORS_PAGE,
  USER_ACTIVITY,
} from '@traveloka/ctv-core/tracking/api';

import { ApiResult } from '../api/types';
import useApi from '../api/useApi';
import { useAuth } from '../auth';
import { getVisitId, removeVisitId } from './utils';

export type TrackingEvent = {
  eventName: string;
  /**
   * Use semantic versioning, initial value should be 1.0.0
   *
   * If you modify event in a non-backward compatible way (eg. change type from INTEGER to STRING),
   * increment X, and set Y to 0.
   *
   * If you modify event in a backward compatible way (eg. add new field),
   * increment Y.
   */
  eventVersion: string;
  /**
   * Current page name or screen name
   */
  pageName: string;
  /**
   * Title on the screen or page
   */
  pageTitle?: string;
  /**
   * Previous `pageName`, if the event does not change page no need to set this
   */
  pageReferrer?: string;
  /**
   * Parent category of the page
   */
  pageCategory: string;
} & Dictionary<any>;

type TrackerModifierFn = {
  send(options?: ModifierOptions): Promise<ApiResult<unknown>>;
};

type ModifierOptions = {
  maxWaitMs: number;
};

const defaultOptions: ModifierOptions = {
  maxWaitMs: 300,
};
const events: object[] = [];
let timeout: number | undefined;

const TRACKING_INTERVAL = 10000;

/**
 * Each path has no difference in the front-end side.
 * Only to provide for the back-end (leaner for them) */

const path = {
  'landing-page': LANDING_PAGE,
  'user-activity': USER_ACTIVITY,
  'ors-page': ORS_PAGE,
  'attachment-activity': ATTACHMENT_ACTIVITY,
};

export default function useTracker(key: keyof typeof path) {
  // TODO: get these values from provider once multi locale and currency are enabled
  const language = 'en';
  const country = 'ID';
  const currency = 'IDR';
  const clientInterface = 'desktop';
  const visitId = getVisitId();

  const fetch = useApi({
    domain: 'management',
    method: 'post',
    path: path[key],
    withAuth: false,
  });
  const { user } = useAuth();

  const trackerModifier: TrackerModifierFn = {
    // Send tracking data immediately, this returns a promise that can be await-ed to make sure
    // tracking is sent before doing some action (usually reload-type navigation). We didn't throw
    // if failed here because tracking shouldn't block user action. If user want to handle error case,
    // they can do it manually by checking res.success property
    send(options = defaultOptions) {
      const { maxWaitMs } = options;
      const latestEvent = events.pop();
      const date = new Date();
      const apiCall = fetch({
        sentTimestamp: date.getTime(),
        timezoneOffset: date.getTimezoneOffset() * -60000,
        events: [latestEvent],
        currency,
      });
      const apiLatencyBuffer: Promise<ApiResult<unknown>> = new Promise(
        resolve => {
          setTimeout(
            resolve({
              success: false,
              error: new Error(`Tracking API took longer than ${maxWaitMs}ms`),
            }),
            maxWaitMs
          );
        }
      );

      // We return Promise.race to make sure tracking API doesn't block navigation events
      // for too long. 300ms is acceptable upper-bound latency for tracking API on regular
      // 3G network (based on unscientific tests)
      return Promise.race([apiCall, apiLatencyBuffer]);
    },
  };

  return useCallback(
    (event: string, data: TrackingEvent): TrackerModifierFn => {
      const timestamp = Date.now();
      const userAgent = window.navigator.userAgent;
      const browser = getParser(userAgent).getBrowser();
      let userLoginId;
      let sessionId;

      if (user) {
        userLoginId = user.email;
        sessionId = `${user.email};${user.loginTime}`;
      }

      events.push({
        event,
        data: Object.assign(
          {},
          {
            eventBusinessUnit: 'CORP_B2B',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            clientTimestamp: timestamp,

            intf: clientInterface,
            userAgent: userAgent,

            userSettingsCountry: country,
            userSettingsLang: language,
            userSettingsCurrency: currency,

            userCorporateUserId: user?.email,
            userCorporateId: user?.corporateCode,
            userCorporateName: user?.corporateName,

            webUrl: window.location.href,
            webReferrer: window.location.href,
            webBrowser: browser.name,
            webBrowserVersion: browser.version,

            visitId,
            userLoginId,
            sessionId,
          },
          data
        ),
        timestamp,
      });

      if (timeout === undefined) {
        timeout = window.setTimeout(async () => {
          const collectedEvents = events.splice(0);
          const date = new Date();
          const res = await fetch({
            sentTimestamp: date.getTime(),
            timezoneOffset: date.getTimezoneOffset() * -60000,
            events: collectedEvents,
            currency,
          });

          if (!res.success) {
            // If tracking data is failed to be sent, add them back in the same order
            // as it was previously, to make sure that order of events is consistent
            events.unshift(...collectedEvents);
          }

          // reset timeout value so we can schedule another batch later
          timeout = undefined;
        }, TRACKING_INTERVAL);
      }

      return trackerModifier;
    },
    [language, country, currency]
  );
}

export function useRemoveTrackerVisitId() {
  useEffect(() => {
    // Remove visitId every close or verify for tracking purpose
    return () => removeVisitId();
  }, []);
}

import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useApi, useContentResource } from '@traveloka/ctv-core';
import Skeleton from '@traveloka/ctvweb-ui/src/shared/components/Skeleton/Skeleton';
import { Button, Card, Text, Token } from '@traveloka/web-components';

import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';
import { convert } from 'shared/utils/currency';
import { formatCurrency } from 'shared/utils/intl';

import useInvoiceUnpaid from '../hooks/useInvoiceUnpaid';
import {
  INVOICE_DISPUTE_API,
  InvoiceDisputeRequest,
  InvoiceDisputeResponse,
} from '../api';
import ConfirmationModal from './ConfirmationModal';

export default function InvoiceDispute() {
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(
    false
  );

  const { data, isLoading } = useInvoiceUnpaid();

  const history = useLocalizedHistory();

  const cr = useContentResource().CorporateInvoiceDispute;

  const invoiceDispute = useApi<InvoiceDisputeResponse, InvoiceDisputeRequest>({
    domain: 'booking',
    method: 'post',
    path: INVOICE_DISPUTE_API,
  });

  function handleYes() {
    invoiceDispute({}).then(res => {
      if (res.success) {
        setIsConfirmationModalVisible(true);
      }
    });
  }

  function handleRedirect() {
    history.push('/company/invoice');
  }

  return (
    <>
      <Card style={styles.container}>
        <Text style={styles.title} variant="title-1">
          {cr.title}
        </Text>
        {data ? (
          <>
            {data.invoices.map((invoice, index) => (
              <Card key={index} style={styles.detail}>
                <Text
                  style={[styles.text, Token.typography.weightMedium]}
                  variant="ui-small"
                >
                  {cr.numInvoiceText}:{' '}
                  {isLoading ? (
                    <Skeleton height={14} width={224} />
                  ) : (
                    <Text variant="ui-small">{invoice.statementNumber}</Text>
                  )}
                </Text>
                <Text
                  style={[styles.text, Token.typography.weightMedium]}
                  variant="ui-small"
                >
                  {cr.descriptionText}:{' '}
                  {isLoading ? (
                    <Skeleton height={14} width={77} />
                  ) : (
                    <Text variant="ui-small">{invoice.type}</Text>
                  )}
                </Text>
                <Text
                  style={[styles.text, Token.typography.weightMedium]}
                  variant="ui-small"
                >
                  {cr.amountText}:{' '}
                  {isLoading ? (
                    <Skeleton height={14} width={91} />
                  ) : (
                    <Text
                      style={Token.typography.weightMedium}
                      variant="ui-small"
                      ink="highlight"
                    >
                      {formatCurrency(convert(invoice.amount))}
                    </Text>
                  )}
                </Text>
              </Card>
            ))}
            <View style={styles.footer}>
              <Text>{cr.actionInfoText}</Text>
              <View style={styles.action}>
                <Button
                  style={[styles.button, styles.buttonLeft]}
                  text={cr.yesButtonText}
                  variant="secondary"
                  onPress={handleYes}
                  disabled={isLoading || data.dispute}
                />
                <Button
                  style={styles.button}
                  text={cr.noButtonText}
                  onPress={handleRedirect}
                  disabled={isLoading || data.dispute}
                />
              </View>
            </View>
          </>
        ) : (
          <Text>{cr.noDataText}</Text>
        )}
      </Card>
      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        handleClose={handleRedirect}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: Token.spacing.xxxxl,
    paddingVertical: Token.spacing.m,
    paddingHorizontal: Token.spacing.l,
    width: 960,
  },
  title: {
    marginBottom: Token.spacing.m,
  },
  detail: {
    padding: Token.spacing.m,
    marginBottom: Token.spacing.m,
    backgroundColor: Token.color.uiLightNeutral,
  },
  text: {
    marginBottom: Token.spacing.m,
  },
  footer: {
    marginTop: Token.spacing.xxl,
    alignItems: 'center',
  },
  action: {
    marginTop: Token.spacing.xxl,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    width: 141,
  },
  buttonLeft: {
    marginRight: Token.spacing.m,
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

type Props = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

export default function ResendCodeModal(props: Props) {
  const { isVisible, setIsVisible } = props;

  const cr = CRQuery.CorporateCognitoLogin;

  const onModalHide = () => {
    setIsVisible(false);
  };

  return (
    <Modal isVisible={isVisible} onModalHide={onModalHide}>
      <Popup
        title={cr.resendCodeTitle}
        showCloseButton
        width={460}
        maxWidth={460}
        onCloseButtonPress={onModalHide}
      >
        <View style={styles.container}>
          <Text>{cr.resendCodeDescription}</Text>
          <Button
            style={styles.button}
            variant="secondary"
            text={cr.resendCodeButtonText}
            onPress={onModalHide}
          />
        </View>
      </Popup>
    </Modal>
  );
}

const CRQuery = {
  CorporateCognitoLogin: {
    resendCodeTitle: 'Your verification code has been re-sent',
    resendCodeDescription:
      'Your current code has been expired, please check your email again and enter the new code.',
    resendCodeButtonText: 'Close',
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: Token.spacing.m,
    marginLeft: 'auto',
  },
});

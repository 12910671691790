import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useApi, useContentResource } from '@traveloka/ctv-core';

import {
  CALCULATE_TRANSACTION_FEE_API,
  CalculateTransactionFeeRequest,
  CalculateTransactionFeeResponse,
} from 'flight/shared/api';
import { CurrencyValue, convert, convertToJava } from 'shared/utils/currency';

export type Item = {
  label: string;
  value: CurrencyValue;
};

type PriceContext = {
  calculatedFee: CalculateTransactionFeeResponse;
  setCalculatedFee: Dispatch<
    SetStateAction<CalculateTransactionFeeResponse | undefined>
  >;
  items: Item[];
  total: CurrencyValue;
  set(id: string, item: Item): void;
  update(id: string, amount: number): void;
  remove(id: string): void;
};

const PriceContext = createContext<PriceContext>(null!);

type Props = {
  children: ReactNode;
  defaultItems?: Dictionary<Item>;
};

export function PriceProvider(props: Props) {
  const { children, defaultItems = {} } = props;
  const [itemMap, setItemMap] = useState(defaultItems);
  const [calculatedFee, setCalculatedFee] = useState<
    CalculateTransactionFeeResponse
  >();

  const cr = useContentResource().CorporateFlightPrebookPriceBreakdown;

  const calculateTransactionFee = useApi<
    CalculateTransactionFeeResponse,
    CalculateTransactionFeeRequest
  >({
    domain: 'booking',
    method: 'post',
    path: CALCULATE_TRANSACTION_FEE_API,
  });

  const set = useCallback(
    (id: string, item: Item) => {
      setItemMap(prev => {
        const newState = { ...prev };
        newState[id] = item;

        return newState;
      });
    },
    [setItemMap]
  );

  const update = useCallback(
    (id: string, amount: number) => {
      setItemMap(prev => {
        const newState = { ...prev };
        const item = newState[id];

        if (item) {
          item.value.amount += amount;
        }

        fetchCalculateServiceFee(Object.values(newState));
        return newState;
      });
    },
    [setItemMap]
  );

  const remove = useCallback((id: string) => {
    setItemMap(prev => {
      const newState = { ...prev };
      const { [id]: removed, ...rest } = newState;

      return rest;
    });
  }, []);

  const items = useMemo(() => Object.values(itemMap), [itemMap]);
  const total = useMemo(
    () =>
      items.reduce(
        (obj, item) => ({
          ...item.value,
          amount: obj.amount + item.value.amount,
        }),
        { amount: 0 } as CurrencyValue
      ),
    [items]
  );

  function fetchCalculateServiceFee(overrideItems?: Item[]) {
    const excludeLabels = [cr.serviceFeeText, cr.serviceFeeVatText];
    const totalWithoutServiceFee = (overrideItems || items)
      .filter(i => !excludeLabels.includes(i.label))
      .reduce(
        (obj, item) => ({
          ...item.value,
          amount: obj.amount + item.value.amount,
        }),
        { amount: 0 } as CurrencyValue
      );

    calculateTransactionFee({
      totalFareFromClient: convertToJava(totalWithoutServiceFee),
    }).then(res => {
      if (res.success) {
        const { serviceFee, vatFee } = res.data;

        if (serviceFee && serviceFee.amount !== '0') {
          set('serviceFee', {
            label: cr.serviceFeeText,
            value: convert(serviceFee),
          });
        }
        if (vatFee && vatFee.amount !== '0') {
          set('serviceFeeVat', {
            label: cr.serviceFeeVatText,
            value: convert(vatFee),
          });
        }

        setCalculatedFee(res.data);
      }
    });
  }

  useEffect(() => {
    fetchCalculateServiceFee();
  }, []);

  if (!calculatedFee) {
    return null;
  }

  return (
    <PriceContext.Provider
      value={{
        items,
        set,
        update,
        total,
        remove,
        calculatedFee,
        setCalculatedFee,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
}

export function usePrice() {
  return useContext(PriceContext);
}

import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
} from 'react';

const SnackbarContext = createContext<SnackbarType>(undefined);
const DispatchContext = createContext<Dispatch<SetStateAction<SnackbarType>>>(
  () => {}
);

export type SnackbarType =
  | {
      message: string;
      variant: 'normal' | 'alert' | 'positive';
    }
  | undefined;
export function SnackbarProvider(props: PropsWithChildren<{}>) {
  const [message, setMessage] = useState<SnackbarType>();

  return (
    <SnackbarContext.Provider value={message}>
      <DispatchContext.Provider value={setMessage}>
        {props.children}
      </DispatchContext.Provider>
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function useDispatchSnackbar() {
  return useContext(DispatchContext);
}

import { parse } from 'qs';
import React from 'react';
import { View } from 'react-native';

import { LoadingIndicator } from '@traveloka/web-components';

import { RefundRequestQs } from 'postbooking/booking-detail/components/ProductRefundDetail/ProductRefundDetail';
import ProductType from 'postbooking/shared/constants/ProductType';
import HotelNonRefundableModal from 'refund/request-refund/components/HotelNonRefundableModal/HotelNonRefundableModal';
import {
  FlightRequestRefundProvider,
  useFlightRequestRefund,
} from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';
import { FlightRequestRefundFormProvider } from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import { FlightRequestRefundNavigatorProvider } from 'refund/request-refund/contexts/flight/FlightRequestRefundNavigatorContext';
import {
  HotelRequestRefundProvider,
  useHotelRequestRefund,
} from 'refund/request-refund/contexts/hotel/HotelRequestRefundContext';
import { HotelRequestRefundFormProvider } from 'refund/request-refund/contexts/hotel/HotelRequestRefundFormContext';
import { HotelRequestRefundNavigatorProvider } from 'refund/request-refund/contexts/hotel/HotelRequestRefundNavigatorContext';
import { RefundJourney } from 'refund/shared/api/types';
import { key } from 'shared/cookie/cookie';
import useEagerNavigation from 'shared/hooks/useEagerNavigation';
import { get, remove } from 'shared/utils/storage';

import FlightRequestRefund from './FlightRequestRefundContent';
import HotelRequestRefund from './HotelRequestRefundContent';

export default function ProductRequestRefundContent() {
  const qs: RefundRequestQs = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const productType = qs.productType.toUpperCase() as ProductType;

  switch (productType) {
    case ProductType.FLIGHT:
      return (
        <FlightRequestRefundProvider bookingId={qs.bookingId}>
          <FlightLoader />
        </FlightRequestRefundProvider>
      );
    case ProductType.HOTEL:
      return (
        <HotelRequestRefundProvider bookingId={qs.bookingId}>
          <HotelLoader />
        </HotelRequestRefundProvider>
      );
  }
}

function FlightLoader() {
  const qs: RefundRequestQs = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const [{ data, isFetching }] = useFlightRequestRefund();
  const navigate = useEagerNavigation();

  if (isFetching) {
    return (
      <View style={{ alignItems: 'center' }}>
        <LoadingIndicator />
      </View>
    );
  }

  if (data === undefined || data.isBookingRefunded) {
    navigate({ pathname: '/' });
    return null;
  }

  return (
    <FlightRequestRefundFormProvider>
      <FlightRequestRefundNavigatorProvider>
        <FlightRequestRefund />
      </FlightRequestRefundNavigatorProvider>
    </FlightRequestRefundFormProvider>
  );
}

function HotelLoader() {
  const [{ data, isFetching }] = useHotelRequestRefund();
  const navigate = useEagerNavigation();

  if (isFetching) {
    return (
      <View style={{ alignItems: 'center' }}>
        <LoadingIndicator />
      </View>
    );
  }

  const isAccessFromButton = get<boolean>(key.requestRefundButton) || false;
  if (isAccessFromButton) {
    remove(key.requestRefundButton);
  }

  const isNonRefundableModal = isAccessFromButton && !data.refundable;

  function onCloseModal() {
    navigate({ pathname: '/' });
  }

  if (data === undefined || (!data.refundable && !isNonRefundableModal)) {
    navigate({ pathname: '/' });
    return null;
  }

  return (
    <HotelRequestRefundFormProvider>
      <HotelRequestRefundNavigatorProvider>
        <HotelRequestRefund />
        <HotelNonRefundableModal
          isVisible={isNonRefundableModal}
          onClose={onCloseModal}
        />
      </HotelRequestRefundNavigatorProvider>
    </HotelRequestRefundFormProvider>
  );
}

import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  Ref,
  useRef,
  ReactElement,
} from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import Chevron from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_24px.svg';
import { Icon, Token, Text } from '@traveloka/web-components';

import Fade from '../../../../shared/components/Fade/Fade';
import { appendTestId } from '../../../../shared/utils/TestUtil';
import useOnClickOutside from '../../../../shared/hooks/useOnClickOutside';

import DropdownChecklistList from './DropdownChecklistList';

export type Item<T = string> = {
  label: string;
  value: T;
  checked: boolean;
};

type Props<T = string> = {
  testID?: string;
  placeholder?: string;
  items: Item<T>[];
  disabled?: boolean;
  onPress: (checked: boolean, value: Item<T>) => void;
  onSelectAllPress?: (checked: boolean, value: Item<string>) => void;
};

function InputDropdownChecklist<T = string>(
  props: Props<T>,
  ref: Ref<{ setShowDropdown: (value: boolean) => void } | undefined>
) {
  const { placeholder, disabled, ...rest } = props;
  const { testID } = rest;

  const [showDropdown, setShowDropdown] = useState(false);

  const refRoot = useRef<View>(null);

  const onPress = useCallback(() => {
    setShowDropdown(s => !s);
  }, [setShowDropdown]);

  useImperativeHandle(ref, () => ({
    setShowDropdown,
  }));

  useOnClickOutside(refRoot, () => {
    setShowDropdown(false);
  });

  return (
    <View ref={refRoot} style={Style.container}>
      <TouchableOpacity
        testID={appendTestId(testID, 'input')}
        style={[Style.wrapper, Style.flex]}
        onPress={onPress}
        disabled={disabled}
      >
        <Text variant="ui-small">{placeholder}</Text>
        <Icon src={Chevron} />
      </TouchableOpacity>
      <Fade
        style={[Style.wrapper, Style.fade, Style.positionDown]}
        visible={showDropdown}
      >
        <DropdownChecklistList
          {...rest}
          testID={appendTestId(testID, 'list')}
        />
      </Fade>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    flex: 1,
  },
  wrapper: {
    padding: Token.spacing.xs,
    borderRadius: Token.border.radius.normal,
    borderWidth: Token.border.width.thin,
    borderColor: Token.color.borderDivide,
  },
  flex: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fade: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: Token.color.uiLightPrimary,
  },
  positionDown: {
    top: '100%',
  },
});

export default forwardRef(InputDropdownChecklist) as <T = string>(
  p: Props<T> & {
    ref?: Ref<{ setShowDropdown: (value: boolean) => void } | undefined>;
  }
) => ReactElement;

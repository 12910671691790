export type Airline = {
  airlineCode: string;
  airlineName: string;
  logoUrl: string;
};

export type Airport = {
  airportCode: string;
  city: string;
  countryCode: string;
  countryId: string;
  internationalAirportName: string;
};

export type AirlineMap = Partial<Dictionary<Airline>>;
export type AirportMap = Partial<Dictionary<Airport>>;
export type AreaAirportsMap = Partial<Dictionary<string>>;

export type StaticData = {
  airlineMap: AirlineMap;
  airportMap: AirportMap;
  areaAirportsMap: AreaAirportsMap;
};

export enum FlightType {
  ONE_WAY = 'ONE_WAY',
  ROUND_TRIP = 'ROUND_TRIP',
}

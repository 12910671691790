import {
  FlightReasonsWithNoSecondary,
  FlightReasonsWithSecondary,
  FlightTraveler,
  FlightMainRefundReason,
  FlightSubmitTraveler,
  FlightSubmitRefundError,
  RefundJourney,
  HotelMainRefundReason,
  HotelSubmitRefundError,
  HotelTraveler,
  CancellationPolicies,
} from './types';
import ProductType from 'postbooking/shared/constants/ProductType';

// Refund Request
export const REFUND_ELIGIBILITY_API = '/api/v1/refund/eligibility';
export type RefundEligibilityRequest = {
  bookingId: string;
  productType: ProductType;
};
export type FlightRefundEligibilityResponse = {
  productType: ProductType.FLIGHT;
  flightRefundEligibility: {
    reasons: Array<FlightReasonsWithNoSecondary | FlightReasonsWithSecondary>;
    isBookingRefunded: boolean;
    isPartialJourney: boolean;
    flightRefundData: {
      travelers: FlightTraveler[];
      journeys: RefundJourney[];
    };
  };
  hotelRefundEligibility: null;
};
export type HotelRefundEligibilityResponse = {
  productType: ProductType.HOTEL;
  flightRefundEligibility: null;
  hotelRefundEligibility: {
    refundable: boolean;
    propertyName: string;
    city: string;
    checkInDate: string; //YYYY-MM-DD
    duration: number;
    numOfRooms: number;
    roomName: string;
    reasons: Array<{
      mainReason: HotelMainRefundReason;
      secondaryReason: null;
    }>;
    travelers: HotelTraveler[];
    cancellationPolicies: CancellationPolicies[];
  };
};

// Submit Request Refund
export const SUBMIT_REQUEST_REFUND_API = '/api/v1/refund/submit';
export type FlightSubmitRefundRequest = {
  bookingId: string;
  productType: ProductType.FLIGHT;
  flightRefundSubmission: {
    journeyIds: string[];
    travelers: FlightSubmitTraveler[];
    mainReason: FlightMainRefundReason;
  };
};
export type HotelSubmitRefundRequest = {
  bookingId: string;
  productType: ProductType.HOTEL;
  hotelRefundSubmission: {
    mainReason: HotelMainRefundReason;
    additionalInformation?: string;
  };
};
export type SubmitRefundFlightResponse = {
  flightRefundSubmission: {
    status: 'OK' | 'FAILED' | 'PARTIALLY_FAILED';
    errorMessage: Nullable<FlightSubmitRefundError>;
    refundedJourneys: string[];
  };
  hotelRefundSubmission: null;
};
export type SubmitRefundHotelResponse = {
  flightRefundSubmission: null;
  hotelRefundSubmission: {
    status: 'OK' | 'FAILED';
    errorMessage: Nullable<HotelSubmitRefundError>;
  };
};

// Upload File
export const UPLOAD_FILE_API = '/api/v1/upload/file';
export type UploadFileRequest = {
  uploadType: 'FLIGHT_REFUND' | 'CORPORATE_REGISTRATION';
};
export type UploadFileResponse = {
  url: string;
};

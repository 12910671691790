import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  Dispatch,
  PropsWithChildren,
} from 'react';

import { format } from 'date-fns';

import { useApi } from '@traveloka/ctv-core';

import {
  SearchRoomRequest,
  SearchRoomResponse,
  SEARCH_ROOM_API,
} from 'hotel/shared/api';
import { Room } from 'hotel/shared/api/types';
import AccomodationType from 'hotel/shared/constants/AccomodationType';
import { useSearchSpec } from 'hotel/shared/contexts/SpecContext';
import { validateSource } from 'hotel/shared/utils/validateSource';
import { JAVA_DATE } from 'shared/utils/date';
import { useAccountStatus } from 'account-status/context/AccountStatusContext';

const PropertyContext = createContext<[State, Dispatch<FilterAction>]>(
  undefined!
);

type FilterAction =
  | { type: 'reset-filter' }
  | { type: 'toggle-cancel' }
  | { type: 'toggle-breakfast' };

type FetchAction =
  | { type: 'fetch' }
  | { type: 'receive'; rooms: Room[]; trackingId?: string };

type Action = FilterAction | FetchAction;

type State = SearchRoomResponse & {
  isLoading: boolean;
  onlyFreeCancel: boolean;
  onlyFreeBreakfast: boolean;
  trackingId?: string;
};

const initialState: State = {
  isLoading: true,
  rooms: [],
  onlyFreeCancel: false,
  onlyFreeBreakfast: false,
  trackingId: '',

  // Hotel Detail
  accommodationType: AccomodationType.HOTEL,
  address: '',
  badges: [],
  checkInInstruction: '',
  checkInTime: null,
  checkOutTime: null,
  facilities: [],
  geoLocation: {
    lat: '',
    lon: '',
  },
  images: [],
  importantNotice: '',
  name: '',
  policy: '',
  reviewScore: null,
  starRating: '',
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'fetch':
      return initialState;
    case 'receive':
      return {
        ...state,
        isLoading: false,
        ...action,
      };
    case 'reset-filter':
      return {
        ...state,
        onlyFreeCancel: false,
        onlyFreeBreakfast: false,
      };
    case 'toggle-cancel':
      return {
        ...state,
        onlyFreeCancel: !state.onlyFreeCancel,
      };
    case 'toggle-breakfast':
      return {
        ...state,
        onlyFreeBreakfast: !state.onlyFreeBreakfast,
      };
  }
}

export function PropertyProvider(props: PropsWithChildren<{}>) {
  const searchSpec = useSearchSpec();
  const prevId = useRef<string>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { fetchAccountStatus } = useAccountStatus();

  const searchRooms = useApi<SearchRoomResponse, SearchRoomRequest>({
    domain: 'search',
    method: 'post',
    path: SEARCH_ROOM_API,
  });

  useEffect(() => {
    if (
      prevId.current !== searchSpec.id &&
      searchSpec.travelers.length + searchSpec.nonEmployeeTravelers.adults > 0
    ) {
      prevId.current = searchSpec.id;
      dispatch({ type: 'fetch' });

      const spec: SearchRoomRequest = {
        checkInDate: format(searchSpec.checkInDate, JAVA_DATE),
        checkOutDate: format(searchSpec.checkOutDate, JAVA_DATE),
        employeeIds: searchSpec.travelers.map(traveler => traveler.employeeId),
        nonEmployeeTravelers: searchSpec.nonEmployeeTravelers,
        numOfRooms: searchSpec.rooms,
        propertyId: searchSpec.id!,
        locale: 'en-ID',
        currencyCode: 'IDR',
        tripRequestId: searchSpec.tripRequestId,
        trackingSpec: {
          pageName: 'HOTEL_DETAIL',
          pageCategory: 'HOTEL',
          data: {
            visitId: searchSpec.visitId,
            searchId: searchSpec.searchId,
            funnelSource: validateSource(searchSpec.funnelSource),
          },
        },
      };
      fetchAccountStatus(() =>
        searchRooms(spec)
          .then(res => {
            if (res.success) {
              return {
                ...res.data,
                trackingId: res.trackingSpec?.id,
              };
            }

            throw new Error();
          })
          .catch(() => ({ rooms: [], trackingId: '' }))
          .then(data => dispatch({ type: 'receive', ...data }))
      );
    }
  }, [searchSpec]);

  return (
    <PropertyContext.Provider value={[state, dispatch]}>
      {props.children}
    </PropertyContext.Provider>
  );
}

export function useProperty() {
  return useContext(PropertyContext);
}

export function useFilteredRooms(): [boolean, Room[]] {
  const [{ rooms, onlyFreeCancel, onlyFreeBreakfast }] = useProperty();

  const isFiltered = onlyFreeCancel || onlyFreeBreakfast;

  const filteredRooms = useMemo(
    () =>
      rooms.filter(room => {
        if (onlyFreeCancel && onlyFreeBreakfast) {
          return room.isFreeCancelation && room.isBreakfastIncluded;
        } else if (onlyFreeCancel) {
          return room.isFreeCancelation;
        } else if (onlyFreeBreakfast) {
          return room.isBreakfastIncluded;
        }

        return true;
      }),
    [rooms, onlyFreeCancel, onlyFreeBreakfast]
  );

  return [isFiltered, filteredRooms];
}

import React from 'react';

import { useContentResource } from '@traveloka/ctv-core/resource';

import AccountSuspendedPopup from 'account-status/components/AccountSuspendedPopup/AccountSuspendedPopup';
import { PrebookStatus } from 'approval-system/shared/api/types';
import { StepHeader, Content, Footer, Page } from 'shared/components/Layout';

import HotelPrebookContent from './components/HotelPrebookContent/HotelPrebookContent';
import { PrebookProvider, usePrebook } from './contexts/PrebookProvider';

export default function HotelPrebook() {
  return (
    <Page>
      <PrebookProvider>
        <Header />
        <Content>
          <HotelPrebookContent />
        </Content>
      </PrebookProvider>
      <Footer />
      <AccountSuspendedPopup backToHome />
    </Page>
  );
}

function Header() {
  const prebook = usePrebook();
  const { preBookingStatus } = prebook.checkSearchSpec || {};
  const hasPaymentApproval = preBookingStatus === PrebookStatus.NEED_APPROVAL;

  const content = useContentResource().CorporateHotelPrebook;

  let steps: string[];
  if (!prebook.checkSearchSpec) {
    steps = [];
  } else if (hasPaymentApproval) {
    steps = [content.step1Approval, content.step2Approval];
  } else {
    steps = [content.step1, content.step2];
  }

  return <StepHeader current={0} steps={steps} />;
}

import React, { ReactNode, forwardRef, Ref } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Dropdown } from '@traveloka/ctvweb-ui';
import { Handler } from '@traveloka/ctvweb-ui/src/shared/components/Dropdown/Dropdown';
import { Token } from '@traveloka/web-components';
import { FlightSearchToolbarMenu } from '@traveloka/ctvweb-ui/flight';

type Props = {
  alignment: 'left' | 'right';
  children: ReactNode;
  text: string;
  style?: ViewProps['style'];
  minWidth?: number;
  testID?: string;
};

function FlightSearchToolbarMenuContainer(props: Props, ref: Ref<Handler>) {
  const { testID, text, alignment, children, style } = props;

  return (
    <Dropdown
      ref={ref}
      trigger={<FlightSearchToolbarMenu testID={testID} text={text} />}
      alignment={alignment}
      style={style}
    >
      <View style={Style.content}>{children}</View>
    </Dropdown>
  );
}

const Style = StyleSheet.create({
  content: {
    padding: Token.spacing.m,
    minWidth: 310,
    width: 'max-content',
  },
});

export default forwardRef(FlightSearchToolbarMenuContainer);

import SeatClass from 'flight/shared/constants/seat-class';

export const DEFAULT_ROUTE = 'JKTA.DPS';

export const AVAILABLE_SEAT_CLASS = [
  SeatClass.ECONOMY,
  SeatClass.PREMIUM_ECONOMY,
  SeatClass.BUSINESS,
  SeatClass.FIRST,
];
export const DEFAULT_SEAT_CLASS = SeatClass.ECONOMY;

export const ROUND_TRIP_SEPARATOR = ' ⇆ ';
export const ONE_WAY_SEPARATOR = ' → ';

export const DEPARTURE_DATE_OFFSET = 1;
export const RETURN_DATE_OFFSET = 3;

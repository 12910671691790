import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useTheme, Text, Token, Badge } from '@traveloka/web-components';
import { Skeleton, useHoverable } from '@traveloka/web-components/future';
import Highlighter from 'react-highlight-words';

type HotelAutocompleteItemProps = {
  testID?: string;
  label: string;
  subLabel?: string;
  badgeText: string;
  subBadgeText?: string;
  isActive: boolean;
  filterText: string;
  onPress: () => void;
};

const highlightStyle = {
  color: Token.color.uiBluePrimary,
  backgroundColor: 'transparent',
};

function HotelAutocompleteItem(props: HotelAutocompleteItemProps) {
  const {
    testID,
    label,
    subLabel,
    badgeText,
    subBadgeText,
    isActive,
    filterText,
    onPress,
  } = props;

  const { color } = useTheme();
  const [isHovered, event] = useHoverable();
  const hoverStyle = {
    backgroundColor: color.lightStain,
  };
  const searchWords = filterText.split(/\s+/g);

  return (
    <TouchableOpacity
      {...event}
      testID={testID}
      onPress={onPress}
      style={[ItemStyle.container, (isHovered || isActive) && hoverStyle]}
    >
      <View style={ItemStyle.content}>
        <View style={ItemStyle.contentLeft}>
          <Text
            variant="ui-baseline"
            ink="primary"
            style={Token.typography.weightMedium}
          >
            <Highlighter
              searchWords={searchWords}
              autoEscape={true}
              textToHighlight={label}
              highlightStyle={highlightStyle}
            />
          </Text>

          {typeof subLabel === 'string' && (
            <Text variant="ui-small" ink="primary">
              <Highlighter
                searchWords={searchWords}
                autoEscape={true}
                textToHighlight={subLabel}
                highlightStyle={highlightStyle}
              />
            </Text>
          )}
        </View>
        <View style={ItemStyle.contentRight}>
          {badgeText && <Badge variant="info-subtle" text={badgeText} />}
          {Boolean(subBadgeText) && (
            <Text variant="ui-tiny" ink="secondary" style={ItemStyle.subBadge}>
              {subBadgeText}
            </Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
}

export function HotelAutocompleteItemLoader() {
  return (
    <View style={ItemStyle.container}>
      <View style={ItemStyle.content}>
        <View style={ItemStyle.contentLeft}>
          <Skeleton
            width={80}
            height={Token.typography.uiBaseline.lineHeight}
            style={ItemStyle.skeleton}
          />
          <Skeleton
            width={220}
            height={Token.typography.uiSmall.lineHeight}
            style={ItemStyle.skeleton}
          />
        </View>
        <View style={ItemStyle.contentRight}>
          <Skeleton
            width={60}
            height={Token.typography.uiBaseline.lineHeight}
            style={ItemStyle.skeleton}
          />
          <Skeleton
            width={70}
            height={Token.typography.uiTiny.lineHeight}
            style={ItemStyle.skeleton}
          />
        </View>
      </View>
    </View>
  );
}

const ItemStyle = StyleSheet.create({
  container: {
    cursor: 'pointer',
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.m,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  contentLeft: {
    flexGrow: 1,
  },
  contentRight: {
    alignItems: 'flex-end',
  },
  subBadge: {
    marginTop: Token.spacing.xxs,
  },
  highlighter: {
    color: Token.color.uiBluePrimary,
    backgroundColor: 'transparent',
  },
  skeleton: {
    marginBottom: Token.spacing.xxs,
  },
});

export default HotelAutocompleteItem;

import { useApi, useFeatureControl } from '@traveloka/ctv-core';
import { useQuery } from '@tanstack/react-query';
import {
  RecentSearchFlightRequest,
  RecentSearchFlightResponse,
  RECENT_SEARCH_FLIGHT_API,
} from 'recent-search/api';

function useRecentSearchFlight() {
  const recentSearchFC = useFeatureControl('b2b-recent-search');
  const fetcher = useApi<RecentSearchFlightResponse, RecentSearchFlightRequest>(
    {
      domain: 'search',
      method: 'post',
      path: RECENT_SEARCH_FLIGHT_API,
    }
  );

  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    ['recent-search-flight'],
    fetcher,
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: recentSearchFC.enabled,
    }
  );

  return {
    isLoading,
    isSuccess,
    isError: data?.success === false || isError,
    data: data?.success ? data.data : null,
    refetch,
  };
}

export default useRecentSearchFlight;

import React from 'react';
import { Item } from './DropdownChecklist';
import { View, StyleSheet } from 'react-native';
import DropdownChecklistItem from './DropdownChecklistItem';
import { appendTestId } from '../../../../shared/utils/TestUtil';

type Props<T> = {
  testID?: string;
  items: Item<T>[];
  onPress: (checked: boolean, value: Item<T>) => void;
  onSelectAllPress?: (checked: boolean, value: Item<string>) => void;
};

export default function DropdownChecklistList<T = string>(props: Props<T>) {
  const { testID, items, onPress, onSelectAllPress } = props;

  return (
    <View style={Style.wrapper}>
      {onSelectAllPress && (
        <DropdownChecklistItem
          testID={appendTestId(testID, `0`)}
          item={{
            label: 'Select All',
            value: '',
            checked: items.every(i => i.checked),
          }}
          onPress={onSelectAllPress}
        />
      )}
      {items.map((item, index) => {
        return (
          <DropdownChecklistItem
            key={index}
            testID={appendTestId(
              testID,
              `${onSelectAllPress ? index + 1 : index}`
            )}
            item={item}
            onPress={onPress}
          />
        );
      })}
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    maxHeight: 450,
    // @ts-ignore
    overflow: 'auto',
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Token } from '@traveloka/web-components';

import {
  useFlightSearchArrivalTimeFilterContext,
  useFlightSearchDepartureTimeFilterContext,
  useFlightSearchOptionContext,
} from 'flight/search/contexts/FlightSortFilterContext';

import { CommonStyle } from '../filter-search-toolbar.style';
import FlightSearchToolbarMenuContainer from '../FlightSearchToolbarMenuContainer';
import DepartureArrivalTimeFilter from './DepartureArrivalTimeFilter';

function TimeFilter() {
  const { CorporateFlightSearchToolbar } = useContentResource();
  const content = {
    title: CorporateFlightSearchToolbar.time,
    departure: CorporateFlightSearchToolbar.timeDeparture,
    arrival: CorporateFlightSearchToolbar.timeArrival,
  };

  const { filterOption, setFilter } = useFlightSearchOptionContext();
  const arrivalTime = useFlightSearchArrivalTimeFilterContext();
  const departureTime = useFlightSearchDepartureTimeFilterContext();

  return (
    <FlightSearchToolbarMenuContainer
      alignment="left"
      text={content.title}
      style={CommonStyle.menuItem}
      testID="flight.search.toolbar.time"
    >
      <View style={Style.container}>
        <DepartureArrivalTimeFilter
          filter="departureTime"
          values={departureTime}
          options={filterOption.departureTime}
          onChange={setFilter}
          title={content.departure}
          style={Style.column}
        />
        <DepartureArrivalTimeFilter
          filter="arrivalTime"
          values={arrivalTime}
          options={filterOption.arrivalTime}
          onChange={setFilter}
          title={content.arrival}
          style={Style.column}
        />
      </View>
    </FlightSearchToolbarMenuContainer>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginHorizontal: -Token.spacing.xs,
    width: 410,
  },
  column: {
    flex: 1,
    paddingHorizontal: Token.spacing.xs,
  },
});

export default TimeFilter;

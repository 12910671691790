import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useApi, useAuth } from '@traveloka/ctv-core';
import {
  EntryDetailRequest,
  EntryDetailResponse,
  GET_ENTRY_DETAIL,
} from '@traveloka/ctv-core/pad';

import { Corporate } from 'company/types';

type Data = EntryDetailResponse<Corporate> | undefined;

type Context = [
  EntryDetailResponse<Corporate> | undefined,
  Dispatch<SetStateAction<EntryDetailResponse<Corporate> | undefined>>
];

const CorporateContext = createContext<Context>(undefined!);

export function CorporateProvider(props: PropsWithChildren<{}>) {
  const { user } = useAuth();

  const state = useState<Data>();
  const [, setData] = state;

  const getEntryDetail = useApi<
    EntryDetailResponse<Corporate>,
    EntryDetailRequest
  >({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_DETAIL,
  });

  useEffect(() => {
    getEntryDetail({
      entityType: 'corporate',
      entryId: user?.corporateId ?? '1',
    }).then(res => {
      if (res.success) {
        setData(res.data);
      }
    });
  }, []);

  return (
    <CorporateContext.Provider value={state}>
      {props.children}
    </CorporateContext.Provider>
  );
}

export function useCorporate() {
  return useContext(CorporateContext);
}

const FLIGHT_SEARCH_POLLING_DELAY = 2000; // in millisecond (5 seconds)
const FLIGHT_SEARCH_POLLING_TIMEOUT = 1000 * 60 * 2; // in millisecond (2 minutes)

export function flightPollingMechanism(
  fetchFlightSearch: () => Promise<boolean>
) {
  let isTimeout = false;
  async function syncedSetInterval() {
    const isCompleted = await fetchFlightSearch();

    if (isTimeout) return;

    if (!isCompleted) {
      setTimeout(() => syncedSetInterval(), FLIGHT_SEARCH_POLLING_DELAY);
    }
  }
  syncedSetInterval();
  const timeoutId = setTimeout(
    () => (isTimeout = true),
    FLIGHT_SEARCH_POLLING_TIMEOUT
  );

  return timeoutId;
}

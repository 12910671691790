import React, { useCallback, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { useApi } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import InfoIcon from '@traveloka/icon-kit-web/svg/dark/ic_system_status_info-fill_24px.svg';
import {
  Button,
  Icon,
  InfoBox,
  LoadingIndicator,
  Text,
  Token,
} from '@traveloka/web-components';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import {
  CREATE_PAYMENT_APPROVAL_API,
  CreatePaymentApprovalRequest,
} from 'approval-system/shared/api';
import { PrebookStatus } from 'approval-system/shared/api/types';
import ProductType from 'approval-system/shared/constants/ProductType';
import { usePrebook } from 'hotel/prebook/contexts/PrebookProvider';
import useRedirectToRoom from 'hotel/prebook/hooks/useRedirectToRoom';
import { FormValue } from 'hotel/prebook/types';
import { generateBookingSpec } from 'hotel/shared/utils/prebookSpecUtil';
import { TwoColumn } from 'shared/components/Layout';
import useEagerNavigation from 'shared/hooks/useEagerNavigation';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';
import { convert } from 'shared/utils/currency';
import { formatCurrency } from 'shared/utils/intl';

import AdditionalForm from '../AdditionalForm/AdditionalForm';
import CancelationPolicy from '../CancelationPolicy/CancelationPolicy';
import ContactForm from '../ContactForm/ContactForm';
import ModalGroup, { Handler } from '../ModalGroup/ModalGroup';
import PriceChangeModal from '../ModalGroup/PriceChangeModal';
import NonEmployeeTravelerForm from '../NonEmployeeTravelerForm/NonEmployeeTravelerForm';
import PaymentApprovalForm from '../PaymentApprovalForm/PaymentApprovalForm';
import PriceBreakdown from '../PriceBreakdown/PriceBreakdown';
import ProductSummary from '../ProductSummary/ProductSummary';
import SpecialRequest from '../SpecialRequest/SpecialRequest';
import TravelerForm from '../TravelerForm/TravelerForm';

export default function HotelPrebookContent() {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const modalRef = useRef<Handler>(null);
  const handleShowConfirmation = useCallback(
    () => modalRef.current?.show('review'),
    []
  );

  const {
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();
  const navigate = useEagerNavigation();
  const history = useLocalizedHistory();
  const redirectToHome = useCallback(() => {
    history.push('/');
  }, [history]);
  const redirectToRoom = useRedirectToRoom();
  const content = useContentResource().CorporateHotelPrebook;

  const {
    isLoading,
    isSuccess,
    checkSearchSpec,
    room,
    spec,
    travelers,
    trackingId,
  } = usePrebook();

  const methods = useForm<FormValue>();
  const { handleSubmit: formSubmit } = methods;

  const submitPaymentApproval = useApi<unknown, CreatePaymentApprovalRequest>({
    domain: 'management',
    method: 'post',
    path: CREATE_PAYMENT_APPROVAL_API,
  });

  function createPaymentApproval(values: FormValue) {
    const productRequestId = spec.productRequestId;
    const bookingSpec = generateBookingSpec(
      spec,
      travelers,
      room,
      values,
      {
        totalFare: room.totalFare,
        grandTotal: room.grandTotal,
        serviceFee: room.serviceFee,
        vatFee: room.vatFee,
      },
      values.nonEmployeeTravelers,
      trackingId,
      {
        confirmSubmit: !checkSearchSpec.complyWithTravelPolicy,
        policyBypassRemarks: !checkSearchSpec.complyWithTravelPolicy
          ? spec.approverReason
          : undefined,
      }
    );

    const payload: CreatePaymentApprovalRequest = {
      productRequestId: productRequestId!,
      productType: ProductType.HOTEL,
      reason: values.paymentApprovalReason!,
      attachmentUrl: values.attachment?.url,
      hotelApprovalRequest: {
        hotelBookingSubmitAPIRequest: bookingSpec,
        geoId: spec.geoId!,
        geoType: spec.geoType!,
      },
    };

    submitPaymentApproval(payload)
      .then(res => {
        if (res.success) {
          navigate({ pathname: `/approval/${spec.tripRequestId}` });
        }
      })
      .finally(() => setIsSubmitting(false));
  }

  if (isLoading) {
    return (
      <View style={Style.loadingContainer}>
        <LoadingIndicator style={Style.loading} />
        <Text>{content.loadingText}</Text>
      </View>
    );
  }

  if (!isSuccess) {
    return (
      <NotAvailable
        action={content.notSuccessButtonText}
        message={content.notSuccessText}
        onPress={redirectToHome}
      />
    );
  }

  if (room.availability === 'NOT_AVAILABLE') {
    return (
      <NotAvailable
        action={content.noRoomButtonText}
        message={content.noRoomText}
        onPress={() => redirectToRoom('room')}
      />
    );
  }

  if (travelers.length !== spec.initialGuestCount) {
    return (
      <NotAvailable
        action={content.mismatchGuestCountButtonText}
        message={content.mismatchGuestCountText}
        onPress={() => {
          redirectToRoom('form');
        }}
      />
    );
  }

  function handleSubmit(values: FormValue) {
    if (hasPaymentApproval) {
      setIsSubmitting(true);
      createPaymentApproval(values);
      return;
    }
    handleShowConfirmation();
  }

  const hasPaymentApproval =
    checkSearchSpec.preBookingStatus === PrebookStatus.NEED_APPROVAL;

  return (
    <>
      <Text variant="title-1" style={Style.title}>
        {content.title}
      </Text>
      <Text ink="secondary" style={Style.subtitle}>
        {content.subtitle}
      </Text>
      <TwoColumn>
        <TwoColumn.Big>
          <FormProvider {...methods}>
            <ContactForm />
            <SpecialRequest />
            <TravelerForm />
            <NonEmployeeTravelerForm />
            <CancelationPolicy />
            <PriceBreakdown />
            <AdditionalForm />
            <PaymentApprovalForm />
            <View style={Style.continue}>
              <Button
                variant="main-cta"
                text={
                  hasPaymentApproval
                    ? content.createPaymentApprovalButtonText
                    : content.submitPrebookButtonText
                }
                onPress={() => fetchAccountStatus(formSubmit(handleSubmit))}
                loading={isAccountStatusFetching || isSubmitting}
              />
            </View>
            <ModalGroup ref={modalRef} />
          </FormProvider>
        </TwoColumn.Big>
        <TwoColumn.Small style={Style.right}>
          <ProductSummary />
        </TwoColumn.Small>
      </TwoColumn>
      <PriceChange />
    </>
  );
}

function PriceChange() {
  const { spec, room } = usePrebook();
  const redirectToRoom = useRedirectToRoom();

  const content = useContentResource().CorporateHotelPrebookPriceChangeModal;

  const [isVisible, setIsVisible] = useState(
    () => spec.totalFare.amount !== convert(room.totalFare).amount
  );

  const closeModal = useCallback(() => setIsVisible(false), []);

  return (
    <PriceChangeModal
      isVisible={isVisible}
      newPrice={formatCurrency(convert(room.grandTotal || room.totalFare))}
      oldPrice={formatCurrency(spec.totalFare)}
      onPrimaryPress={closeModal}
      onSecondaryPress={() => redirectToRoom('room')}
      primaryLabel={content.submitButtonText}
      secondaryLabel={content.navigateToRoomButtonText}
    />
  );
}

type NotAvailableProps = {
  action: string;
  message: string;
  onPress(): void;
};

function NotAvailable(props: NotAvailableProps) {
  const { action, message, onPress } = props;

  return (
    <InfoBox
      message={message}
      Icon={() => <Icon src={InfoIcon} width={16} height={16} />}
      isFullWidth={false}
      variant="ALERT"
      Action={
        <Button onPress={onPress} variant="text" size="small" text={action} />
      }
    />
  );
}

const Style = StyleSheet.create({
  loadingContainer: {
    alignItems: 'center',
  },
  loading: {
    marginBottom: Token.spacing.xs,
  },
  title: {
    marginBottom: Token.spacing.s,
  },
  subtitle: {
    marginBottom: Token.spacing.l,
  },
  continue: {
    alignItems: 'flex-end',
  },
  right: {
    width: 300,
    marginTop: Token.spacing.xl,
  },
});

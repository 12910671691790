import React, { useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { addMonths, addYears, isValid, parse, subYears } from 'date-fns';

import {
  useContentResource,
  useFeatureControl,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { Card, Text, Token, useTheme } from '@traveloka/web-components';

import {
  useAddOnsContext,
  useBookingRuleContext,
  useFlightsContext,
  useIsInternationalContext,
  usePassengersContext,
} from 'flight/prebook/contexts/FlightPrebookContext';
import { Style as SharedStyle } from 'flight/prebook/flight-prebook.style';
import { PrebookForm } from 'flight/prebook/types';
import CountryField from 'shared/components/form/CountryField/CountryField';
import DateField, {
  Value as DateFieldValue,
} from 'shared/components/form/DateField/DateField';
import HiddenField from 'shared/components/form/HiddenField/HiddenField';
import InputDropdownField from 'shared/components/form/InputDropdownField/InputDropdownField';
import InputField from 'shared/components/form/InputField/InputField';
import { JAVA_DATE } from 'shared/utils/date';
import { formatMessage } from 'shared/utils/intl';
import {
  isAlphaNumeric,
  maxLength,
  minLength,
  required,
} from 'shared/utils/validator';

import BaggageAddonForm from '../AddonForm/BaggageAddonForm';
import Collapsible, { CollapsibleHandler } from '../Collapsible/Collapsible';

type Props = {
  index: number;
};

export default function TravellerForm(props: Props) {
  const { index } = props;

  const collapsibleRef = useRef<CollapsibleHandler>();
  const { color } = useTheme();
  const flights = useFlightsContext();
  const flightAddOns = useAddOnsContext();
  const passengers = usePassengersContext();
  const bookingRule = useBookingRuleContext();
  const isInternational = useIsInternationalContext();
  const {
    formState: { errors, isSubmitSuccessful },
    control,
  } = useFormContext<PrebookForm>();
  const passengersForm = useWatch({
    control,
    name: 'passengers',
  });

  const { format } = useLocalizedDateFormat();
  const content = useContentResource().CorporateFlightPrebookTravelerForm;
  const titleContext = useContentResource().CorporateEnumTravelerTitle;
  const { enabled } = useFeatureControl('b2b-document-type-new-behavior');

  const departureDate = flights[0].summary.departureDateTime;
  const departureYear = departureDate.getFullYear();

  const titleItems = useMemo(
    () => [
      { label: titleContext.MR, value: 'MR' },
      { label: titleContext.MRS, value: 'MRS' },
      { label: titleContext.MISS, value: 'MISS' },
    ],
    []
  );

  useEffect(() => {
    if (!isSubmitSuccessful) {
      const hasError = !!errors?.passengers?.adults?.at?.(index)?.message;

      if (collapsibleRef.current && hasError) {
        collapsibleRef.current.setIsCollapsed(false);
      }
    }
  }, [errors, isSubmitSuccessful]);

  const initialValues = useMemo(() => {
    const passenger = passengers[index];
    const { fullname, division, title, nationality } = passengers[index];

    let identityCardNumber: string | undefined = undefined;
    let passportCountryOfIssue: string | undefined = undefined;
    let passportExpiryDate: DateFieldValue | undefined = undefined;
    let passportNumber: string | undefined = undefined;
    if (passenger.identityCard) {
      identityCardNumber = passenger.identityCard;
    }

    if (passenger.passportCountryOfIssue) {
      passportCountryOfIssue = passenger.passportCountryOfIssue;
    }

    if (passenger.passportNumber) {
      passportNumber = passenger.passportNumber;
    }

    if (passenger.passportExpiryDate) {
      let date = new Date(passenger.passportExpiryDate);

      passportExpiryDate = {
        year: String(date.getFullYear()),
        month: String(date.getMonth()),
        day: String(date.getDate()),
      };
    }

    return {
      fullname: fullname,
      division: division,
      title: title || undefined,
      nationality: nationality || undefined,
      identityCardNumber,
      passportCountryOfIssue,
      passportExpiryDate,
      passportNumber,
    };
  }, [passengers[index]]);

  const currentNationality =
    passengersForm?.adults?.[index]?.nationality || initialValues.nationality;

  const requiresBirthDate = isInternational
    ? bookingRule.requiresBirthDateForInternational
    : bookingRule.requiresBirthDate;

  const internationalFieldsProps: InternationalFieldsProps = useMemo(
    () => ({
      departureDate,
      departureYear,
      index,
      initialValues,
    }),
    []
  );
  const isDomesticId =
    !isInternational && flights[0].summary.departureAirport.countryId === 'ID';
  const isDomesticNonId =
    !isInternational && flights[0].summary.departureAirport.countryId !== 'ID';
  const isSameNationalityAsRoute =
    currentNationality === flights[0].summary.departureAirport.countryId;

  return (
    <>
      <Card
        style={[
          SharedStyle.section,
          Style.section,
          { zIndex: passengers.length - index },
        ]}
      >
        <Collapsible
          touchableTestID={`pre-book.form.traveler.${index}.colapsible`}
          title={
            <>
              <Text>{initialValues.fullname}</Text>
              <Text variant="ui-tiny" ink="secondary">
                {initialValues.division}
              </Text>
            </>
          }
          defaultIsCollapsed={false}
          // @ts-ignore
          ref={collapsibleRef}
        >
          <>
            <View style={[SharedStyle.group, Style.shortField, { zIndex: 4 }]}>
              <InputDropdownField
                testID={`pre-book.form.traveler.${index}.title`}
                name={`passengers.adults[${index}].title`}
                label={content.travelerTitleField}
                items={titleItems}
                defaultValue={initialValues.title}
                validate={value => {
                  if (required(value) === false) {
                    return content.travelerTitleRequiredErrorMessage;
                  }

                  return;
                }}
              />
            </View>

            <View style={[SharedStyle.row, { zIndex: 3 }]}>
              {requiresBirthDate && (
                <View style={[SharedStyle.col, SharedStyle.group]}>
                  <DateField
                    name={`passengers.adults[${index}].dateOfBirth`}
                    label={content.dobField}
                    leftHelper={content.dobAdultDescription}
                    maxYear={departureYear - 12}
                    yearRange={100}
                    validate={value => {
                      const isRequired = content.DobRequiredErrorMessage;
                      if (value === undefined) {
                        return isRequired;
                      }

                      const { year, month, day } = value;

                      if (year === '' || month === '' || day === '') {
                        return isRequired;
                      }

                      const date = parse(
                        `${year}-${Number(month) + 1}-${day}`,
                        JAVA_DATE,
                        0
                      );
                      const minDate = subYears(departureDate, 112);
                      const maxDate = subYears(departureDate, 12);

                      if (!isValid(date)) {
                        return isRequired;
                      } else if (date < minDate) {
                        return formatMessage(content.earliestDobChosen, {
                          date: format(minDate, 'SHORT_MONTH'),
                        });
                      } else if (date > maxDate) {
                        return formatMessage(content.latestDobChosen, {
                          date: format(maxDate, 'SHORT_MONTH'),
                        });
                      }

                      return;
                    }}
                  />
                </View>
              )}
              <View style={[SharedStyle.col, SharedStyle.group]}>
                <CountryField
                  testID={`pre-book.form.traveler.${index}.nationality`}
                  name={`passengers.adults[${index}].nationality`}
                  label={content.nationalityField}
                  defaultValue={initialValues.nationality}
                  validate={(value?: string) => {
                    if (required(value) === false) {
                      return content.nationalityRequiredErrorMessage;
                    }

                    return;
                  }}
                />
              </View>
            </View>

            {!isInternational && (
              <>
                {!enabled && (
                  <HiddenField
                    name={`passengers.adults[${index}].documentDetail.documentType`}
                    value={bookingRule.requiresId ? 'NATIONAL_ID' : 'PASSPORT'}
                  />
                )}
                <DomesticFields
                  showField={!!currentNationality}
                  index={index}
                  initialValues={initialValues}
                  internationalFieldsProps={internationalFieldsProps}
                  isSameNationalityAsRoute={isSameNationalityAsRoute}
                  isDomesticId={isDomesticId}
                  isDomesticNonId={isDomesticNonId}
                />
              </>
            )}

            {isInternational && bookingRule.requiresDocumentNoForInternational && (
              <>
                {!enabled && (
                  <HiddenField
                    name={`passengers.adults[${index}].documentDetail.documentType`}
                    value="PASSPORT"
                  />
                )}
                <InternationalFields {...internationalFieldsProps} />
              </>
            )}

            {flightAddOns.length > 0 && (
              <View style={{ zIndex: 1 }}>
                <View
                  style={[
                    Style.addOnTitle,
                    SharedStyle.section,
                    { backgroundColor: color.lightStain },
                  ]}
                >
                  <Text style={SharedStyle.bold}>{content.addOnTitle}</Text>
                </View>
                <Text>{content.baggageLabel}</Text>
                {flightAddOns.map((flightAddOn, flightIndex) => (
                  <View
                    style={[
                      Style.row,
                      Style.addOnFlightWrapper,
                      { zIndex: flightAddOns.length - flightIndex },
                    ]}
                  >
                    {flightAddOn.journeysWithAvailableAddOnsOptions.map(
                      (journeyAddOn, journeyAddOnsIndex) => (
                        <BaggageAddonForm
                          key={journeyAddOnsIndex}
                          flightIndex={flightIndex}
                          journeyAddOnsIndex={journeyAddOnsIndex}
                          adultIndex={index}
                          journeyAddOn={journeyAddOn}
                          testID={`pre-book.form.traveler.${index}.baggage.${flightIndex}.${journeyAddOnsIndex}`}
                        />
                      )
                    )}
                  </View>
                ))}
              </View>
            )}
          </>
        </Collapsible>
      </Card>
    </>
  );
}

type InternationalFieldsProps = {
  index: number;
  initialValues: {
    passportNumber: string | undefined;
    passportCountryOfIssue: string | undefined;
    passportExpiryDate: DateFieldValue | undefined;
  };
  departureYear: number;
  departureDate: Date;
};

function InternationalFields(props: InternationalFieldsProps) {
  const { departureDate, departureYear, index, initialValues } = props;

  const content = useContentResource().CorporateFlightPrebookTravelerForm;
  const { format } = useLocalizedDateFormat();
  const { enabled } = useFeatureControl('b2b-document-type-new-behavior');

  return (
    <>
      {enabled && (
        <HiddenField
          name={`passengers.adults[${index}].documentDetail.documentType`}
          value="PASSPORT"
        />
      )}
      <View style={{ zIndex: 2 }}>
        <View style={[SharedStyle.group, Style.mediumField]}>
          <InputField
            name={`passengers.adults[${index}].documentDetail.documentNo`}
            label={content.passportNumberField}
            defaultValue={initialValues.passportNumber}
            validate={value => {
              if (required(value) === false) {
                return content.passportNumberRequiredErrorMessage;
              } else if (isAlphaNumeric(value) === false) {
                return content.passportNumberAlphanumericErrorMessage;
              } else if (minLength(value, 6) === false) {
                return formatMessage(
                  content.passportNumberMinLengthErrorMessage,
                  { length: 6 }
                );
              } else if (maxLength(value, 9) === false) {
                return formatMessage(
                  content.passportNumberMaxLengthErrorMessage,
                  { length: 9 }
                );
              }

              return;
            }}
          />
        </View>
        <View style={[SharedStyle.row, SharedStyle.group]}>
          <View style={SharedStyle.col}>
            <CountryField
              name={`passengers.adults[${index}].documentDetail.issuingCountry`}
              label={content.issuingCountryField}
              defaultValue={initialValues.passportCountryOfIssue}
              validate={value => {
                if (required(value) === false) {
                  return content.issuingCountryRequiredErrorMessage;
                }

                return;
              }}
            />
          </View>
          <View style={SharedStyle.col}>
            <DateField
              name={`passengers.adults[${index}].documentDetail.expirationDate`}
              label={content.expirationDateField}
              maxYear={departureYear + 23}
              yearRange={24}
              reverse
              defaultValue={initialValues.passportExpiryDate}
              validate={value => {
                const isRequired = content.expirationDateRequiredErrorMessage;
                if (value === undefined) {
                  return isRequired;
                }

                const { year, month, day } = value;

                if (year === '' || month === '' || day === '') {
                  return isRequired;
                }

                const date = parse(
                  `${year}-${Number(month) + 1}-${day}`,
                  JAVA_DATE,
                  0
                );
                const minDate = addMonths(departureDate, 6);
                const maxDate = addYears(departureDate, 24);

                if (!isValid(date)) {
                  return isRequired;
                } else if (date < minDate) {
                  return formatMessage(content.earliestexpirationDateChosen, {
                    date: format(minDate, 'SHORT_MONTH'),
                  });
                } else if (date > maxDate) {
                  return formatMessage(content.latestexpirationDateChosen, {
                    date: format(maxDate, 'SHORT_MONTH'),
                  });
                }

                return;
              }}
            />
          </View>
        </View>
      </View>
    </>
  );
}

type DomesticFieldsProps = {
  showField: boolean;
  index: number;
  internationalFieldsProps: InternationalFieldsProps;
  initialValues: {
    identityCardNumber: string | undefined;
  };
  isSameNationalityAsRoute: boolean;
  isDomesticId: boolean;
  isDomesticNonId: boolean;
};

function DomesticFields(props: DomesticFieldsProps) {
  const {
    showField,
    index,
    initialValues,
    internationalFieldsProps,
    isSameNationalityAsRoute,
    isDomesticId,
    isDomesticNonId,
  } = props;

  const content = useContentResource().CorporateFlightPrebookTravelerForm;
  // TODO: Need to create separate PR to move all common validation CR to new entry
  const validationContent = useContentResource().CorporateRegistration;
  const bookingRule = useBookingRuleContext();
  const { enabled } = useFeatureControl('b2b-document-type-new-behavior');

  if (!showField) {
    return null;
  }

  // Issue: https://29022131.atlassian.net/browse/CTV-5999
  // If and only if `requiresId` is true and `requiresDocumentNoForDomestic` is true and route is domestic non id
  // Then will bypass the KTP field and render Passport instead
  const patchPassportAndKtpIssue =
    isDomesticNonId &&
    bookingRule.requiresId &&
    bookingRule.requiresDocumentNoForDomestic;

  const patchDomesticNonIdRequiresIdIssue =
    isDomesticNonId && !isSameNationalityAsRoute;
  if (
    bookingRule.requiresId &&
    !patchPassportAndKtpIssue &&
    !patchDomesticNonIdRequiresIdIssue
  ) {
    return (
      <>
        {enabled && (
          <HiddenField
            name={`passengers.adults[${index}].documentDetail.documentType`}
            value="NATIONAL_ID"
          />
        )}
        <View style={[SharedStyle.group, Style.mediumField]}>
          <InputField
            name={`passengers.adults[${index}].documentDetail.documentNo`}
            label={content.identityNumberField}
            defaultValue={initialValues.identityCardNumber}
            validate={value => {
              if (!(isDomesticId && isSameNationalityAsRoute)) return;

              if (value && value.length > 16) {
                return formatMessage(validationContent.validationMaxLength, {
                  value: 16,
                });
              }

              if (!isAlphaNumeric(value)) {
                return formatMessage(
                  validationContent.validationAlphaNumericOnly,
                  {
                    field: content.identityNumberField,
                  }
                );
              }
              return;
            }}
          />
        </View>
      </>
    );
  } else if (
    bookingRule.requiresDocumentNoForDomestic &&
    ((!isSameNationalityAsRoute && isDomesticId) || isDomesticNonId)
  ) {
    return <InternationalFields {...internationalFieldsProps} />;
  }

  return null;
}

const Style = StyleSheet.create({
  section: {
    overflow: 'visible',
  },
  flexRow: {
    flexDirection: 'row',
  },
  addOnTitle: {
    paddingVertical: Token.spacing.s,
    paddingHorizontal: Token.spacing.m,
    marginHorizontal: -Token.spacing.m,
  },
  addOnFlightWrapper: {
    marginHorizontal: -Token.spacing.s,
    flexDirection: 'row',
  },
  row: {
    marginTop: Token.spacing.m,
  },
  shortField: {
    width: '33.33%',
  },
  mediumField: {
    width: '66.66%',
  },
  titleField: {
    marginRight: Token.spacing.l,
  },
});

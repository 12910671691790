import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import imageSrc from '../../../img/hotel-search-empty.png';

import { Button, Image, Text, Token } from '@traveloka/web-components';

type Props = {
  buttonText?: string;
  onPress?(): void;
  style?: StyleProp<ViewStyle>;
  text: string;
};

export default function EmptyResult(props: Props) {
  const { style, text, buttonText, onPress } = props;

  return (
    <View style={[Style.container, style]}>
      <Image src={imageSrc} />
      <Text style={Style.text}>{text}</Text>
      {buttonText && <Button text={buttonText} onPress={onPress} />}
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    marginTop: Token.spacing.xl,
    marginBottom: Token.spacing.m,
  },
});

import React, { useMemo, useRef, useCallback } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import Chevron from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';
import { Icon, Text, Token, RadioGroup } from '@traveloka/web-components';
import {
  usePriceDisplay,
  PriceDisplay,
} from 'hotel/search/contexts/PriceDisplayContext';
import { Dropdown } from '@traveloka/ctvweb-ui';
import { Handler } from '@traveloka/ctvweb-ui/src/shared/components/Dropdown/Dropdown';

export default function RoomPriceDisplay() {
  const dropdown = useRef<Handler>(null!);
  const [priceDisplay, setPriceDisplay] = usePriceDisplay();

  const content = useContentResource().CorporateHotelDetailRoomSearch;

  const priceDisplayItems = useMemo(
    () => [
      {
        label: content.roomPerNightText,
        value: PriceDisplay.NIGHTLY,
      },
      {
        label: content.totalPriceText,
        value: PriceDisplay.TOTAL,
      },
    ],
    []
  );

  const displayValue = useMemo(
    () => priceDisplayItems.find(item => item.value === priceDisplay)!.label,
    [priceDisplay, priceDisplayItems]
  );

  const handleRadioChange = useCallback(
    (value: string | number) => {
      dropdown.current.setIsCollapsed(true);
      setPriceDisplay(value as PriceDisplay);
    },
    [setPriceDisplay]
  );

  return (
    <Dropdown
      ref={dropdown}
      alignment="right"
      trigger={
        <TouchableOpacity activeOpacity={0.5}>
          <Text variant="ui-small" style={Style.title}>
            {content.priceDisplayTitle}
          </Text>
          <View style={Style.value}>
            <Text
              variant="ui-small"
              ink="primary-interactive"
              style={Style.text}
            >
              {displayValue}
            </Text>
            <Icon src={Chevron} />
          </View>
        </TouchableOpacity>
      }
    >
      <RadioGroup
        value={priceDisplay}
        options={priceDisplayItems}
        onChange={handleRadioChange}
      />
    </Dropdown>
  );
}

const Style = StyleSheet.create({
  title: {
    fontWeight: Token.typography.weightMedium.fontWeight,
    marginBottom: Token.spacing.m,
  },
  value: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    marginRight: Token.spacing.xs,
  },
});

import { Config } from './types';

const config: Config = {
  auth: {
    audience: 'https://tvlk/ctv',
    domain: 'tvlk-dev.auth0.com',
    clientId: 'NqjzaMttt4KDsr5ehgly88cses607yJA',
    maxAge: 43200,
  },
  amplify: {
    Auth: {
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      region: 'ap-southeast-1',
      userPoolId: 'ap-southeast-1_DKJ78n7hI',
      userPoolWebClientId: '361mlb5thatpiuapuocj43tj2f',

      oauth: {
        domain: 'ctv-stg.auth.ap-southeast-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: 'https://corporates.ctv.staging-traveloka.com/',
        redirectSignOut: 'https://corporates.ctv.staging-traveloka.com/',
        responseType: 'code',
      },
    },
  },
  apiHost: {
    search: 'https://search-api.ctv.staging-traveloka.com/',
    booking: 'https://booking-api.ctv.staging-traveloka.com/',
    refund: 'https://refund-api.ctv.staging-traveloka.com/',
    management: 'https://management-api.ctv.staging-traveloka.com/',
    content: 'https://cntpapi.cnt.staging-traveloka.com/',
  },
  datadog: {
    // All empty strings are intentional.
    applicationId: '',
    clientToken: '',
    defaultPrivacyLevel: 'mask',
    env: '',
    service: 'corporate-travel-web',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionReplaySampleRate: 100,
    sessionSampleRate: 10,
    site: 'datadoghq.com',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
  },
  securePayment: 'https://payfrm.pay.staging-traveloka.com',
};

export default config;

import React from 'react';
import { Animated, FlatList, StyleSheet, View } from 'react-native';

import {
  useTheme,
  Button,
  Image,
  Text,
  Token,
} from '@traveloka/web-components';
import { useLayout } from '@traveloka/web-components/future';

import RouteIcon from '../../../../svg/16/ic_flight_route_16px.svg';
import Divider from '../../../shared/components/Divider/Divider';
import FlightCompliance from '../../FlightCompliance/FlightCompliance';
import {
  useActiveDetail,
  useDispatch,
  ActiveDetail,
} from '../../FlightSearchResultItem/contexts/DetailContext';
import useAccentDefault from '../../FlightSearchResultItem/hooks/use-accent-default';
import useControlledTransition from '../../FlightSearchResultItem/hooks/use-controlled-transition';
import { FlightData } from '../../types';
import { useProps } from '../contexts/PropsContext';
import { appendTestId } from '../../../shared/utils/TestUtil';

type ToggleBehaviorMap = Record<ActiveDetail, number>;
type Props = {
  testID?: string;
};

export default function FlightSummary(props: Props) {
  const { flights } = useProps();
  const { testID } = props;

  return (
    <>
      <FlatList
        data={flights}
        keyExtractor={item => item.flightId}
        ItemSeparatorComponent={Divider}
        renderItem={({ item }) => (
          <>
            <View style={Style.row}>
              <DateInfo flight={item} />
              <AirlineInfo flight={item} />
              <JourneyInfo flight={item} />
              <DurationInfo flight={item} />
            </View>
            <ComplianceInfo flight={item} />
          </>
        )}
      />
      <DetailToggler testID={testID} />
    </>
  );
}

type CommonProps = {
  flight: FlightData & { flightLabel: string };
};

function DateInfo(props: CommonProps) {
  const { flight } = props;

  return (
    <View style={Style.dateInfo}>
      <Text>{flight.flightLabel}</Text>
      <Text variant="ui-small" ink="secondary">
        {flight.summary.departureDate}
      </Text>
    </View>
  );
}

function AirlineInfo(props: CommonProps) {
  const { summary } = props.flight;

  return (
    <View style={[Style.centerRow, Style.airlineInfo]}>
      <View style={Style.row}>
        {summary.airlineLogos.map((logo, index) => (
          <Image
            key={index}
            src={logo.src}
            alt={logo.alt}
            height={24}
            width={24}
            objectFit="contain"
            importance="low"
            style={{ marginRight: Token.spacing.xs }}
          />
        ))}
      </View>
      <View style={Style.airlineName}>
        <Text>{summary.airlineName}</Text>
        <Text variant="ui-small" ink="secondary">
          {summary.seatClass}
        </Text>
      </View>
    </View>
  );
}

function JourneyInfo(props: CommonProps) {
  const { summary } = props.flight;

  return (
    <View style={[Style.row, Style.journeyInfo]}>
      <View style={Style.segment}>
        <Text>{summary.departureTime}</Text>
        <Text variant="ui-small" ink="secondary">
          {summary.departureLocation}
        </Text>
      </View>
      <View style={Style.icon}>
        <Image
          src={RouteIcon}
          width={Token.spacing.xl}
          height={Token.spacing.l}
          objectFit="contain"
        />
      </View>
      <View style={Style.segment}>
        <Text>{summary.arrivalTime}</Text>
        <Text variant="ui-small" ink="secondary">
          {summary.arrivalLocation}
        </Text>
      </View>
    </View>
  );
}

function DurationInfo(props: CommonProps) {
  const { label } = useProps();
  const { summary } = props.flight;

  return (
    <View style={Style.durationInfo}>
      <Text>{label.duration}</Text>
      <Text variant="ui-small" ink="secondary">
        {`${summary.durationStr} \u2022 ${summary.transitStr}`}
      </Text>
    </View>
  );
}

function ComplianceInfo(props: CommonProps) {
  const { compliance } = props.flight;

  return (
    compliance && (
      <View style={Style.complianceInfo}>
        <FlightCompliance label={compliance.label} style={Style.compliance} />
      </View>
    )
  );
}

function DetailToggler(props: Props) {
  const { label, onContinuePress, totalPrice } = useProps();
  const dispatch = useDispatch();
  const activeDetail = useActiveDetail();
  const { color } = useTheme();
  const { testID } = props;

  const { interpolate } = useControlledTransition<
    ActiveDetail,
    ToggleBehaviorMap
  >(activeDetail, Token.animation.timing.instant);
  const [flightRect, flightBindings] = useLayout();
  const [fareRect, fareBindings] = useLayout();

  const accentHeight = interpolate({
    none: 0,
    flight: Token.border.width.bold,
    fare: Token.border.width.bold,
  });

  const accentOffsetMap = useAccentDefault({
    flight: 0,
    fare: flightRect.width + Token.spacing.xxl,
  });
  const accentOffset = interpolate(accentOffsetMap);

  const accentWidthMap = useAccentDefault({
    flight: flightRect.width,
    fare: fareRect.width,
  });
  const accentWidth = interpolate(accentWidthMap);
  const containerStyle = {
    backgroundColor: color.lightNeutral,
    marginBottom: activeDetail === 'none' ? -Token.spacing.xl : 0,
  };

  return (
    <View style={[Style.priceAndToggle, containerStyle]}>
      <View>
        <View style={Style.detailToggler}>
          <Button
            {...flightBindings}
            text={label.flightDetail}
            onPress={() => dispatch('flight')}
            variant="text"
            size="small"
          />
          <Button
            {...fareBindings}
            text={label.fareDetail}
            onPress={() => dispatch('fare')}
            variant="text"
            size="small"
            style={Style.detailTogglerOffset}
          />
        </View>
        <View style={Style.accentContainer}>
          <Animated.View
            style={{
              backgroundColor: color.tintPrimary,
              height: 1,
              width: 1,
              transform: [{ scaleX: accentWidth }, { scaleY: accentHeight }],
              transformOrigin: 'bottom left',
              marginLeft: accentOffset,
            }}
          />
        </View>
      </View>
      <View style={Style.priceContainer}>
        <View testID={appendTestId(testID, 'price')} style={Style.price}>
          <Text variant="ui-large" ink="price">
            {`${totalPrice}${label.priceSuffix}`}
          </Text>
          <Text variant="ui-tiny" ink="secondary">
            {label.priceHelper}
          </Text>
        </View>
        <Button
          testID={appendTestId(testID, 'submit')}
          text={label.button}
          onPress={onContinuePress}
          variant="main-cta"
        />
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  centerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateInfo: {
    flex: 1,
    marginRight: Token.spacing.m,
  },
  airlineInfo: {
    flex: 2,
    marginRight: Token.spacing.m,
  },
  airlineName: {
    flex: 1,
  },
  journeyInfo: {
    flex: 2,
    marginRight: Token.spacing.m,
  },
  segment: {
    flex: 1,
  },
  icon: {
    marginHorizontal: Token.spacing.m,
  },
  durationInfo: {
    flex: 1,
  },
  complianceInfo: {
    alignItems: 'flex-end',
    marginTop: Token.spacing.s,
  },
  compliance: {
    borderRadius: Token.border.radius.normal,
  },
  priceAndToggle: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.l,
    marginHorizontal: -Token.spacing.m,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: Token.spacing.l,
    zIndex: 1,
    top: 0,
    // @ts-ignore
    position: 'sticky',
  },
  detailToggler: {
    flexDirection: 'row',
  },
  detailTogglerOffset: {
    marginLeft: Token.spacing.xxl,
  },
  accentContainer: {
    height: Token.border.width.bold,
    justifyContent: 'flex-end',
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  price: {
    alignItems: 'flex-end',
    marginRight: Token.spacing.l,
  },
});

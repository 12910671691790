import React, { ComponentProps } from 'react';

import BaggageBaseIcon from '@traveloka/icon-kit-web/svg/darkBlue/ic_facilities_baggage_empty_24px.svg';
import BaggagePlusIcon from '@traveloka/icon-kit-web/svg/darkBlue/ic_facilities_baggage_add_24px.svg';
import MealIcon from '@traveloka/icon-kit-web/svg/darkBlue/ic_facilities_meal_24px.svg';

import BaggageBaseIconLight from '@traveloka/icon-kit-web/svg/light/ic_facilities_baggage_empty_24px.svg';
import BaggagePlusIconLight from '@traveloka/icon-kit-web/svg/light/ic_facilities_baggage_add_24px.svg';
import MealIconLight from '@traveloka/icon-kit-web/svg/light/ic_facilities_meal_24px.svg';

import { Icon, Token, useTheme, Text } from '@traveloka/web-components';
import { FlightFacilityData } from '../types';
import { StyleSheet, ViewProps, View } from 'react-native';

type IconProps = Pick<
  ComponentProps<typeof Icon>,
  'height' | 'width' | 'fill' | 'stroke' | 'alt'
>;

type Props = {
  facility: FlightFacilityData;
  useLightIcon?: boolean;
  style?: ViewProps['style'];
  testID?: string;
} & IconProps;

export default function FlightFacility(props: Props) {
  const { testID, facility, useLightIcon, style, ...iconProps } = props;

  const { color } = useTheme();

  return (
    <View testID={testID} style={[Style.facility, style]}>
      <FlightFacilityIcon
        {...iconProps}
        facility={facility}
        useLightIcon={useLightIcon}
      />
      <Text
        variant="ui-small"
        ink="secondary"
        style={[Style.text, useLightIcon && { color: color.lightSecondary }]}
      >
        {facility.description}
      </Text>
    </View>
  );
}

type FacilityIconProps = {
  facility: FlightFacilityData;
  useLightIcon?: boolean;
} & IconProps;

export function FlightFacilityIcon(props: FacilityIconProps) {
  const { facility, useLightIcon, ...iconProps } = props;

  if (facility.type === 'baggage') {
    return <BaggageWithText {...iconProps} text={String(facility.weight)} />;
  }

  return <Icon {...iconProps} src={getIconSrc(facility.type, useLightIcon)} />;
}

function getIconSrc(
  type: FlightFacilityData['type'],
  useLightIcon?: boolean
): string {
  const iconSrc = {
    baggage: useLightIcon ? BaggageBaseIconLight : BaggageBaseIcon,
    baggagePaid: useLightIcon ? BaggagePlusIconLight : BaggagePlusIcon,
    meal: useLightIcon ? MealIconLight : MealIcon,
  };

  switch (type) {
    case 'baggage':
      return iconSrc.baggage;
    case 'baggage_paid':
      return iconSrc.baggagePaid;
    case 'meal':
      return iconSrc.meal;
  }
}

type BaggageWithTextProps = {
  text: string;
} & IconProps;

function BaggageWithText(props: BaggageWithTextProps) {
  const { color } = useTheme();

  const {
    text,
    fill = color.darkSecondary,
    stroke = color.tintPrimary,
    width = 24,
    height = 24,
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke={stroke}
          strokeWidth="2"
          d="M9,5 L15,5 C15.5522847,5 16,5.44771525 16,6 L16,8 L8,8 L8,6 C8,5.44771525 8.44771525,5 9,5 Z M4.5638852,8 L19.4361148,8 C20.3276335,8 20.6509198,8.09282561 20.9768457,8.2671327 C21.3027716,8.4414398 21.5585602,8.69722837 21.7328673,9.0231543 C21.9071744,9.34908022 22,9.67236646 22,10.5638852 L22,17.4361148 C22,18.3276335 21.9071744,18.6509198 21.7328673,18.9768457 C21.5585602,19.3027716 21.3027716,19.5585602 20.9768457,19.7328673 C20.6509198,19.9071744 20.3276335,20 19.4361148,20 L4.5638852,20 C3.67236646,20 3.34908022,19.9071744 3.0231543,19.7328673 C2.69722837,19.5585602 2.4414398,19.3027716 2.2671327,18.9768457 C2.09282561,18.6509198 2,18.3276335 2,17.4361148 L2,10.5638852 C2,9.67236646 2.09282561,9.34908022 2.2671327,9.0231543 C2.4414398,8.69722837 2.69722837,8.4414398 3.0231543,8.2671327 C3.34908022,8.09282561 3.67236646,8 4.5638852,8 Z"
        />
        <text
          x={5.5}
          y={18}
          fill={fill}
          fontSize={Token.typography.captionMicro.fontSize}
          fontFamily={Token.typography.weightBold.fontFamily}
          fontWeight={Token.typography.weightBold.fontWeight}
        >
          <tspan>{text}</tspan>
        </text>
      </g>
    </svg>
  );
}

const Style = StyleSheet.create({
  facility: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: Token.spacing.xxs,
  },
});

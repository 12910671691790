import React, { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { Token } from '@traveloka/web-components';

type Props = PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
}>;

export default function TwoColumn(props: Props) {
  return <View {...props} style={[Style.row, props.style]} />;
}

TwoColumn.Big = function(props: Props) {
  return <View {...props} style={[Style.bigColumn, props.style]} />;
};

TwoColumn.Small = function(props: Props & { isSticky?: boolean }) {
  const { isSticky = true, ...rest } = props;
  return (
    <View
      {...rest}
      style={[Style.smallColumn, isSticky && Style.sticky, props.style]}
    />
  );
};

const Style = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginLeft: -Token.spacing.m,
  },
  smallColumn: {
    width: 280,
    marginLeft: Token.spacing.m,
  },
  sticky: {
    // @ts-ignore
    position: 'sticky',
    top: 60 + Token.spacing.m,
    height: 'fit-content',
  },
  bigColumn: {
    flex: 1,
    marginLeft: Token.spacing.m,
  },
});

import React from 'react';
import { StyleSheet } from 'react-native';
import { useController, useFormContext } from 'react-hook-form';

import { InputGroup } from '@traveloka/ctvweb-ui';
import InputDropdown, {
  Props as InputDropdownProps,
} from '@traveloka/ctvweb-ui/src/shared/components/form/InputDropdown/InputDropdown';

import { FieldProps } from '../types';

export type Props = FieldProps<string> & Omit<InputDropdownProps<any>, 'value'>;

export default function InputDropdownField(props: Props) {
  const {
    defaultValue,
    name,
    validate,
    onFocus,
    label,
    leftHelper,
    rightHelper,
    disabled,
    style,
    onPressItem,
    items,
    ...inputDropdownProps
  } = props;

  const { control } = useFormContext();
  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });

  function handleFocus() {
    if (typeof onFocus === 'function') {
      onFocus();
    }
  }

  function handlePressItem(item: any) {
    onChange(item.value);
    onPressItem && onPressItem(item);
  }

  return (
    <InputGroup
      label={label}
      error={error?.message}
      leftHelper={leftHelper}
      rightHelper={rightHelper}
      disabled={disabled}
      style={style}
    >
      <InputDropdown
        {...inputDropdownProps}
        disabled={disabled}
        error={error?.message}
        showError={false}
        onBlur={onBlur}
        onFocus={handleFocus}
        items={items}
        onPressItem={handlePressItem}
        value={value}
        style={styles.input}
        labelAsInputValue
      />
    </InputGroup>
  );
}

const styles = StyleSheet.create({
  input: {
    zIndex: 1,
  },
});

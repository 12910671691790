import React, { useCallback, useRef } from 'react';
import { View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Handler } from '@traveloka/ctvweb-ui/src/shared/components/Dropdown/Dropdown';
import { useTheme, RadioGroup, Text } from '@traveloka/web-components';

import {
  useFlightSearchOptionContext,
  useFlightSearchSortContext,
} from 'flight/search/contexts/FlightSortFilterContext';

import { getThemeStyle, CommonStyle } from '../filter-search-toolbar.style';
import FlightSearchToolbarMenuContainer from '../FlightSearchToolbarMenuContainer';

function SortMenu() {
  const menuRef = useRef<Handler>(null!);
  const { CorporateFlightSearchToolbar } = useContentResource();
  const content = {
    default: 'No Sort',
    title: CorporateFlightSearchToolbar.sort,
    price: CorporateFlightSearchToolbar.sortLowestPrice,
    earliestDeparture: CorporateFlightSearchToolbar.sortEarliestDeparture,
    latestDeparture: CorporateFlightSearchToolbar.sortLatestDeparture,
    earliestArrival: CorporateFlightSearchToolbar.sortEarliestArrival,
    latestArrival: CorporateFlightSearchToolbar.sortLatestArrival,
    duration: CorporateFlightSearchToolbar.sortShortestDuration,
  };

  const ThemeStyle = getThemeStyle(useTheme());
  const { sortOptions, setSort } = useFlightSearchOptionContext();
  const value = useFlightSearchSortContext();

  const handleChange = useCallback(
    (value: string | number) => {
      menuRef.current.setIsCollapsed(true);
      setSort(value as any);
    },
    [setSort]
  );

  return (
    <FlightSearchToolbarMenuContainer
      ref={menuRef}
      alignment="right"
      text={content.title}
      style={CommonStyle.menuItem}
      testID="flight.search.toolbar.sort"
    >
      <Text ink="primary" variant="ui-small">
        {content.title}
      </Text>
      <View style={[CommonStyle.container, ThemeStyle.container]}>
        <View style={CommonStyle.checkbox}>
          <RadioGroup
            value={value}
            options={sortOptions.map(option => ({
              label: content[option],
              value: option,
            }))}
            onChange={handleChange}
          />
        </View>
      </View>
    </FlightSearchToolbarMenuContainer>
  );
}

export default SortMenu;

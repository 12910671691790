import { useEffect, useRef } from 'react';

import { Animated, Easing } from 'react-native';

// Copied and modified from Input/useTransition
export default function useControlledTransition<Behavior, BehaviorValueMap>(
  behavior: Behavior,
  duration: number,
  onFinishTransitioning?: () => void
) {
  const animatedValue = useRef(new Animated.Value(0));
  const prevValue = useRef(0);
  const prevBehavior = useRef<Behavior>(behavior);

  useEffect(() => {
    const animation = Animated.timing(animatedValue.current, {
      toValue: prevValue.current === 0 ? 1 : 0,
      duration,
      easing: Easing.inOut(Easing.linear),
    });

    const rafId = requestAnimationFrame(() =>
      animation.start(() => {
        typeof onFinishTransitioning === 'function' && onFinishTransitioning();
      })
    );

    prevBehavior.current = behavior;
    prevValue.current = prevValue.current === 0 ? 1 : 0;

    return () => cancelAnimationFrame(rafId);
  }, [behavior]);

  function interpolate(behaviorValueMap: BehaviorValueMap) {
    return getInterpolation(
      animatedValue.current,
      behavior,
      prevBehavior.current,
      prevValue.current === 0,
      behaviorValueMap
    );
  }

  return { behavior, interpolate };
}

function getInterpolation(
  animatedValue: Animated.Value,
  currentBehavior: any,
  prevBehavior: any,
  isDirectionFlipped: boolean,
  behaviorValueMap: any
) {
  const fromPointer = isDirectionFlipped ? prevBehavior : currentBehavior;
  const toPointer = isDirectionFlipped ? currentBehavior : prevBehavior;

  return animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [behaviorValueMap[fromPointer], behaviorValueMap[toPointer]],
  });
}

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  addDays,
  addYears,
  differenceInCalendarDays,
  startOfDay,
} from 'date-fns';

import {
  useApi,
  useAuth,
  Permission,
  useLocalizedDateFormat,
  useFeatureControl,
} from '@traveloka/ctv-core';
import {
  EntryListRequest,
  EntryListResponse,
  GET_ENTRY_LIST,
  Operator,
} from '@traveloka/ctv-core/pad';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { SearchForm as SearchFormUI } from '@traveloka/ctvweb-ui/hotel';
import { Traveler } from '@traveloka/ctvweb-ui/src/generic/TravelerPickerModal/types';
import { Item } from '@traveloka/ctvweb-ui/src/shared/components/form/InputDropdown/types';
import AutocompleteType, {
  Item as HotelAutocompleteItem,
} from '@traveloka/ctvweb-ui/src/hotel/HotelAutocomplete/types';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import { PartialEmployee } from 'company/types';
import {
  MAX_DURATION,
  MAX_GUESTS,
  MAX_NON_EMPLOYEE_CHILDREN,
  MAX_ROOMS,
} from 'hotel/search/constants/SearchConstant';
import useAutocomplete from 'hotel/search/hooks/useAutocomplete';
import {
  parseSearchSpec,
  stringifySearchSpec,
  GeoSpec,
  convertNonEmployeeTypeToPayload,
  convertNonEmployeePayloadToType,
} from 'hotel/search/utils/SearchQueryUtil';
import {
  HotelNonEmployeeTraveler,
  NonEmployeeType,
} from 'hotel/shared/api/types';
import {
  defaultHotelNonEmployee,
  useSearchSpec,
  useSearchSpecDispatch,
} from 'hotel/shared/contexts/SpecContext';
import RevampTravelerPickerModal, {
  NonEmployeeField,
  usePickerDesign,
  ValidationAdditionalInfo,
  ValidationReturn,
} from 'shared/components/TravelerPickerModal/RevampTravelerPickerModal';
import useEagerNavigation from 'shared/hooks/useEagerNavigation';
import { formatCurrency, formatMessage } from 'shared/utils/intl';
import useRecentSearchProperty from 'hotel/search/hooks/useRecentSearchProperty';
import useRecentSearchGeo from 'hotel/search/hooks/useRecentSearchGeo';
import { Item as HotelCardItem } from '@traveloka/ctvweb-ui/src/hotel/HotelCard/types';
import { convert } from 'shared/utils/currency';

type Props = {
  funnelSource: 'SEARCH_FORM' | 'SEARCH_RESULT_SF' | 'HOTEL_DETAIL_SF';
  onSearch?: () => void;
  isSearching?: boolean;
  tripRequestEnabled: boolean;
};

export type NonEmployeeFields = [
  {
    type: NonEmployeeType.ADULT;
    value: number;
    onValidate(
      employees: Traveler[],
      nonEmployees: NonEmployeeField[],
      additionalInfo?: ValidationAdditionalInfo
    ): Nullable<ValidationReturn>;
  },
  {
    type: NonEmployeeType.CHILD_AGE;
    value: string[];
    onValidate(
      employees: Traveler[],
      nonEmployees: NonEmployeeField[],
      additionalInfo?: ValidationAdditionalInfo
    ): Nullable<ValidationReturn>;
  }
];

const defaultSpec = parseSearchSpec('');

export default function HotelSearchForm(props: Props) {
  const {
    isSuspended,
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();
  const { funnelSource, onSearch, isSearching, tripRequestEnabled } = props;

  const { format } = useLocalizedDateFormat();
  const searchSpec = useSearchSpec() ?? defaultSpec;
  const setSearchSpec = useSearchSpecDispatch() ?? defaultSpec;
  const { user } = useAuth();
  const recentSearchFC = useFeatureControl('b2b-recent-search');

  const guestTtlRef = useRef<number>();
  const roomTtlRef = useRef<number>();
  const destinationTtlRef = useRef<number>();
  const [guestError, setGuestError] = useState(false);
  const [roomError, setRoomError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const [roomErrorTooltip, setRoomErrorTooltip] = useState('');
  const [
    autocompleteOptions,
    fetchAutocomplete,
    loadingAutocomplete,
  ] = useAutocomplete();
  const {
    data: recentSearchDataGeo,
    isLoading: recentSearchDataGeoLoading,
  } = useRecentSearchGeo();
  const {
    data: recentSearchDataProperty,
    isLoading: recentSearchDataPropertyLoading,
  } = useRecentSearchProperty();

  const [checkInDate, setCheckInDate] = useState<Date>(searchSpec.checkInDate);
  const [duration, setDuration] = useState<number>(0);
  const [autocomplete, setAutocomplete] = useState<GeoSpec>();
  const [rooms, setRooms] = useState<number>(searchSpec.rooms);
  const [travelers, setTravelers] = useState<Traveler[]>(searchSpec.travelers);
  const [nonEmployeeTravelers, setNonEmployeeTravelers] = useState(
    searchSpec.nonEmployeeTravelers || defaultHotelNonEmployee
  );

  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const todayNextYear = useRef(startOfDay(addYears(new Date(), 1)));

  const content = useContentResource().CorporateHotelSearchForm;
  const productContent = useContentResource().CorporateProductSearchForm;
  const hotelRecentlySearchContent = useContentResource()
    .CorporateHotelRecentlySearch;

  const [canBookForOwn, canBookForOthers, canBookNonEmployee] = useMemo(
    () => [
      user?.has(Permission.BOOK_PRODUCT_FOR_OWN),
      user?.has(Permission.BOOK_PRODUCT_FOR_OTHERS),
      user?.has(Permission.BOOK_PRODUCT_FOR_NON_EMPLOYEE),
    ],
    [user]
  );
  const pickerDesign = usePickerDesign();

  const canBook = canBookForOwn || canBookForOthers || canBookNonEmployee;
  const canBookAll = canBookForOwn && canBookForOthers;

  const fetchEntryList = useApi<
    EntryListResponse<PartialEmployee>,
    EntryListRequest
  >({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_LIST,
  });

  const navigate = useEagerNavigation();
  function handleSearchPress() {
    if (travelers.length + nonEmployeeTravelers.adults === 0) {
      setGuestError(true);

      clearTimeout(guestTtlRef.current);
      guestTtlRef.current = window.setTimeout(() => {
        setGuestError(false);
      }, 5000);

      return;
    }

    if (!autocomplete) {
      setDestinationError(true);

      clearTimeout(destinationTtlRef.current);
      destinationTtlRef.current = window.setTimeout(() => {
        setDestinationError(false);
      }, 5000);

      return;
    }

    const spec = {
      ...searchSpec,
      travelers,
      nonEmployeeTravelers,
      checkInDate,
      checkOutDate: addDays(checkInDate, duration),
      type: autocomplete.type,
      id: autocomplete.id,
      geoName: autocomplete.geoName,
      rooms,
      funnelSource,
    };

    const stringifySpec = stringifySearchSpec(spec);
    navigate({
      pathname: spec.type === 'HOTEL' ? '/hotel/detail' : '/hotel/search',
      search: stringifySpec,
    });
    setSearchSpec(parseSearchSpec(stringifySpec));

    onSearch && onSearch();
  }

  function handleRoomChange(room: number) {
    if (room < 1) {
      setRooms(1);

      return;
    }

    if (room > MAX_ROOMS) {
      setRoomError(true);
      setRoomErrorTooltip(
        formatMessage(content.errorTooltipMaxRoom, { maxRoom: MAX_ROOMS })
      );

      clearTimeout(roomTtlRef.current);
      roomTtlRef.current = window.setTimeout(() => {
        setRoomError(false);
      }, 5000);

      return;
    }

    if (room > travelers.length + nonEmployeeTravelers.adults) {
      setRoomError(true);
      setRoomErrorTooltip(content.errorTooltipRoomExceedGuest);

      clearTimeout(roomTtlRef.current);
      roomTtlRef.current = window.setTimeout(() => {
        setRoomError(false);
      }, 5000);

      return;
    }

    setRooms(room);
  }

  function handleCheckInChange(date: Date) {
    setCheckInDate(date);
  }

  function handleDurationChange(item: Item) {
    setDuration(Number(item.value));
  }

  const handleDestinationChange = useCallback(
    (item: Item, type?: AutocompleteType) => {
      switch (type) {
        case AutocompleteType.RECENT_SEARCH_CITY: {
          const option = recentSearchDataGeo?.details.find(
            option => option.id === item.value
          );
          if (option) {
            setAutocomplete({
              type: option.type,
              id: option.id,
              geoName: option.name,
            });
          }
        }
        case AutocompleteType.RECENT_SEARCH_PROPERTY: {
          const option = recentSearchDataProperty?.details.find(
            option => option.hotelId === item.value
          );
          if (option) {
            setAutocomplete({
              type: 'HOTEL',
              id: option.hotelId,
              geoName: option.hotelName,
            });
          }
        }
        case AutocompleteType.POLULAR_DESTINATION:
        default: {
          const option = autocompleteOptions.find(
            option => option.id === item.value
          );
          if (option) {
            setAutocomplete({
              type: option.type,
              id: option.id,
              geoName: option.name,
            });
          }
        }
      }
    },
    [
      autocompleteOptions,
      recentSearchDataGeo,
      recentSearchDataProperty,
      setAutocomplete,
    ]
  );

  function handleTravelerChange(
    travelers: Traveler[],
    nonEmployeeFields?: NonEmployeeFields
  ) {
    if (canBookNonEmployee && nonEmployeeFields) {
      const value = { ...defaultHotelNonEmployee };
      nonEmployeeFields.forEach(field => {
        const convertedKey = convertNonEmployeeTypeToPayload(field.type);
        if (field.type === NonEmployeeType.ADULT && convertedKey === 'adults') {
          value[convertedKey] = field.value;
        } else if (
          field.type === NonEmployeeType.CHILD_AGE &&
          convertedKey === 'childrenAges'
        ) {
          value[convertedKey] = field.value;
        }
      });
      setNonEmployeeTravelers(value);
    } else {
      setNonEmployeeTravelers(defaultHotelNonEmployee);
    }

    const rooms = Math.min(
      Math.max(
        travelers.length +
          ((nonEmployeeFields?.find(f => f.type === NonEmployeeType.ADULT)
            ?.value as number) ?? 0),
        1
      ),
      MAX_ROOMS
    );
    setIsPickerVisible(false);
    setTravelers(travelers);
    setRooms(rooms);
    setGuestError(false);
  }

  useEffect(() => {
    if (pickerDesign === 'own') {
      const payload = {
        entityType: 'employeeList',
        search: {
          entriesCount: 1,
          filter: [
            {
              fieldName: 'email',
              arguments: {
                value: user!.email,
                operator: Operator.EQUAL,
              },
            },
          ],
        },
      };

      fetchEntryList(payload).then(res => {
        if (res.success) {
          setTravelers(res.data.entries);
        }
      });
    }
  }, []);

  useEffect(() => {
    fetchAutocomplete('');
  }, []);

  useEffect(() => {
    setCheckInDate(searchSpec.checkInDate);
    setDuration(differenceInCalendarDays(searchSpec.checkOutDate, checkInDate));
    setAutocomplete(() => {
      if (!searchSpec.id) {
        return undefined;
      }

      return {
        type: searchSpec.type!,
        id: searchSpec.id!,
        geoName: searchSpec.geoName!,
      };
    });
    setRooms(searchSpec.rooms);
    setTravelers(searchSpec.travelers);
    setNonEmployeeTravelers(
      searchSpec.nonEmployeeTravelers || defaultHotelNonEmployee
    );
  }, [searchSpec]);

  const getBadgeText = useCallback(
    (type: string) => {
      switch (type) {
        case 'HOTEL':
          return content.destinationTypeHotel;
        case 'LANDMARK':
          return content.destinationTypeLandmark;
        case 'REGION':
        default:
          return content.destinationTypeRegion;
      }
    },
    [content]
  );

  const getBadgeCityText = useCallback(
    (type: string) => {
      switch (type) {
        case 'CITY':
          return content.destinationTypeCity;
        case 'AREA':
          return content.destinationTypeArea;
        case 'LANDMARK':
          return content.destinationTypeLandmark;
        case 'REGION':
        default:
          return content.destinationTypeRegion;
      }
    },
    [content]
  );

  const recentSearchProperty: HotelCardItem[] = useMemo(() => {
    return (recentSearchDataProperty?.details || []).map(detail => {
      return {
        value: detail.hotelId,
        image: detail.image,
        price:
          Number(detail.price.amount) > 0
            ? formatCurrency(convert(detail.price))
            : null,
        label: detail.hotelName,
        location: detail.location,
        showRibbon: detail.isFrequentlyBooked,
      };
    });
  }, [recentSearchDataProperty]);

  const recentSearchGeo: HotelAutocompleteItem[] = useMemo(() => {
    return (recentSearchDataGeo?.details || []).map(detail => {
      return {
        label: detail.name,
        subLabel: detail.location,
        badgeText: getBadgeCityText(detail.type),
        subBadgeText:
          Number(detail.numOfHotels) > 0
            ? formatMessage(content.destinationNumOfHotels, {
                numOfHotels: detail.numOfHotels,
              })
            : '',
        value: detail.id,
      };
    });
  }, [recentSearchDataGeo]);

  const durationOptions = useMemo(() => {
    return Array.from({ length: MAX_DURATION }, (_, i) => {
      const night = i + 1;

      return {
        label: formatMessage(content.numOfNightInfoText, { num: night }),
        value: String(night),
        subLabel: format(addDays(checkInDate, night), 'SHORT_WEEKDAY'),
      };
    });
  }, [checkInDate]);

  const destinationOptions = useMemo(
    () =>
      autocompleteOptions.map<HotelAutocompleteItem>(option => ({
        label: option.name,
        value: option.id,
        subLabel: option.location,
        badgeText: getBadgeText(option.type),
        subBadgeText:
          Number(option.numOfHotels) > 0
            ? formatMessage(content.destinationNumOfHotels, {
                numOfHotels: option.numOfHotels,
              })
            : '',
      })),
    [autocompleteOptions]
  );

  const guestText = useMemo(() => {
    const showDetail: string[] = [];
    const nonEmployeeKeys = Object.keys(nonEmployeeTravelers) as Array<
      keyof typeof nonEmployeeTravelers
    >;

    nonEmployeeKeys.forEach(key => {
      switch (convertNonEmployeePayloadToType(key)) {
        case NonEmployeeType.ADULT:
          if (nonEmployeeTravelers.adults + travelers.length > 0) {
            showDetail.push(
              formatMessage(productContent.nonEmployeeAdultUnit, {
                num: nonEmployeeTravelers.adults + travelers.length,
              })
            );
          }
          break;
        case NonEmployeeType.CHILD_AGE:
          if (nonEmployeeTravelers.childrenAges.length > 0) {
            showDetail.push(
              formatMessage(productContent.nonEmployeeChildUnit, {
                num: nonEmployeeTravelers.childrenAges.length,
              })
            );
          }
      }
    });

    const isOneEmployeeOnly =
      travelers.length === 1 &&
      nonEmployeeTravelers.adults + nonEmployeeTravelers.childrenAges.length ===
        0;

    return showDetail.length === 0
      ? content.emptyGuestText
      : isOneEmployeeOnly
      ? travelers[0].fullname
      : showDetail.join(', ');
  }, [canBookNonEmployee, travelers, nonEmployeeTravelers]);

  const guestInfoTooltip = canBookAll
    ? content.canBookAllText
    : canBookForOthers
    ? content.canBookForOthers
    : '';

  const room = formatMessage(content.roomInfoText, { num: rooms });
  const numOfNight = formatMessage(content.numOfNightInfoText, {
    num: duration,
  });

  const maxTravelersErrorMessage = formatMessage(
    content.errorTooltipMaxTraveler,
    {
      maxTraveler: MAX_GUESTS,
      maxChildren: MAX_NON_EMPLOYEE_CHILDREN,
    }
  );

  const nonEmployeeFields: NonEmployeeFields | undefined = useMemo(
    () =>
      canBookNonEmployee
        ? [
            {
              type: NonEmployeeType.ADULT,
              value: nonEmployeeTravelers.adults,
              onValidate(
                employees: Traveler[],
                nonEmployees: NonEmployeeFields,
                additionalInfo?: { value: number }
              ) {
                const employee = employees.length;
                const neAdult = additionalInfo?.value ?? nonEmployees[0].value;
                const neChild = nonEmployees[1].value.length;

                const isNoUpdate = neAdult >= nonEmployees[0].value;
                if (employee + neAdult > MAX_GUESTS) {
                  return {
                    error: maxTravelersErrorMessage,
                    noUpdate: isNoUpdate,
                  };
                }

                if (employee + neAdult > 0) {
                  return null;
                }

                return neChild > 0
                  ? {
                      error: content.nonEmployeeTooltipNoAdult,
                      noUpdate: false,
                    }
                  : null;
              },
            },
            {
              type: NonEmployeeType.CHILD_AGE,
              value: nonEmployeeTravelers.childrenAges,
              onValidate(
                employees: Traveler[],
                nonEmployees: NonEmployeeFields,
                additionalInfo?: { value: number }
              ) {
                const neChild =
                  additionalInfo?.value ?? nonEmployees[1].value.length;

                return neChild > MAX_NON_EMPLOYEE_CHILDREN
                  ? {
                      error: formatMessage(
                        content.nonEmployeeTooltipMaxChildren,
                        {
                          maxChildren: MAX_NON_EMPLOYEE_CHILDREN,
                        }
                      ),
                      noUpdate: true,
                    }
                  : null;
              },
            },
          ]
        : undefined,
    [canBookNonEmployee, nonEmployeeTravelers]
  );

  return (
    <>
      <SearchFormUI
        testID="hotel.form"
        traveler={guestText}
        travelerLabel={content.travelerLabel}
        room={room}
        roomLabel={content.roomLabel}
        numOfRoom={rooms}
        destination={autocomplete?.geoName || ''}
        destinationOptions={destinationOptions}
        destinationLoading={loadingAutocomplete}
        destinationLabel={content.destinationLabel}
        destinationPlaceholder={content.destinationPlaceholder}
        checkInDate={checkInDate}
        checkInLabel={content.checkInLabel}
        checkOutLabel={content.checkOutLabel}
        duration={numOfNight}
        durationOptions={durationOptions}
        durationLabel={content.durationLabel}
        numOfNight={duration}
        onTravelerFocus={() => setIsPickerVisible(true)}
        onRoomChange={handleRoomChange}
        onDestinationChange={handleDestinationChange}
        onDestinationChangeText={fetchAutocomplete}
        onCheckInDateChange={handleCheckInChange}
        onDurationChange={handleDurationChange}
        onSearchPress={() => fetchAccountStatus(handleSearchPress)}
        dateFormat="SHORT_WEEKDAY"
        maxDate={todayNextYear.current}
        guestError={guestError}
        guestErrorTooltip={content.errorTooltipNullGuest}
        roomError={roomError}
        roomErrorTooltip={roomErrorTooltip}
        destinationError={destinationError}
        destinationErrorTooltip={content.errorTooltipNullDestination}
        enableRecentSearch={recentSearchFC.enabled}
        destinationTypeMore={content.destinationTypeMore}
        popularDestinationText={content.popularDestinationText}
        destinationEmptyTitle={content.destinationEmptyTitle}
        destinationEmptyDescription={content.destinationEmptyDescription}
        recentlySearchCityText={
          hotelRecentlySearchContent.recentlySearchCityText
        }
        recentlyViewedHotelText={
          hotelRecentlySearchContent.recentlyViewedHotelText
        }
        recentlyViewedHotelWithKeywordText={
          hotelRecentlySearchContent.recentlyViewedHotelWithKeywordText
        }
        startFromText={hotelRecentlySearchContent.startFromText}
        recentlySearchCityOptions={recentSearchGeo}
        recentlySearchCityLoading={recentSearchDataGeoLoading}
        recentlyViewedHotelOptions={recentSearchProperty}
        recentlyViewedHotelLoading={recentSearchDataPropertyLoading}
        guestInfoTooltip={formatMessage(guestInfoTooltip, {
          maxGuest: MAX_GUESTS,
        })}
        searchLabel={content.searchButtonText}
        searchEnabled={canBook}
        isSearching={isAccountStatusFetching || isSearching}
        formDisabled={isSuspended}
      />
      {pickerDesign !== 'own' && (
        <RevampTravelerPickerModal
          isVisible={isPickerVisible}
          maximumTraveler={MAX_GUESTS}
          travelers={travelers}
          nonEmployeeFields={nonEmployeeFields}
          hasNonEmployee={Object.keys(nonEmployeeTravelers).some(key => {
            const k = key as keyof HotelNonEmployeeTraveler;
            if (k === 'childrenAges') {
              return (
                nonEmployeeTravelers[k].length !==
                defaultHotelNonEmployee[k].length
              );
            }
            return nonEmployeeTravelers[k] !== defaultHotelNonEmployee[k];
          })}
          onClose={() => setIsPickerVisible(false)}
          onSave={handleTravelerChange}
          tripRequestEnabled={tripRequestEnabled}
          complianceLabel={content.travelerComplianceLabel}
          nonEmployeeEnablementCheckbox={content.nonEmployeeEnablementCheckbox}
          nonEmployeeTooltip={content.nonEmployeeTooltip}
          nonEmployeeSubtitle={content.nonEmployeeSubtitle}
          emptySelectedTravelerText={content.emptySelectedTravelerText}
          selectedTravelerText={content.selectedTravelerText}
          emptyTotalTravelerText={content.emptyTotalTravelerText}
          totalTravelerText={content.totalTravelerText}
          maxTravelersErrorMessage={maxTravelersErrorMessage}
          subtitle={formatMessage(content.travelerSubtitleLabel, {
            maxGuest: MAX_GUESTS,
          })}
          title={content.travelerTitleLabel}
        />
      )}
    </>
  );
}

import { useEffect } from 'react';

import { useLocale } from '@traveloka/ctv-core';

export default function RedirectHome() {
  const { locale } = useLocale();

  useEffect(() => {
    window.location.href = '/' + locale;
  }, []);

  return null;
}

export const BATHROOM_AMENITIES = [
  'BATHROBES',
  'BATHTUB',
  'DISABILITY_FRIENDLY_BATHROOM',
  'HAIR_DRYER',
  'HAIR_DRYER_ON_REQUEST',
  'HEATED_WATER',
  'HYDROMASSAGE_SHOWERHEAD',
  'INDOOR_PRIVATE_BATH',
  'OUTDOOR_BATHROOM',
  'OUTDOOR_SHOWER',
  'PARTIALLY_OPEN_BATHROOM',
  'PRIVATE_BATHROOM',
  'PRIVATE_BATHROOM_NOT_IN_ROOM',
  'RAINFALL_SHOWERHEAD',
  'SEPARATE_SHOWER_AND_TUB',
  'SHARED_BATHROOM',
  'SHOWER',
  'SHOWER_OR_TUB',
  'SPA_TUB',
  'TOILET_WITH_ELECTRONIC_BIDET',
  'TOILETRIES',
  'TOWELS_PROVIDED',
];

export const ROOM_AMENITIES = [
  'AIR_CONDITIONING',
  'AIR_CONDITIONING_SURCHARGE',
  'BALCONY_TERRACE',
  'BLACKOUT_DRAPES_CURTAINS',
  'CLOTHES_DRYER',
  'COFFEE_TEA_MAKER',
  'COMPLIMENTARY_BOTTLED_WATER',
  'CRIBS_SURCHARGE',
  'DESK',
  'DISHWASHER',
  'DRYER',
  'DVD_PLAYER',
  'ELECTRIC_KETTLE',
  'EXTRA_BEDS',
  'EXTRA_BEDS_AVAILABLE',
  'FAN',
  'FREE_CRIBS',
  'FREE_NEWSPAPER',
  'FUTON',
  'FUTON_SURCHARGE',
  'HEATING',
  'IN_HOUSE_MOVIES',
  'IN_ROOM_CLIMATE_CONTROL',
  'IN_ROOM_SAFE',
  'IN_ROOM_SAFE_SURCHARGE',
  'IN_ROOM_VIDEO_GAMES',
  'IRONING_BOARD_ON_REQUEST',
  'IRONING_FACILITIES',
  'KITCHEN',
  'LINENS_PROVIDED',
  'MICROWAVE',
  'MICROWAVE_ON_REQUEST',
  'MINIBAR',
  'OVEN',
  'PRIVATE_POOL',
  'REFRIGERATOR',
  'REFRIGERATOR_ON_REQUEST',
  'RICE_COOKER',
  'ROLLAWAY_OR_EXTRA_BEDS',
  'ROLLAWAY_OR_EXTRA_BEDS_SURCHARGE',
  'SEPARATE_DINING_AREA',
  'SLIPPERS',
  'SOFA_BED',
  'SOFA_BED_SIZE',
  'SOUNDPROOFED_ROOMS',
  'STOVETOP',
  'TELEVISION',
  'TOASTER',
  'TOWELS_OR_SHEETS_SURCHARGE',
  'WASHER_OR_DRYER',
  'WASHING_MACHINE',
];

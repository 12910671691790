export type Item = {
  label: string;
  subLabel?: string;
  badgeText: string;
  subBadgeText?: string;
  value: string;
};

enum AutocompleteType {
  RECENT_SEARCH_PROPERTY = 'RECENT_SEARCH_PROPERTY',
  RECENT_SEARCH_CITY = 'RECENT_SEARCH_CITY',
  POLULAR_DESTINATION = 'POLULAR_DESTINATION',
}

export default AutocompleteType;

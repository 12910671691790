import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useTheme, Token } from '@traveloka/web-components';
import { Spacing } from '@traveloka/web-components/src/momentum/Token';

type Margin =
  | 'none'
  | 'all'
  | 'vertical'
  | 'horizontal'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right';

type MarginKey =
  | 'margin'
  | 'marginVertical'
  | 'marginHorizontal'
  | 'marginTop'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginRight';

const marginMap: Partial<Record<Margin, MarginKey>> = {
  all: 'margin',
  vertical: 'marginVertical',
  horizontal: 'marginHorizontal',
  top: 'marginTop',
  bottom: 'marginBottom',
  left: 'marginLeft',
  right: 'marginRight',
};

type Props = {
  margin?: Margin;
  spacing?: Spacing;
  style?: StyleProp<ViewStyle>;
  subtle?: boolean;
};

export default function Divider(props: Props) {
  const { margin = 'vertical', spacing = 'm', style, subtle } = props;
  const { color } = useTheme();

  const dividerStyle: StyleProp<ViewStyle> = {
    backgroundColor: subtle ? color.borderSubtle : color.borderDivide,
  };

  const size = Token.spacing[spacing];
  const marginKey = marginMap[margin];

  if (marginKey) {
    dividerStyle[marginKey] = size;
  }

  return <View style={[Style.divider, dividerStyle, style]} />;
}

const Style = StyleSheet.create({
  divider: {
    alignSelf: 'stretch',
    flexBasis: Token.border.width.thick,
  },
});

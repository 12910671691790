import React from 'react';
import { View, ViewProps } from 'react-native';

import { useTheme, Checkbox, Text } from '@traveloka/web-components';

import {
  useFlightSearchArrivalTimeFilterContext,
  useFlightSearchDepartureTimeFilterContext,
  useFlightSearchOptionContext,
} from 'flight/search/contexts/FlightSortFilterContext';

import { getThemeStyle, CommonStyle } from '../filter-search-toolbar.style';

type ContextType = ReturnType<typeof useFlightSearchOptionContext>;
type FilterOption = ContextType['filterOption'];
type SetFilter = ContextType['setFilter'];

type Props = {
  style?: ViewProps['style'];
  filter: 'departureTime' | 'arrivalTime';
  values:
    | ReturnType<typeof useFlightSearchDepartureTimeFilterContext>
    | ReturnType<typeof useFlightSearchArrivalTimeFilterContext>;
  options: FilterOption['departureTime'] | FilterOption['arrivalTime'];
  onChange: SetFilter;
  title: string;
};

function DepartureArrivalTimeFilter(props: Props) {
  const { style, title, filter, values, options, onChange } = props;
  const ThemeStyle = getThemeStyle(useTheme());

  const content = {
    midnight: '00.00 - 06.00',
    morning: '06.00 - 12.00',
    afternoon: '12.00 - 18.00',
    evening: '18.00 - 00.00',
  };

  return (
    <View style={style}>
      <Text ink="primary" variant="ui-small">
        {title}
      </Text>
      <View style={[CommonStyle.container, ThemeStyle.container]}>
        {options.map(option => (
          <View key={option.value} style={CommonStyle.checkbox}>
            <Checkbox
              checked={values.includes(option.value)}
              disabled={option.disabled}
              onChange={() => onChange(filter, option.value)}
            >
              <Checkbox.Control />
              <Text
                ink={option.disabled ? 'muted' : 'primary'}
                variant="ui-small"
                style={CommonStyle.checkboxText}
              >
                {content[option.value]}
              </Text>
            </Checkbox>
          </View>
        ))}
      </View>
    </View>
  );
}

export default DepartureArrivalTimeFilter;

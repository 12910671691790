import React from 'react';
import { View, ViewProps } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { useTheme, Checkbox, Text } from '@traveloka/web-components';

import {
  useFlightSearchOptionContext,
  useFlightSearchTransitCountFilterContext,
} from 'flight/search/contexts/FlightSortFilterContext';
import { formatMessage } from 'shared/utils/intl';

import { getThemeStyle, CommonStyle } from '../filter-search-toolbar.style';

type Props = {
  style?: ViewProps['style'];
};

function TransitCountFilter(props: Props) {
  const { style } = props;
  const { CorporateFlightSearchToolbar } = useContentResource();
  const content = {
    title: CorporateFlightSearchToolbar.transitCount,
    direct: CorporateFlightSearchToolbar.transitDirect,
    one: formatMessage(CorporateFlightSearchToolbar.transitPluralPlus, {
      num: 1,
    }),
    moreThanOne: formatMessage(CorporateFlightSearchToolbar.transitPluralPlus, {
      num: 2,
      val: '2+',
    }),
  };

  const ThemeStyle = getThemeStyle(useTheme());
  const { filterOption, setFilter } = useFlightSearchOptionContext();
  const values = useFlightSearchTransitCountFilterContext();

  return (
    <View style={style}>
      <Text ink="primary" variant="ui-small">
        {content.title}
      </Text>
      <View style={[CommonStyle.container, ThemeStyle.container]}>
        {filterOption.transitCount.map(option => (
          <View key={option.value} style={CommonStyle.checkbox}>
            <Checkbox
              checked={values.includes(option.value)}
              disabled={option.disabled}
              onChange={() => setFilter('transitCount', option.value)}
            >
              <Checkbox.Control />
              <Text
                ink={option.disabled ? 'muted' : 'primary'}
                variant="ui-small"
                style={CommonStyle.checkboxText}
              >
                {content[option.value]}
              </Text>
            </Checkbox>
          </View>
        ))}
      </View>
    </View>
  );
}

export default TransitCountFilter;

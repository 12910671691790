import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { useTheme, Card, Text, Token } from '@traveloka/web-components';
import Label from '@traveloka/web-components/src/momentum/Input/Label';

import { useProfileContext } from 'flight/prebook/contexts/FlightPrebookContext';
import { Style as SharedStyle } from 'flight/prebook/flight-prebook.style';
import PhoneField, {
  Value as PhoneFieldValue,
} from 'shared/components/form/PhoneField/PhoneField';
import { formatMessage } from 'shared/utils/intl';
import {
  isPhone,
  maxLength,
  minLength,
  required,
} from 'shared/utils/validator';

export default function ContactForm() {
  const profile = useProfileContext();
  const { color } = useTheme();

  const content = useContentResource().CorporateFlightPrebookContactForm;

  const initialValue = useMemo(() => {
    const { phone, phoneCountryCode } = profile;

    let phoneValue: PhoneFieldValue | undefined = undefined;
    if (phone && phoneCountryCode) {
      phoneValue = {
        phone,
        prefix: phoneCountryCode,
      };
    }

    return { phoneValue };
  }, [profile]);

  const inputStyle = {
    borderColor: color.lightSecondary,
  };

  return (
    <View style={[SharedStyle.section, { zIndex: 3 }]}>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {content.title}
      </Text>
      <Card style={SharedStyle.card}>
        <View style={SharedStyle.group}>
          <Label text={content.nameField} />
          <Text ink="secondary" style={[Style.inputLike, inputStyle]}>
            {profile.fullname}
          </Text>
        </View>
        <View style={SharedStyle.row}>
          <View style={SharedStyle.col}>
            <PhoneField
              testIDPrefix="pre-book.form.contact-detail"
              name="contactDetail.customerPhone"
              label={content.phoneField}
              leftHelper={content.phoneFieldDescription}
              defaultValue={initialValue.phoneValue}
              validate={value => {
                if (
                  value === undefined ||
                  required(value.phone) === false ||
                  required(value.prefix) === false
                ) {
                  return content.phoneRequiredErrorMessage;
                } else if (minLength(value.phone, 7) === false) {
                  return formatMessage(content.phoneMinLengthErrorMessage, {
                    length: 7,
                  });
                } else if (maxLength(value.phone, 15) === false) {
                  return formatMessage(content.phoneMaxLengthErrorMessage, {
                    length: 15,
                  });
                } else if (isPhone(value.phone) === false) {
                  return content.phoneNumericOnlyErrorMessage;
                }

                return;
              }}
            />
          </View>
          <View style={SharedStyle.col}>
            <Label text={content.emailField} />
            <Text ink="secondary" style={[Style.inputLike, inputStyle]}>
              {profile.email}
            </Text>
          </View>
        </View>
      </Card>
    </View>
  );
}

const Style = StyleSheet.create({
  inputLike: {
    borderWidth: Token.border.width.thick,
    borderRadius: Token.border.radius.normal,
    padding: Token.spacing.xs - 1,
  },
});

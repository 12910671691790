import React, { ComponentType, useEffect, useState } from 'react';

import {
  Route,
  RouteComponentProps,
  RouteProps,
  useParams,
} from 'react-router-dom';

import { AuthLoading, useAuth, useLocale } from '@traveloka/ctv-core';
import { User } from '@traveloka/ctv-core/auth/types';
import {
  FeatureControl,
  useGlobalFeatureControl,
} from '@traveloka/ctv-core/feature-control';

import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

type Props = {
  component: PrivatePageComponent<any>;
} & OmitTyped<RouteProps, 'component'>;

type RouteProtectionOptions = {
  user: User;
  featureControl: FeatureControl;
  params: Record<string, string>;
};

export type PrivatePageComponent<P = {}> = ComponentType<P> & {
  routeProtection?(opts: RouteProtectionOptions): { path: string } | undefined;
};

export default function PrivateRoute(props: Props) {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => (
        <Wrapper {...props} component={Component} />
      )}
    />
  );
}

function Wrapper(props: Props) {
  const { component: Component } = props;
  const { isAuthenticated, isLoading, user } = useAuth();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const history = useLocalizedHistory();
  const { locale } = useLocale();
  const featureControl = useGlobalFeatureControl();
  const params = useParams();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      window.location.href = '/' + locale;
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (typeof Component.routeProtection === 'function' && user) {
      const result = Component.routeProtection({
        user,
        featureControl,
        params,
      });

      if (result) {
        history.replace(result.path);
        return;
      }
    }

    setIsAuthorized(true);
  }, []);

  return isAuthenticated && isAuthorized ? (
    <Component {...props} />
  ) : (
    <AuthLoading />
  );
}

import { Permission } from '@traveloka/ctv-core';

import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const CompanyExpenseReport: PrivatePageComponent = function() {
  return null;
};

CompanyExpenseReport.routeProtection = function({ user }) {
  const hasPermission = user.has(Permission.EXPENSE_REPORT_VIEW);

  if (!hasPermission) {
    return { path: '/' };
  }

  return { path: '/financial/expense-report' };
};

export default CompanyExpenseReport;

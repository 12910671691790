import React, {
  useCallback,
  AnchorHTMLAttributes,
  ComponentProps,
} from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Anchor } from '@traveloka/web-components/future';

import LinkAnchor from './LinkAnchor';

type AnchorProps = ComponentProps<typeof Anchor>;

type Props = AnchorProps & {
  to: string;
  replace?: boolean;
};

export default function Link(props: Props) {
  const { to, replace, ...rest } = props;

  if (isSameHostname(to)) {
    return (
      <RouterLink
        to={to}
        replace={replace}
        component={LinkAnchor}
        {...(rest as AnchorHTMLAttributes<HTMLAnchorElement>)}
      />
    );
  }

  const handleNavigate = useCallback(() => {
    window.location.href = to;
  }, [to]);

  return <LinkAnchor href={to} navigate={handleNavigate} {...rest} />;
}

// =====
function isSameHostname(url: string) {
  const parser = document.createElement('a');
  parser.href = url;

  return window.location.host === parser.host;
}

import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';

import {
  useLocalizedDateFormat,
  useContentResource,
} from '@traveloka/ctv-core';
import { Fade } from '@traveloka/ctvweb-ui';
import { FlightSearchHeaderInfo } from '@traveloka/ctvweb-ui/flight';
import { Card, Token } from '@traveloka/web-components';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import { useProductRequest } from 'approval-system/shared/contexts/ProductRequestContext';
import FlightSearchForm from 'flight/search/components/FlightSearchForm/FlightSearchForm';
import { useSearchSpec } from 'flight/search/contexts/SearchSpecContext';
import { useStaticData } from 'flight/search/contexts/StaticDataContext';
import { guessAirportName } from 'flight/search/utils/flight-static-data-util';
import { formatMessage } from 'shared/utils/intl';

import {
  ONE_WAY_SEPARATOR,
  ROUND_TRIP_SEPARATOR,
} from '../../constants/flight-search-constant';

type Props = {
  onSearch: () => void;
  isLoading: boolean;
};

function FlightSearchHeader(props: Props) {
  const { onSearch, isLoading } = props;
  const {
    CorporateFlightGeneral,
    CorporateFlightSearch,
    CorporateFlightSeatClass,
  } = useContentResource();
  const { format } = useLocalizedDateFormat();
  const searchSpec = useSearchSpec();
  const {
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();

  const content = {
    pax: formatMessage(CorporateFlightGeneral.pax, {
      num:
        searchSpec.passengers.length +
        searchSpec.nonEmployeeTravelers.adults +
        searchSpec.nonEmployeeTravelers.children +
        searchSpec.nonEmployeeTravelers.infants,
    }),
    button: CorporateFlightSearch.changeSearch,
    seatClass: CorporateFlightSeatClass[searchSpec.seatClass],
    allAirports: CorporateFlightSearch.allAirports,
  };

  const productRequestDetail = useProductRequest();
  const staticData = useStaticData();
  const [isFormExpanded, setFormExpanded] = useState(false);

  const handleToggleForm = useCallback(() => {
    setFormExpanded(!isFormExpanded);
  }, [isFormExpanded]);

  const headerTitle = [
    guessAirportName(
      searchSpec.airport.origin,
      staticData,
      content.allAirports
    ),
    guessAirportName(
      searchSpec.airport.destination,
      staticData,
      content.allAirports
    ),
  ].join(searchSpec.isRoundTrip ? ROUND_TRIP_SEPARATOR : ONE_WAY_SEPARATOR);
  const headerSubtitle = [
    format(searchSpec.date.depart, 'FULL_WEEKDAY_MONTH'),
    searchSpec.isRoundTrip &&
      format(searchSpec.date.return, 'FULL_WEEKDAY_MONTH'),
  ]
    .filter(Boolean)
    .join(' - ');

  function handleSearch() {
    setFormExpanded(false);
    onSearch();
  }

  return (
    <>
      <FlightSearchHeaderInfo
        testID={'flight.search.header'}
        title={headerTitle}
        subtitle={headerSubtitle}
        pax={content.pax}
        seatClass={content.seatClass}
        onPress={() => fetchAccountStatus(handleToggleForm)}
        button={content.button}
        style={Style.header}
        loading={isAccountStatusFetching}
      />

      <Fade visible={isFormExpanded} style={Style.form}>
        <Card elevation="raised" style={Style.card}>
          <FlightSearchForm
            onSearch={() => fetchAccountStatus(handleSearch)}
            isSearching={isAccountStatusFetching || isLoading}
            tripRequestEnabled={
              productRequestDetail.enabled && !!productRequestDetail.data
            }
          />
        </Card>
      </Fade>
    </>
  );
}

const Style = StyleSheet.create({
  header: {
    marginBottom: Token.spacing.ml,
  },
  form: {
    zIndex: 2,
  },
  card: {
    marginBottom: Token.spacing.ml,
    overflow: 'visible',
  },
});

export default FlightSearchHeader;

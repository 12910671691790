import { content, image, slider } from './payload';
import { ContentResult, ImageResult, SliderResult } from './types';

// Content Resource
export const CONTENT_RESOURCE_API = '/v2/content/resource/content';
export type ContentResourceRequest = {
  contentResources: Record<string, string[]>;
};
export type ContentResourceResponse = ContentResult<typeof content>;

// Image Resource
export const IMAGE_RESOURCE_API = '/v2/content/resource/image';
export type ImageResourceRequest = {
  imageResources: Record<string, string[]>;
};
export type ImageResourceResponse = ImageResult<typeof image>;

// Slider Resource
export const IMAGE_SLIDER_API = '/v2/content/resource/imageslider';
export type ImageSliderRequest = {
  imageSliderNames: string[];
};
export type SliderResourceResponse = SliderResult<typeof slider>;

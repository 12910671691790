import React from 'react';
import { Redirect } from 'react-router-dom';

import ProductType from 'approval-system/shared/constants/ProductType';
import { ProductRequestProvider } from 'approval-system/shared/contexts/ProductRequestContext';
import RedirectHome from 'generic/RedirectHome';
import { PriceDisplayProvider } from 'hotel/search/contexts/PriceDisplayContext';
import { parseSearchSpec } from 'hotel/search/utils/SearchQueryUtil';
import {
  SearchSpecProvider,
  useSearchSpec,
} from 'hotel/shared/contexts/SpecContext';
import { Footer, Header, Page } from 'shared/components/Layout';
import CovidBanner from 'shared/components/CovidBanner';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

import HotelDetailContent from './components/HotelDetailContent/HotelDetailContent';
import { PropertyProvider } from './contexts/PropertyContext';

export default function HotelDetail() {
  const { type } = parseSearchSpec(window.location.search);
  const history = useLocalizedHistory();

  if (typeof type === 'undefined') {
    return <RedirectHome />;
  } else if (type !== 'HOTEL') {
    return (
      <Redirect
        to={history.createHref({
          pathname: '/hotel/search',
          search: window.location.search,
        })}
      />
    );
  }

  return (
    <SearchSpecProvider>
      <Content />
    </SearchSpecProvider>
  );
}

function Content() {
  const { tripRequestId, travelers } = useSearchSpec();

  return (
    <ProductRequestProvider
      tripRequestId={tripRequestId}
      type={ProductType.HOTEL}
      mainBooker={travelers[0]}
    >
      <Page>
        <Header additionalInfoHeader={<CovidBanner />} />
        <PriceDisplayProvider>
          <PropertyProvider>
            <HotelDetailContent />
          </PropertyProvider>
        </PriceDisplayProvider>
        <Footer />
      </Page>
    </ProductRequestProvider>
  );
}

import React from 'react';

import { StyleSheet, View } from 'react-native';
import { Button, Icon, Token } from '@traveloka/web-components';
import { Props as ButtonProps } from '@traveloka/web-components/src/momentum/Button/Button';

import IcSystemPlayMediaFill from '@traveloka/icon-kit-web/svg/light/ic_system_play_media-fill_24px.svg';

type Props = {
  text: string;
  style?: ButtonProps['style'];
  onPress(): void;
};

export default function GenericHomeWatchDemoButton(props: Props) {
  const { text, style, onPress } = props;
  const iconPlayer = (
    <View style={Style.iconPlayer}>
      <Icon src={IcSystemPlayMediaFill} width={8} height={8} />
    </View>
  );

  return (
    <Button
      variant="secondary"
      text={text}
      iconEnd={() => iconPlayer}
      style={style}
      onPress={onPress}
    />
  );
}

const Style = StyleSheet.create({
  iconPlayer: {
    width: 16,
    height: 16,
    borderRadius: 12,
    backgroundColor: Token.color.uiBluePrimary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useAuth, useContentResource, Permission } from '@traveloka/ctv-core';
import {
  Button,
  LoadingIndicator,
  Text,
  Token,
} from '@traveloka/web-components';

import { usePaymentState } from 'payment/select/contexts/PaymentContext';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

export default function CreditCard() {
  const { creditCard } = usePaymentState();

  const { tripId } = usePaymentState().booking;
  const user = useAuth().user!;
  const cr = useContentResource().CorporateProductPayment;
  const history = useLocalizedHistory();

  if (creditCard.isFetching) {
    return (
      <View style={Style.loading}>
        <LoadingIndicator />
      </View>
    );
  }

  if (!creditCard.registered) {
    if (user.has(Permission.BILLING_MODIFY)) {
      return (
        <NoCardMessage
          button={cr.manageCard}
          message={cr.noCardActionAdmin}
          onPress={() => history.push('/company/manage/cards')}
        />
      );
    } else {
      return (
        <NoCardMessage
          button={cr.seePurchaseDetail}
          message={cr.noCardActionUser}
          onPress={() => history.push(`/company/purchase/${tripId}`)}
        />
      );
    }
  }

  return (
    <View style={Style.content}>
      <View style={Style.header}>
        <Text ink="white">{cr.payWithCreditCard}</Text>
      </View>
    </View>
  );
}

type NoCardMessageProps = {
  button: string;
  message: string;
  onPress(): void;
};

function NoCardMessage(props: NoCardMessageProps) {
  const { button, message, onPress } = props;
  const cr = useContentResource().CorporateProductPayment;

  return (
    <View style={Style.content}>
      <Text style={Style.messageTitle}>{cr.noCardAvailable}</Text>
      <Text ink="secondary" variant="ui-tiny" style={Style.message}>
        {cr.noCardAvailableHelp}
      </Text>
      <Button
        onPress={onPress}
        size="small"
        style={Style.button}
        text={button}
      />
      <Text ink="secondary" style={Style.action} variant="ui-tiny">
        {message}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  header: {
    padding: Token.spacing.m,
    backgroundColor: Token.color.brandBusinessSuit,
    borderRadius: Token.border.radius.normal,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  content: {
    padding: Token.spacing.l,
  },
  messageTitle: {
    marginBottom: Token.spacing.m,
  },
  message: {
    marginBottom: Token.spacing.l,
  },
  button: {
    marginBottom: Token.spacing.l,
    alignSelf: 'flex-start',
  },
  action: {
    backgroundColor: Token.color.uiBlueLight,
    borderRadius: Token.border.radius.normal,
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.m,
  },
});

import { Permission } from '@traveloka/ctv-core';

import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const CompanyStructure: PrivatePageComponent = function() {
  return null;
};

CompanyStructure.routeProtection = function({ user }) {
  // Should sync with `company/shared/component/LeftSidebar/LeftSidebar` file
  const hasPermission = user.has(
    Permission.DIVISION_READ,
    Permission.DIVISION_CREATE,
    Permission.DIVISION_DELETE,
    Permission.DIVISION_UPDATE,
    Permission.TIER_READ,
    Permission.TIER_CREATE,
    Permission.TIER_DELETE,
    Permission.TIER_UPDATE,
    Permission.USER_ROLE_READ,
    Permission.USER_ROLE_CREATE,
    Permission.USER_ROLE_DELETE,
    Permission.USER_ROLE_UPDATE,
    Permission.POLICY_READ,
    Permission.POLICY_CREATE,
    Permission.POLICY_DELETE,
    Permission.POLICY_UPDATE,
    Permission.APPROVAL_CONFIG_VIEW,
    Permission.APPROVAL_CONFIG_MODIFY,
    Permission.BILLING_READ,
    Permission.BILLING_MODIFY
  );

  if (!hasPermission) {
    return { path: '/' };
  }

  const hasCompanyInfoPermission = user.has(
    Permission.BILLING_READ,
    Permission.BILLING_MODIFY
  );

  if (hasCompanyInfoPermission) {
    return { path: '/company/info' };
  }

  const hasCompanyPermission = user.has(
    Permission.USER_ROLE_READ,
    Permission.USER_ROLE_CREATE,
    Permission.USER_ROLE_DELETE,
    Permission.USER_ROLE_UPDATE,
    Permission.POLICY_READ,
    Permission.POLICY_CREATE,
    Permission.POLICY_DELETE,
    Permission.POLICY_UPDATE,
    Permission.APPROVAL_CONFIG_VIEW,
    Permission.APPROVAL_CONFIG_MODIFY
  );

  if (hasCompanyPermission) {
    return { path: '/company/permissions' };
  }

  const hasPeopleStructurePermission = user.has(
    Permission.DIVISION_READ,
    Permission.DIVISION_CREATE,
    Permission.DIVISION_DELETE,
    Permission.DIVISION_UPDATE,
    Permission.TIER_READ,
    Permission.TIER_CREATE,
    Permission.TIER_DELETE,
    Permission.TIER_UPDATE
  );

  if (hasPeopleStructurePermission) {
    return { path: '/company/people-structure' };
  }

  return;
};

export default CompanyStructure;

import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource, useLocale } from '@traveloka/ctv-core';
import GirlSad from '@traveloka/ctvweb-ui/img/popup-girl-sad.svg';
import { Button, Image, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import {
  CorporateProvider,
  useCorporate,
} from 'company/structure/contexts/CorporateContext';
import { formatMessage } from 'shared/utils/intl';

export type AccountSuspendedPopupProps = {
  showCloseButton?: boolean;
  backToHome?: boolean;
};

function AccountSuspendedPopup(props: AccountSuspendedPopupProps) {
  const { showCloseButton = false, backToHome = false } = props;

  const content = useContentResource().CorporateAccountStatus;
  const { isSuspended, hasInvoicePermission } = useAccountStatus();
  const [corporate] = useCorporate();
  const { locale } = useLocale();

  const [isVisible, setIsVisible] = useState(false);

  const regularUserMessage = formatMessage(content.popupMessage, {
    picName: corporate?.value?.picName! || '',
  });

  useEffect(() => {
    if (isSuspended) {
      setIsVisible(true);
    }
  }, [isSuspended]);

  const handleClosePopup = () => setIsVisible(false);

  const renderAction = () => {
    if (hasInvoicePermission) {
      return (
        <Button
          text={content.popupCtaInvoice}
          size={'small'}
          style={Style.button}
          onPress={() => (window.location.href = `/${locale}/company/invoice`)}
        />
      );
    }

    if (backToHome) {
      return (
        <Button
          text={content.popupCtaHome}
          size={'small'}
          style={Style.button}
          onPress={() => (window.location.href = `/${locale}/`)}
        />
      );
    }

    return (
      <Button
        text={content.popupCta}
        size={'small'}
        style={Style.button}
        onPress={handleClosePopup}
      />
    );
  };

  return (
    <Modal isVisible={isVisible}>
      <Popup
        showCloseButton={showCloseButton}
        width={500}
        height={240}
        onCloseButtonPress={handleClosePopup}
      >
        <View style={Style.container}>
          <Image src={GirlSad} width={80} height={94} />
          <View style={Style.textContainer}>
            <View>
              <Text variant="title-2" style={[Style.marginBottom]}>
                {hasInvoicePermission
                  ? content.popupTitleInvoice
                  : content.popupTitle}
              </Text>
              <Text variant="ui-small" style={[Style.marginBottom]}>
                {hasInvoicePermission
                  ? content.popupMessageInvoice
                  : regularUserMessage}
              </Text>
            </View>
            {renderAction()}
          </View>
        </View>
      </Popup>
    </Modal>
  );
}

export default function AccountSuspendedPopupContainer(
  props: AccountSuspendedPopupProps
) {
  return (
    <CorporateProvider>
      <AccountSuspendedPopup {...props} />
    </CorporateProvider>
  );
}

const Style = StyleSheet.create({
  container: { flexDirection: 'row', marginTop: -28 },
  textContainer: {
    flexDirection: 'column',
    width: 350,
    height: 200,
    marginLeft: Token.spacing.m,
    justifyContent: 'space-between',
  },
  marginBottom: {
    marginBottom: Token.spacing.s,
  },
  button: { width: 'fit-content', marginLeft: 'auto', marginTop: 'auto' },
});

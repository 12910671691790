import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const CreateApproval: PrivatePageComponent = function() {
  return null;
};

CreateApproval.routeProtection = function({ user }) {
  if (!user.globalTripRequestApproval) {
    return { path: '/' };
  }

  return { path: '/approval/create' };
};

export default CreateApproval;

import React from 'react';
import { FlightSegmentData } from '../types';
import { View, StyleSheet } from 'react-native';
import { useTheme, Token, Text } from '@traveloka/web-components';
import FlightFacility from '../FlightFacility/FlightFacility';

type Props = {
  segments: FlightSegmentData[];
};

export default function FlightSingleSummaryTooltipContent(props: Props) {
  const { segments } = props;
  const lastIndex = segments.length - 1;

  return (
    <View style={Style.container}>
      {segments.map((segment, index) => (
        <View
          key={index}
          style={[Style.journeyRow, index !== lastIndex && Style.journey]}
        >
          <SegmentInfo segment={segment} />
          <FacilityInfo segment={segment} />
        </View>
      ))}
    </View>
  );
}

type CommonProps = {
  segment: FlightSegmentData;
};

function SegmentInfo(props: CommonProps) {
  const {
    departureTime,
    departureDate,
    departureLocation,
    departureAirportName,
    arrivalTime,
    arrivalDate,
    arrivalLocation,
    arrivalAirportName,
  } = props.segment;
  const { color } = useTheme();

  const circleStartStyle = {
    borderColor: color.lightPrimary,
  };
  const circleEndStyle = {
    backgroundColor: color.lightPrimary,
    borderColor: color.lightPrimary,
  };
  const lineStyle = { backgroundColor: color.darkSecondary };
  const primaryTextStyle = { color: color.lightPrimary };
  const secondaryTextStyle = { color: color.lightSecondary };

  return (
    <View style={Style.segment}>
      <View style={Style.segmentRow}>
        <View style={Style.segmentLineCol}>
          <View style={[Style.segmentCircle, circleStartStyle]} />
          <View style={[Style.segmentLine, lineStyle]} />
        </View>
        <View style={Style.segmentDateTime}>
          <Text variant="ui-small" style={primaryTextStyle}>
            {departureTime}
          </Text>
          <Text variant="ui-small" style={secondaryTextStyle}>
            {departureDate}
          </Text>
        </View>
        <View style={Style.segmentLocation}>
          <Text variant="ui-small" style={primaryTextStyle}>
            {departureLocation}
          </Text>
          <Text variant="ui-small" style={secondaryTextStyle}>
            {departureAirportName}
          </Text>
        </View>
      </View>
      <View style={[Style.segmentLineCol, Style.segmentLineColSpacing]}>
        <View style={[Style.segmentLine, lineStyle]} />
      </View>
      <View style={Style.segmentRow}>
        <View style={Style.segmentLineCol}>
          <View style={[Style.segmentCircle, circleEndStyle]} />
        </View>
        <View style={Style.segmentDateTime}>
          <Text variant="ui-small" style={primaryTextStyle}>
            {arrivalTime}
          </Text>
          <Text variant="ui-small" style={secondaryTextStyle}>
            {arrivalDate}
          </Text>
        </View>
        <View style={Style.segmentLocation}>
          <Text variant="ui-small" style={primaryTextStyle}>
            {arrivalLocation}
          </Text>
          <Text variant="ui-small" style={secondaryTextStyle}>
            {arrivalAirportName}
          </Text>
        </View>
      </View>
    </View>
  );
}

function FacilityInfo(props: CommonProps) {
  const { segment } = props;
  const { color } = useTheme();
  const primaryTextStyle = { color: color.lightPrimary };

  return (
    <View style={Style.facilityInfo}>
      <Text variant="ui-small" style={[Style.flightNo, primaryTextStyle]}>
        {segment.flightName}
      </Text>
      {segment.facilities.map((facility, index) => (
        <FlightFacility
          key={index}
          facility={facility}
          style={Style.facility}
          useLightIcon
        />
      ))}
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    width: 500,
  },
  journeyRow: {
    flexDirection: 'row',
  },
  journey: {
    marginBottom: Token.spacing.m,
  },

  // Segment Info
  segment: {
    flex: 1,
    marginRight: Token.spacing.m,
  },
  segmentRow: {
    flexDirection: 'row',
  },
  segmentLineCol: {
    alignItems: 'center',
    width: Token.spacing.xs,
    marginRight: Token.spacing.xs,
  },
  segmentLineColSpacing: {
    height: Token.spacing.s,
  },
  segmentCircle: {
    width: Token.spacing.xs,
    height: Token.spacing.xs,
    borderWidth: Token.border.width.thick,
    borderRadius: Token.border.radius.rounded,
    marginVertical: 6,
  },
  segmentLine: {
    flex: 1,
    width: Token.border.width.thick,
  },
  segmentDateTime: {
    width: 96,
  },
  segmentLocation: {
    flex: 1,
  },

  // Facility Info
  flightNo: {
    marginBottom: Token.spacing.xs,
  },
  facilityInfo: {
    width: 165,
  },
  facility: {
    marginBottom: Token.spacing.xs,
  },
});

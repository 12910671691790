import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const CompanyApprover: PrivatePageComponent = function() {
  return null;
};

CompanyApprover.routeProtection = function() {
  return { path: '/company/permissions/approver' };
};

export default CompanyApprover;

import React, { useState, useEffect } from 'react';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Input } from '@traveloka/ctvweb-ui';

import { useFlightRequestRefundFormAction } from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import { RefundDocument } from 'refund/shared/api/types';
import { validatePnrCode } from 'refund/shared/utils/request-refund-validation';

type Props = {
  value?: string;
  travelerId: string;
  document: RefundDocument.DOUBLE_BOOKING_PNR;
  isIdenticalTrip: boolean;
};

export default function RefundInput(props: Props) {
  const { document, isIdenticalTrip, travelerId, value } = props;

  const [errorMessage, setErrorMessage] = useState<string>();
  const { addIdenticalTrip } = useFlightRequestRefundFormAction();

  const content = useContentResource().CorporateFlightRequestRefundDocument;

  useEffect(() => {
    if (!isIdenticalTrip) {
      setErrorMessage(content.identicalTripErrorMessage);
    } else if (validatePnrCode(value)) {
      setErrorMessage(content.pnrCodeErrorMessage);
    } else {
      setErrorMessage(undefined);
    }
  });

  return (
    <Input
      value={value}
      onChangeText={value =>
        addIdenticalTrip(travelerId, document, value.toUpperCase())
      }
      error={errorMessage}
    />
  );
}

import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Checkbox, Text, Token } from '@traveloka/web-components';

type Props = {
  isFirstSegment?: boolean;
  checked?: boolean;
  changeCheckbox?(): void;
  disabledCheckbox?: boolean;
  flightRoute: string;
  flightDatetime: string;
  airlineInfo: string;
};

export default function FlightSegment(props: Props) {
  const {
    airlineInfo,
    checked = false,
    disabledCheckbox = false,
    flightDatetime,
    flightRoute,
    isFirstSegment,
    changeCheckbox,
  } = props;

  const ink = disabledCheckbox ? 'secondary' : undefined;

  return (
    <View style={Style.container}>
      {changeCheckbox && (
        <View style={Style.checkbox}>
          {isFirstSegment && (
            <Checkbox
              checked={checked}
              disabled={disabledCheckbox}
              onChange={changeCheckbox}
            />
          )}
        </View>
      )}
      <View style={Style.content}>
        <Text ink={ink}>{flightRoute}</Text>
        <View style={Style.detail}>
          <Text style={Style.detailBox} variant="ui-small" ink="secondary">
            {flightDatetime}
          </Text>
          <Text style={Style.detailBox} variant="ui-small" ink="secondary">
            {airlineInfo}
          </Text>
        </View>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    padding: Token.spacing.m,
    flexDirection: 'row',
  },
  checkbox: {
    width: 24,
    height: 24,
    marginRight: Token.spacing.ml,
  },
  content: {
    flex: 1,
  },
  detail: {
    flexDirection: 'row',
    marginTop: Token.spacing.xxs,
  },
  detailBox: {
    flex: 1,
  },
  fullname: {
    marginRight: Token.spacing.m,
  },
});

import React from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Button, Text, Token } from '@traveloka/web-components';

import PopupGirl from '../PopupGirl/PopupGirl';

type Props = {
  isVisible: boolean;
  onPress(): void;
  text: string;
};

export default function GeneralErrorModal(props: Props) {
  const { isVisible, onPress, text } = props;

  const content = useContentResource().CorporateHotelPrebookGeneralErrorModal;

  return (
    <PopupGirl isVisible={isVisible} title={content.title}>
      <Text ink="secondary" style={Style.text}>
        {text}
      </Text>
      <Button text={content.closeButtonText} onPress={onPress} />
    </PopupGirl>
  );
}

const Style = StyleSheet.create({
  text: {
    textAlign: 'center',
    marginBottom: Token.spacing.l,
  },
});

import React, {
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

import { useApi } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import InsufficientCreditLimitModal from 'credit-limit/components/InsufficientCreditLimitModal/InsufficientCreditLimitModal';
import { SubmitBookingResponse } from 'flight/shared/api';
import { usePrebook } from 'hotel/prebook/contexts/PrebookProvider';
import useRedirectToRoom from 'hotel/prebook/hooks/useRedirectToRoom';
import { FormValue } from 'hotel/prebook/types';
import { SUBMIT_BOOKING_API, SubmitBookingRequest } from 'hotel/shared/api';
import { generateBookingSpec } from 'hotel/shared/utils/prebookSpecUtil';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';
import { convert } from 'shared/utils/currency';
import { formatCurrency } from 'shared/utils/intl';

import AvailabilityModal from './AvailabilityModal';
import ComplianceModal from './ComplianceModal';
import GeneralErrorModal from './GeneralErrorModal';
import PriceChangeModal from './PriceChangeModal';
import ReviewModal from './ReviewModal';

type ModalType = SubmitBookingResponse['bookingFailureReason'] | 'review';

type ModalState = {
  isLoading: boolean;
  type: ModalType;
  message: string;
};

export type Handler = {
  show(modal: ModalType): void;
};

const initialState: ModalState = {
  isLoading: false,
  type: null,
  message: '',
};

function ModalGroup(_props: unknown, ref: Ref<Handler>) {
  useImperativeHandle(ref, () => ({
    show(modal, message: string = '') {
      setModalState({ ...initialState, type: modal, message });
    },
  }));

  const {
    fetchAccountStatus,
    isFetching: isAccountStatusFetching,
  } = useAccountStatus();
  const priceChangeContent = useContentResource()
    .CorporateHotelPrebookPriceChangeModal;

  const prebookState = usePrebook();
  const { getValues } = useFormContext<FormValue>();

  const [{ isLoading, type, message }, setModalState] = useState<ModalState>(
    initialState
  );

  const oldPriceRef = useRef({
    grandTotal:
      prebookState.room.grandTotal &&
      formatCurrency(convert(prebookState.room.grandTotal)),
    totalFare: formatCurrency(convert(prebookState.room.totalFare)),
    serviceFee:
      prebookState.room.serviceFee &&
      formatCurrency(convert(prebookState.room.serviceFee)),
    vatFee:
      prebookState.room.vatFee &&
      formatCurrency(convert(prebookState.room.vatFee)),
  });
  const newPriceRef = useRef({
    grandTotal: prebookState.room.grandTotal,
    totalFare: prebookState.room.totalFare,
    serviceFee: prebookState.room.serviceFee,
    vatFee: prebookState.room.vatFee,
  });

  const handleCloseAll = useCallback(() => setModalState(initialState), []);

  const history = useLocalizedHistory();

  const submitBooking = useApi<SubmitBookingResponse, SubmitBookingRequest>({
    domain: 'booking',
    method: 'post',
    path: SUBMIT_BOOKING_API,
  });
  const sendSubmission = useCallback(
    async (additionalSpec?: Partial<SubmitBookingRequest>) => {
      setModalState(s => ({ ...s, isLoading: true }));

      const { spec, travelers, room, trackingId } = prebookState;
      const formValues = getValues();

      const bookingSpec = generateBookingSpec(
        spec,
        travelers,
        room,
        formValues,
        newPriceRef.current,
        {
          ...formValues.nonEmployeeTravelers,
          children: formValues.nonEmployeeTravelers?.children?.map(
            (child, index) => ({
              ...child,
              age: prebookState.nonEmployeeTravelers[
                index + (formValues.nonEmployeeTravelers?.adults?.length ?? 0)
              ].age!,
            })
          ),
        },
        trackingId,
        additionalSpec
      );

      const res = await submitBooking(bookingSpec);

      if (res.success) {
        if (res.data.status === 'BOOKED') {
          history.push(`/payment/select/hotel/${res.data.bookingId}`);
          return;
        }

        let message = '';
        if (res.data.bookingFailureReason === 'PRICE_CHANGED') {
          newPriceRef.current = {
            totalFare: res.data.totalPriceCharged,
            serviceFee: res.data.serviceFee,
            vatFee: res.data.vatFee,
            grandTotal: res.data.grandTotal,
          };
          message = formatCurrency(
            convert(res.data.grandTotal || res.data.totalPriceCharged)
          );
        }

        setModalState({
          isLoading: false,
          type: res.data.bookingFailureReason,
          message,
        });

        return;
      }

      setModalState({
        isLoading: false,
        type: 'FAILED',
        message: res.error.message,
      });
    },
    [getValues, prebookState, submitBooking]
  );

  const sendUnconfirmed = useCallback(() => {
    sendSubmission();
  }, [sendSubmission]);

  const sendConfirmed = useCallback(
    (reason: string) => {
      sendSubmission({
        confirmSubmit: true,
        policyBypassRemarks: reason,
      });
    },
    [sendSubmission]
  );

  const redirectToRoom = useRedirectToRoom();
  const handleRedirectToRoom = useCallback(() => {
    redirectToRoom('room');
  }, [redirectToRoom]);

  return (
    <>
      <ReviewModal
        isVisible={type === 'review'}
        onClosePress={handleCloseAll}
        onContinuePress={() => fetchAccountStatus(sendUnconfirmed)}
        isLoading={isLoading || isAccountStatusFetching}
      />
      <ComplianceModal
        isVisible={type === 'NON_COMPLYING'}
        onClosePress={handleCloseAll}
        onSubmit={value => fetchAccountStatus(() => sendConfirmed(value))}
        isLoading={isLoading || isAccountStatusFetching}
      />
      <PriceChangeModal
        isVisible={type === 'PRICE_CHANGED'}
        isLoading={isLoading}
        newPrice={message}
        oldPrice={
          oldPriceRef.current.grandTotal || oldPriceRef.current.totalFare
        }
        onPrimaryPress={sendUnconfirmed}
        onSecondaryPress={handleCloseAll}
        primaryLabel={priceChangeContent.submitButtonText}
        secondaryLabel={priceChangeContent.closeButtonText}
      />
      <AvailabilityModal
        isVisible={type === 'SOLD_OUT'}
        onPress={handleRedirectToRoom}
      />
      <GeneralErrorModal
        isVisible={type === 'FAILED'}
        onPress={handleCloseAll}
        text={message}
      />
      <InsufficientCreditLimitModal
        isVisible={type === 'CREDIT_LIMIT_NOT_SUFFICIENT'}
      />
    </>
  );
}

export default forwardRef(ModalGroup);

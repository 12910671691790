import { Approver } from 'company/shared/api/types';
import { FlightNonEmployeeTraveler } from 'flight/shared/api/types';
import SeatClass from 'flight/shared/constants/seat-class';
import { HotelNonEmployeeTraveler } from 'hotel/shared/api/types';
import { JavaCurrencyValue } from 'shared/utils/currency';

import ProductType from '../constants/ProductType';
import {
  Action,
  Activity,
  Employee,
  FilterRole,
  FilterStatus,
  FlightPaymentApproval,
  FlightTripRequestApproval,
  HotelPaymentApproval,
  HotelTripRequestApproval,
  NonMatchReason,
  PrebookStatus,
  Product,
  Role,
  Sort,
  Status,
  TripRequest,
} from './types';

// ===== Approval List
export const APPROVAL_LIST_API = '/api/v1/trip-request';
export type ApprovalListRequest = {
  fromDate: string;
  toDate: string;
  status: FilterStatus;
  role: FilterRole;
  sort: Sort;
  page: number;
};
export type ApprovalListResponse = {
  entriesCount: string;
  totalEntries: string;
  tripRequests: TripRequest[];
};

// ===== Approval Detail
export const APPROVAL_DETAIL_API = '/api/v1/trip-request/detail';
export type ApprovalDetailRequest = {
  tripRequestId: string;
};
export type ApprovalDetailResponse = {
  role: Role;
  tripRequestId: string;
  tripName: string;
  fromDate: string;
  toDate: string;
  status: Status;
  approvers: Employee[];
  requester: Employee;
  traveler: Employee[];
  mainBookerId: string;
  products: Product[];
  activities: Activity[];
};

// ===== Trip Request Action
export const TRIP_ACTION_API = '/api/v1/trip-request/action';
export type TripActionRequest = {
  action: Action;
  tripRequestId: string;
  reason?: string;
};

// ===== Approve Payment Request
export const APPROVE_PAYMENT_ACTION_API =
  '/api/v2/trip-payment-approval/approve';
export type ApprovePaymentActionRequest = {
  activityId: string;
  price: JavaCurrencyValue;
};
export type ApprovePaymentActionResponse = {
  status:
    | 'OK'
    | 'PRICE_CHANGED'
    | 'CREDIT_LIMIT_NOT_SUFFICIENT'
    | 'SOLD_OUT'
    | 'FLIGHT_NOT_AVAILABLE';
  price: JavaCurrencyValue | null;
};

// ===== Decline Payment Request
export const DECLINE_PAYMENT_ACTION_API =
  '/api/v1/trip-payment-approval/decline';
export type DeclinePaymentActionRequest = {
  activityId: string;
  reason: string;
};

// ===== Create Approval
export const CREATE_APPROVAL_API = '/api/v1/trip-request/create';
export type CreateApprovalRequest = {
  tripName: string;
  tripPurpose: string;
  startDate: string;
  endDate: string;
  mainBookerId: string;
  approverId: string;
  travelerIds: string[];
  attachmentUrl: string | null;
  flight?: {
    origin: string;
    destination: string;
    departureDate: string;
    returnDate?: string;
    travelerIds: string[];
    seatClass: SeatClass;
    nonEmployeeTravelers: FlightNonEmployeeTraveler;
  };
  hotel?: {
    geoId: string;
    geoType: string;
    location: string;
    checkInDate: string;
    checkOutDate: string;
    travelerIds: string[];
    numOfRooms: number;
    nonEmployeeTravelers: HotelNonEmployeeTraveler;
  };
};

// Create Approval - Get Approver List
export const GET_APPROVER_LIST_API = '/api/v1/approval/booker/approver';
export type GetApproverListRequest = {
  mainBookerEmail: string;
};
export type GetApproverListResponse = {
  approvers: Approver[];
  suggestions: Approver[];
};

// Active Trip Request Approval - Product Request
export const ACTIVE_PRODUCT_REQUEST_API = '/api/v1/product-request';
export type ActiveRequestListResponse = {
  flight: FlightTripRequestApproval[];
  hotel: HotelTripRequestApproval[];
};

// Product Request Detail
export const PRODUCT_REQUEST_DETAIL_API = '/api/v1/product-request/detail';
export type ProductRequestDetailRequest = {
  tripRequestId: string;
  type: ProductType;
};
export type ProductRequestDetailResponse =
  | FlightTripRequestApproval
  | HotelTripRequestApproval;

// Create Payment Approval
export const CREATE_PAYMENT_APPROVAL_API =
  '/api/v2/trip-payment-approval/create';
export type CreatePaymentApprovalRequest =
  | FlightPaymentApproval
  | HotelPaymentApproval;

// Check Search Spec - Select Product
export const CHECK_SEARCH_SPEC_API = '/api/v1/booking/prebook-check';
export type CheckSearchSpecRequest = {
  productRequestId?: string; // Will be null if TRA is not available
  productType: ProductType;
  travelers: string[]; // emails
  flightPreBookRequest?: {
    price: JavaCurrencyValue;
    flightIds: string[];
  };
  hotelPreBookRequest?: {
    numOfRooms: string;
    geoType: string;
    geoId: string;
    checkInDate: string;
    checkOutDate: string;
    rateKey: string;
    roomId: string;
    roomOccupancy: string;
    propertyId: string;
    nonEmployeeTravelers: HotelNonEmployeeTraveler;
    totalFareFromClient: JavaCurrencyValue;
  };
};
export type CheckSearchSpecResponse = {
  preBookingStatus: PrebookStatus;
  complyWithTravelPolicy: boolean;
  matchWithTripRequest: boolean;
  creditLimitSufficient: boolean;
  nonMatchReasons: NonMatchReason[];
};

// Approval Attachment Upload
export const APPROVAL_ATTACHMENT_UPLOAD = '/api/v1/booking/upload';
export type ApprovalAttachmentUploadRequest = {
  type: 'TRIP_REQUEST';
};
export type ApprovalAttachmentUploadResponse = {
  url: string;
  isSuccess: boolean;
};

// Approval Attachment View or Download
export const RETRIEVE_APPROVAL_ATTACHMENT =
  '/api/v1/trip-request/retrieve/attachment';
export type RetrieveApprovalAttachmentRequest = {
  activityId: string;
  isDownload: boolean;
};
export type RetrieveApprovalAttachmentResponse = {
  url: string;
  isSuccess: boolean;
};

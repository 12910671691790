import React, { ReactNode } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { Token, useTheme } from '@traveloka/web-components';

type Props = {
  style?: ViewProps['style'];
  children: ReactNode;
};

export default function FlightSearchToolbar(props: Props) {
  const { style, children } = props;
  const { color } = useTheme();

  return (
    <View
      style={[
        Style.filterSortBar,
        {
          backgroundColor: color.lightPrimary,
          borderTopColor: color.borderDivide,
          borderBottomColor: color.borderDivide,
        },
        style,
      ]}
    >
      {children}
    </View>
  );
}

const Style = StyleSheet.create({
  filterSortBar: {
    borderTopWidth: Token.border.width.thick,
    borderBottomWidth: Token.border.width.thick,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: Token.spacing.m,
  },
});

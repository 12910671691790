import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react';

import { format } from 'date-fns';

import { useApi } from '@traveloka/ctv-core';

import {
  SearchPropertyRequest,
  SearchPropertyResponse,
  SEARCH_PROPERTY_API,
} from 'hotel/shared/api';
import { Property } from 'hotel/shared/api/types';
import { useSearchSpec } from 'hotel/shared/contexts/SpecContext';
import { validateSource } from 'hotel/shared/utils/validateSource';
import { JAVA_DATE } from 'shared/utils/date';

import { RESULT_PER_PAGE } from '../constants/SearchConstant';
import { useSortFilter } from './SortFilterContext';

type State = {
  isLoading: boolean;
  properties: Property[];
  maximalOffset: number;
  trackingId: string;
};

function initializer(isLoading: boolean): State {
  return {
    isLoading,
    properties: [],
    maximalOffset: 0,
    trackingId: '',
  };
}

const ResultContext = createContext<State>(undefined!);

export function ResultProvider(props: PropsWithChildren<{}>) {
  const [state, setState] = useState<State>(initializer(false));

  const search = useApi<SearchPropertyResponse, SearchPropertyRequest>({
    domain: 'search',
    method: 'post',
    path: SEARCH_PROPERTY_API,
  });

  const searchSpec = useSearchSpec();
  const [sortFilerSpec] = useSortFilter();

  useEffect(() => {
    if (
      searchSpec.id &&
      (searchSpec.travelers.length || searchSpec.nonEmployeeTravelers.adults)
    ) {
      setState(initializer(true));

      search({
        checkInDate: format(searchSpec.checkInDate, JAVA_DATE),
        checkOutDate: format(searchSpec.checkOutDate, JAVA_DATE),
        employeeIds: searchSpec.travelers.map(traveler => traveler.employeeId),
        nonEmployeeTravelers: searchSpec.nonEmployeeTravelers,
        numOfRooms: searchSpec.rooms,
        id: searchSpec.id,
        type: searchSpec.type!,
        sort: sortFilerSpec.sort,
        stars: sortFilerSpec.stars,
        minPrice: sortFilerSpec.minPrice,
        maxPrice: sortFilerSpec.maxPrice,
        accommodationTypes: sortFilerSpec.accommodationTypes,
        limit: RESULT_PER_PAGE,
        offset: (sortFilerSpec.page - 1) * RESULT_PER_PAGE,
        locale: 'en-ID',
        currencyCode: 'IDR',
        searchTerm: searchSpec.geoName,
        tripRequestId: searchSpec.tripRequestId,
        trackingSpec: {
          pageName: 'HOTEL_SEARCH',
          pageCategory: 'HOTEL',
          data: {
            visitId: searchSpec.visitId,
            funnelSource: validateSource(searchSpec.funnelSource),
          },
        },
      })
        .then(res => {
          if (res.success) {
            return {
              isLoading: false,
              properties: res.data.properties,
              maximalOffset: Number(res.data.maximalOffset),
              trackingId: res.trackingSpec?.id || '',
            };
          }

          throw new Error();
        })
        .catch(() => initializer(false))
        .then(setState);
    }
  }, [sortFilerSpec, searchSpec, search, setState]);

  return (
    <ResultContext.Provider value={state}>
      {props.children}
    </ResultContext.Provider>
  );
}

export function useResult() {
  return useContext(ResultContext);
}

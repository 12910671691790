import React, { useCallback, useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { useTheme, Text } from '@traveloka/web-components';

import {
  useFlightSearchOptionContext,
  useFlightSearchPriceFilterContext,
} from 'flight/search/contexts/FlightSortFilterContext';
import Slider from 'legacy/Slider/Slider';
import { formatCurrency } from 'shared/utils/intl';

import { getThemeStyle, CommonStyle } from '../filter-search-toolbar.style';

type Props = {
  style?: ViewProps['style'];
};

function MorePriceFilter(props: Props) {
  const { style } = props;
  const { CorporateFlightSearchToolbar } = useContentResource();
  const content = {
    title: CorporateFlightSearchToolbar.morePrice,
  };

  const ThemeStyle = getThemeStyle(useTheme());
  const { filterOption, setFilter } = useFlightSearchOptionContext();
  const value = useFlightSearchPriceFilterContext();
  const [display, setDisplay] = useState({
    min: value.min,
    max: value.max,
  });

  const displayMin = { ...filterOption.price.min, amount: display.min };
  const displayMax = { ...filterOption.price.max, amount: display.max };

  const handleSliderChange = useCallback(
    ([min, max]) => {
      setDisplay({ min, max });
    },
    [setDisplay]
  );
  const handleSliderRelease = useCallback(
    ([min, max]) => {
      setFilter('price', { min, max });
    },
    [setFilter]
  );

  return (
    <View style={style}>
      <Text ink="primary" variant="ui-small">
        {content.title}
      </Text>
      <View style={[CommonStyle.container, ThemeStyle.container]}>
        <Text ink="secondary" variant="ui-tiny">{`${formatCurrency(
          displayMin
        )} - ${formatCurrency(displayMax)}`}</Text>
        <View style={CommonStyle.slider}>
          <Slider
            min={filterOption.price.min.amount}
            max={filterOption.price.max.amount}
            defaultValue={[display.min, display.max]}
            onChange={handleSliderChange}
            onAfterChange={handleSliderRelease}
            pushable={
              (filterOption.price.max.amount - filterOption.price.min.amount) *
              0.05
            }
          />
        </View>
        <View style={Style.spread}>
          <Text variant="ui-tiny">
            {formatCurrency(filterOption.price.min)}
          </Text>
          <Text variant="ui-tiny">
            {formatCurrency(filterOption.price.max)}
          </Text>
        </View>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  spread: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default MorePriceFilter;

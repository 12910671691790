import { CognitoUserSession } from 'amazon-cognito-identity-js';

import Permission from './permission';

export enum PaymentMethod {
  INVOICE = 'INVOICE',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum Country {
  ID = 'ID',
  TH = 'TH',
  VN = 'VN',
}

export type User = {
  name: string;
  email: string;
  has(...permission: Permission[]): boolean;
  corporateId: string;
  corporateCode: string;
  corporateName: string;
  corporateAlias: string;
  corporateStatus: string;
  globalTripRequestApproval: boolean;
  privateTripRequestApproval: boolean;
  loginTime: number;
  paymentMethod: PaymentMethod;
  country: Country;
};

type AppState = {
  targetUrl: string;
};

interface BaseAuth {
  getToken(): Promise<string | undefined>;
  applyUser(user: CognitoUserSession | string): void;
  isAuthenticated: boolean;
  isLoading: boolean;
  login(targetUrl?: string): void;
  logout(): void;
}

interface LoggedOut extends BaseAuth {
  isAuthenticated: false;
  user: undefined;
}

interface LoggedIn extends BaseAuth {
  isAuthenticated: true;
  user: User;
}

export type Auth = LoggedOut | LoggedIn;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StyleSheet, View } from 'react-native';

import { Permission, useAuth } from '@traveloka/ctv-core';
import { Card, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import { FlightNonEmployeeTraveler } from 'flight/shared/api/types';
import { HotelNonEmployeeTraveler } from 'hotel/shared/api/types';
import { PartialEmployee } from 'company/types';

import LeftSection from './Sections/LeftSection';
import ActionSection from './Sections/ActionSection';
import RightSection from './Sections/RightSection';
import { NonEmployeeProvider } from './contexts/NonEmployeeContext';

export type Traveler = {
  employeeId: string;
  fullname: string;
  email: string;
  division: Nullable<string>;
};
export type NonEmployeeTraveler =
  | FlightNonEmployeeTraveler
  | HotelNonEmployeeTraveler;

export enum NonEmployeeType {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
  CHILD_AGE = 'CHILD_AGE',
  INFANT = 'INFANT',
}

export type ValidationAdditionalInfo = { value: number };
export type ValidationReturn = Nullable<{ error: string; noUpdate: boolean }>;

export type NonEmployeeField = {
  type: NonEmployeeType;
  value: number | Nullable<string>[];
  onValidate?: (
    employees: Traveler[],
    nonEmployees: NonEmployeeField[],
    additionalInfo?: ValidationAdditionalInfo
  ) => ValidationReturn;
};

type Props = {
  isVisible: boolean;
  testID?: string;
  tripRequestEnabled: boolean;
  maximumTraveler: number;

  travelers: Traveler[];
  nonEmployeeFields?: NonEmployeeField[];
  hasNonEmployee?: boolean;

  onClose(): void;
  onSave(travelers: Traveler[], nonEmployeeFields?: NonEmployeeField[]): void;

  title: string;
  subtitle: string;
  nonEmployeeEnablementCheckbox?: string;
  nonEmployeeTooltip?: string;
  nonEmployeeSubtitle?: string;
  emptySelectedTravelerText: string;
  selectedTravelerText: string;
  complianceLabel: string;
  emptyTotalTravelerText: string;
  totalTravelerText: string;
  maxTravelersErrorMessage: string;
};

export default function TravelerPickerModal(props: Props) {
  const {
    isVisible,
    testID,
    tripRequestEnabled,
    maximumTraveler,

    travelers: travelersProps,
    nonEmployeeFields,
    hasNonEmployee,

    onClose,
    onSave,

    title,
    subtitle,
    nonEmployeeEnablementCheckbox,
    nonEmployeeTooltip,
    nonEmployeeSubtitle,
    emptySelectedTravelerText,
    selectedTravelerText,
    complianceLabel,
    emptyTotalTravelerText,
    totalTravelerText,
    maxTravelersErrorMessage,
  } = props;
  const [travelers, setTravelers] = useState<Traveler[]>(travelersProps);
  const [nonEmployeeTravelers, setNonEmployeeTravelers] = useState<
    NonEmployeeField[] | undefined
  >(nonEmployeeFields);
  const [isNonEmployeeChecked, setIsNonEmployeeChecked] = useState<boolean>(
    !!hasNonEmployee
  );

  const searchQuery = useRef('');

  useEffect(() => {
    setTravelers(travelersProps);
    setNonEmployeeTravelers(nonEmployeeFields);
    setIsNonEmployeeChecked(!!hasNonEmployee);
  }, [isVisible]);

  function selectNewEmployee(newEmployee: Nullable<PartialEmployee>) {
    newEmployee && setTravelers(prev => prev.concat(newEmployee));
  }

  const onTravelerChange = useCallback((employee: Traveler) => {
    setTravelers(prev => {
      const clone = Array.from(prev);
      const index = clone.findIndex(value => value.email === employee.email);

      if (index >= 0) {
        clone.splice(index, 1);
      } else {
        clone.push(employee);
      }

      return clone;
    });
  }, []);

  function handleSaveButton() {
    onSave(travelers, isNonEmployeeChecked ? nonEmployeeTravelers : undefined);
  }

  return (
    <>
      <Modal isVisible={isVisible}>
        <Card style={Style.popup}>
          <NonEmployeeProvider
            isVisible={isVisible}
            hasNonEmployee={!!hasNonEmployee}
            isNonEmployeeChecked={isNonEmployeeChecked}
            maximumTraveler={maximumTraveler}
            onTravelerChange={onTravelerChange}
            setIsNonEmployeeChecked={setIsNonEmployeeChecked}
            travelers={travelers}
            tripRequestEnabled={tripRequestEnabled}
            nonEmployeeTravelers={nonEmployeeTravelers}
            setNonEmployeeTravelers={setNonEmployeeTravelers}
          >
            <View style={Style.container}>
              <Text
                style={{ marginTop: -Token.spacing.xxl + Token.spacing.xs }}
                variant="headline"
              >
                {title}
              </Text>
              <Text>{subtitle}</Text>
              <View style={Style.body}>
                <LeftSection
                  testID={testID}
                  searchQuery={searchQuery}
                  selectNewEmployee={selectNewEmployee}
                />
                <RightSection
                  testID={testID}
                  emptySelectedTravelerText={emptySelectedTravelerText}
                  selectedTravelerText={selectedTravelerText}
                  complianceLabel={complianceLabel}
                  nonEmployeeEnablementCheckbox={nonEmployeeEnablementCheckbox}
                  nonEmployeeTooltip={nonEmployeeTooltip}
                  nonEmployeeSubtitle={nonEmployeeSubtitle}
                />
              </View>
            </View>
            <ActionSection
              testID={testID}
              onClose={onClose}
              onSave={handleSaveButton}
              emptyTotalTravelerText={emptyTotalTravelerText}
              totalTravelerText={totalTravelerText}
              maxTravelersErrorMessage={maxTravelersErrorMessage}
            />
          </NonEmployeeProvider>
        </Card>
      </Modal>
    </>
  );
}

export function usePickerDesign() {
  const { user } = useAuth();
  const [own, other, neb] = [
    user?.has(Permission.BOOK_PRODUCT_FOR_OWN),
    user?.has(Permission.BOOK_PRODUCT_FOR_OTHERS),
    user?.has(Permission.BOOK_PRODUCT_FOR_NON_EMPLOYEE),
  ];

  //        Permission  | Has Traveler Picker | Has Employee | Has Auto Select | Has Non-employee
  // =======================================================================================================
  // own + other + neb ->         Yes         |      Yes     |        -        |        Yes       (has-all)
  //       own + other ->         Yes         |      Yes     |        -        |         -        (other)
  //         own + neb ->         Yes         |      Yes     |        -        |        Yes       (has-all)
  //       other + neb ->         Yes         |      Yes     |        -        |        Yes       (has-all)
  //               own ->          -          |       -      |       Yes       |         -        (own)
  //             other ->         Yes         |      Yes     |        -        |         -        (other)
  //               neb ->         Yes         |       -      |        -        |        Yes       (ne)
  return useMemo(() => {
    if (own && other && neb) return 'has-all';
    if (own && other) return 'other';
    if (own && neb) return 'has-all';
    if (other && neb) return 'has-all';
    if (own) return 'own';
    if (other) return 'other';
    if (neb) return 'ne';
    return 'own';
  }, []);
}

const Style = StyleSheet.create({
  popup: {
    width: 706,
    paddingTop: 29,
  },
  container: {
    padding: Token.spacing.l,
    paddingBottom: 0,
  },
  body: {
    flex: 1,
    flexDirection: 'row',
    marginTop: Token.spacing.m,
    zIndex: 1,
    height: 532,
  },
});

import React from 'react';
import { View, ViewProps } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { useTheme, Checkbox, Icon, Text } from '@traveloka/web-components';

import {
  useFlightSearchFacilityFilterContext,
  useFlightSearchOptionContext,
} from 'flight/search/contexts/FlightSortFilterContext';

import { getThemeStyle, CommonStyle } from '../filter-search-toolbar.style';

type Props = {
  style?: ViewProps['style'];
};

function MoreFaciltyFilter(props: Props) {
  const { style } = props;
  const { CorporateFlightSearchToolbar } = useContentResource();
  const content = {
    title: CorporateFlightSearchToolbar.moreFacilities,
    baggage: CorporateFlightSearchToolbar.moreBaggage,
    meal: CorporateFlightSearchToolbar.moreInFlightMeal,
  };

  const ThemeStyle = getThemeStyle(useTheme());
  const { filterOption, setFilter } = useFlightSearchOptionContext();
  const values = useFlightSearchFacilityFilterContext();

  return (
    <View style={style}>
      <Text ink="primary" variant="ui-small">
        {content.title}
      </Text>
      <View style={[CommonStyle.container, ThemeStyle.container]}>
        {filterOption.facility.map(option => (
          <View key={option.value} style={CommonStyle.checkbox}>
            <Checkbox
              checked={values.includes(option.value)}
              disabled={option.disabled}
              onChange={() => setFilter('facility', option.value)}
            >
              <Checkbox.Control />
              <View style={CommonStyle.checkboxTextJustify}>
                <Text
                  ink={option.disabled ? 'muted' : 'primary'}
                  variant="ui-small"
                >
                  {content[option.value]}
                </Text>
                <Icon src="" />
              </View>
            </Checkbox>
          </View>
        ))}
      </View>
    </View>
  );
}

export default MoreFaciltyFilter;

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { useFormContext } from 'react-hook-form';

import { useContentResource } from '@traveloka/ctv-core';
import { Card, Label, Text, Token } from '@traveloka/web-components';

import Collapsible, {
  CollapsibleHandler,
} from 'flight/prebook/components/Collapsible/Collapsible';
import { usePrebook } from 'hotel/prebook/contexts/PrebookProvider';
import SharedStyle from 'hotel/prebook/hotelPrebook.style';
import { FormValue } from 'hotel/prebook/types';
import { convertNonEmployeeTypeToSubmitPayload } from 'hotel/search/utils/SearchQueryUtil';
import { HotelNonEmployeeForm, NonEmployeeType } from 'hotel/shared/api/types';
import HiddenField from 'shared/components/form/HiddenField/HiddenField';
import InputDropdownField from 'shared/components/form/InputDropdownField/InputDropdownField';
import InputField from 'shared/components/form/InputField/InputField';
import { formatMessage } from 'shared/utils/intl';
import { isEmail, isName, required } from 'shared/utils/validator';

export default function NonEmployeeTravelerForm() {
  const { travelers, nonEmployeeTravelers } = usePrebook();

  const content = useNonEmployeeFormContent();
  const contentNonEmployee = useContentResource()
    .CorporateHotelPrebookNonEmployeeTravelerForm;

  if (!nonEmployeeTravelers.length) {
    return null;
  }

  const nonEmployeeIndex = {
    ADULT: travelers.length - 1,
    CHILD_AGE: -1,
  };

  const nonEmployeeFormIndex = {
    ADULT: -1,
    CHILD_AGE: -1,
  };

  return (
    <>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {contentNonEmployee.title}
      </Text>
      {nonEmployeeTravelers.map((traveler, index) => {
        nonEmployeeIndex[traveler.type]++;
        nonEmployeeFormIndex[traveler.type]++;

        return (
          <NonEmployeeTravelerItem
            {...traveler}
            collapsibleTitle={content(
              traveler.type,
              nonEmployeeIndex[traveler.type] + 1,
              traveler.age
            )}
            nonEmployeeIndex={nonEmployeeFormIndex[traveler.type]}
            index={index}
            zIndex={nonEmployeeTravelers.length - index}
          />
        );
      })}
    </>
  );
}

type Props = HotelNonEmployeeForm & {
  collapsibleTitle: string;
  nonEmployeeIndex: number;
  index: number;
  zIndex: number;
};

function NonEmployeeTravelerItem(props: Props) {
  const {
    collapsibleTitle,
    nonEmployeeIndex,
    index,
    zIndex,
    type: nonEmployeeType,
    ...initialValues
  } = props;

  const collapsibleRef = useRef<CollapsibleHandler>();
  const {
    formState: { errors, isSubmitSuccessful },
  } = useFormContext<FormValue>();

  const contentNonEmployee = useContentResource()
    .CorporateHotelPrebookNonEmployeeTravelerForm;
  const titleContext = useContentResource().CorporateEnumTravelerTitle;
  const titleItems = useMemo(
    () =>
      nonEmployeeType === NonEmployeeType.ADULT
        ? [
            { label: titleContext.MR, value: 'MR' },
            { label: titleContext.MRS, value: 'MRS' },
            { label: titleContext.MISS, value: 'MISS' },
          ]
        : [
            { label: titleContext.MR, value: 'MR' },
            { label: titleContext.MISS, value: 'MISS' },
          ],
    []
  );

  useEffect(() => {
    if (!isSubmitSuccessful) {
      const hasError = !!errors.nonEmployeeTravelers?.[
        convertNonEmployeeTypeToSubmitPayload(nonEmployeeType)
      ]?.at?.(nonEmployeeIndex)?.message;

      if (collapsibleRef.current && hasError) {
        collapsibleRef.current.setIsCollapsed(false);
      }
    }
  }, [errors, isSubmitSuccessful]);

  return (
    <Card style={[SharedStyle.section, Style.section, { zIndex }]}>
      <Collapsible
        touchableTestID={`pre-book.form.non-employee-traveler.${index}.colapsible`}
        title={
          <>
            <Text>{collapsibleTitle}</Text>
          </>
        }
        defaultIsCollapsed={false}
        // @ts-ignore
        ref={collapsibleRef}
      >
        <>
          <HiddenField
            name={`nonEmployeeTravelers.${convertNonEmployeeTypeToSubmitPayload(
              nonEmployeeType
            )}[${nonEmployeeIndex}].age`}
            value={initialValues.age || ''}
          />
          <View style={[Style.group, Style.flexRow, { zIndex: 4 }]}>
            <View style={[Style.shortField, Style.titleField]}>
              <InputDropdownField
                testID={`pre-book.form.non-employee-traveler.${index}.title`}
                name={`nonEmployeeTravelers.${convertNonEmployeeTypeToSubmitPayload(
                  nonEmployeeType
                )}[${nonEmployeeIndex}].title`}
                label={contentNonEmployee.titleField}
                items={titleItems}
                defaultValue={initialValues.title}
                validate={value => {
                  if (required(value) === false) {
                    return contentNonEmployee.titleRequiredErrorMessage;
                  }

                  return;
                }}
              />
            </View>
            <InputField
              name={`nonEmployeeTravelers.${convertNonEmployeeTypeToSubmitPayload(
                nonEmployeeType
              )}[${nonEmployeeIndex}].fullName`}
              label={contentNonEmployee.fullNameField}
              validate={value => {
                if (required(value) === false) {
                  return contentNonEmployee.fullNameRequiredErrorMessage;
                } else if (isName(value) === false) {
                  return contentNonEmployee.fullNameInvalidCharacterErrorMessage;
                }

                return;
              }}
            />
          </View>
          {nonEmployeeType === 'ADULT' && (
            <View style={Style.group}>
              <Label text={contentNonEmployee.emailField} />
              <Text
                style={Style.emailSubtitle}
                variant="ui-tiny"
                ink="secondary"
              >
                {contentNonEmployee.emailSubtitle}
              </Text>
              <View style={Style.mediumField}>
                <InputField
                  name={`nonEmployeeTravelers.${convertNonEmployeeTypeToSubmitPayload(
                    nonEmployeeType
                  )}[${nonEmployeeIndex}].email`}
                  validate={value => {
                    if (value && !isEmail(value)) {
                      return contentNonEmployee.emailFormatErrorMessage;
                    }
                    return;
                  }}
                />
              </View>
            </View>
          )}
        </>
      </Collapsible>
    </Card>
  );
}

export function useNonEmployeeFormContent() {
  const content = useContentResource()
    .CorporateHotelPrebookNonEmployeeTravelerForm;
  return useCallback(
    (type: NonEmployeeType, index: number, age: string = '<1'): string => {
      switch (type) {
        case NonEmployeeType.ADULT:
          return formatMessage(content.adultUnit, { index });
        case NonEmployeeType.CHILD_AGE:
          return formatMessage(content.childUnit, {
            index,
            age,
          });
      }
    },
    [content]
  );
}

const Style = StyleSheet.create({
  section: {
    overflow: 'visible',
  },
  group: {
    marginBottom: Token.spacing.m,
  },
  flexRow: {
    flexDirection: 'row',
  },
  shortField: {
    width: '33.33%',
  },
  mediumField: {
    width: '66.66%',
  },
  titleField: {
    marginRight: Token.spacing.l,
  },
  emailSubtitle: {
    marginTop: Token.spacing.xs,
    marginBottom: Token.spacing.s,
  },
});

import React from 'react';

import RedirectHome from 'generic/RedirectHome';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import FlightPrebook from './prebook/FlightPrebook';
import FlightSearch from './search/FlightSearch';

type Props = RouteComponentProps;

function FlightApp(props: Props) {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.path}/search`} exact component={FlightSearch} />
      <Route
        path={`${match.path}/prebook/:storage`}
        exact
        component={FlightPrebook}
      />
      <Route component={RedirectHome} />
    </Switch>
  );
}

export default FlightApp;

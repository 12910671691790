import { useCallback } from 'react';

import { LocationDescriptorObject } from 'history';

import useLocalizedHistory from './useLocalizedHistory';

export default function useEagerNavigation() {
  const history = useLocalizedHistory();

  return useCallback((location: LocationDescriptorObject) => {
    const newPath = history.createHref(location);

    // React router create different format from window.location.href
    const currentPath = `${window.location.pathname}${window.location.search}`;

    // Prevent history spam
    if (currentPath !== newPath) {
      history.push(location);
    } else {
      history.replace(location);
    }
  }, []);
}

import React, { useCallback } from 'react';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { InsufficientCreditLimitModal as InsufficientCreditLimitModalUI } from '@traveloka/ctvweb-ui/generic';

import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

type Props = {
  isVisible: boolean;
};

export default function InsufficientCreditLimitModal(props: Props) {
  const { isVisible } = props;

  const { CorporateInsufficientCreditLimit } = useContentResource();
  const history = useLocalizedHistory();

  const handleClose = useCallback(() => {
    history.push('/');
  }, []);

  return (
    <InsufficientCreditLimitModalUI
      testID="insufficient-credit-limit-modal"
      title={CorporateInsufficientCreditLimit.title}
      description={CorporateInsufficientCreditLimit.description}
      isVisible={isVisible}
      onClose={handleClose}
      buttonBackText={CorporateInsufficientCreditLimit.backButtonText}
    />
  );
}

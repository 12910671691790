import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useFeatureControl, useContentResource } from '@traveloka/ctv-core';
import { Divider, Link } from '@traveloka/ctvweb-ui';
import {
  ImageLightbox,
  ImageSlideshow,
  StarRating,
} from '@traveloka/ctvweb-ui/hotel';
import Skeleton from '@traveloka/ctvweb-ui/src/shared/components/Skeleton/Skeleton';
import Location from '@traveloka/icon-kit-web/svg/dark/ic_system_map_location-fill_24px.svg';
import FrontDesk from '@traveloka/icon-kit-web/svg/darkBlue/ic_contact_24_hours_24px.svg';
import AirConditioning from '@traveloka/icon-kit-web/svg/darkBlue/ic_facilities_airco_24px.svg';
import Elevator from '@traveloka/icon-kit-web/svg/darkBlue/ic_facilities_elevator_lift_24px.svg';
import Wifi from '@traveloka/icon-kit-web/svg/darkBlue/ic_facilities_internet_wi_fi_24px.svg';
import Restaurant from '@traveloka/icon-kit-web/svg/darkBlue/ic_facilities_meal_24px.svg';
import Parking from '@traveloka/icon-kit-web/svg/darkBlue/ic_facilities_parking_24px.svg';
import Pool from '@traveloka/icon-kit-web/svg/darkBlue/ic_facilities_swimming_pool_24px.svg';
import Traveloka from '@traveloka/icon-kit-web/svg/godwitBlue/ic_brand_traveloka_24px.svg';
import CleanStay from '@traveloka/icon-kit-web/svg/greenPrimary/ic_symbol_clean_stay-fill_24px.svg';
import {
  Badge,
  Button,
  Card,
  Icon,
  Text,
  Token,
} from '@traveloka/web-components';

import { useProductRequest } from 'approval-system/shared/contexts/ProductRequestContext';
import { useProperty } from 'hotel/detail/contexts/PropertyContext';
import {
  usePriceDisplayFormatter,
  PriceDisplay,
} from 'hotel/search/contexts/PriceDisplayContext';
import { HotelBadge } from 'hotel/shared/api/types';
import ImageType from 'hotel/shared/constants/ImageType';
import useStarRatingSwitcher from 'hotel/shared/hooks/useStarRatingSwitcher';
import { convert, CurrencyValue } from 'shared/utils/currency';

const featuredFacilityMap: Record<string, string> = {
  AIR_CONDITIONING: AirConditioning,
  RESTAURANT: Restaurant,
  POOL: Pool,
  HAS_24_HOUR_FRONT_DESK: FrontDesk,
  CARPARK: Parking,
  ELEVATOR: Elevator,
  WIFI_FREE: Wifi,
};

type Props = {
  onSelectRoomPress(): void;
};

export default function Overview(props: Props) {
  const { onSelectRoomPress } = props;

  const [isVisible, setIsVisible] = useState(false);
  const openLightbox = useCallback(() => setIsVisible(true), []);
  const closeLightbox = useCallback(() => setIsVisible(false), []);

  const [
    {
      accommodationType,
      address,
      images,
      name,
      reviewScore,
      starRating,
      facilities,
    },
  ] = useProperty();

  const content = useContentResource().CorporateHotelDetailOverview;
  const toRatingLabel = useRatingLabel(reviewScore);

  const star = Number(starRating);
  const slides = useMemo(
    () =>
      images
        .filter(image => image.type === ImageType.LARGE)
        .map(image => image.url),
    [images]
  );

  const featuredFacilities = useMemo(
    () =>
      facilities.reduce((featured, facility) => {
        const { name, type } = facility;
        const icon = featuredFacilityMap[type];

        if (icon) {
          featured.push([icon, name]);
        }

        return featured;
      }, [] as Array<[string, string]>),
    [facilities]
  );

  const propertyLabelMap = {
    ALL_INCLUSIVE: content.propertyAllInclusiveLabel,
    APARTMENT: content.propertyApartmentLabel,
    BED_AND_BREAKFAST: content.propertyBedAndBreakfastLabel,
    BOAT: content.propertyBoatLabel,
    CAMPING: content.propertyCampingLabel,
    GUESTHOUSE: content.propertyGuesthouseLabel,
    HOMESTAY: content.propertyHomestayLabel,
    HOSTEL: content.propertyHostelLabel,
    HOTEL: content.propertyHotelLabel,
    OTHER: content.propertyOtherLabel,
    POUSADA: content.propertyPousadaLabel,
    RESORT: content.propertyResortLabel,
    RIAD: content.propertyRiadLabel,
    RYOKAN: content.propertyRyokanLabel,
    VILLA: content.propertyVillaLabel,
  };

  return (
    <>
      <Card style={Style.card}>
        <View style={Style.content}>
          <Text variant="ui-large">{name}</Text>
          <View style={Style.tagStar}>
            <Badge variant="info" text={propertyLabelMap[accommodationType]} />
            <StarRating size="large" star={star} style={Style.star} />
          </View>
          <View style={Style.location}>
            <Icon src={Location} width={16} height={16} />
            <Text variant="ui-small" ink="secondary" style={Style.address}>
              {address}
            </Text>
          </View>
          <CleanStayBanner />
        </View>
        <Divider margin="none" subtle />
        <View style={Style.content}>
          <ImageSlideshow
            images={slides}
            noImageLabel={content.noImageSlideShowText}
            onPress={openLightbox}
            showAllLabel={content.showAllImageText}
          />
          <View style={Style.scorePrice}>
            <View style={Style.score}>
              <Text variant="ui-large">{content.scoreTitle}</Text>
              <View style={Style.row}>
                <Icon src={Traveloka} />
                <Text
                  variant="ui-large"
                  ink="highlight"
                  style={Style.scoreText}
                >
                  {toRatingLabel}
                </Text>
              </View>
            </View>
            <LowestFare onPress={onSelectRoomPress} />
          </View>
        </View>
        {featuredFacilities.length > 0 && (
          <>
            <Divider margin="none" subtle />
            <View style={Style.content}>
              <Text variant="ui-large" style={Style.facilityTitle}>
                {content.facilityTitle}
              </Text>
              <View style={Style.facilities}>
                {featuredFacilities.map(([icon, label], index) => (
                  <View key={index} style={Style.facility}>
                    <Icon src={icon} width={32} height={32} />
                    <Text style={Style.facilityText}>{label}</Text>
                  </View>
                ))}
              </View>
            </View>
          </>
        )}
      </Card>
      {slides.length > 0 && (
        <ImageLightbox
          isVisible={isVisible}
          images={slides}
          onClosePress={closeLightbox}
        />
      )}
    </>
  );
}

type LowestFareProps = {
  onPress(): void;
};

function LowestFare(props: LowestFareProps) {
  const { onPress } = props;
  const [{ isLoading, rooms }] = useProperty();
  const formatPrice = usePriceDisplayFormatter(PriceDisplay.NIGHTLY);
  const productRequest = useProductRequest();

  const content = useContentResource().CorporateHotelDetailOverview;

  const cheapestRoomPrice = useMemo(() => {
    return rooms.reduce(
      (price, room) => {
        const totalFare = convert(room.totalFare);

        if (price.amount > totalFare.amount) {
          return totalFare;
        }

        return price;
      },
      { amount: Number.MAX_SAFE_INTEGER } as CurrencyValue
    );
  }, [rooms]);

  const hasRooms = rooms.length > 0;

  return (
    <>
      <View style={Style.price}>
        <Text variant="ui-small">{content.cheapestPriceLabel}</Text>
        {isLoading && <Skeleton height={28} width={140} />}
        {!isLoading && (
          <Text ink="price" style={Style.priceText}>
            {hasRooms ? formatPrice(cheapestRoomPrice) : '-'}
          </Text>
        )}
      </View>
      {(!productRequest.enabled || !!productRequest.data) && (
        <Button
          variant="main-cta"
          text={content.selectCheapestRoomButtonText}
          style={Style.button}
          loading={isLoading}
          disabled={!isLoading && !hasRooms}
          onPress={onPress}
        />
      )}
    </>
  );
}

function useRatingLabel(score: string | null) {
  const scoreValue = Number(score);
  const label = useStarRatingSwitcher(scoreValue);

  if (!label) {
    return undefined;
  }

  return `${Number(scoreValue).toFixed(1)} ${label}`;
}

function CleanStayBanner() {
  const [{ badges }] = useProperty();
  const content = useContentResource().CorporateHotelCleanStay;
  const cleanStayFc = useFeatureControl('b2b-clean-accommodation');

  if (!cleanStayFc.enabled) {
    return null;
  }

  const hasCleanStay = badges.some(
    badge => badge.name === HotelBadge.HOTEL_CLEAN_ACCOMMODATION
  );

  if (!hasCleanStay) {
    return null;
  }

  return (
    <View style={CleanStayStyle.container}>
      <Icon src={CleanStay} />
      <View style={CleanStayStyle.description}>
        <Text>{content.title}</Text>
        <Text ink="secondary">
          {content.subtitle}{' '}
          <Link
            to="https://traveloka.com/en/cleanaccommodation"
            target="_blank"
          >
            <Text ink="highlight">{content.learnMoreText}</Text>
          </Link>
        </Text>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  card: {
    marginBottom: Token.spacing.l,
  },
  tagStar: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Token.spacing.xxs,
  },
  star: {
    marginLeft: Token.spacing.xxs,
  },
  location: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
  address: {
    marginLeft: Token.spacing.xs,
  },
  content: {
    padding: Token.spacing.m,
  },
  row: {
    flexDirection: 'row',
  },
  scorePrice: {
    marginTop: Token.spacing.l,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  score: {
    flex: 3,
  },
  price: {
    flex: 2,
    marginHorizontal: Token.spacing.m,
  },
  scoreText: {
    marginLeft: Token.spacing.xs,
  },
  priceText: {
    ...Token.typography.headline,
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  button: {
    flex: 2,
  },
  facilityTitle: {
    marginBottom: Token.spacing.xs,
  },
  facilities: {
    flexDirection: 'row',
  },
  facility: {
    width: 'calc(100%/7)',
    alignItems: 'center',
  },
  facilityText: {
    textAlign: 'center',
    marginTop: Token.spacing.xxs,
  },
});

const CleanStayStyle = StyleSheet.create({
  container: {
    marginTop: Token.spacing.xs,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  description: {
    marginLeft: Token.spacing.xs,
  },
});

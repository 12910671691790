import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Token, Card, Text, Icon } from '@traveloka/web-components';
import { Divider } from '@traveloka/ctvweb-ui';
import DocumentIcon from '@traveloka/icon-kit-web/svg/dark/ic_system_document_24px.svg';

import { useFlightRequestRefundForm } from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import {
  RefundDocument,
  FlightMainRefundReasonWithNoSecondaryButHasDocument,
} from 'refund/shared/api/types';
import { useRefundDocumentSwitcher } from 'refund/shared/utils/request-refund-utils';

const DOT = '•';

export default function PassengerList() {
  const { mainRefundReason, travelerForms } = useFlightRequestRefundForm();

  const content = useContentResource().CorporateFlightRequestRefundReview;
  const mainRefundReasonContent = useContentResource()
    .CorporateEnumFlightMainRefundReason;
  const personalReasonContent = useContentResource()
    .CorporateEnumFlightPersonalRefundReason;
  const refundDocumentSwitcher = useRefundDocumentSwitcher();
  const travelerTitleContent = useContentResource().CorporateEnumTravelerTitle;

  return (
    <>
      {travelerForms
        .filter(travelerForm => travelerForm.checkboxValue)
        .map((travelerForm, travelerIndex) => {
          const secondaryReason = travelerForm.secondaryReason
            ? `${DOT} ${personalReasonContent[travelerForm.secondaryReason]}`
            : undefined;

          const requiredDocumentCount = Object.keys(
            travelerForm.requiredDocument
          ).length;

          return (
            <Card key={travelerIndex} style={Style.passengerListCard}>
              <View style={Style.passengerUpperSection}>
                <Text style={Style.title} variant="title-1">
                  {`${travelerTitleContent[travelerForm.traveler.title]} ${
                    travelerForm.traveler.fullname
                  }`}
                </Text>
                <Text variant="ui-baseline">
                  {content.refundReasonText}{' '}
                  {mainRefundReasonContent[mainRefundReason!.mainReason]}{' '}
                  {secondaryReason}
                </Text>
              </View>
              {!!requiredDocumentCount && (
                <>
                  <Divider
                    style={Style.passengerDivider}
                    margin="none"
                    subtle
                  />
                  {mainRefundReason!.mainReason ===
                  FlightMainRefundReasonWithNoSecondaryButHasDocument.DOUBLE_BOOKING ? (
                    <View style={Style.passengerLowerSection}>
                      <Text>
                        {
                          refundDocumentSwitcher(
                            RefundDocument.DOUBLE_BOOKING_PNR
                          ).title
                        }
                        :
                        {
                          travelerForm.requiredDocument.DOUBLE_BOOKING_PNR!
                            .valueToSubmit
                        }
                      </Text>
                    </View>
                  ) : (
                    <View style={Style.passengerLowerSection}>
                      <Icon src={DocumentIcon} width={20} height={20} />
                      <Text style={Style.attachedDocumentText}>
                        {requiredDocumentCount} {content.documentUnit}
                      </Text>
                    </View>
                  )}
                </>
              )}
            </Card>
          );
        })}
    </>
  );
}

const Style = StyleSheet.create({
  title: {
    marginBottom: Token.spacing.m,
  },
  passengerListCard: {
    marginBottom: Token.spacing.xs,
    paddingVertical: Token.spacing.ml,
  },
  passengerUpperSection: {
    paddingHorizontal: Token.spacing.l,
  },
  passengerDivider: {
    marginTop: Token.spacing.s,
    marginBottom: Token.spacing.ml,
  },
  passengerLowerSection: {
    paddingHorizontal: Token.spacing.l,
    flexDirection: 'row',
  },
  attachedDocumentText: {
    marginLeft: Token.spacing.ml,
  },
});

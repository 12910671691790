import { Airport } from 'flight/search/types';
import { JavaCurrencyValue } from 'shared/utils/currency';

import PreTripStatus from 'postbooking/shared/constants/PreTripStatus';

import BookingStatus from '..//constants/booking-status';
import RefundableStatus from '../constants/refundable-status';
import SeatClass from '../constants/seat-class';

// Prebook - Refund
export type RefundPolicy = {
  refundPolicy: string[];
};

// Prebook - Add Ons
export type FlightAddOns = {
  journeysWithAvailableAddOnsOptions: JourneyAddOns[];
};

export type JourneyAddOns = {
  segmentsWithAvailableAddOns: SegmentAddOns[];
  availableAddOnsOptions: Nullable<AddOnsOptions>;
};

type SegmentAddOns = {
  segment: SegmentInfo;
  availableAddOnsOptions: AddOnsOptions;
};

type SegmentInfo = {
  sourceAirport: string;
  destinationAirport: string;
  departureDate: string;
  marketingAirline: string;
  operatingAirline: string;
  seatClass: SeatClass;
};

type AddOnsOptions = {
  baggageOptions: BaggageAddOns[];
  mealOptions: MealAddOns[];
};

export type BaggageAddOns = {
  id: string;
  baggageType: 'PIECE' | 'KG';
  baggageWeight: string;
  baggageQuantity: string;
  priceWithCurrency: JavaCurrencyValue;
};

type MealAddOns = {
  id: string;
  quantity: string;
  displayName: string;
  priceWithCurrency: JavaCurrencyValue;
};

// Prebook - Booking Submit
export type Gender = 'M' | 'F';
export type Honorific = 'MR' | 'MRS' | 'MISS';

export type Passengers = {
  adults: AdultPassenger[];
  children?: ChildPassenger[];
  infants?: InfantPassenger[];
};

export type PassengerDetail = {
  type: NonEmployeeType;
  isEmployee: boolean;
  gender: Gender;
  nationality?: string;
  dateOfBirth?: string;
  title: Honorific;
  documentDetail: {
    documentType: 'NATIONAL_ID' | 'PASSPORT';
    documentNo?: string;
    issuingCountry?: string;
    expirationDate?: string;
    issuanceDate?: string;
  } | null;
};

export type AdultPassenger = PassengerDetail & {
  type: NonEmployeeType.ADULT;
  fullName?: string; // For non-employee
  addOns?: FlightAddOns[];
  email?: string;
};

export type ChildPassenger = PassengerDetail & {
  type: NonEmployeeType.CHILD;
  fullName: string;
  addOns?: FlightAddOns[];
};

export type InfantPassenger = PassengerDetail & {
  type: NonEmployeeType.INFANT;
  fullName: string;
};

// Flight Search
export type FlightSearchResult = {
  complying: Nullable<Compliance>;
  flightId: string;
  departureAirport: string;
  arrivalAirport: string;
  numOfTransits: string;
  journeys: Journey[];
};

export type Compliance = {
  errorMessage: string;
  isComplying: boolean;
  reasonNotComplying: 'NONE' | 'FLIGHT_NOT_COMPLY' | 'SEATCLASS_NOT_COMPLY';
};

export type Journey = {
  segments: Segment[];
  arrivalDetail: Omit<ArrivalDetail, 'airport'>;
  departureDetail: Omit<DepartureDetail, 'airport'>;
  numOfTransits: string;
  journeyDuration: string;
  // API contract stated this is nullable, but it makes no sense
  fareInfo: FareInfo;
  daysOffset: string;
  refundableStatus: RefundableStatus;
};

export type Segment = {
  flightCode: string;
  marketingAirline: string;
  brandAirline: string;
  operatingAirline: string;
  subClass: Nullable<string>;
  seatClass: SeatClass;
  flightDurationInMinutes: string;
  transitDurationInMinutes: Nullable<string>;
  departureDetail: Omit<DepartureDetail, 'airport'>;
  arrivalDetail: Omit<ArrivalDetail, 'airport'>;
  stopInfo: Nullable<StopInfo>;
  addOns: AddOnsOptions;
  mayNeedReCheckIn: boolean;
  visaRequired: boolean;
};

type StopInfo = {
  airportCode: string;
  durationMinutes: string;
};

export type FareInfo = {
  partnerFare: FareDetail;
  airlineFare: FareDetail;
};

export type FareDetail = {
  adultFare: PaxFareDetail;
  childFare: Nullable<PaxFareDetail>;
  infantFare: Nullable<PaxFareDetail>;
};

export type PaxFareDetail = {
  baseFareWithCurrency: JavaCurrencyValue;
  vatWithCurrency: Nullable<JavaCurrencyValue>;
  pscWithCurrency: Nullable<JavaCurrencyValue>;
  fuelSurchargeWithCurrency: Nullable<JavaCurrencyValue>;
  adminFeeWithCurrency: Nullable<JavaCurrencyValue>;
  additionalFeeWithCurrency: Nullable<JavaCurrencyValue>;
  totalFareWithCurrency: JavaCurrencyValue;
};

// Flight Smart Combo
export type SmartComboSearchResult = {
  complying: Compliance;
  journeys: Journey[];
};

export type SmartComboReturnResult = {
  complying: Compliance;
  journeys: Array<Omit<Journey, 'fareInfo'> & { fareInfo: null }>;
};

export type FlightTable = Array<{
  indexReturn: string;
  flightId: string;
  returnFlightFareInfo: FareInfo;
}>;

// Booking Detail
export type FlightBookingDetail = {
  bookingTime: string;
  status: BookingStatus;
  fareDetail: {
    adultFare: {
      totalFareWithCurrency: JavaCurrencyValue;
    };
    childFare: Nullable<{
      totalFareWithCurrency: JavaCurrencyValue;
    }>;
    infantFare: Nullable<{
      totalFareWithCurrency: JavaCurrencyValue;
    }>;
  };
  grandTotalFareWithCurrency: JavaCurrencyValue;
  journeys: BookingDetailJourney[];
  contactDetail: BookingDetailContactDetail;
  passengers: {
    infants: BookingDetailPassengerDetail[];
    children: BookingDetailPassengerDetail[];
    adults: BookingDetailPassengerDetail[];
  };
};

export type BookingDetailPassengerDetail = {
  isEmployee: boolean;
  email: string;
  fullName: string;
  firstName: string;
  lastName?: string;
  addOns: JourneyAddOns[];
  division: Nullable<string>;
  title: Honorific;
  documentDetail?: DocumentDetail;
};

type DocumentDetail = {
  documentType: string;
  documentNo: string;
};

export type BookingDetailContactDetail = {
  firstName: string;
  lastName: string;
  customerEmail: string;
  customerPhoneNumber: string;
  customerPhoneNumberCountryCode: string;
};

export type BookingDetailJourney = {
  segments: BookingDetailSegment[];
  arrivalDetail: ArrivalDetail;
  departureDetail: DepartureDetail;
  numOfTransits: string;
  journeyDuration: string;
  daysOffset: string;
  refundableStatus: RefundableStatus;
  preTripStatus: Nullable<PreTripStatus>;
};

export type BookingDetailSegment = {
  airline: {
    airlineCode: string;
    airlineName: string;
    logoUrl: string;
  };
  flightCode: string;
  marketingAirline: string;
  brandAirline: string;
  operatingAirline: string;
  subClass: Nullable<string>;
  seatClass: SeatClass;
  flightDurationInMinutes: string;
  transitDurationInMinutes: Nullable<string>;
  departureDetail: DepartureDetail;
  arrivalDetail: ArrivalDetail;
  stopInfo: Nullable<StopInfo>;
  fareBasisCode: Nullable<string>;
  mayNeedReCheckIn: boolean;
  visaRequired: boolean;
};

type ArrivalDetail = {
  airport: Airport;
  airportCode: string;
  arrivalDate: string;
  arrivalTime: string;
  arrivalTerminal: Nullable<string>;
};

type DepartureDetail = {
  airport: Airport;
  airportCode: string;
  departureDate: string;
  departureTime: string;
  departureTerminal: Nullable<string>;
};

// Non-employee Booking
export type FlightNonEmployeeTraveler = {
  adults: number;
  children: number;
  infants: number;
};

export enum NonEmployeeType {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
  INFANT = 'INFANT',
}

export type BookingDetailPnrCode = {
  airlinePnr: string[];
  providerPnr: string;
  segments: string[];
};

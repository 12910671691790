import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import {
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { Text, Token, Icon, Image } from '@traveloka/web-components';
import RightArrow from '@traveloka/icon-kit-web/svg/dark/ic_system_arrow_right_16px.svg';
import { Divider } from '@traveloka/ctvweb-ui';

import { BookingDetailJourney } from 'flight/shared/api/types';
import { usePaymentStateFlight } from 'payment/select/contexts/PaymentContext';
import { JAVA_DATE_ALT } from 'shared/utils/date';
import { formatMessage } from 'shared/utils/intl';

type TransitProps = {
  style: ViewStyle;
  text: string;
};
function Transit(props: TransitProps) {
  return (
    <>
      <View style={props.style} />
      <Text variant="ui-small" ink="secondary">
        {props.text}
      </Text>
    </>
  );
}

function useSwitchTransit(num: number) {
  const content = useContentResource().CorporateFlightPayment;

  if (num === 0) {
    return <Transit style={Style.flightDirectDot} text={content.directText} />;
  } else {
    return (
      <Transit
        style={Style.flightTransitDot}
        text={formatMessage(content.transitText, { num })}
      />
    );
  }
}

export default function FlightDetail() {
  const { booking } = usePaymentStateFlight();
  const { journeys } = booking.flightBookingDetail;

  return (
    <>
      {journeys.map((journey, index) => (
        <Journey key={index} index={index} {...journey} />
      ))}
    </>
  );
}

function Journey(props: BookingDetailJourney & { index: number }) {
  const {
    journeyDuration,
    segments,
    departureDetail,
    arrivalDetail,
    index,
  } = props;

  const content = useContentResource().CorporateFlightPayment;
  const { changeFormat } = useLocalizedDateFormat();

  const journeyHours = Math.floor(Number(journeyDuration) / 60);

  const { airline, seatClass } = segments[0];

  const transit =
    segments.length + segments.filter(segment => segment.stopInfo).length - 1;

  const switchTransitContent = useSwitchTransit(transit);

  return (
    <View style={Style.sliderContainer}>
      {index === 1 && (
        <View style={Style.divider}>
          <Divider />
        </View>
      )}
      <View style={Style.date}>
        <Text variant="title-2">
          {changeFormat(
            departureDetail.departureDate,
            JAVA_DATE_ALT,
            'FULL_WEEKDAY_MONTH'
          )}
        </Text>
      </View>
      <View style={[Style.airlineInfo, Style.flexRowVerticalCenter]}>
        <Image
          key={index}
          src={airline.logoUrl}
          width={44}
          height={44}
          objectFit="contain"
        />
        <View style={Style.airlineText}>
          <View style={Style.textHeader}>
            <Text variant="ui-baseline">{airline.airlineName}</Text>
          </View>
          <Text style={Style.seatClassText} variant="ui-small" ink="secondary">
            {seatClass.toLowerCase()}
          </Text>
        </View>
      </View>
      <View style={Style.flexRow}>
        <View style={Style.departureItem}>
          <View style={Style.textHeader}>
            <Text variant="ui-baseline">{departureDetail.departureTime}</Text>
          </View>
          <Text variant="ui-small" ink="secondary">
            {`${departureDetail.airport.city} (${departureDetail.airport.airportCode})`}
          </Text>
        </View>
        <View style={[Style.flexRowVerticalCenter, Style.departureItem]}>
          <Icon src={RightArrow} width={12} height={12} />
        </View>
        <View style={Style.departureItem}>
          <View style={Style.textHeader}>
            <Text variant="ui-baseline">{arrivalDetail.arrivalTime}</Text>
          </View>
          <Text variant="ui-small" ink="secondary">
            {`${arrivalDetail.airport.city} (${arrivalDetail.airport.airportCode})`}
          </Text>
        </View>
        <View style={Style.departureItem}>
          <View style={Style.textHeader}>
            <Text variant="ui-baseline">
              {formatMessage(content.duration, {
                h: padStartNumber(journeyHours),
                m: padStartNumber(Number(journeyDuration) - journeyHours * 60),
              })}
            </Text>
          </View>
          <View style={Style.flexRowVerticalCenter}>
            {switchTransitContent}
          </View>
        </View>
      </View>
    </View>
  );
}

function padStartNumber(value: number) {
  return value >= 10 ? value : `0${value}`;
}

const Style = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  flexRowVerticalCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sliderContainer: {
    marginBottom: Token.spacing.m,
  },
  date: {
    marginBottom: Token.spacing.s,
  },
  airlineInfo: {
    marginBottom: Token.spacing.s,
  },
  seatClassText: {
    textTransform: 'capitalize',
  },
  divider: {
    marginTop: -Token.spacing.s,
  },
  departureItem: {
    marginRight: Token.spacing.l,
  },
  airlineText: {
    marginLeft: Token.spacing.m,
  },
  textHeader: {
    marginBottom: Token.spacing.xxs,
  },
  flightDirectDot: {
    width: Token.spacing.xs,
    height: Token.spacing.xs,
    borderRadius: Token.border.radius.rounded,
    backgroundColor: Token.color.uiBluePrimary,
    marginRight: Token.spacing.xs,
  },
  flightTransitDot: {
    width: Token.spacing.xs,
    height: Token.spacing.xs,
    borderRadius: Token.border.radius.rounded,
    backgroundColor: Token.color.uiYellowPrimary,
    marginRight: Token.spacing.xs,
  },
});

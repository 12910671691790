import { parse } from 'qs';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useLocale } from '@traveloka/ctv-core';

import { RefundRequestQs } from 'postbooking/booking-detail/components/ProductRefundDetail/ProductRefundDetail';
import {
  FlightSubmitRefundError,
  HotelSubmitRefundError,
} from 'refund/shared/api/types';

export enum LocalRequestRefundError {
  MUST_NOT_CHILD_INFANT_ONLY = 'MUST_NOT_CHILD_INFANT_ONLY',
}

export enum RequestRefundStep {
  REFUND_POLICY = 'REFUND_POLICY',
  SELECT_DETAILS = 'SELECT_DETAILS',
  REFUND_DOCUMENT = 'REFUND_DOCUMENT',
  REVIEW_REQUEST = 'REVIEW_REQUEST',
}

type Error =
  | FlightSubmitRefundError
  | HotelSubmitRefundError
  | LocalRequestRefundError;

type State = {
  currentStep: RequestRefundStep;
  error: Nullable<Error>;
};

type Dispatch = {
  changeCurrentStep(step: RequestRefundStep): void;
  setError(error: Nullable<Error>): void;
  goToBookingDetail(): void;
};

const initialState: State = {
  currentStep: RequestRefundStep.REFUND_POLICY,
  error: null,
};

const ProductRequestRefundPageContext = createContext<[State, Dispatch]>(
  undefined!
);

export function ProductRequestRefundPageProvider(props: PropsWithChildren<{}>) {
  const qs: RefundRequestQs = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const { locale } = useLocale();
  const [state, setState] = useState<State>(initialState);

  const changeCurrentStep = useCallback(
    (step: RequestRefundStep) =>
      setState(prevValue => ({ ...prevValue, currentStep: step })),
    []
  );
  const setError = useCallback(
    (error: Nullable<Error>) =>
      setState(prevValue => ({ ...prevValue, error })),
    []
  );

  const goToBookingDetail = useCallback(() => {
    window.location.href = `/${locale}/booking/ongoing/${qs.productType.toLowerCase()}/${
      qs.bookingId
    }/${qs.index}`;
  }, []);

  const dispatch: Dispatch = useMemo(
    () => ({ changeCurrentStep, setError, goToBookingDetail }),
    [changeCurrentStep, setError, goToBookingDetail]
  );

  return (
    <ProductRequestRefundPageContext.Provider value={[state, dispatch]}>
      {props.children}
    </ProductRequestRefundPageContext.Provider>
  );
}

export function useProductRequestRefundPage() {
  return useContext(ProductRequestRefundPageContext);
}

import React, { useMemo } from 'react';
import { Animated, StyleSheet } from 'react-native';

import { Token } from '@traveloka/web-components';
import { useLayout } from '@traveloka/web-components/future';

import Divider from '../../../shared/components/Divider/Divider';
import FlightFare from '../../FlightFare/FlightFare';
import FlightJourney from '../../FlightJourney/FlightJourney';
import { useActiveDetail, ActiveDetail } from '../contexts/DetailContext';
import { useProps } from '../contexts/PropsContext';
import useAccentDefault from '../hooks/use-accent-default';
import useControlledTransition from '../hooks/use-controlled-transition';
import { appendTestId } from '../../../shared/utils/TestUtil';

type ToggleBehaviorMap = Record<ActiveDetail, number>;
type Props = {
  testID?: string;
};

export default function FlightDetail(props: Props) {
  const activeDetail = useActiveDetail();
  const { compactLayout, flight } = useProps();
  const { testID } = props;

  const flightJourney = useMemo(
    () => (
      <FlightJourney
        testID={appendTestId(testID, 'journey')}
        compactLayout={compactLayout}
        flight={flight}
      />
    ),
    [compactLayout, flight, testID]
  );
  const flightFare = useMemo(
    () => (
      <FlightFare
        testID={appendTestId(testID, 'fare')}
        compactLayout={compactLayout}
        flight={flight}
      />
    ),
    [compactLayout, flight, testID]
  );

  const { interpolate } = useControlledTransition<
    ActiveDetail,
    ToggleBehaviorMap
  >(activeDetail, Token.animation.timing.instant);
  const [flightRect, flightBindings] = useLayout();
  const [fareRect, fareBindings] = useLayout();

  const height = {
    none: 0,
    flight: flightRect.height + 1,
    fare: fareRect.height + 1,
  }[activeDetail];

  const translateXMap = useAccentDefault({
    flight: 0,
    fare: -fareRect.width,
  });
  const translateX = interpolate(translateXMap);

  const flightOpacity = interpolate({
    none: 0,
    flight: 1,
    fare: 0,
  });

  const fareOpacity = interpolate({
    none: 0,
    flight: 0,
    fare: 1,
  });

  return (
    <>
      {activeDetail !== 'none' && (
        <Animated.View style={[Style.container, { height }]}>
          <Divider margin="none" />
          <Animated.View
            style={[Style.slider, { transform: [{ translateX }] }]}
          >
            <Animated.View
              {...flightBindings}
              style={[Style.item, { opacity: flightOpacity }]}
            >
              {flightJourney}
            </Animated.View>
            <Animated.View
              {...fareBindings}
              style={[Style.item, { opacity: fareOpacity }]}
            >
              {flightFare}
            </Animated.View>
          </Animated.View>
        </Animated.View>
      )}
    </>
  );
}

const Style = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
  slider: {
    width: '200%',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  item: {
    width: '50%',
  },
});

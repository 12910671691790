import React, { useCallback } from 'react';

import { useContentResource } from '@traveloka/ctv-core/resource';
import InfoIcon from '@traveloka/icon-kit-web/svg/dark/ic_system_status_info-fill_24px.svg';
import { Button, Icon, InfoBox } from '@traveloka/web-components';

import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

export default function NotAuthorized() {
  const history = useLocalizedHistory();
  const handleRedirection = useCallback(() => {
    history.push('/');
  }, []);

  const content = useContentResource().CorporateProductPaymentNotAuthorized;

  return (
    <InfoBox
      message={content.message}
      Icon={() => <Icon src={InfoIcon} width={16} height={16} />}
      isFullWidth={false}
      variant="ALERT"
      Action={
        <Button
          onPress={handleRedirection}
          variant="text"
          size="small"
          text={content.goToHomepageButtonText}
        />
      }
    />
  );
}

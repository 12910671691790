import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { addDays } from 'date-fns';

import {
  useContentResource,
  useFeatureControl,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { EmptyResult, PropertyCard } from '@traveloka/ctvweb-ui/hotel';
import {
  LoadingIndicator,
  Pagination,
  Text,
  Token,
} from '@traveloka/web-components';

import { RESULT_PER_PAGE } from 'hotel/search/constants/SearchConstant';
import { usePriceDisplayFormatter } from 'hotel/search/contexts/PriceDisplayContext';
import { useResult } from 'hotel/search/contexts/ResultContext';
import { useSortFilter } from 'hotel/search/contexts/SortFilterContext';
import { stringifySearchSpec } from 'hotel/search/utils/SearchQueryUtil';
import { HotelBadge, Property } from 'hotel/shared/api/types';
import AccomodationType from 'hotel/shared/constants/AccomodationType';
import { useSearchSpec } from 'hotel/shared/contexts/SpecContext';
import useStarRatingSwitcher from 'hotel/shared/hooks/useStarRatingSwitcher';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';
import { convert } from 'shared/utils/currency';
import { formatCurrency, formatMessage } from 'shared/utils/intl';

type PriceBreakdown =
  | {
      label: string;
      value: string;
      isPositive?: true;
    }
  | 'separator';

export default function SearchList() {
  const searchSpec = useSearchSpec();
  const { checkInDate, duration } = searchSpec;
  const { isLoading, maximalOffset, properties, trackingId } = useResult();
  const [{ isFiltered, page }, dispatch] = useSortFilter();

  const { format } = useLocalizedDateFormat();
  const content = useContentResource().CorporateHotelSearch;

  const durationLabels = useMemo(
    () =>
      Array.from({ length: duration }, (_, index) => {
        const night = index + 1;
        const date = format(addDays(checkInDate, night), 'FULL_MONTH');

        return formatMessage(content.durationInfoText, {
          index: night,
          date,
        });
      }),
    [checkInDate, duration]
  );

  const propertyLabelMap = useMemo<Record<AccomodationType, string>>(
    () => ({
      ALL_INCLUSIVE: content.propertyAllInclusiveLabel,
      APARTMENT: content.propertyApartmentLabel,
      BED_AND_BREAKFAST: content.propertyBedAndBreakfastLabel,
      BOAT: content.propertyBoatLabel,
      CAMPING: content.propertyCampingLabel,
      GUESTHOUSE: content.propertyGuesthouseLabel,
      HOMESTAY: content.propertyHomestayLabel,
      HOSTEL: content.propertyHostelLabel,
      HOTEL: content.propertyHotelLabel,
      OTHER: content.propertyOtherLabel,
      POUSADA: content.propertyPousadaLabel,
      RESORT: content.propertyResortLabel,
      RIAD: content.propertyRiadLabel,
      RYOKAN: content.propertyRyokanLabel,
      VILLA: content.propertyVillaLabel,
    }),
    [content]
  );

  const history = useLocalizedHistory();
  const navigateToHotelDetail = useCallback(
    (property: Property) => {
      const spec = {
        ...searchSpec,
        searchId: trackingId,
        type: 'HOTEL',
        geoName: property.name,
        id: property.propertyId,
        funnelSource: 'SEARCH_RESULT',
      };

      const url = history.createHref({
        pathname: '/hotel/detail',
        search: stringifySearchSpec(spec),
      });

      window.open(url, '_blank');
    },
    [searchSpec, trackingId]
  );

  if (isLoading) {
    return (
      <View style={Style.center}>
        <LoadingIndicator style={Style.loadingDots} />
        <Text variant="ui-large">{content.loadingHeaderText}</Text>
        <Text ink="secondary">{content.loadingDescriptionText}</Text>
      </View>
    );
  }

  if (!properties.length) {
    const text = isFiltered
      ? content.emptyResultWithFilter
      : content.emptyResultNoFilter;
    const buttonText = isFiltered ? content.emptyResultButton : undefined;

    return (
      <EmptyResult
        text={text}
        buttonText={buttonText}
        style={Style.empty}
        onPress={() => dispatch({ type: 'reset' })}
      />
    );
  }

  return (
    <>
      {properties.map((property, index) => (
        <PropertyWrapper
          key={index}
          index={index}
          property={property}
          navigateToHotelDetail={navigateToHotelDetail}
          propertyLabelMap={propertyLabelMap}
          durationLabels={durationLabels}
        />
      ))}
      <View style={Style.center}>
        <Pagination
          testIDPrefix="hotel.search.list.pagination.item"
          value={page}
          totalPage={Math.ceil(maximalOffset / RESULT_PER_PAGE)}
          onPageChange={page => dispatch({ type: 'setPage', page })}
        />
      </View>
    </>
  );
}

type PropertyWrapperProps = {
  index: number;
  property: Property;
  navigateToHotelDetail(property: Property): void;
  propertyLabelMap: Record<AccomodationType, string>;
  durationLabels: string[];
};

function PropertyWrapper(props: PropertyWrapperProps) {
  const {
    durationLabels,
    index,
    navigateToHotelDetail,
    property,
    propertyLabelMap,
  } = props;

  const searchSpec = useSearchSpec();
  const { duration } = searchSpec;

  const cleanStayFc = useFeatureControl('b2b-clean-accommodation');
  const content = useContentResource().CorporateHotelSearch;
  const toRatingLabel = useRatingLabel(property.reviewScore);
  const formatDisplayPrice = usePriceDisplayFormatter();

  const highRate = convert(property.highRate);
  const surchargeTotal = convert(property.surchargeTotal);
  const totalFare = convert(property.totalFare);

  const nightlyRateTotal = {
    ...totalFare,
    amount: totalFare.amount - surchargeTotal.amount,
  };

  const nightlyRate = {
    ...nightlyRateTotal,
    amount: nightlyRateTotal.amount / duration,
  };

  const breakdown: PriceBreakdown[] = durationLabels.map(label => ({
    label,
    value: formatCurrency(nightlyRate),
  }));

  breakdown.push(
    'separator',
    {
      label: content.breakdownIncludingTaxText,
      value: formatCurrency(nightlyRateTotal),
    },
    {
      label: content.breakdownTaxAndOtherFeeText,
      value: formatCurrency(surchargeTotal),
    },
    {
      label: content.breakdownTravelokaFee,
      value: content.breakdownValueFree,
      isPositive: true,
    }
  );

  const originalPrice =
    highRate.amount > totalFare.amount
      ? formatDisplayPrice(highRate)
      : undefined;

  return (
    <PropertyCard
      testID={`hotel.search.list.${index}`}
      key={property.propertyId}
      complianceLabel={content.noncompliantLabelText}
      hasCleanStay={
        cleanStayFc.enabled &&
        property.badges.some(
          badge => badge.name === HotelBadge.HOTEL_CLEAN_ACCOMMODATION
        )
      }
      image={property.image || undefined}
      isComplying={property.isComplying}
      location={property.regionCity}
      mainPrice={formatDisplayPrice(totalFare)}
      name={property.name}
      originalPrice={originalPrice}
      onPress={() => navigateToHotelDetail(property)}
      priceBreakdown={breakdown}
      propertyType={propertyLabelMap[property.accommodationType]}
      rating={toRatingLabel}
      star={Number(property.starRating)}
      style={Style.result}
      toggleLabel={content.priceBreakdownToggleText}
      totalPayment={formatCurrency(totalFare)}
      totalPaymentLabel={content.totalPaymentText}
    />
  );
}

function useRatingLabel(score: string | null) {
  const scoreValue = Number(score);
  const label = useStarRatingSwitcher(scoreValue);

  if (!label) {
    return undefined;
  }

  return `${label} - ${scoreValue.toFixed(1)}`;
}

const Style = StyleSheet.create({
  result: {
    marginBottom: Token.spacing.m,
  },
  empty: {
    marginTop: Token.spacing.m,
  },
  center: {
    alignItems: 'center',
  },
  loadingDots: {
    marginBottom: Token.spacing.l,
  },
});

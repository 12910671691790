import React from 'react';
import { View, StyleSheet, createElement } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Text, Icon, Token, useTheme } from '@traveloka/web-components';
import { Divider } from '@traveloka/ctvweb-ui';
import FlightIcon from '@traveloka/icon-kit-web/svg/darkPrimary/ic_product_flight-fill_24px.svg';

import {
  ROUND_TRIP_SEPARATOR,
  ONE_WAY_SEPARATOR,
} from 'flight/search/constants/flight-search-constant';
import { useFlightRequestRefund } from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';

export default function PolicyCard() {
  const [{ data }] = useFlightRequestRefund();
  const { color } = useTheme();

  const content = useContentResource().CorporateFlightRequestRefundPolicy;

  const departureCity =
    data.flightRefundData.journeys[0].segments[0].departureDetail.city;
  const arrivalCity =
    data.flightRefundData.journeys[0].segments[
      data.flightRefundData.journeys[0].segments.length - 1
    ].arrivalDetail.city;

  return (
    <>
      <View style={[Style.header, { borderBottomColor: color.borderDivide }]}>
        <Icon src={FlightIcon} />
        <Text style={Style.policyTitle} variant="title-1">
          {content.policyTitle}
        </Text>
      </View>
      <View style={Style.content}>
        <Text variant="title-1">
          {departureCity}{' '}
          <Text variant="ui-baseline">
            {data.flightRefundData.journeys.length > 1
              ? ROUND_TRIP_SEPARATOR
              : ONE_WAY_SEPARATOR}
          </Text>{' '}
          {arrivalCity}
        </Text>
        <Divider />
        <Text style={Style.policyPointTitle} variant="title-3">
          {content.generalRefundPolicyTitle}
        </Text>
        <ul>
          {content.generalRefundPolicyContent
            .split('|')
            .map(text =>
              createElement(
                'li',
                { style: Style.policyPoint },
                <Text variant="ui-small">{text}</Text>
              )
            )}
        </ul>
      </View>
    </>
  );
}

const Style = StyleSheet.create({
  header: {
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.ml,
    borderLeftWidth: Token.border.width.bold,
    borderLeftColor: Token.color.uiBluePrimary,
    borderBottomWidth: Token.border.width.thick,
    flexDirection: 'row',
  },
  policyTitle: {
    marginLeft: Token.spacing.ml,
  },
  content: {
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.ml,
  },
  policyPointTitle: {
    marginTop: Token.spacing.xxs,
    marginBottom: Token.spacing.m,
  },
  policyPoint: {
    marginTop: Token.spacing.xxs,
    marginBottom: Token.spacing.m,
    listStyle: 'disc',
  },
});

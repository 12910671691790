import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Text, Token } from '@traveloka/web-components';
import Highlighter from 'react-highlight-words';

const highlightStyle = {
  color: Token.color.uiBluePrimary,
  backgroundColor: 'transparent',
};

type HotelAutocompleteEmptyProps = {
  title: string;
  description: string;
  filterText: string;
};

export default function HotelAutocompleteEmpty(
  props: HotelAutocompleteEmptyProps
) {
  const { title, description, filterText } = props;
  const searchWords = filterText.split(/\s+/g);

  return (
    <View style={Style.content}>
      <Text variant="title-3" ink="secondary" style={Style.title}>
        <Highlighter
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={title}
          highlightStyle={highlightStyle}
        />
      </Text>
      <Text variant="ui-tiny" ink="secondary">
        {description}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  content: {
    backgroundColor: Token.color.uiLightNeutral,
    paddingVertical: Token.spacing.s,
    paddingHorizontal: Token.spacing.m,
  },
  title: {
    marginBottom: Token.spacing.xxs,
  },
});

import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Auth } from 'aws-amplify';

import { useApi, useAuth } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { ProfileFieldGroupLoading } from '@traveloka/ctvweb-ui/profile';
import { Card, Text, Token } from '@traveloka/web-components';

import { GET_PROFILE, ProfileResponse } from 'profile/api';
import { Profile } from 'profile/types';

import EditProfileForm from './components/EditProfileForm/EditProfileForm';
import { doForgetPassword } from '@traveloka/ctv-core/auth/utils/auth';
import { User } from '@traveloka/ctv-core/auth/types';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

type PageState = { isLoading: true } | { isLoading: false; profile: Profile };

export default function EditProfile() {
  const user = useAuth().user as User;
  const [pageState, setPageState] = useState<PageState>({ isLoading: true });

  const content = useContentResource().CorporateEditProfile;
  const history = useLocalizedHistory();

  const fetchProfile = useApi<ProfileResponse>({
    domain: 'management',
    method: 'post',
    path: GET_PROFILE,
  });

  useEffect(() => {
    fetchProfile({}).then(res => {
      if (res.success) {
        setPageState({
          isLoading: false,
          profile: res.data.profile,
        });
      }
    });
  }, []);

  async function handleResetPassword() {
    const message = await doForgetPassword(user.email)
      .then(data => {
        history.push(`/login?data=${data}`);
        Auth.signOut();
      })
      .catch(err => err.message);

    return message;
  }

  return (
    <>
      <Text variant="headline" style={Style.title}>
        {content.title}
      </Text>

      {pageState.isLoading && (
        <Card>
          {Array.from({ length: 5 }, (_, index) => (
            <ProfileFieldGroupLoading key={index} />
          ))}
        </Card>
      )}

      {!pageState.isLoading && (
        <EditProfileForm
          profile={pageState.profile}
          onResetPassword={handleResetPassword}
        />
      )}
    </>
  );
}

const Style = StyleSheet.create({
  title: {
    marginBottom: Token.spacing.m,
  },
});

import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Divider, Input, InputDropdownRadio } from '@traveloka/ctvweb-ui';
import ChevronDown from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';
import InfoIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_status_info_16px.svg';
import { Icon, Text, Token } from '@traveloka/web-components';

import {
  useHotelRequestRefundForm,
  useHotelRequestRefundFormAction,
} from 'refund/request-refund/contexts/hotel/HotelRequestRefundFormContext';
import { useHotelRequestRefund } from 'refund/request-refund/contexts/hotel/HotelRequestRefundContext';
import { HotelMainRefundReason } from 'refund/shared/api/types';
import {
  validateTripId,
  validateRefundReason,
} from 'refund/shared/utils/request-refund-validation';

export default function SelectMainReason() {
  const { mainRefundReason } = useHotelRequestRefundForm();
  const { updateSelectedMainReason } = useHotelRequestRefundFormAction();
  const [{ data }] = useHotelRequestRefund();
  const { reasons } = data;

  const content = useContentResource().CorporateHotelRequestRefundSelectDetails;
  const mainRefundReasonContent = useContentResource()
    .CorporateEnumHotelMainRefundReason;

  return (
    <>
      <View style={Style.sidePadding}>
        <InputDropdownRadio
          style={Style.mainReasonDropdown}
          cardStyle={Style.mainReasonDropdownCard}
          alignment="left"
          text={mainRefundReasonContent[mainRefundReason!.mainReason]}
          title={content.mainReasonTitle}
          items={reasons.map(reason => ({
            label: mainRefundReasonContent[reason.mainReason],
            value: reason.mainReason,
          }))}
          value={mainRefundReason!.mainReason}
          onChange={(value: HotelMainRefundReason) =>
            updateSelectedMainReason(value)
          }
          rightIcon={<Icon src={ChevronDown} />}
        />
        <Text variant="ui-small" ink="secondary">
          {content.mainReasonDesc}
        </Text>
      </View>
      {mainRefundReason?.mainReason === 'DOUBLE_BOOKING' && <IdenticalTrip />}
      {mainRefundReason?.mainReason === 'OTHERS' && <RefundReason />}
    </>
  );
}

function IdenticalTrip() {
  const [errorMessage, setErrorMessage] = useState<string>();

  const { mainRefundReason } = useHotelRequestRefundForm();
  const { updateAdditionalInfo } = useHotelRequestRefundFormAction();
  const content = useContentResource().CorporateHotelRequestRefundSelectDetails;

  useEffect(() => {
    if (validateTripId(mainRefundReason?.additionalInformation)) {
      setErrorMessage(content.identicalTripInvalidTripIdMessage);
    } else {
      setErrorMessage(undefined);
    }
  });

  return (
    <View style={AdditionalInputStyle.sidePadding}>
      <Divider margin="vertical" spacing="l" />
      <Text style={AdditionalInputStyle.title}>
        {content.identicalTripTitle}
      </Text>
      <Input
        style={AdditionalInputStyle.inputIdenticalTripContainer}
        inputStyle={AdditionalInputStyle.inputInner}
        value={mainRefundReason!.additionalInformation}
        placeholder={content.inputIdenticalTripPlaceholder}
        onChangeText={value => updateAdditionalInfo(value)}
        error={errorMessage}
      />
      <View style={AdditionalInputStyle.identicalTripDescriptionContainer}>
        <Icon src={InfoIcon} />
        <Text
          style={AdditionalInputStyle.identicalTripDescriptionText}
          variant="ui-tiny"
          ink="secondary"
        >
          {content.inputIdenticalTripDescription}
        </Text>
      </View>
    </View>
  );
}

function RefundReason() {
  const [errorMessage, setErrorMessage] = useState<string>();

  const { mainRefundReason } = useHotelRequestRefundForm();
  const { updateAdditionalInfo } = useHotelRequestRefundFormAction();
  const content = {
    refundReasonTitle: 'Refund reason',
    inputRefundReasonTripPlaceholder: 'I submit this refund because...',
    inputRefundReasonDescription:
      'Briefly explain your reason in maximum character 160',
    refundReasonMaxLengthErrorMessage:
      'Refund Reason too long. Please describe your Refund Reason in 160 characters or less.',
  };

  useEffect(() => {
    if (validateRefundReason(mainRefundReason?.additionalInformation)) {
      setErrorMessage(content.refundReasonMaxLengthErrorMessage);
    } else {
      setErrorMessage(undefined);
    }
  });

  return (
    <View style={AdditionalInputStyle.sidePadding}>
      <Divider margin="vertical" spacing="l" />
      <Text style={AdditionalInputStyle.title}>
        {content.refundReasonTitle}
      </Text>
      <Input
        style={AdditionalInputStyle.inputRefundReasonTripContainer}
        inputStyle={AdditionalInputStyle.inputInner}
        value={mainRefundReason!.additionalInformation}
        placeholder={content.inputRefundReasonTripPlaceholder}
        onChangeText={value => updateAdditionalInfo(value)}
        error={errorMessage}
      />
      <Text variant="ui-small" ink="secondary">
        {content.inputRefundReasonDescription}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  mainReasonDropdown: {
    zIndex: 1,
    width: 300,
    marginBottom: Token.spacing.xs,
  },
  mainReasonDropdownCard: {
    width: 288,
  },
  sidePadding: {
    paddingHorizontal: Token.spacing.m,
    zIndex: 2,
  },
});

const AdditionalInputStyle = StyleSheet.create({
  sidePadding: {
    paddingHorizontal: Token.spacing.m,
  },
  title: {
    marginTop: Token.spacing.xxs,
    marginBottom: Token.spacing.m,
  },
  inputIdenticalTripContainer: {
    marginBottom: Token.spacing.m,
  },
  inputRefundReasonTripContainer: {
    marginBottom: Token.spacing.xs,
  },
  inputInner: {
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.m,
  },
  identicalTripDescriptionContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  identicalTripDescriptionText: {
    marginLeft: Token.spacing.m,
  },
});

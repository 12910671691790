import { format, parse, addMinutes } from 'date-fns';

/**
 * As of v2, date-fns use Unicode Technical Standard #35
 *
 * read more
 * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 */
export const JAVA_DATE = 'yyyy-MM-dd';
// This should be removed once BE has changed all format to JAVA_DATE
export const JAVA_DATE_ALT = 'MM-dd-yyyy';
export const DATE = 'dd-MM-yyyy';

export const TIME = 'HH:mm';

export function changeFormat(
  date: string,
  from: string,
  to: string,
  baseDate: number | Date = 0
) {
  return format(parse(date, from, baseDate), to);
}

export function formatWithOffset(dateString: string, dateFormat: string) {
  const date = new Date(dateString.replace(' ', 'T'));
  return format(addMinutes(date, -date.getTimezoneOffset()), dateFormat);
}

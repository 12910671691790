import React from 'react';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Style from './Slider.module.css';

type Value = [number, number];

type Props = {
  min: number;
  max: number;
  step?: number;
  value?: Value;
  defaultValue?: Value;
  pushable?: boolean | number;
  onBeforeChange?(value: Value): void;
  onChange?(value: Value): void;
  onAfterChange?(value: Value): void;
};

export default function Slider(props: Props) {
  return <Range {...props} className={Style.slider} allowCross={false} />;
}

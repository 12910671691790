import {
  addDays,
  format,
  isBefore,
  isValid,
  min,
  parse,
  startOfDay,
} from 'date-fns';
import { parse as parseQs, stringify } from 'qs';

import {
  HotelNonEmployeeTraveler,
  NonEmployeeType,
} from 'hotel/shared/api/types';
import { ComputedSearchSpec } from 'hotel/shared/contexts/SpecContext';
import { DATE } from 'shared/utils/date';

import {
  CHECKIN_DATE_OFFSET,
  CHECKOUT_DATE_OFFSET,
  MAX_DURATION,
  MAX_ROOMS,
} from '../constants/SearchConstant';

type RawSearchSpec = {
  at?: string;
  dt?: string;
  r?: string;
  vid?: string;
  sid?: string;
  fs?: string;
  tr?: string;
  et?: string;
  a?: string;
  c?: string;
};

type TrackingData = {
  visitId: string;
  searchId?: string;
  funnelSource?: string;
};

export type GeoSpec = {
  type: string;
  id: string;
  geoName: string;
};

type BaseSearchSpec = {
  travelers: string[];
  nonEmployeeTravelers: HotelNonEmployeeTraveler;
  checkInDate: Date;
  checkOutDate: Date;
  rooms: number;
  tripRequestId?: string;
} & TrackingData;

export type SearchSpec = BaseSearchSpec & (GeoSpec | Partial<GeoSpec>);

export function parseSearchSpec(qs: string): SearchSpec {
  const query: RawSearchSpec = parseQs(qs, { ignoreQueryPrefix: true });

  const travelers = parseEmployeeTravelers(query.et);
  const nonEmployeeTravelers = parseNonEmployeeTravelers(query.a, query.c);
  const [type, id, geoName] = parseAutocomplete(query.at);
  const [checkInDate, checkOutDate] = parseDate(query.dt);
  const rooms = parseRoom(
    query.r,
    travelers.length + nonEmployeeTravelers.adults
  );
  const trackingSpec = parseTrackingSpec(query);

  return {
    ...trackingSpec,
    travelers,
    nonEmployeeTravelers,
    checkInDate,
    checkOutDate,
    type,
    id,
    geoName,
    rooms,
    tripRequestId: query.tr,
  };
}

const allowedTypes = ['HOTEL', 'LANDMARK'];
export function stringifySearchSpec(
  obj: Omit<ComputedSearchSpec, 'duration'> & GeoSpec
): string {
  const type = allowedTypes.includes(obj.type) ? obj.type : 'GEO';
  const query = {
    at: [type, obj.id, obj.geoName].join('.'),
    dt: [obj.checkInDate, obj.checkOutDate]
      .map(date => format(date, DATE))
      .join('.'),
    r: String(obj.rooms),
    vid: obj.visitId,
    sid: obj.searchId,
    fs: obj.funnelSource,
    tr: obj.tripRequestId,
    et: obj.travelers.map(t => t.employeeId).join('.'),
    a: obj.nonEmployeeTravelers.adults,
    c: obj.nonEmployeeTravelers.childrenAges.join('.'),
  };

  return stringify(query, { addQueryPrefix: true });
}

function parseEmployeeTravelers(et?: string) {
  if (!et) {
    return [];
  }

  // Filter Unique
  return et
    .split('.')
    .filter((value, index, array) => array.indexOf(value) === index);
}

function parseNonEmployeeTravelers(a?: string, c?: string) {
  return {
    adults: checkNumber(a),
    childrenAges: c ? c.split('.') : [],
  };
}

function checkNumber(num: string | undefined): number {
  const number = Number(num);
  return isNaN(number) ? 0 : number;
}

function parseAutocomplete(at?: string) {
  if (!at) {
    return [];
  }

  const autocomplete = at
    .split('.')
    .filter(Boolean)
    .slice(0, 3);

  return autocomplete.length < 3 ? [] : autocomplete;
}

function parseDate(dt?: string) {
  const today = startOfDay(new Date());

  if (!dt) {
    const checkInDate = addDays(today, CHECKIN_DATE_OFFSET);
    const checkOutDate = addDays(checkInDate, CHECKOUT_DATE_OFFSET);

    return [checkInDate, checkOutDate];
  }

  const [checkInDate, maybeCheckOutDate] = dt.split('.') as [string, string?];
  const checkInDateOrToday = normalizeDate(checkInDate, today);
  const minimumCheckOutDate = addDays(checkInDateOrToday, CHECKOUT_DATE_OFFSET);

  if (!maybeCheckOutDate) {
    return [checkInDateOrToday, minimumCheckOutDate];
  }

  const checkOutDate = min([
    normalizeDate(maybeCheckOutDate, minimumCheckOutDate),
    addDays(checkInDateOrToday, MAX_DURATION),
  ]);

  return [checkInDateOrToday, checkOutDate];
}

function normalizeDate(date: string, fallbackDate: Date): Date {
  const parsedDate = parse(date, DATE, 0);

  if (!isValid(parsedDate) || isBefore(parsedDate, fallbackDate)) {
    return fallbackDate;
  }

  return parsedDate;
}

function parseRoom(r: string | undefined, fallback: number) {
  const rooms = Number(r);

  return isNaN(rooms) || rooms < 1 || rooms > fallback
    ? Math.min(Math.max(fallback, 1), MAX_ROOMS)
    : rooms;
}

const visitId = Date.now().toString();

function parseTrackingSpec(query: RawSearchSpec): TrackingData {
  return {
    visitId: parseId(query.vid) ?? visitId,
    searchId: parseId(query.sid),
    funnelSource: query.fs,
  };
}

function parseId(id: string | undefined) {
  return id && isNaN(parseInt(id, 10)) ? undefined : id;
}

export function convertNonEmployeeTypeToPayload(type: NonEmployeeType) {
  switch (type) {
    case NonEmployeeType.ADULT:
      return 'adults';
    case NonEmployeeType.CHILD_AGE:
      return 'childrenAges';
  }
}

export function convertNonEmployeePayloadToType(
  type: keyof HotelNonEmployeeTraveler
) {
  switch (type) {
    case 'adults':
      return NonEmployeeType.ADULT;
    case 'childrenAges':
      return NonEmployeeType.CHILD_AGE;
  }
}

export function convertNonEmployeeTypeToSubmitPayload(type: NonEmployeeType) {
  switch (type) {
    case NonEmployeeType.ADULT:
      return 'adults';
    case NonEmployeeType.CHILD_AGE:
      return 'children';
  }
}

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';

import ChevronLeft from '@traveloka/icon-kit-web/svg/light/ic_system_chevron_left_16px.svg';
import ChevronRight from '@traveloka/icon-kit-web/svg/light/ic_system_chevron_right_16px.svg';
import Close from '@traveloka/icon-kit-web/svg/light/ic_system_cross_close_24px.svg';
import { Icon, Image, Token } from '@traveloka/web-components';
import { useHoverable, Modal } from '@traveloka/web-components/future';

type Props = {
  images: string[];
  isVisible: boolean;
  onClosePress(): void;
};

const IMAGES_PER_ROW = 8;

export default function ImageLightbox(props: Props) {
  const { images, isVisible, onClosePress } = props;

  const scrollHeightRef = useRef(0);
  const measureLayout = useCallback((e: LayoutChangeEvent) => {
    scrollHeightRef.current = e.nativeEvent.layout.height;
  }, []);
  const scrollRef = useRef<ScrollView>(null!);
  const thumbnailRefs = useRef<Array<TouchableOpacity | null>>([]);

  const yOffsetRef = useRef(0);
  const handleScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      yOffsetRef.current = e.nativeEvent.contentOffset.y;
    },
    []
  );

  const [currentIndex, setIndex] = useState(0);
  const handleLeft = useCallback(() => {
    setIndex(index => {
      const nextIndex = index - 1;

      if (nextIndex < 0) {
        return images.length - 1;
      }

      return nextIndex;
    });
  }, [images.length]);
  const handleRight = useCallback(() => {
    setIndex(index => {
      const nextIndex = index + 1;

      if (nextIndex > images.length - 1) {
        return 0;
      }

      return nextIndex;
    });
  }, [images.length]);
  const handleUp = useCallback(() => {
    setIndex(index => {
      const nextIndex = index - IMAGES_PER_ROW;

      if (nextIndex >= 0) {
        return nextIndex;
      }

      const numOfRows = Math.ceil(images.length / IMAGES_PER_ROW);
      const fullRows = numOfRows * IMAGES_PER_ROW;
      const tempIndex = fullRows + nextIndex;

      if (tempIndex > images.length - 1) {
        return tempIndex - IMAGES_PER_ROW;
      }

      return tempIndex;
    });
  }, [images.length]);
  const handleDown = useCallback(() => {
    setIndex(index => {
      const nextIndex = index + IMAGES_PER_ROW;

      if (nextIndex > images.length - 1) {
        return nextIndex % IMAGES_PER_ROW;
      }

      return nextIndex;
    });
  }, [images.length]);

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();

      switch (e.key) {
        case 'ArrowLeft':
          handleLeft();
          break;
        case 'ArrowRight':
          handleRight();
          break;
        case 'ArrowUp':
          handleUp();
          break;
        case 'ArrowDown':
          handleDown();
          break;
      }
    },
    [handleLeft, handleRight, handleUp, handleDown]
  );

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('keydown', handleKeydown);

      return () => document.removeEventListener('keydown', handleKeydown);
    }

    return;
  }, [isVisible]);

  useEffect(() => {
    const view = thumbnailRefs.current[currentIndex];

    if (scrollRef.current && view) {
      view.measure((_x, y, _w, h) => {
        if (
          y < yOffsetRef.current ||
          y + h > yOffsetRef.current + scrollHeightRef.current
        ) {
          scrollRef.current.scrollTo({ y });
        }
      });
    }
  }, [currentIndex]);
  const mainImage = images[currentIndex];

  return (
    <Modal isVisible={isVisible} style={Style.content} backdropOpacity={0.95}>
      <View style={Style.header}>
        <TouchableIcon src={Close} size={24} onPress={onClosePress} />
      </View>
      <View style={Style.main}>
        <TouchableIcon src={ChevronLeft} size={16} onPress={handleLeft} />
        <Image
          variant="rounded"
          objectFit="cover"
          src={mainImage}
          width={640}
          height={360}
        />
        <TouchableIcon src={ChevronRight} size={16} onPress={handleRight} />
      </View>
      <ScrollView
        onScroll={handleScroll}
        ref={scrollRef}
        style={Style.thumbnailsScroll}
        contentContainerStyle={Style.thumbnails}
        scrollEventThrottle={500}
        onLayout={measureLayout}
      >
        {images.map((image, index) => (
          <Thumbnail
            ref={el => (thumbnailRefs.current[index] = el)}
            key={index}
            src={image}
            isActive={currentIndex === index}
            onPress={() => setIndex(index)}
          />
        ))}
      </ScrollView>
    </Modal>
  );
}

type TouchableIconProps = {
  onPress(): void;
  size: number;
  src: string;
};

function TouchableIcon(props: TouchableIconProps) {
  const { onPress, size, src } = props;
  const [isHovered, hoverEvent] = useHoverable();

  return (
    <TouchableOpacity activeOpacity={0.5} onPress={onPress}>
      <View
        {...hoverEvent}
        style={[Style.chevron, isHovered && Style.chevronFocused]}
      >
        <Icon src={src} width={size} height={size} />
      </View>
    </TouchableOpacity>
  );
}

type ThumbnailProps = {
  isActive: boolean;
  src: string;
  onPress(): void;
};

const Thumbnail = forwardRef<TouchableOpacity, ThumbnailProps>((props, ref) => {
  const [isHovered, hoverEvent] = useHoverable();
  const { isActive, onPress, src } = props;

  return (
    <TouchableOpacity
      ref={ref}
      activeOpacity={0.5}
      onPress={onPress}
      style={Style.thumbnailTouchable}
      accessible={false}
    >
      <Image
        {...hoverEvent}
        variant="rounded"
        objectFit="cover"
        src={src}
        height={108}
        style={[
          Style.thumbnail,
          isActive && Style.thumbnailActive,
          isHovered && Style.thumbnailFocused,
        ]}
      />
    </TouchableOpacity>
  );
});

const Style = StyleSheet.create({
  content: {
    width: 960,
    marginTop: Token.spacing.l,
    marginBottom: 0,
  },
  header: {
    alignItems: 'flex-end',
    paddingLeft: Token.spacing.xl,
  },
  main: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: Token.spacing.m,
    marginBottom: Token.spacing.l,
  },
  thumbnailsScroll: {
    marginHorizontal: Token.spacing.xl,
    flex: 1,
  },
  thumbnails: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  chevron: {
    transitionProperty: 'border-color, opacity',
    transitionDuration: `${Token.animation.timing.instant}ms`,
    opacity: 0.5,
    padding: Token.spacing.xs,
  },
  chevronFocused: {
    opacity: 1,
  },
  thumbnailTouchable: {
    flexBasis: `${100 / IMAGES_PER_ROW}%`,
  },
  thumbnail: {
    borderWidth: 2,
    borderStyle: 'solid',
    transitionProperty: 'opacity, border-color',
    transitionDuration: `${Token.animation.timing.instant}ms`,
    opacity: 0.7,
    overflow: 'hidden',
  },
  thumbnailActive: {
    borderColor: Token.color.uiLightPrimary,
    opacity: 1,
  },
  thumbnailFocused: {
    opacity: 1,
  },
});

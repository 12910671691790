import React from 'react';
import {
  RequestRefundStep,
  useProductRequestRefundPage,
} from 'refund/request-refund/contexts/ProductRequestRefundPageContext';

import HotelRequestRefundPolicy from '../HotelRequestRefundSteps/policy/HotelRequestRefundPolicy';
import HotelRequestRefundReview from '../HotelRequestRefundSteps/review/HotelRequestRefundReview';
import HotelRequestRefundSelectDetails from '../HotelRequestRefundSteps/select-details/HotelRequestRefundSelectDetails';

export default function HotelRequestRefund() {
  const [pageData] = useProductRequestRefundPage();
  const currentStep = pageData.currentStep as Exclude<
    RequestRefundStep,
    RequestRefundStep.REFUND_DOCUMENT
  >;

  // Hotel doesn't have REFUND_DOCUMENT step.
  switch (currentStep) {
    case RequestRefundStep.REFUND_POLICY:
      return <HotelRequestRefundPolicy />;
    case RequestRefundStep.SELECT_DETAILS:
      return <HotelRequestRefundSelectDetails />;
    case RequestRefundStep.REVIEW_REQUEST:
      return <HotelRequestRefundReview />;
  }
}

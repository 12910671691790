import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FlatList, ScrollView, StyleSheet, View } from 'react-native';
import { debounce } from 'lodash';

import {
  Permission,
  useApi,
  useAuth,
  useContentResource,
  useFeatureControl,
} from '@traveloka/ctv-core';
import {
  EntryDetailRequest,
  EntryDetailResponse,
  EntryListRequest,
  EntryListResponse,
  GET_ENTRY_DETAIL,
  GET_ENTRY_LIST,
  Operator,
  SortType,
} from '@traveloka/ctv-core/pad';
import { Divider, Input } from '@traveloka/ctvweb-ui';
import { FilterBar, TravelerPickerEntry } from '@traveloka/ctvweb-ui/generic';
import { Item } from '@traveloka/ctvweb-ui/src/generic/TravelerPickerModal/FilterBar/DropdownChecklist/DropdownChecklist';
import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import { Button, Checkbox, Text, Token, Icon } from '@traveloka/web-components';
import { Traveler } from '@traveloka/ctvweb-ui/src/generic/TravelerPickerModal/types';

import { PartialDivision, PartialEmployee, Employee } from 'company/types';
import CompanyEmployeeUpsertModal from 'company/employee/components/CompanyEmployeeUpsertModal/CompanyEmployeeUpsertModal';
import { formatMessage } from 'shared/utils/intl';

import { NonEmployeeType, usePickerDesign } from '../RevampTravelerPickerModal';
import { useNonEmployee } from '../contexts/NonEmployeeContext';
import useRecentPickerPassengers from 'shared/hooks/useRecentPickerPassengers';
import Search from '@traveloka/icon-kit-web/svg/dark/ic_system_search_24px.svg';

type Props = {
  testID?: string;
  searchQuery: MutableRefObject<string>;
  selectNewEmployee(newEmployee: Nullable<PartialEmployee>): void;
};

export default function LeftSection(props: Props) {
  const { testID, searchQuery, selectNewEmployee } = props;

  const [entryList, setEntryList] = useState<
    EntryListResponse<PartialEmployee>
  >({
    entries: [],
    message: null,
    totalEntries: '0',
  });
  const { data: recentPickerDataPassenger } = useRecentPickerPassengers();
  const [divisions, setDivisions] = useState<Item[]>([]);
  const [hasNewEmployee, setHasNewEmployee] = useState<boolean>(false);
  const recentSearchFC = useFeatureControl('b2b-recent-search');

  const {
    isVisible,
    travelers,
    nonEmployeeTravelers,
    maximumTraveler,
    tripRequestEnabled,
    isNonEmployeeChecked,
    onTravelerChange,
  } = useNonEmployee();

  const content = useContentResource().CorporateProductSearchForm;
  const dropdownRef = useRef<{ setShowDropdown: (value: boolean) => void }>();
  const { user } = useAuth();
  const pickerDesign = usePickerDesign();

  const fetchEntryList = useApi<EntryListResponse<unknown>, EntryListRequest>({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_LIST,
  });

  const fetchEntryDetail = useApi<
    EntryDetailResponse<PartialEmployee>,
    EntryDetailRequest
  >({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_DETAIL,
  });

  const fetchEmployeeById = useCallback(
    async (id: string) => {
      const payload = {
        entityType: 'employee',
        entryId: id,
      };

      const res = await fetchEntryDetail(payload);

      if (res.success) {
        return res.data.value;
      }
      return null;
    },
    [fetchEntryDetail]
  );

  useEffect(() => {
    fetchEntryList({
      entityType: 'divisionList',
      search: {
        entriesCount: 9999,
        filter: [],
      },
    }).then(divisionResponse => {
      if (divisionResponse.success) {
        const divisions = (divisionResponse.data
          .entries as PartialDivision[]).map(entry => ({
          label: entry.division,
          value: entry.divisionId,
          checked: false,
        }));
        setDivisions(divisions);
      }
    });
  }, []);

  const [canBookForOwn, canBookForOthers, mayCreateUser] = useMemo(
    () => [
      user?.has(Permission.BOOK_PRODUCT_FOR_OWN),
      user?.has(Permission.BOOK_PRODUCT_FOR_OTHERS),
      user?.has(Permission.USER_CREATE),
    ],
    [user]
  );

  const fetchEmployee = useCallback(
    async (query: string) => {
      if (canBookForOthers) {
        const payload: EntryListRequest = {
          entityType: 'employeeList',
          search: {
            entriesCount: 10,
            sort: {
              fieldName: 'fullname',
              type: SortType.ASCENDING,
            },
            filter: [
              {
                fieldName: 'search',
                arguments: {
                  value: query,
                  operator: Operator.LIKE,
                },
              },
            ],
          },
        };

        const checkedDivision = divisions.filter(division => division.checked);
        if (checkedDivision.length) {
          payload.search.filter.push({
            fieldName: 'divisionId',
            arguments: {
              value: checkedDivision.map(s => s.value),
              operator: Operator.IN,
            },
          });
        }

        if (!canBookForOwn) {
          payload.search.filter.push({
            fieldName: 'email',
            arguments: {
              value: user!.email,
              operator: Operator.NOT_EQUAL,
            },
          });
        }

        const res = await fetchEntryList(payload);

        if (res.success) {
          setEntryList(res.data as EntryListResponse<PartialEmployee>);
        }
      } else if (canBookForOwn) {
        const payload: EntryListRequest = {
          entityType: 'employeeList',
          search: {
            entriesCount: 1,
            filter: [
              {
                fieldName: 'email',
                arguments: {
                  value: user!.email,
                  operator: Operator.EQUAL,
                },
              },
            ],
          },
        };

        const res = await fetchEntryList(payload);

        if (res.success) {
          setEntryList(res.data as EntryListResponse<PartialEmployee>);
        }
      }
    },
    [fetchEntryList, divisions, user]
  );

  async function handleNewEmployeeOnSuccess(data: Partial<Employee>) {
    if (data.employeeId) {
      const newEmployee = await fetchEmployeeById(data.employeeId);

      selectNewEmployee(newEmployee);
    }
    fetchEmployee(searchQuery.current);
    setHasNewEmployee(false);
  }

  const onChangeSearchText = useCallback(
    debounce((query: string) => {
      fetchEmployee(query);
      searchQuery.current = query;
    }, 300),
    [fetchEmployee]
  );

  const onSelectAllDivisionPress = useCallback((checked: boolean) => {
    setDivisions(prevDivisions =>
      prevDivisions.map(division => {
        return {
          ...division,
          checked,
        };
      })
    );
  }, []);

  function onDivisionPress(checked: boolean, item: Item) {
    setDivisions(prevDivisions =>
      prevDivisions.map(division => {
        if (division.value === item.value) {
          return {
            ...division,
            checked,
          };
        }
        return division;
      })
    );
  }

  useEffect(() => {
    fetchEmployee(searchQuery.current);
  }, [divisions]);

  useEffect(() => {
    if (isVisible) return;

    dropdownRef.current?.setShowDropdown(false);
    searchQuery.current = '';

    //Waiting for close animation
    setTimeout(() => {
      onSelectAllDivisionPress(false);
    }, 300);
  }, [isVisible]);

  const travelerMap: Dictionary<true | undefined> = useMemo(() => {
    return travelers.reduce((obj, entry) => {
      obj[entry.email] = true;

      return obj;
    }, {} as Dictionary<true>);
  }, [travelers]);

  const showAddEmployee =
    !!mayCreateUser && entryList.entries.length < 10 && pickerDesign !== 'ne';

  const recentPickerPassenger: Traveler[] = useMemo(() => {
    return (recentPickerDataPassenger?.details || []).map(detail => {
      return {
        employeeId: detail.id,
        fullname: detail.fullName,
        email: detail.email,
        division: detail.division,
      };
    });
  }, [recentPickerDataPassenger]);

  const filteredRecentPickerPassenger = useMemo(() => {
    return recentPickerPassenger.filter(passenger => {
      let passengerFound = true;
      const checkedDivision = divisions.filter(division => division.checked);
      if (checkedDivision.length > 0) {
        passengerFound = checkedDivision.some(
          division => division.label === passenger.division
        );
      }
      if (passengerFound && searchQuery.current) {
        passengerFound = passenger.fullname
          .toLocaleLowerCase()
          .includes(searchQuery.current.toLocaleLowerCase());
      }
      return passengerFound;
    });
  }, [recentPickerPassenger, divisions, searchQuery.current]);

  return (
    <>
      <View style={Style.container}>
        {pickerDesign === 'ne' && (
          <View style={Style.noPermission}>
            <Text variant="ui-tiny">{content.noEmployeePermission}</Text>
          </View>
        )}

        <Input
          key={String(isVisible)}
          testID={appendTestId(testID, 'search.input')}
          placeholder={content.employeeSearchPlaceholder}
          onChangeText={onChangeSearchText}
          iconLeft={<Icon src={Search} />}
          disabled={pickerDesign === 'ne'}
        />
        <FilterBar
          style={Style.filter}
          filterLabel={content.filterText}
          divisions={divisions}
          divisionPlaceholder={content.divisionPlaceholderText}
          divisionDisabled={pickerDesign === 'ne'}
          dropdownRef={dropdownRef}
          onDivisionPress={onDivisionPress}
          onSelectAllDivisionPress={onSelectAllDivisionPress}
          testID={appendTestId(testID, 'search.filter')}
        />
        <ScrollView style={Style.content}>
          {/* Recent Picker */}
          {recentSearchFC.enabled &&
            filteredRecentPickerPassenger.length > 0 &&
            !searchQuery.current && (
              <FlatList
                data={filteredRecentPickerPassenger}
                style={Style.entryList}
                scrollEnabled={false}
                keyExtractor={item => item.email}
                ItemSeparatorComponent={() => <Divider margin="none" />}
                ListHeaderComponent={() => (
                  <View style={Style.header}>
                    <Text
                      variant="ui-small"
                      testID={appendTestId(testID, 'search.recent.header')}
                      style={Token.typography.weightMedium}
                    >
                      {content.recentlySelected}
                    </Text>
                  </View>
                )}
                extraData={filteredRecentPickerPassenger.length}
                renderItem={({ item, index }) => {
                  const totalSelectedNonEmployee = isNonEmployeeChecked
                    ? nonEmployeeTravelers
                      ? nonEmployeeTravelers
                          .filter(
                            ne =>
                              ne.type === NonEmployeeType.ADULT ||
                              ne.type === NonEmployeeType.CHILD
                          )
                          .map(ne =>
                            typeof ne.value === 'number'
                              ? ne.value
                              : ne.value.length
                          )
                          .reduce((a, b) => a + b)
                      : 0
                    : 0;
                  const disabled =
                    !travelerMap[item.email] &&
                    maximumTraveler <=
                      travelers.length + totalSelectedNonEmployee;

                  const checkBoxDisabled =
                    disabled ||
                    (tripRequestEnabled &&
                      travelers.length !== 0 &&
                      travelers[0].email === item.email) ||
                    pickerDesign === 'ne';

                  return (
                    <View style={Style.entry}>
                      <Checkbox
                        checked={travelerMap[item.email] || false}
                        onChange={() => onTravelerChange(item)}
                        testID={appendTestId(
                          testID,
                          `search.recent.list.${index}`
                        )}
                        disabled={checkBoxDisabled}
                      >
                        <Checkbox.Control />
                        <TravelerPickerEntry
                          traveler={item}
                          style={Style.entrySummary}
                          disabled={checkBoxDisabled}
                          testID={appendTestId(
                            testID,
                            `search.recent.list.entry.${index}`
                          )}
                        />
                      </Checkbox>
                    </View>
                  );
                }}
              />
            )}
          {/* All Employees */}
          <FlatList
            data={entryList.entries}
            style={Style.entryList}
            scrollEnabled={false}
            keyExtractor={item => item.email}
            ItemSeparatorComponent={() => <Divider margin="none" />}
            ListEmptyComponent={() => (
              <Text variant="ui-tiny" ink="secondary" style={Style.empty}>
                {content.emptyEmployeeList}
              </Text>
            )}
            ListHeaderComponent={() => (
              <View style={Style.header}>
                <Text
                  variant="ui-small"
                  testID={appendTestId(testID, 'search.count')}
                  style={Token.typography.weightMedium}
                >
                  {formatMessage(content.allEmployee, {
                    showEntries: entryList.entries.length,
                    totalEntries: entryList.totalEntries,
                  })}
                </Text>
              </View>
            )}
            ListFooterComponent={
              showAddEmployee ? (
                <View style={Style.entry}>
                  <Button
                    testID={appendTestId(testID, 'add-new-passenger-button')}
                    variant="secondary"
                    onPress={() => setHasNewEmployee(true)}
                    text={content.addNewEmployeeItem}
                    disabled={pickerDesign === 'ne'}
                  />
                </View>
              ) : null
            }
            extraData={travelers.length}
            renderItem={({ item, index }) => {
              const totalSelectedNonEmployee = isNonEmployeeChecked
                ? nonEmployeeTravelers
                  ? nonEmployeeTravelers
                      .filter(
                        ne =>
                          ne.type === NonEmployeeType.ADULT ||
                          ne.type === NonEmployeeType.CHILD
                      )
                      .map(ne =>
                        typeof ne.value === 'number'
                          ? ne.value
                          : ne.value.length
                      )
                      .reduce((a, b) => a + b)
                  : 0
                : 0;
              const disabled =
                !travelerMap[item.email] &&
                maximumTraveler <= travelers.length + totalSelectedNonEmployee;

              const checkBoxDisabled =
                disabled ||
                (tripRequestEnabled &&
                  travelers.length !== 0 &&
                  travelers[0].email === item.email) ||
                pickerDesign === 'ne';

              return (
                <View style={Style.entry}>
                  <Checkbox
                    checked={travelerMap[item.email] || false}
                    onChange={() => onTravelerChange(item)}
                    disabled={checkBoxDisabled}
                    testID={appendTestId(testID, `search.list.${index}`)}
                  >
                    <Checkbox.Control />
                    <TravelerPickerEntry
                      traveler={item}
                      style={Style.entrySummary}
                      disabled={checkBoxDisabled}
                      testID={appendTestId(
                        testID,
                        `search.list.entry.${index}`
                      )}
                    />
                  </Checkbox>
                </View>
              );
            }}
          />
        </ScrollView>
      </View>
      {mayCreateUser && (
        <CompanyEmployeeUpsertModal
          testIDPrefix="traveler-picker"
          visible={hasNewEmployee}
          onCloseModal={() => setHasNewEmployee(false)}
          onSubmitSuccess={handleNewEmployeeOnSuccess}
          employee={{ fullname: searchQuery.current }}
        />
      )}
    </>
  );
}

const Style = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    height: 411,
    marginTop: Token.spacing.s,
  },
  noPermission: {
    width: 330 + Token.spacing.xs,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Token.opacity.opaque(Token.color.uiLightSecondary),
    position: 'absolute',
    zIndex: 2,
    marginLeft: -Token.spacing.xxs,
  },
  filter: {
    marginTop: Token.spacing.xs,
  },
  entryList: {
    paddingRight: Token.spacing.m,
  },
  header: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.s,
    backgroundColor: Token.color.uiLightStain,
  },
  empty: {
    padding: Token.spacing.l,
    textAlign: 'center',
  },
  entry: {
    paddingVertical: Token.spacing.m,
  },
  entrySummary: {
    marginLeft: Token.spacing.s,
  },
});

import React, { useState, useMemo } from 'react';
import { StyleSheet, View, TouchableOpacity, Easing } from 'react-native';
import { animated, useTransition } from 'react-spring/native';

import { Token, Icon } from '@traveloka/web-components';

import IcSystemChevronLeft from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_left_24px.svg';
import IcSystemChevronRight from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_right_24px.svg';

const AnimatedView = animated<any>(View);

type PageStateType = {
  pageIndex: number;
  direction: 'left' | 'right';
};

type CarouselProps<T> = {
  lists: T[];
  column: number;
  containerHeight: number;
  renderItem: (renderItemProps: { item: T; index: number }) => void;
};

function Carousel<T>(props: CarouselProps<T>) {
  const { lists, column, containerHeight, renderItem } = props;
  const [pageState, setPageState] = useState<PageStateType>({
    pageIndex: 0,
    direction: 'right',
  });

  const numOfItems = lists.length;
  const numberOfPages = Math.ceil(numOfItems / column);

  const listsDisplay = useMemo(() => {
    const currentlyVisible = [];
    if (pageState.pageIndex === 0) {
      const endIndex = numberOfPages > 1 ? column : numOfItems;
      for (let i = 0; i < endIndex; i++) {
        currentlyVisible.push(lists[i]);
      }
    } else {
      const startingIdx = pageState.pageIndex * column;
      for (
        let i = startingIdx;
        i < Math.min(startingIdx + column, numOfItems);
        i++
      ) {
        currentlyVisible.push(lists[i]);
      }
    }
    return currentlyVisible;
  }, [pageState.pageIndex, lists, numberOfPages, numOfItems]);

  const showLeftBtn = pageState.pageIndex > 0;
  const showRightBtn = pageState.pageIndex < numberOfPages - 1;

  const flightTransitions = useTransition<any, any>(
    listsDisplay.reduce((p, c) => `${p}-${JSON.stringify(c)}`, ''),
    null,
    {
      initial: { translateX: '0' },
      from: () => ({
        translate: pageState.direction === 'left' ? '-100%' : '100%',
      }),
      enter: { translate: '0%' },
      leave: () => ({
        translate: pageState.direction === 'left' ? '100%' : '-100%',
      }),
      easing: Easing.bezier,
    }
  );

  return (
    <View style={Style.wrapper}>
      {showLeftBtn && (
        <TouchableOpacity
          style={[Style.button, Style.leftButton]}
          onPress={() =>
            setPageState(oldState => ({
              ...oldState,
              pageIndex: oldState.pageIndex - 1,
              direction: 'left',
            }))
          }
        >
          <Icon src={IcSystemChevronLeft} width={16} />
        </TouchableOpacity>
      )}
      <View style={[Style.rowContainer, { height: containerHeight }]}>
        {flightTransitions.map(
          ({ props: animatedStyle }, flightTransitionIdx) => (
            <AnimatedView
              key={flightTransitionIdx}
              style={[
                Style.content,
                Style.contentGrid,
                showLeftBtn
                  ? Style.contentLeftActionSpacing
                  : Style.contentLeftSpacing,
                showRightBtn
                  ? Style.contentRightActionSpacing
                  : Style.contentRightSpacing,
                {
                  gridTemplateColumns: `repeat(${column}, minmax(0, 1fr))`,
                  transform: [{ translateX: animatedStyle.translate }],
                },
              ]}
            >
              {listsDisplay.map((item, itemIdx) =>
                renderItem({ item, index: itemIdx })
              )}
            </AnimatedView>
          )
        )}
      </View>
      {showRightBtn && (
        <TouchableOpacity
          style={[Style.button, Style.rightButton]}
          onPress={() =>
            setPageState(oldState => ({
              ...oldState,
              pageIndex: oldState.pageIndex + 1,
              direction: 'right',
            }))
          }
        >
          <Icon src={IcSystemChevronRight} width={16} />
        </TouchableOpacity>
      )}
    </View>
  );
}

type CarouselLoaderProps = {
  column: number;
  containerHeight: number;
  renderItem: (renderItemProps: { index: number }) => void;
};

export function CarouselLoader(props: CarouselLoaderProps) {
  const { containerHeight, column, renderItem } = props;

  return (
    <View
      style={[
        Style.contentLeftSpacing,
        Style.contentRightActionSpacing,
        Style.rowContainer,
        { height: containerHeight },
      ]}
    >
      <View
        style={[
          Style.contentGrid,
          // @ts-ignore
          { gridTemplateColumns: `repeat(${column}, minmax(0, 1fr))` },
        ]}
      >
        {Array.from(Array(column).keys()).map((_, itemIdx) =>
          renderItem({ index: itemIdx })
        )}
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    width: '100%',
    position: 'absolute',
  },
  contentGrid: {
    paddingVertical: Token.spacing.s,
    // @ts-ignore
    display: 'grid',
    gridColumnGap: Token.spacing.xs,
  },
  contentLeftSpacing: {
    paddingLeft: Token.spacing.xs,
  },
  contentRightSpacing: {
    paddingRight: Token.spacing.xs,
  },
  contentLeftActionSpacing: {
    paddingLeft: Token.spacing.m,
  },
  contentRightActionSpacing: {
    paddingRight: Token.spacing.m,
  },
  rowContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  button: {
    ...Token.elevation.float,
    position: 'absolute',
    backgroundColor: Token.color.uiLightPrimary,
    borderRadius: 25,
    padding: Token.spacing.xs,
    zIndex: 2,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  leftButton: {
    left: 0,
  },
  rightButton: {
    right: 0,
  },
});

export default Carousel;

import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Button, Card, Icon, Text, Token } from '@traveloka/web-components';
import ChevronLeft from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_left_16px.svg';

import { useHotelRequestRefundNavigator } from 'refund/request-refund/contexts/hotel/HotelRequestRefundNavigatorContext';

import RefundHotelDetail from './RefundHotelDetail/RefundHotelDetail';
import SelectMainReason from './SelectMainReason/SelectMainReason';
import HotelGuests from './HotelGuests/HotelGuests';
import { useHotelRequestRefundForm } from 'refund/request-refund/contexts/hotel/HotelRequestRefundFormContext';
import { useProductRequestRefundPage } from 'refund/request-refund/contexts/ProductRequestRefundPageContext';
import { HotelMainRefundReasonWithAdditionalInfo } from 'refund/shared/api/types';
import {
  validateRefundReason,
  validateTripId,
} from 'refund/shared/utils/request-refund-validation';
import { checkMainReasonError } from 'refund/shared/utils/request-refund-utils';

export default function HotelRequestRefundSelectDetails() {
  const [{ error }] = useProductRequestRefundPage();
  const { goToNextStep, goToPreviousStep } = useHotelRequestRefundNavigator();
  const { mainRefundReason } = useHotelRequestRefundForm();

  const mainReasonError = checkMainReasonError(error);

  const content = useContentResource().CorporateHotelRequestRefundSelectDetails;

  const checkRefundReason = useMemo(() => {
    const { mainReason, additionalInformation } = mainRefundReason!;
    switch (mainReason) {
      case HotelMainRefundReasonWithAdditionalInfo.DOUBLE_BOOKING:
        return (
          validateTripId(additionalInformation) ||
          !additionalInformation!.length
        );
      case HotelMainRefundReasonWithAdditionalInfo.OTHERS:
        return (
          validateRefundReason(additionalInformation) ||
          !additionalInformation!.length
        );
      default:
        return false;
    }
  }, [mainRefundReason]);

  return (
    <>
      <Button
        style={Style.buttonBack}
        size="small"
        variant="text"
        text={content.backButtonText}
        iconStart={() => <Icon src={ChevronLeft} />}
        onPress={goToPreviousStep}
      />
      <Text style={Style.headline} variant="headline">
        {content.refundFormHeadline}
      </Text>
      <Card style={Style.hotelDetail}>
        <RefundHotelDetail />
      </Card>
      <Text style={Style.title} variant="title-1">
        {content.selectMainReasonTitle}
      </Text>
      <Card
        style={[
          Style.mainReasonContainer,
          mainReasonError && Style.errorBorder,
        ]}
      >
        <SelectMainReason />
      </Card>
      <Text style={Style.title} variant="title-1">
        {content.guestTitle}
      </Text>
      <Card style={Style.guestContainer}>
        <HotelGuests />
      </Card>
      <Button
        style={Style.buttonContinue}
        variant="main-cta"
        text={content.continueButtonText}
        disabled={checkRefundReason}
        onPress={() => {
          goToNextStep();
          window.scrollTo(0, 0);
        }}
      />
    </>
  );
}

const Style = StyleSheet.create({
  buttonBack: {
    marginBottom: Token.spacing.m,
    width: 80,
  },
  headline: {
    marginBottom: Token.spacing.xl,
  },
  title: {
    marginBottom: Token.spacing.m,
  },
  hotelDetail: {
    marginBottom: Token.spacing.xl,
  },
  mainReasonContainer: {
    paddingTop: Token.spacing.ml,
    paddingBottom: Token.spacing.l,
    marginBottom: Token.spacing.xxl,
    overflow: 'visible',
    zIndex: 2,
  },
  guestContainer: {
    paddingHorizontal: Token.spacing.l,
    paddingTop: Token.spacing.m,
    paddingBottom: Token.spacing.l,
    marginBottom: Token.spacing.ml,
  },
  shouldRefundAllPaxText: {
    marginLeft: Token.spacing.m,
  },
  buttonContinue: {
    marginLeft: 'auto',
    width: 304,
  },
  errorBorder: {
    borderColor: Token.color.uiRedPrimary,
    borderWidth: Token.border.width.thick,
  },
});

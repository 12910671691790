import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { useParams } from 'react-router';

import { format } from 'date-fns/esm';
import { uniq } from 'lodash';

import {
  useApi,
  useContentResource,
  useFeatureControl,
} from '@traveloka/ctv-core';
import {
  Button,
  LoadingIndicator,
  Text,
  Token,
} from '@traveloka/web-components';

import AccountSuspendedPopup from 'account-status/components/AccountSuspendedPopup/AccountSuspendedPopup';
import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import {
  CREATE_PAYMENT_APPROVAL_API,
  CreatePaymentApprovalRequest,
} from 'approval-system/shared/api';
import { PrebookStatus } from 'approval-system/shared/api/types';
import ProductType from 'approval-system/shared/constants/ProductType';
import { PartialEmployee } from 'company/types';
import {
  FlightPrebookProvider,
  useAddOnsContext,
  useFlightsContext,
  useJourneyTypeContext,
  usePassengersContext,
  usePaymentApprovalContext,
  useSearchIdContext,
} from 'flight/prebook/contexts/FlightPrebookContext';
import {
  Item,
  PriceProvider,
  usePrice,
} from 'flight/prebook/contexts/PriceContext';
import {
  FlightNonEmployeeForm,
  PaymentApproval,
  PrebookForm,
} from 'flight/prebook/types';
import { generatePrebookSpec } from 'flight/prebook/utils/prebookSpecUtil';
import store from 'flight/prebook/utils/store';
import {
  AddOnsResponse,
  BookingRulesResponse,
  RefundPolicyResponse,
} from 'flight/shared/api';
import { Profile } from 'profile/types';
import TwoColumn from 'shared/components/Layout/TwoColumn';
import useEagerNavigation from 'shared/hooks/useEagerNavigation';
import { convertToJava } from 'shared/utils/currency';
import { JAVA_DATE } from 'shared/utils/date';
import { formatMessage } from 'shared/utils/intl';

import { Style as SharedStyle } from '../../flight-prebook.style';
import AdditionalForm from '../AdditionalForm/AdditionalForm';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import ContactForm from '../ContactForm/ContactForm';
import FlightPrebookExpired from '../FlightPrebookExpired/FlightPrebookExpired';
import NonEmployeeTravelerForm from '../NonEmployeeTravelerForm/NonEmployeeTravelerForm';
import PaymentApprovalForm from '../PaymentApprovalForm/PaymentApprovalForm';
import PriceBreakdown from '../PriceBreakdown/PriceBreakdown';
import ProductSummary from '../ProductSummary/ProductSummary';
import TravellerForm from '../TravellerForm/TravellerForm';

type Props = {
  addOns: AddOnsResponse['flights'];
  bookingRule: BookingRulesResponse;
  isLoading: boolean;
  passengers: PartialEmployee[];
  nonEmployeeTravelers: FlightNonEmployeeForm[];
  policies: RefundPolicyResponse['flights'];
  profile: Profile;
  paymentApproval: PaymentApproval;
};

export default function FlightPrebookContent(props: Props) {
  const params = useParams<{ storage: string }>();

  const prebook = useMemo(() => store.get(params.storage), [params.storage]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const {
    addOns,
    bookingRule,
    isLoading,
    passengers,
    nonEmployeeTravelers,
    policies,
    profile,
    paymentApproval,
  } = props;

  const content = useContentResource().CorporateFlightPrebook;

  const isInternational = useMemo(() => {
    if (!prebook) {
      return false;
    }

    const countryCodes = prebook.flights.reduce((airportCodes, flight) => {
      return airportCodes.concat(
        ...flight.segments.map(segment => [
          segment.departureAirport.countryCode,
          segment.arrivalAirport.countryCode,
        ])
      );
    }, [] as string[]);

    return uniq(countryCodes).length > 1;
  }, [prebook]);

  const priceItems = useMemo(() => {
    if (!prebook || !(passengers.length + nonEmployeeTravelers.length)) {
      return {};
    }

    return prebook.flights.reduce((obj, { summary }) => {
      const id = `${summary.departureAirport.airportCode}-${summary.arrivalAirport.airportCode}`;

      obj[id] = {
        value: summary.totalPrice,
        label: formatMessage(content.priceBreakdownInfoText, {
          departureAirportCity: summary.departureAirport.city,
          arrivalAirportCity: summary.arrivalAirport.city,
          passengersLength: passengers.length + nonEmployeeTravelers.length,
        }),
      };
      return obj;
    }, {} as Dictionary<Item>);
  }, [
    prebook,
    passengers.length,
    nonEmployeeTravelers.length,
    content.priceBreakdownInfoText,
  ]);

  if (!prebook) {
    return <FlightPrebookExpired />;
  }

  if (isLoading) {
    return (
      <View style={Style.loadingContainer}>
        <LoadingIndicator style={Style.loading} />
        <Text>{content.loadingText}</Text>
      </View>
    );
  }

  return (
    <FlightPrebookProvider
      searchId={prebook.searchId}
      searchSpec={prebook.searchSpec}
      flights={prebook.flights}
      addOns={addOns}
      policies={policies}
      bookingRule={bookingRule}
      isInternational={isInternational}
      journeyType={prebook.journeyType}
      passengers={passengers}
      nonEmployeeTravelers={nonEmployeeTravelers}
      profile={profile}
      paymentApproval={paymentApproval}
    >
      <PriceProvider defaultItems={priceItems}>
        <PrebookFormComponent
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
        />
      </PriceProvider>
    </FlightPrebookProvider>
  );
}

type PrebookFormProps = {
  showConfirmation: boolean;
  setShowConfirmation: (value: boolean) => void;
};

function PrebookFormComponent(props: PrebookFormProps) {
  const { showConfirmation, setShowConfirmation } = props;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const content = useContentResource().CorporateFlightPrebook;
  const travelerFormContent = useContentResource()
    .CorporateFlightPrebookTravelerForm;

  const {
    isFetching: isAccountStatusFetching,
    fetchAccountStatus,
  } = useAccountStatus();
  const searchId = useSearchIdContext();
  const flights = useFlightsContext();
  const journeyType = useJourneyTypeContext();
  const passengers = usePassengersContext();
  const addOns = useAddOnsContext();
  const paymentApproval = usePaymentApprovalContext();
  const { calculatedFee, total } = usePrice();
  const methods = useForm<PrebookForm>({ mode: 'onTouched' });
  const { handleSubmit: formSubmit } = methods;

  const navigate = useEagerNavigation();
  const { enabled: documentTypeFc } = useFeatureControl(
    'b2b-document-type-new-behavior'
  );

  const submitPaymentApproval = useApi<unknown, CreatePaymentApprovalRequest>({
    domain: 'management',
    method: 'post',
    path: CREATE_PAYMENT_APPROVAL_API,
  });

  function createPaymentApproval(values: PrebookForm) {
    const prebookSpec = generatePrebookSpec(
      values,
      searchId,
      flights,
      journeyType,
      passengers,
      addOns,
      calculatedFee || { totalFareFromClient: convertToJava(total) },
      documentTypeFc,
      paymentApproval
    );

    const flightJourneys = flights.map(({ summary }) => ({
      airlineCode: summary.airlineCode,
      arrivalDate: format(new Date(summary.arrivalDate), JAVA_DATE),
      arrivalAirportCode: summary.arrivalAirport.airportCode,
      arrivalTime: summary.arrivalTime,
      departureDate: format(new Date(summary.departureDate), JAVA_DATE),
      departureAirportCode: summary.departureAirport.airportCode,
      departureTime: summary.departureTime,
      duration: summary.duration.toString(),
      seatClass: summary.seatClass,
      transit: summary.transit.toString(),
    }));

    const payload: CreatePaymentApprovalRequest = {
      productRequestId: paymentApproval.productRequestId!,
      productType: ProductType.FLIGHT,
      reason: values.paymentApprovalReason!,
      attachmentUrl: values.attachment?.url,
      flightApprovalRequest: {
        flightBookingSubmitAPIRequest: prebookSpec,
        flightJourneys,
        price: convertToJava(total),
      },
      ...calculatedFee,
    };

    submitPaymentApproval(payload)
      .then(res => {
        if (res.success) {
          navigate({ pathname: `/approval/${paymentApproval.tripRequestId}` });
        }
      })
      .finally(() => setIsSubmitting(false));
  }

  function handleSubmit(values: PrebookForm) {
    if (hasPaymentApproval) {
      setIsSubmitting(true);
      createPaymentApproval(values);
      return;
    }
    setShowConfirmation(true);
  }

  const hasPaymentApproval =
    paymentApproval.preBookingStatus === PrebookStatus.NEED_APPROVAL;

  return (
    <FormProvider {...methods}>
      <Text variant="title-1" style={Style.title}>
        {content.pageTitle}
      </Text>
      <Text ink="secondary" style={Style.subtitle}>
        {content.pageSubtitle}
      </Text>
      <TwoColumn>
        <TwoColumn.Big>
          <ContactForm />
          {!!passengers.length && (
            <View style={Style.travelerForm}>
              <Text variant="title-1" style={SharedStyle.sectionTitle}>
                {travelerFormContent.title}
              </Text>
              {passengers.map((_, index) => (
                <TravellerForm key={index} index={index} />
              ))}
            </View>
          )}
          <NonEmployeeTravelerForm />
          <PriceBreakdown />
          <AdditionalForm />
          <PaymentApprovalForm />
          <View style={[SharedStyle.section, Style.continue]}>
            <Button
              testID="pre-book.submit"
              variant="main-cta"
              text={
                hasPaymentApproval
                  ? content.createPaymentApprovalButtonText
                  : content.submitPrebookButtonText
              }
              onPress={() => fetchAccountStatus(formSubmit(handleSubmit))}
              loading={isAccountStatusFetching || isSubmitting}
              disabled={!paymentApproval.preBookingStatus}
            />
          </View>
        </TwoColumn.Big>
        <TwoColumn.Small>
          <ProductSummary />
        </TwoColumn.Small>
      </TwoColumn>
      <ConfirmationModal
        isVisible={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      />
      <AccountSuspendedPopup backToHome />
    </FormProvider>
  );
}

const Style = StyleSheet.create({
  loadingContainer: {
    alignItems: 'center',
  },
  loading: {
    marginBottom: Token.spacing.xs,
  },
  title: {
    marginBottom: Token.spacing.s,
  },
  subtitle: {
    marginBottom: Token.spacing.l,
  },
  continue: {
    alignItems: 'flex-end',
  },
  travelerForm: {
    zIndex: 2,
  },
});

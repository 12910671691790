import { JavaCurrencyValue } from 'shared/utils/currency';

import AccomodationType from '../constants/AccomodationType';
import ImageType from '../constants/ImageType';

export type AutocompleteItem = {
  id: string;
  type: string;
  name: string;
  location: string;
  score: string;
  accommodationType: Nullable<string>;
  numOfHotels: string;
};

export type Property = {
  propertyId: string;
  name: string;
  regionCity: string;
  starRating: string;
  reviewScore: Nullable<string>;
  accommodationType: AccomodationType;
  geoLocation: GeoLocation;
  image: Nullable<string>;
  highRate: JavaCurrencyValue;
  surchargeTotal: JavaCurrencyValue;
  totalFare: JavaCurrencyValue;
  isComplying: boolean;
  badges: Badge[];
};

export type Badge = {
  name: HotelBadge;
};

export enum HotelBadge {
  HOTEL_CLEAN_ACCOMMODATION = 'HOTEL_CLEAN_ACCOMMODATION',
}

export type Room = {
  roomId: string;
  rateKey: string;
  roomName: string;
  roomTypeName: Nullable<string>;
  bedType: string;
  roomOccupancy: string;
  promos: string[];
  images: Image[];
  remainingAllotment: string;
  amenities: Facility[];
  highRate: JavaCurrencyValue;
  surchargeTotal: JavaCurrencyValue;
  totalFare: JavaCurrencyValue;
  isFreeCancelation: boolean;
  isRefundable: boolean;
  refundPolicy: string;
  isBreakfastIncluded: Nullable<boolean>;
  isWifiIncluded: Nullable<boolean>;
  roomSize: string;
  isComplying: boolean;
};

export type Image = {
  type: ImageType;
  url: string;
};

export type Facility = {
  type: string;
  name: string;
};

export type GeoLocation = {
  lat: string;
  lon: string;
};

// Non-employee
// For Response API only
export type HotelNonEmployeeTravelerResponseAPI = {
  adults: string;
  childrenAges: string[];
};

// Type used, after data type string -> number conversion
export type HotelNonEmployeeTraveler = {
  adults: number;
  childrenAges: string[];
};

export enum NonEmployeeType {
  ADULT = 'ADULT',
  CHILD_AGE = 'CHILD_AGE',
}

export enum NonEmployeeTypeSimplified {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
}

export type HotelNonEmployeeDetail = {
  adults?: Array<{
    title: string;
    fullName: string;
    email?: string;
  }>;
  children?: Array<{
    title: string;
    fullName: string;
    age: string;
  }>;
};

export type HotelNonEmployeeForm = {
  type: NonEmployeeType;
  title: string;
  fullName: string;
  email?: string;
  age?: string;
};

import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { useApi } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import {
  Popup,
  LoadingIndicator,
  Text,
  Token,
} from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import {
  CheckSearchSpecResponse,
  CheckSearchSpecRequest,
  CHECK_SEARCH_SPEC_API,
} from 'approval-system/shared/api';
import {
  PrebookStatus,
  NonMatchReason,
} from 'approval-system/shared/api/types';
import InsufficientCreditLimitModal from 'credit-limit/components/InsufficientCreditLimitModal/InsufficientCreditLimitModal';
import prebookStore, { FlightPrebookStore } from 'flight/prebook/utils/store';
import useEagerNavigation from 'shared/hooks/useEagerNavigation';

import FlightPaymentApprovalModal from '../FlightPaymentApprovalModal/FlightPaymentApprovalModal';
import { generateSearchSpecPayload } from 'flight/search/utils/searchSpecCheckUtil';

type Props = {
  isVisible: boolean;
  onClose: () => void;
} & Omit<FlightPrebookStore, 'approverReason'>;

type ConfirmationModalData = {
  isCheckModalVisible: boolean;
  isCreditLimitModalVisible: boolean;
  isNonCompliance: boolean;
  isNotMatch: boolean;
  nonMatchReasons: NonMatchReason[];
};

const initPCMData: ConfirmationModalData = {
  isCheckModalVisible: false,
  isCreditLimitModalVisible: false,
  isNonCompliance: false,
  isNotMatch: false,
  nonMatchReasons: [],
};

export default function FlightCheckBookingModal(props: Props) {
  const { isVisible, onClose, ...prebookData } = props;
  const [confirmationModalData, setConfirmationModalData] = useState<
    ConfirmationModalData
  >(initPCMData);

  const content = useContentResource()
    .CorporateApprovalSystemProductConfirmation;
  const navigate = useEagerNavigation();

  const checkSearchSpec = useApi<
    CheckSearchSpecResponse,
    CheckSearchSpecRequest
  >({
    domain: 'booking',
    method: 'post',
    path: CHECK_SEARCH_SPEC_API,
  });

  function goToPrebookPage(approverReason?: string) {
    const key = prebookStore.set({
      ...prebookData,
      approverReason,
    });

    let pathname = `/flight/prebook/${key}`;

    navigate({ pathname });
  }

  async function showCreditLimitModal() {
    setConfirmationModalData(prevValue => ({
      ...prevValue,
      isCreditLimitModalVisible: true,
    }));
  }

  async function showPaymentApprovalModal(
    complyWithTravelPolicy: boolean,
    matchWithTripRequest: boolean,
    nonMatchReasons: NonMatchReason[]
  ) {
    setConfirmationModalData({
      isCheckModalVisible: true,
      isCreditLimitModalVisible: false,
      isNonCompliance: !complyWithTravelPolicy,
      isNotMatch: !matchWithTripRequest,
      nonMatchReasons,
    });
  }

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    const payload = generateSearchSpecPayload(prebookData);

    checkSearchSpec(payload)
      .then(res => {
        if (res.success) {
          const {
            preBookingStatus,
            complyWithTravelPolicy,
            matchWithTripRequest,
            creditLimitSufficient,
            nonMatchReasons,
          } = res.data;
          if (
            // Any Continue status will be treated as OK to Prebook Page
            preBookingStatus === PrebookStatus.CONTINUE ||
            // No compliance and not match, will go to prebook directly
            // Passing pre booking status to let the prebook page decide which state is being used.
            (complyWithTravelPolicy && matchWithTripRequest)
          ) {
            goToPrebookPage();
          } else if (!creditLimitSufficient) {
            showCreditLimitModal();
          } else {
            showPaymentApprovalModal(
              complyWithTravelPolicy,
              matchWithTripRequest,
              nonMatchReasons
            );
          }
        }
      })
      .finally(() => onClose());
  }, [isVisible]);

  return (
    <>
      <Modal isVisible={isVisible}>
        <Popup showCloseButton={false} width={550} height={150} minHeight={150}>
          <View testID="flight.check-booking.modal" style={Style.content}>
            <Text style={Style.text} variant="ui-baseline">
              {content.loadingText}
            </Text>
            <LoadingIndicator />
          </View>
        </Popup>
      </Modal>
      <FlightPaymentApprovalModal
        nonMatchReasons={confirmationModalData.nonMatchReasons}
        isNonCompliance={confirmationModalData.isNonCompliance}
        isNotMatch={confirmationModalData.isNotMatch}
        isVisible={confirmationModalData.isCheckModalVisible}
        onClose={() => setConfirmationModalData(initPCMData)}
        onContinue={goToPrebookPage}
      />
      <InsufficientCreditLimitModal
        isVisible={confirmationModalData.isCreditLimitModalVisible}
      />
    </>
  );
}

const Style = StyleSheet.create({
  content: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginBottom: Token.spacing.s,
  },
});

import React, { useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  useContentResource,
  useLocale,
  useLocaleDispatcher,
  Locale,
  useAuth,
} from '@traveloka/ctv-core';
import { Dropdown, MenuItem } from '@traveloka/ctvweb-ui';
import { Handler } from '@traveloka/ctvweb-ui/src/shared/components/Dropdown/Dropdown';
import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import FlagEN from '@traveloka/icon-kit-web/svg/blue/ic_flag_circular_en_24px.svg';
import FlagID from '@traveloka/icon-kit-web/svg/blue/ic_flag_circular_id_24px.svg';
import FlagTH from '@traveloka/icon-kit-web/svg/blue/ic_flag_circular_th_24px.svg';
import FlagVN from '@traveloka/icon-kit-web/svg/blue/ic_flag_circular_vn_24px.svg';
import CheckMark from '@traveloka/icon-kit-web/svg/blue/ic_system_checkmark_24px.svg';
import { Button, Icon, Text, Token } from '@traveloka/web-components';

type Props = {
  testID?: string;
};

export default function LanguageMenu(props: Props) {
  const { testID } = props;
  const languageDropdownRef = useRef<Handler>(null!);

  const { locale } = useLocale();
  const { user } = useAuth();

  const [current, setCurrent] = useState<Locale>(locale);
  const { changeLanguage } = useLocaleDispatcher();

  const content = useContentResource().CorporateLanguage;

  const menusEN = (locale: Locale) => ({
    text: content.englishText,
    name: locale,
    leftIcon: FlagEN,
    leftIconActive: FlagEN,
    onPress() {
      setCurrent(locale);
    },
  });

  const menusID = {
    text: content.indonesiaText,
    name: Locale.IDID,
    leftIcon: FlagID,
    leftIconActive: FlagID,
    onPress() {
      setCurrent(Locale.IDID);
    },
  };

  const menusTH = {
    text: content.thailandText,
    name: Locale.THTH,
    leftIcon: FlagTH,
    leftIconActive: FlagTH,
    onPress() {
      setCurrent(Locale.THTH);
    },
  };

  const menusVN = {
    text: content.vietnamText,
    name: Locale.VNVN,
    leftIcon: FlagVN,
    leftIconActive: FlagVN,
    onPress() {
      setCurrent(Locale.VNVN);
    },
  };

  function getMenus(country?: string) {
    switch (country) {
      case 'TH':
        return [menusEN(Locale.ENTH), menusTH];
      case 'VN':
        return [menusEN(Locale.ENVN), menusVN];
      case 'ID':
        return [menusEN(Locale.ENID), menusID];
      default:
        return [menusEN(Locale.ENID), menusID, menusTH, menusVN];
    }
  }

  function selectLanguage() {
    changeLanguage(current);
    languageDropdownRef.current?.setIsCollapsed(true);
  }

  return (
    <Dropdown
      ref={languageDropdownRef}
      alignment="right"
      trigger={
        <Button
          testID="header.language"
          variant="text-black"
          text={languageSwitcher(locale).text}
          iconStart={() => <Icon src={languageSwitcher(locale).icon} />}
          style={Style.languageButton}
          innerStyle={Style.innerLanguageButton}
        />
      }
    >
      <View style={Style.dropdownLanguageMenu}>
        <Text style={Style.header}>{content.title}</Text>
        {getMenus(user?.country).map(menu => (
          <MenuItem
            {...menu}
            testID={appendTestId(testID, `menu.language.${menu.name}`)}
            key={menu.name}
            style={Style.menuItem}
            textStyle={Style.menuText}
            activeStyle={Style.activeMenuItem}
            isActive={menu.name === current}
            rightIcon={''}
            rightIconActive={CheckMark}
          />
        ))}
        <View style={Style.actions}>
          <Button
            style={Style.cancelButton}
            variant="secondary"
            onPress={() => {
              languageDropdownRef.current?.setIsCollapsed(true);
            }}
            text="Cancel"
          />
          <Button onPress={selectLanguage} text="Done" />
        </View>
      </View>
    </Dropdown>
  );
}

function languageSwitcher(locale: Locale) {
  switch (locale) {
    case Locale.IDID:
      return { text: 'ID', icon: FlagID };
    case Locale.THTH:
      return { text: 'TH', icon: FlagTH };
    case Locale.VNVN:
      return { text: 'VN', icon: FlagVN };
    case Locale.ENID:
    case Locale.ENTH:
    case Locale.ENVN:
      return { text: 'EN', icon: FlagEN };
  }
}

const Style = StyleSheet.create({
  languageButton: {
    marginLeft: Token.spacing.s,
    minWidth: 55,
  },
  innerLanguageButton: {
    justifyContent: 'flex-start',
  },
  dropdownLanguageMenu: {
    minWidth: 260,
    padding: Token.spacing.m,
  },
  header: {
    fontWeight: Token.typography.weightMedium.fontWeight,
    letterSpacing: 1,
    paddingBottom: Token.spacing.m,
  },
  menuText: {
    color: Token.color.uiDarkPrimary,
  },
  menuItem: {
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.xxs,
    marginHorizontal: 0,
    marginBottom: Token.spacing.m,
  },
  activeMenuItem: {
    backgroundColor: 'none',
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: Token.spacing.xs,
  },
  cancelButton: {
    marginRight: Token.spacing.m,
  },
});

import React, { useCallback, useMemo, PropsWithChildren } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';

import { useTheme, Token } from '@traveloka/web-components';
import { useHoverable } from '@traveloka/web-components/future';

import { FlightData } from '../types';
import { useDispatch, DetailProvider } from './contexts/DetailContext';
import { PropsProvider } from './contexts/PropsContext';
import FlightDetail from './fragments/FlightDetail';
import FlightSummary from './fragments/FlightSummary';
import { appendTestId } from '../../shared/utils/TestUtil';

export type Props = {
  flight: FlightData;
  compactLayout?: boolean;
  label: {
    priceSuffix: string;
    select: string;
    flightDetail: string;
    fareDetail: string;
    smartComboTag: string;
    smartComboTooltipTitle: string;
    smartComboTooltipDescription: string;
  };
  onRefundInfoClick?: () => void;
  onRescheduleInfoClick?: () => void;
  onSelectResultClick: () => void;
  showButton: boolean;
  loading?: boolean;
  testID?: string;
};

export default function FlightSearchResultItem(props: Props) {
  const { testID } = props;
  const flightSummary = useMemo(
    () => <FlightSummary testID={appendTestId(testID, 'summary')} />,
    [testID]
  );
  const flightDetail = useMemo(
    () => <FlightDetail testID={appendTestId(testID, 'detail')} />,
    [testID]
  );

  return (
    <PropsProvider value={props}>
      <DetailProvider>
        <Wrapper testID={testID}>
          {flightSummary}
          {flightDetail}
        </Wrapper>
      </DetailProvider>
    </PropsProvider>
  );
}

function Wrapper(props: PropsWithChildren<{ testID?: string }>) {
  const { testID, children } = props;
  const { color } = useTheme();
  const dispatch = useDispatch();
  const handlePress = useCallback(() => dispatch('card'), [dispatch]);

  const [isHovered, eventHandlers] = useHoverable();
  const borderStyle = {
    borderColor: isHovered ? color.tintPrimary : color.lightSecondary,
  };

  return (
    <TouchableWithoutFeedback testID={testID} onPress={handlePress}>
      <View {...eventHandlers} style={[Style.container, borderStyle]}>
        {children}
      </View>
    </TouchableWithoutFeedback>
  );
}

const Style = StyleSheet.create({
  container: {
    padding: Token.spacing.l,
    borderWidth: Token.border.width.thick,
    borderRadius: Token.border.radius.normal,
    transitionDuration: `${Token.animation.timing.instant}ms`,
    transitionProperty: 'border-color',
  },
});

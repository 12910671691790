import { useCallback, useEffect, useRef, RefObject } from 'react';
import { findNodeHandle, View } from 'react-native';

export default function useOnClickOutside(
  ref: RefObject<View>,
  handler: Function
) {
  const handlerRef = useRef(handler);
  const handleClickOutside = useCallback((e: MouseEvent) => {
    const reactTag = findNodeHandle(ref.current) as Element | null;

    if (reactTag && !reactTag.contains(e.target as Node)) {
      handlerRef.current();
    }
  }, []);

  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [!handler, handleClickOutside]);
}

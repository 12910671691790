enum AccomodationType {
  HOTEL = 'HOTEL',
  HOSTEL = 'HOSTEL',
  VILLA = 'VILLA',
  RESORT = 'RESORT',
  ALL_INCLUSIVE = 'ALL_INCLUSIVE',
  APARTMENT = 'APARTMENT',
  BED_AND_BREAKFAST = 'BED_AND_BREAKFAST',
  CAMPING = 'CAMPING',
  BOAT = 'BOAT',
  HOMESTAY = 'HOMESTAY',
  GUESTHOUSE = 'GUESTHOUSE',
  RYOKAN = 'RYOKAN',
  RIAD = 'RIAD',
  POUSADA = 'POUSADA',
  OTHER = 'OTHER',
}

export default AccomodationType;

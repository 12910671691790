enum SeatClass {
  ECONOMY = 'ECONOMY',
  PROMO = 'PROMO',
  PREMIUM_ECONOMY = 'PREMIUM_ECONOMY',
  FIRST = 'FIRST',
  BUSINESS = 'BUSINESS',
  OTHERS = 'OTHERS',
  MIXED = 'MIXED',
}

export default SeatClass;

import React, { lazy, Suspense, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, UseFormHandleSubmit } from 'react-hook-form';

import {
  ConfirmationResponse,
  RegistrationRequest,
  RegistrationResponse,
  REGISTRATION_API,
} from 'registration/api';
import { RegistrationStatus } from 'registration/api/types';
import { usePreRegisterConfirm } from 'registration/contexts/PreRegisterConfirmContext';
import { RequiredDocumentProvider } from 'registration/contexts/RequiredDocumentContext';
import { useToken } from 'registration/contexts/TokenContext';
import { RegistrationFormValue } from 'registration/types';
import { DocumentFieldValue } from 'shared/components/form/DocumentField/types';
import { StepHeader } from 'shared/components/Layout';
import { useDispatchSnackbar } from 'shared/contexts/Snackbar/SnackbarContext';
import Snackbar from 'shared/contexts/Snackbar/Snackbar';

import { useApi, useContentResource, useTracker } from '@traveloka/ctv-core';

const RegistrationForm = lazy(() => import('./RegistrationForm'));
const RegistrationOfferingDetails = lazy(() =>
  import('./RegistrationOfferingDetails/RegistrationOfferingDetails')
);
const RegistrationPaymentMethod = lazy(() =>
  import('./RegistrationPaymentMethod/RegistrationPaymentMethod')
);
const RegistrationDocuments = lazy(() =>
  import('./RegistrationDocuments/RegistrationDocuments')
);
const RegistrationReview = lazy(() =>
  import('./RegistrationReview/RegistrationReview')
);

const SUBMIT_STEP = 4;

type Props = {
  onRequestNda(): void;
  onRequestMeeting(): void;
};

export default function RegistrationWizard(props: Props) {
  const { onRequestMeeting, onRequestNda } = props;

  const registrationData = usePreRegisterConfirm();

  const [step, setStep] = useState(
    registrationData?.status === RegistrationStatus.INCOMPLETE_DATA ? 3 : 0
  );
  const prevValues = useRef(
    usePreprocessInitialValues(registrationData) as RegistrationFormValue
  );

  const cr = useContentResource().CorporateRegistration;
  const token = useToken();
  const openSnackbar = useDispatchSnackbar();
  const track = useTracker('ors-page');
  const methods = useForm<RegistrationFormValue>({
    defaultValues: prevValues.current,
  });
  const { handleSubmit: formSubmit } = methods;

  const submit = useApi<RegistrationResponse, RegistrationRequest>({
    domain: 'management',
    method: 'post',
    path: REGISTRATION_API,
  });

  function goBack() {
    setStep(step - 1);
    window.scrollTo({ top: 0 });
  }

  function onContinue() {
    setStep(step + 1);
    window.scrollTo({ top: 0 });
  }

  const steps = [cr.step1V2, cr.step2V2, cr.step3, cr.step4, cr.step5];

  async function handleSubmit(v: RegistrationFormValue) {
    const isSubmit = step === SUBMIT_STEP;

    const documents: RegistrationRequest['documents'] = [];
    const usedDocs: Record<
      string,
      DocumentFieldValue | DocumentFieldValue[]
    > = {
      ...v.document,
      ...(step === 0 ? prevValues.current.document : {}),
    };
    Object.keys(usedDocs).forEach(doc => {
      if (usedDocs[doc]) {
        const v = (!Array.isArray(usedDocs[doc])
          ? [usedDocs[doc]]
          : usedDocs[doc]) as DocumentFieldValue[];

        documents.push(
          ...v.filter(Boolean).map(d => ({
            documentType: doc,
            fileName: d.url,
          }))
        );
      }
    });

    const agreementDocuments: RegistrationRequest['agreementDocuments'] = [];
    const usedAgreementDocs: Record<string, DocumentFieldValue> = {
      ...v.agreementDocument,
      ...(step === 0 ? prevValues.current.agreementDocument : {}),
    };
    Object.keys(usedAgreementDocs).forEach(doc => {
      if (usedAgreementDocs[doc]) {
        agreementDocuments.push({
          documentType: doc,
          fileName: usedAgreementDocs[doc].url,
        });
      }
    });

    const payload: RegistrationRequest = {
      picName: v.picName,
      picPhoneCountryCode: v.phone.prefix,
      picPhone: v.phone.phone,
      picJobTitle: v.picJobTitle,
      corporateName: v.companyName,
      corporateIndustry: v.companyIndustry,
      country: v.country,
      officeAddress: v.officeAddress,
      officeCity: v.officeCity,
      officePostalCode: v.officePostalCode,
      officePhoneCountryCode: v.officePhone.prefix,
      officePhone: v.officePhone.phone,
      website: v.website,
      monthlyTravelSpending: v.monthlyTravelSpending,
      productNeeded: v.productNeeded,
      productRequest: v.productRequest,
      paymentMethod: v.paymentMethod,
      draft: !isSubmit,
      documents,
      preferableScheduledMeetingFirst: v.preferableScheduledMeetingFirst,
      preferableScheduledMeetingSecond: v.preferableScheduledMeetingSecond,
      discussionTopic: v.discussionTopic,
      locationPreference: v.locationPreference,
      ownerIdCard: v.ownerIdCard,
      ownerAddress: v.ownerAddress,
      ownerAddressCity: v.ownerAddressCity,
      ownerPostalCode: v.ownerPostalCode,
      ownerName: v.ownerName,
      ownerJobTitle: v.ownerJobTitle,
      agreementDocuments,
      serviceFee: v.serviceFee,
      termsOfPayment: v.termsOfPayment,
      billingCycle: v.billingCycle,
      creditLimit: v.creditLimit,
      legalNpwpNumber: v.legalNpwpNumber,
      legalSppkpNumber: v.legalSppkpNumber,
      legalName: v.legalName,
      legalFiscalAddress: v.legalFiscalAddress,
      legalFiscalCity: v.legalFiscalCity,
      legalFiscalPostalCode: v.legalFiscalPostalCode,
      legalBillingAddress: v.legalBillingAddress,
      legalBillingCity: v.legalBillingCity,
      legalBillingPostalCode: v.legalBillingPostalCode,
      documentNotes: v.documentNotes,
      creditAssessmentNotes: v.creditAssessmentNotes,
      partnerCompanyName: v.partnerCompanyName,
      partnerCompanyRelation: v.partnerCompanyRelation,
      partnerCompanyPicName: v.partnerCompanyPicName,
      partnerCompanyPicEmail: v.partnerCompanyPicEmail,
      partnerCompanyPhoneCountryCode: v.partnerCompanyPhone.prefix,
      partnerCompanyPhone: v.partnerCompanyPhone.phone,
      otherEmails: v.otherEmails,
      pagePosition: pageMap(step),
      token,
    };

    const res = await submit(payload);

    if (res.success) {
      if (!isSubmit) {
        prevValues.current = v;
        setStep(step + 1);
        window.scrollTo({ top: 0 });
        openSnackbar({ message: cr.submitSuccessMessage, variant: 'normal' });

        const eventValue = trackingMap(step);
        if (eventValue) {
          track('corpB2b.onlineRegistration.webEvent', {
            eventName: 'CORP_B2B.ONLINE_REGISTRATION.WEB_EVENT',
            eventVersion: '1.0.0',
            pageName: 'ORS_PAGE',
            pageCategory: 'ORS_PAGE',
            eventValue,
            emailAddress: v.picEmail,
            corporateName: registrationData?.corporateName,
          }).send();
        }
      } else {
        // Reload page to get new pre-register-confirm
        window.location.reload();
      }
    } else {
      openSnackbar({ message: cr.submitErrorMessage, variant: 'alert' });
    }
  }

  return (
    <>
      <StepHeader current={step} steps={steps} />
      <Snackbar />
      <FormProvider
        {...methods}
        handleSubmit={
          (formSubmit(handleSubmit) as unknown) as UseFormHandleSubmit<
            RegistrationFormValue
          >
        }
      >
        <Suspense fallback={null}>
          {step === 0 && <RegistrationForm />}
          {step === 1 && (
            <RegistrationOfferingDetails
              onBackPress={goBack}
              onContinuePress={onContinue}
              onRequestNda={onRequestNda}
              onRequestMeeting={onRequestMeeting}
            />
          )}
          {step === 2 && <RegistrationPaymentMethod onBackPress={goBack} />}
          {step === 3 && (
            <RequiredDocumentProvider>
              <RegistrationDocuments
                onBackPress={goBack}
                onRequestNda={onRequestNda}
              />
            </RequiredDocumentProvider>
          )}
          {step === SUBMIT_STEP && (
            <RequiredDocumentProvider>
              <RegistrationReview onBackPress={goBack} />
            </RequiredDocumentProvider>
          )}
        </Suspense>
      </FormProvider>
    </>
  );
}

const defaultValue = {
  phone: {
    prefix: '+62',
  },
  officePhone: {
    prefix: '+62',
  },
  partnerCompanyPhone: {
    prefix: '+62',
  },
  serviceFee: '4.0',
};
function usePreprocessInitialValues(values: Nullable<ConfirmationResponse>) {
  return useMemo(() => {
    const {
      corporateIndustry,
      corporateName,
      country,
      documents,
      monthlyTravelSpending,
      officeAddress,
      officeCity,
      officePhone,
      officePhoneCountryCode,
      officePostalCode,
      paymentMethod,
      picEmail,
      picJobTitle,
      picName,
      picPhone,
      picPhoneCountryCode,
      productNeeded,
      productRequest,
      website,
      preferableScheduledMeetingFirst,
      preferableScheduledMeetingSecond,
      discussionTopic,
      locationPreference,
      ownerIdCard,
      ownerAddress,
      ownerAddressCity,
      ownerPostalCode,
      ownerName,
      ownerJobTitle,
      agreementDocuments,
      serviceFee,
      termsOfPayment,
      billingCycle,
      creditLimit,
      legalNpwpNumber,
      legalSppkpNumber,
      legalName,
      legalFiscalAddress,
      legalFiscalCity,
      legalFiscalPostalCode,
      legalBillingAddress,
      legalBillingCity,
      legalBillingPostalCode,
      documentNotes,
      creditAssessmentNotes,
      partnerCompanyName,
      partnerCompanyRelation,
      partnerCompanyPicName,
      partnerCompanyPicEmail,
      partnerCompanyPhone,
      partnerCompanyPhoneCountryCode,
      otherEmails,
    } = values || {};

    // To check if a document file is multiple or not
    // Will need a 2 way checking, because the document requirement API
    // Is on the children level
    const documentMultipleFlag: Record<string, boolean> = {};
    documents?.forEach(doc => {
      if (documentMultipleFlag[doc.documentType] === undefined) {
        documentMultipleFlag[doc.documentType] = false;
      } else {
        documentMultipleFlag[doc.documentType] = true;
      }
    });
    // TODO: Change this to .reduce
    const document: Record<
      string,
      DocumentFieldValue | DocumentFieldValue[]
    > = {};
    documents?.forEach(doc => {
      if (doc.fileNameEncrypted) {
        const documentValue = {
          uploaded: true,
          label: doc.label,
          url: doc.fileNameEncrypted,
          file: new File([], doc.fileName),
        };

        if (documentMultipleFlag[doc.documentType]) {
          if (document[doc.documentType] === undefined) {
            document[doc.documentType] = [documentValue];
          } else {
            (document[doc.documentType] as DocumentFieldValue[]).push(
              documentValue
            );
          }
        } else {
          document[doc.documentType] = documentValue;
        }
      }
    });

    // TODO: Change this to .reduce
    const agreementDocument: Record<string, DocumentFieldValue> = {};
    agreementDocuments?.forEach(doc => {
      if (doc.fileNameEncrypted) {
        agreementDocument[doc.documentType] = {
          uploaded: true,
          label: doc.label,
          url: doc.fileNameEncrypted,
          file: new File([], doc.fileName),
        };
      }
    });

    return {
      companyIndustry: corporateIndustry ?? '',
      companyName: corporateName ?? '',
      country: country ?? '',
      document,
      monthlyTravelSpending: monthlyTravelSpending ?? '',
      officeAddress: officeAddress ?? '',
      officeCity: officeCity ?? '',
      officePhone: {
        phone: officePhone ?? '',
        prefix: officePhoneCountryCode ?? defaultValue.officePhone.prefix,
      },
      officePostalCode: officePostalCode ?? '',
      paymentMethod: paymentMethod ?? '',
      phone: {
        phone: picPhone ?? '',
        prefix: picPhoneCountryCode ?? defaultValue.phone.prefix,
      },
      picEmail: picEmail ?? '',
      picJobTitle: picJobTitle ?? '',
      picName: picName ?? '',
      productNeeded: productNeeded ?? [],
      productRequest: productRequest ?? [],
      website: website ?? '',
      preferableScheduledMeetingFirst: preferableScheduledMeetingFirst ?? '',
      preferableScheduledMeetingSecond: preferableScheduledMeetingSecond ?? '',
      discussionTopic: discussionTopic ?? '',
      locationPreference: locationPreference ?? '',
      ownerIdCard: ownerIdCard ?? '',
      ownerAddress: ownerAddress ?? '',
      ownerAddressCity: ownerAddressCity ?? '',
      ownerPostalCode: ownerPostalCode ?? '',
      ownerName: ownerName ?? '',
      ownerJobTitle: ownerJobTitle ?? '',
      agreementDocument,
      serviceFee: serviceFee ?? defaultValue.serviceFee,
      termsOfPayment: termsOfPayment ?? '',
      billingCycle: billingCycle ?? '',
      creditLimit: creditLimit ?? '',
      legalNpwpNumber: legalNpwpNumber ?? '',
      legalSppkpNumber: legalSppkpNumber ?? '',
      legalName: legalName ?? '',
      legalFiscalAddress: legalFiscalAddress ?? '',
      legalFiscalCity: legalFiscalCity ?? '',
      legalFiscalPostalCode: legalFiscalPostalCode ?? '',
      legalBillingAddress: legalBillingAddress ?? '',
      legalBillingCity: legalBillingCity ?? '',
      legalBillingPostalCode: legalBillingPostalCode ?? '',
      documentNotes: documentNotes ?? '',
      creditAssessmentNotes: creditAssessmentNotes ?? '',
      partnerCompanyName: partnerCompanyName ?? '',
      partnerCompanyRelation: partnerCompanyRelation ?? '',
      partnerCompanyPicName: partnerCompanyPicName ?? '',
      partnerCompanyPicEmail: partnerCompanyPicEmail ?? '',
      partnerCompanyPhone: {
        phone: partnerCompanyPhone ?? '',
        prefix:
          partnerCompanyPhoneCountryCode ??
          defaultValue.partnerCompanyPhone.prefix,
      },
      otherEmails: otherEmails ?? [],
    };
  }, [values]);
}

function pageMap(step: number) {
  // step 1 not callling the submit api
  if (step === 2) return 'PAYMENT_METHOD';
  if (step === 3) return 'DOCUMENT';
  if (step === 4) return 'REVIEW';
  return 'REGISTRATION_FORM';
}

function trackingMap(step: number) {
  if (step === 0) return 'SUBMIT_REGISTRATION_PAGE_1';
  // step 1 not callling the submit api
  if (step === 2) return 'SUBMIT_REGISTRATION_PAGE_3';
  if (step === 3) return 'SUBMIT_REGISTRATION_PAGE_4';
  if (step === 4) return 'SUBMIT_REGISTRATION_COMPLETE';
  return null;
}

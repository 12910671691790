import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Divider } from '@traveloka/ctvweb-ui';
import { Text, Token, useTheme } from '@traveloka/web-components';

type Props = {
  items: Array<{
    testID?: string;
    label: string;
    value: string;
    isFree?: boolean;
  }>;
  totalPrice: string;
};

export default function PriceBreakdown(props: Props) {
  const { items, totalPrice } = props;

  const content = useContentResource().CorporateProductPayment;

  const { color } = useTheme();
  const sectionStyle = {
    backgroundColor: color.lightStain,
  };

  return (
    <View style={[Style.section, sectionStyle]}>
      <Text style={Style.title}>{content.priceBreakdownTitle}</Text>
      {items.map((item, index) => (
        <View key={index} style={Style.item}>
          <Text variant="ui-small" style={Style.label}>
            {item.label}
          </Text>
          <Text
            testID={item.testID}
            variant="ui-small"
            ink={item.isFree ? 'positive' : undefined}
          >
            {item.value}
          </Text>
        </View>
      ))}
      <Divider spacing="xs" />
      <View style={Style.item}>
        <Text
          testID="confirmation-payment.content.total-price.label"
          variant="ui-large"
          style={Style.label}
        >
          {content.totalPriceText}
        </Text>
        <Text
          testID="confirmation-payment.content.total-price.value"
          variant="ui-large"
        >
          {totalPrice}
        </Text>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  section: {
    paddingHorizontal: Token.spacing.l,
    marginBottom: Token.spacing.l,
    paddingVertical: Token.spacing.s,
  },
  title: {
    marginBottom: Token.spacing.m,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginVertical: Token.spacing.xs,
  },
  label: {
    paddingRight: Token.spacing.m,
    flex: 1,
  },
});

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { isOffline, useFeatureControl } from '..';
import useApi from '../api/useApi';
import { useLocaleDispatcher } from '../locale';
import {
  CONTENT_RESOURCE_API,
  ContentResourceRequest,
  ContentResourceResponse,
  IMAGE_RESOURCE_API,
  IMAGE_SLIDER_API,
  ImageResourceRequest,
  ImageResourceResponse,
  ImageSliderRequest,
  SliderResourceResponse,
} from './api';
import {
  createContentMock,
  createImageMock,
  createSliderMock,
} from './createResourceMock';
import * as payload from './payload';
import { ContentQuery, ContentResult, ImageQuery, SliderQuery } from './types';

type State = {
  isLoading: boolean;
  content: ContentResourceResponse;
  image: ImageResourceResponse;
  slider: SliderResourceResponse;
};

const ResourceContext = createContext<State>(undefined!);

export function ResourceProvider(props: PropsWithChildren<{}>) {
  const [state, setState] = useState<State>({
    isLoading: true,
    content: undefined!,
    image: undefined!,
    slider: undefined!,
  });

  const crFallbackLogFc = useFeatureControl('b2b-cr-fallback-log');
  const { setIsLoading } = useLocaleDispatcher();

  const fetchContent = useApi<ContentResourceResponse, ContentResourceRequest>({
    domain: 'content',
    method: 'post',
    path: CONTENT_RESOURCE_API,
  });

  const fetchImage = useApi<ImageResourceResponse, ImageResourceRequest>({
    domain: 'content',
    method: 'post',
    path: IMAGE_RESOURCE_API,
  });

  const fetchSlider = useApi<SliderResourceResponse, ImageSliderRequest>({
    domain: 'content',
    method: 'post',
    path: IMAGE_SLIDER_API,
  });

  useEffect(() => {
    const contentQuery: ContentQuery = payload.content;
    const imageQuery: ImageQuery = payload.image;
    const sliderQuery: SliderQuery = payload.slider;

    const contentResources = Object.keys(contentQuery).reduce((obj, name) => {
      obj[name] = Object.keys(contentQuery[name]);
      return obj;
    }, {} as Record<string, string[]>);

    const imageResources = Object.keys(imageQuery).reduce((obj, name) => {
      obj[name] = Object.keys(imageQuery[name]);
      return obj;
    }, {} as Record<string, string[]>);

    const imageSliderNames = Object.keys(sliderQuery);

    if (isOffline === true) {
      setState({
        isLoading: false,
        content: createContentMock(),
        image: createImageMock(),
        slider: createSliderMock(),
      });
      return;
    }

    Promise.all([
      fetchContent({ contentResources }),
      fetchImage({ imageResources }),
      fetchSlider({ imageSliderNames }),
    ])
      .then(res => {
        const [apiContent, image, slider] = res;

        let content: ContentResourceResponse;
        if (apiContent.success) {
          content = apiContent.data;
        } else {
          if (crFallbackLogFc.enabled) {
            console.error('Creating content resource mock');
          }
          content = createContentMock();
        }

        setState({
          isLoading: false,
          content,
          image: image.success ? image.data : createImageMock(),
          slider: slider.success ? slider.data : createSliderMock(),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchContent, fetchImage, fetchSlider]);

  if (state.isLoading) return null;

  return (
    <ResourceContext.Provider value={state}>
      {props.children}
    </ResourceContext.Provider>
  );
}

export function useContentResource() {
  return useContext(ResourceContext).content;
}

export function useImageResource() {
  return useContext(ResourceContext).image;
}

export function useImageSlider() {
  return useContext(ResourceContext).slider;
}

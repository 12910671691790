import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  forwardRef,
  Ref,
} from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';

import {
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { Divider } from '@traveloka/ctvweb-ui';
import {
  useTheme,
  Button,
  Card,
  Checkbox,
  Text,
  Token,
} from '@traveloka/web-components';

import {
  useFilteredRooms,
  useProperty,
} from 'hotel/detail/contexts/PropertyContext';
import { useSearchSpec } from 'hotel/shared/contexts/SpecContext';
import { getHeaderHeight, FIXED_HEADER_HEIGHT } from 'shared/utils/header';
import { formatMessage } from 'shared/utils/intl';

import RoomPriceDisplay from '../RoomPriceDisplay/RoomPriceDisplay';
import RoomSearchForm from '../RoomSearchForm/RoomSearchForm';

const FIXED_HIDDEN_SECTION_HEIGHT = 28;
function RoomSearch(_: unknown, ref: Ref<View>) {
  const { color } = useTheme();
  const priceDisplayStyle = {
    borderColor: color.borderSubtle,
  };

  const content = useContentResource().CorporateHotelDetailRoomSearch;

  const [isCollapsed, setIsCollapsed] = useState(true);
  const buttonText = isCollapsed
    ? content.roomSearchShowButtonText
    : content.roomSearchHideButtonText;
  const handleTogglePress = useCallback(() => {
    setIsDropdownOpened(false);
    setIsCollapsed(s => !s);
  }, [setIsCollapsed]);

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const handleDropdownPress = useCallback(() => setIsDropdownOpened(true), []);

  const contentHeightRef = useRef(0);
  const handleLayout = useCallback((e: LayoutChangeEvent) => {
    contentHeightRef.current = e.nativeEvent.layout.height;
  }, []);
  const formStyle = {
    height: isCollapsed ? 0 : contentHeightRef.current,
    overflowY: isDropdownOpened ? 'visible' : 'hidden',
  };

  const cardStyle = {
    top: getHeaderHeight(0) - FIXED_HEADER_HEIGHT - FIXED_HIDDEN_SECTION_HEIGHT,
  };

  return (
    <Card style={[Style.card, cardStyle]}>
      <View ref={ref} style={[Style.content, Style.filterDisplay]}>
        <View>
          <Text variant="title-2" style={Style.title}>
            {content.title}
          </Text>
          <RoomFilter />
        </View>
        <View style={[Style.priceDisplay, priceDisplayStyle]}>
          <RoomPriceDisplay />
        </View>
      </View>
      <Divider margin="none" subtle />
      <View style={[Style.content, Style.searchHeader]}>
        <SearchSummary />
        <Button
          variant="secondary"
          size="small"
          text={buttonText}
          onPress={handleTogglePress}
        />
      </View>
      <View style={[Style.form, formStyle]}>
        <View onLayout={handleLayout}>
          <Divider margin="none" subtle />
          <RoomSearchForm onDropdownPress={handleDropdownPress} />
        </View>
      </View>
    </Card>
  );
}

function SearchSummary() {
  const {
    rooms,
    travelers,
    nonEmployeeTravelers,
    checkInDate,
    duration,
  } = useSearchSpec();
  const [, filteredRooms] = useFilteredRooms();

  const { format } = useLocalizedDateFormat();
  const content = useContentResource().CorporateHotelDetailRoomSearch;

  const text = useMemo(() => {
    return formatMessage(content.summaryContentV2, {
      room: rooms,
      adult: travelers.length + nonEmployeeTravelers.adults,
      child: nonEmployeeTravelers.childrenAges.length,
      date: format(checkInDate, 'SHORT_MONTH'),
      night: duration,
      option: filteredRooms.length,
    });
  }, [
    rooms,
    travelers.length,
    nonEmployeeTravelers.adults,
    nonEmployeeTravelers.childrenAges.length,
    checkInDate,
    duration,
    filteredRooms.length,
  ]);

  return (
    <Text variant="ui-small" ink="highlight">
      {text}
    </Text>
  );
}

function RoomFilter() {
  const content = useContentResource().CorporateHotelDetailRoomSearch;

  const [{ onlyFreeBreakfast, onlyFreeCancel }, dispatch] = useProperty();

  const handleBreakfastToggle = useCallback(() => {
    dispatch({ type: 'toggle-breakfast' });
  }, [dispatch]);
  const handleCancelationToggle = useCallback(() => {
    dispatch({ type: 'toggle-cancel' });
  }, [dispatch]);

  return (
    <View style={Style.checkboxGroup}>
      <View style={Style.checkbox}>
        <Checkbox checked={onlyFreeBreakfast} onChange={handleBreakfastToggle}>
          <Checkbox.Control />
          <Text variant="ui-small" style={Style.checkboxText}>
            {content.checkboxFreeBreakfastText}
          </Text>
        </Checkbox>
      </View>
      <View style={Style.checkbox}>
        <Checkbox checked={onlyFreeCancel} onChange={handleCancelationToggle}>
          <Checkbox.Control />
          <Text variant="ui-small" style={Style.checkboxText}>
            {content.checkboxFreeCancellationText}
          </Text>
        </Checkbox>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  card: {
    marginBottom: Token.spacing.l,
    zIndex: 1,
    // @ts-ignore
    position: 'sticky',
    overflow: 'visible',
  },
  title: {
    marginBottom: Token.spacing.s,
  },
  content: {
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.m,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filterDisplay: {
    zIndex: 1,
  },
  searchHeader: {
    alignItems: 'center',
  },
  checkboxGroup: {
    flexDirection: 'row',
  },
  checkbox: {
    marginRight: Token.spacing.xxl,
  },
  checkboxText: {
    marginLeft: Token.spacing.s,
  },
  priceDisplay: {
    paddingLeft: Token.spacing.m,
    borderLeftWidth: Token.border.width.thin,
    minWidth: 180,
  },
  form: {
    // @ts-ignore
    transitionDuration: `${Token.animation.timing.instant}ms`,
    transitionProperty: 'height',
  },
});

export default forwardRef(RoomSearch);

import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StyleSheet, View } from 'react-native';

import { differenceInMinutes } from 'date-fns';

import {
  useApi,
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import {
  FlightSearchResultHeader,
  FlightSearchSingleSummary,
  RoundTripSummaryModal,
} from '@traveloka/ctvweb-ui/flight';
import { Button, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import { useProductRequest } from 'approval-system/shared/contexts/ProductRequestContext';
import { ONE_WAY_SEPARATOR } from 'flight/search/constants/flight-search-constant';
import { useSearchSpec } from 'flight/search/contexts/SearchSpecContext';
import { useStaticData } from 'flight/search/contexts/StaticDataContext';
import useFlightSearchReducer from 'flight/search/reducers/flight-search-reducer';
import { getBasicSearchSpec } from 'flight/search/utils/flight-search-spec-util';
import {
  FlightSearchData,
  NormalFlightSearchData,
  SmartComboSearchData,
  useCreateFlights,
  useCreateSmartComboFlights,
} from 'flight/search/utils/flight-search-view-util';
import { getAirportCity } from 'flight/search/utils/flight-static-data-util';
import {
  FlightSearchRequest,
  FlightSearchResponse,
  ROUND_TRIP_API,
  SmartComboSearchResponse,
} from 'flight/shared/api';
import {
  FareDetail,
  FareInfo,
  PaxFareDetail,
  SmartComboSearchResult,
} from 'flight/shared/api/types';
import JourneyType from 'flight/shared/constants/journey-type';
import { CurrencyValue, JavaCurrencyValue } from 'shared/utils/currency';
import { formatCurrency } from 'shared/utils/intl';

import { flightPollingMechanism } from 'flight/search/utils/flightPollingMechanism';
import { CheckBookingData } from '../FlightSearchContent/FlightSearchContent';
import FlightSearchResultBlock from './FlightSearchResultBlock';

type Props = {
  departFlight: FlightSearchData;
  onChangeFlightPress: () => void;
  smartComboResponse?: Nullable<
    Omit<SmartComboSearchResponse, 'departureFlightDetail'>
  >;
  setCheckBookingData: Dispatch<SetStateAction<CheckBookingData>>;
};

function FlightSearchResultBlockReturn(props: Props) {
  const {
    departFlight,
    onChangeFlightPress,
    smartComboResponse,
    setCheckBookingData,
  } = props;
  const contentResource = useContentResource();
  const {
    CorporateFlightGeneral,
    CorporateFlightSearch,
    CorporateFlightSearchReturnInvalidModal: contentInvalidModal,
    CorporateFlightSearchReturnConfirmationModal: contentConfirmationModal,
  } = contentResource;
  const { format } = useLocalizedDateFormat();

  const isSCDepart = departFlight.isSmartCombo;

  const productRequest = useProductRequest();
  const staticData = useStaticData();
  const searchSpec = useSearchSpec();
  const [returnState, returnAction] = useFlightSearchReducer();
  const [isTooClose, setIsTooClose] = useState(false);
  const [isSummaryVisible, setSummaryVisible] = useState(false);
  const [isShowingSmartCombo, setIsShowingSmartCombo] = useState(true);
  const pollingTimeoutId = useRef<NodeJS.Timeout>();

  let departIsScAndHasNonSc = false;
  let usedDepartFlight = departFlight;

  if (departFlight.isSmartCombo) {
    departIsScAndHasNonSc = !!departFlight.nonSmartCombo;

    if (departIsScAndHasNonSc && !isShowingSmartCombo) {
      usedDepartFlight = departFlight.nonSmartCombo!;
    }
  }
  const toSmartComboPage = isSCDepart && !isShowingSmartCombo;
  const toNonSmartComboPage = isSCDepart && isShowingSmartCombo;

  const departureCity = getAirportCity(searchSpec.airport.origin, staticData);
  const arrivalCity = getAirportCity(
    searchSpec.airport.destination,
    staticData
  );

  const content = {
    direct: CorporateFlightGeneral.direct,
    duration: CorporateFlightSearch.totalDuration,
    detail: CorporateFlightGeneral.detail,
    price: CorporateFlightSearch.departurePrice,
    tax: CorporateFlightSearch.taxInclusive,
    chooseDeparture: CorporateFlightSearch.chooseDeparture,
    chooseReturn: CorporateFlightSearch.chooseReturn,
    changeFlight: CorporateFlightSearch.changeDeparture,
  };

  const createFlights = useCreateFlights();
  const createSmartComboFlights = useCreateSmartComboFlights();
  const searchRoundTrip = useApi<FlightSearchResponse, FlightSearchRequest>({
    domain: 'search',
    method: 'post',
    path: ROUND_TRIP_API,
  });

  function assignSmartComboData() {
    const smartComboFlight = createSmartComboFlights(
      searchSpec,
      createFlightReturnWithFare(smartComboResponse!, departFlight),
      null,
      staticData,
      contentResource
    );
    returnAction.smartCombo(smartComboFlight, null);
  }

  async function flightSearchResult() {
    return await searchRoundTrip(getBasicSearchSpec(searchSpec, false)).then(
      res => {
        if (res.success) {
          const searchId = res.trackingSpec && res.trackingSpec.id;
          const results = createFlights(
            searchSpec,
            res.data.searchResults,
            staticData,
            contentResource
          );
          returnAction.result(results, searchId);

          if (isSCDepart) {
            assignSmartComboData();
          } else {
            returnAction.smartCombo([], null);
          }
          return res.data.completed;
        }

        console.error(res.error.message);
        returnAction.result([], null);
        return false;
      }
    );
  }

  useEffect(() => {
    if (departFlight.isSmartCombo && !departFlight.nonSmartCombo) {
      pollingTimeoutId.current !== undefined &&
        clearTimeout(pollingTimeoutId.current);
      assignSmartComboData();
      return;
    }

    pollingTimeoutId.current = flightPollingMechanism(flightSearchResult);

    // Clean-up
    return () => {
      returnAction.reset();
      pollingTimeoutId.current !== undefined &&
        clearTimeout(pollingTimeoutId.current);
    };
  }, [returnAction, searchSpec]);

  const handleSelect = useCallback(
    (flight: FlightSearchData) => {
      const diff = differenceInMinutes(
        flight.summary.departureDateTime,
        departFlight.summary.arrivalDateTime
      );
      const sixhour = 6 * 60;

      if (diff <= sixhour) {
        setIsTooClose(true);
      } else {
        returnAction.select(flight);
        setSummaryVisible(true);
      }
    },
    [returnAction, departFlight]
  );

  const handleContinuePress = useCallback(() => {
    if (!returnState.selected) {
      return;
    }

    const storedFlights: FlightSearchData[] = [];

    if (usedDepartFlight.isSmartCombo) {
      if (smartComboResponse) {
        const smartComboFlight =
          smartComboResponse.flightTable[Number(usedDepartFlight.flightId)][
            Number(returnState.selected!.flightId)
          ];

        // This is to force the nonSmartCombo data to be null
        storedFlights.push(
          {
            ...usedDepartFlight,
            flightId: smartComboFlight!.flightId,
            nonSmartCombo: null,
          },
          {
            ...(returnState.selected as SmartComboSearchData),
            flightId: smartComboFlight!.flightId,
            nonSmartCombo: null,
          }
        );
      }
    } else {
      // Leaving it mutable as the value will be gone after changing the page
      storedFlights.push(
        usedDepartFlight,
        returnState.selected as NormalFlightSearchData
      );
    }

    let productRequestId: string | undefined;
    let tripName: string | undefined;
    let approverName: string | undefined;

    if (productRequest.enabled) {
      productRequestId = productRequest.data?.productRequestId;
      tripName = productRequest.data?.tripName;
      approverName = productRequest.data?.approverName;
    }

    setCheckBookingData({
      isVisible: true,
      searchId: returnState.searchId,
      journeyType: usedDepartFlight.isSmartCombo
        ? JourneyType.PACKAGE_RT
        : JourneyType.BASIC_RT,
      passengers: searchSpec.passengers,
      nonEmployeeTravelers: searchSpec.nonEmployeeTravelers,
      flights: (storedFlights as unknown) as NormalFlightSearchData[],
      productRequestId,
      tripName,
      approverName,
      searchSpec: window.location.search,
    });
    setSummaryVisible(false);
  }, [returnState.searchId, returnState.selected, productRequest]);

  const summaryProps = useMemo(() => {
    if (!returnState.selected) {
      return {
        flights: [] as Array<FlightSearchData & { flightLabel: string }>,
        totalPrice: '',
      };
    }

    const flights = [
      {
        ...usedDepartFlight,
        flightLabel: contentConfirmationModal.departureText,
      },
      {
        ...returnState.selected,
        flightLabel: contentConfirmationModal.returnText,
      },
    ];
    const totalPrice = flights.reduce(
      (obj, flight) => {
        const { amount, currency, decimalPoints } = flight.summary.mainPrice;

        return {
          amount: obj.amount + amount,
          currency: currency,
          decimalPoints: decimalPoints,
        };
      },
      { amount: 0 } as CurrencyValue
    );

    return {
      flights,
      totalPrice: formatCurrency(totalPrice),
    };
  }, [departFlight, returnState.selected, searchSpec.passengers.length]);

  const handleCloseModal = useCallback(() => {
    setIsTooClose(false);
    returnAction.cancel();
  }, [returnAction, setIsTooClose]);

  const flightSearchResultBlock = useMemo(
    () => (
      <FlightSearchResultBlock
        isReturnFlight={true}
        isLoading={returnState.isLoading}
        scState={{
          departIsScAndHasNonSc,
          toSmartComboPage: toSmartComboPage,
          toNonSmartComboPage: toNonSmartComboPage,
          setState(value: boolean) {
            window.scrollTo({ top: 0 });
            returnAction.loading(true);
            setIsShowingSmartCombo(value);
          },
          setLoading: returnAction.loading,
          selectedDepart: departFlight,
        }}
        results={
          !toNonSmartComboPage
            ? returnState.results
            : returnState.smartComboResults
        }
        onSelect={handleSelect}
      />
    ),
    [
      returnState.isLoading,
      returnState.results,
      returnState.smartComboResults,
      handleSelect,
    ]
  );

  return (
    <>
      <View style={Style.block}>
        <View style={Style.departBlock}>
          <FlightSearchResultHeader
            title={content.chooseDeparture}
            date={format(searchSpec.date.depart, 'FULL_WEEKDAY_MONTH')}
            route={[departureCity, arrivalCity].join(ONE_WAY_SEPARATOR)}
          />
          <FlightSearchSingleSummary
            summary={usedDepartFlight.summary}
            segments={usedDepartFlight.segments}
            label={content}
            onPressChangeFlight={onChangeFlightPress}
            style={Style.departContent}
          />
        </View>
        <View style={Style.returnBlock}>
          <FlightSearchResultHeader
            isReturnFlight
            title={content.chooseReturn}
            date={format(searchSpec.date.return, 'FULL_WEEKDAY_MONTH')}
            route={[arrivalCity, departureCity].join(ONE_WAY_SEPARATOR)}
            style={Style.returnHeader}
          />
          {flightSearchResultBlock}
        </View>
      </View>

      <Modal isVisible={isTooClose}>
        <Popup
          title={contentInvalidModal.title}
          showCloseButton
          width={800}
          maxWidth={800}
          onCloseButtonPress={handleCloseModal}
        >
          <Text>{contentInvalidModal.body}</Text>
          <View style={Style.button}>
            <Button
              text={contentInvalidModal.closeButtonText}
              onPress={handleCloseModal}
            />
          </View>
        </Popup>
      </Modal>

      {returnState.selected && (
        <RoundTripSummaryModal
          testID="flight.search.summary-modal"
          flights={summaryProps.flights}
          isVisible={isSummaryVisible}
          onCloseButtonPress={() => setSummaryVisible(false)}
          onContinuePress={handleContinuePress}
          onModalHide={returnAction.cancel}
          totalPrice={summaryProps.totalPrice}
          label={{
            button: contentConfirmationModal.bookText,
            duration: contentConfirmationModal.totalDurationText,
            fareDetail: contentConfirmationModal.fareInfoText,
            flightDetail: contentConfirmationModal.flightDetailsText,
            priceHelper: contentConfirmationModal.inclusiveTaxText,
            priceSuffix: contentConfirmationModal.priceSuffix,
            title: contentConfirmationModal.title,
          }}
        />
      )}
    </>
  );
}

const Style = StyleSheet.create({
  block: {
    flexDirection: 'row',
  },
  departBlock: {
    width: 275,
    height: 'fit-content',
    // @ts-ignore
    position: 'sticky',
    top: 60,
  },
  departContent: {
    borderWidth: Token.border.width.thick,
    borderTopWidth: 0,
    borderColor: Token.color.uiLightSecondary,
    padding: Token.spacing.m,
    paddingBottom: Token.spacing.xs,
  },
  returnBlock: {
    flex: 1,
    marginLeft: Token.spacing.s,
  },
  returnHeader: {
    marginBottom: Token.spacing.ml,
  },
  button: {
    marginTop: Token.spacing.m,
    alignItems: 'flex-end',
  },
});

export default FlightSearchResultBlockReturn;

// Functions
function getSubtractFareInfo(
  rFare: FareInfo,
  dFare: FareInfo,
  fareName: keyof FareInfo,
  travelerType: keyof FareDetail,
  fareType: keyof Pick<
    PaxFareDetail,
    'baseFareWithCurrency' | 'totalFareWithCurrency'
  >
): JavaCurrencyValue {
  const currReturn = rFare[fareName][travelerType]![fareType];
  const currDeparture = dFare[fareName][travelerType]![fareType];

  const amount = Number(currReturn.amount) - Number(currDeparture.amount);
  const displayAmount =
    Number(currReturn.displayAmount) - Number(currDeparture.displayAmount);

  return {
    ...currReturn,
    amount: amount.toString(),
    displayAmount: displayAmount
      .toFixed(Number(currReturn.decimalPoints))
      .toString(),
  };
}

function createFare(
  rFare: FareInfo,
  dFare: FareInfo,
  fareName: keyof FareInfo,
  travelerType: keyof FareDetail
) {
  const currentFare = rFare[fareName][travelerType];
  if (!currentFare) {
    return null;
  }

  return {
    ...currentFare,
    baseFareWithCurrency: getSubtractFareInfo(
      rFare,
      dFare,
      fareName,
      travelerType,
      'baseFareWithCurrency'
    ),
    totalFareWithCurrency: getSubtractFareInfo(
      rFare,
      dFare,
      fareName,
      travelerType,
      'totalFareWithCurrency'
    ),
  };
}

function fareSubtractor(rFare: FareInfo, dFare: FareInfo): FareInfo {
  return {
    airlineFare: {
      childFare: createFare(rFare, dFare, 'airlineFare', 'childFare'),
      infantFare: createFare(rFare, dFare, 'airlineFare', 'infantFare'),
      adultFare: createFare(rFare, dFare, 'airlineFare', 'adultFare')!,
    },
    partnerFare: {
      childFare: createFare(rFare, dFare, 'partnerFare', 'childFare'),
      infantFare: createFare(rFare, dFare, 'partnerFare', 'infantFare'),
      adultFare: createFare(rFare, dFare, 'partnerFare', 'adultFare')!,
    },
  };
}

function createFlightReturnWithFare(
  smartComboResponse: Omit<SmartComboSearchResponse, 'departureFlightDetail'>,
  departFlight: FlightSearchData
) {
  const { returnFlightDetail, flightTable } = smartComboResponse!;

  return flightTable[Number(departFlight.flightId)].map<SmartComboSearchResult>(
    returnFlightInfo => {
      const selectedReturnFlight =
        returnFlightDetail[Number(returnFlightInfo.indexReturn)];

      return {
        ...selectedReturnFlight,
        journeys: selectedReturnFlight.journeys.map(
          (journey, journeyIndex) => ({
            ...journey,
            fareInfo: fareSubtractor(
              returnFlightInfo.returnFlightFareInfo,
              departFlight.journeys[journeyIndex].fareInfo
            ),
          })
        ),
      };
    }
  );
}

import { format } from 'date-fns';

import { PartialEmployee } from 'company/types';
import { Spec } from 'hotel/prebook/contexts/PrebookProvider';
import { FormValue } from 'hotel/prebook/types';
import { formatValue } from 'hotel/prebook/utils/SpecialRequestUtils';
import { JavaCurrencyValue } from 'shared/utils/currency';
import { JAVA_DATE } from 'shared/utils/date';

import { RoomAvailabilityResponse, SubmitBookingRequest } from '../api';
import { HotelNonEmployeeDetail } from '../api/types';

export function generateBookingSpec(
  spec: Spec,
  travelers: PartialEmployee[],
  room: RoomAvailabilityResponse,
  formValues: FormValue,
  calculatedFee: {
    grandTotal: Nullable<JavaCurrencyValue>;
    totalFare: JavaCurrencyValue;
    serviceFee: Nullable<JavaCurrencyValue>;
    vatFee: Nullable<JavaCurrencyValue>;
  },
  nonEmployeeTravelers?: HotelNonEmployeeDetail,
  trackingId?: string,
  additionalSpec?: Partial<SubmitBookingRequest>
): SubmitBookingRequest {
  const specialRequest = Object.keys(formValues.specialRequest)
    .reduce((requests, key) => {
      const request = formValues.specialRequest[key];

      if (!request.selected) return requests;

      let value = Array.isArray(request.value)
        ? formatValue(request.value)
        : request.value;

      if (request.type === 'time') {
        value = `${request.label} (${value})`;
      }
      requests.push(value);

      return requests;
    }, [] as string[])
    .join(', ');

  return {
    confirmSubmit: false,
    ...additionalSpec,
    type: 'HOTEL',
    locale: 'en-ID',
    productRequestId: spec.productRequestId,
    notes: formValues.notes,
    attachmentUrl: formValues.attachment?.url,
    tripRequestId: spec.tripRequestId,
    contact: {
      phoneNumber: formValues.contact.phone.phone,
      phoneNumberPrefix: formValues.contact.phone.prefix,
    },
    totalFareFromClient: calculatedFee.totalFare,
    hotel: {
      checkInDate: format(spec.checkInDate, JAVA_DATE),
      checkOutDate: format(spec.checkOutDate, JAVA_DATE),
      propertyId: spec.propertyId,
      roomId: room.roomId,
      numOfRooms: spec.numOfRooms,
      roomOccupancy: spec.roomOccupancy,
      rateKey: spec.rateKey,
      specialRequest,
      employeeIds: travelers.map(traveler => traveler.employeeId),
      nonEmployeeTravelers: nonEmployeeTravelers ?? {
        adults: [],
        children: [],
      },
    },
    trackingSpec: {
      pageName: 'HOTEL_BOOKING',
      pageCategory: 'HOTEL',
      data: {
        visitId: spec.visitId,
        searchId: spec.searchId,
        detailId: spec.detailId,
        preBookingId: trackingId,
      },
    },
    grandTotal: calculatedFee.grandTotal,
    serviceFee: calculatedFee.serviceFee,
    vatFee: calculatedFee.vatFee,
  };
}

import React from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Text, Token } from '@traveloka/web-components';

import { useHotelRequestRefund } from 'refund/request-refund/contexts/hotel/HotelRequestRefundContext';
import { formatMessage } from 'shared/utils/intl';

export default function HotelGuests() {
  const content = useContentResource().CorporateHotelRequestRefundSelectDetails;
  const nonEmployeeTypeContent = useContentResource()
    .CorporateEnumNonEmployeeType;

  const [{ data }] = useHotelRequestRefund();
  const { travelers } = data;

  return (
    <>
      {travelers.map((traveler, index) => (
        <Text key={index} style={!index && Style.guestName}>
          {index + 1}. {traveler.fullname} (
          {[
            nonEmployeeTypeContent[traveler.type],
            traveler.age &&
              formatMessage(content.guestAge, { num: traveler.age }),
          ]
            .filter(Boolean)
            .join(', ')}
          )
        </Text>
      ))}
    </>
  );
}

const Style = StyleSheet.create({
  guestName: {
    marginTop: Token.spacing.xs,
  },
});

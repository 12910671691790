export enum ResultStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum Operator {
  EQUAL = 'EQUAL',
  LIKE = 'LIKE',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER_THAN = 'gt',
  GREATER_THAN_EQUAL = 'gte',
  IN = 'in',
  NOT_IN = 'nin',
  LESS_THAN = 'lt',
  LESS_THAN_EQUAL = 'lte',
}

export enum FieldType {
  CHECKBOX = 'checkbox',
  DATETIME = 'datetime',
  FIELDSET = 'fieldset',
  LIST = 'list',
  LONGTEXT = 'longtext',
  NUMBER = 'number',
  PASSWORD = 'password',
  SELECT = 'select',
  TEXT = 'text',
  TIME = 'time',
}

export enum ValidationType {
  REQUIRED = 'required',
  MAXLENGTH = 'maxLength',
  MINLENGTH = 'minLength',
  MAXITEMS = 'maxItems',
  MINITEMS = 'minItems',
  MINVALUE = 'minValue',
  MAXVALUE = 'maxValue',
  PHONE = 'phone',
  EMAIL = 'email',
}

export enum SortType {
  DESCENDING = 'DESCENDING',
  ASCENDING = 'ASCENDING',
}

type NoParamsValidation = {
  type: 'required' | 'phone' | 'email';
  arguments: {};
};

type ParamsValidation = {
  type:
    | 'maxLength'
    | 'minLength'
    | 'maxItems'
    | 'minItems'
    | 'minValue'
    | 'maxValue';
  arguments: {
    value: string;
  };
};

type Option = {
  label: string;
  value: string;
};

export type Field = {
  arguments: {
    options?: Option[];
  };
  children: Field[];
  defaultValue: unknown;
  editableOnCreate: boolean;
  editableOnUpdate: boolean;
  filterable: boolean;
  identifier: boolean;
  label: string;
  name: string;
  sortable: boolean;
  type: FieldType;
  validations: Array<NoParamsValidation | ParamsValidation>;
};

export type ValidationResult = {
  fields: Dictionary<string[]>;
  forms: [];
};

export type SearchParams = {
  entriesCount: number;
  offset?: number;
  sort?: {
    fieldName: string;
    type: SortType;
  };
  filter: Array<{
    fieldName: string;
    arguments: {
      value: string | string[];
      operator: Operator;
    };
  }>;
};

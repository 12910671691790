import React, { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { LoadingIndicator, Token } from '@traveloka/web-components';
import { useLocale } from '@traveloka/ctv-core';

type Props = PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
  hasLocaleLoader?: boolean;
}>;

export default function Content(props: Props) {
  const { hasLocaleLoader = true, ...restProps } = props;

  const { isLoading } = useLocale();

  return (
    <>
      {hasLocaleLoader && <LocaleOverlayLoader />}
      {!isLoading && (
        <View {...restProps} style={[Style.content, restProps.style]} />
      )}
    </>
  );
}

function LocaleOverlayLoader() {
  const { isLoading } = useLocale();

  return (
    <View style={[Style.localeOverlay, isLoading && Style.showOverlay]}>
      <LoadingIndicator />
    </View>
  );
}

const Style = StyleSheet.create({
  content: {
    zIndex: 1,
    width: 960,
    paddingTop: Token.spacing.xxl,
    paddingBottom: Token.spacing.m,
    marginHorizontal: 'auto',
  },
  localeOverlay: {
    position: 'absolute',
    zIndex: 2,
    height: '100%',
    width: '100%',
    display: 'none',
    backgroundColor: Token.opacity.translucent(Token.color.uiDarkPrimary),
    alignItems: 'center',
    justifyContent: 'center',
  },
  showOverlay: {
    display: 'flex',
  },
});

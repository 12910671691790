import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Text, Token, Button, Icon } from '@traveloka/web-components';
import ChevronLeft from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_left_16px.svg';

import { useFlightRequestRefundForm } from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';
import { useFlightRequestRefundNavigator } from 'refund/request-refund/contexts/flight/FlightRequestRefundNavigatorContext';
import InfoBox from 'refund/shared/components/InfoBox';
import {
  FlightMainRefundReasonWithNoSecondaryButHasDocument,
  FlightRefundPersonalReason,
  RefundDocument,
} from 'refund/shared/api/types';
import { validatePnrCode } from 'refund/shared/utils/request-refund-validation';

import PassengerDocumentCard from './PassengerDocumentCard/PassengerDocumentCard';

export default function FlightRequestRefundDocument() {
  const { goToNextStep, goToPreviousStep } = useFlightRequestRefundNavigator();
  const { mainRefundReason, travelerForms } = useFlightRequestRefundForm();

  const content = useContentResource().CorporateFlightRequestRefundDocument;

  let isIdenticalTrip: boolean = true;
  if (
    mainRefundReason!.mainReason ===
    FlightMainRefundReasonWithNoSecondaryButHasDocument.DOUBLE_BOOKING
  ) {
    let firstValue =
      travelerForms[0].requiredDocument.DOUBLE_BOOKING_PNR?.valueToSubmit || '';
    travelerForms.forEach(travelerForm => {
      if (
        (travelerForm.requiredDocument.DOUBLE_BOOKING_PNR?.valueToSubmit ||
          '') !== firstValue
      ) {
        isIdenticalTrip = false;
      }
    });
  }

  let isPnrValid: boolean = true;
  if (
    mainRefundReason!.mainReason ===
    FlightMainRefundReasonWithNoSecondaryButHasDocument.DOUBLE_BOOKING
  ) {
    isPnrValid = travelerForms.every(
      travelerForm =>
        !validatePnrCode(
          travelerForm.requiredDocument.DOUBLE_BOOKING_PNR?.valueToSubmit
        )
    );
  }

  const hasInputAllDocuments = useMemo(() => {
    const secondaryReasonObject: Partial<Record<
      FlightRefundPersonalReason | 'null',
      RefundDocument[]
    >> = {};
    mainRefundReason!.secondaryReasons?.forEach(
      reason =>
        (secondaryReasonObject[reason.reason ? reason.reason : 'null'] =
          reason.requiredDocument)
    );

    return !travelerForms
      .filter(travelerForm => travelerForm.checkboxValue)
      .some(travelerForm => {
        const requiredDocument =
          secondaryReasonObject[
            travelerForm.secondaryReason ? travelerForm.secondaryReason : 'null'
          ];

        if (requiredDocument) {
          const addedDocuments = Object.keys(travelerForm.requiredDocument);
          return !requiredDocument.every(
            refundDocument =>
              addedDocuments.includes(refundDocument) &&
              !!travelerForm.requiredDocument[refundDocument]?.valueToSubmit
          );
        }
        return false;
      });
  }, [travelerForms, mainRefundReason]);

  return (
    <>
      <Button
        style={Style.buttonBack}
        size="small"
        variant="text"
        text={content.backButtonText}
        iconStart={() => <Icon src={ChevronLeft} />}
        onPress={() => goToPreviousStep()}
      />
      <Text style={Style.title} variant="headline">
        {content.documentHeadline}
      </Text>
      <InfoBox style={Style.infoBox} text={content.documentProceedInfoText} />
      {travelerForms.map((travelerForm, travelerIndex) => (
        <PassengerDocumentCard
          key={travelerForm.traveler.travelerId}
          isIdenticalTrip={isIdenticalTrip}
          travelerIndex={travelerIndex}
        />
      ))}
      <Button
        style={Style.buttonContinue}
        variant="main-cta"
        text={content.continueButtonText}
        disabled={!isIdenticalTrip || !hasInputAllDocuments || !isPnrValid}
        onPress={() => {
          goToNextStep();
          window.scrollTo(0, 0);
        }}
      />
    </>
  );
}

const Style = StyleSheet.create({
  buttonBack: {
    marginBottom: Token.spacing.m,
    width: 80,
  },
  title: {
    marginBottom: Token.spacing.m,
  },
  infoBox: {
    marginBottom: Token.spacing.xxl,
  },
  buttonContinue: {
    marginTop: Token.spacing.xs,
    marginLeft: 'auto',
    width: 304,
  },
});

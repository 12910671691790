import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import PolicyDark from '@traveloka/icon-kit-web/svg/dark/ic_policy_24px.svg';
import Policy from '@traveloka/icon-kit-web/svg/light/ic_policy_24px.svg';
import CreditCardDark from '@traveloka/icon-kit-web/svg/dark/ic_payment_credit_card_24px.svg';
import CreditCard from '@traveloka/icon-kit-web/svg/light/ic_payment_credit_card_24px.svg';
import { useTheme, Icon, Text, Token } from '@traveloka/web-components';

import PaymentMethod from 'payment/shared/constants/PaymentMethod';
import { useAuth } from '@traveloka/ctv-core';

type Props = {
  onPress(paymentOption: PaymentMethod): void;
  activeMenu: PaymentMethod;
};

type MenuItem = string | OmitTyped<MenuItemProps, 'onPress' | 'isActive'>;

export default function SideMenu(props: Props) {
  const { activeMenu, onPress } = props;

  const { color } = useTheme();
  const textStyle = {
    color: color.lightPrimary,
  };

  const content = useContentResource().CorporateProductPaymentSideMenu;

  const menus: MenuItem[] = [content.headerMenu];

  const user = useAuth().user!;
  if (user.paymentMethod === PaymentMethod.INVOICE) {
    menus.push({
      paymentMethod: PaymentMethod.INVOICE,
      testID: 'confirmation-payment.menu.invoicing',
      text: content.invoiceMenu,
      leftIcon: Policy,
      leftIconActive: PolicyDark,
    });
  } else {
    menus.push({
      paymentMethod: PaymentMethod.CREDIT_CARD,
      testID: 'confirmation-payment.menu.credit-card',
      text: content.creditCardMenu,
      leftIcon: CreditCard,
      leftIconActive: CreditCardDark,
    });
  }

  return (
    <View style={Style.container}>
      <Text style={[Style.title, textStyle]}>{content.title}</Text>
      {menus.map((menu, index) => {
        if (typeof menu === 'string') {
          return <Header key={index} text={menu} />;
        }

        return (
          <MenuItem
            {...menu}
            key={index}
            onPress={onPress}
            isActive={activeMenu === menu.paymentMethod}
          />
        );
      })}
    </View>
  );
}

type MenuItemProps = {
  paymentMethod: PaymentMethod;
  testID?: string;
  isActive: boolean;
  leftIcon?: string;
  leftIconActive?: string;
  rightIcon?: string;
  rightIconActive?: string;
  text: string;
  subText?: string;
  onPress(paymentOption: PaymentMethod): void;
};

function MenuItem(props: MenuItemProps) {
  const {
    paymentMethod,
    testID,
    isActive,
    leftIcon,
    leftIconActive,
    rightIcon,
    rightIconActive,
    text,
    subText,
    onPress,
  } = props;

  const hasLeftIcon = !!(leftIcon && leftIconActive);
  const hasRightIcon = !!(rightIcon && rightIconActive);

  const { color } = useTheme();
  const menuStyle = isActive && {
    backgroundColor: color.lightPrimary,
  };
  const textStyle = {
    color: color.lightPrimary,
  };
  const activeTextStyle = isActive && {
    color: color.darkSecondary,
  };
  const menuTextStyle = [
    hasLeftIcon && Style.withLeftIcon,
    hasRightIcon && Style.withRightIcon,
  ];

  return (
    <TouchableOpacity
      testID={testID}
      activeOpacity={0.5}
      onPress={() => onPress(paymentMethod)}
    >
      <View style={[Style.menu, menuStyle]}>
        <View style={Style.inner}>
          {hasLeftIcon && (
            <Icon
              width={Token.spacing.l}
              height={Token.spacing.l}
              src={isActive ? leftIconActive! : leftIcon!}
            />
          )}
          <Text
            variant="ui-small"
            style={[menuTextStyle, textStyle, activeTextStyle]}
          >
            {text}
          </Text>
          {hasRightIcon && (
            <Icon
              width={24}
              height={24}
              src={isActive ? rightIconActive! : rightIcon!}
            />
          )}
        </View>
        {subText && (
          <Text variant="ui-tiny" style={[Style.subText, textStyle]}>
            {subText}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
}

type HeaderProps = {
  text: string;
};

function Header(props: HeaderProps) {
  const { color } = useTheme();
  const headerStyle = {
    backgroundColor: String(Token.opacity.clear(color.lightPrimary)),
  };

  return (
    <Text variant="ui-tiny" ink="muted" style={[Style.header, headerStyle]}>
      {props.text}
    </Text>
  );
}

const Style = StyleSheet.create({
  container: {
    backgroundColor: Token.color.brandBusinessSuit,
    width: 188,
  },
  title: {
    padding: Token.spacing.s,
    textAlign: 'center',
  },
  header: {
    textTransform: 'uppercase',
    paddingVertical: Token.spacing.s,
    paddingHorizontal: Token.spacing.l,
    fontWeight: Token.typography.weightMedium.fontWeight,
    letterSpacing: 1,
  },
  menu: {
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.s,
  },
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subText: {
    marginTop: Token.spacing.xs,
  },
  withLeftIcon: {
    marginLeft: Token.spacing.xs,
  },
  withRightIcon: {
    marginRight: Token.spacing.xs,
  },
});

import React from 'react';

import { View, StyleSheet } from 'react-native';
import { Text, Token } from '@traveloka/web-components';

export type Props = {
  text: string;
};

export default function ErrorLabel(props: Props) {
  return (
    <View style={styles.container}>
      <Text variant="ui-tiny" ink="alert">
        {props.text}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: Token.spacing.xxs,
  },
});

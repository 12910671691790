import React, { useCallback, useState } from 'react';

import { View, StyleSheet } from 'react-native';
import { Icon, Image, Token } from '@traveloka/web-components';

import Hotel from '@traveloka/icon-kit-web/svg/dark/ic_product_duotone_hotel-fill_24px.svg';

export const IMAGE_HEIGHT = 98;

type HotelImageProps = {
  src?: string;
};

function HotelImage(props: HotelImageProps) {
  const { src } = props;

  const placeholderStyle = {
    backgroundColor: Token.color.uiLightNeutral,
  };

  const [isError, setIsError] = useState(false);
  const handleError = useCallback(() => setIsError(true), [setIsError]);

  if (!src || isError) {
    return (
      <View style={[Style.placeholder, placeholderStyle]}>
        <Icon src={Hotel} />
      </View>
    );
  }

  return (
    <Image
      src={src}
      width={'100%'}
      height={IMAGE_HEIGHT}
      objectFit="cover"
      onError={handleError}
    />
  );
}

const Style = StyleSheet.create({
  placeholder: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: IMAGE_HEIGHT,
  },
});

export default HotelImage;

import React, { useEffect, useState, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth, AuthLoading } from '@traveloka/ctv-core';

import { useProfileContext, ProfileProvider } from './contexts/ProfileContext';

function RouterInterceptor(props: PropsWithChildren<{}>) {
  const history = useHistory();
  const [currentPathname, setCurrentPathname] = useState<string>('');

  const profile = useProfileContext();
  const auth = useAuth();

  useEffect(() => {
    if (!currentPathname) {
      profile.fetchData();
    }

    return history.listen(location => {
      if (!auth.isAuthenticated) {
        return;
      }

      if (location.pathname !== currentPathname) {
        profile.fetchData();
        setCurrentPathname(location.pathname);
      }
    });
  }, [auth, currentPathname]);

  const profileLoading = profile.isFetching && !profile.data;
  const loading = auth.isAuthenticated && profileLoading;
  if (loading) {
    return <AuthLoading />;
  }

  return <>{props.children}</>;
}

function Wrapper(props: PropsWithChildren<{}>) {
  return (
    <ProfileProvider>
      <RouterInterceptor {...props} />
    </ProfileProvider>
  );
}

export default Wrapper;

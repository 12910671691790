import React, { useCallback, useMemo, useState } from 'react';

import { addDays } from 'date-fns';

import {
  Permission,
  useAuth,
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { RoomSearchForm as RoomSearchFormUI } from '@traveloka/ctvweb-ui/hotel';
import { Traveler } from '@traveloka/ctvweb-ui/src/generic/TravelerPickerModal/types';

import { useProductRequest } from 'approval-system/shared/contexts/ProductRequestContext';
import { NonEmployeeFields } from 'hotel/search/components/HotelSearchForm/HotelSearchForm';
import {
  HotelNonEmployeeTraveler,
  NonEmployeeType,
} from 'hotel/shared/api/types';
import {
  MAX_DURATION,
  MAX_GUESTS,
  MAX_NON_EMPLOYEE_CHILDREN,
  MAX_ROOMS,
} from 'hotel/search/constants/SearchConstant';
import {
  stringifySearchSpec,
  convertNonEmployeeTypeToPayload,
  parseSearchSpec,
} from 'hotel/search/utils/SearchQueryUtil';
import {
  ComputedSearchSpec,
  defaultHotelNonEmployee,
  useSearchSpec,
  useSearchSpecDispatch,
} from 'hotel/shared/contexts/SpecContext';
import RevampTravelerPickerModal from 'shared/components/TravelerPickerModal/RevampTravelerPickerModal';
import useEagerNavigation from 'shared/hooks/useEagerNavigation';
import { formatMessage } from 'shared/utils/intl';

type Props = {
  onDropdownPress?(): void;
};

export default function RoomSearchForm1(props: Props) {
  const { onDropdownPress } = props;

  const searchSpec = useSearchSpec();
  const productRequest = useProductRequest();
  const { format } = useLocalizedDateFormat();

  const content = useContentResource().CorporateHotelDetailRoomSearchForm;
  const pickerContent = useContentResource().CorporateHotelSearchForm;

  const { user } = useAuth();
  const canBookNonEmployee = user?.has(
    Permission.BOOK_PRODUCT_FOR_NON_EMPLOYEE
  );

  const numberOfGuest =
    searchSpec.travelers.length + searchSpec.nonEmployeeTravelers.adults;

  const durationOptions = useMemo(() => {
    return Array.from({ length: MAX_DURATION }, (_, i) => {
      const night = i + 1;

      return {
        label: formatMessage(content.durationContent, { num: night }),
        value: night,
        subLabel: format(
          addDays(searchSpec.checkInDate, night),
          'SHORT_WEEKDAY'
        ),
      };
    });
  }, [searchSpec.checkInDate]);

  const roomOptions = useMemo(() => {
    return Array.from(
      { length: Math.min(numberOfGuest, MAX_ROOMS) },
      (_, i) => {
        const room = i + 1;

        return {
          label: formatMessage(content.roomContent, { num: room }),
          value: room,
        };
      }
    );
  }, [numberOfGuest]);

  const navigate = useEagerNavigation();
  const changeSpec = useCallback(
    (partialSpec: Partial<ComputedSearchSpec>) => {
      const spec = stringifySearchSpec({
        ...searchSpec,
        ...partialSpec,
      } as Required<ComputedSearchSpec>);

      setSearchSpec(parseSearchSpec(spec));
      navigate({
        pathname: '/hotel/detail',
        search: spec,
      });
    },
    [searchSpec, navigate]
  );

  const handleDayChange = useCallback(
    (date: Date) => {
      changeSpec({
        checkInDate: date,
        checkOutDate: addDays(date, searchSpec.duration),
      });
    },
    [searchSpec.duration, changeSpec]
  );

  const handleDurationChange = useCallback(
    (duration: number) => {
      changeSpec({
        checkOutDate: addDays(searchSpec.checkInDate, duration),
      });
    },
    [searchSpec.checkInDate, changeSpec]
  );

  const handleRoomChange = useCallback(
    (room: number) => {
      changeSpec({ rooms: room });
    },
    [changeSpec]
  );

  // TODO: Refactor traveler picker
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const setSearchSpec = useSearchSpecDispatch();

  const openTravelerPicker = useCallback(() => setIsPickerVisible(true), [
    setIsPickerVisible,
  ]);
  const closeTravelerPicker = useCallback(() => setIsPickerVisible(false), [
    setIsPickerVisible,
  ]);
  const handleTravelersChange = useCallback(
    (travelers: Traveler[], nonEmployeeFields?: NonEmployeeFields) => {
      let nonEmployeeTravelers = defaultHotelNonEmployee;
      if (canBookNonEmployee && nonEmployeeFields) {
        const value = { ...defaultHotelNonEmployee };
        nonEmployeeFields.forEach(field => {
          const convertedKey = convertNonEmployeeTypeToPayload(field.type);
          if (
            field.type === NonEmployeeType.ADULT &&
            convertedKey === 'adults'
          ) {
            value[convertedKey] = field.value;
          } else if (
            field.type === NonEmployeeType.CHILD_AGE &&
            convertedKey === 'childrenAges'
          ) {
            value[convertedKey] = field.value;
          }
        });
        nonEmployeeTravelers = value;
      }

      const rooms = Math.min(
        Math.max(
          travelers.length +
            ((nonEmployeeFields?.find(f => f.type === NonEmployeeType.ADULT)
              ?.value as number) ?? 0),
          1
        ),
        MAX_ROOMS
      );

      closeTravelerPicker();
      handleRoomChange(rooms);
      setIsPickerVisible(false);
      const spec = {
        ...searchSpec,
        rooms,
        nonEmployeeTravelers,
      };
      setSearchSpec({ ...spec, travelers: travelers.map(t => t.employeeId) });

      navigate({
        pathname: '/hotel/detail',
        search: stringifySearchSpec(spec as Required<ComputedSearchSpec>),
      });
    },
    [closeTravelerPicker, handleRoomChange, searchSpec]
  );

  const guestValue: string[] = [];
  if (
    searchSpec.travelers.length + searchSpec.nonEmployeeTravelers.adults >
    0
  ) {
    guestValue.push(
      formatMessage(content.adultContent, {
        num:
          searchSpec.travelers.length + searchSpec.nonEmployeeTravelers.adults,
      })
    );
  }
  if (searchSpec.nonEmployeeTravelers.childrenAges.length > 0) {
    guestValue.push(
      formatMessage(content.childContent, {
        num: searchSpec.nonEmployeeTravelers.childrenAges.length,
      })
    );
  }

  const maxTravelersErrorMessage = formatMessage(
    pickerContent.errorTooltipMaxTraveler,
    {
      maxTraveler: MAX_GUESTS,
      maxChildren: MAX_NON_EMPLOYEE_CHILDREN,
    }
  );
  const nonEmployeeFields: NonEmployeeFields | undefined = useMemo(
    () =>
      canBookNonEmployee
        ? [
            {
              type: NonEmployeeType.ADULT,
              value: searchSpec.nonEmployeeTravelers.adults,
              onValidate(
                employees: Traveler[],
                nonEmployees: NonEmployeeFields,
                additionalInfo?: { value: number }
              ) {
                const employee = employees.length;
                const neAdult = additionalInfo?.value ?? nonEmployees[0].value;
                const neChild = nonEmployees[1].value.length;

                const isNoUpdate = neAdult >= nonEmployees[0].value;
                if (employee + neAdult > MAX_GUESTS) {
                  return {
                    error: maxTravelersErrorMessage,
                    noUpdate: isNoUpdate,
                  };
                }

                if (employee + neAdult > 0) {
                  return null;
                }

                return neChild > 0
                  ? {
                      error: pickerContent.nonEmployeeTooltipNoAdult,
                      noUpdate: false,
                    }
                  : null;
              },
            },
            {
              type: NonEmployeeType.CHILD_AGE,
              value: searchSpec.nonEmployeeTravelers.childrenAges,
              onValidate(
                employees: Traveler[],
                nonEmployees: NonEmployeeFields,
                additionalInfo?: { value: number }
              ) {
                const neChild =
                  additionalInfo?.value ?? nonEmployees[1].value.length;

                return neChild > MAX_NON_EMPLOYEE_CHILDREN
                  ? {
                      error: formatMessage(
                        pickerContent.nonEmployeeTooltipMaxChildren,
                        {
                          maxChildren: MAX_NON_EMPLOYEE_CHILDREN,
                        }
                      ),
                      noUpdate: true,
                    }
                  : null;
              },
            },
          ]
        : undefined,
    [canBookNonEmployee, searchSpec.nonEmployeeTravelers]
  );

  return (
    <>
      <RoomSearchFormUI
        checkInDate={searchSpec.checkInDate}
        duration={searchSpec.duration}
        rooms={searchSpec.rooms}
        checkInDateLabel={content.checkInText}
        durationLabel={content.durationText}
        guestLabel={content.guestText}
        roomsLabel={content.roomText}
        checkInDateValue={format(searchSpec.checkInDate, 'SHORT_WEEKDAY')}
        durationValue={formatMessage(content.durationContent, {
          num: searchSpec.duration,
        })}
        guestValue={guestValue.join(', ')}
        roomsValue={formatMessage(content.roomContent, {
          num: searchSpec.rooms,
        })}
        durationOptions={durationOptions}
        roomOptions={roomOptions}
        onDayChange={date => handleDayChange(date)}
        onDurationChange={duration => handleDurationChange(duration)}
        onRoomChange={room => handleRoomChange(room)}
        onDropdownPress={onDropdownPress}
        onGuestPress={openTravelerPicker}
        testID="hotel.detail.room.form"
        labelID="hotel.detail.room.form"
      />
      {
        <RevampTravelerPickerModal
          isVisible={isPickerVisible}
          maximumTraveler={MAX_GUESTS}
          travelers={searchSpec.travelers}
          nonEmployeeFields={nonEmployeeFields}
          hasNonEmployee={Object.keys(searchSpec.nonEmployeeTravelers).some(
            key => {
              const k = key as keyof HotelNonEmployeeTraveler;
              if (k === 'childrenAges') {
                return (
                  searchSpec.nonEmployeeTravelers[k].length !==
                  defaultHotelNonEmployee[k].length
                );
              }
              return (
                searchSpec.nonEmployeeTravelers[k] !==
                defaultHotelNonEmployee[k]
              );
            }
          )}
          onClose={() => setIsPickerVisible(false)}
          onSave={handleTravelersChange}
          tripRequestEnabled={productRequest.enabled && !!productRequest.data}
          complianceLabel={pickerContent.travelerComplianceLabel}
          nonEmployeeEnablementCheckbox={
            pickerContent.nonEmployeeEnablementCheckbox
          }
          nonEmployeeTooltip={pickerContent.nonEmployeeTooltip}
          nonEmployeeSubtitle={pickerContent.nonEmployeeSubtitle}
          emptySelectedTravelerText={pickerContent.emptySelectedTravelerText}
          selectedTravelerText={pickerContent.selectedTravelerText}
          emptyTotalTravelerText={pickerContent.emptyTotalTravelerText}
          totalTravelerText={pickerContent.totalTravelerText}
          maxTravelersErrorMessage={maxTravelersErrorMessage}
          subtitle={formatMessage(pickerContent.travelerSubtitleLabel, {
            maxGuest: MAX_GUESTS,
          })}
          title={pickerContent.travelerTitleLabel}
        />
      }
    </>
  );
}

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Card, Icon, Text, Token } from '@traveloka/web-components';

import IcSystemStatusOkDoneFill from '@traveloka/icon-kit-web/svg/greenPrimary/ic_system_status_ok_done-fill_24px.svg';
import ClockIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_clock_24px.svg';

import IcSystemStatusFailFill from '@traveloka/icon-kit-web/svg/redPrimary/ic_system_status_fail-fill_24px.svg';

import { ProcessStep } from '../RegistrationProcess';

import VerificationStepsContent from './VerificationStepsContent';

type Props = {
  status: Exclude<ProcessStep, 'INITIAL_PROCESS'>;
};

export default function VerificationSteps(props: Props) {
  const { status } = props;

  const cr = useContentResource().CorporateRegistration;

  const titleMap = {
    VERIFICATION_PROCESS: {
      label: cr.verificationStepInProgress,
      icon: ClockIcon,
    },
    COMPLETED: {
      label: cr.verificationStepCompleted,
      icon: IcSystemStatusOkDoneFill,
    },
    REJECTED: {
      label: cr.verificationStepRejected,
      icon: IcSystemStatusFailFill,
    },
  };

  return (
    <Card style={styles.card}>
      <View style={styles.titleContainer}>
        <Icon src={titleMap[status].icon} width={20} height={20} />
        <Text style={styles.title} variant="title-2">
          {titleMap[status].label}
        </Text>
      </View>
      <VerificationStepsContent status={status} />
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    padding: Token.spacing.l,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Token.spacing.m,
  },
  title: {
    marginLeft: Token.spacing.s,
  },
});

import { CheckSearchSpecRequest } from 'approval-system/shared/api';
import ProductType from 'approval-system/shared/constants/ProductType';
import { FlightPrebookStore } from 'flight/prebook/utils/store';
import { convertToJava, CurrencyValue } from 'shared/utils/currency';

export function generateSearchSpecPayload(
  prebookData: FlightPrebookStore
): CheckSearchSpecRequest {
  return {
    productRequestId: prebookData.productRequestId,
    productType: ProductType.FLIGHT,
    travelers: prebookData.passengers.map(p => p.email),
    flightPreBookRequest: {
      price: convertToJava(
        prebookData.flights.reduce(
          (a, b) => {
            const { summary } = b;
            return {
              amount: a.amount + summary.totalPrice.amount,
              currency: summary.totalPrice.currency,
              decimalPoints: summary.totalPrice.decimalPoints,
            };
          },
          { amount: 0 } as CurrencyValue
        )
      ),
      flightIds: prebookData.flights.map(f => f.flightId),
    },
  };
}

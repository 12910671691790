import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useContentResource, useLocale } from '@traveloka/ctv-core';
import { MenuItem } from '@traveloka/ctvweb-ui';
import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import Account from '@traveloka/icon-kit-web/svg/blue/ic_user_account_24px.svg';
import Employement from '@traveloka/icon-kit-web/svg/blue/ic_user_my_bill_24px.svg';
import AccountActive from '@traveloka/icon-kit-web/svg/light/ic_user_account_24px.svg';
import EmployementActive from '@traveloka/icon-kit-web/svg/light/ic_user_my_bill_24px.svg';
import { Text, Token } from '@traveloka/web-components';

type Props = {
  testID?: string;
  currentPath?: string;
  headerStyle?: StyleProp<ViewStyle>;
  children?: ReactNode;
};

export default function ProfileMenu(props: Props) {
  const { testID, currentPath, headerStyle, children } = props;

  const { locale } = useLocale();
  const content = useContentResource().CorporateProfileSidebar;

  const menus = [
    {
      text: content.editProfileText,
      leftIcon: Account,
      leftIconActive: AccountActive,
      url: '/profile/edit',
      name: 'edit-profile',
    },
    {
      text: content.employmentDetailText,
      leftIcon: Employement,
      leftIconActive: EmployementActive,
      url: '/profile/employment-detail',
      name: 'employment-detail',
    },
  ];

  // Process to deprecate the repository
  // Decoupling from local react redirection state
  function redirectTo(url: string) {
    window.location.href = '/' + locale + url;
  }

  return (
    <View style={Style.dropdownProfileMenu}>
      <View style={headerStyle}>
        <Text style={Style.header}>{content.title}</Text>
      </View>
      {menus.map(menu => (
        <MenuItem
          {...menu}
          testID={appendTestId(testID, `menu.profile.${menu.name}`)}
          key={menu.url}
          isActive={menu.url === currentPath}
          onPress={() => redirectTo(menu.url)}
        />
      ))}
      {children}
    </View>
  );
}

const Style = StyleSheet.create({
  dropdownProfileMenu: {
    minWidth: 200,
    paddingVertical: Token.spacing.xs,
  },
  header: {
    fontWeight: Token.typography.weightMedium.fontWeight,
    letterSpacing: 1,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.m,
  },
});

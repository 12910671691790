import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { FormProvider, useForm } from 'react-hook-form';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Button, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import InputField from 'shared/components/form/InputField/InputField';
import { required } from 'shared/utils/validator';

type Props = {
  isLoading: boolean;
  isVisible: boolean;
  onClosePress(): void;
  onSubmit(reason: string): void;
};

const MAX_TEXT_LENGTH = 100;

type FormValue = {
  reason: string;
};

export default function ComplianceModal(props: Props) {
  const { isLoading, isVisible, onClosePress, onSubmit } = props;

  const content = useContentResource().CorporateHotelPrebookComplianceModal;
  const methods = useForm<FormValue>();
  const { handleSubmit: formSubmit } = methods;

  const handleSubmit = useCallback(
    (value: FormValue) => {
      onSubmit(value.reason);
    },
    [onSubmit]
  );

  return (
    <Modal isVisible={isVisible}>
      <Popup
        showCloseButton={false}
        title={content.title}
        width={640}
        maxWidth={640}
      >
        <Text style={Style.help}>{content.infoText}</Text>
        <FormProvider {...methods}>
          <InputField
            name="reason"
            placeholder={content.reasonFieldPlaceholder}
            multiline
            textInputStyle={Style.input}
            maxLength={MAX_TEXT_LENGTH}
            validate={value => {
              if (required(value) === false) {
                return content.reasonRequiredErrorMessage;
              }

              return;
            }}
          />
          <View style={Style.actionContainer}>
            <Button
              variant="secondary"
              text={content.closeButtonText}
              onPress={onClosePress}
            />
            <Button
              variant="main-cta"
              text={content.submitButtonText}
              loading={isLoading}
              onPress={formSubmit(handleSubmit)}
              style={Style.continue}
            />
          </View>
        </FormProvider>
      </Popup>
    </Modal>
  );
}

const Style = StyleSheet.create({
  help: {
    marginBottom: Token.spacing.l,
  },
  input: {
    height: Token.typography.uiBaseline.lineHeight * 3,
  },
  actionContainer: {
    marginTop: Token.spacing.m,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  continue: {
    marginLeft: Token.spacing.m,
  },
});

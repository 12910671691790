import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Divider } from '@traveloka/ctvweb-ui';
import { useTheme, Button, Card, Text, Token } from '@traveloka/web-components';

type Props = {
  onDetailPress?(): void;
  travelersLabel: string;
  travelers: Array<{
    name: string;
    subtitle?: string;
  }>;
  tripId: string;
  productName: string;
  productColor: string;
  productBreakdown: Array<{
    testID?: string;
    label: string;
  }>;
};

export default function DetailCard(props: Props) {
  const {
    tripId,
    onDetailPress,
    travelers,
    productName,
    productColor,
    productBreakdown,
    travelersLabel,
  } = props;
  const { color } = useTheme();
  const oddStyle = {
    backgroundColor: color.lightStain,
  };
  const productNameStyle = {
    borderColor: productColor,
  };

  const content = useContentResource().CorporateProductPaymentDetailCard;

  return (
    <Card style={Style.card}>
      <View style={Style.header}>
        <Text variant="ui-tiny" ink="secondary" style={Style.title}>
          {content.title}
        </Text>
        <Text testID="confirmation-payment.trip-detail.trip-id">{tripId}</Text>
      </View>
      <Divider margin="none" />
      <View style={Style.body}>
        <View style={Style.control}>
          <Text variant="ui-tiny" ink="secondary" style={Style.title}>
            {content.tripDetailText}
          </Text>
          {onDetailPress && (
            <Button
              variant="text"
              size="small"
              text={content.detailButtonText}
              onPress={onDetailPress}
            />
          )}
        </View>
        <Text
          testID="confirmation-payment.trip-detail.product-name"
          style={[Style.productName, productNameStyle]}
        >
          {productName}
        </Text>
        {productBreakdown.map((item, index) => (
          <View key={index} testID={item.testID} style={Style.item}>
            <Text variant="ui-tiny" ink="secondary" style={Style.bullet}>
              {'\u2022'}
            </Text>
            <Text variant="ui-tiny" ink="secondary" style={Style.label}>
              {item.label}
            </Text>
          </View>
        ))}
      </View>
      <Divider margin="none" />
      <View style={Style.footer}>
        <Text
          variant="ui-tiny"
          ink="secondary"
          style={[Style.footerTitle, Style.title]}
        >
          {travelersLabel}
        </Text>
        {travelers.map((traveler, index) => (
          <View
            key={index}
            style={[Style.travelers, index % 2 === 1 && oddStyle]}
          >
            <Text
              testID={`confirmation-payment.trip-detail.travelers.name-${index}`}
              variant="ui-small"
              style={Style.name}
              numberOfLines={1}
            >
              {traveler.name}
            </Text>
            {traveler.subtitle && (
              <Text variant="ui-tiny" ink="secondary">
                {traveler.subtitle}
              </Text>
            )}
          </View>
        ))}
      </View>
    </Card>
  );
}

const Style = StyleSheet.create({
  card: {
    width: 280,
  },
  header: {
    padding: Token.spacing.m,
  },
  body: {
    paddingTop: Token.spacing.xs,
    paddingBottom: Token.spacing.xs,
  },
  footer: {
    paddingVertical: Token.spacing.m,
  },
  title: {
    fontWeight: Token.typography.weightMedium.fontWeight,
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  control: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Token.spacing.m,
    alignItems: 'center',
    marginBottom: Token.spacing.xs,
  },
  productName: {
    borderLeftWidth: Token.border.width.bold,
    paddingHorizontal: Token.spacing.m - Token.border.width.bold,
  },
  item: {
    paddingHorizontal: Token.spacing.m,
    marginBottom: Token.spacing.xxs,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  bullet: {
    width: 16,
    userSelect: 'none',
  },
  label: {
    flex: 1,
  },
  footerTitle: {
    paddingHorizontal: Token.spacing.m,
    marginBottom: Token.spacing.xs,
  },
  travelers: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.xs,
  },
  name: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
});

import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { useTheme, Card, Text, Token } from '@traveloka/web-components';
import Label from '@traveloka/web-components/src/momentum/Input/Label';

import { usePrebook } from 'hotel/prebook/contexts/PrebookProvider';
import SharedStyle from 'hotel/prebook/hotelPrebook.style';
import { MAX_GUESTS } from 'hotel/search/constants/SearchConstant';
import PhoneField, {
  Value as PhoneFieldValue,
} from 'shared/components/form/PhoneField/PhoneField';
import { formatMessage } from 'shared/utils/intl';
import {
  isPhone,
  maxLength,
  minLength,
  required,
} from 'shared/utils/validator';

export default function ContactForm() {
  const { profile } = usePrebook();

  const content = useContentResource().CorporateHotelPrebookContactForm;

  const { color } = useTheme();
  const inputStyle = {
    borderColor: color.lightSecondary,
  };

  const initialValue = useMemo(() => {
    let phoneValue: PhoneFieldValue | undefined = undefined;
    if (profile.phone && profile.phoneCountryCode) {
      phoneValue = {
        phone: profile.phone,
        prefix: profile.phoneCountryCode,
      };
    }

    return { phoneValue };
  }, [profile]);

  return (
    <>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {content.title}
      </Text>
      <Card style={[SharedStyle.card, { zIndex: MAX_GUESTS + 2 }]}>
        <View style={SharedStyle.fieldGroup}>
          <Label text="Name" />
          <Text ink="secondary" style={[Style.inputLike, inputStyle]}>
            {profile.fullname}
          </Text>
        </View>
        <View style={SharedStyle.row}>
          <View style={SharedStyle.col}>
            <PhoneField
              name="contact.phone"
              label={content.phoneField}
              leftHelper={content.phoneFieldDescription}
              defaultValue={initialValue.phoneValue}
              validate={value => {
                if (
                  value === undefined ||
                  required(value.phone) === false ||
                  required(value.prefix) === false
                ) {
                  return content.phoneRequiredErrorMessage;
                } else if (minLength(value.phone, 7) === false) {
                  return formatMessage(content.phoneMinLengthErrorMessage, {
                    length: 7,
                  });
                } else if (maxLength(value.phone, 15) === false) {
                  return formatMessage(content.phoneMaxLengthErrorMessage, {
                    length: 15,
                  });
                } else if (isPhone(value.phone) === false) {
                  return content.phoneNumericOnlyErrorMessage;
                }

                return;
              }}
            />
          </View>
          <View style={SharedStyle.col}>
            <Label text={content.emailField} />
            <Text ink="secondary" style={[Style.inputLike, inputStyle]}>
              {profile.email}
            </Text>
          </View>
        </View>
      </Card>
    </>
  );
}

const Style = StyleSheet.create({
  inputLike: {
    borderWidth: Token.border.width.thick,
    borderRadius: Token.border.radius.normal,
    padding: Token.spacing.xs - 1,
  },
});

import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useTheme, Text, Token } from '@traveloka/web-components';
import { Props as TextProps } from '@traveloka/web-components/src/momentum/Text/Text';

import { appendTestId } from '../../shared/utils/TestUtil';

type Props = {
  border?: boolean;
  left: string;
  right?: ReactNode;
  leftVariant?: TextProps['variant'];
  rightStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  tint?: boolean;
  testIDPrefix?: string;
};

export default function ViewRow(props: Props) {
  const {
    border,
    left,
    right,
    leftVariant,
    rightStyle,
    style,
    tint,
    testIDPrefix,
  } = props;

  const { color } = useTheme();
  const borderColor = { borderBottomColor: color.borderDivide };
  const isString = typeof right === 'string';

  return (
    <View
      style={[
        Style.row,
        tint && { backgroundColor: color.lightStain },
        border && [Style.border, borderColor],
        style,
      ]}
    >
      <Text
        testID={appendTestId(testIDPrefix, 'left')}
        style={Style.left}
        variant={leftVariant || 'ui-small'}
      >
        {left}
      </Text>
      {Boolean(right) && isString && (
        <Text
          testID={appendTestId(testIDPrefix, 'right')}
          variant="ui-tiny"
          ink="secondary"
          style={[Style.right, Style.baseline, rightStyle]}
        >
          {right}
        </Text>
      )}
      {Boolean(right) && !isString && (
        <View
          testID={appendTestId(testIDPrefix, 'right')}
          style={[Style.right, rightStyle]}
        >
          {right}
        </View>
      )}
    </View>
  );
}

const Style = StyleSheet.create({
  row: {
    flexDirection: 'row',
    padding: Token.spacing.xxs,
  },
  border: {
    borderBottomWidth: Token.border.width.thick,
    borderBottomStyle: 'solid',
  },
  left: {
    flex: 3,
    paddingHorizontal: Token.spacing.xxs,
  },
  right: {
    flex: 4,
    paddingHorizontal: Token.spacing.xxs,
    textAlign: 'right',
  },
  baseline: {
    alignItems: 'baseline',
  },
});

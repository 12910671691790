import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Keyboard,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  TextInputKeyPressEventData,
  View,
} from 'react-native';

import ChevronDownIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_16px.svg';
import ChevronDownIconGray from '@traveloka/icon-kit-web/svg/lightSecondary/ic_system_chevron_down_16px.svg';
import { Card, Icon, Token } from '@traveloka/web-components';

import useOnClickOutside from '../../shared/hooks/useOnClickOutside';
import Fade from '../../shared/components/Fade/Fade';
import Input, {
  Props as InputProps,
} from '../../shared/components/form/Input/Input';
import { appendTestId } from '../../shared/utils/TestUtil';
import HotelAutocompleteList from './HotelAutocompleteList';
import AutocompleteType, { Item } from './types';
import { CarouselLoader, ImageCarousel } from '@traveloka/ctvweb-ui';
import HotelAutocompleteHeader from './HotelAutocompleteHeader';
import IntlMessageformat from 'intl-messageformat';
import HotelCard, { HotelCardLoader } from '../HotelCard/HotelCard';
import { IMAGE_HEIGHT } from '../HotelCard/HotelImage';
import { Item as HotelCardItem } from '../HotelCard/types';
import HotelAutocompleteEmpty from './HotelAutocompleteEmpty';

const HIGHLIGHT_DURATION = 1000;
const CARD_HEIGHT =
  Token.typography.title3.lineHeight +
  Token.typography.captionMicro.lineHeight +
  Token.typography.uiSmall.lineHeight +
  Token.spacing.m * 2 +
  Token.spacing.s +
  Token.spacing.xxs +
  IMAGE_HEIGHT;
const CONTAINER_HEIGHT = CARD_HEIGHT + Token.spacing.m * 2;

export type Props<T> = {
  cityTestID?: string;
  width?: number;
  onPressItem?: (item: Item, type?: AutocompleteType) => void;
  items: T[];
  itemsLoading: boolean;
  cityItems: T[];
  cityLoading: boolean;
  propertyItems: HotelCardItem[];
  propertyLoading: boolean;

  searchable?: boolean;
  position?: 'down' | 'up';
  dropdownHeight?: number;
  destinationTypeMore: string;
  popularDestinationText: string;
  destinationEmptyTitle: string;
  destinationEmptyDescription: string;
  recentlySearchCityText: string;
  recentlyViewedHotelText: string;
  recentlyViewedHotelWithKeywordText: string;
  startFromText: string;
} & InputProps;

export default function HotelAutocomplete<T extends Item>(props: Props<T>) {
  const {
    items,
    itemsLoading,
    cityItems,
    cityLoading,
    propertyItems,
    propertyLoading,
    onChangeText = noop,
    onPressItem = noop,
    value,
    style,
    width,
    editable,
    searchable,
    position = 'down',
    testID,
    cityTestID,
    dropdownHeight = 531,
    destinationTypeMore,
    popularDestinationText,
    destinationEmptyTitle,
    destinationEmptyDescription,
    recentlySearchCityText,
    recentlyViewedHotelText,
    recentlyViewedHotelWithKeywordText,
    startFromText,
    ...inputProps
  } = props;
  const rootRef = useRef<View>(null);
  const totalItemsLength = useRef(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterText, setFilterText] = useState<string>('');
  const [filterItems, setFilterItems] = useState<Item[]>([]);
  const [filterCityItems, setFilterCityItems] = useState<Item[]>(cityItems);
  const [filterPropertyItems, setFilterPropertyItems] = useState<
    HotelCardItem[]
  >(propertyItems);
  const [highlighted, setHighlighted] = useState(false);

  function switchPosition() {
    switch (position) {
      case 'down':
        return Style.positionDown;
      case 'up':
        return Style.positionUp;
    }
  }

  function handleFocus() {
    setActiveIndex(0);
    setShowDropdown(true);
    handleChangeText('');
  }

  function handlePressItem(item: Item, type: AutocompleteType) {
    setShowDropdown(false);
    onPressItem(item, type);

    // Highlighted form input
    setHighlighted(true);
    setTimeout(() => {
      setHighlighted(false);
    }, HIGHLIGHT_DURATION);
  }

  function filterItem(items: T[], text: string) {
    return items.filter(item => {
      if (item.label.toLocaleLowerCase().includes(text.toLowerCase())) {
        return true;
      } else if (item.subLabel) {
        return item.subLabel.toLocaleLowerCase().includes(text.toLowerCase());
      }
      return false;
    });
  }

  function filterHotelItem(items: HotelCardItem[], text: string) {
    return items.filter(item => {
      if (item.label.toLocaleLowerCase().includes(text.toLowerCase())) {
        return true;
      }
      return false;
    });
  }

  function filter(text: string) {
    if (searchable) {
      // Filter Hotel Autocomplete
      setFilterItems(filterItem(items, text));

      // Filter Recently Search City
      setFilterCityItems(filterItem(cityItems, text));

      // Filter Recently Viewed Hotel
      setFilterPropertyItems(filterHotelItem(propertyItems, text));
    }
  }

  function handleChangeText(text: string) {
    filter(text);
    setFilterText(text);
    onChangeText(text);
  }

  function moveCursor(increment: number) {
    let newIndex = activeIndex + increment;

    if (newIndex < 0) {
      newIndex = totalItemsLength.current - 1;
    } else if (newIndex >= totalItemsLength.current) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  }

  function handleKeyPress(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    switch (e.nativeEvent.key) {
      case 'ArrowUp':
        moveCursor(-1);
        break;
      case 'ArrowDown':
        moveCursor(+1);
        break;
      case 'Escape':
      case 'Tab':
        Keyboard.dismiss();
        setShowDropdown(false);
        break;
      case 'Enter':
        const startCityIndex = filterPropertyItems.length;
        const startIndex = startCityIndex + filterCityItems.length;
        if (
          filterPropertyItems.length > 0 &&
          activeIndex < filterPropertyItems.length
        ) {
          handlePressItem(
            {
              label: filterPropertyItems[activeIndex].label,
              value: filterPropertyItems[activeIndex].value,
              badgeText: '',
            },
            AutocompleteType.POLULAR_DESTINATION
          );
        } else if (
          filterCityItems.length > 0 &&
          activeIndex - startCityIndex < filterCityItems.length
        ) {
          handlePressItem(
            filterCityItems[activeIndex - startCityIndex],
            AutocompleteType.RECENT_SEARCH_CITY
          );
        } else if (
          filterItems.length > 0 &&
          activeIndex - startIndex < filterItems.length
        ) {
          handlePressItem(
            filterItems[activeIndex - startIndex],
            AutocompleteType.RECENT_SEARCH_PROPERTY
          );
        }
        setShowDropdown(false);
        break;
    }
    return;
  }

  useOnClickOutside(rootRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    setFilterItems(prev => (prev === items ? prev : items));
  }, [setFilterItems, items]);

  useEffect(() => {
    totalItemsLength.current =
      filterPropertyItems.length + filterCityItems.length + filterItems.length;
  }, [filterItems, filterCityItems, filterPropertyItems]);

  useEffect(() => {
    if (filterText === '' && propertyItems !== filterPropertyItems) {
      setFilterPropertyItems(propertyItems);
    }
  }, [propertyItems]);

  useEffect(() => {
    if (filterText === '' && cityItems !== filterCityItems) {
      setFilterCityItems(cityItems);
    }
  }, [cityItems]);

  const headerText = useMemo(() => {
    if (filterText) {
      return formatMessage(recentlyViewedHotelWithKeywordText, {
        keyword: filterText,
      });
    }
    return recentlyViewedHotelText;
  }, [filterText]);

  return (
    <View ref={rootRef} style={style}>
      <Input
        {...inputProps}
        testID={appendTestId(testID, 'input')}
        editable={editable}
        onFocus={handleFocus}
        onChangeText={handleChangeText}
        onKeyPress={handleKeyPress}
        value={showDropdown && editable ? filterText : value}
        iconRight={
          inputProps.disabled ? (
            <Icon src={ChevronDownIconGray} />
          ) : (
            <Icon src={ChevronDownIcon} />
          )
        }
        containerStyle={highlighted && Style.highlightInput}
      />
      <Fade
        visible={showDropdown}
        style={[Style.dropdown, switchPosition(), { width }]}
      >
        <Card elevation="float" style={{ maxHeight: dropdownHeight }}>
          <ScrollView>
            {/* Recently Viewed Hotel  */}
            {(filterPropertyItems.length > 0 || propertyLoading) && (
              <View>
                <HotelAutocompleteHeader label={headerText} />
                <View style={Style.carouselWrapper}>
                  {propertyLoading ? (
                    <CarouselLoader
                      column={5}
                      containerHeight={CONTAINER_HEIGHT}
                      renderItem={({ index }) => (
                        <HotelCardLoader key={index} />
                      )}
                    />
                  ) : (
                    <ImageCarousel
                      column={5}
                      lists={filterPropertyItems}
                      containerHeight={CONTAINER_HEIGHT}
                      renderItem={({ item, index }) => (
                        <HotelCard
                          key={index}
                          testID={`hotel.card.list.${index}`}
                          startFromText={startFromText}
                          filterText={filterText}
                          isActive={activeIndex === index}
                          onPressItem={item => {
                            handlePressItem(
                              {
                                label: item.label,
                                value: item.value,
                                badgeText: '',
                              },
                              AutocompleteType.RECENT_SEARCH_PROPERTY
                            );
                          }}
                          {...item}
                        />
                      )}
                    />
                  )}
                </View>
              </View>
            )}

            {/* Recently Search City */}
            {(filterCityItems.length > 0 || cityLoading) && (
              <HotelAutocompleteList
                testID={cityTestID}
                items={filterCityItems}
                onPressItem={item =>
                  handlePressItem(item, AutocompleteType.RECENT_SEARCH_CITY)
                }
                startIndex={filterPropertyItems.length}
                activeIndex={activeIndex}
                filterText={filterText}
                headerText={recentlySearchCityText}
                isLoading={cityLoading}
              />
            )}

            {/* Autocomplete Hotel */}
            {filterItems.length > 0 || itemsLoading ? (
              <HotelAutocompleteList
                testID={testID}
                items={filterItems}
                startIndex={filterPropertyItems.length + filterCityItems.length}
                onPressItem={item =>
                  handlePressItem(item, AutocompleteType.POLULAR_DESTINATION)
                }
                activeIndex={activeIndex}
                filterText={filterText}
                headerText={
                  filterText ? destinationTypeMore : popularDestinationText
                }
                isLoading={itemsLoading}
              />
            ) : (
              <HotelAutocompleteEmpty
                title={formatMessage(destinationEmptyTitle, {
                  keyword: filterText,
                })}
                description={destinationEmptyDescription}
                filterText={filterText}
              />
            )}
          </ScrollView>
        </Card>
      </Fade>
    </View>
  );
}

const Style = StyleSheet.create({
  dropdown: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 10,
  },
  positionDown: {
    paddingTop: Token.spacing.xs,
    top: '100%',
  },
  positionUp: {
    bottom: '100%',
  },
  highlightInput: {
    borderColor: Token.color.uiBluePrimary,
    borderWidth: Token.border.width.thick,
    borderRadius: 7,
  },
  carouselWrapper: {
    paddingHorizontal: Token.spacing.xxs,
  },
});

// ===== HELPERS
function noop() {}
function formatMessage(
  entry: string,
  params?: Record<string, string | number>
) {
  try {
    return new IntlMessageformat(entry).format(params);
  } catch (err) {
    console.error('Missing content resource variable passed', { err });
    return entry;
  }
}

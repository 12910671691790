import React from 'react';
import { View, StyleSheet, ViewStyle, StyleProp } from 'react-native';

import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import CreditIcon from '@traveloka/icon-kit-web/svg/darkStain/ic_payment_credit_card-fill_24px.svg';
import CreditUnavailableIcon from '@traveloka/icon-kit-web/svg/blueDark/ic_payment_credit_card_unavailable_16px.svg';
import XIcon from '@traveloka/icon-kit-web/svg/darkStain/ic_system_cross_close_16px.svg';
import { Token, Icon } from '@traveloka/web-components';

import { CreditLimitStatus } from 'credit-limit/context';
import { creditLimitColorSwitcher } from 'credit-limit/shared/utils/StatusSwitcher';

type IconSize = 'normal' | 'tiny';

type Props = {
  style?: StyleProp<ViewStyle>;
  status?: CreditLimitStatus;
  size?: IconSize;
  testID?: string;
  isSuspended?: boolean;
};

function CreditLimitIcon(props: Props) {
  const { style, status, size = 'normal', testID, isSuspended } = props;

  const color = {
    backgroundColor: creditLimitColorSwitcher(status),
  };

  const componentSize = sizeSwitcher(size);

  return (
    <View
      testID={appendTestId(testID, `credit-limit.icon.${status}`)}
      style={[componentSize.containerSize, style]}
    >
      <Icon
        src={isSuspended ? CreditUnavailableIcon : CreditIcon}
        width={componentSize.iconSize}
        height={componentSize.iconSize}
      />
      {!isSuspended && (
        <View style={[Style.dot, color, componentSize.dotStyle]}>
          {status === undefined && (
            <Icon
              src={XIcon}
              width={componentSize.dotStyle.width - 2}
              height={componentSize.dotStyle.height - 2}
            />
          )}
        </View>
      )}
    </View>
  );
}

function sizeSwitcher(size: IconSize) {
  switch (size) {
    case 'normal':
      return {
        containerSize: {
          width: 21,
          height: 21,
        },
        iconSize: 21,
        dotStyle: {
          width: 11,
          height: 11,
          left: 13,
          top: -3,
        },
      };
    case 'tiny':
      return {
        containerSize: {
          width: 16,
          height: 16,
        },
        iconSize: 16,
        dotStyle: {
          width: 7,
          height: 7,
          left: 9,
          top: -2,
        },
      };
  }
}

const Style = StyleSheet.create({
  dot: {
    position: 'absolute',
    borderRadius: Token.border.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CreditLimitIcon;

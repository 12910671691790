import React from 'react';

import { useContentResource } from '@traveloka/ctv-core/resource';
import { Card, Text } from '@traveloka/web-components';

import { usePrebook } from 'hotel/prebook/contexts/PrebookProvider';
import SharedStyle from 'hotel/prebook/hotelPrebook.style';

export default function CancelationPolicy() {
  const { room } = usePrebook();

  const content = useContentResource().CorporateHotelPrebookCancellationPolicy;

  return (
    <>
      <Text variant="title-1" style={SharedStyle.sectionTitle}>
        {content.title}
      </Text>
      <Card style={SharedStyle.card}>
        <Text>{room.refundPolicy}</Text>
      </Card>
    </>
  );
}

import uuidV4 from 'uuid/v4';

import { Traveler } from '@traveloka/ctvweb-ui/src/generic/TravelerPickerModal/types';

import { NormalFlightSearchData } from 'flight/search/utils/flight-search-view-util';
import { FlightNonEmployeeTraveler } from 'flight/shared/api/types';
import JourneyType from 'flight/shared/constants/journey-type';
import expirableStorage from 'shared/utils/expirable-storage';

import { PaymentApproval } from '../types';

export type FlightPrebookStore = {
  searchId: Nullable<string>;
  journeyType: JourneyType;
  passengers: Traveler[];
  nonEmployeeTravelers: FlightNonEmployeeTraveler;
  flights: NormalFlightSearchData[];
  searchSpec: string;
} & Omit<PaymentApproval, 'preBookingStatus'>;

const STORAGE_KEY = 'fb';

export function set(data: FlightPrebookStore): string {
  const key = `${STORAGE_KEY}-${uuidV4()}`;

  expirableStorage.set(key, data);

  return key;
}

export function get(key: string): Nullable<FlightPrebookStore> {
  const cached = expirableStorage.get<FlightPrebookStore>(key);

  if (cached) {
    return Object.assign(cached, {
      flights: cached.flights.map(flight =>
        Object.assign(flight, {
          summary: Object.assign(flight.summary, parseDate(flight.summary)),
          segments: flight.segments.map(segment =>
            Object.assign(segment, parseDate(segment))
          ),
          journeys: flight.journeys.map(journey =>
            Object.assign(journey, {
              segments: journey.segments.map(segment =>
                Object.assign(segment, parseDate(segment))
              ),
            })
          ),
        })
      ),
    });
  }

  return null;
}

export default { get, set };

// ===== HELPERS

type DateTimeString = {
  departureDateTime: string;
  arrivalDateTime: string;
};

function parseDate(dtString: DateTimeString) {
  return {
    departureDateTime: new Date(dtString.departureDateTime),
    arrivalDateTime: new Date(dtString.arrivalDateTime),
  };
}

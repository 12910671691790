import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource, useLocale } from '@traveloka/ctv-core';
import Wallet from '@traveloka/ctvweb-ui/img/wallet.png';
import { Button, Image, Text, Token } from '@traveloka/web-components';

type Props = {
  tripId: string;
};

export default function Confirmed(props: Props) {
  const content = useContentResource().CorporateProductPaymentConfirmed;
  const { locale } = useLocale();

  return (
    <View style={Style.container}>
      <Image src={Wallet} width={231} height={250} style={Style.image} />
      <Text
        testID="confirmation-payment.success"
        variant="ui-large"
        style={Style.text}
      >
        {content.successText}
      </Text>
      <Text ink="secondary" style={Style.text}>
        {content.infoText}
      </Text>
      <Button
        testID="confirmation-payment.button.purchase-detail"
        text={content.goToPurchaseDetailButtonText}
        onPress={() => {
          window.location.assign(`/${locale}/booking/purchase/${props.tripId}`);
        }}
      />
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    width: 600,
    marginHorizontal: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    marginBottom: Token.spacing.xl,
  },
  image: {
    marginBottom: Token.spacing.xl,
  },
});

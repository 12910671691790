import htmr from 'htmr';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { ScrollView, StyleSheet, View } from 'react-native';

import { useApi, useFeatureControl } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { Divider } from '@traveloka/ctvweb-ui';
import { BookingReviewSummary } from '@traveloka/ctvweb-ui/flight';
import FlightIcon from '@traveloka/icon-kit-web/svg/blue/ic_product_flight-fill_24px.svg';
import IcSystemCrossClose from '@traveloka/icon-kit-web/svg/light/ic_system_cross_close_16px.svg';
import { Button, Icon, Popup, Text, Token } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import InsufficientCreditLimitModal from 'credit-limit/components/InsufficientCreditLimitModal/InsufficientCreditLimitModal';
import {
  useAddOnsContext,
  useFlightsContext,
  useJourneyTypeContext,
  usePassengersContext,
  usePaymentApprovalContext,
  useSearchSpecContext,
} from 'flight/prebook/contexts/FlightPrebookContext';
import { usePrice } from 'flight/prebook/contexts/PriceContext';
import { PolicyBypassRemarks, PrebookForm } from 'flight/prebook/types';
import getFlightBookingErrorMessage, {
  FlightErrorMessage,
} from 'flight/prebook/utils/getFlightBookingErrorMessage';
import { generatePrebookSpec } from 'flight/prebook/utils/prebookSpecUtil';
import {
  ONE_WAY_SEPARATOR,
  ROUND_TRIP_SEPARATOR,
} from 'flight/search/constants/flight-search-constant';
import {
  SUBMIT_BOOKING_API,
  SubmitBookingRequest,
  SubmitBookingResponse,
} from 'flight/shared/api';
import { NonEmployeeType } from 'flight/shared/api/types';
import JourneyType from 'flight/shared/constants/journey-type';
import InputField from 'shared/components/form/InputField/InputField';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';
import { convert } from 'shared/utils/currency';
import { formatCurrency, formatMessage } from 'shared/utils/intl';
import { maxLength, required } from 'shared/utils/validator';

import { useSearchIdContext } from '../../contexts/FlightPrebookContext';
import PopupGirl from '../PopupGirl/PopupGirl';

type Props = {
  isVisible?: boolean;
  onClose: () => void;
};

export default function ConfirmationModal(props: Props) {
  const { isVisible, onClose } = props;
  const {
    isFetching: isAccountStatusFetching,
    fetchAccountStatus,
  } = useAccountStatus();
  const history = useLocalizedHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReasonModalVisible, setIsReasonModalVisible] = useState(false);
  const [isFnaModalVisible, setIsFnaModalVisible] = useState(false);
  const [
    isInsufficientCreditVisible,
    setIsInsufficientCreditVisible,
  ] = useState(false);
  const [isGeneralErrorModalVisible, setIsGeneralErrorModalVisible] = useState<
    FlightErrorMessage
  >();
  const submitTimeoutCount = useRef(0);

  const confirmationContent = useContentResource()
    .CorporateFlightPrebookConfirmationModal;
  const reasonContent = useContentResource().CorporateFlightPrebookReasonModal;
  const availabilityContent = useContentResource()
    .CorporateFlightPrebookAvailabilityModal;
  const priceChangeContent = useContentResource()
    .CorporateFlightPrebookPriceChangeModal;
  const flightBookingMessage = useContentResource()
    .CorporateFlightBookingErrorMessages;
  const nonEmployeeContent = useNonEmployeeContent();

  const searchId = useSearchIdContext();
  const searchSpec = useSearchSpecContext();
  const flights = useFlightsContext();
  const journeyType = useJourneyTypeContext();
  const passengers = usePassengersContext();
  const addOns = useAddOnsContext();
  const paymentApproval = usePaymentApprovalContext();
  const { calculatedFee, setCalculatedFee, total } = usePrice();
  const [{ segments }] = flights;
  const { 0: firstSegment, [segments.length - 1]: lastSegment } = segments;
  const { enabled: documentTypeFc } = useFeatureControl(
    'b2b-document-type-new-behavior'
  );

  const { getValues } = useFormContext<Partial<PrebookForm>>();
  const values = getValues();

  const methods = useForm<PolicyBypassRemarks>();
  const {
    handleSubmit: formSubmit,
    getValues: getPolicyBypassRemarks,
  } = methods;

  const [priceChangeState, setPriceChangeState] = useState({
    isVisible: false,
    from: '',
    to: calculatedFee,
  });
  useEffect(() => {
    setPriceChangeState(state => ({ ...state, to: calculatedFee }));
  }, [calculatedFee]);

  const separator =
    journeyType === JourneyType.ONE_WAY
      ? ONE_WAY_SEPARATOR
      : ROUND_TRIP_SEPARATOR;
  const flight = [
    firstSegment.departureAirport.city,
    lastSegment.arrivalAirport.city,
  ].join(separator);

  const submitBooking = useApi<SubmitBookingResponse, SubmitBookingRequest>({
    domain: 'booking',
    method: 'post',
    path: SUBMIT_BOOKING_API,
  });

  async function handleSubmit() {
    const policyBypassRemark = getPolicyBypassRemarks();
    const spec = generatePrebookSpec(
      values as PrebookForm,
      searchId,
      flights,
      journeyType,
      passengers,
      addOns,
      calculatedFee,
      documentTypeFc,
      paymentApproval,
      policyBypassRemark
    );

    setIsSubmitting(true);

    submitBooking(spec)
      .then(res => {
        if (!res.success) {
          setIsSubmitting(false);
          return;
        }

        if (res.data.status === 'BOOKED') {
          submitTimeoutCount.current = 0;
          redirectToPayment(res.data.bookingId);

          return;
        } else {
          switch (res.data.bookingFailureReason) {
            case 'PRICE_CHANGED':
              const newFee = {
                totalFareFromClient: res.data.totalPriceCharged,
                grandTotal: res.data.grandTotal,
                serviceFee: res.data.serviceFee,
                vatFee: res.data.vatFee,
              };
              setCalculatedFee(newFee);
              setPriceChangeState({
                isVisible: true,
                from: formatCurrency(total),
                to: newFee,
              });
              onClose();
              break;
            case 'SOLD_OUT':
              setIsFnaModalVisible(true);
              onClose();
              break;
            case 'CREDIT_LIMIT_NOT_SUFFICIENT':
              setIsInsufficientCreditVisible(true);
              onClose();
              break;
            case 'BOOKING_SPEC_NOT_ALLOWED':
              setIsGeneralErrorModalVisible(
                getFlightBookingErrorMessage(
                  res.data.bookingFailureReason,
                  flightBookingMessage,
                  () => history.push('/flight/search' + searchSpec)
                )
              );
              break;
            case 'FAILED': {
              handleFailedError(res.data.bookingErrorMessage);
              break;
            }
            case null:
              break;
            default:
              // Unknown handler
              showGeneralError(res.data.bookingFailureReason);
              break;
          }
        }

        // Only disable this for bookingFailureReason === 'FAILED' and bookingErrorMessage === 'TIMEOUT'
        if (
          res.data.bookingFailureReason !== 'FAILED' ||
          res.data.bookingErrorMessage !== 'TIMEOUT'
        ) {
          setIsSubmitting(false);
        }
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  }

  function handleFailedError(
    bookingErrorMessage: SubmitBookingResponse['bookingErrorMessage']
  ) {
    if (!bookingErrorMessage) {
      return showGeneralError('FAILED');
    }

    if (bookingErrorMessage !== 'TIMEOUT') {
      return showGeneralError(bookingErrorMessage);
    }

    if (submitTimeoutCount.current++ >= 3) {
      setIsSubmitting(false);
      showGeneralError('TIMEOUT');
      submitTimeoutCount.current = 0;
    } else {
      handleSubmit();
    }
  }

  function showGeneralError(reason: string) {
    setIsGeneralErrorModalVisible(
      getFlightBookingErrorMessage(reason, flightBookingMessage)
    );
  }

  function handleCloseComplyingModal() {
    setIsReasonModalVisible(false);
  }

  function redirectToPayment(bookingId: string) {
    history.push({ pathname: `/payment/select/flight/${bookingId}` });
  }

  function handleSubmitOrPopup() {
    const isComplying = flights.every(flight => !flight.compliance);

    if (!isComplying) {
      setIsReasonModalVisible(true);
      onClose();
      return;
    }

    fetchAccountStatus(handleSubmit);
  }

  function redirectTo(pathname: string) {
    history.push({ pathname });
  }

  function handleClosePriceChange() {
    setPriceChangeState(prev => ({
      ...prev,
      isVisible: false,
    }));
  }

  function handleCloseGeneralErrorModal() {
    setIsGeneralErrorModalVisible(undefined);
  }

  return (
    <>
      <Modal isVisible={isVisible}>
        <Popup
          showCloseButton
          onCloseButtonPress={onClose}
          width={860}
          maxWidth={860}
          title={confirmationContent.title}
        >
          <View style={Style.spread}>
            <Text>{confirmationContent.headline}</Text>
            <View style={Style.product}>
              <Icon src={FlightIcon} />
              <Text variant="ui-small" ink="secondary" style={Style.flight}>
                {flight}
              </Text>
            </View>
          </View>
          <Divider />
          <View style={Style.summary}>
            {flights.map((flight, index) => {
              const title =
                index === 0
                  ? confirmationContent.departureTitleText
                  : confirmationContent.returnTitleText;

              return (
                <BookingReviewSummary
                  flight={flight}
                  style={Style.summaryItem}
                  label={{ title }}
                />
              );
            })}
          </View>

          <Text style={Style.travelerTitle}>
            {formatMessage(confirmationContent.travelerTitleText, {
              length:
                values.passengers?.adults.length ??
                0 +
                  (values.passengers?.children?.length ?? 0) +
                  (values.passengers?.infants?.length ?? 0),
            })}
          </Text>
          <Divider margin="none" />
          <ScrollView style={Style.scroll}>
            <View style={Style.scrollContent}>
              {passengers.map((passenger, index) => (
                <View
                  key={index}
                  style={[index === 0 ? Style.firstTraveler : Style.traveler]}
                >
                  <Text>{passenger.fullname}</Text>
                  <Text variant="ui-tiny" ink="secondary">
                    {passenger.email}
                  </Text>
                </View>
              ))}
              {values.passengers?.adults
                .slice(passengers.length)
                .map((traveler, index) => (
                  <View
                    key={index}
                    style={[
                      passengers.length + index === 0
                        ? Style.firstTraveler
                        : Style.traveler,
                    ]}
                  >
                    <Text>{traveler.fullName}</Text>
                    <Text variant="ui-tiny" ink="secondary">
                      {formatMessage(nonEmployeeContent(traveler.type), {
                        index: passengers.length + index + 1,
                      })}
                    </Text>
                  </View>
                ))}
              {values.passengers?.children?.map((traveler, index) => (
                <View key={index} style={Style.traveler}>
                  <Text>{traveler.fullName}</Text>
                  <Text variant="ui-tiny" ink="secondary">
                    {formatMessage(nonEmployeeContent(traveler.type), {
                      index: index + 1,
                    })}
                  </Text>
                </View>
              ))}
              {values.passengers?.infants?.map((traveler, index) => (
                <View key={index} style={Style.traveler}>
                  <Text>{traveler.fullName}</Text>
                  <Text variant="ui-tiny" ink="secondary">
                    {formatMessage(nonEmployeeContent(traveler.type), {
                      index: index + 1,
                    })}
                  </Text>
                </View>
              ))}
            </View>
          </ScrollView>
          <Divider margin="none" />

          <View style={[Style.spread, Style.price]}>
            <Text>{confirmationContent.totalPriceText}</Text>
            <Text
              testID="pre-book.confirmation.main-price"
              variant="ui-large"
              ink="price"
            >
              {formatCurrency(total)}
            </Text>
          </View>

          <View style={Style.control}>
            <View style={Style.help}>
              <Text variant="ui-tiny" ink="secondary">
                {confirmationContent.infoText}
              </Text>
            </View>
            <View style={Style.buttons}>
              <Button
                variant="secondary"
                text={confirmationContent.closeButtonText}
                onPress={onClose}
              />
              <Button
                testID="pre-book.confirmation.submit"
                variant="main-cta"
                text={confirmationContent.submitButtonText}
                onPress={handleSubmitOrPopup}
                style={Style.submit}
                loading={isAccountStatusFetching || isSubmitting}
              />
            </View>
          </View>
        </Popup>
      </Modal>
      <Modal isVisible={isReasonModalVisible}>
        <Popup
          showCloseButton
          onCloseButtonPress={handleCloseComplyingModal}
          width={640}
          maxWidth={640}
          title={reasonContent.title}
        >
          <View testID="pre-book.confirmation.reason">
            <FormProvider {...methods}>
              <Text style={Style.margin}>{reasonContent.line1Text}</Text>
              <Text ink="secondary" style={Style.margin}>
                {reasonContent.line2Text}
              </Text>
              <InputField
                testID="pre-book.confirmation.reason.input"
                name="reason"
                maxLength={100}
                validate={value => {
                  if (required(value) === false) {
                    return reasonContent.reasonRequiredErrorMessage;
                  } else if (maxLength(value, 100) === false) {
                    return formatMessage(
                      reasonContent.reasonMaxLengthErrorMessage,
                      { length: 100 }
                    );
                  }

                  return;
                }}
              />

              <View style={Style.control}>
                <View style={Style.help}>
                  <Text variant="ui-tiny" ink="secondary">
                    {reasonContent.infoText}
                  </Text>
                </View>
                <View style={Style.buttons}>
                  <Button
                    variant="secondary"
                    text={reasonContent.closeButtonText}
                    onPress={handleCloseComplyingModal}
                  />
                  <Button
                    testID="pre-book.confirmation.reason.submit"
                    variant="main-cta"
                    text={reasonContent.submitButtonText}
                    onPress={formSubmit(handleSubmit)}
                    style={Style.submit}
                    loading={isSubmitting}
                  />
                </View>
              </View>
            </FormProvider>
          </View>
        </Popup>
      </Modal>
      <PopupGirl
        isVisible={isFnaModalVisible}
        title={availabilityContent.title}
        style={Style.popupContent}
      >
        <View>
          <Text ink="secondary">{availabilityContent.line1Text}</Text>
          <Text ink="secondary">{availabilityContent.line2Text}</Text>
        </View>
        <View style={Style.popupControl}>
          <Button
            text={availabilityContent.closeButtonText}
            onPress={() => redirectTo('/')}
          />
        </View>
      </PopupGirl>
      <PopupGirl
        isVisible={priceChangeState.isVisible}
        title={priceChangeContent.title}
        style={Style.popupContent}
      >
        <View>
          <Text>
            {`${priceChangeContent.line1Text} `}
            <Text style={Style.bold}>{priceChangeState.from}</Text>
            {` ${priceChangeContent.line1TextBold} `}
            <Text
              testID="pre-book.confirmation.price-change.price-to"
              style={Style.bold}
            >
              {formatCurrency(
                convert(
                  priceChangeState.to.grandTotal ||
                    priceChangeState.to.totalFareFromClient
                )
              )}
            </Text>
          </Text>
          <Text>{priceChangeContent.line2Text}</Text>
          <Text>{priceChangeContent.line3Text}</Text>
        </View>
        <View style={Style.popupControl}>
          <Button
            variant="secondary"
            text={priceChangeContent.closeButtonText}
            onPress={handleClosePriceChange}
          />
          <Button
            testID="pre-book.confirmation.price-change.submit"
            variant="main-cta"
            text={priceChangeContent.submitButtonText}
            onPress={() => fetchAccountStatus(handleSubmit)}
            loading={isAccountStatusFetching || isSubmitting}
            style={Style.submit}
          />
        </View>
      </PopupGirl>
      <InsufficientCreditLimitModal isVisible={isInsufficientCreditVisible} />

      <Modal isVisible={Boolean(isGeneralErrorModalVisible)}>
        <Popup
          showCloseButton
          onCloseButtonPress={handleCloseGeneralErrorModal}
          width={614}
        >
          <View style={Style.generalErrorModalContainer}>
            <Icon
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: Token.spacing.xs,
                backgroundColor: Token.color.uiRedPrimary,
                borderRadius: Token.border.radius.rounded,
              }}
              src={IcSystemCrossClose}
              alt="failed"
              width={32}
              height={32}
            />
            <Text style={Style.generalErrorModalTitle} variant="title-2">
              {isGeneralErrorModalVisible?.title}
            </Text>
            {isGeneralErrorModalVisible && (
              <Text style={Style.generalErrorModalContent} ink="secondary">
                {htmr(isGeneralErrorModalVisible?.message)}
              </Text>
            )}
            {isGeneralErrorModalVisible?.action && (
              <Button
                style={Style.generalErrorModalButton}
                text={confirmationContent.closeButtonText}
                onPress={isGeneralErrorModalVisible.action}
              />
            )}
          </View>
        </Popup>
      </Modal>
    </>
  );
}

function useNonEmployeeContent() {
  const content = useContentResource().CorporateFlightPrebookConfirmationModal;
  return useCallback((type: NonEmployeeType) => {
    switch (type) {
      case NonEmployeeType.ADULT:
        return content.nonEmployeeAdultText;
      case NonEmployeeType.CHILD:
        return content.nonEmployeeChildText;
      case NonEmployeeType.INFANT:
        return content.nonEmployeeInfantText;
    }
  }, []);
}

const Style = StyleSheet.create({
  summary: {
    flexDirection: 'row',
    marginBottom: Token.spacing.l,
    marginHorizontal: -Token.spacing.xs,
  },
  summaryItem: {
    width: '50%',
    paddingHorizontal: Token.spacing.xs,
  },
  spread: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  product: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flight: {
    marginLeft: Token.spacing.xs,
  },
  help: {
    flex: 1,
  },
  price: {
    paddingVertical: Token.spacing.m,
  },
  control: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttons: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
  },
  submit: {
    marginLeft: Token.spacing.m,
  },
  scroll: {
    maxHeight: 200,
    paddingVertical: Token.spacing.xs,
    minHeight: 40,
  },
  scrollContent: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginHorizontal: -Token.spacing.xs,
  },
  travelerTitle: {
    marginBottom: Token.spacing.xs,
  },
  firstTraveler: {
    flex: 1,
    paddingVertical: Token.spacing.m,
    paddingHorizontal: Token.spacing.xs,
  },
  traveler: {
    flex: 1,
    paddingVertical: Token.spacing.m,
    paddingHorizontal: Token.spacing.xs,
    borderTopWidth: Token.border.width.thick,
    borderTopColor: Token.color.borderDivide,
  },
  margin: {
    marginBottom: Token.spacing.s,
  },
  bold: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  popupContent: {
    justifyContent: 'space-between',
  },
  popupControl: {
    marginTop: Token.spacing.l,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  generalErrorModalContainer: {
    padding: Token.spacing.l,
  },
  generalErrorModalTitle: {
    textTransform: 'capitalize',
    textAlign: 'center',
    marginVertical: Token.spacing.m,
  },
  generalErrorModalContent: {
    textAlign: 'center',
  },
  generalErrorModalButton: {
    marginTop: Token.spacing.m,
    marginHorizontal: 'auto',
  },
});

import storage, { JsonValue } from './storage';

// We separate data and expiration keys to prevent expensive JSON-parsing.
// The convention for local-storage keys are as follows:
// (*) `exp-time/<key>` to store the expiration timestamp for `exp-data/<key>`
// (*) `exp-data/<key>` to store the data itself
// (*) `exp-meta/<key>` to store metadata for `expirable-storage` (e.g. last cleanup)
const TIMESTAMP_KEY_PREFIX = 'exp-time/';
const DATA_KEY_PREFIX = 'exp-data/';
const LAST_CLEANUP_TIMESTAMP_KEY = 'exp-meta/time';

// We don't want the browser to do cleanup enery time for performance reasons.
// Set the following to govern how often we should do cleanup.
const CLEANUP_PERIOD = 60 * 60 * 1000; // 60 minutes

const DEFAULT_TTL = 60 * 60 * 1000; // 60 minutes

export function get<T>(key: string) {
  const timestamp = storage.get<number>(toExpirationKey(key));

  if (timestamp === null || isExpired(timestamp)) {
    remove(key);

    return null;
  }

  return storage.get<T>(toDataKey(key));
}

export function set<T extends JsonValue>(
  key: string,
  data: T,
  ttl = DEFAULT_TTL
) {
  try {
    storage.set(toExpirationKey(key), Date.now() + ttl);
    storage.set(toDataKey(key), data);

    return true;
  } catch {
    return false;
  }
}

export function remove(key: string) {
  storage.remove(toExpirationKey(key));
  storage.remove(toDataKey(key));
}

export default { get, set, remove };

// ===== SCHEDULER
const lastCleanupTimestamp = storage.get<number>(LAST_CLEANUP_TIMESTAMP_KEY);

if (lastCleanupTimestamp === null) {
  cleanExpired();
  setInterval(cleanExpired, CLEANUP_PERIOD);
} else {
  // Schedule a clean up
  setTimeout(() => {
    cleanExpired();
    setInterval(cleanExpired, CLEANUP_PERIOD);
  }, lastCleanupTimestamp + CLEANUP_PERIOD - Date.now());
}

// ===== HELPERS
function isExpired(timestamp: number) {
  return Date.now() >= timestamp;
}

function toExpirationKey(key: string) {
  return TIMESTAMP_KEY_PREFIX + key;
}

function toDataKey(key: string) {
  return DATA_KEY_PREFIX + key;
}

/**
 * This regex will parse 'exp-prefix/123' into ['exp-prefix/123', '123']
 */
const extractorRegex = new RegExp(
  `^(?:${TIMESTAMP_KEY_PREFIX}|${DATA_KEY_PREFIX})(.*)$`
);

/**
 * Clears all entries (created by `expirable-storage`)
 * that have expired or are invalid (e.g. missing data or timestamp).
 */
function cleanExpired() {
  try {
    const expirableKeys = new Set<string>();

    const allKeys = Array.from(
      { length: window.localStorage.length },
      (_, index) => window.localStorage.key(index) as string
    );

    allKeys.forEach(key => {
      const result = extractorRegex.exec(key);

      if (result !== null) {
        expirableKeys.add(result[1]);
      }
    });

    expirableKeys.forEach(key => {
      const timestamp = storage.get<number>(toExpirationKey(key));

      if (timestamp === null || isExpired(timestamp)) {
        remove(key);
      }
    });

    storage.set(LAST_CLEANUP_TIMESTAMP_KEY, Date.now());
  } catch {
    return;
  }
}

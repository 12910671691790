import React from 'react';

import { StyleSheet, View } from 'react-native';
import { Token, useTheme, Text } from '@traveloka/web-components';

type Props = {
  ribbonText?: string;
  ribbonPosition?: 'TOP' | 'BOTTOM';
  ribbonTextColor?: string;
  ribbonBackgroundColor?: string;
};

export default function Ribbon(props: Props) {
  const { color } = useTheme();
  const {
    ribbonText,
    ribbonPosition = 'TOP',
    ribbonTextColor = color.lightPrimary,
    ribbonBackgroundColor = color.uiGreenPrimary,
  } = props;

  if (!ribbonText) return null;

  const ribbonColor = {
    borderTopColor: ribbonBackgroundColor,
    borderRightColor: ribbonBackgroundColor,
    borderBottomColor: ribbonBackgroundColor,
  };

  return (
    <View
      style={[
        styles.ribbonWrapper,
        ribbonPosition === 'TOP' ? styles.ribbonTop : styles.ribbonBottom,
      ]}
    >
      <View style={[styles.ribbon, ribbonColor]} />
      <View
        style={[
          styles.ribbonContent,
          { backgroundColor: ribbonBackgroundColor },
        ]}
      >
        <Text
          variant="caption-micro"
          style={[Token.typography.weightMedium, { color: ribbonTextColor }]}
          ellipsizeMode="tail"
          numberOfLines={1}
        >
          {ribbonText}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  ribbonWrapper: {
    position: 'absolute',
    right: 0,
    zIndex: 10,
    maxWidth: `calc(100% - ${Token.spacing.xs}px)`,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  ribbonTop: {
    top: 28,
  },
  ribbonBottom: {
    bottom: Token.spacing.xs,
  },
  ribbon: {
    width: 0,
    height: 0,
    borderTopWidth: Token.spacing.ml / 2,
    borderBottomWidth: Token.spacing.ml / 2,
    borderLeftWidth: Token.spacing.xs,
    borderRightWidth: Token.spacing.xxs,
    borderLeftColor: 'transparent',
  },
  ribbonContent: {
    flex: 1,
    height: Token.spacing.ml,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    paddingRight: Token.spacing.xs,
    paddingVertical: Token.spacing.xxs,
  },
});

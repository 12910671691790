import { Permission } from '@traveloka/ctv-core';

// ===== Account Status Handling
export const AUTH_SESSION_API = '/api/v2/authorization/session';

export type AuthSessionResponse = {
  employeeId: string;
  employeeFullName: string;
  permissions: Permission[];
  additionalData: string[];
  status: string;
  errorMessage: string | null;
  trackingSpec: any;
};

import React from 'react';
import { StyleSheet } from 'react-native';

import { Popup, Token, Text, Button } from '@traveloka/web-components';
import { Modal } from '@traveloka/web-components/future';
import { useContentResource } from '@traveloka/ctv-core';

type Props = {
  isVisible: boolean;
  handleClose(): void;
};

export default function ConfirmationModal(props: Props) {
  const { isVisible, handleClose } = props;

  const cr = useContentResource().CorporateInvoiceDispute;

  return (
    <Modal isVisible={isVisible}>
      <Popup
        title={cr.confirmationModalTitle}
        showCloseButton
        width={320}
        maxWidth={320}
        onCloseButtonPress={handleClose}
      >
        <Text style={styles.info} ink="secondary">
          {cr.confirmationModalInfo}
        </Text>
        <Button text={cr.confirmationModalButtonText} onPress={handleClose} />
      </Popup>
    </Modal>
  );
}

const styles = StyleSheet.create({
  info: {
    marginBottom: Token.spacing.l,
  },
});

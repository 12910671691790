import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Card, Token } from '@traveloka/web-components';

import Skeleton from '../../shared/components/Skeleton/Skeleton';

type Props = {
  style?: StyleProp<ViewStyle>;
};

export default function CompanyInfoLoading(props: Props) {
  const { style } = props;

  return (
    <Card style={style}>
      <View style={Style.top}>
        <Skeleton height={16} width={120} />
        <View style={Style.name}>
          <Skeleton height={20} width={200} />
        </View>
      </View>
      <View style={Style.bottom}>
        <View style={Style.row}>
          <Skeleton height={20} width={108} />
          <Skeleton height={40} style={Style.text} />
        </View>
        <View style={Style.row}>
          <Skeleton height={20} width={108} />
          <Skeleton height={20} style={Style.text} />
        </View>
        <View style={Style.row}>
          <Skeleton height={20} width={108} />
          <Skeleton height={20} style={Style.text} />
        </View>
      </View>
    </Card>
  );
}

const Style = StyleSheet.create({
  name: {
    marginTop: Token.spacing.xs,
    flex: 1,
    justifyContent: 'center',
  },
  top: {
    padding: Token.spacing.s,
    backgroundColor: Token.color.uiBluePrimary,
  },
  bottom: {
    flex: 1,
    padding: Token.spacing.s,
    paddingBottom: 0,
  },
  row: {
    flexDirection: 'row',
    marginBottom: Token.spacing.s,
  },
  text: {
    flex: 1,
    marginLeft: Token.spacing.s,
  },
});

import React from 'react';

import {
  StyleSheet,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  View,
} from 'react-native';
import { Card, Token, Text, Icon } from '@traveloka/web-components';
import HotelImage from './HotelImage';
import Highlighter from 'react-highlight-words';
import Ribbon from '../../shared/components/Ribbon/Ribbon';

import { Skeleton, useHoverable } from '@traveloka/web-components/future';
import { appendTestId } from '../../shared/utils/TestUtil';
import { Item } from './types';

import LocationIcon from '@traveloka/icon-kit-web/svg/light/ic_system_map_location-fill_24px.svg';

const highlightStyle = {
  color: Token.color.uiBluePrimary,
  backgroundColor: 'transparent',
};

type HotelCardProps = Item & {
  testID?: string;
  startFromText: string;
  filterText: string;
  isActive: boolean;
  style?: StyleProp<ViewStyle>;
  onPressItem: (item: Item) => void;
};

function HotelCard(props: HotelCardProps) {
  const {
    testID,
    style,
    startFromText,
    filterText,
    isActive,
    onPressItem,
    ...item
  } = props;

  const { image, location, label, price, showRibbon } = item;

  const [isHovered, hoverEvent] = useHoverable();
  const searchWords = filterText.split(/\s+/g);

  return (
    <Card
      elevation={isHovered || isActive ? 'float' : 'raised'}
      style={[Style.card, style, (isHovered || isActive) && Style.hovered]}
    >
      <TouchableOpacity
        {...hoverEvent}
        testID={appendTestId(testID, 'hotel-card')}
        activeOpacity={0.5}
        onPress={() => onPressItem(item)}
      >
        <View style={Style.imageWrapper}>
          <View style={Style.locationWrapper}>
            <View style={Style.locationContent}>
              <Icon src={LocationIcon} width={12} height={12} />
              <Text
                variant="ui-tiny"
                ink="white"
                numberOfLines={1}
                style={Style.locationText}
              >
                {location}
              </Text>
            </View>
          </View>
          <HotelImage src={image} />
          {showRibbon && (
            <Ribbon
              ribbonText="Frequently booked"
              ribbonPosition="BOTTOM"
              ribbonTextColor={Token.color.uiLightPrimary}
              ribbonBackgroundColor={Token.color.uiOrangePrimary}
            />
          )}
        </View>
        <View style={Style.summary}>
          <Text
            variant="title-3"
            numberOfLines={2}
            style={{ height: Token.typography.title3.lineHeight * 2 }}
          >
            <Highlighter
              searchWords={searchWords}
              autoEscape={true}
              textToHighlight={label}
              highlightStyle={highlightStyle}
            />
          </Text>
          {price ? (
            <View style={Style.price}>
              <Text
                variant="caption-micro"
                ink="secondary"
                style={Style.priceLabel}
              >
                {startFromText}
              </Text>
              <Text variant="ui-small" ink="price">
                {price}
              </Text>
            </View>
          ) : (
            <View
              style={[
                Style.price,
                Style.priceLabel,
                {
                  height:
                    Token.typography.captionMicro.lineHeight +
                    Token.typography.uiSmall.lineHeight,
                },
              ]}
            />
          )}
        </View>
      </TouchableOpacity>
    </Card>
  );
}

export function HotelCardLoader() {
  return (
    <Card elevation={'raised'} style={Style.card}>
      <View style={Style.imageWrapper}>
        <HotelImage src={''} />
      </View>
      <View style={Style.summary}>
        <Skeleton
          width={100}
          height={Token.typography.title3.lineHeight}
          style={Style.skeleton}
        />
        <Skeleton width={80} height={Token.typography.title3.lineHeight} />
        <View style={Style.price}>
          <Skeleton
            width={70}
            height={Token.typography.captionMicro.lineHeight}
            style={Style.skeleton}
          />
          <Skeleton width={80} height={Token.typography.uiSmall.lineHeight} />
        </View>
      </View>
    </Card>
  );
}

const animateTransform = {
  transitionProperty: 'transform',
  transitionDuration: `${Token.animation.timing.instant}ms`,
};

const Style = StyleSheet.create({
  card: {
    ...animateTransform,
    // @ts-ignore
    transitionProperty: 'box-shadow',
  },
  summary: {
    padding: Token.spacing.s,
  },
  hovered: {
    backgroundColor: Token.color.uiLightStain,
  },
  locationWrapper: {
    position: 'absolute',
    left: 0,
    zIndex: 10,
    maxWidth: `calc(100% - 0px)`,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-start',
  },
  locationContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(3, 18, 26, 0.5)',
    paddingHorizontal: Token.spacing.xxs,
    paddingVertical: Token.spacing.xxs / 2,
  },
  price: {
    marginTop: Token.spacing.s,
  },
  priceLabel: {
    marginBottom: Token.spacing.xxs,
  },
  locationText: {
    // @ts-ignore
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: Token.spacing.xxs / 2,
  },
  imageWrapper: {
    flex: 1,
    borderTopLeftRadius: Token.border.radius.normal,
    borderTopRightRadius: Token.border.radius.normal,
    overflow: 'hidden',
    position: 'relative',
  },
  skeleton: {
    marginBottom: Token.spacing.xxs,
  },
});

export default HotelCard;

import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useTheme, Card, Text, Token } from '@traveloka/web-components';

import { appendTestId } from '../../shared/utils/TestUtil';
import RoomInfo from './RoomInfo';
import RoomStaticInfo from './RoomStaticInfo';

type Props = {
  allotment?: string;
  bed: string;
  bookLabel: string;
  breakfast: string;
  cancelation: string;
  cancelationPolicy: string;
  complianceLabel: string;
  roomDetailLabel: string;
  guest: string;
  images: string[];
  isComplying: boolean;
  isFreeBreakfast?: boolean;
  isFreeCancelation?: boolean;
  isFreeWifi?: boolean;
  mainPrice: string;
  name: string;
  onSelectRoomPress(): void;
  onImagePress?(url: string): void;
  onRoomDetailPress(): void;
  selectRoomButtonLoading?: boolean;
  originalPrice?: string;
  priceBreakdown: Array<'separator' | { label: string; value: string }>;
  promos: string[];
  readPolicyLabel: string;
  roomNightLabel: string;
  roomSize?: string;
  roomType?: string;
  showBookButton: boolean;
  style?: StyleProp<ViewStyle>;
  taxInclusionLabel: string;
  testID?: string;
  wifi: string;
};

export default function RoomCard(props: Props) {
  const { roomType, style, ...rest } = props;
  const { testID } = rest;
  const { color } = useTheme();
  const cardStyle = {
    backgroundColor: color.lightNeutral,
  };

  return (
    <Card style={[Style.card, cardStyle, style]}>
      {!!roomType && (
        <Text
          testID={appendTestId(testID, 'room-type')}
          variant="title-1"
          style={Style.type}
        >
          {roomType}
        </Text>
      )}
      <RoomStaticInfo {...rest} style={Style.left} />
      <RoomInfo {...rest} style={Style.right} />
    </Card>
  );
}

const Style = StyleSheet.create({
  card: {
    padding: Token.spacing.m,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    overflow: 'visible',
  },
  type: {
    marginBottom: Token.spacing.xs,
    flexBasis: '100%',
  },
  left: {
    width: 287,
  },
  right: {
    flex: 1,
    marginLeft: Token.spacing.m,
  },
});

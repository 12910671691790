import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Token } from '@traveloka/web-components';

type Props = {
  text?: string;
};

export default function GenericHomeSectionName(props: Props) {
  const { text } = props;

  return (
    <View style={Style.sectionName}>
      <View style={Style.block}>
        <View style={Style.blockFill}></View>
      </View>
      <Text
        ink="primary-interactive"
        style={{ fontWeight: Token.typography.weightMedium.fontWeight }}
      >
        {text}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  sectionName: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  block: {
    width: 32,
    height: 8,
    backgroundColor: Token.color.brandBusinessSuit,
    marginRight: Token.spacing.m,
  },
  blockFill: {
    width: 8,
    height: '100%',
    backgroundColor: Token.color.brandGodwitBlue,
  },
});

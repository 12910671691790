import { useCallback } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

import config from '../config';
import { useAuth } from '../auth';
import { ResponseError, ApiResult, ResponseStatus } from '../api/types';
import ApiError from '../api/ApiError';

type UploadProgress = {
  loaded: number;
  total: number;
};

type Option = {
  path: string;
  config?: {
    onUploadProgress?(progressEvent: UploadProgress): void;
  };
};

export default function useUpload<Response = unknown, Request = unknown>(
  option: Option
) {
  const { path, config: configProps } = option;
  const { getToken, isAuthenticated } = useAuth();

  return useCallback(async (payload: Request, file: File): Promise<
    ApiResult<Response>
  > => {
    let authServiceToken: string | undefined;
    if (isAuthenticated) {
      authServiceToken = await getToken();
    }

    const formData = new FormData();
    formData.append('data.content', file);
    formData.append(
      'main-request',
      JSON.stringify({
        fields: [],
        data: payload,
        context: {
          authServiceToken,
        },
        clientInterface: 'desktop',
      })
    );

    const axiosConfig: AxiosRequestConfig = {
      headers: { accept: '*/*' },
    };

    if (configProps?.onUploadProgress) {
      axiosConfig.onUploadProgress = configProps.onUploadProgress;
    }

    const { data: res } = await axios.post(
      config.apiHost.management + path.replace(/^\/+/, ''),
      formData,
      axiosConfig
    );

    if ('errorType' in res) {
      const { errorType } = res;

      let errorMessage = res.userErrorMessage;
      if (errorType === ResponseError.NOT_AUTHORIZED) {
        errorMessage = res.errorMessage;
      }

      return {
        success: false,
        error: new ApiError(errorType, errorMessage),
      };
    }

    // CTV API
    if ('status' in res && res.status !== ResponseStatus.OK) {
      const { status, errorMessage } = res;

      return {
        success: false,
        error: new ApiError(status, errorMessage),
      };
    }

    return {
      success: true,
      data: res.data,
      trackingSpec: null,
    };
  }, []);
}

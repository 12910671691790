import React, { useState, Fragment } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  useLocalizedDateFormat,
  useContentResource,
} from '@traveloka/ctv-core';
import { Divider } from '@traveloka/ctvweb-ui';
import {
  FlightCompliance,
  TransitInfoContent,
} from '@traveloka/ctvweb-ui/flight';
import FlightIcon from '@traveloka/icon-kit-web/svg/blue/ic_product_flight-fill_24px.svg';
import AirlinesIcon from '@traveloka/icon-kit-web/svg/dark/ic_flight_airlines_24px.svg';
import ArrowRightIcon from '@traveloka/icon-kit-web/svg/dark/ic_system_arrow_right_16px.svg';
import NotAvailableIcon from '@traveloka/icon-kit-web/svg/dark/ic_system_status_ok_done-fill_16px.svg';
import AvailableIcon from '@traveloka/icon-kit-web/svg/greenSecondary/ic_system_status_ok_done-fill_16px.svg';
import {
  useTheme,
  Badge,
  Button,
  Card,
  Icon,
  Image,
  Text,
  Token,
} from '@traveloka/web-components';

import {
  useFlightsContext,
  useJourneyTypeContext,
  usePoliciesContext,
} from 'flight/prebook/contexts/FlightPrebookContext';
import {
  ONE_WAY_SEPARATOR,
  ROUND_TRIP_SEPARATOR,
} from 'flight/search/constants/flight-search-constant';
import JourneyType from 'flight/shared/constants/journey-type';

import ProductSummaryModal from '../ProductSummaryModal/ProductSummaryModal.';

export default function ProductSummary() {
  const { color } = useTheme();
  const labelStyle = {
    backgroundColor: color.lightStain,
  };
  const { format } = useLocalizedDateFormat();

  const [isModalVisible, setModalVisibility] = useState(false);

  const content = useContentResource().CorporateFlightPrebookProductSummary;

  const flights = useFlightsContext();
  const journeyType = useJourneyTypeContext();
  const flightRefundPolicies = usePoliciesContext();

  const [{ segments }] = flights;
  const { 0: firstSegment, [segments.length - 1]: lastSegment } = segments;

  const title = [
    firstSegment.departureAirport.city,
    lastSegment.arrivalAirport.city,
  ].join(
    journeyType === JourneyType.ONE_WAY
      ? ONE_WAY_SEPARATOR
      : ROUND_TRIP_SEPARATOR
  );

  function handleShowModal() {
    setModalVisibility(true);
  }

  function handleCloseModal() {
    setModalVisibility(false);
  }

  return (
    <>
      <Card>
        <View style={Style.header}>
          <Icon src={FlightIcon} />
          <Text variant="ui-small" style={Style.title}>
            {title}
          </Text>
          <Button
            variant="text"
            size="small"
            text={content.detailButtonText}
            onPress={handleShowModal}
          />
        </View>
        {flights.map((flight, index) => {
          const { flightId, compliance, summary, segments, journeys } = flight;
          const [{ refund }] = journeys;
          const type =
            index === 0 ? content.departureText : content.arrivalText;

          return (
            <Fragment key={flightId}>
              <Divider margin="none" />
              <View
                style={[Style.summary, compliance && Style.compliancePadding]}
              >
                {compliance && (
                  <FlightCompliance
                    label={compliance.label}
                    style={Style.compliance}
                  />
                )}
                <Text variant="ui-small" style={Style.itemTitle}>
                  {[
                    type,
                    '•',
                    format(summary.departureDateTime, 'SHORT_WEEKDAY'),
                  ].join(' ')}
                </Text>
                <View style={Style.airline}>
                  <View style={Style.airlineInfo}>
                    <Text variant="ui-small" style={Style.airlineName}>
                      {summary.airlineName}
                    </Text>
                    <Text variant="ui-small" ink="secondary">
                      {summary.seatClass}
                    </Text>
                  </View>

                  {summary.airlineLogos.length > 1 && (
                    <View style={Style.multiAirline}>
                      <Icon src={AirlinesIcon} />
                      <Text variant="caption-tiny" style={Style.badge}>
                        {summary.airlineLogos.length}
                      </Text>
                    </View>
                  )}

                  {summary.airlineLogos.length === 1 && (
                    <Image
                      src={summary.airlineLogos[0].src}
                      alt={summary.airlineLogos[0].alt}
                      width={Token.spacing.xl}
                      height={Token.spacing.xl}
                      objectFit="contain"
                    />
                  )}
                </View>
                <View style={Style.route}>
                  <View style={[Style.routeItem, Style.schedule]}>
                    <Text variant="ui-small">{summary.departureTime}</Text>
                    <Badge
                      variant="neutral-subtle"
                      text={summary.departureAirport.airportCode}
                    />
                  </View>
                  <View style={[Style.routeItem, Style.arrow]}>
                    <Icon src={ArrowRightIcon} />
                  </View>
                  <View style={[Style.routeItem, Style.schedule]}>
                    <Text variant="ui-small">{summary.arrivalTime}</Text>
                    <Badge
                      variant="neutral-subtle"
                      text={summary.arrivalAirport.airportCode}
                    />
                  </View>
                  <View style={[Style.routeItem, Style.duration]}>
                    <Text variant="ui-small">{summary.durationStr}</Text>
                    <View style={Style.transit}>
                      <View
                        style={[
                          Style.transitDot,
                          summary.transit === 0 && Style.transitDotDirect,
                        ]}
                      />
                      <Text variant="ui-small" ink="secondary">
                        {summary.transitStr}
                      </Text>
                    </View>
                  </View>
                </View>
                {journeys.map((journey, ji) => {
                  const lastSegmentNumber = journey.segments.length - 1;
                  const isRefundable =
                    flightRefundPolicies[index].refundPolicies[ji].refundPolicy
                      .length > 0;
                  return (
                    <Card style={Style.journeySummary} elevation="raised">
                      {journey.segments.map((segment, si) => {
                        return (
                          <>
                            {segment.additionalTransitInfo.length !== 0 && (
                              <View
                                key={si}
                                style={[Style.additionalInfo, labelStyle]}
                              >
                                {segment.additionalTransitInfo.map(
                                  (info, ti) => (
                                    <TransitInfoContent
                                      key={ti}
                                      index={ti}
                                      {...info}
                                    />
                                  )
                                )}
                              </View>
                            )}
                            <Text variant="ui-small">
                              {segment.departureLocation} -{' '}
                              {segment.arrivalLocation}
                            </Text>
                            {lastSegmentNumber === si && (
                              <View style={Style.policy}>
                                <Icon
                                  src={
                                    isRefundable
                                      ? AvailableIcon
                                      : NotAvailableIcon
                                  }
                                />
                                <Text
                                  variant="ui-small"
                                  style={Style.policyText}
                                  ink={isRefundable ? 'positive' : 'secondary'}
                                >
                                  {isRefundable
                                    ? content.refundableText
                                    : content.nonRefundableText}
                                </Text>
                              </View>
                            )}
                          </>
                        );
                      })}
                    </Card>
                  );
                })}
              </View>
            </Fragment>
          );
        })}
      </Card>
      <ProductSummaryModal
        isVisible={isModalVisible}
        onClosePress={handleCloseModal}
      />
    </>
  );
}

const Style = StyleSheet.create({
  header: {
    padding: Token.spacing.m,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    fontWeight: Token.typography.weightMedium.fontWeight,
    marginHorizontal: Token.spacing.s,
  },
  summary: {
    padding: Token.spacing.m,
  },
  journeySummary: {
    padding: Token.spacing.xs,
    marginBottom: Token.spacing.xs,
  },
  itemTitle: {
    marginBottom: Token.spacing.s,
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  airline: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: Token.spacing.s,
  },
  airlineInfo: {
    flex: 1,
    marginRight: Token.spacing.s,
  },
  airlineName: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  multiAirline: {
    width: Token.spacing.xl,
    height: Token.spacing.xl,
    alignItems: 'flex-start',
  },
  badge: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: Token.color.uiBluePrimary,
    borderRadius: Token.border.radius.rounded,
    textAlign: 'center',
    width: Token.spacing.m,
    lineHeight: Token.spacing.m,
    color: Token.color.uiLightPrimary,
    fontWeight: Token.typography.weightMedium.fontWeight,
    userSelect: 'none',
  },
  route: {
    flexDirection: 'row',
    marginHorizontal: -Token.spacing.xs,
    marginBottom: Token.spacing.s,
  },
  routeItem: {
    paddingHorizontal: Token.spacing.xs,
  },
  arrow: {
    justifyContent: 'center',
  },
  schedule: {
    alignItems: 'center',
  },
  duration: {
    flex: 1,
  },
  additionalInfo: {
    marginTop: Token.spacing.xxs,
    marginHorizontal: -Token.spacing.m,
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.xs,
  },
  policy: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  policyText: {
    marginLeft: Token.spacing.xs,
  },
  transit: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  transitDot: {
    width: Token.spacing.s,
    height: Token.spacing.s,
    marginRight: Token.spacing.xxs,
    borderRadius: Token.border.radius.rounded,
    backgroundColor: Token.color.uiYellowPrimary,
  },
  transitDotDirect: {
    backgroundColor: Token.color.uiBluePrimary,
  },

  // Compliance
  compliance: {
    position: 'absolute',
    right: 0,
    top: 0,
    borderBottomLeftRadius: Token.border.radius.normal,
  },
  compliancePadding: {
    paddingTop: Token.spacing.xl,
  },
});

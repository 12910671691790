import React from 'react';
import {
  StyleSheet,
  TouchableWithoutFeedbackProps,
  View,
  ViewProps,
} from 'react-native';

import { Button, Text, Token } from '@traveloka/web-components';
import { appendTestId } from '../../shared/utils/TestUtil';

type Props = {
  style?: ViewProps['style'];
  title: string;
  subtitle: string;
  pax: string;
  seatClass: string;
  onPress?: TouchableWithoutFeedbackProps['onPress'];
  button: string;
  testID?: string;
  loading?: boolean;
};

export default function FlightSearchHeaderInfo(props: Props) {
  const {
    testID,
    style,
    title,
    subtitle,
    pax,
    seatClass,
    onPress,
    button,
    loading,
  } = props;

  return (
    <View style={style}>
      <Text
        testID={appendTestId(testID, 'title')}
        variant="title-1"
        style={Style.title}
      >
        {title}
      </Text>
      <View style={Style.detail}>
        <View>
          <Text
            testID={appendTestId(testID, 'date')}
            variant="ui-small"
            ink="secondary"
          >
            {subtitle}
          </Text>
          <View style={Style.bottomInfo}>
            <Text
              testID={appendTestId(testID, 'passenger')}
              variant="ui-small"
              ink="secondary"
            >
              {pax}
            </Text>
            <Text variant="ui-small" ink="secondary" style={Style.leftMargin}>
              |
            </Text>
            <Text
              testID={appendTestId(testID, 'seat-class')}
              variant="ui-small"
              ink="secondary"
              style={Style.leftMargin}
            >
              {seatClass}
            </Text>
          </View>
        </View>
        <Button
          testID={appendTestId(testID, 'change-search')}
          style={Style.button}
          text={button}
          onPress={onPress}
          loading={loading}
        />
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  title: {
    marginBottom: Token.spacing.l,
  },
  detail: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomInfo: {
    flexDirection: 'row',
    marginTop: Token.spacing.xxs,
  },
  leftMargin: {
    marginLeft: Token.spacing.m,
  },
  button: {
    alignSelf: 'flex-end',
  },
});

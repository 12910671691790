import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Text, Token } from '@traveloka/web-components';

import { useDispatchSnackbar, useSnackbar } from './SnackbarContext';

export default function Snackbar() {
  const timeoutRef = useRef<number>();
  const [height, setHeight] = useState(0);
  const snackbarConfig = useSnackbar();
  const dispatchSnackbar = useDispatchSnackbar();
  const [show, setShow] = useState(false);

  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (snackbarConfig) {
      setShow(true);
      timeoutRef.current = window.setTimeout(() => {
        setShow(false);
        dispatchSnackbar(undefined);
      }, 5000);
    } else {
      setShow(false);
    }

    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, [snackbarConfig]);

  useEffect(() => {
    // To config value reset after forcefully changes the page (before 5 seconds)
    dispatchSnackbar(undefined);
  }, [window.location.href]);

  useEffect(() => {
    firstRenderRef.current = false;
  }, []);

  const reduceMotion = firstRenderRef.current && show;
  const rootStyle = [
    styles.root,
    { height: reduceMotion ? undefined : show ? height : 0 },
  ];

  return (
    <View style={rootStyle}>
      <View
        onLayout={e => setHeight(e.nativeEvent.layout.height)}
        style={[
          styles.snackbar,
          snackbarConfig?.variant === 'alert' && styles.alert,
          snackbarConfig?.variant === 'positive' && styles.positive,
          (snackbarConfig?.variant === 'normal' || undefined) && styles.normal,
        ]}
      >
        <Text ink="white" variant="ui-small" style={styles.text}>
          {snackbarConfig?.message}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'sticky' as any,
    top: 68,
    zIndex: 10,
    transitionDuration: `${Token.animation.timing.instant}ms`,
    transitionProperty: 'height',
    overflow: 'hidden',
  },
  snackbar: {
    alignItems: 'center',
    padding: Token.spacing.l,
  },
  normal: {
    backgroundColor: Token.color.brandBusinessSuit,
  },
  alert: {
    backgroundColor: Token.color.uiRedPrimary,
  },
  positive: {
    backgroundColor: Token.color.uiGreenPrimary,
  },
  text: {
    width: 960,
    textAlign: 'center',
  },
});

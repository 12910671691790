import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useTheme, Card, Token } from '@traveloka/web-components';

import Divider from '../../shared/components/Divider/Divider';
import Skeleton from '../../shared/components/Skeleton/Skeleton';

type Props = {
  style?: StyleProp<ViewStyle>;
};

export default function RoomCard(props: Props) {
  const { style } = props;
  const { color } = useTheme();
  const cardStyle = {
    backgroundColor: color.lightNeutral,
  };

  return (
    <Card style={[Style.card, cardStyle, style]}>
      <Skeleton height={24} width={290} style={Style.title} />
      <View style={Style.content}>
        <Card style={Style.left}>
          <Skeleton height={144} style={Style.bigImage} />
          <View style={Style.row}>
            <Skeleton height={48} style={Style.thumbnail} />
            <Skeleton height={48} style={Style.thumbnail} />
            <Skeleton height={48} style={Style.thumbnail} />
          </View>
          <View style={Style.leftContent}>
            <Skeleton height={20} style={Style.info} />
            <Skeleton height={20} style={Style.info} />
            <Skeleton height={20} />
            <Skeleton height={32} style={Style.leftButton} />
          </View>
        </Card>
        <Card style={Style.right}>
          <Skeleton height={24} width={150} style={Style.roomName} />
          <View style={Style.row}>
            <Skeleton height={20} width={200} />
            <Skeleton height={20} width={200} style={Style.rightInfo} />
          </View>
          <Divider subtle />
          <View style={[Style.row, Style.info]}>
            <Skeleton height={20} width={200} />
            <Skeleton height={20} width={200} style={Style.rightInfo} />
            <Skeleton height={20} width={160} style={Style.rightInfo} />
          </View>
          <View style={[Style.row, Style.info]}>
            <Skeleton height={20} width={200} />
            <Skeleton height={20} width={200} style={Style.rightInfo} />
            <Skeleton height={20} width={160} style={Style.rightInfo} />
          </View>
          <View style={[Style.row, Style.info]}>
            <Skeleton height={20} width={200} />
            <Skeleton height={20} width={200} style={Style.rightInfo} />
            <Skeleton height={20} width={160} style={Style.rightInfo} />
          </View>
          <Divider subtle />
          <Skeleton height={20} width={150} />
        </Card>
      </View>
    </Card>
  );
}

const Style = StyleSheet.create({
  card: {
    padding: Token.spacing.m,
  },
  title: {
    marginBottom: Token.spacing.xs,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  left: {
    width: 287,
  },
  row: {
    flexDirection: 'row',
  },
  bigImage: {
    borderRadius: 0,
  },
  thumbnail: {
    flex: 1,
    borderRadius: 0,
  },
  leftContent: {
    padding: Token.spacing.m,
  },
  leftButton: {
    marginTop: Token.spacing.m,
  },
  info: {
    marginBottom: Token.spacing.xs,
  },
  right: {
    flex: 1,
    marginLeft: Token.spacing.m,
    paddingVertical: Token.spacing.m,
    paddingHorizontal: Token.spacing.l,
  },
  rightInfo: {
    marginLeft: Token.spacing.xs,
  },
  roomName: {
    marginBottom: Token.spacing.s,
  },
});

import { useApi } from '@traveloka/ctv-core';
import { useQuery } from '@tanstack/react-query';

import {
  INVOICE_UNPAID_API,
  InvoiceUnpaidRequest,
  InvoiceUnpaidResponse,
} from 'invoice-dispute/api';

function useInvoiceUnpaid() {
  const fetcher = useApi<InvoiceUnpaidResponse, InvoiceUnpaidRequest>({
    domain: 'booking',
    method: 'post',
    path: INVOICE_UNPAID_API,
  });

  const { data, isLoading } = useQuery(['invoice-unpaid'], fetcher, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return {
    isLoading,
    data: data?.success ? data.data : null,
  };
}

export default useInvoiceUnpaid;

import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const ApprovalList: PrivatePageComponent = function() {
  return null;
};

ApprovalList.routeProtection = function({ user }) {
  if (!user.globalTripRequestApproval) {
    return { path: '/' };
  }

  return { path: '/approval/list' };
};

export default ApprovalList;

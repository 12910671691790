import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Divider } from '@traveloka/ctvweb-ui';
import { CompanyInfoLoading } from '@traveloka/ctvweb-ui/company';
import Skeleton from '@traveloka/ctvweb-ui/src/shared/components/Skeleton/Skeleton';
import { Card, Token } from '@traveloka/web-components';

export default function EmploymentDetaiLoading() {
  return (
    <>
      <CompanyInfoLoading style={Style.section} />

      <Card style={Style.section}>
        <View style={Style.sectionContent}>
          <Skeleton height={24} width={250} />
        </View>
        <Divider margin="none" />
        <View style={Style.employmentContent}>
          <View style={Style.stretch}>
            <Skeleton height={14} width={75} style={Style.columnTop} />
            <Skeleton height={14} width={100} />
          </View>
          <View style={Style.stretch}>
            <Skeleton height={14} width={75} style={Style.columnTop} />
            <Skeleton height={14} width={100} />
          </View>
          <View style={Style.stretch}>
            <Skeleton height={14} width={75} style={Style.columnTop} />
            <Skeleton height={14} width={100} />
          </View>
          <View style={Style.stretch}>
            <Skeleton height={14} width={75} style={Style.columnTop} />
            <Skeleton height={14} width={100} />
          </View>
        </View>
      </Card>

      <Card>
        <View style={Style.sectionContent}>
          <Skeleton height={24} width={250} />
        </View>
        <Divider margin="none" />
        <View style={Style.sectionContent}>
          <Skeleton height={20} width={135} style={Style.policyTitle} />
          <View style={[Style.policyRow, Style.policyLastRow]}>
            <View style={Style.policyLeft}>
              <Skeleton height={16} width={50} />
            </View>
            <Skeleton height={16} width={150} />
          </View>
          <Skeleton height={20} width={135} style={Style.policyTitle} />
          <View style={Style.policyRow}>
            <View style={Style.policyLeft}>
              <Skeleton height={16} width={50} />
            </View>
            <Skeleton height={16} width={150} />
          </View>
          <View style={Style.policyRow}>
            <View style={Style.policyLeft}>
              <Skeleton height={16} width={50} />
            </View>
            <Skeleton height={16} width={150} />
          </View>
        </View>
      </Card>
    </>
  );
}

const Style = StyleSheet.create({
  section: {
    marginBottom: Token.spacing.m,
  },
  sectionContent: {
    padding: Token.spacing.m,
  },
  employmentContent: {
    flexDirection: 'row',
    padding: Token.spacing.m,
  },
  policyRow: {
    flexDirection: 'row',
    marginBottom: Token.spacing.xs,
  },
  policyLastRow: {
    marginBottom: Token.spacing.xl,
  },
  policyLeft: {
    width: 150,
  },
  policyTitle: {
    marginBottom: Token.spacing.m,
  },
  stretch: {
    flex: 1,
  },
  columnTop: {
    marginBottom: Token.spacing.xxs,
  },
});

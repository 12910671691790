import React, {
  createContext,
  Dispatch,
  useReducer,
  PropsWithChildren,
  useContext,
} from 'react';

export type ActiveDetail = 'none' | 'flight' | 'fare';
export type Action = 'card' | 'flight' | 'fare';

const ActiveDetailContext = createContext<ActiveDetail>('none');
const DispatchContext = createContext<Dispatch<Action>>(() => void 0);

function reducer(state: ActiveDetail, action: Action): ActiveDetail {
  switch (action) {
    case 'card':
      return state !== 'none' ? 'none' : 'flight';
    default:
      return state === action ? 'none' : action;
  }
}

export function DetailProvider(props: PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(reducer, 'none');

  return (
    <ActiveDetailContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {props.children}
      </DispatchContext.Provider>
    </ActiveDetailContext.Provider>
  );
}

export function useActiveDetail() {
  return useContext(ActiveDetailContext);
}

export function useDispatch() {
  return useContext(DispatchContext);
}

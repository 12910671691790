import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import WarningIcon from '@traveloka/icon-kit-web/svg/yellowPrimary/ic_system_status_warning-fill_24px.svg';
import { useTheme, Icon, Image, Text, Token } from '@traveloka/web-components';

import RouteIcon from '../../../svg/16/ic_flight_route_16px.svg';
import { FlightData } from '../types';

type Props = {
  flight: FlightData;
  style?: StyleProp<ViewStyle>;
  label: {
    title: string;
  };
};

export default function BookingReviewSummary(props: Props) {
  const { flight, style, label } = props;
  const { compliance, summary } = flight;
  const [firstSegment] = flight.segments;
  const { color } = useTheme();

  const dotStyle = {
    backgroundColor: summary.transit
      ? color.uiYellowPrimary
      : color.tintPrimary,
  };

  return (
    <View style={style}>
      <Text variant="ui-small" ink="secondary">
        {label.title}
      </Text>
      <Text variant="ui-small" style={Style.date}>
        {summary.departureDate}
      </Text>
      <View style={Style.airline}>
        <Image
          src={firstSegment.airlineLogo.src}
          alt={firstSegment.airlineLogo.alt}
          width={Token.spacing.xl}
          height={Token.spacing.xl}
          objectFit="contain"
        />
        <View style={Style.airlineInfo}>
          <View style={Style.row}>
            <Text variant="ui-small" style={Style.airlineName}>
              {firstSegment.flightName}
            </Text>
            {compliance && (
              <View style={Style.compliance}>
                <Icon
                  src={WarningIcon}
                  width={Token.spacing.m}
                  height={Token.spacing.m}
                />
              </View>
            )}
          </View>
          <Text variant="ui-small" ink="secondary">
            {firstSegment.seatClass}
          </Text>
        </View>
      </View>
      <View style={Style.row}>
        <View style={Style.route}>
          <Text>{summary.departureTime}</Text>
          <Text variant="ui-small" ink="secondary">
            {summary.departureLocation}
          </Text>
        </View>
        <View style={Style.icon}>
          <Image
            src={RouteIcon}
            width={Token.spacing.xl}
            height={Token.spacing.l}
            objectFit="contain"
          />
        </View>
        <View style={Style.route}>
          <Text>{summary.arrivalTime}</Text>
          <Text variant="ui-small" ink="secondary">
            {summary.arrivalLocation}
          </Text>
        </View>
        <View style={Style.transitInfo}>
          <Text>{summary.durationStr}</Text>
          <View style={Style.transit}>
            <View style={[Style.transitDot, dotStyle]} />
            <Text variant="ui-small" ink="secondary">
              {summary.transitStr}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  date: {
    marginBottom: Token.spacing.s,
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  airline: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: Token.spacing.s,
  },
  airlineInfo: {
    flex: 1,
    marginLeft: Token.spacing.s,
  },
  airlineName: {
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  row: {
    flexDirection: 'row',
  },
  route: {
    flex: 3,
    marginRight: Token.spacing.s,
  },
  icon: {
    marginRight: Token.spacing.s,
  },
  compliance: {
    marginLeft: Token.spacing.xs,
  },
  transitInfo: {
    flex: 2,
  },
  transit: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  transitDot: {
    width: Token.spacing.s,
    height: Token.spacing.s,
    marginRight: Token.spacing.xxs,
    borderRadius: Token.border.radius.rounded,
  },
});

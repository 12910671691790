import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { encode } from 'html-entities';
import htmr from 'htmr';

import { Button, Card, Icon, Text, Token } from '@traveloka/web-components';
import IcSystemStatusOkDoneFill from '@traveloka/icon-kit-web/svg/greenPrimary/ic_system_status_ok_done-fill_24px.svg';

import IcSystemArrowRight from '@traveloka/icon-kit-web/svg/blue/ic_system_arrow_right_16px.svg';
import { usePreRegisterConfirm } from 'registration/contexts/PreRegisterConfirmContext';

import { formatMessage } from 'shared/utils/intl';
import AddEmailModal from './AddEmailModal';
import { useContentResource } from '@traveloka/ctv-core';

export default function RegistrationSubmitted() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const cr = useContentResource().CorporateRegistration;
  const data = usePreRegisterConfirm()!;

  const emails = [data.picEmail];

  if (data.otherEmails) {
    emails.push(...data.otherEmails);
  }

  return (
    <>
      <Card style={[styles.card, styles.marginBtmM]}>
        <View style={styles.icon}>
          <Icon src={IcSystemStatusOkDoneFill} width={24} height={24} />
          <Text style={styles.title} variant="title-2">
            {cr.successfulSubmitTitle}
          </Text>
        </View>
        <Text variant="ui-small">
          {htmr(
            formatMessage(cr.successfulSubmitInfo, {
              emails: emails.map(email => `<b>${encode(email)}</b>`).join(', '),
            })
          )}
        </Text>
      </Card>
      {!data.otherEmails?.length && (
        <>
          <Card style={styles.card}>
            <Text style={styles.otherEmailLabel}>{cr.otherEmailLabel}</Text>
            <Button
              style={styles.addEmailButton}
              iconEnd={() => (
                <Icon src={IcSystemArrowRight} width={16} height={16} />
              )}
              text={cr.addEmailText}
              variant="text"
              onPress={() => setIsModalVisible(true)}
            />
          </Card>
          <AddEmailModal
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
          />
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    padding: Token.spacing.l,
  },
  marginBtmM: {
    marginBottom: Token.spacing.m,
  },
  otherEmailLabel: {
    marginBottom: Token.spacing.xs,
  },
  icon: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Token.spacing.m,
  },
  title: {
    marginLeft: Token.spacing.xs,
  },
  addEmailButton: {
    width: 'fit-content',
  },
});

import { StaticData, Airport } from 'flight/search/types';

function findAirportsFromAreaCode(
  areaCode: string,
  staticData: StaticData
): Airport[] | undefined {
  const airportCodes = staticData.areaAirportsMap[areaCode];

  if (airportCodes) {
    return airportCodes
      .split('|')
      .map(airportCode => staticData.airportMap[airportCode]!);
  }

  return;
}

/**
 * @param code may be Airport Code or Area Code
 */
export function guessAirportName(
  code: string,
  staticData: StaticData,
  allAiportLabel: string
): string {
  const airport = staticData.airportMap[code];
  if (airport) {
    return `${airport.city} (${code}) - ${airport.internationalAirportName}`;
  }

  const areaAirports = findAirportsFromAreaCode(code, staticData);
  if (areaAirports) {
    return `${areaAirports[0].city} (${allAiportLabel})`;
  }

  return code;
}

export function guessAirportCity(code: string, staticData: StaticData): string {
  const airport = staticData.airportMap[code];
  if (airport) {
    return `${airport.city} (${code})`;
  }

  const areaAirports = findAirportsFromAreaCode(code, staticData);
  if (areaAirports) {
    return `${areaAirports[0].city} (${code})`;
  }

  return code;
}

export function getCityWithAirportCode(
  airportCode: string,
  staticData: StaticData
): string {
  const airport = staticData.airportMap[airportCode];
  if (airport) {
    return `${airport.city} (${airportCode})`;
  }

  return airportCode;
}

export function getAirportNameWithAirportCode(
  airportCode: string,
  staticData: StaticData
): string {
  const airport = staticData.airportMap[airportCode];
  if (airport) {
    return `${airport.internationalAirportName} (${airportCode})`;
  }

  return airportCode;
}

export function getAirportName(
  airportCode: string,
  staticData: StaticData
): string {
  const airport = staticData.airportMap[airportCode];
  if (airport) {
    return airport.internationalAirportName;
  }

  return airportCode;
}

export function getAirportCity(
  airportCode: string,
  staticData: StaticData
): string {
  const airport = staticData.airportMap[airportCode];
  if (airport) {
    return airport.city;
  }

  return airportCode;
}

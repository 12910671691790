import { format } from 'date-fns';

import { PartialEmployee } from 'company/types';
import { FlightSearchData } from 'flight/search/utils/flight-search-view-util';
import {
  AdultPassenger,
  ChildPassenger,
  FlightAddOns,
  Gender,
  Honorific,
  InfantPassenger,
  NonEmployeeType,
  PassengerDetail,
} from 'flight/shared/api/types';
import JourneyType from 'flight/shared/constants/journey-type';
import { JAVA_DATE_ALT } from 'shared/utils/date';

import {
  AddOnsResponse,
  CalculateTransactionFeeResponse,
  SubmitBookingRequest,
} from '../../shared/api';
import {
  AdultTravelerForm,
  ChildTravelerForm,
  InfantTravelerForm,
  PaymentApproval,
  PolicyBypassRemarks,
  PrebookForm,
} from '../types';

const genderMap: Record<Honorific, Gender> = {
  MR: 'M',
  MRS: 'F',
  MISS: 'F',
};

type PreprocessTraveler = (
  traveler: AdultTravelerForm | ChildTravelerForm | InfantTravelerForm,
  isEmployee: boolean,
  addOns: AddOnsResponse['flights'],
  documentTypeFc: boolean,
  additionalInfo?: Record<string, any>
) => AdultPassenger | ChildPassenger | InfantPassenger;

export function generatePrebookSpec(
  values: PrebookForm,
  searchId: Nullable<string>,
  flights: FlightSearchData[],
  journeyType: JourneyType,
  passengers: PartialEmployee[],
  addOns: AddOnsResponse['flights'],
  calculatedFee: CalculateTransactionFeeResponse,
  documentTypeFc: boolean,
  paymentApproval?: PaymentApproval,
  formValue?: PolicyBypassRemarks
): SubmitBookingRequest {
  const { customerPhone } = values.contactDetail;
  const { adults = [], children = [], infants = [] } = values.passengers;

  return {
    ...calculatedFee,
    type: 'FLIGHT',
    confirmSubmit: true,
    locale: 'en_ID',
    policyBypassRemarks: formValue?.reason,
    productRequestId: paymentApproval?.productRequestId,
    notes: values.notes,
    attachmentUrl: values.attachment?.url,
    tripRequestId: paymentApproval?.tripRequestId,
    contact: {
      phoneNumber: customerPhone.phone,
      phoneNumberPrefix: customerPhone.prefix,
    },
    flight: {
      journeyType,
      passengers: {
        adults: adults.map(
          (adult, adultIndex) =>
            preprocessTraveler(
              adult,
              !!passengers[adultIndex],
              addOns,
              documentTypeFc,
              passengers[adultIndex]
                ? {
                    email: passengers[adultIndex].email,
                  }
                : undefined
            ) as AdultPassenger
        ),
        children: children.map(
          child =>
            preprocessTraveler(
              child,
              false,
              addOns,
              documentTypeFc
            ) as ChildPassenger
        ),
        infants: infants.map(
          infant =>
            preprocessTraveler(
              infant,
              false,
              addOns,
              documentTypeFc
            ) as InfantPassenger
        ),
      },
      flightIds: flights
        .map(flight => flight.flightId)
        .filter((flightId, index, ar) => ar.indexOf(flightId) === index),
      airlineCodes: flights.flatMap(flight =>
        flight.segments.map(segment => segment.airlineCode)
      ),
    },
    trackingSpec: {
      pageName: 'FLIGHT_PREBOOK',
      pageCategory: 'FLIGHT',
      data: { searchId },
    },
  };
}

function preprocessAddOns(
  traveler: AdultTravelerForm | ChildTravelerForm,
  addOns: AddOnsResponse['flights']
): FlightAddOns[] {
  return traveler.addOns.map((flightAddOns, flightIndex) => {
    return {
      journeysWithAvailableAddOnsOptions: flightAddOns.journeys.map(
        (addOn, journeyIndex) => {
          if (!Array.isArray(addOn)) {
            return {
              segmentsWithAvailableAddOns: [],
              availableAddOnsOptions: {
                baggageOptions: [addOn],
                mealOptions: [],
              },
            };
          }

          const segmentAddons =
            addOns[flightIndex].journeysWithAvailableAddOnsOptions[journeyIndex]
              .segmentsWithAvailableAddOns;

          return {
            availableAddOnsOptions: {
              baggageOptions: [],
              mealOptions: [],
            },
            segmentsWithAvailableAddOns: segmentAddons.map(
              (origAddon, segmentIndex) => {
                return {
                  segment: origAddon.segment,
                  availableAddOnsOptions: {
                    baggageOptions: [addOn[segmentIndex]],
                    mealOptions: [],
                  },
                };
              }
            ),
          };
        }
      ),
    };
  });
}

const preprocessTraveler: PreprocessTraveler = (
  traveler: AdultTravelerForm | ChildTravelerForm | InfantTravelerForm,
  isEmployee: boolean,
  addOns: AddOnsResponse['flights'],
  documentTypeFc: boolean,
  additionalInfo?: Record<string, any>
) => {
  const { dateOfBirth: dob, documentDetail } = traveler;
  const { expirationDate: exp } = documentDetail || {};

  const dateOfBirth =
    dob && new Date(Number(dob.year), Number(dob.month), Number(dob.day));
  const expirationDate =
    exp && new Date(Number(exp.year), Number(exp.month), Number(exp.day));

  const result: PassengerDetail = {
    ...traveler,
    ...additionalInfo,
    isEmployee,
    gender: genderMap[traveler.title],
    dateOfBirth: dateOfBirth && format(dateOfBirth, JAVA_DATE_ALT),
    documentDetail: {
      ...documentDetail,
      expirationDate: expirationDate && format(expirationDate, JAVA_DATE_ALT),
    },
  };

  if (documentTypeFc) {
    if (!expirationDate) {
      delete result.documentDetail!.expirationDate;
    }

    if (
      result.documentDetail &&
      Object.keys(result.documentDetail).length === 0
    ) {
      result.documentDetail = null;
    }
  }

  switch (traveler.type) {
    case NonEmployeeType.CHILD:
      return {
        ...result,
        fullName: traveler.fullName,
        addOns: preprocessAddOns(traveler, addOns),
      } as ChildPassenger;
    case NonEmployeeType.INFANT:
      return result as InfantPassenger;
    default:
      //Because Employee doesn't have `type`
      return {
        ...result,
        addOns: preprocessAddOns(traveler, addOns),
      } as AdultPassenger;
  }
};

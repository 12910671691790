import React, {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
} from 'react';

import { useApi, useAuth } from '@traveloka/ctv-core';

import { ProfileResponse, GET_PROFILE } from 'profile/api';

type Context = {
  isFetching: boolean;
  fetchData(): void;
  data?: ProfileResponse;
};

const ProfileContext = createContext<Context>(undefined!);

export function ProfileProvider({ children }: PropsWithChildren<{}>) {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [profile, setProfile] = useState<ProfileResponse>();

  const { isAuthenticated } = useAuth();

  const fetchProfile = useApi<ProfileResponse>({
    domain: 'management',
    method: 'post',
    path: GET_PROFILE,
  });

  function fetchProfileApi() {
    if (!isAuthenticated) {
      return;
    }

    setIsFetching(true);
    fetchProfile({})
      .then(res => {
        if (res.success) {
          setProfile(res.data);
        }
      })
      .finally(() => setIsFetching(false));
  }

  return (
    <ProfileContext.Provider
      value={{
        isFetching,
        fetchData: fetchProfileApi,
        data: profile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export function useProfileContext() {
  return useContext(ProfileContext);
}

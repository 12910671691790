import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Button, Icon, Text, Token } from '@traveloka/web-components';

import CompletedIcon from '@traveloka/icon-kit-web/svg/greenPrimary/ic_marketing_survey_24px.svg';

import { StepHeader } from 'shared/components/Layout';
import useLocalizedHistory from 'shared/hooks/useLocalizedHistory';

type Props = {
  title: string;
};
export default function RegistrationUserRequest(props: Props) {
  const { title } = props;

  const cr = useContentResource().CorporateRegistration;
  const history = useLocalizedHistory();

  function redirectToHomePage() {
    history.push('/');
  }

  return (
    <>
      <StepHeader current={-1} steps={[]} />
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <Icon src={CompletedIcon} width={70} />
        </View>
        <Text style={styles.title} variant="headline">
          {title}
        </Text>
        <Text style={styles.content} ink="secondary">
          {cr.userRequestBody}{' '}
          <Text ink="highlight">{cr.userRequestAmEmail}</Text>
        </Text>
        <Button
          variant="main-cta"
          text={cr.userRequestRedirectHomepage}
          onPress={redirectToHomePage}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    backgroundColor: Token.color.uiGreenLight,
    width: 150,
    height: 150,
    borderRadius: Token.border.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginTop: Token.spacing.xl,
    marginBottom: Token.spacing.m,
  },
  content: {
    marginBottom: Token.spacing.xl,
    maxWidth: 650,
    textAlign: 'center',
  },
});

import React from 'react';
import { StyleSheet } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { Text, Token } from '@traveloka/web-components';

import { useHotelRequestRefundForm } from 'refund/request-refund/contexts/hotel/HotelRequestRefundFormContext';
import { formatMessage } from 'shared/utils/intl';

export default function RefundReason() {
  const { mainRefundReason } = useHotelRequestRefundForm();

  const content = useContentResource().CorporateHotelRequestRefundReview;
  const mainRefundReasonContent = useContentResource()
    .CorporateEnumHotelMainRefundReason;

  return (
    <>
      <Text variant="title-1">
        {mainRefundReasonContent[mainRefundReason!.mainReason]}
      </Text>
      {mainRefundReason!.additionalInformation && (
        <Text style={Style.additionalInfo}>
          {mainRefundReason!.mainReason === 'OTHERS'
            ? `"${mainRefundReason!.additionalInformation}"`
            : formatMessage(content.identicalTripText, {
                tripID: mainRefundReason!.additionalInformation,
              })}
        </Text>
      )}
    </>
  );
}

const Style = StyleSheet.create({
  additionalInfo: {
    marginTop: Token.spacing.m,
  },
});

import React from 'react';
import { createElement, StyleSheet, View } from 'react-native';

import { Link, LinkProps } from 'react-router-dom';

import InfoIcon from '@traveloka/icon-kit-web/svg/dark/ic_system_status_info-fill_24px.svg';
import { Button, Icon, InfoBox } from '@traveloka/web-components';
import { useContentResource } from '@traveloka/ctv-core';

function RNLink(props: LinkProps) {
  return createElement(Link, props);
}

function FlightPrebookExpired() {
  const content = useContentResource().CorporateFlightPrebook;

  return (
    <InfoBox
      message={content.expiredMessage}
      Icon={() => <Icon src={InfoIcon} width={16} height={16} />}
      isFullWidth={false}
      variant="ALERT"
      Action={
        <View>
          <RNLink to="/" style={Style.link}>
            <Button
              variant="text"
              size="small"
              text={content.expiredBackButtonText}
            />
          </RNLink>
        </View>
      }
    />
  );
}

const Style = StyleSheet.create({
  link: {
    textDecorationLine: 'none',
  },
});

export default FlightPrebookExpired;

import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

const CompanyAttachmentList: PrivatePageComponent = function() {
  return null;
};

CompanyAttachmentList.routeProtection = function() {
  return { path: '/financial/attachment' };
};

export default CompanyAttachmentList;

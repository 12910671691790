import React from 'react';
import { FlatList } from 'react-native';

import { appendTestId } from '../../shared/utils/TestUtil';
import HotelAutocompleteItem, {
  HotelAutocompleteItemLoader,
} from './HotelAutocompleteItem';
import { Item } from './types';
import Divider from '../../shared/components/Divider/Divider';
import HotelAutocompleteHeader from './HotelAutocompleteHeader';

export type ItemComponentType = {
  testID?: string;
  isActive: boolean;
  onPress(): void;
} & Item;

export type Props<T> = {
  testID?: string;
  items: T[];
  onPressItem: (item: T) => void;
  activeIndex: number;
  startIndex: number;
  filterText: string;
  headerText?: string;
  isLoading?: boolean;
};

function HotelAutocompleteList<T extends Item>(props: Props<T>) {
  const {
    testID,
    items,
    onPressItem,
    activeIndex,
    startIndex,
    filterText,
    headerText,
    isLoading = false,
  } = props;

  if (isLoading) {
    return (
      <FlatList
        initialNumToRender={10}
        maxToRenderPerBatch={10}
        data={Array.from({ length: 5 })}
        extraData={activeIndex}
        windowSize={3}
        scrollEnabled={false}
        keyExtractor={(item, index) => `${index}-${item}`}
        ItemSeparatorComponent={() => <Divider margin="none" />}
        ListHeaderComponent={() => {
          // Type more header text only show when typing
          if (headerText) {
            return <HotelAutocompleteHeader label={headerText} />;
          }
          return null;
        }}
        renderItem={({ index }) => <HotelAutocompleteItemLoader key={index} />}
      />
    );
  }

  return (
    <FlatList
      initialNumToRender={10}
      maxToRenderPerBatch={10}
      data={items}
      extraData={activeIndex}
      windowSize={3}
      scrollEnabled={false}
      keyExtractor={(item, index) => `${index}-${item.value}`}
      ItemSeparatorComponent={() => <Divider margin="none" />}
      ListHeaderComponent={() => {
        // Type more header text only show when typing
        if (headerText) {
          return <HotelAutocompleteHeader label={headerText} />;
        }
        return null;
      }}
      renderItem={({ item, index }) => (
        <HotelAutocompleteItem
          testID={appendTestId(testID, `list.${index}`)}
          label={item.label}
          subLabel={item.subLabel}
          badgeText={item.badgeText}
          subBadgeText={item.subBadgeText}
          filterText={filterText}
          isActive={activeIndex - startIndex === index}
          onPress={() => onPressItem(item)}
        />
      )}
    />
  );
}

export default HotelAutocompleteList;

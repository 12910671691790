import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';

import Chevron from '@traveloka/icon-kit-web/svg/dark/ic_system_chevron_down_16px.svg';
import { Token, Icon } from '@traveloka/web-components';
import { useContentResource } from '@traveloka/ctv-core';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import CreditLimitIcon from 'credit-limit/components/CreditLimitIcon/CreditLimitIcon';
import CreditLimitBadge from 'credit-limit/components/CreditLimitBadge/CreditLimitBadge';
import { useCreditLimit } from 'credit-limit/context';

export default function HeaderCreditLimit() {
  const { data: creditLimit, fetchCreditLimit } = useCreditLimit(); // Deleted if BE already done
  const { isSuspended } = useAccountStatus();
  const accountStatusContent = useContentResource().CorporateAccountStatus;

  useEffect(() => {
    fetchCreditLimit();
  }, []);

  if (!creditLimit) {
    return (
      <View style={[Style.container, { alignItems: 'flex-end' }]}>
        <CreditLimitIcon
          testID="header"
          style={Style.icon}
          status={undefined}
          size="tiny"
        />
        <Icon src={Chevron} />
      </View>
    );
  }

  if (creditLimit.hasPermission) {
    if (isSuspended) {
      return (
        <View style={Style.container}>
          <CreditLimitBadge
            testID="header"
            style={Style.badge}
            status={'EMPTY'}
            percentage={accountStatusContent.suspended}
          />
          <Icon src={Chevron} />
        </View>
      );
    }

    return (
      <View style={Style.container}>
        <CreditLimitBadge
          testID="header"
          style={Style.badge}
          status={creditLimit.remainingCreditLimit.status}
          percentage={creditLimit.remainingPercentage}
        />
        <Icon src={Chevron} />
      </View>
    );
  }

  if (isSuspended) {
    return (
      <View style={[Style.container, { alignItems: 'flex-end' }]}>
        <CreditLimitIcon
          testID="header"
          style={Style.icon}
          status={'EMPTY'}
          size="tiny"
          isSuspended
        />
        <Icon src={Chevron} />
      </View>
    );
  }

  return (
    <View
      style={[
        Style.container,
        creditLimit.hasPermission
          ? { alignItems: 'center' }
          : { alignItems: 'flex-end' },
      ]}
    >
      <CreditLimitIcon
        style={Style.icon}
        status={creditLimit.remainingCreditLimit.status}
        size="tiny"
        testID="header"
      />
      <Icon src={Chevron} />
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  icon: {
    marginLeft: Token.spacing.l,
    marginRight: Token.spacing.s,
  },
  badge: {
    marginRight: Token.spacing.s,
  },
});

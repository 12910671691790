import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useApi } from '@traveloka/ctv-core';
import {
  EntryListRequest,
  EntryListResponse,
  GET_ENTRY_LIST,
  Operator,
} from '@traveloka/ctv-core/pad';

import { Employee } from 'company/types';
import { FlightNonEmployeeTraveler } from 'flight/shared/api/types';
import { Traveler } from 'shared/components/TravelerPickerModal/RevampTravelerPickerModal';

import { parseSearchSpec, SearchSpec } from '../utils/flight-search-query-util';
import { useStaticData } from './StaticDataContext';

export type ComputedSearchSpec = Omit<SearchSpec, 'passengers'> & {
  passengers: Traveler[];
};

export const defaultFlightNonEmployee: FlightNonEmployeeTraveler = {
  adults: 0,
  children: 0,
  infants: 0,
};

const SpecContext = createContext<
  [ComputedSearchSpec, Dispatch<SetStateAction<SearchSpec>>]
>(undefined!);

export function SearchSpecProvider(props: PropsWithChildren<{}>) {
  const { airportMap, areaAirportsMap } = useStaticData();

  const [passengers, setPassengers] = useState<Traveler[]>([]);
  const [searchSpec, setSearchSpec] = useState<SearchSpec>(
    parseSearchSpec(window.location.search, airportMap, areaAirportsMap)
  );
  const [isLoading, setIsLoading] = useState(false);

  const getEntries = useApi<EntryListResponse<Employee>, EntryListRequest>({
    domain: 'management',
    method: 'post',
    path: GET_ENTRY_LIST,
  });

  useEffect(() => {
    if (searchSpec.passengers.length === 0) return;
    setIsLoading(true);
    const spec: EntryListRequest = {
      entityType: 'employeeList',
      search: {
        entriesCount: searchSpec.passengers.length,
        filter: [
          {
            fieldName: 'employeeId',
            arguments: {
              operator: Operator.IN,
              value: searchSpec.passengers,
            },
          },
        ],
      },
    };

    getEntries(spec)
      .then(res => {
        if (res.success) {
          const { entries } = res.data;
          setPassengers(
            searchSpec.passengers
              .map(p => entries.find(e => e.employeeId.toString() === p))
              .filter(Boolean) as Traveler[]
          );
        }
      })
      .finally(() => setIsLoading(false));
  }, [searchSpec]);

  if (isLoading) {
    return null;
  }

  return (
    <SpecContext.Provider
      value={[
        {
          ...searchSpec,
          passengers,
          nonEmployeeTravelers: searchSpec.nonEmployeeTravelers,
        },
        setSearchSpec,
      ]}
    >
      {props.children}
    </SpecContext.Provider>
  );
}

export function useSearchSpec() {
  const [spec] = useContext(SpecContext) ?? [];
  return spec;
}

export function useSearchSpecDispatch() {
  const [_, dispatch] = useContext(SpecContext) ?? [undefined, () => {}];
  return dispatch;
}

import { Permission } from '@traveloka/ctv-core';
import React from 'react';
import { PrivatePageComponent } from 'shared/auth/PrivateRoute';

import ManageCreditCardContent from './components/ManageCreditCardContent';
import { ManageCardProvider } from './context/CardListContext';

const ManageCard: PrivatePageComponent = function() {
  return (
    <ManageCardProvider>
      <ManageCreditCardContent />
    </ManageCardProvider>
  );
};

ManageCard.routeProtection = function({ user }) {
  const hasPermission = user.has(Permission.BILLING_MODIFY);

  if (!hasPermission) {
    return { path: '/' };
  }

  return;
};

export default ManageCard;

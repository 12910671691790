import React from 'react';

import { Button, Icon } from '@traveloka/web-components';
import { Props as ButtonProps } from '@traveloka/web-components/src/momentum/Button/Button';

import IcSystemChevronRightLight from '@traveloka/icon-kit-web/svg/light/ic_system_chevron_right_12px.svg';
import IcSystemChevronRightBlue from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_right_12px.svg';

type Props = {
  text: string;
  variant?: 'secondary' | 'main-cta';
  style?: ButtonProps['style'];
  onPress(): void;
};

const iconMap = {
  secondary: IcSystemChevronRightBlue,
  'main-cta': IcSystemChevronRightLight,
};

export default function GenericHomeButton(props: Props) {
  const { text, variant = 'main-cta', style, onPress } = props;

  return (
    <Button
      text={text}
      variant={variant}
      style={style}
      iconEnd={() => <Icon src={iconMap[variant]} />}
      onPress={onPress}
    />
  );
}

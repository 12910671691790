export const content = {
  CorporateFlightBookingErrorMessages: {
    bookingSpecNotAllowedTitle: '',
    bookingSpecNotAllowedDesc: '',
    inappropriateWordsTitle: '',
    inappropriateWordsDesc: '',
    flightNotAvailableTitle: '',
    flightNotAvailableDesc: '',
    timeoutTitle: '',
    timeoutDesc: '',
    generalErrorTitle: '',
    generalErrorDesc: '',
    reservationSystemErrorTitle: '',
    reservationSystemErrorDesc: '',
    fallbackErrorTitle: '',
    fallbackErrorDesc: '',
  },
  CorporateCommon: {
    errorMessage: '',
  },
  CorporateCC: {
    cardHolder: '',
    creditCardNumber: '',
    cvv: '',
    cvvTooltip: '',
    errorBelowMinimumChar: '',
    errorExpiredCard: '',
    errorInvalidCard: '',
    errorMoreThanMaxChar: '',
    errorName: '',
    errorRequiredField: '',
    expiryDate: '',
    tnc: '',
  },
  CorporateFlightSeatClass: {
    BUSINESS: '',
    ECONOMY: '',
    FIRST: '',
    MIXED: '',
    OTHERS: '',
    PREMIUM_ECONOMY: '',
    PROMO: '',
  },
  CorporateFlightSearch: {
    allAirports: '',
    baggagePaid: '',
    baggagePaidPartial: '',
    baggagePartial: '',
    changeDeparture: '',
    changeSearch: '',
    choose: '',
    chooseDeparture: '',
    chooseReturn: '',
    departurePrice: '',
    fareDetail: '',
    flightDetail: '',
    inFlightMealPartial: '',
    noFlights: '',
    nonCompliant: '',
    pax: '',
    pleaseModify: '',
    taxInclusive: '',
    totalDuration: '',
  },
  CorporateFlightSearchReturnInvalidModal: {
    title: '',
    body: '',
    closeButtonText: '',
  },
  CorporateFlightSearchReturnConfirmationModal: {
    bookText: '',
    departureText: '',
    totalDurationText: '',
    fareInfoText: '',
    flightDetailsText: '',
    inclusiveTaxText: '',
    priceSuffix: '',
    returnText: '',
    title: '',
  },
  CorporateFlightSearchSmartCombo: {
    tag: '',
    tooltipTitle: '',
    tooltipDescription: '',
    toSmartComboTitle: '',
    toSmartComboDescription: '',
    toNormalFlightTitle: '',
    toNormalFlightDescription: '',
    continueButton: '',
  },
  CorporateFlightGeneral: {
    additionalFee: '',
    adminFee: '',
    adultFare: '',
    childFare: '',
    infantFare: '',
    pax: '',
    baggage: '',
    baggagePiece: '',
    detail: '',
    direct: '',
    durationD: '',
    durationH: '',
    durationM: '',
    extraDay: '',
    fuelSurcharge: '',
    inFlightMeal: '',
    multiAirline: '',
    operatedBy: '',
    psc: '',
    regularTotalPrice: '',
    save: '',
    stopOverInfo: '',
    totalPrice: '',
    transitDifferentAirport: '',
    transitMayNeedReCheckInInfo: '',
    transitStopInfo: '',
    transitMayNeedReCheckIn1: '',
    transitMayNeedReCheckIn2: '',
    transitVisaRequiredInfo: '',
    transitVisaRequired1: '',
    transitInfo: '',
    transitPlural: '',
    vat: '',
  },
  CorporateFlightRefundableStatus: {
    REFUNDABLE: '',
    NON_REFUNDABLE: '',
    UNKNOWN: '',
  },
  CorporateFlightSearchToolbar: {
    airline: '',
    filter: '',
    more: '',
    moreBaggage: '',
    moreExcludeLateNight: '',
    moreExcludeMultiple: '',
    moreExcludeOvernight: '',
    moreFacilities: '',
    moreInFlightMeal: '',
    morePreference: '',
    morePrice: '',
    selectAll: '',
    sort: '',
    sortEarliestArrival: '',
    sortEarliestDeparture: '',
    sortLatestArrival: '',
    sortLatestDeparture: '',
    sortLowestPrice: '',
    sortShortestDuration: '',
    time: '',
    timeArrival: '',
    timeDeparture: '',
    transit: '',
    transitCount: '',
    transitDirect: '',
    transitDuration: '',
    transitHourRange: '',
    transitLocation: '',
    transitPluralPlus: '',
    complianceTitle: '',
    yes: '',
    no: '',
  },
  CorporateFlightPrebook: {
    pageTitle: '',
    pageSubtitle: '',
    step1: '',
    step2: '',
    step1Approval: '',
    step2Approval: '',
    submitPrebookButtonText: '',
    createPaymentApprovalButtonText: '',
    priceBreakdownInfoText: '',
    loadingText: '',
    expiredMessage: '',
    expiredBackButtonText: '',
  },
  CorporateFlightPrebookTravelerForm: {
    title: '',
    adultText: '',
    childText: '',
    infantText: '',
    travelerTitleField: '',
    travelerTitleRequiredErrorMessage: '',
    dobField: '',
    dobAdultDescription: '',
    dobChildDescription: '',
    dobInfantDescription: '',
    DobRequiredErrorMessage: '',
    earliestDobChosen: '',
    latestDobChosen: '',
    nationalityField: '',
    nationalityRequiredErrorMessage: '',
    identityNumberField: '',
    identityNumberNumericLengthErrorMessage: '',
    passportNumberField: '',
    passportNumberRequiredErrorMessage: '',
    passportNumberAlphanumericErrorMessage: '',
    passportNumberMinLengthErrorMessage: '',
    passportNumberMaxLengthErrorMessage: '',
    issuingCountryField: '',
    issuingCountryRequiredErrorMessage: '',
    expirationDateField: '',
    expirationDateRequiredErrorMessage: '',
    earliestexpirationDateChosen: '',
    latestexpirationDateChosen: '',
    addOnTitle: '',
    baggageLabel: '',
    baggageCityText: '',
    nonEmployeeTitle: '',
    fullNameField: '',
    fullNameRequiredErrorMessage: '',
    fullNameInvalidCharacterErrorMessage: '',
    emailField: '',
    emailFieldSubtitle: '',
    emailFormatErrorMessage: '',
  },
  CorporateFlightPrebookContactForm: {
    title: '',
    nameField: '',
    phoneField: '',
    phoneFieldDescription: '',
    phoneRequiredErrorMessage: '',
    phoneMinLengthErrorMessage: '',
    phoneMaxLengthErrorMessage: '',
    phoneNumericOnlyErrorMessage: '',
    emailField: '',
  },
  CorporateFlightPrebookPriceBreakdown: {
    title: '',
    totalPriceLabel: '',
    serviceFeeText: '',
    serviceFeeVatText: '',
  },
  CorporateFlightPrebookAdditionalInfo: {
    attachmentInfo: '',
    attachmentInfoBoxContent: '',
    attachmentInfoBoxTitle: '',
    notesField: '',
    notesFieldDescription: '',
    notesPlaceholder: '',
    notesRequiredErrorMessage: '',
    title: '',
    uploadAttachmentField: '',
    uploadAttachmentButtonText: '',
    uploadAttachmentRetryButtonText: '',
  },
  CorporateFlightPrebookApprovalSystem: {
    approvalReasonPlaceholder: '',
    approvalReasonRequiredErrorMessage: '',
    approverNameLabel: '',
    paymentApprovalBookingAttachmentInfo: '',
    paymentApprovalBookingAttachmentLabel: '',
    paymentApprovalBookingAttachmentPlaceholder: '',
    paymentApprovalBookingAttachmentButtonText: '',
    paymentApprovalBookingAttachmentRetryButtonText: '',
    paymentApprovalBookingAttachmentTooltip: '',
    paymentApprovalBookingNotesLabel: '',
    paymentApprovalInfoBoxContent: '',
    paymentApprovalInfoBoxTitle: '',
    title: '',
    tripInfo: '',
    tripNameLabel: '',
  },
  CorporateFlightPrebookProductSummary: {
    detailButtonText: '',
    departureText: '',
    arrivalText: '',
    refundableText: '',
    nonRefundableText: '',
    modalTitle: '',
    modalTabItemFlight: '',
    modalTabItemHasRefundPolicy: '',
  },
  CorporateFlightPrebookConfirmationModal: {
    title: '',
    headline: '',
    departureTitleText: '',
    returnTitleText: '',
    travelerTitleText: '',
    totalPriceText: '',
    infoText: '',
    closeButtonText: '',
    submitButtonText: '',
    nonEmployeeAdultText: '',
    nonEmployeeChildText: '',
    nonEmployeeInfantText: '',
  },
  CorporateFlightPrebookReasonModal: {
    title: '',
    line1Text: '',
    line2Text: '',
    reasonRequiredErrorMessage: '',
    reasonMaxLengthErrorMessage: '',
    infoText: '',
    closeButtonText: '',
    submitButtonText: '',
  },
  CorporateFlightPrebookAvailabilityModal: {
    title: '',
    line1Text: '',
    line2Text: '',
    closeButtonText: '',
  },
  CorporateFlightPrebookPriceChangeModal: {
    title: '',
    line1Text: '',
    line1TextBold: '',
    line2Text: '',
    line3Text: '',
    closeButtonText: '',
    submitButtonText: '',
  },
  CorporateInsufficientCreditLimit: {
    title: '',
    description: '',
    backButtonText: '',
  },
  CorporateManageCard: {
    addCard: '',
    addCardTitle: '',
    addOneCard: '',
    backupCard: '',
    cancel: '',
    close: '',
    confirm: '',
    expired: '',
    mainCard: '',
    manageCardErrorMessage: '',
    noBackupCard: '',
    noCards: '',
    pageTitle: '',
    remove: '',
    removeBackupCard: '',
    removeMainCard: '',
    removeMainCardNoBackup: '',
    removeTitle: '',
    setMainCard: '',
    setMainCardBody: '',
    setMainCardTitle: '',
  },
  CorporateRegistration: {
    aboutSection: '',
    aboutSectionHelp: '',
    aboutSectionHelpV2: '',
    aboutSectionV2: '',
    accountCreatedPhase: '',
    accountCreatedPhaseHelpV2: '',
    accountCreatedPhaseHelpV3: '',
    accountCreatedPhaseV2: '',
    activateAccountButton: '',
    activateAccountInfo: '',
    addEmailConfirmInfo: '',
    addEmailConfirmOkButton: '',
    addEmailConfirmTitle: '',
    addEmailModalAddButton: '',
    addEmailModalCancelButton: '',
    addEmailModalEmail1Label: '',
    addEmailModalEmail2Label: '',
    addEmailModalSameEmailErrorMessage: '',
    addEmailModalTitle: '',
    addEmailText: '',
    agreementFormInfoBoxActionText: '',
    agreementFormInfoBoxContent: '',
    agreementFormInfoBoxTitle: '',
    agreementFormSignedLabel: '',
    agreementFormTitle: '',
    agreementSigning: '',
    agreementSigningDesc: '',
    back: '',
    benefitDownloadText: '',
    benefits: '',
    benefits1Description: '',
    benefits1Title: '',
    benefits2Description: '',
    benefits2Title: '',
    benefits3Description: '',
    benefits3Title: '',
    benefitsDescription: '',
    benefitsTitle: '',
    cityMaxLength: '',
    cityPlaceholder: '',
    cityRequired: '',
    citySpecialCharacter: '',
    companyIndustryLabel: '',
    companyIndustryLabelV2: '',
    companyIndustryRequired: '',
    companyNameLabel: '',
    companyNameMaxLength: '',
    companyNamePlaceholder: '',
    companyNameRequired: '',
    companyNameSpecialCharacter: '',
    companySection: '',
    companyVerification: '',
    companyVerificationDesc: '',
    completedLabel: '',
    continueButtonText: '',
    continueToDocument: '',
    continueToDocumentV2: '',
    continueToReview: '',
    countryLabel: '',
    countryRequired: '',
    creditAssessment: '',
    creditAssessmentDesc: '',
    creditAssessmentNotesLabel: '',
    creditAssessmentNotesPlaceholder: '',
    creditAssessmentSection: '',
    creditCard: '',
    creditCardBenefitsV2: '',
    creditCardBenefitsV3: '',
    creditCardHelp: '',
    creditCardHelpV2: '',
    delete: '',
    directorAttorneyFullNameLabel: '',
    directorAttorneyInfoBoxContent: '',
    directorAttorneyInfoBoxTitle: '',
    directorAttorneyPositionLabel: '',
    directorAttorneyTitle: '',
    documentExtension: '',
    documentExtensionInfo: '',
    documentFileSize: '',
    documentInfo: '',
    documentInfoV2: '',
    documentNotesLabel: '',
    documentNotesPlaceholder: '',
    documentRequestNdaButton: '',
    documentRequestNdaContent: '',
    documentRequestNdaTitle: '',
    documentRequired: '',
    documentSectionV2: '',
    documentSectionV3: '',
    documentSubInfo: '',
    emailHasRegistered: '',
    emailLabel: '',
    emailLabelV2: '',
    emailSent: '',
    expensesLabel: '',
    expensesLabelV2: '',
    expensesRequired: '',
    failedDownloadErrorMessage: '',
    failedRequestMeetingErrorMessage: '',
    failedRequestNdaErrorMessage: '',
    faqBillingCycleInfo: '',
    faqBillingCycleTitle: '',
    faqNdaActionText: '',
    faqNdaInfo: '',
    faqNdaTitle: '',
    faqRegistrationProcessInfo: '',
    faqRegistrationProcessTitle: '',
    faqRequiredDocumentActionText: '',
    faqRequiredDocumentInfo1: '',
    faqRequiredDocumentInfo2: '',
    faqRequiredDocumentTitle: '',
    faqServiceFeeInfo: '',
    faqServiceFeeTitle: '',
    faqTitle: '',
    formInfo: '',
    formInfoBody: '',
    formInfoBodyV2: '',
    formInfoV2: '',
    formSubmitted: '',
    formSubmittedBody: '',
    goBackToOverview: '',
    invoice: '',
    invoiceBenefitsV2: '',
    invoiceBenefitsV3: '',
    invoiceHelp: '',
    invoiceHelpV2: '',
    jobTitleLabel: '',
    jobTitleLabelV2: '',
    jobTitleRequired: '',
    legalDetailsAddressPlaceholder: '',
    legalDetailsBillingAddressLabel: '',
    legalDetailsBusinessAddressLabel: '',
    legalDetailsCityPlaceholder: '',
    legalDetailsCopyAddressNpwpCheckbox: '',
    legalDetailsCorporateNameLabel: '',
    legalDetailsNpwpNumberLabel: '',
    legalDetailsPostalCodePlaceholder: '',
    legalDetailsSppkpNumberLabel: '',
    legalDetailsSppkpNumberSubLabel: '',
    legalDetailsTitle: '',
    moreTimeInfo: '',
    moreTimeInfoBody: '',
    nextButtonText: '',
    officeAddressLabel: '',
    officeAddressMaxLength: '',
    officeAddressPlaceholder: '',
    officeAddressPlaceholderV2: '',
    officeAddressRequired: '',
    officeAddressSpecialCharacter: '',
    officePhoneLabel: '',
    officePhoneMaxLength: '',
    officePhoneMinLength: '',
    officePhoneRequired: '',
    officePhoneSpecialCharacter: '',
    onGoingLabel: '',
    otherEmailLabel: '',
    overviewApprovalSystemContent: '',
    overviewApprovalSystemLabel: '',
    overviewAvailableProductsFlight: '',
    overviewAvailableProductsHotel: '',
    overviewAvailableProductsLabel: '',
    overviewBookingMethodContent: '',
    overviewBookingMethodLabel: '',
    overviewBookingSizeContent: '',
    overviewBookingSizeLabel: '',
    overviewDescription: '',
    overviewDownloadText: '',
    overviewFlightSpecialRateContent: '',
    overviewFlightSpecialRateLabel: '',
    overviewPaymentDocumentsItem1: '',
    overviewPaymentDocumentsItem2: '',
    overviewPaymentDocumentsLabel: '',
    overviewPaymentMethodCreditCardLabel: '',
    overviewPaymentMethodCreditCardServiceFee: '',
    overviewPaymentMethodInvoiceItem1: '',
    overviewPaymentMethodInvoiceItem2: '',
    overviewPaymentMethodInvoiceLabel: '',
    overviewPaymentMethodInvoiceServiceFee: '',
    overviewPaymentMethodInvoiceTooltip: '',
    overviewPaymentMethodLabel: '',
    overviewPlatformTypeContent: '',
    overviewPlatformTypeLabel: '',
    overviewTitle: '',
    partnerCompanyNameLabel: '',
    partnerCompanyPhoneLabel: '',
    partnerCompanyPicEmailLabel: '',
    partnerCompanyPicNameLabel: '',
    partnerCompanyRelationLabel: '',
    partnerCompanySameNameErrorMessage: '',
    paymentMethodHelp: '',
    paymentMethodHelpV2: '',
    paymentMethodSectionV2: '',
    paymentMethodSectionV3: '',
    paymentProposalBillingCycleLabel: '',
    paymentProposalCredtLimitLabel: '',
    paymentProposalCredtLimitTooltip: '',
    paymentProposalServiceFeeLabel: '',
    paymentProposalServiceFeeTooltip: '',
    paymentProposalTermsOfPaymentLabel: '',
    paymentProposalTitle: '',
    picEmailFormat: '',
    picEmailMaxLength: '',
    picEmailPlaceholder: '',
    picEmailRequired: '',
    picNameLabel: '',
    picNameLabelV2: '',
    picNameMaxLength: '',
    picNamePlaceholder: '',
    picNameRequired: '',
    picNameSpecialCharacter: '',
    picPhoneLabel: '',
    picPhoneMaxLength: '',
    picPhoneMinLength: '',
    picPhoneRequired: '',
    picPhoneSpecialCharacter: '',
    postalCodeMaxLength: '',
    postalCodePlaceholder: '',
    postalCodeRequired: '',
    postalCodeSpecialCharacter: '',
    preparingAccount: '',
    preparingAccountDesc: '',
    processOverview: '',
    processOverviewHelpV2: '',
    processOverviewHelpV3: '',
    processOverviewV2: '',
    productRequired: '',
    productSection: '',
    productSectionV2: '',
    referenceCheckSection: '',
    referenceCheckSubInfo: '',
    registrationForm: '',
    registrationFormDesc: '',
    registrationPhase: '',
    registrationPhaseComplete: '',
    registrationPhaseCompleteInfo: '',
    registrationPhaseEmailLabel: '',
    registrationPhaseHelpV2: '',
    registrationPhaseHelpV3: '',
    registrationPhaseInfo: '',
    registrationPhaseV2: '',
    registrationTncText: '',
    rejectionAmNotes: '',
    rejectionCreditAssessmentDesc: '',
    rejectionDoubleDataDesc: '',
    rejectionFraudDesc: '',
    rejectionLegalAssessmentDesc: '',
    rejectionTravelAgentDesc: '',
    rejectionTravelAgentLearnMoreButton: '',
    requestMeetingCancelButton: '',
    requestMeetingContent: '',
    requestMeetingPreference: '',
    requestMeetingSameDateErrorMessage: '',
    requestMeetingSchedule1: '',
    requestMeetingSchedule2: '',
    requestMeetingSubmitButton: '',
    requestMeetingTimeUnit: '',
    requestMeetingTitle: '',
    requestMeetingTopic: '',
    requestNdaCancelButton: '',
    requestNdaInfoAction: '',
    requestNdaInfoContent: '',
    requestNdaInfoTitle: '',
    requestNdaSubmitButton: '',
    requestNdaTitle: '',
    requestNdaUploadButton: '',
    requestNdaUploadDescription: '',
    requestNdaUploadInfo: '',
    requestNdaUploadPlaceholder: '',
    requestProductInfo: '',
    requestProductInfoContent: '',
    requestProductLabel: '',
    requestProductPlaceholder: '',
    requestProductSection: '',
    requiredDocumentCc: '',
    requiredDocumentCcContent: '',
    requiredDocumentInvoice: '',
    requiredDocumentInvoiceContent: '',
    requiredDocumentOkButton: '',
    requiredDocumentTitle: '',
    reviewAboutSection: '',
    reviewAboutSectionV2: '',
    reviewCompanySection: '',
    reviewCompanySectionV2: '',
    reviewDocumentSection: '',
    reviewForm: '',
    reviewFormV2: '',
    reviewOtherProductLabel: '',
    reviewPaymentMethodSection: '',
    reviewProductSection: '',
    reviewProductSectionV2: '',
    reviewViewButton: '',
    saveAndExitButtonText: '',
    seeRequiredDocumentsButton: '',
    sendEmail: '',
    sendEmailV2: '',
    sendEmailPlaceholder: '',
    sendEmailPlaceholderV2: '',
    signedRegistrationForm: '',
    signedRegistrationFormFieldLabel: '',
    signInButton: '',
    step1: '',
    step1V2: '',
    step2: '',
    step2V2: '',
    step3: '',
    step4: '',
    step5: '',
    submit: '',
    submitErrorMessage: '',
    submitModalCancelButton: '',
    submitModalInfo: '',
    submitModalSubmitButton: '',
    submitModalTitle: '',
    submitSuccessMessage: '',
    submittedMeethingContent: '',
    submittedMeetingOkButton: '',
    submittedMeetingTitle: '',
    submittedNdaContent: '',
    submittedNdaOkButton: '',
    submittedNdaTitle: '',
    successfulSubmitInfo: '',
    successfulSubmitTitle: '',
    tnc: '',
    tncAgree: '',
    tncError: '',
    tokenExpired: '',
    userRequestAmEmail: '',
    userRequestBody: '',
    userRequestMeetingTitle: '',
    userRequestNdaTitle: '',
    userRequestRedirectHomepage: '',
    validationAlphabetOnly: '',
    validationAlphaNumericOnly: '',
    validationEmailFormat: '',
    validationMaxLength: '',
    validationMaxValue: '',
    validationMinLength: '',
    validationMinValue: '',
    validationNumericOnly: '',
    validationRequired: '',
    verificationAgreementSigningInfo: '',
    verificationCompanyVerifCCInfo: '',
    verificationCompanyVerifInvoiceInfo: '',
    verificationCompleteInfo: '',
    verificationCreditAssessmentInfo: '',
    verificationDefaultInfo: '',
    verificationPhase: '',
    verificationPhaseComplete: '',
    verificationPhaseCorporateEmail: '',
    verificationPhaseHelpV2: '',
    verificationPhaseHelpV3: '',
    verificationPhaseInfoV2AfterEmail: '',
    verificationPhaseInfoV2BeforeEmail: '',
    verificationPhaseInProgress: '',
    verificationPhaseInProgressHour: '',
    verificationPhasePicEmail: '',
    verificationPhaseV2: '',
    verificationPhaseWaitLonger: '',
    verificationStepCompleted: '',
    verificationStepInProgress: '',
    verificationStepRejected: '',
    websiteFormat: '',
    websiteLabel: '',
    websiteLabelV2: '',
    websiteMaxLength: '',
    websitePlaceholder: '',
    whatsNextCollapsibleTitle: '',
    whatsNextConnectActionText: '',
    whatsNextConnectInfo: '',
    whatsNextConnectTitle: '',
    whatsNextCurrentStep: '',
    whatsNextStep1Info: '',
    whatsNextStep1Title: '',
    whatsNextStep2Info: '',
    whatsNextStep2Title: '',
    whatsNextStep3Info: '',
    whatsNextStep3Title: '',
    whatsNextTitle: '',
  },
  CorporateRegistrationMeetingEnum: {
    SERVICE_FEE: '',
    PAYMENT_METHOD: '',
    AVAILABLE_PRODUCTS: '',
    OTHERS: '',
    ONLINE: '',
    OFFLINE: '',
  },
  CorporateRegistrationProductType: {
    FLIGHT: '',
    HOTEL: '',
    CAR_RENTAL: '',
    AIRPORT_TRANSPORT: '',
    INSURANCE: '',
  },
  CorporateRegistrationDropdown: {},
  CorporateApprovalSystemConfig: {
    tabTitle: '',
    roleUpdateTitle: '',
    roleUpdateDescription: '',
    roleUpdateNavigationText: '',
    roleUpdateCheckboxText: '',
    appointApproverTitle: '',
    appointApproverDescription1: '',
    appointApproverDescriptionBold: '',
    appointApproverDescription2: '',
    appointApproverNavigationText: '',
    finishStepTextBold: '',
    finishStepTextRegular: '',
    headerInfoSetUpText: '',
    headerInfoCompleteText: '',
    globalTitle: '',
    tripRequestApprovalTitle: '',
    tripRequestApprovalDescription: '',
    paymentApprovalTitle: '',
    paymentApprovalDescription: '',
    paymentApprovalFirstValue: '',
    paymentApprovalSecondValue: '',
    exceptionTitle: '',
    exceptionDescription: '',
    exceptionAddText: '',
    exceptionViewText: '',
    exceptionEditText: '',
    exceptionDeleteText: '',
    saveButtonText: '',
    viewExceptionModalTitle: '',
    viewExceptionEnabledText: '',
    viewExceptionDisabledText: '',
    addExceptionModalTitle: '',
    addExceptionInputPlaceholder: '',
    exceptionSetConfigText: '',
    exceptionModalCloseText: '',
    editExceptionModalTitle: '',
    leavePromptText: '',
    approverListTitle: '',
    seeFullListApprover: '',
    seeFullListUnassignedApprover: '',
  },
  CorporateApproverList: {
    backText: '',
    approverListTitle: '',
    approverListDesc: '',
    filterPlaceholderText: '',
    paginationOfText: '',
    noApproverForText: '',
    approverForText: '',
    viewApprover: '',
    editApprover: '',
    deleteApprover: '',
    viewModalTitle: '',
    closeButtonText: '',
    nameLabel: '',
    divisionLabel: '',
    emailLabel: '',
    scopeOfApprovalLabel: '',
    addModalTitle: '',
    searchEmployeePlaceholder: '',
    saveButtonText: '',
    appointAsApproverText: '',
    deleteModalTitle: '',
    deleteModalContent: '',
    deleteModalInfo: '',
    deleteButtonText: '',
    cancelButtonText: '',
    editModalTitle: '',
    editModalContent: '',
    showUnassignedApproverLabel: '',
    allUnassignedInfoBoxContent: '',
    allUnassignedInfoBoxTitle: '',
    someUnassignedInfoBoxContent: '',
    someUnassignedInfoBoxTitle: '',
    allAssignedInfoBoxContent: '',
    allAssignedInfoBoxTitle: '',
    assignedDivisionLevelText: '',
    assignedEmployeeLevelText: '',
    assignedGlobalLevelText: '',
    assignedDivisionLevelDesc: '',
    assignedEmployeeLevelDesc: '',
    assignedGlobalLevelDesc: '',
    assignedOtherText: '',
    selectedDivisionText: '',
    selectedEmployeeText: '',
    seeMoreText: '',
    seeLessText: '',
    approverSuggestionTitle: '',
    approverSuggestionDesc: '',
  },
  CorporateApprovalList: {
    pageTitle: '',
    approver: '',
    approverEmptyBody: '',
    approverEmptyTitle: '',
    createRequest: '',
    customizeDate: '',
    filterDate: '',
    filterRole: '',
    filterSort: '',
    filterStatus: '',
    from: '',
    myApproval: '',
    myRequest: '',
    next30Days: '',
    nextMonth: '',
    noResultBody: '',
    noResultTitle: '',
    numProductOpenSearch: '',
    numProductPurchased: '',
    numProductWaitingApproval: '',
    requester: '',
    requesterEmptyBody: '',
    requesterEmptyTitle: '',
    requestId: '',
    resetFilter: '',
    roleAll: '',
    roleRequester: '',
    roleTraveler: '',
    sortEndedSoon: '',
    sortLastUpdated: '',
    statusAll: '',
    statusApproved: '',
    statusDeclined: '',
    statusExpired: '',
    statusProductOpenSearch: '',
    statusProductPurchased: '',
    statusProductWaitingApproval: '',
    statusProductWaitingPayment: '',
    statusWaitingForApproval: '',
    thisMonth: '',
    to: '',
    tripDate: '',
  },
  CorporateAttachment: {
    back: '',
    downloadButtonText: '',
    downloadItemButtonText: '',
    employeeFilter: '',
    employeeFilterAllText: '',
    employeeFilterSelectedText: '',
    infoText: '',
    noAttachmentFoundInfo: '',
    noAttachmentFoundText: '',
    period30Days: '',
    periodCustom: '',
    periodCustomInfo: '',
    periodCustomizeDateFrom: '',
    periodCustomizeDateTo: '',
    seeAllAttachmentsButtonText: '',
    selectAllPage: '',
    selectOnePage: '',
    title: '',
    transactionPeriodFilter: '',
    tripIdFilter: '',
    tripIdLabel: '',
    viewItemButtonText: '',
  },
  CorporateAttachmentLinkModal: {
    clickHereText: '',
    content: '',
    downloadButton: '',
    emailFormButton: '',
    emailFormContent: '',
    emailFormInvalidEmail: '',
    emailFormTitle: '',
    failedButton: '',
    failedContent: '',
    failedTitle: '',
    sendOtherEmail: '',
    successButton: '',
    successContent: '',
    successTitle: '',
    title: '',
  },
  CorporateAttachmentViewModal: {
    downloadButtonText: '',
    requestIdText: '',
  },
  CorporateCreateApproval: {
    activeWithinPeriod: '',
    addApprover: '',
    addUpto: '',
    approverFormTitle: '',
    approverRequired: '',
    attachmentInfoBoxContent: '',
    attachmentInfoBoxTitle: '',
    attachmentTitle: '',
    attachmentUploadButtonText: '',
    attachmentUploadInfo: '',
    attachmentUploadPlaceholder: '',
    attachmentUploadRetryButtonText: '',
    bookerResponsibility: '',
    cancel: '',
    change: '',
    changeBooker: '',
    checkAgain: '',
    confirmationBody: '',
    confirmationTitle: '',
    continue: '',
    delete: '',
    discardAndCancel: '',
    flight: '',
    flightAirportArea: '',
    flightDepartureDate: '',
    flightDestination: '',
    flightMustDiffer: '',
    flightNonEmployeeEnablementCheckbox: '',
    flightOrigin: '',
    flightReturnDate: '',
    flightSeatClass: '',
    guestInfo: '',
    hotel: '',
    hotelCheckInDate: '',
    hotelCheckOutDate: '',
    hotelDestination: '',
    hotelDestinationPlaceholder: '',
    hotelDestinationRequired: '',
    hotelDuration: '',
    hotelNonEmployeeEnablementCheckbox: '',
    hotelNumDuration: '',
    hotelNumRooms: '',
    hotelRoom: '',
    mainBooker: '',
    noProductSelected: '',
    pageTitle: '',
    passengerInfo: '',
    productFormTitleV2: '',
    samePersonNotAllowed: '',
    saveChanges: '',
    searchApprover: '',
    selectApprover: '',
    submit: '',
    travelerEmpty: '',
    travelerFormTitleV2: '',
    travelerModalAdded: '',
    travelerModalAddNew: '',
    travelerModalAll: '',
    travelerModalEmpty: '',
    travelerModalPlaceholder: '',
    travelerModalSave: '',
    travelerModalSubtitle: '',
    travelerModalTitle: '',
    travelerRequired: '',
    tripFormTitle: '',
    tripName: '',
    tripNameRequired: '',
    tripPeriod: '',
    tripPurpose: '',
    tripPurposeRequired: '',
  },
  CorporateApprovalSystemSearchFooter: {
    noTripRequestApprovalDescription: '',
    approvalListLinkText: '',
    createApprovalRequestButton: '',
    hasTripRequestApprovalDescription: '',
    tripDetailLinkText: '',
    closeText: '',
  },
  CorporateApprovalSystemProductConfirmation: {
    bookingDiscrepancyTitle: '',
    cancelButtonText: '',
    continueButtonText: '',
    departureFlight: '',
    destination: '',
    fromNewDateUnitFlight: '',
    fromNewDateUnitHotel: '',
    fromOldDateUnit: '',
    guests: '',
    guestUnit: '',
    adultUnit: '',
    childUnit: '',
    infantUnit: '',
    inputPlaceholder: '',
    loadingText: '',
    numOfGuestsV2: '',
    numOfPassengersV2: '',
    numOfRooms: '',
    numOfAdults: '',
    numOfChildren: '',
    numOfInfants: '',
    passengerUnit: '',
    paymentApprovalModalTitle: '',
    plannedTripDate: '',
    proceedBookingTitle: '',
    reasonEmptyValidationText: '',
    reasonInvalidSymbolValidationText: '',
    requestedTripHeader: '',
    returnFlight: '',
    returnFlightNo: '',
    returnFlightYes: '',
    roomUnit: '',
    seatClass: '',
    thisBookingHeader: '',
    toNewDateUnitFlight: '',
    toNewDateUnitHotel: '',
    toOldDateUnit: '',
    warningNonCompliant: '',
    warningNotMatchText: '',
    withReturnFlight: '',
  },
  CorporateApprovalDetail: {
    actionApprove: '',
    actionDecline: '',
    actionPay: '',
    actionProceed: '',
    activityTitle: '',
    adultText: '',
    approverTitle: '',
    attachmentSize: '',
    awaitingPayment: '',
    bookingNotesText: '',
    checkAgain: '',
    childText: '',
    close: '',
    declineApproval: '',
    declineBody: '',
    declinePlaceholder: '',
    declineTitle: '',
    departureDate: '',
    details: '',
    downloadButtonText: '',
    employeeTravelersTitle: '',
    failedBody: '',
    failedTitle: '',
    flightBaggage: '',
    flightDuration: '',
    flightRequestDetails: '',
    goBack: '',
    gotoPurchaseDetail: '',
    guestDetail: '',
    hotelCheckInDate: '',
    hotelDuration: '',
    hotelRequestDetails: '',
    hotelRoom: '',
    infantText: '',
    mainBooker: '',
    matchFlightDeparture: '',
    matchFlightReturn: '',
    matchFlightType: '',
    matchFlightTypeOneWay: '',
    matchFlightTypeRoundTrip: '',
    matchGuest: '',
    matchHotelDestination: '',
    matchNumOfAdult: '',
    matchNumOfChild: '',
    matchNumOfGuestV2: '',
    matchNumOfInfant: '',
    matchNumOfPassengerV2: '',
    matchNumOfRoom: '',
    matchPassenger: '',
    matchSeatClass: '',
    matchTripDate: '',
    matchTripEnd: '',
    matchTripStart: '',
    noInventoryBody: '',
    noInventoryPlaceholder: '',
    noInventoryTitle: '',
    nonEmployeeFlightTraveler: '',
    nonEmployeeHotelTraveler: '',
    nonEmployeeTravelerAdult: '',
    nonEmployeeTravelerAge: '',
    nonEmployeeTravelerChild: '',
    nonEmployeeTravelerInfant: '',
    nonEmployeeTravelersTitle: '',
    notComply: '',
    notFound: '',
    notMatch: '',
    notMatchBody: '',
    notMatchTitle: '',
    numAdult: '',
    numChild: '',
    numGuest: '',
    numInfant: '',
    numNight: '',
    numPassenger: '',
    numRoom: '',
    passengerDetail: '',
    plannedTripDate: '',
    policyDomesticBudgetText: '',
    policyExcludedAirlinesText: '',
    policyIncludedSeatclassText: '',
    policyInternationalBudget: '',
    policyStarRatingText: '',
    policyDefaultBudgetText: '',
    policyAllLocationText: '',
    policySpecificLocationText: '',
    policyTitle: '',
    priceChangeInfoText1: '',
    priceChangeInfoText2: '',
    priceChangeInfoText3: '',
    priceChangeTitle: '',
    productStatusPurchased: '',
    provideReason: '',
    purchaseFailed: '',
    purchaseSuccessful: '',
    rejectionNotesText: '',
    requestedTrip: '',
    requesterTitle: '',
    requestId: '',
    requestStatus: '',
    respondRquest: '',
    returnDate: '',
    searchFlight: '',
    searchHotel: '',
    statusApproved: '',
    statusDeclined: '',
    statusExpired: '',
    statusWaitingForApproval: '',
    summaryApprovePaymentV2: '',
    summaryApproveTrip: '',
    summaryDeclinePaymentV2: '',
    summaryDeclineTrip: '',
    summaryRequestPaymentV2: '',
    summaryRequestTrip: '',
    thisBooking: '',
    totalPrice: '',
    travelerTitle: '',
    travelerTitleV2: '',
    tripPurposeText: '',
    viewButtonText: '',
  },
  CorporateProductRequestRefund: {
    step1Text: '',
    step2Text: '',
    step3Text: '',
  },
  CorporateEnumFlightRefundDocument: {
    deathCerificateTitle: '',
    doubleBookingTitle: '',
    doubleBookingDesc: '',
    embassyRejectionLetterTitle: '',
    familyRegistrationCerificateTitle: '',
    idCardTitle: '',
    idCardDesc: '',
    inpatientMedicalCerificateTitle: '',
    medicalCertificateTitle: '',
    otherMedicalDocumentTitle: '',
    outpatientInpatientMedicalCertificateTitle: '',
    outpatientMedicalCertificateTitle: '',
    pharmacyInvoiceTitle: '',
    ultrasoundResultTitle: '',
    forceMajeureReportTitle: '',
    providerApprovalLetterTitle: '',
    passportImageTitle: '',
    preflightInfoProofTitle: '',
    idCardPassportBankAccountTitle: '',
    idCardPassportTravelokaAccountTitle: '',
    transferReceiptTitle: '',
    selfieImageTitle: '',
    passbookImageTitle: '',
    statementLetterTitle: '',
  },
  GeneralRefundSubmissionFlow_Flight: {} as Record<string, string>,
  CorporateFlightRequestRefundPolicy: {
    backButtonText: '',
    refundPolicyHeadline: '',
    tncText: '',
    continueButtonText: '',
    policyTitle: '',
    generalRefundPolicyTitle: '',
    generalRefundPolicyContent: '',
    selectMainReasonTitle: '',
    dropdownMainReasonTitle: '',
    emptyMainReasonText: '',
    mainReasonDescription: '',
    requiredDocumentTitle: '',
    requiredDocumentContent: '',
    refundableAmountTitle: '',
    refundableAmountContent: '',
  },
  CorporateFlightRequestRefundSelectDetails: {
    backButtonText: '',
    refundFormHeadline: '',
    partialJourneyInfoText: '',
    flightToRefundTitle: '',
    selectMainReasonTitle: '',
    emptySelectSecondaryReason: '',
    passengerTitle: '',
    continueButtonText: '',
    mainReasonTitle: '',
    mainReasonDesc: '',
    shouldRefundAllPaxInfoText: '',
    passengerRefundedItemInfoText: '',
    passengerRefundedTooltipContentText: '',
  },
  CorporateFlightRequestRefundDocument: {
    backButtonText: '',
    documentHeadline: '',
    documentProceedInfoText: '',
    continueButtonText: '',
    refundReasonText: '',
    identicalTripErrorMessage: '',
    pnrCodeErrorMessage: '',
    selectUploadFileText: '',
    uploadingText: '',
    deleteFileText: '',
    documentErrorMessage: '',
  },
  CorporateFlightRequestRefundReview: {
    backButtonText: '',
    reviewHeadline: '',
    refundItemTitle: '',
    passengerTitle: '',
    refundableAmountTitle: '',
    refundableAmountContent: '',
    submitButtonText: '',
    refundReasonText: '',
    documentUnit: '',
    partiallyRefundTitle: '',
    successRefundedTitle: '',
    failedRefundedTitle: '',
    partialRefundRedirectButtonText: '',
  },
  CorporateHotelRequestRefundPolicy: {
    backButtonText: '',
    refundPolicyHeadline: '',
    tncText: '',
    continueButtonText: '',
    policyTitle: '',
    generalRefundPolicyTitle: '',
    generalRefundPolicyContent: '',
    selectMainReasonTitle: '',
    dropdownMainReasonTitle: '',
    emptyMainReasonText: '',
    mainReasonDescription: '',
    refundableAmountContent: '',
    hoursLeftText: '',
    beforeDateText: '',
    adultText: '',
  },
  CorporateHotelRequestRefundNonRefundableModal: {
    title: '',
    body: '',
    redirectButtonText: '',
  },
  CorporateHotelRequestRefundSelectDetails: {
    backButtonText: '',
    refundFormHeadline: '',
    selectMainReasonTitle: '',
    guestTitle: '',
    guestAge: '',
    continueButtonText: '',
    hotelDetailDateInfo: '',
    mainReasonTitle: '',
    mainReasonDesc: '',
    identicalTripTitle: '',
    inputIdenticalTripPlaceholder: '',
    inputIdenticalTripDescription: '',
    identicalTripInvalidTripIdMessage: '',
  },
  CorporateHotelRequestRefundReview: {
    backButtonText: '',
    reviewHeadline: '',
    refundItemTitle: '',
    refundReason: '',
    passengerTitle: '',
    refundableAmountTitle: '',
    refundableAmountContent: '',
    submitButtonText: '',
    identicalTripText: '',
  },
  CorporateProductSearchForm: {
    emptyActiveTripRequestApproval: '',
    allRequestApprovalButtonText: '',
    createTripRequestButtonText: '',
    filterText: '',
    divisionPlaceholderText: '',
    employeeSearchPlaceholder: '',
    allEmployee: '',
    recentlySelected: '',
    emptyEmployeeList: '',
    addNewEmployeeItem: '',
    nonEmployeeAdultUnit: '',
    nonEmployeeChildUnit: '',
    nonEmployeeInfantUnit: '',
    nonEmployeeShowButton: '',
    nonEmployeeHideButton: '',
    noEmployeePermission: '',
    childAgePlaceholder: '',
    adultText: '',
    childrenText: '',
    childrenDescription: '',
    infantText: '',
    infantDescription: '',
    cancelButtonText: '',
    submitButtinText: '',
  },
  CorporateFlightSearchForm: {
    menuText: '',
    approvalSystemDiscoverText: '',
    approvalSystemBookProductText: '',
    passengerUnit: '',
    searchTripButtonText: '',
    airportOptionAllAirportText: '',
    emptyPassengerText: '',
    pluralPassengerUnit: '',
    canBookAllText: '',
    canBookForOthersText: '',
    passengerLabel: '',
    originLabel: '',
    destinationLabel: '',
    departureDateLabel: '',
    returnDateLabel: '',
    seatClassLabel: '',
    searchButtonText: '',
    destinationTooltipText: '',
    passengerTooltipText: '',
    travelerAddedLabel: '',
    travelerAddNewLabel: '',
    travelerAllLabel: '',
    travelerComplianceLabel: '',
    travelerEmptyLabel: '',
    travelerPlaceholderLabel: '',
    travelerSaveLabel: '',
    travelerSubtitleLabel: '',
    travelerTitleLabel: '',
    nonEmployeeAdultUnit: '',
    nonEmployeeChildUnit: '',
    nonEmployeeInfantUnit: '',
    emptySelectedTravelerText: '',
    selectedTravelerText: '',
    emptyTotalTravelerText: '',
    totalTravelerText: '',
    nonEmployeeEnablementCheckbox: '',
    nonEmployeeTooltip: '',
    nonEmployeeSubtitle: '',
    nonEmployeeTooltipNoAdult: '',
    nonEmployeeTooltipLessAdult: '',
    errorTooltipMaxTraveler: '',
    errorTooltipMaxInfant: '',
  },
  CorporateFlightRecentlySearch: {
    recentlySearchText: '',
    frequentlyBookedText: '',
  },
  CorporateHotelSearchForm: {
    menuText: '',
    approvalSystemDiscoverText: '',
    approvalSystemBookProductText: '',
    dateInfo: '',
    bookInfo: '',
    searchTripButtonText: '',
    numOfNightInfoText: '',
    errorTooltipMaxRoom: '',
    errorTooltipRoomExceedGuest: '',
    emptyGuestText: '',
    pluralGuest: '',
    canBookAllText: '',
    canBookForOthers: '',
    roomInfoText: '',
    travelerLabel: '',
    roomLabel: '',
    destinationLabel: '',
    destinationPlaceholder: '',
    destinationNumOfHotels: '',
    destinationTypeRegion: '',
    destinationTypeCity: '',
    destinationTypeArea: '',
    destinationTypeHotel: '',
    destinationTypeLandmark: '',
    destinationTypeMore: '',
    popularDestinationText: '',
    destinationEmptyTitle: '',
    destinationEmptyDescription: '',
    checkInLabel: '',
    checkOutLabel: '',
    durationLabel: '',
    errorTooltipNullGuest: '',
    errorTooltipNullDestination: '',
    searchButtonText: '',
    travelerAddNewLabel: '',
    travelerAddedLabel: '',
    travelerAllLabel: '',
    travelerComplianceLabel: '',
    travelerEmptyLabel: '',
    travelerPlaceholderLabel: '',
    travelerSaveLabel: '',
    travelerSubtitleLabel: '',
    travelerTitleLabel: '',
    nonEmployeeEnablementCheckbox: '',
    nonEmployeeTooltip: '',
    nonEmployeeSubtitle: '',
    nonEmployeeTooltipMaxChildren: '',
    nonEmployeeTooltipNoAdult: '',
    nonEmployeeChildAgeLabel: '',
    nonEmployeeTooltipAutoFillChildAge: '',
    emptySelectedTravelerText: '',
    selectedTravelerText: '',
    emptyTotalTravelerText: '',
    totalTravelerText: '',
    errorTooltipMaxTraveler: '',
  },
  CorporateHotelRecentlySearch: {
    recentlyViewedHotelText: '',
    recentlyViewedHotelWithKeywordText: '',
    recentlySearchCityText: '',
    startFromText: '',
  },
  CorporateCompanyStructure: {
    tabTitle: '',
    howToSetCompanyText: '',
    corporateInfoHeaderText: '',
    editInfoButtonText: '',
    addressLabelText: '',
    billingAddressLabelText: '',
    taxRegistrationNoLabelText: '',
    phoneLabelText: '',
    picEmailLabelText: '',
    creditCard: '',
    numOfCardRegistered: '',
    paymentMethod: '',
    manageCards: '',
    billingInfoTitle: '',
    loadingTitle: '',
    leavePromptText: '',
  },
  CorporateCompanyStructureEditInfoModal: {
    title: '',
    corporateNameField: '',
    corporateNameRequiredErrorMessage: '',
    corporateNameMaxLengthErrorMessage: '',
    corporateNameInvalidSymbolErrorMessage: '',
    addressField: '',
    addressRequiredErrorMessage: '',
    addressMaxLengthErrorMessage: '',
    addressInvalidCharacterErrorMessage: '',
    phoneField: '',
    phoneRequiredErrorMessage: '',
    phoneMinLengthErrorMessage: '',
    phoneMaxLengthErrorMessage: '',
    phoneNumericErrorMessage: '',
    closeButtonText: '',
    submitButtonText: '',
  },
  CorporateCompanyStructureDivision: {
    title: '',
    subtitle: '',
    insertButtonText: '',
    emptyDivisionText: '',
    divisionNameHeaderText: '',
    divisionHeadHeaderText: '',
    editButtonText: '',
    deleteButtonText: '',
    showAllDataText: '',
  },
  CorporateCompanyStructureDivisionUpsertModal: {
    addNewEmployeeItemText: '',
    addTitle: '',
    editTitle: '',
    divisionNameField: '',
    divisionNameRequiredErrorMessage: '',
    divisionNameMaxLengthErrorMessage: '',
    divisionNameAlphabethErrorMessage: '',
    divisionHeadField: '',
    divisionHeadRequiredErrorMessage: '',
  },
  CorporateCompanyStructureDivisionDeleteAndMoveModal: {
    addNewDropdownItem: '',
    titleText: '',
    info1RegularText: '',
    info2BoldText: '',
    info3RegularText: '',
    info4BoldText: '',
    info5RegularText: '',
    entryFieldLabel: '',
    newEntryFieldLabel: '',
    divisionHeadField: '',
    divisionHeadRequiredErrorMessage: '',
  },
  CorporateCompanyStructureDivisionDeleteModal: {
    title: '',
    content: '',
    submitButtonText: '',
    closeButtonText: '',
  },
  CorporateCompanyStructureGeneralUpsertModal: {
    closeButtonText: '',
    submitButtonText: '',
  },
  CorporateCompanyStructureGeneralDeleteAndMoveModal: {
    actionLabelText: '',
    submitButtonText: '',
    clobeButtonText: '',
    fieldRequiredErrorMessage: '',
  },
  CorporateCompanyStructureTier: {
    title: '',
    subtitle: '',
    insertButtonText: '',
    emptyTierText: '',
    editButtonText: '',
    deleteButtonText: '',
    showAllDataText: '',
  },
  CorporateCompanyStructureTierUpsertModal: {
    addTitle: '',
    editTitle: '',
    tierNameField: '',
    tierRequiredErrorMessage: '',
    tierMaxLengthErrorMessage: '',
    tierAlphaNumericErrorMessage: '',
  },
  CorporateCompanyStructureTierDeleteModal: {
    title: '',
    content: '',
    submitButtonText: '',
    closeButtonText: '',
  },
  CorporateCompanyStructureTierDeleteAndMoveModal: {
    addNewDropdownItem: '',
    titleText: '',
    info1RegularText: '',
    info2BoldText: '',
    info3RegularText: '',
    info4BoldText: '',
    info5RegularText: '',
    entryFieldLabel: '',
    newEntryFieldLabel: '',
  },
  CorporateCompanyStructureUserAccessRole: {
    tabTitle: '',
    title: '',
    subtitle: '',
    insertButtonText: '',
    emptyAccessRoleText: '',
    viewButtonText: '',
    editButtonText: '',
    deleteButtonText: '',
    showAllDataText: '',
  },
  CorporateCompanyStructureUserAccessRoleViewModal: {
    title: '',
    createButtonText: '',
    readButtonText: '',
    updateButtonText: '',
    deleteButtonText: '',
    manageButtonText: '',
  },
  CorporateCompanyStructureUserAccessRoleDeleteModal: {
    title: '',
    content: '',
    submitButtonText: '',
    closeButtonText: '',
  },
  CorporateCompanyStructureUserAccessRoleDeleteAndMoveModal: {
    addNewDropdownItem: '',
    titleText: '',
    info1RegularText: '',
    info2BoldText: '',
    info3RegularText: '',
    info4BoldText: '',
    info5RegularText: '',
    entryFieldLabel: '',
    newEntryFieldLabel: '',
  },
  CorporateCompanyStructureUserAccessRoleForm: {
    backText: '',
    addTitle: '',
    editTitle: '',
    accessRoleNameField: '',
    accessRolePlaceholder: '',
    accessRoleRequiredErrorMessage: '',
    accessRoleMinLengthErrorMessage: '',
    accessRoleMaxLengthErrorMessage: '',
    accessRoleAlphabetErrorMessage: '',
    cancelButtonText: '',
    submitButtonText: '',
  },
  CorporateCompanyStructurePolicy: {
    tabTitle: '',
    title: '',
    insertButtonText: '',
    emptyPolicyText: '',
    viewButtonText: '',
    editButtonText: '',
    deleteButtonText: '',
    showAllDataText: '',
    successMessage: '',
  },
  CorporateCompanyStructurePolicyViewModal: {
    title: '',
    unsetPolicyText: '',
    userClassificationTitle: '',
    tierLabelText: '',
    flightTitle: '',
    excludedAirlinesLabelText: '',
    includedSeatclassLabelText: '',
    hotelTitle: '',
    starRatingLabelText: '',
    defaultBudgetText: '',
    allLocationText: '',
    specificLocationText: '',
  },
  CorporateCompanyStructurePolicyDeleteModal: {
    title: '',
    content: '',
    submitButtonText: '',
    closeButtonText: '',
  },
  CorporateCompanyStructurePolicyForm: {
    backText: '',
    addTitle: '',
    editTitle: '',
    policyTitleField: '',
    policyTitlePlaceholder: '',
    policyTitleRequiredErrorMessage: '',
    policyTitleMinLengthErrorMessage: '',
    policyTitleMaxLengthErrorMessage: '',
    policyTitleAlphabetErrorMessage: '',
    corporateStructureTitle: '',
    selectTierText: '',
    flightCheckboxText: '',
    excludedAirlineText: '',
    seatClassText: '',
    hotelText: '',
    starRatingText: '',
    domesticBudgetField: '',
    domesticBudgetRequiredErrorMessage: '',
    domesticBudgetNumericErrorMessage: '',
    domesticBudgetMaxAmountErrorMessage: '',
    internationalBudgetField: '',
    internationalBudgetRequiredErrorMessage: '',
    internationalBudgetNumericErrorMessage: '',
    internationalBudgetMaxAmountErrorMessage: '',
    cancelButtonText: '',
    submitButtonText: '',
    defaultBudgetText: '',
    defaultBudgetInfoText: '',
    locationText: '',
    defaultLocationText: '',
    budgetPerNightText: '',
    specificBudgetText: '',
    specificBudgetInfoText: '',
    searchLocationPlaceholder: '',
    emptyLocationTitle: '',
    emptyLocationInfo: '',
    locationNotFoundTitle: '',
    locationNotFoundInfo: '',
    addNewLocation: '',
    showAllText: '',
    showLessText: '',
  },
  CorporateCompanyStructurePolicyLocationAddModal: {
    modalTitle: '',
    modalInfo: '',
    locationPlaceholder: '',
    selectedLocationText: '',
    cancelButton: '',
    saveButton: '',
    COUNTRY: '',
    PROVINCE: '',
    CITY: '',
  },
  CorporateCompanyStructurePolicyLeaveModal: {
    modalTitle: '',
    modalInfo: '',
    exitButton: '',
    cancelButton: '',
  },
  CorporateCompanyExpenseReport: {
    failedFetchMessage: '',
    failedFetchButton: '',
    downloadTransaction: '',
    downloadTransactionDesc: '',
    downloadTransaction1Label: '',
    downloadTransaction1Value: '',
    downloadTransaction2Label: '',
    downloadTransaction2Value: '',
    downloadTransaction3Label: '',
    downloadTransaction3Value: '',
    transactionPeriod: '',
    downloadButton: '',
    pageTitle: '',
    periodLabel: '',
    periodCurrentMonth: '',
    periodLastMonth: '',
    periodCustomizeDate: '',
    periodCustomizeDateFrom: '',
    periodCustomizeDateTo: '',
    filterLabel: '',
    filterCardTitle: '',
    filterItemTransaction: '',
    filterItemBooking: '',
    filterDisplayItemTransaction: '',
    filterDisplayItemBooking: '',
    spendingPerDivision: '',
    NO_DIVISION: '',
    division: '',
    totalTransaction: '',
    totalBooking: '',
    bookingUnit: '',
    spendingPerProduct: '',
    FLIGHT: '',
    HOTEL: '',
    productType: '',
    detailButton: '',
    closeButton: '',
    flightSpending: '',
    hotelSpending: '',
    spendingPerAirline: '',
    airlineName: '',
    spendingPerFlightClass: '',
    totalUniquePassenger: '',
    passengerUnit: '',
    top3Route: '',
    spendingPerStarRating: '',
    starUnit: '',
    totalUniqueGuest: '',
    guestUnit: '',
    topCitiesDestination: '',
    totalTripRequest: '',
    totalApprovedRequest: '',
    totalUniqueTraveler: '',
    totalFlight: '',
    tripUnit: '',
    requestUnit: '',
    travelerUnit: '',
    flightUnit: '',
    noTripRequest: '',
    noRequest: '',
    noData: '',
    totalTransactionSubtitle: '',
    totalBookingSubtitle: '',
    complianceRate: '',
    complianceRateSubtitle: '',
    bookingRate: '',
    noBookingYet: '',
    noTransactionYet: '',
    noChart: '',
    customPeriodRangeInfo: '',
  },
  CorporateEnumSeatClass: {
    ECONOMY: '',
    PROMO: '',
    PREMIUM_ECONOMY: '',
    FIRST: '',
    BUSINESS: '',
    OTHERS: '',
    MIXED: '',
    ALL: '',
  },
  CorporateProfileSidebar: {
    title: '',
    editProfileText: '',
    employmentDetailText: '',
  },
  CorporateEditProfile: {
    title: '',
    changePasswordMessage: '',
    emailSentMessage: '',
    divisionText: '',
    tierText: '',
    emailTitleField: '',
    phoneTitleField: '',
    phoneText: '',
    phoneMinLengthErrorMessage: '',
    phoneMaxLengthErrorMessage: '',
    phoneNumericErrorMessage: '',
    nationalityTitleField: '',
    nationalityText: '',
    passwordTitleField: '',
    identityNumberTitleField: '',
    identityNumberText: '',
    identityNumericErrorMessage: '',
    passportTitleField: '',
    passportText: '',
    passportAlphanumericErrorMessage: '',
    passportMinLengthErrorMessage: '',
    passportMaxLengthErrorMessage: '',
    passportExpiryDateText: '',
    passportExpiryDateNotValidErrorMessage: '',
    passportExpiryDateEarliestDateErrorMessage: '',
    passportCountryText: '',
    submitButtonText: '',
  },
  CorporateEmploymentDetail: {
    title: '',
    companyTitle: '',
    addressText: '',
    phoneText: '',
    divisionText: '',
    tierText: '',
    roleText: '',
    managerText: '',
    approverRoleText: '',
    approverLabelText: '',
    employmentTitle: '',
    policyTitle: '',
    policyUserClassificationText: '',
    policyTierText: '',
    policyFlightText: '',
    policyExcludedAirlineText: '',
    policyIncludedSeatclassText: '',
    policyHotelText: '',
    policyStarRatingText: '',
    policyDefaultBudgetText: '',
    policyAllLocationText: '',
    policySpecificLocationText: '',
  },
  CorporateProductPayment: {
    paymentStatusBookingExpired: '',
    paymentStatusBookingNotFound: '',
    paymentStatusAlreadyConfirmed: '',
    paymentStatusFailed: '',
    pageHeadline: '',
    expirationTime: '',
    priceBreakdownTitle: '',
    totalPriceText: '',
    agreementInfoText: '',
    agreementText: '',
    agreementPolicySeparatorText: '',
    policyText: '',
    submitButtonText: '',
    loadingText: '',
    payWithCreditCard: '',
    noCardAvailable: '',
    noCardAvailableHelp: '',
    seePurchaseDetail: '',
    noCardActionAdmin: '',
    manageCard: '',
    noCardActionUser: '',
  },
  CorporateProductPaymentSideMenu: {
    title: '',
    headerMenu: '',
    invoiceMenu: '',
    creditCardMenu: '',
  },
  CorporateProductPaymentExpiredModal: {
    title: '',
    body: '',
    closeButtonText: '',
  },
  CorporateProductPaymentInvoiceContent: {
    title: '',
    corporateNameText: '',
    creditLimitStatusText: '',
  },
  CorporateProductPaymentConfirmationModal: {
    headline: '',
    infoText: '',
    confirmationField: '',
    confirmationFieldPlaceholder: '',
    closeButtonText: '',
    submitButtonText: '',
  },
  CorporateProductPaymentDetailCard: {
    title: '',
    tripDetailText: '',
    detailButtonText: '',
  },
  CorporateProductPaymentConfirmed: {
    successText: '',
    infoText: '',
    goToPurchaseDetailButtonText: '',
  },
  CorporateProductPaymentNotAuthorized: {
    message: '',
    goToHomepageButtonText: '',
  },
  CorporateFlightPayment: {
    breakdownAdultFareLabel: '',
    breakdownChildFareLabel: '',
    breakdownInfantFareLabel: '',
    breakdownBaggageLabel: '',
    breakdownValueFree: '',
    breakdownServiceFeeLabel: '',
    breakdownVatFeeLabel: '',
    productNameText: '',
    travelersLabelText: '',
    duration: '',
    directText: '',
    transitText: '',
    adultAgeText: '',
    childAgeText: '',
    infantAgeText: '',
  },
  CorporateFlightPaymentDetailModal: {
    flightTab: '',
    travelerDetailsTab: '',
    headlineText: '',
    tripIdText: '',
    idCardText: '',
    childAgeText: '',
    infantAgeText: '',
  },
  CorporateHotelPayment: {
    roomContent: '',
    durationContent: '',
    travelersLabelText: '',
    nonEmployeeText: '',
    nonEmployeeAgeText: '',
    breakdownServiceFeeLabel: '',
    breakdownVatFeeLabel: '',
  },
  CorporateHotelPrebook: {
    step1: '',
    step2: '',
    step1Approval: '',
    step2Approval: '',
    loadingText: '',
    notSuccessButtonText: '',
    notSuccessText: '',
    noRoomButtonText: '',
    noRoomText: '',
    mismatchGuestCountButtonText: '',
    mismatchGuestCountText: '',
    title: '',
    subtitle: '',
    submitPrebookButtonText: '',
    createPaymentApprovalButtonText: '',
  },
  CorporateHotelPrebookSpecialRequest: {
    title: '',
    required: '',
    requestInfo: '',
    hour: '',
    minute: '',
  },
  CorporateHotelPrebookSpecialRequestOption: {},
  CorporateHotelPrebookContactForm: {
    title: '',
    phoneField: '',
    phoneFieldDescription: '',
    phoneRequiredErrorMessage: '',
    phoneMinLengthErrorMessage: '',
    phoneMaxLengthErrorMessage: '',
    phoneNumericOnlyErrorMessage: '',
    emailField: '',
  },
  CorporateHotelPrebookTravelerForm: {
    title: '',
    travelerFullNameText: '',
  },
  CorporateHotelPrebookNonEmployeeTravelerForm: {
    title: '',
    titleField: '',
    titleRequiredErrorMessage: '',
    fullNameField: '',
    fullNameRequiredErrorMessage: '',
    fullNameInvalidCharacterErrorMessage: '',
    emailField: '',
    emailSubtitle: '',
    emailFormatErrorMessage: '',
    adultUnit: '',
    childUnit: '',
  },
  CorporateHotelPrebookCancellationPolicy: {
    title: '',
  },
  CorporateHotelPrebookPriceBreakdown: {
    title: '',
    duration: '',
    totalPriceText: '',
    serviceFeeText: '',
    vatFeeText: '',
  },
  CorporateHotelPrebookAdditionalInfo: {
    attachmentInfo: '',
    attachmentInfoBoxContent: '',
    attachmentInfoBoxTitle: '',
    notesField: '',
    notesFieldDescription: '',
    notesPlaceholder: '',
    notesRequiredErrorMessage: '',
    title: '',
    uploadAttachmentButtonText: '',
    uploadAttachmentRetryButtonText: '',
    uploadAttachmentField: '',
  },
  CorporateHotelPrebookApprovalSystem: {
    approvalReasonPlaceholder: '',
    approvalReasonRequiredErrorMessage: '',
    approverNameLabel: '',
    paymentApprovalBookingAttachmentButtonText: '',
    paymentApprovalBookingAttachmentRetryButtonText: '',
    paymentApprovalBookingAttachmentInfo: '',
    paymentApprovalBookingAttachmentLabel: '',
    paymentApprovalBookingAttachmentPlaceholder: '',
    paymentApprovalBookingAttachmentTooltip: '',
    paymentApprovalBookingNotesLabel: '',
    paymentApprovalInfoBoxContent: '',
    paymentApprovalInfoBoxTitle: '',
    title: '',
    tripInfo: '',
    tripNameLabel: '',
  },
  CorporateHotelPrebookProductSummary: {
    roomContent: '',
    guestContent: '',
    durationContent: '',
    freeCancellationText: '',
    hasCancellationPolicyText: '',
    freeBreakfastText: '',
    freeWifiText: '',
    nonCompliantText: '',
    durationText: '',
    checkInText: '',
    checkOutText: '',
    roomTypeText: '',
    roomNameText: '',
    numOfRoomsText: '',
    guestPerRoomText: '',
    bedTypeText: '',
  },
  CorporateHotelPrebookPriceChangeModal: {
    submitButtonText: '',
    navigateToRoomButtonText: '',
    closeButtonText: '',
    title: '',
    totalPriceUpdateText: '',
    toText: '',
    totalPriceUpdateText2: '',
  },
  CorporateHotelPrebookReviewModal: {
    roomContent: '',
    guestContent: '',
    durationContent: '',
    travelerContent: '',
    title: '',
    hotelDetailHeader: '',
    nonCompliantText: '',
    checkInText: '',
    checkOutText: '',
    durationText: '',
    roomDetailHeader: '',
    roomTypeText: '',
    roomNameText: '',
    numOfRooms: '',
    guestPerRoom: '',
    totalPriceText: '',
    infoText: '',
    closeButtonText: '',
    submitButtonText: '',
  },
  CorporateHotelPrebookComplianceModal: {
    title: '',
    infoText: '',
    reasonFieldPlaceholder: '',
    reasonRequiredErrorMessage: '',
    closeButtonText: '',
    submitButtonText: '',
  },
  CorporateHotelPrebookAvailabilityModal: {
    title: '',
    infoText: '',
    closeButtonText: '',
  },
  CorporateHotelPrebookGeneralErrorModal: {
    title: '',
    closeButtonText: '',
  },
  CorporateHotelSearch: {
    propertyAllInclusiveLabel: '',
    propertyApartmentLabel: '',
    propertyBedAndBreakfastLabel: '',
    propertyBoatLabel: '',
    propertyCampingLabel: '',
    propertyGuesthouseLabel: '',
    propertyHomestayLabel: '',
    propertyHostelLabel: '',
    propertyHotelLabel: '',
    propertyOtherLabel: '',
    propertyPousadaLabel: '',
    propertyResortLabel: '',
    propertyRiadLabel: '',
    propertyRyokanLabel: '',
    propertyVillaLabel: '',
    durationInfoText: '',
    loadingHeaderText: '',
    loadingDescriptionText: '',
    emptyResultWithFilter: '',
    emptyResultNoFilter: '',
    emptyResultButton: '',
    breakdownIncludingTaxText: '',
    breakdownTaxAndOtherFeeText: '',
    breakdownTravelokaFee: '',
    breakdownValueFree: '',
    noncompliantLabelText: '',
    priceBreakdownToggleText: '',
    totalPaymentText: '',
  },
  CorporateHotelSearchSort: {
    highPriceText: '',
    lowestPriceText: '',
    reviewScoreText: '',
    populatiryText: '',
    sortTitle: '',
    sortInfoText: '',
  },
  CorporateHotelSearchSearchBar: {
    priceDisplayNightlyText: '',
    priceDisplayTotalText: '',
    omniSearchPlaceholder: '',
  },
  CorporateHotelSearchFilter: {
    filterTitle: '',
    filterInfoText: '',
    priceFilterTitle: '',
    starFilterTitle: '',
    typeFilterTitle: '',
    typeHotelText: '',
    typeHostelText: '',
    typeVillaText: '',
    typeResortText: '',
    typeApartmentText: '',
    typeBedAndBreakfastText: '',
    typeCampingText: '',
    typeHomestayText: '',
    typeGuesthouseText: '',
    typeOtherText: '',
  },
  CorporateHotelSearchHeader: {
    paxInfoText: '',
    roomInfoText: '',
    searchHeaderTitle: '',
    changeSearchButtonText: '',
  },
  CorporateHotelStarRating: {
    superbRatingText: '',
    impressiveRatingText: '',
    convenientRatingText: '',
    goodRatingText: '',
    acceptableRatingText: '',
  },
  CorporateHotelCleanStay: {
    title: '',
    subtitle: '',
    learnMoreText: '',
  },
  CorporateHotelDetail: {
    loadingText: '',
    notAvailableText: '',
    goBackToHomeButtonText: '',
    changeSearchButtonText: '',
  },
  CorporateHotelDetailOverview: {
    propertyAllInclusiveLabel: '',
    propertyApartmentLabel: '',
    propertyBedAndBreakfastLabel: '',
    propertyBoatLabel: '',
    propertyCampingLabel: '',
    propertyGuesthouseLabel: '',
    propertyHomestayLabel: '',
    propertyHostelLabel: '',
    propertyHotelLabel: '',
    propertyOtherLabel: '',
    propertyPousadaLabel: '',
    propertyResortLabel: '',
    propertyRiadLabel: '',
    propertyRyokanLabel: '',
    propertyVillaLabel: '',
    noImageSlideShowText: '',
    showAllImageText: '',
    scoreTitle: '',
    facilityTitle: '',
    cheapestPriceLabel: '',
    selectCheapestRoomButtonText: '',
  },
  CorporateHotelDetailRoomSearch: {
    roomSearchHideButtonText: '',
    roomSearchShowButtonText: '',
    title: '',
    summaryContent: '',
    summaryContentV2: '',
    checkboxFreeBreakfastText: '',
    checkboxFreeCancellationText: '',
    roomPerNightText: '',
    totalPriceText: '',
    priceDisplayTitle: '',
  },
  CorporateHotelDetailRoomSearchForm: {
    roomContent: '',
    guestContent: '',
    adultContent: '',
    childContent: '',
    durationContent: '',
    checkInText: '',
    durationText: '',
    guestText: '',
    roomText: '',
    guestAddedText: '',
    insertGuestButtonText: '',
    allGuestInfoText: '',
    complianceInfoText: '',
    emptyGuestText: '',
    searchGuestPlaceholder: '',
    submitGuestButtonText: '',
    travelerPickerSubtitle: '',
    travelerPickerTitle: '',
  },
  CorporateHotelDetailRoomList: {
    emptyResultWithFilter: '',
    emptyResultNoFilter: '',
    emptyResultButtonText: '',
    emptyResultTitle: '',
    durationLabel: '',
    guestContent: '',
    roomRemainingContent: '',
    roomNightContent: '',
    breakdownTitleContent: '',
    breakdownIncludingTaxText: '',
    breakdownTaxAndOtherFeeText: '',
    breakdownTravelokaFeeText: '',
    breakdownTotalPaymentText: '',
    freeWifiText: '',
    noWifiText: '',
    freeBreakfastText: '',
    noBreakfastText: '',
    freeCancellationText: '',
    refundableRoomText: '',
    nonRefundableRoomText: '',
    bookButtonText: '',
    nonCompliantText: '',
    readPolicyText: '',
    roomDetailButtonText: '',
    inclusiveTaxText: '',
    bathroomAmenitiesText: '',
    roomFacilityText: '',
    roomInfoText: '',
    roomPriceUnit: '',
    seeOptionButtonText: '',
    startingFromText: '',
    noPhotoText: '',
  },
  CorporateHotelDetailAdditionalInfo: {
    checkInInfoTitle: '',
    checkInReadLessText: '',
    checkInReadMoreText: '',
    policyInfoTitle: '',
    policyReadLessText: '',
    policyReadMoreText: '',
    faiclityTitle: '',
  },
  CorporateEnumTravelerTitle: {
    MR: '',
    MRS: '',
    MISS: '',
  },
  CorporatePurchaseList: {
    title: '',
    myBookingInfoText: '',
    myBookingLinkText: '',
    emptyPurchaseListTitle: '',
    emptyPurchaseListText: '',
    detailButtonText: '',
    purchaseDateText: '',
    tripIdText: '',
  },
  CorporateCompanyFilterBar: {
    allEmployeeItemLabel: '',
    allDivisionsItemLabel: '',
    allProductTypeItemLabel: '',
    flightProductTypeItemLabel: '',
    hotelProductTypeItemLabel: '',
    filterText: '',
    customizeDateButtonText: '',
    dropdownFilterButtonText: '',
    startDateText: '',
    endDateText: '',
    divisionText: '',
    divisionFilterPlaceholder: '',
    productTypeText: '',
    productTypeFilterPlaceholder: '',
    employeeText: '',
    employeeFilterPlaceholder: '',
  },
  CorporateBookingList: {
    title: '',
    purchaseListText: '',
    purchaseListInfoText: '',
    purchaseListLinkText: '',
    emptyBookingListTitle: '',
    emptyBookingListText: '',
    tripIdText: '',
    flightScheduleText: '',
    flightDepartureAirport: '',
    flightActionButtonText: '',
    hotelCheckInText: '',
    hotelCheckOutText: '',
    hotelActionButtonText: '',
    refundProcessStatusText: '',
    refundProcessActionText: '',
  },
  CorporatePurchaseDetail: {
    attachmentText: '',
    bookingNotesText: '',
    contactTravelokaText: '',
    contactUsInfoText: '',
    downloadButtonText: '',
    goBackButtonText: '',
    pageTitle: '',
    paymentMethodText: '',
    productDetailsText: '',
    purchasedOnText: '',
    title: '',
    totalText: '',
    viewButtonText: '',
    nonComplyText: '',
    nonComplyNotesText: '',
    mainTravelerText: '',
  },
  CorporateFlightBookingDetail: {
    urlNotAvailableErrorMessage: '',
    goBackButtonText: '',
  },
  CorporateFlightBookingDetailProduct: {
    durationDay: '',
    durationHour: '',
    durationMinute: '',
    additionalTransitInfoText: '',
    shouldChangeAirportInfoText: '',
    title: '',
    pnrCodeText: '',
    airlinePnrCodeText: '',
    departureText: '',
    arrivalText: '',
    departureAirportText: '',
    arrivalAirportText: '',
    preTripRescheduledByAirlineText: '',
    preTripInvalidRescheduleByAirlineText: '',
    preTripInvalidRescheduleByAirlineLinkText: '',
    preTripCancelledByAirlineText: '',
  },
  CorporateFlightBookingDetailTraveler: {
    title: '',
    baggageText: '',
    adultText: '',
    childText: '',
    infantText: '',
  },
  CorporateHotelBookingDetail: {
    urlNotAvailableErrorMessage: '',
    goBackButtonText: '',
    guestAge: '',
  },
  CorporateHotelBookingDetailProduct: {
    checkInValueText: '',
    checkOutValueText: '',
    numOfNightText: '',
    roomNameText: '',
    bedTypeText: '',
    checkInText: '',
    checkOutText: '',
    durationText: '',
    roomTypeText: '',
    specialRequestText: '',
    title: '',
  },
  CorporateHotelBookingDetailTraveler: {
    title: '',
    hotelMainReasonText: '',
    identicalTripText: '',
    nonEmployeeText: '',
    age: '',
  },
  CorporateProductBookingDetailOrder: {
    title: '',
    bookedByText: '',
    bookingDateText: '',
    bookingIdText: '',
    paymentMethodText: '',
    paymentStatusText: '',
    downloadVoucherButtonText: '',
    resendVoucherButtonText: '',
    resendVoucherSuccessInfoText: '',
  },
  CorporateProductBookingDetailRefund: {
    title: '',
    requestRefundButtonText: '',
    flightTravelerText: '',
    hotelTravelerText: '',
    emptyRefundDetailTitle: '',
    emptyRefundDetailInfoText: '',
    refundSubmittedProgressText: '',
    refundProcessedProgressText: '',
    confirmationRespondsProgressText: '',
    refundResolvedProgressText: '',
    overflowTravelerText: '',
    amountText: '',
    progressText: '',
    requestedOnText: '',
    detailsText: '',
    hideDetailsText: '',
    reasonLabel: '',
    childAgeText: '',
    infantAgeText: '',
  },
  CorporateBookingStatusTextSwitcher: {
    WAITING_FOR_PAYMENT: '',
    WAITING_FOR_ISSUANCE: '',
    ISSUED: '',
    PAYMENT_EXPIRED: '',
    CANCELLED: '',
    ISSUANCE_FAILED: '',
    BOOKING_NOT_FOUND: '',
  },
  CorporatePurchaseStatusTextSwitcher: {
    WAITING_FOR_PAYMENT: '',
    PAID: '',
    PURCHASE_SUCCESSFUL: '',
    PAYMENT_EXPIRED: '',
    PURCHASE_FAILED: '',
    PURCHASE_CANCELED: '',
  },
  CorporateActionTextSwitcher: {
    WAITING_FOR_PAYMENT: '',
    ISSUED: '',
  },
  CorporatePaymentMethodTextSwitcher: {
    CREDIT_CARD: '',
    INVOICE: '',
  },
  CorporateRefundStatusTextSwitcher: {
    REFUND_SUBMITTED: '',
    REFUND_PROCESSED: '',
    REFUND_APPROVED: '',
    REFUND_FAILED: '',
    REFUND_CANCELLED: '',
    REFUND_CLOSED: '',
  },
  CorporateEmployeeList: {
    loadingText: '',
    title: '',
    insertButtonText: '',
    bulkUploadButtonText: '',
    actionSeparatorText: '',
    deletedEmployeeUnitInfoText: '',
    deletedEmployeeUndoButtonText: '',
    emailSentText: '',
    resetPasswordText: '',
    editButtonText: '',
    deleteButtonText: '',
    approverLabelText: '',
    divisionLabelText: '',
    tierLabelText: '',
    roleLabelText: '',
    managerLabelText: '',
  },
  CorporateEmployeeListUpsertModal: {
    insertTitle: '',
    updateTitle: '',
    insertDivisionDropdownItemText: '',
    insertTierDropdownItemText: '',
    insertRoleDropdownItemText: '',
    fullNameField: '',
    fullNamePlaceholder: '',
    fullNameRequiredErrorMessage: '',
    fullNameMaxLengthErrorMessage: '',
    fullNameAlphabetErrorMessage: '',
    emailField: '',
    emailPlaceholder: '',
    emailRequiredErrorMessage: '',
    emailMaxLengthErrorMessage: '',
    emailFormatErrorMessage: '',
    approverCheckboxLabel: '',
    divisionDropdownField: '',
    divisionNameField: '',
    divisionNamePlaceholder: '',
    divisionNameRequiredErrorMessage: '',
    divisionNameMaxLengthErrorMessage: '',
    divisionNameAlphabetErrorMessage: '',
    divisionHeadField: '',
    divisionHeadRequiredErrorMessage: '',
    tierDropdownField: '',
    tierNameField: '',
    tierNamePlaceholder: '',
    tierNameRequiredErrorMessage: '',
    tierNameMaxLengthErrorMessage: '',
    tierNameAlphanumericErrorMessage: '',
    roleDropdownField: '',
    roleDropdownRequiredErrorMessage: '',
    roleNameField: '',
    roleNamePlaceholder: '',
    roleNameRequiredErrorMessage: '',
    roleNameMinLengthErrorMessage: '',
    roleNameMaxLengthErrorMessage: '',
    roleNameAlphabetErrorMessage: '',
    managerDropdownField: '',
    closeButtonText: '',
    insertSubmitButtonText: '',
    updateSubmitButtonText: '',
  },
  CorporateEmployeeListToolBar: {
    searchEmployeePlaceholder: '',
    showApproverCheckboxField: '',
    multiDeleteUserSelectedUnit: '',
    multiDeleteInfoText: '',
    multiDeleteSubmitButtonText: '',
  },
  CorporateEmployeeListPagination: {
    infoText: '',
    ofText: '',
  },
  CorporateEmployeeListBulkUpload: {
    fileMaxSizeErrorMessage: '',
    fileExtensionErrorMessage: '',
    fileMaxEmployeeErrorMessage: '',
  },
  CorporateEmployeeListBulkUploadStep: {
    step1: '',
    step2: '',
    stepTitle: '',
    stepInfoText: '',
    step1InfoText: '',
    step1InfoBoldText: '',
    downloadFormatButtonText: '',
    step2InfoText: '',
    fileUploadButtonText: '',
    browseButtonText: '',
    cancelButtonText: '',
    nextButtonText: '',
    submitButtonText: '',
  },
  CorporateEmployeeListBulkUploadResult: {
    title: '',
    successInfoText: '',
    failureInfoText: '',
    failureResultText: '',
    closeButtonText: '',
  },
  CorporateMenu: {
    purchaseListMenuText: '',
    bookingListMenuText: '',
    companyStructureMenuText: '',
    approvalListMenuText: '',
    employeeListMenuText: '',
    expenseReportMenuText: '',
    invoiceListMenuText: '',
    title: '',
  },
  CorporateCompanyStructureAccessRoleActionSwitcher: {
    actionCreateText: '',
    actionViewText: '',
    actionUpdateText: '',
    actionDeleteText: '',
    actionManageText: '',
  },
  CorporateContactUs: {
    flightText: '',
    hotelText: '',
    haveTripCheckboxText: '',
    title: '',
    selectProductField: '',
    selectProductPlaceholder: '',
    nameField: '',
    emailField: '',
    reasonField: '',
    reasonInfoText: '',
    submitButtonText: '',
    otherChannelInfoText: '',
    otherChannelEmailText: '',
    otherChannelPhoneText: '',
    rightSideInfoText: '',
  },
  CorporateContactUsHeader: {
    title: '',
    subtitle: '',
  },
  CorporateCreditLimit: {
    failedInfoText: '',
    exceedCreditLimitText: '',
    creditLimitBalanceText: '',
  },
  CorporateCreditLimitPayment: {
    sufficientText: '',
    insufficientText: '',
    failToLoadText: '',
  },
  CorporateCreditLimitMenu: {
    failToLoadText: '',
  },
  CorporateEnumCreditLimit: {
    SUFFICIENT: '',
    LOW: '',
    VERY_LOW: '',
    EMPTY: '',
    MINUS: '',
  },
  CorporatePostLogin: {
    title: '',
  },
  CorporateAuthCallback: {
    title: '',
    infoText: '',
    reloginButtonText: '',
  },
  CorporateEnumFlightMainRefundReason: {
    PERSONAL_REASON: '',
    CANCELLED_BY_AIRLINE: '',
    DOUBLE_BOOKING: '',
    FORCE_MAJEURE: '',
    RESCHEDULED_BY_AIRLINE: '',
  },
  CorporateEnumFlightPersonalRefundReason: {
    CHANGE_OF_PLAN: '',
    DEATH: '',
    ILLNESS: '',
    PREGNANCY: '',
    VISA_REJECTION: '',
  },
  CorporateEnumFlightSubmitRefundError: {
    INCONSISTENT_REFUND_REASON: '',
    INVALID_CUSTOMER_REASON: '',
    MUST_REFUND_ALL_PAX: '',
    PAX_NOT_REFUNDABLE: '',
    SHOULD_REFUND_ALL_JOURNEY: '',
    SHOULD_REFUND_ALL_ROUTE: '',
    GENERAL_ERROR: '',
    BOOKING_NOT_FOUND: '',
    PARTIALLY_FAILED: '',
    ALREADY_CANCELLED: '',
    NON_REFUNDABLE_BOOKING: '',
    INVALID_SPEC: '',
    MUST_NOT_CHILD_INFANT_ONLY: '',
  },
  CorporateEnumHotelMainRefundReason: {
    PERSONAL_REASON: '',
    FORCE_MAJEURE: '',
    RESCHEDULED_BY_ACCOMMODATION: '',
    CANCELLED_BY_ACCOMMODATION: '',
    DOUBLE_BOOKING: '',
    OTHERS: '',
  },
  CorporateEnumHotelSubmitRefundError: {
    alreadyCancelledTitle: '',
    alreadyCancelledBody: '',
    nonRefundableTitle: '',
    nonRefundableBody: '',
    redirectBookingDetailButtonText: '',
  },
  CorporateEnumPreTripStatus: {
    RESCHEDULED_BY_AIRLINE: '',
    INVALID_RESCHEDULED_BY_AIRLINE: '',
    CANCELLED_BY_AIRLINE: '',
  },
  CorporateEnumAttachment: {
    BOOKING: '',
    TRIP_REQUEST: '',
  },
  CorporateApprovalMenu: {
    myRequestText: '',
    createTripRequestText: '',
    needMyApprovalText: '',
  },
  CorporateFooter: {
    contactUsText: '',
    contactUsValue: '',
    travelokaBusinessTitle: '',
    redirectContactUsText: '',
    othersTitle: '',
    redirectTncText: '',
    copyrightText: '',
  },
  CorporateHeader: {
    myBookingButtonText: '',
    loginButtonText: '',
    registerButtonText: '',
    approvalButtonText: '',
    logoutButtonText: '',
  },
  CorporateCovidBanner: {
    infoText: '',
    linkText: '',
  },
  CorporateInvoiceList: {
    cancelButton: '',
    daysLeftBadge: '',
    daysOverBadge: '',
    downloadButton: '',
    downloadModalTitle: '',
    filterFromLabel: '',
    filterSearchButton: '',
    filterStatusLabel: '',
    filterTitle: '',
    filterToLabel: '',
    headerDueDate: '',
    headerInvoiceAmount: '',
    headerInvoiceDate: '',
    headerInvoiceNumber: '',
    headerPaymentDates: '',
    headerPaymentStatus: '',
    headerUnpaidAmount: '',
    initialInvoiceInfo: '',
    initialInvoiceTitle: '',
    invoiceNotFoundInfo: '',
    invoiceNotFoundTitle: '',
    moreLabel: '',
    nextButton: '',
    pageTitle: '',
    previousButton: '',
    serviceFeeText: '',
    summaryTotalUnpaid: '',
    summaryUnpaidAfterDue: '',
    summaryUnpaidBeforeDue: '',
    transactionFileText: '',
    viewText: '',
  },
  CorporateTermsOfUse: {
    termsOfUseTitle: '',
    termsOfUseContent: '',
    scopeOfOurServicesTitle: '',
    scopeOfOurServicesContentNo1: '',
    scopeOfOurServicesContentNo2: '',
    scopeOfOurServicesContentNo3: '',
    scopeOfOurServicesContentNo4: '',
    scopeOfOurServicesContentNo5: '',
    scopeOfOurServicesContentNo6: '',
    scopeOfOurServicesContentNo7: '',
    cancellationsTitle: '',
    cancellationsContentNo1: '',
    cancellationsContentNo2: '',
    specialRequestsTitle: '',
    specialRequestsContentNo1: '',
    specialRequestsContentNo2: '',
    travelAdviceTitle: '',
    travelAdviceContentNo1: '',
    travelAdviceContentNo2: '',
    travelAdviceContentNo3: '',
    ratingsTitle: '',
    ratingsContentNo1: '',
    priceAndPromotionTitle: '',
    priceAndPromotionContentNo1: '',
    priceAndPromotionContentNo2: '',
    serviceFeeTitle: '',
    serviceFeeContentNo1: '',
    serviceFeeContentNo2: '',
    serviceFeeContentNo2a: '',
    serviceFeeContentNo2b: '',
    serviceFeeContentNo3: '',
    dataDiscrepanciesTitle: '',
    dataDiscrepanciesContentNo1: '',
    dataDiscrepanciesContentNo2: '',
    dataDiscrepanciesContentNo3: '',
    additionalChargesRefundsTitle: '',
    additionalChargesRefundsContentNo1: '',
    additionalChargesRefundsContentNo2: '',
    additionalChargesRefundsContentNo3: '',
    additionalChargesFromHotelsTitle: '',
    additionalChargesFromHotelsContentNo1: '',
    additionalChargesFromHotelsContentNo2: '',
    additionalChargesFromHotelsContentNo3: '',
    additionalChargesFromHotelsContentNo4: '',
    additionalChargesFromHotelsContentNo5: '',
    userAccountTitle: '',
    userAccountContentNo1: '',
    userAccountContentNo2: '',
    userAccountContentNo3: '',
    userAccountContentNo4: '',
    userAccountContentNo5: '',
    userAccountContentNo6: '',
    userAccountContentNo6Sub1: '',
    userAccountContentNo6Sub2: '',
    userAccountContentNo6Sub3: '',
    userAccountContentNo6Sub4: '',
    userAccountContentNo6Sub5: '',
    userAccountContentNo6Sub6: '',
    userAccountContentNo6Sub7: '',
    userAccountContentNo6Sub8: '',
    userAccountContentNo6Sub9: '',
    userAccountContentNo6Sub10: '',
    userAccountContentNo6Sub11: '',
    paymentDetailsAndProceduresTitle: '',
    paymentDetailsAndProceduresContentNo1: '',
    paymentDetailsAndProceduresContentNo2: '',
    paymentDetailsAndProceduresContentNo3: '',
    paymentDetailsAndProceduresContentNo4: '',
    paymentDetailsAndProceduresContentNo5: '',
    paymentDetailsAndProceduresContentNo6: '',
    paymentDetailsAndProceduresContentNo6Sub1: '',
    paymentDetailsAndProceduresContentNo6Sub2: '',
    paymentDetailsAndProceduresContentNo6Sub3: '',
    paymentDetailsAndProceduresContentNo6Sub4: '',
    paymentDetailsAndProceduresContentNo6Sub5: '',
    paymentByCreditCardFraudTitle: '',
    paymentByCreditCardFraudContentNo1: '',
    paymentByCreditCardFraudContentNo2: '',
    paymentByCreditCardFraudContentNo3: '',
    paymentByCreditCardFraudContentNo4: '',
    paymentByCreditCardFraudContentNo5: '',
    paymentByCreditCardFraudContentNo6: '',
    paymentByCreditCardFraudContentNo7: '',
    rightsAndObligationsTitle: '',
    rightsAndObligationsContentNo1: '',
    rightsAndObligationsContentNo2: '',
    rightsAndObligationsContentNo2Sub1: '',
    rightsAndObligationsContentNo2Sub2: '',
    rightsAndObligationsContentNo2Sub3: '',
    rightsAndObligationsContentNo2Sub4: '',
    rightsAndObligationsContentNo2Sub5: '',
    rightsAndObligationsContentNo2Sub6: '',
    rightsAndObligationsContentNo2Sub7: '',
    rightsAndObligationsContentNo2Sub8: '',
    rightsAndObligationsContentNo2Sub9: '',
    rightsAndObligationsContentNo2Sub10: '',
    rightsAndObligationsContentNo2Sub11: '',
    rightsAndObligationsContentNo2Sub12: '',
    rightsAndObligationsContentNo2Sub13: '',
    rightsAndObligationsContentNo2Sub14: '',
    rightsAndObligationsContentNo2Sub15: '',
    rightsAndObligationsContentNo2Sub16: '',
    rightsAndObligationsContentNo2Sub17: '',
    rightsAndObligationsContentNo2Sub18: '',
    rightsAndObligationsContentNo2Sub19: '',
    rightsAndObligationsContentNo2Sub20: '',
    rightsAndObligationsContentNo2Sub21: '',
    rightsAndObligationsContentNo2Sub22: '',
    intellectualPropertyRightsTitle: '',
    intellectualPropertyRightsContentNo1: '',
    intellectualPropertyRightsContentNo2: '',
    intellectualPropertyRightsContentNo3: '',
    intellectualPropertyRightsContentNo4: '',
    intellectualPropertyRightsContentNo5: '',
    intellectualPropertyRightsContentNo6: '',
    claimsOfIntellectualPropertyRightsInfringementTitle: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1Sub1: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1Sub2: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1Sub3: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1Sub4: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1Sub5: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1Sub6: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1Sub7: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1Sub8: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1Sub9: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo1Sub10: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo2: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo2Sub1: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo2Sub2: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo3: '',
    claimsOfIntellectualPropertyRightsInfringementContentNo4: '',
    limitationOfLiabilityTitle: '',
    limitationOfLiabilityContentNo1: '',
    limitationOfLiabilityContentNo1Sub1: '',
    limitationOfLiabilityContentNo1Sub2: '',
    limitationOfLiabilityContentNo1Sub3: '',
    limitationOfLiabilityContentNo1Sub4: '',
    limitationOfLiabilityContentNo1Sub5: '',
    limitationOfLiabilityContentNo1Sub6: '',
    limitationOfLiabilityContentNo2: '',
    limitationOfLiabilityContentNo2Sub1: '',
    limitationOfLiabilityContentNo2Sub2: '',
    limitationOfLiabilityContentNo2Sub3: '',
    limitationOfLiabilityContentNo3: '',
    limitationOfLiabilityContentNo4: '',
    limitationOfLiabilityContentNo5: '',
    limitationOfLiabilityContentNo6: '',
    limitationOfLiabilityContentNo7: '',
    limitationOfLiabilityContentNo8: '',
    governingLawTitle: '',
    governingLawContentNo1: '',
    disputesResolutionTitle: '',
    disputesResolutionContentNo1: '',
    disputesResolutionContentNo2: '',
    disputesResolutionContentNo3: '',
    disputesResolutionContentNo4: '',
    disputesResolutionContentNo5: '',
    disputesResolutionContentNo6: '',
    disputesResolutionContentNo7: '',
    disputesResolutionContentNo8: '',
    disputesResolutionContentNo9: '',
    disputesResolutionContentNo10: '',
    forceMajeureTitle: '',
    forceMajeureContentNo1: '',
    forceMajeureContentNo2: '',
  },
  CorporateLanguage: {
    title: '',
    englishText: '',
    indonesiaText: '',
    thailandText: '',
    vietnamText: '',
  },
  CorporateEnumNonEmployeeType: {
    ADULT: '',
    CHILD: '',
    INFANT: '',
  },
  CorporateHomePage: {
    metaDescription: '',
    title: '',
    introductionHeading: '',
    introductionParagraph: '',
    introductionSecondaryParagraph: '',
    introductionPartnerParagraph: '',
    videoIntroductionHeading: '',
    videoIntroductionButtonText: '',
    videoIntroductionYoutubeEmbedUrl: '',
    solutionSectionName: '',
    solutionHeading: '',
    solutionItemTitle01: '',
    solutionItemTitle02: '',
    solutionItemTitle03: '',
    solutionItemTitle04: '',
    solutionItemTitle05: '',
    solutionItemDesription01: '',
    solutionItemDesription02: '',
    solutionItemDesription03: '',
    solutionItemDesription04: '',
    solutionItemDesription05: '',
    featuredSolutionItemTitle01: '',
    featuredSolutionItemTitle02: '',
    featuredSolutionItemTitle03: '',
    featuredSolutionItemDescription01: '',
    featuredSolutionItemDescription02: '',
    featuredSolutionItemDescription03: '',
    solutionBannerText: '',
    useCaseSectionName: '',
    useCaseHeading: '',
    useCaseTitle01: '',
    useCaseTitle02: '',
    useCaseTitle03: '',
    useCaseDescription01: '',
    useCaseDescription02: '',
    useCaseDescription03: '',
    useCaseBannerText: '',
    benefitSectionName: '',
    benefitHeading: '',
    benefitHeadingParagraph: '',
    benefitListTitle: '',
    retailColumnText: '',
    retailColumnCheckIndexList: '',
    corporateColumnText: '',
    corporateColumnCheckIndexList: '',
    benefitItemDescription01: '',
    benefitItemDescription02: '',
    benefitItemDescription03: '',
    benefitItemDescription04: '',
    benefitItemDescription05: '',
    benefitItemDescription06: '',
    benefitBannerText: '',
    howToRegisterSectionName: '',
    howToRegisterHeading: '',
    registrationStepTitle01: '',
    registrationStepTitle02: '',
    registrationStepTitle03: '',
    registrationStepDescription01: '',
    registrationStepDescription02: '',
    registrationStepDescription03: '',
    statisticHeading: '',
    statisticTitle01: '',
    statisticTitle02: '',
    statisticTitle03: '',
    statisticDescription01: '',
    statisticDescription02: '',
    statisticDescription03: '',
    howToRegisterBannerText: '',
    statisticBannerText: '',
    youtubeEmbedSrc: '',
    requiredDocumentButtonText: '',
    loginButtonText: '',
    signUpButtonText: '',
    watchDemoButtonText: '',
    comingSoonBadgeText: '',
    closeButtonText: '',
    requiredDocumentHeading: '',
    requiredDocumentParagraph: '',
    requiredDocumentImportantNoteParagraph: '',
    requiredDocument01: '',
    requiredDocument02: '',
    requiredDocument03: '',
    requiredDocument04: '',
  },
  CorporateFooterV2: {
    officeLabel: '',
    callCenterLabel: '',
    emailUsLabel: '',
    directionLabel: '',
    officeAddressMapLink: '',
    officeAddressLine01: '',
    officeAddressLine02: '',
    callCenterNumber: '',
    callCenterNumberLabel: '',
    emailAddress: '',
    copyrightText: '',
    helpCenter: '',
  },
  CorporateEnumCountryCurrency: {
    currencySymbol: '',
  },
  CorporateEnumInvoice: {
    ALL: '',
    NOT_PAID: '',
    UNDERPAID: '',
    PAID: '',
  },
  CorporateEnumFileType: {
    PDF: '',
    XLSX: '',
  },
  CorporateEnumProductType: {
    FLIGHT: '',
    HOTEL: '',
  },
  CorporateAccountStatus: {
    suspended: '',
    outstandingInvoice: '',
    suspendedBannerMessage: '',
    popupTitle: '',
    popupMessage: '',
    popupCta: '',
    suspendedBannerMessageInvoice: '',
    popupTitleInvoice: '',
    popupMessageInvoice: '',
    popupCtaInvoice: '',
    popupCtaHome: '',
  },
  CorporateInvoiceDispute: {
    title: '',
    numInvoiceText: '',
    descriptionText: '',
    amountText: '',
    actionInfoText: '',
    yesButtonText: '',
    noButtonText: '',
    noDataText: '',
    confirmationModalTitle: '',
    confirmationModalInfo: '',
    confirmationModalButtonText: '',
  },
};

export const image = {
  CorporateB2B: {
    InsufficientCreditLimit: {},
  },
  CommerceAPIImages: {
    amex: { link: '' },
    jcb: { link: '' },
    mastercard: { link: '' },
    visa: { link: '' },
  },
  CorporateHomePage: {
    introduction: { link: '' },
    introductionVideoThumbnail: { link: '' },
    registrationStep01: { link: '' },
    registrationStep02: { link: '' },
    registrationStep03: { link: '' },
    solution01: { link: '' },
    solution02: { link: '' },
    solution03: { link: '' },
    solution04: { link: '' },
    solution05: { link: '' },
    travelokaCorporateLogo: { link: '' },
    travelokaLogo: { link: '' },
    usecase01: { link: '' },
    usecase02: { link: '' },
    usecase03: { link: '' },
  },
  CorporateRegistration: {
    registrationBenefit1: { link: '' },
    registrationBenefit2: { link: '' },
    registrationBenefit3: { link: '' },
    whatsNextDataVerification: { link: '' },
    whatsNextRegistrationForm: { link: '' },
    whatsNextStartJourney: { link: '' },
  },
  CorporateInvoice: {
    noInvoiceFound: { link: '' },
  },
};

export const slider = {
  CorporateHomePage: {
    link: '',
    groupName: '',
  },
};

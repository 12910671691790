import React from 'react';

import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import RedirectHome from 'generic/RedirectHome';

import HotelDetail from './detail/HotelDetail';
import HotelPrebook from './prebook/HotelPrebook';
import HotelSearch from './search/HotelSearch';

type Props = RouteComponentProps;

function HotelApp(props: Props) {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.path}/search`} exact component={HotelSearch} />
      <Route path={`${match.path}/detail`} exact component={HotelDetail} />
      <Route
        path={`${match.path}/prebook/:key`}
        exact
        component={HotelPrebook}
      />

      <Route component={RedirectHome} />
    </Switch>
  );
}

export default HotelApp;

import React, {
  useState,
  ComponentType,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';
import { StyleSheet, View } from 'react-native';
import { useParams } from 'react-router-dom';

import { useApi, useAuth } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { Card, Text, Token } from '@traveloka/web-components';

import InsufficientCreditLimitModal from 'credit-limit/components/InsufficientCreditLimitModal/InsufficientCreditLimitModal';
import {
  usePaymentStateDispatch,
  usePaymentState,
} from 'payment/select/contexts/PaymentContext';
import {
  ConfirmPaymentRequest,
  ConfirmPaymentResponse,
  CONFIRM_PAYMENT,
  ProductType,
} from 'payment/select/api';
import { ALLOWED_PAYMENT_STATUSES } from 'payment/select/constants/PaymentSelectConstant';
import PaymentMethod from 'payment/shared/constants/PaymentMethod';
import BookingStatus from 'postbooking/shared/constants/BookingStatus';

import ExpirationTimer from '../ExpirationTimer/ExpirationTimer';
import Invoice from '../Form/Invoice';
import PayNow from '../PayNow/PayNow';
import PriceBreakdown from '../PriceBreakdown/PriceBreakdown';
import SideMenu from '../SideMenu/SideMenu';
import Confirmed from '../Confirmed/Confirmed';
import CreditCard from '../Form/CreditCard';

type ProductTypeParam = 'flight' | 'hotel';

type Props = ContentProps & {
  status: BookingStatus;
};

type ContentProps = PropsWithChildren<{
  expireOn: number;
  priceBreakdown: Array<{
    testID?: string;
    label: string;
    value: string;
    isFree?: boolean;
  }>;
  totalPrice: string;
}>;

const formMap: Record<PaymentMethod, ComponentType> = {
  INVOICE: Invoice,
  CREDIT_CARD: CreditCard,
};

export default function PaymentSelectContent(props: Props) {
  const { status, ...rest } = props;
  const { booking } = usePaymentState();

  if (!ALLOWED_PAYMENT_STATUSES.includes(status)) {
    return <Confirmed tripId={booking.tripId} />;
  }

  return <Content {...rest} />;
}

function Content(props: ContentProps) {
  const { children, expireOn, priceBreakdown, totalPrice } = props;

  const content = useContentResource().CorporateProductPayment;

  const user = useAuth().user!;
  const [activeMenu, setActiveMenu] = useState(user.paymentMethod);
  const [
    isCreditInsufficientModalVisible,
    setIsCreditInsufficientModalVisible,
  ] = useState(false);

  const { type: productType } = useParams<{
    type: string;
  }>();

  const { booking } = usePaymentState();
  const { confirmBookingStatus } = usePaymentStateDispatch();
  const paymentStatusMap = useMemo(
    () => ({
      PAYMENT_EXPIRED: content.paymentStatusBookingExpired,
      BOOKING_NOT_FOUND: content.paymentStatusBookingNotFound,
      ALREADY_CONFIRMED: content.paymentStatusAlreadyConfirmed,
      FAILED: content.paymentStatusFailed,
    }),
    []
  );

  const sendConfirmation = useApi<
    ConfirmPaymentResponse,
    ConfirmPaymentRequest
  >({
    domain: 'booking',
    method: 'post',
    path: CONFIRM_PAYMENT,
  });

  const confirmPayment = useCallback(() => {
    return sendConfirmation({
      tripId: booking.tripId,
      paymentMethod: 'INVOICE',
      productType: convertProductType(productType as ProductTypeParam),
    }).then(res => {
      if (res.success) {
        const status = res.data.paymentConfirmationStatus;
        if (status === 'OK') {
          confirmBookingStatus();
          return;
        }

        if (status === 'CREDIT_LIMIT_NOT_SUFFICIENT') {
          setIsCreditInsufficientModalVisible(true);
          return;
        }

        return paymentStatusMap[status];
      }

      return res.error.message;
    });
  }, [
    sendConfirmation,
    confirmBookingStatus,
    productType,
    paymentStatusMap,
    booking.tripId,
  ]);

  const Form = formMap[activeMenu];

  return (
    <>
      <Text variant="headline" style={Style.pageTitle}>
        {content.pageHeadline}
      </Text>
      <View style={Style.layout}>
        <Card style={Style.card}>
          <SideMenu activeMenu={activeMenu} onPress={setActiveMenu} />
          <View style={Style.content}>
            <ExpirationTimer expireOn={expireOn} />
            <Form />
            <PriceBreakdown items={priceBreakdown} totalPrice={totalPrice} />
            <PayNow confirmPayment={confirmPayment} />
          </View>
        </Card>
        {children}
      </View>
      <InsufficientCreditLimitModal
        isVisible={isCreditInsufficientModalVisible}
      />
    </>
  );
}

function convertProductType(productType: ProductTypeParam): ProductType {
  switch (productType) {
    case 'flight':
      return 'FLIGHT';
    case 'hotel':
      return 'HOTEL';
  }
}

const Style = StyleSheet.create({
  pageTitle: {
    marginBottom: Token.spacing.xl,
  },
  layout: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  card: {
    flex: 1,
    flexDirection: 'row',
    marginRight: Token.spacing.m,
  },
  content: {
    flex: 1,
    minHeight: 300,
    paddingBottom: Token.spacing.xl,
  },
});

import React from 'react';

import { useContentResource } from '@traveloka/ctv-core';

import { CommonStyle } from '../filter-search-toolbar.style';
import FlightSearchToolbarMenuContainer from '../FlightSearchToolbarMenuContainer';
import MoreFaciltyFilter from './MoreFaciltyFilter';
import MorePreferenceFilter from './MorePreferenceFilter';
import MorePriceFilter from './MorePriceFilter';
import MoreComplianceFilter from './MoreComplianceFilter';

function MoreFilter() {
  const { CorporateFlightSearchToolbar } = useContentResource();
  const content = {
    title: CorporateFlightSearchToolbar.more,
  };

  return (
    <FlightSearchToolbarMenuContainer
      alignment="left"
      text={content.title}
      style={CommonStyle.menuItem}
      testID="flight.search.toolbar.more"
    >
      <MoreComplianceFilter style={CommonStyle.section} />
      <MorePriceFilter style={CommonStyle.section} />
      <MoreFaciltyFilter style={CommonStyle.section} />
      <MorePreferenceFilter />
    </FlightSearchToolbarMenuContainer>
  );
}

export default MoreFilter;

import React, { Ref } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { Text, Token } from '@traveloka/web-components';

import { Item } from './DropdownChecklist/DropdownChecklist';
import DropdownChecklist from './DropdownChecklist/DropdownChecklist';
import { appendTestId } from '../../../shared/utils/TestUtil';

type Props = {
  style?: StyleProp<ViewStyle>;
  filterLabel: string;
  divisions: Item[];
  divisionPlaceholder?: string;
  divisionDisabled?: boolean;
  dropdownRef?: Ref<{ setShowDropdown: (value: boolean) => void } | undefined>;
  onDivisionPress: (checked: boolean, value: Item) => void;
  onSelectAllDivisionPress?: (checked: boolean, value: Item) => void;
  testID?: string;
};

export default function FilterBar(props: Props) {
  const {
    style,
    filterLabel,
    divisions,
    divisionPlaceholder,
    divisionDisabled,
    dropdownRef,
    onDivisionPress,
    onSelectAllDivisionPress,
    testID,
  } = props;

  return (
    <View style={[Style.container, style]}>
      <Text variant="ui-small" style={Style.filterLabel}>
        {filterLabel}
      </Text>
      <DropdownChecklist
        ref={dropdownRef}
        placeholder={divisionPlaceholder}
        items={divisions}
        onPress={onDivisionPress}
        onSelectAllPress={onSelectAllDivisionPress}
        testID={appendTestId(testID, 'division')}
        disabled={divisionDisabled}
      />
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1,
  },
  filterLabel: {
    marginRight: Token.spacing.xs,
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  inputDropdown: {
    flex: 1,
  },
});

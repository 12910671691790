import React from 'react';

import { useLocalizedDateFormat } from '@traveloka/ctv-core';
import { Divider } from '@traveloka/ctvweb-ui';
import { FlightSegment } from '@traveloka/ctvweb-ui/refund';

import { ONE_WAY_SEPARATOR } from 'flight/search/constants/flight-search-constant';
import { useFlightRequestRefund } from 'refund/request-refund/contexts/flight/FlightRequestRefundContext';
import { useFlightRequestRefundForm } from 'refund/request-refund/contexts/flight/FlightRequestRefundFormContext';

const DOT = '•';

export default function FlightJourneyList() {
  const { flightCheckboxes } = useFlightRequestRefundForm();
  const [{ data }] = useFlightRequestRefund();
  const { flightRefundData } = data;

  const { format } = useLocalizedDateFormat();

  return (
    <>
      {flightCheckboxes.map((checkbox, checkboxIndex) => {
        if (checkbox.checkboxValue) {
          return flightRefundData.journeys[checkboxIndex].segments.map(
            (segment, segmentIndex) => {
              const isFirstRow = checkboxIndex + segmentIndex === 0;
              const isFirstSegment = segmentIndex === 0;

              const key = `${checkboxIndex}-${segmentIndex}`;

              return (
                <React.Fragment key={key}>
                  {!isFirstRow && (
                    <Divider
                      margin={!isFirstSegment ? 'left' : 'none'}
                      spacing="m"
                    />
                  )}
                  <FlightSegment
                    airlineInfo={`${segment.airline.airlineName} ${DOT} ${segment.flightCode}`}
                    flightDatetime={`${format(
                      new Date(segment.departureDetail.departureDate),
                      'FULL_WEEKDAY'
                    )} ${DOT} ${segment.departureDetail.departureTime}`}
                    flightRoute={`${segment.departureDetail.city}(${segment.departureDetail.airportCode}) ${ONE_WAY_SEPARATOR} ${segment.arrivalDetail.city}(${segment.arrivalDetail.airportCode})`}
                  />
                </React.Fragment>
              );
            }
          );
        }
        return null;
      })}
    </>
  );
}

import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import EmailIcon from '@traveloka/icon-kit-web/svg/blue/ic_contact_mail_24px.svg';
import PhoneIcon from '@traveloka/icon-kit-web/svg/blue/ic_contact_phone-fill_24px.svg';
import LocationIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_map_location-fill_24px.svg';
import {
  useTheme,
  Button,
  Card,
  Icon,
  Text,
  Token,
} from '@traveloka/web-components';

import { appendTestId } from '../../shared/utils/TestUtil';

type Props = {
  address: string;
  addressLabel: string;
  corporateId: string;
  corporateName: string;
  editLabel?: string;
  email?: string;
  emailLabel?: string;
  onEditPress?(): void;
  phone: string;
  phoneLabel: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

export default function CompanyInfo(props: Props) {
  const {
    address,
    addressLabel,
    corporateId,
    corporateName,
    editLabel,
    email,
    emailLabel,
    onEditPress,
    phone,
    phoneLabel,
    style,
    testID,
  } = props;

  const { color } = useTheme();
  const textStyle = {
    color: color.lightPrimary,
  };

  return (
    <Card style={style}>
      <View style={Style.top}>
        <View>
          <Text variant="ui-tiny" style={[Style.id, textStyle]}>
            {corporateId}
          </Text>
          <Text variant="ui-large" style={[Style.name, textStyle]}>
            {corporateName}
          </Text>
        </View>
        {editLabel !== undefined && (
          <Button
            testID={appendTestId(testID, 'edit')}
            variant="secondary"
            text={editLabel}
            onPress={onEditPress}
          />
        )}
      </View>
      <View style={Style.bottom}>
        <Info
          icon={LocationIcon}
          label={addressLabel}
          text={address}
          style={Style.info}
        />
        <Info
          icon={PhoneIcon}
          label={phoneLabel}
          text={phone}
          style={Style.info}
        />
        {emailLabel !== undefined && email !== undefined && (
          <Info icon={EmailIcon} label={emailLabel} text={email} />
        )}
      </View>
    </Card>
  );
}

type InfoProps = {
  icon: string;
  label: string;
  style?: StyleProp<ViewStyle>;
  text: string;
};

function Info(props: InfoProps) {
  const { icon, label, style, text } = props;

  return (
    <View style={[Style.row, style]}>
      <View style={Style.labelGroup}>
        <Icon src={icon} width={16} height={16} />
        <Text variant="ui-small" style={Style.label}>
          {label}
        </Text>
      </View>
      <Text variant="ui-small">{text}</Text>
    </View>
  );
}

const Style = StyleSheet.create({
  top: {
    padding: Token.spacing.s,
    backgroundColor: Token.color.uiBluePrimary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bottom: {
    padding: Token.spacing.s,
  },
  id: {
    textTransform: 'uppercase',
  },
  name: {
    marginTop: Token.spacing.xs,
    flex: 1,
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  info: {
    marginBottom: Token.spacing.m,
  },
  labelGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'baseline',
  },
  label: {
    fontWeight: Token.typography.weightMedium.fontWeight,
    marginHorizontal: Token.spacing.s,
    width: 80,
  },
});

import { LocationDescriptorObject, LocationState } from 'history';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useLocale } from '@traveloka/ctv-core';

type LocalizedHistory = {
  push(location: LocationDescriptorObject): void;
  push(path: string, state?: LocationState): void;
  replace(location: LocationDescriptorObject): void;
  replace(path: string, state?: LocationState): void;
  createHref(location: LocationDescriptorObject): string;
};

export default function useLocalizedHistory() {
  const history = useHistory();
  const { locale } = useLocale();

  return useMemo<LocalizedHistory>(() => {
    return {
      push(location: LocationDescriptorObject | string, state?: LocationState) {
        if (typeof location === 'string') {
          window.location.href = '/' + locale + location;
          return;
        }

        const newPath = history.createHref(location);
        window.location.href = '/' + locale + newPath;
      },
      replace(
        location: LocationDescriptorObject | string,
        state?: LocationState
      ) {
        if (typeof location === 'string') {
          window.location.replace('/' + locale + location);
          return;
        }

        const newPath = history.createHref(location);
        window.location.replace('/' + locale + newPath);
      },
      createHref(location: LocationDescriptorObject) {
        return '/' + locale + history.createHref(location);
      },
    };
  }, [locale]);
}

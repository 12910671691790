import React, { useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { FormProvider, useForm } from 'react-hook-form';

import { isValid, parse, startOfDay } from 'date-fns';

import {
  useApi,
  useContentResource,
  useLocalizedDateFormat,
} from '@traveloka/ctv-core';
import { ProfileFieldGroup } from '@traveloka/ctvweb-ui/profile';
import Chevron from '@traveloka/icon-kit-web/svg/blue/ic_system_chevron_down_24px.svg';
import Flag from '@traveloka/icon-kit-web/svg/dark/ic_flag_24px.svg';
import Phone from '@traveloka/icon-kit-web/svg/dark/ic_mobile_phone_24px.svg';
import CreditCard from '@traveloka/icon-kit-web/svg/dark/ic_payment_credit_card_24px.svg';
import User from '@traveloka/icon-kit-web/svg/dark/ic_user_account_24px.svg';
import Email from '@traveloka/icon-kit-web/svg/dark/ic_user_subscription_24px.svg';
import Passport from '@traveloka/icon-kit-web/svg/dark/ic_user_wallet_24px.svg';
import Lock from '@traveloka/icon-kit-web/svg/dark/ic_system_lock_24px.svg';
import { Button, Card, Icon, Text, Token } from '@traveloka/web-components';

import { UpdateProfileRequest, UPDATE_PROFILE } from 'profile/api';
import { Profile } from 'profile/types';
import CountryField from 'shared/components/form/CountryField/CountryField';
import DateField, {
  Value as DateFieldValue,
} from 'shared/components/form/DateField/DateField';
import PhoneField from 'shared/components/form/PhoneField/PhoneField';
import InputField from 'shared/components/form/InputField/InputField';
import { JAVA_DATE } from 'shared/utils/date';
import { formatMessage } from 'shared/utils/intl';
import {
  isAlphaNumeric,
  isPhone,
  maxLength,
  minLength,
  required,
  isNumeric,
} from 'shared/utils/validator';

import { ProfileForm } from '../../types';

type Props = {
  profile: Profile;
  onResetPassword: () => Promise<string>;
};

export default function EditProfileForm(props: Props) {
  const { profile, onResetPassword } = props;

  const { format } = useLocalizedDateFormat();
  const content = useContentResource().CorporateEditProfile;

  const [message, setMessage] = useState(content.changePasswordMessage);
  const [loadingButton, setLoadingButton] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [variantButton, setVariantButton] = useState<
    'secondary' | 'destructive-secondary'
  >('secondary');

  const defaultValues = useMemo<ProfileForm>(() => {
    const { phone, phoneCountryCode, profileDetails, nationality } = profile;

    const initialValue: ProfileForm = {
      nationality: nationality || undefined,
      phone: undefined,
      identityCardNumber: undefined,
      passportCountryOfIssue: undefined,
      passportExpiryDate: undefined,
      passportNumber: undefined,
    };

    if (phone && phoneCountryCode) {
      initialValue.phone = {
        phone,
        prefix: phoneCountryCode,
      };
    }

    if (profileDetails) {
      const { identityCard, passport } = profileDetails;

      if (identityCard && identityCard.identityCardNumber) {
        initialValue.identityCardNumber = identityCard.identityCardNumber;
      }

      if (passport) {
        const { countryOfIssue, passportNumber, passportExpiryDate } = passport;

        if (countryOfIssue) {
          initialValue.passportCountryOfIssue = countryOfIssue;
        }

        if (passportNumber) {
          initialValue.passportNumber = passportNumber;
        }

        if (passportExpiryDate) {
          const date = new Date(passportExpiryDate);

          initialValue.passportExpiryDate = {
            year: String(date.getFullYear()),
            month: String(date.getMonth()),
            day: String(date.getDate()),
          };
        }
      }
    }

    return initialValue;
  }, [profile]);

  const methods = useForm({ defaultValues });
  const {
    formState: { isSubmitting },
    handleSubmit: formSubmit,
  } = methods;
  const today = startOfDay(new Date());

  const updateProfile = useApi<unknown, UpdateProfileRequest>({
    domain: 'management',
    method: 'post',
    path: UPDATE_PROFILE,
  });

  async function handleSubmit(value: ProfileForm) {
    const {
      nationality = null,
      phone,
      identityCardNumber = null,
      passportCountryOfIssue = null,
      passportExpiryDate,
      passportNumber = null,
    } = value;

    const spec: UpdateProfileRequest = {
      nationality,
      phone: null,
      phoneCountryCode: null,
      profileDetails: {
        frequentFlyer: null,
        identityCard: {
          identityCardNumber,
        },
        passport: {
          countryOfIssue: passportCountryOfIssue,
          passportExpiryDate: null,
          passportNumber,
        },
      },
    };

    if (phone && phone.phone) {
      spec.phone = phone.phone;
      spec.phoneCountryCode = phone.prefix;
    }

    if (passportExpiryDate) {
      spec.profileDetails.passport!.passportExpiryDate = String(
        Date.UTC(
          Number(passportExpiryDate.year),
          Number(passportExpiryDate.month),
          Number(passportExpiryDate.day)
        )
      );
    }

    return updateProfile(spec);
  }

  async function handleResetPassword() {
    setLoadingButton(true);
    const message = await onResetPassword();
    if (message === '') {
      setMessage(content.emailSentMessage);
      setLoadingButton(false);
      setDisableButton(true);
    } else {
      setMessage(message);
      setLoadingButton(false);
      setVariantButton('destructive-secondary');
    }
  }

  return (
    <FormProvider {...methods}>
      <Card style={Style.card}>
        <ProfileFieldGroup icon={User} title={profile.fullname} border>
          <View style={Style.row}>
            <View style={Style.column}>
              <Text variant="ui-small" style={Style.subTitle}>
                {content.divisionText}
              </Text>
              <Text ink="secondary">{profile.division || '-'}</Text>
            </View>
            <View style={Style.column}>
              <Text variant="ui-small" style={Style.subTitle}>
                {content.tierText}
              </Text>
              <Text ink="secondary">{profile.tier || '-'}</Text>
            </View>
          </View>
        </ProfileFieldGroup>

        <ProfileFieldGroup icon={Email} title={content.emailTitleField} border>
          <Text ink="secondary">{profile.email}</Text>
        </ProfileFieldGroup>

        <ProfileFieldGroup
          icon={Phone}
          title={content.phoneTitleField}
          border
          style={{ zIndex: 5 }}
        >
          <PhoneField
            name="phone"
            label={content.phoneText}
            style={Style.shortInput}
            validate={value => {
              if (value && required(value.phone) === true) {
                if (minLength(value.phone, 7) === false) {
                  return formatMessage(content.phoneMinLengthErrorMessage, {
                    length: 7,
                  });
                } else if (maxLength(value.phone, 15) === false) {
                  return formatMessage(content.phoneMaxLengthErrorMessage, {
                    length: 15,
                  });
                } else if (isPhone(value.phone) === false) {
                  return content.phoneNumericErrorMessage;
                }
              }

              return;
            }}
          />
        </ProfileFieldGroup>

        <ProfileFieldGroup
          icon={Flag}
          title={content.nationalityTitleField}
          border
          style={{ zIndex: 4 }}
        >
          <CountryField
            name="nationality"
            label={content.nationalityText}
            iconRight={<Icon src={Chevron} />}
            style={Style.longInput}
          />
        </ProfileFieldGroup>

        <ProfileFieldGroup
          icon={Lock}
          title={content.passwordTitleField}
          border
          style={{ zIndex: 3 }}
        >
          <View style={Style.password}>
            <Text>*********</Text>
            <Button
              loading={loadingButton}
              disabled={disableButton}
              variant={variantButton}
              text={message}
              onPress={handleResetPassword}
            />
          </View>
        </ProfileFieldGroup>

        <ProfileFieldGroup
          icon={CreditCard}
          title={content.identityNumberTitleField}
          border
        >
          <InputField
            name="identityCardNumber"
            label={content.identityNumberText}
            style={Style.longInput}
            validate={value => {
              if (value && isNumeric(value) === false) {
                return content.identityNumericErrorMessage;
              }

              return;
            }}
          />
        </ProfileFieldGroup>

        <ProfileFieldGroup icon={Passport} title={content.passportTitleField}>
          <View style={[Style.row, Style.inputGroup, { zIndex: 3 }]}>
            <InputField
              name="passportNumber"
              label={content.passportText}
              style={[Style.column, Style.marginRight]}
              validate={value => {
                if (required(value) === true) {
                  if (isAlphaNumeric(value) === false) {
                    return content.passportAlphanumericErrorMessage;
                  } else if (minLength(value, 6) === false) {
                    return formatMessage(
                      content.passportMinLengthErrorMessage,
                      { length: 6 }
                    );
                  } else if (maxLength(value, 9) === false) {
                    return formatMessage(
                      content.passportMaxLengthErrorMessage,
                      { length: 9 }
                    );
                  }
                }

                return;
              }}
            />
            <DateField
              name="passportExpiryDate"
              label={content.passportExpiryDateText}
              style={Style.column}
              maxYear={today.getFullYear() + 24}
              yearRange={25}
              reverse
              validate={value => {
                const { year, month, day } = value || ({} as DateFieldValue);

                if (year && month && day) {
                  const date = parse(
                    `${year}-${Number(month) + 1}-${day}`,
                    JAVA_DATE,
                    0
                  );

                  if (!isValid(date)) {
                    return content.passportExpiryDateNotValidErrorMessage;
                  } else if (date < today) {
                    return formatMessage(
                      content.passportExpiryDateEarliestDateErrorMessage,
                      { date: format(today, 'SHORT_MONTH') }
                    );
                  }
                }

                return;
              }}
            />
          </View>

          <CountryField
            name="passportCountryOfIssue"
            label={content.passportCountryText}
            style={Style.longInput}
            iconRight={<Icon src={Chevron} />}
          />
        </ProfileFieldGroup>
      </Card>

      <View style={Style.control}>
        <Button
          text={content.submitButtonText}
          variant="main-cta"
          loading={isSubmitting}
          onPress={formSubmit(handleSubmit)}
        />
      </View>
    </FormProvider>
  );
}

const Style = StyleSheet.create({
  card: {
    overflow: 'visible',
    marginBottom: Token.spacing.xl,
    zIndex: 1,
  },
  subTitle: {
    marginBottom: Token.spacing.xxs,
    fontWeight: Token.typography.weightMedium.fontWeight,
  },
  column: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  marginRight: {
    marginRight: Token.spacing.m,
  },
  control: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  inputGroup: {
    marginBottom: Token.spacing.m,
  },
  shortInput: {
    width: 270,
  },
  longInput: {
    width: 350,
  },
  password: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

import { Country } from '@traveloka/ctv-core/auth/types';

import { Locale } from '../LocaleContext';

export function getCompanyCountry() {
  // get locale from url, e.g: en-id
  const currentLocale = window && window.location.pathname.split('/')[1];

  switch (currentLocale) {
    case Locale.ENID:
    case Locale.IDID:
      return Country.ID;
    case Locale.ENTH:
    case Locale.THTH:
      return Country.TH;
    case Locale.ENVN:
    case Locale.VNVN:
      return Country.VN;
    default:
      return undefined;
  }
}

import React from 'react';
import { RouteProps } from 'react-router-dom';

import { useAuth, useLocale } from '@traveloka/ctv-core';

import NewPrelogin from './prelogin/NewPrelogin';

export default function Home(props: RouteProps) {
  const { isAuthenticated } = useAuth();
  const { locale } = useLocale();

  if (isAuthenticated) {
    // We have already migrated the post login component to the new next js repository
    // We have changed the url from `/` to `/home` for the post login page
    window.location.href = `/${locale}/home`;
    return null;
  }

  return <NewPrelogin />;
}

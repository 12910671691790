import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import {
  HotelMainRefundReason,
  HotelReasonsWithNoAdditionalInfo,
  HotelReasonsWithAdditionalInfo,
  HotelMainRefundReasonWithAdditionalInfo,
} from 'refund/shared/api/types';
import { useHotelRequestRefund } from './HotelRequestRefundContext';

type FormValue = {
  mainRefundReason: Nullable<
    HotelReasonsWithNoAdditionalInfo | HotelReasonsWithAdditionalInfo
  >;
  tncCheckbox: boolean;
};

type Action =
  | {
      type: 'updateAdditionalInfo';
      additionalInfo: string;
    }
  | {
      type: 'updateSelectedMainReason';
      selectedMainReason:
        | HotelReasonsWithNoAdditionalInfo
        | HotelReasonsWithAdditionalInfo;
    }
  | {
      type: 'updateTncCheckbox';
      tncCheckbox: boolean;
    };

type ActionDispatcher = {
  updateAdditionalInfo(additionalInfo: string): void;
  updateSelectedMainReason(
    selectedMainReason: Nullable<HotelMainRefundReason>
  ): void;
  updateTncCheckbox(value: boolean): void;
};

const HotelRequestRefundFormContext = createContext<
  [FormValue, Dispatch<Action>]
>(undefined!);

function reducer(state: FormValue, action: Action): FormValue {
  switch (action.type) {
    case 'updateAdditionalInfo':
      if (
        state.mainRefundReason?.mainReason ===
          HotelMainRefundReasonWithAdditionalInfo.DOUBLE_BOOKING ||
        state.mainRefundReason?.mainReason ===
          HotelMainRefundReasonWithAdditionalInfo.OTHERS
      ) {
        return {
          ...state,
          mainRefundReason: {
            mainReason: state.mainRefundReason!.mainReason,
            additionalInformation: action.additionalInfo,
          },
        };
      }

      const mainRefundReasonNoAdditionalInfo: HotelReasonsWithNoAdditionalInfo = {
        mainReason: state.mainRefundReason!.mainReason,
        additionalInformation: undefined,
      };

      return {
        ...state,
        mainRefundReason: mainRefundReasonNoAdditionalInfo,
      };
    case 'updateSelectedMainReason':
      return {
        ...state,
        mainRefundReason: action.selectedMainReason,
      };
    case 'updateTncCheckbox':
      return {
        ...state,
        tncCheckbox: action.tncCheckbox,
      };
  }
}

const initialState: FormValue = {
  mainRefundReason: null,
  tncCheckbox: false,
};

export function HotelRequestRefundFormProvider({
  children,
}: PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <HotelRequestRefundFormContext.Provider value={[state, dispatch]}>
      {children}
    </HotelRequestRefundFormContext.Provider>
  );
}

export function useHotelRequestRefundForm() {
  const [state] = useContext(HotelRequestRefundFormContext);
  return state;
}

export function useHotelRequestRefundFormAction(): ActionDispatcher {
  const [_, dispatch] = useContext(HotelRequestRefundFormContext);
  const [{ data }] = useHotelRequestRefund();
  const { reasons } = data;

  return {
    updateAdditionalInfo(additionalInfo) {
      dispatch({
        type: 'updateAdditionalInfo',
        additionalInfo,
      });
    },
    updateSelectedMainReason(mainReason) {
      const selectedMainReason = reasons.find(
        reason => reason.mainReason === mainReason
      )!;
      dispatch({
        type: 'updateSelectedMainReason',
        selectedMainReason,
      });
    },
    updateTncCheckbox(value: boolean) {
      dispatch({ type: 'updateTncCheckbox', tncCheckbox: value });
    },
  };
}

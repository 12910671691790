enum BookingStatus {
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  WAITING_FOR_ISSUANCE = 'WAITING_FOR_ISSUANCE',
  ISSUED = 'ISSUED',
  PAYMENT_EXPIRED = 'PAYMENT_EXPIRED',
  CANCELLED = 'CANCELLED',
  ISSUANCE_FAILED = 'ISSUANCE_FAILED',
  BOOKING_NOT_FOUND = 'BOOKING_NOT_FOUND',
}

export default BookingStatus;

import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Tooltip from '@traveloka/ctvweb-ui/src/shared/components/Tooltip/Tooltip';
import { Icon, Text, Token } from '@traveloka/web-components';

import IcSystemStatusInfoFill from '@traveloka/icon-kit-web/svg/darkBlue/ic_system_status_info-fill_24px.svg';

import RequiredLabel from './RequiredLabel';

type FieldRowProps = {
  children: ReactNode;
  label?: string;
  subLabel?: string | null;
  tooltip?: string | null;
  tooltipPosition?: 'top' | 'bottom';
  required?: boolean;
  style?: StyleProp<ViewStyle>;
};

export function FieldRow(props: FieldRowProps) {
  const {
    children,
    label,
    required,
    subLabel,
    tooltip,
    tooltipPosition,
    style,
  } = props;

  return (
    <View style={[styles.row, style]}>
      <View style={styles.labelContainer}>
        {Boolean(label) && (
          <>
            <Text variant="ui-small" style={styles.label}>
              {label}
              {required && <RequiredLabel />}
              {tooltip && (
                <Tooltip
                  position={tooltipPosition || 'top'}
                  backgroundColor={Token.color.uiBlueSecondary}
                  width={404}
                  content={
                    <Text variant="ui-small" ink="white">
                      {tooltip}
                    </Text>
                  }
                >
                  <Icon
                    style={{ marginLeft: Token.spacing.xs }}
                    src={IcSystemStatusInfoFill}
                    width={12}
                  />
                </Tooltip>
              )}
            </Text>
            {subLabel && (
              <Text style={styles.subLabel} variant="ui-tiny" ink="secondary">
                {subLabel}
              </Text>
            )}
          </>
        )}
      </View>
      <View style={styles.contentContainer}>{children}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  labelContainer: {
    width: 207,
    zIndex: 1,
    paddingTop: Token.spacing.xxs,
  },
  label: {
    fontWeight: Token.typography.weightMedium.fontWeight,
    marginEnd: Token.spacing.m,
  },
  subLabel: {
    marginTop: Token.spacing.xxs,
  },
  contentContainer: {
    flex: 1,
  },
});

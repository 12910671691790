import React from 'react';
import { View } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { useTheme, Checkbox, Image, Text } from '@traveloka/web-components';

import {
  useFlightSearchAirlineFilterContext,
  useFlightSearchOptionContext,
} from 'flight/search/contexts/FlightSortFilterContext';

import { getThemeStyle, CommonStyle } from '../filter-search-toolbar.style';
import FlightSearchToolbarMenuContainer from '../FlightSearchToolbarMenuContainer';

function AirlineFilter() {
  const { CorporateFlightSearchToolbar } = useContentResource();
  const content = {
    title: CorporateFlightSearchToolbar.airline,
    selectAll: CorporateFlightSearchToolbar.selectAll,
  };

  const ThemeStyle = getThemeStyle(useTheme());
  const { filterOption, setFilter } = useFlightSearchOptionContext();
  const values = useFlightSearchAirlineFilterContext();

  function selectAll() {
    if (values.length === filterOption.airline.length) {
      setFilter('airline', []);
      return;
    }

    setFilter(
      'airline',
      filterOption.airline.map(airline => airline.airlineCode)
    );
  }

  return (
    <FlightSearchToolbarMenuContainer
      alignment="left"
      text={content.title}
      style={CommonStyle.menuItem}
      testID="flight.search.toolbar.airline"
    >
      <Text ink="primary" variant="ui-small">
        {content.title}
      </Text>
      <View style={[CommonStyle.container, ThemeStyle.container]}>
        <View style={CommonStyle.checkbox}>
          <Checkbox
            checked={values.length === filterOption.airline.length}
            onChange={selectAll}
          >
            <Checkbox.Control />
            <Text variant="ui-small" style={CommonStyle.checkboxText}>
              {content.selectAll}
            </Text>
          </Checkbox>
        </View>
        {filterOption.airline.map(option => (
          <View key={option.airlineCode} style={CommonStyle.checkbox}>
            <Checkbox
              checked={values.includes(option.airlineCode)}
              onChange={() => setFilter('airline', option.airlineCode)}
            >
              <Checkbox.Control />
              <View style={CommonStyle.checkboxTextJustify}>
                <Text variant="ui-small">{option.airlineName}</Text>
                <Image
                  src={option.logoUrl}
                  width={28}
                  height={19}
                  objectFit="contain"
                />
              </View>
            </Checkbox>
          </View>
        ))}
      </View>
    </FlightSearchToolbarMenuContainer>
  );
}

export default AirlineFilter;

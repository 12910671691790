import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

import { useApi } from '@traveloka/ctv-core';
import { useContentResource } from '@traveloka/ctv-core/resource';
import { Text, Token } from '@traveloka/web-components';

import { AirlineMap } from 'flight/search/types';
import { AirlineDataResponse, AIRLINE_DATA_API } from 'flight/shared/api';
import { ProfileResponse, GET_PROFILE } from 'profile/api';

import EmploymentDetailContent from './components/EmploymentDetailContent/EmploymentDetailContent';
import EmploymentDetaiLoading from './components/EmploymentDetailLoading/EmploymentDetailLoading';

type PageState =
  | { isLoading: true }
  | { isLoading: false; profile: ProfileResponse; airlineMap: AirlineMap };

export default function EmploymentDetail() {
  const [pageState, setPageState] = useState<PageState>({ isLoading: true });

  const content = useContentResource().CorporateEmploymentDetail;

  const fetchProfile = useApi<ProfileResponse>({
    domain: 'management',
    method: 'post',
    path: GET_PROFILE,
  });

  const fetchAirlines = useApi<AirlineDataResponse>({
    domain: 'search',
    method: 'post',
    path: AIRLINE_DATA_API,
  });

  useEffect(() => {
    async function fetchAll() {
      const profileRes = await fetchProfile({});

      if (profileRes.success) {
        const airlineRes = await fetchAirlines({});

        if (airlineRes.success) {
          const airlineMap = airlineRes.data.airlines.reduce((obj, airline) => {
            obj[airline.airlineCode] = airline;

            return obj;
          }, {} as AirlineMap);

          setPageState({
            isLoading: false,
            profile: profileRes.data,
            airlineMap,
          });
        }
      }
    }

    fetchAll();
  }, []);

  return (
    <>
      <Text variant="headline" style={Style.title}>
        {content.title}
      </Text>

      {pageState.isLoading && <EmploymentDetaiLoading />}

      {!pageState.isLoading && (
        <EmploymentDetailContent
          airlineMap={pageState.airlineMap}
          profile={pageState.profile}
        />
      )}
    </>
  );
}

const Style = StyleSheet.create({
  title: {
    marginBottom: Token.spacing.m,
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Image, Token } from '@traveloka/web-components';
import {
  GenericHomeContent_v2,
  GenericHomeButton,
  GenericHomeWatchDemoButton,
} from '@traveloka/ctvweb-ui/generic';
import { DRNHtmlText } from '@traveloka/district-ui';

type Props = {
  content: {
    heading: string;
    paragraph: string;
    secondaryParagraph: string;
    partnerParagraph: string;
    signUpButtonText: string;
    watchDemoButtonText: string;
    mainImageUrl: string;
    logoImageUrls: Array<string>;
  };
  onPressSignUp(): void;
  onPressWatchVideo(): void;
};

export default function GenericHomeIntroduction(props: Props) {
  const { content, onPressSignUp, onPressWatchVideo } = props;

  const paragraphHtmlStyle = {
    fontSize: Token.typography.uiSmall.fontSize,
    lineHeight: Token.typography.uiSmall.lineHeight,
    margin: 0,
    color: Token.color.uiDarkSecondary,
  };

  return (
    <GenericHomeContent_v2 style={Style.container}>
      <View style={Style.flexBox}>
        <View style={Style.contentContainer}>
          <Text variant="hero">{content.heading}</Text>
          <Text style={Style.marginTop}>{content.paragraph}</Text>
          <View style={[Style.flexBox, Style.marginTop]}>
            <GenericHomeButton
              text={content.signUpButtonText}
              style={Style.signUpButton}
              onPress={onPressSignUp}
            />
            <GenericHomeWatchDemoButton
              text={content.watchDemoButtonText}
              onPress={onPressWatchVideo}
            />
          </View>

          <View style={Style.marginTop}>
            <DRNHtmlText
              htmlText={'<p>' + content.secondaryParagraph + '</p>'}
              htmlStyles={{
                p: paragraphHtmlStyle,
              }}
            />
          </View>
        </View>
        <View style={Style.imageContainer}>
          <Image
            objectFit="contain"
            src={content.mainImageUrl}
            style={Style.image}
          />
        </View>
      </View>

      <View style={Style.marginTop}>
        <Text>{content.partnerParagraph}</Text>
        <View style={[Style.flexBox, Style.partnerLogoList]}>
          {content.logoImageUrls.map((url, index) => {
            return (
              <Image
                key={index}
                height={26}
                style={Style.partnerLogo}
                src={url}
              />
            );
          })}
        </View>
      </View>
    </GenericHomeContent_v2>
  );
}

const Style = StyleSheet.create({
  container: {
    paddingTop: Token.spacing.xl,
    paddingBottom: Token.spacing.xxxxl * 2,
  },
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  marginTop: {
    marginTop: Token.spacing.l,
  },
  contentContainer: {
    flexBasis: '60%',
    paddingRight: Token.spacing.xxxl * 2,
  },
  imageContainer: {
    flexBasis: '40%',
  },
  image: {
    width: '100%',
  },
  signUpButton: {
    marginRight: Token.spacing.l,
  },
  partnerLogoList: {
    flexWrap: 'wrap',
    marginTop: Token.spacing.s,
  },
  partnerLogo: {
    height: 26,
    width: 'auto',
    marginTop: Token.spacing.s,
    marginRight: Token.spacing.xxl,
  },
});

import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import {
  PaymentMethod,
  Permission,
  useAuth,
  useContentResource,
  useLocale,
} from '@traveloka/ctv-core';
import { MenuItem } from '@traveloka/ctvweb-ui';
import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import { Text, Token } from '@traveloka/web-components';

import Policy from '@traveloka/icon-kit-web/svg/blue/ic_policy_check_24px.svg';
import ProductBill from '@traveloka/icon-kit-web/svg/blue/ic_product_duotone_bill_24px.svg';
import Document from '@traveloka/icon-kit-web/svg/blue/ic_system_document_24px.svg';
import Setting from '@traveloka/icon-kit-web/svg/blue/ic_system_setting_24px.svg';
import Booking from '@traveloka/icon-kit-web/svg/blue/ic_user_booking_24px.svg';
import Employee from '@traveloka/icon-kit-web/svg/blue/ic_user_quick_pick_24px.svg';
import Receipt from '@traveloka/icon-kit-web/svg/blue/ic_user_receipt_24px.svg';
import PolicyActive from '@traveloka/icon-kit-web/svg/light/ic_policy_check_24px.svg';
import ProductBillActive from '@traveloka/icon-kit-web/svg/light/ic_product_duotone_bill_24px.svg';
import DocumentActive from '@traveloka/icon-kit-web/svg/light/ic_system_document_24px.svg';
import SettingActive from '@traveloka/icon-kit-web/svg/light/ic_system_setting_24px.svg';
import BookingActive from '@traveloka/icon-kit-web/svg/light/ic_user_booking_24px.svg';
import EmployeeActive from '@traveloka/icon-kit-web/svg/light/ic_user_quick_pick_24px.svg';
import ReceiptActive from '@traveloka/icon-kit-web/svg/light/ic_user_receipt_24px.svg';

import MenuCreditLimit from 'credit-limit/components/MenuCreditLimit/MenuCreditLimit';

type Props = {
  currentPath?: string;
  headerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

type Menu = {
  text: string;
  leftIcon: string;
  leftIconActive: string;
  url: string;
  name: string;
};

export default function CompanyMenu(props: Props) {
  const { currentPath = '', headerStyle, style, testID } = props;

  const content = useContentResource().CorporateMenu;
  const { locale } = useLocale();

  const { user } = useAuth();

  const menus: Menu[] = [
    {
      text: content.purchaseListMenuText,
      leftIcon: Receipt,
      leftIconActive: ReceiptActive,
      url: '/booking/?tab=my-purchase',
      name: 'purchase-list',
    },
    {
      text: content.bookingListMenuText,
      leftIcon: Booking,
      leftIconActive: BookingActive,
      url: '/booking/?tab=my-booking',
      name: 'my-booking',
    },
  ];

  if (user) {
    // Should sync with `company/structure/CompanyStructure` file
    const hasPermission = user.has(
      Permission.DIVISION_READ,
      Permission.DIVISION_CREATE,
      Permission.DIVISION_DELETE,
      Permission.DIVISION_UPDATE,
      Permission.TIER_READ,
      Permission.TIER_CREATE,
      Permission.TIER_DELETE,
      Permission.TIER_UPDATE,
      Permission.USER_ROLE_READ,
      Permission.USER_ROLE_CREATE,
      Permission.USER_ROLE_DELETE,
      Permission.USER_ROLE_UPDATE,
      Permission.POLICY_READ,
      Permission.POLICY_CREATE,
      Permission.POLICY_DELETE,
      Permission.POLICY_UPDATE,
      Permission.APPROVAL_CONFIG_VIEW,
      Permission.APPROVAL_CONFIG_MODIFY,
      Permission.BILLING_READ,
      Permission.BILLING_MODIFY
    );

    if (hasPermission) {
      menus.splice(-2, 0, {
        text: content.companyStructureMenuText,
        leftIcon: Setting,
        leftIconActive: SettingActive,
        url: '/company/info',
        name: 'company-structure',
      });
    }

    if (user.globalTripRequestApproval) {
      menus.splice(-2, 0, {
        text: content.approvalListMenuText,
        leftIcon: Policy,
        leftIconActive: PolicyActive,
        url: '/approval',
        name: 'approval',
      });
    }

    if (
      user.has(
        Permission.USER_READ,
        Permission.USER_CREATE,
        Permission.USER_UPDATE,
        Permission.USER_DELETE
      )
    ) {
      menus.splice(-2, 0, {
        text: content.employeeListMenuText,
        leftIcon: Employee,
        leftIconActive: EmployeeActive,
        url: '/people-structure/?tab=employee',
        name: 'employee-list',
      });
    }

    if (user.has(Permission.EXPENSE_REPORT_VIEW)) {
      menus.push({
        text: content.expenseReportMenuText,
        leftIcon: Document,
        leftIconActive: DocumentActive,
        url: '/financial/?tab=expense-report',
        name: 'expense-report',
      });
    }

    if (user.has(Permission.INVOICE_LIST_VIEW)) {
      menus.push({
        text: content.invoiceListMenuText,
        leftIcon: ProductBill,
        leftIconActive: ProductBillActive,
        url: '/financial/?tab=invoice',
        name: 'invoice-list',
      });
    }
  }

  const useInvoice = user?.paymentMethod === PaymentMethod.INVOICE;

  // Process to deprecate the repository
  // Decoupling from local react redirection state
  function redirectTo(url: string) {
    window.location.href = '/' + locale + url;
  }

  return (
    <View style={[Style.dropdownCompanyMenu, style]}>
      <View style={headerStyle}>
        <Text style={[Style.header]}>{content.title}</Text>
        {useInvoice && <MenuCreditLimit testID={testID} />}
      </View>
      {menus.map(menu => (
        <MenuItem
          {...menu}
          style={Style.menuItem}
          testID={appendTestId(testID, `menu.company.${menu.name}`)}
          key={menu.url}
          isActive={currentPath.startsWith('/' + locale + menu.url)}
          onPress={() => redirectTo(menu.url)}
        />
      ))}
    </View>
  );
}

const Style = StyleSheet.create({
  dropdownCompanyMenu: {
    minWidth: 256,
    paddingVertical: Token.spacing.xs,
  },
  header: {
    fontWeight: Token.typography.weightMedium.fontWeight,
    letterSpacing: 1,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.m,
  },
  menuItem: {
    marginRight: 0,
  },
});

import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { useContentResource } from '@traveloka/ctv-core';
import { appendTestId } from '@traveloka/ctvweb-ui/src/shared/utils/TestUtil';
import RetryIcon from '@traveloka/icon-kit-web/svg/blue/ic_system_retry_24px.svg';
import { Token, Text, useTheme, Icon } from '@traveloka/web-components';

import { useAccountStatus } from 'account-status/context/AccountStatusContext';
import CreditLimitIcon from 'credit-limit/components/CreditLimitIcon/CreditLimitIcon';
import CreditLimitBadge from 'credit-limit/components/CreditLimitBadge/CreditLimitBadge';
import { useCreditLimit } from 'credit-limit/context';

type Props = {
  testID?: string;
};

function MenuCreditLimit(props: Props) {
  const { testID } = props;

  const { data: creditLimit, fetchCreditLimit, isFetching } = useCreditLimit();
  const { isSuspended } = useAccountStatus();
  const { color } = useTheme();
  const content = useContentResource().CorporateCreditLimitMenu;
  const creditLimitEnum = useContentResource().CorporateEnumCreditLimit;
  const accountStatusContent = useContentResource().CorporateAccountStatus;

  const containerStyle = [
    Style.container,
    { borderTopColor: color.borderDivide },
  ];

  if (!creditLimit) {
    return (
      <View testID={appendTestId(testID, 'menu')} style={containerStyle}>
        <CreditLimitIcon
          testID={appendTestId(testID, 'menu')}
          style={Style.icon}
          status={undefined}
        />
        <View style={Style.retry}>
          <Text variant="ui-tiny" ink="highlight">
            {content.failToLoadText}
          </Text>

          {!isFetching && (
            <TouchableOpacity
              testID={appendTestId(testID, 'menu.retry')}
              onPress={fetchCreditLimit}
            >
              <Icon src={RetryIcon} />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  }

  if (isSuspended) {
    return (
      <View testID={appendTestId(testID, 'menu')} style={containerStyle}>
        <CreditLimitIcon
          testID={appendTestId(testID, 'menu')}
          style={Style.icon}
          status={creditLimit.remainingCreditLimit.status}
          isSuspended
        />

        <View>
          {creditLimit.hasPermission && (
            <CreditLimitBadge
              testID={appendTestId(testID, 'menu')}
              status={'EMPTY'}
              percentage={accountStatusContent.suspended}
            />
          )}
          <Text variant="ui-tiny">
            {accountStatusContent.outstandingInvoice}
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View testID={appendTestId(testID, 'menu')} style={containerStyle}>
      <CreditLimitIcon
        testID={appendTestId(testID, 'menu')}
        style={Style.icon}
        status={creditLimit.remainingCreditLimit.status}
      />

      <View>
        {creditLimit.hasPermission && (
          <CreditLimitBadge
            testID={appendTestId(testID, 'menu')}
            status={creditLimit.remainingCreditLimit.status}
            percentage={creditLimit.remainingPercentage}
          />
        )}
        <Text variant="ui-tiny">
          {creditLimitEnum[creditLimit.remainingCreditLimit.status]}
        </Text>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingLeft: Token.spacing.l,
    paddingVertical: Token.spacing.s,
    alignItems: 'center',
    height: 60,
    borderTopWidth: Token.border.width.thin,
  },
  icon: {
    marginRight: Token.spacing.ml,
  },
  retry: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    paddingRight: Token.spacing.m,
  },
});

export default MenuCreditLimit;

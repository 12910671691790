import React from 'react';
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  StyleSheet,
} from 'react-native';

import { Props } from '@traveloka/web-components/src/momentum/Text/Text';
import { Text, Token } from '@traveloka/web-components';
import { useHoverable } from '@traveloka/web-components/future';

import Fade from '../Fade/Fade';
import { appendTestId } from '../../utils/TestUtil';

type TextTooltipProps = {
  testID?: string;
  text: string;
  maxLength: number;
  variant?: Props['variant'];
  ink?: 'secondary' | 'white';
  direction?: 'up' | 'down';
  style?: StyleProp<TextStyle>;
  styleContainer?: StyleProp<ViewStyle>;
  styleTooltip?: StyleProp<ViewStyle>;
};

function TextTooltip(props: TextTooltipProps) {
  const {
    testID,
    text,
    maxLength,
    style,
    styleContainer,
    styleTooltip,
    ink,
    variant,
    direction = 'up',
  } = props;
  const [isHovered, eventHandlers] = useHoverable();

  const cutLength = maxLength - 3;
  return (
    <View testID={testID} style={styleContainer}>
      {text.length < maxLength ? (
        <Text
          testID={appendTestId(testID, 'text')}
          style={style}
          variant={variant}
          ink={ink}
        >
          {text}
        </Text>
      ) : (
        <>
          <Fade
            visible={isHovered}
            style={[
              Style.fade,
              direction === 'up'
                ? {
                    bottom: 20,
                  }
                : {
                    top: 24,
                  },
              styleTooltip,
            ]}
            direction={direction}
          >
            <Text
              testID={appendTestId(testID, 'tooltip')}
              variant="ui-tiny"
              ink="secondary"
            >
              {text}
            </Text>
          </Fade>
          <View {...eventHandlers}>
            <Text
              testID={appendTestId(testID, 'text')}
              style={style}
              variant={variant}
              ink={ink}
            >
              {text.substr(0, cutLength)}...
            </Text>
          </View>
        </>
      )}
    </View>
  );
}

const Style = StyleSheet.create({
  fade: {
    position: 'absolute',
    zIndex: 100,
    maxWidth: 600,
    width: 'fit-content',
    backgroundColor: Token.color.uiLightStain,
    borderColor: Token.color.borderDivide,
    borderWidth: Token.border.width.thick,
    paddingVertical: Token.spacing.xxs,
    paddingHorizontal: Token.spacing.s,
    borderRadius: Token.border.radius.normal,
  },
});

export default TextTooltip;
